import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setUserData } from "../../../../../redux/slices/olympics";
import { MainContext } from "../../../../../context/MainContext";

const Result = ({ score }) => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.olympics);
  const { setIsTimeOver, setIsEndTask } = useContext(MainContext);

  useEffect(() => {
    return () => {
      setIsEndTask(false);
      setIsTimeOver(false);
      dispatch(setUserData({}));
      localStorage.removeItem("endTime");
      localStorage.removeItem("initialTime");
    };
  }, []);

  return (
    <div className="all-faqs container">
      <div className="faqs-all-qus">
        <div className="article-preview ant-result ant-result-403 css-amq5gd pt-0">
          <div className="ant-result-icon ant-result-image">
            <img src="/images/githubit/undraw_agree_re_hor9.svg" />
          </div>
          <div className="ant-result-title">
            <span>
              {`${userData?.lang === "uz" ? "Umumiy: " : "Общий: "}`}{" "}
              {score.total}
            </span>
            <span>
              {`${userData?.lang === "uz" ? "To'g'ri: " : "Правильных: "}`}{" "}
              {score.total - score.incorrect}
            </span>
            <span>
              {`${userData?.lang === "uz" ? "Noto'g'ri: " : "Неправильных: "}`}{" "}
              {score.incorrect}
            </span>
          </div>
          <div className="ant-result-title">
            <span>
              {`${
                userData?.lang === "uz" ? "Koeffitsiyent: " : "Коэффициент: "
              }`}

              {`${(
                ((score.total - score.incorrect) * 100) /
                score.total
              ).toFixed(2)} %`}
            </span>
          </div>
          <div className="ant-result-subtitle">
            {userData?.lang === "uz" ? (
              <span>
                1-turning umumiy natijalari hamda 2-turga muvaffiqiyatli o‘tgan
                o‘quvchilar ro‘yxati <br /> bizning telegram kanalimizda e'lon
                qilinadi.
                <a href="https://t.me/mathschoolsuz" style={{ color: "blue" }}>
                  https://t.me/mathschoolsuz
                </a>
              </span>
            ) : (
              <span>
                Результаты 1 тура и список студентов, успешно прошедших на 2
                тур, <br /> будут объявлены в нашем Telegram канале.{" "}
                <a href="https://t.me/mathschoolsuz" style={{ color: "blue" }}>
                  https://t.me/mathschoolsuz
                </a>
              </span>
            )}
          </div>
          <div className="col-12 d-flex justify-content-center mt-5">
            <Link to="/">
              <button type="submit" className="theme-btn-two">
                Выход
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Result;
