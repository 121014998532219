import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const FancyFeatureTen = () => {
  const { t } = useTranslation();
  const FeaturesContent = [
    {
      bgColor: "#FFEBDB",
      icon: "68",
      title: t("speedup.degree"),
      desc: t("speedup.degree.desc"),
      dataDealy: "0",
    },
    {
      bgColor: "#E0F8F8",
      icon: "67",
      title: t("high.degree"),
      desc: t("high.degree.desc"),
      dataDealy: "100",
    },
    {
      bgColor: "#F7EDFF",
      icon: "70",
      title: t("advanced.degree"),
      desc: t("advanced.degree.desc"),
      dataDealy: "200",
    },
  ];
  return (
    <div className="row justify-content-center mt-35 md-mt-20">
      {FeaturesContent.map((val, i) => (
        <div
          className="col-lg-4 col-md-6 h-100"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.dataDealy}
          key={i}
        >
          <div className="block-style-fifteen mt-40">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: val.bgColor }}
            >
              {i === 0 ? "I" : i === 1 ? "II" : "III"}
              {/* <img src={`images/icon/${val.icon}.svg`} alt="icon" /> */}
            </div>
            <h3>{val.title}</h3>
            <p>{val.desc}</p>
            {/* <Link to="/solution-management" className="mt-30">
              {" "}
              <img src="images/icon/69.svg" alt="icon" />
            </Link> */}
          </div>
          {/* /.block-style-ten */}
        </div>
      ))}
    </div>
  );
};

export default FancyFeatureTen;
