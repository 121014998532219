import React, { useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const FormEvent = () => {
  const { t } = useTranslation();
  const [phone, setPhone] = useState("");
  const handleSubmit = (event) => {
    event.preventDefault();
    fetch(
      `https://api.telegram.org/bot6620369147:AAEPOjyu0acCRnOCz1PJ3hX3GtVcn8DIbQU/sendMessage?chat_id=6531369683&parse_mode=HTML&text=Номер телефона: ${phone}`,
    )
      .then((response) => {
        return response.json();
      })
      .then(() => toast.success(t("contact.form.success.message")));
  };
  return (
    <form>
      <input
        type="text"
        placeholder={t("phone.number")}
        onChange={(e) => setPhone(e.target.value)}
      />
      <button onClick={handleSubmit}>{t("send")}</button>
    </form>
  );
};

export default FormEvent;
