import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { json } from "react-router-dom";

const HeaderPopupForm = ({ toggleModalOne }) => {
  const { t } = useTranslation();
  // for validation
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("name.is.required")),
    email: Yup.string().required(t("phone.number.is.required")),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  function onSubmit(data, e) {
    // display form data on success
    fetch(
      `https://api.telegram.org/bot6620369147:AAEPOjyu0acCRnOCz1PJ3hX3GtVcn8DIbQU/sendMessage?chat_id=6531369683&parse_mode=HTML&text=Имя: ${data.name} Номер телефона: ${data.email} Сообщение: ${data.message}`,
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        toast.success(t("contact.form.success.message"));
        toggleModalOne();
      });
    e.target.reset();
  }

  return (
    <>
      <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="messages"></div>
        <div className="row controls">
          <div className="col-12">
            <div className="input-group-meta form-group mb-20">
              <label>{t("name")}*</label>
              <input
                type="text"
                placeholder={t("your.name")}
                name="name"
                {...register("name")}
                className={`${errors.name ? "is-invalid" : ""}`}
              />
              {errors.name && (
                <div className="invalid-feedback">{errors.name?.message}</div>
              )}
            </div>
          </div>
          {/* End .col */}

          <div className="col-12">
            <div className="input-group-meta form-group mb-20">
              <label>{t("phone.number")}*</label>
              <input
                placeholder={t("your.phone.number")}
                name="email"
                type="text"
                {...register("email")}
                className={` ${errors.email ? "is-invalid" : ""}`}
              />
              {errors.email && (
                <div className="invalid-feedback">{errors.email?.message}</div>
              )}
            </div>
          </div>
          {/* End .col */}

          <div className="col-12">
            <div className="input-group-meta form-group mb-30">
              <label>{t("message")}</label>
              <textarea
                placeholder=""
                name="sendMessage"
                type="text"
                {...register("message")}
                className={`${errors.sendMessage ? "is-invalid" : ""}`}
              ></textarea>
              {errors.sendMessage && (
                <div className="invalid-feedback">
                  {errors.sendMessage?.message}
                </div>
              )}
            </div>
          </div>
          {/* End .col */}

          <div className="col-12">
            <button className="theme-btn-seven w-100">{t("send")}</button>
          </div>
          {/* End .col */}
        </div>
      </form>
    </>
  );
};

export default HeaderPopupForm;
