import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../../../../redux/slices/olympics";

const LanguageForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userData } = useSelector((state) => state.olympics);
  // for validation
  const validationSchema = Yup.object().shape({
    lang: Yup.string().required("Language is required"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm(formOptions);

  function onSubmit(data, e) {
    // display form data on success
    dispatch(setUserData({ ...userData, ...data }));
    e.target.reset();
    navigate("/olympics/type");
  }

  useEffect(() => {
    if (userData?.phone) {
      navigate("/olympics/test");
    }
  }, [userData]);
  return (
    <form className="checkout-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="user-profile-data">
        <div className="row">
          <div className="col-12">
            <label htmlFor="">
              Qaysi tilda test topshirasiz? / На каком языке <br /> хотите сдавать тест?
            </label>
            <select className="theme-select-menu mb-0 mt-5" {...register("lang")}>
              <option value="uz">O'zbek tilida / На узбекском языке</option>
              <option value="ru">Rus tilida / На русском языке</option>
            </select>
            {/* {errors.class && <div className="invalid-feedback">{errors.class?.message}</div>} */}
          </div>
        </div>
      </div>
      <div className="col-12 d-flex justify-content-center mt-5">
        <button type="submit" className="theme-btn-two">
          Выбрать
        </button>
      </div>
    </form>
  );
};

export default LanguageForm;
