import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import AllRoutes from "./router/AllRoutes";
import ScrollToTop from "./components/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";
import "photoswipe/dist/photoswipe.css";
import "bootstrap/dist/js/bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import PageLoading from "./components/pageLoading";
import { persistor, store } from "./redux/store";
import { useTranslation } from "react-i18next";

const App = () => {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
  window.addEventListener("load", AOS.refresh);

  return (
    <>
      <Helmet>
        <title>Mathschool - {t("edu.center.in.chirchik")}</title>
        <meta property="og:site_name" content="deski" />
        <meta
          property="og:url"
          content="https://themeforest.net/user/ib-themes"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Mathschool - учебный центр в Чирчике"
        />
        <meta
          name="keywords"
          content="matematika, fizika, ingliz tili, o'quv markaz, mathschool, school, math, minimal, modern, multipurpose, parallax, personal, photography, portfolio, showcase, Digital marketing agency, Digital marketing company, Digital marketing services, sass, software company"
        />
        <meta
          name="description"
          content="Бесплатное Обучение. Это просто! Набери высокие баллы по тестам и учись бесплатно."
        />
        <meta
          name="description"
          content="Бесплатное Обучение. Это просто! Набери высокие баллы по тестам и учись бесплатно."
        />
      </Helmet>
      {/* End Seo Helmt */}

      <ToastContainer />
      <ScrollToTop />
      {/* End Scroll top */}
      <Provider store={store}>
        <PersistGate loading={<PageLoading />} persistor={persistor}>
          <AllRoutes />
        </PersistGate>
      </Provider>
    </>
  );
};

export default App;
