/* {
  id: __,
  question: [
    { type: "__", value: "__" },
    { type: "__", value: "__" },
  ],
  options: [
    { type: "__", value: "__" },
    { type: "__", value: "__" },
    { type: "__", value: "__" },
    { type: "__", value: "__" },
    { type: "__", value: "__" },
  ],
  answer: __,
}, */

import {
  alXorazmiyEnglish,
  alXorazmiyMath,
  mirzoUlugbekEnglish,
  mirzoUlugbekMathRu,
  mirzoUlugbekMathUz,
  prezidentSchoolCriticalThinking,
  prezidentSchoolEnglish,
  prezidentSchoolProblemSolving,
  saralash,
  specializedSchoolEnglish_3class,
  specializedSchoolEnglish_4class,
  specializedSchoolEnglish_5class,
  specializedSchoolEnglish_6class,
  specializedSchoolMath_3class,
  specializedSchoolMath_4class,
  specializedSchoolMath_5class,
  specializedSchoolMath_6class,
  specializedSchoolMath_7class,
} from "./quiz";

const applicant = [
  {
    id: 0,
    question: [
      { type: "txt", value: "-27 ni 5 ga bo’lgandagi qoldiqni toping?" },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "1" },
    ],
    answer: 1,
  },
  {
    id: 1,
    question: [
      { type: "img", value: "/quiz-images/applicant/img1.png" },
      { type: "txt", value: "tenglamalar sistemasini yeching." },
    ],
    options: [
      { type: "txt", value: "[-3; 5)" },
      { type: "txt", value: "(2; 4]" },
      { type: "txt", value: "[-6; 6)" },
      { type: "txt", value: "[6; ∞)" },
      { type: "img", value: "/quiz-images/applicant/img2.png" },
    ],
    answer: 2,
  },
  {
    id: 2,
    question: [
      { type: "img", value: "/quiz-images/applicant/img3.png" },
      { type: "txt", value: "ni hisoblang." },
    ],
    options: [
      { type: "img", value: "/quiz-images/applicant/img4.png" },
      { type: "img", value: "/quiz-images/applicant/img5.png" },
      { type: "img", value: "/quiz-images/applicant/img6.png" },
      { type: "img", value: "/quiz-images/applicant/img7.png" },
      { type: "img", value: "/quiz-images/applicant/img8.png" },
    ],
    answer: 4,
  },
  {
    id: 3,
    question: [
      { type: "img", value: "/quiz-images/applicant/img9.png" },
      { type: "txt", value: "bo'lsa," },
      { type: "img", value: "/quiz-images/applicant/img10.png" },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "txt", value: "-1.2" },
      { type: "txt", value: "-0.6" },
      { type: "txt", value: "-1" },
    ],
    answer: 1,
  },
  {
    id: 4,
    question: [
      { type: "txt", value: "Tenglamani yeching." },
      { type: "img", value: "/quiz-images/applicant/img11.png" },
    ],
    options: [
      { type: "txt", value: "7" },
      { type: "txt", value: "6" },
      { type: "txt", value: "5" },
      { type: "txt", value: "4" },
    ],
    answer: 0,
  },
  {
    id: 5,
    question: [
      { type: "txt", value: "Hisoblang" },
      { type: "img", value: "/quiz-images/applicant/img12.png" },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "img", value: "/quiz-images/applicant/img13.png" },
      { type: "img", value: "/quiz-images/applicant/img14.png" },
      { type: "txt", value: "3" },
    ],
    answer: 0,
  },
  {
    id: 6,
    question: [{ type: "txt", value: "1-2+3-4+...+195-196" }],
    options: [
      { type: "txt", value: "98" },
      { type: "txt", value: "-98" },
      { type: "txt", value: "97" },
      { type: "txt", value: "-97" },
    ],
    answer: 1,
  },
  {
    id: 7,
    question: [
      { type: "txt", value: "Hisoblang:" },
      { type: "img", value: "/quiz-images/applicant/img15.png" },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "img", value: "/quiz-images/applicant/img16.png" },
      { type: "img", value: "/quiz-images/applicant/img17.png" },
      { type: "img", value: "/quiz-images/applicant/img18.png" },
    ],
    answer: 0,
  },
  {
    id: 8,
    question: [
      { type: "txt", value: "Soddalashtiring." },
      { type: "img", value: "/quiz-images/applicant/img19.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/applicant/img20.png" },
      { type: "img", value: "/quiz-images/applicant/img21.png" },
      { type: "img", value: "/quiz-images/applicant/img22.png" },
      { type: "img", value: "/quiz-images/applicant/img23.png" },
    ],
    answer: 2,
  },
  {
    id: 9,
    question: [
      { type: "img", value: "/quiz-images/applicant/img24.png" },
      { type: "txt", value: "Tenglamani yeching?" },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "-1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "-2" },
    ],
    answer: 1,
  },
  {
    id: 10,
    question: [
      { type: "txt", value: "Kasrni qisqartiring." },
      { type: "img", value: "/quiz-images/applicant/img25.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/applicant/img26.png" },
      { type: "img", value: "/quiz-images/applicant/img27.png" },
      { type: "img", value: "/quiz-images/applicant/img28.png" },
      { type: "img", value: "/quiz-images/applicant/img29.png" },
    ],
    answer: 0,
  },
  {
    id: 11,
    question: [{ type: "txt", value: "Hisoblang: 38∙45-45∙26+12∙5" }],
    options: [
      { type: "txt", value: "560" },
      { type: "txt", value: "600" },
      { type: "txt", value: "400" },
      { type: "txt", value: "500" },
    ],
    answer: 1,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value:
          "101112131415...47484950 sonining  raqamlari yig’indisini  toping.",
      },
    ],
    options: [
      { type: "txt", value: "280" },
      { type: "txt", value: "265" },
      { type: "txt", value: "275" },
      { type: "txt", value: "285" },
    ],
    answer: 3,
  },
  {
    id: 13,
    question: [
      { type: "txt", value: "20 dan kichik tub sonlar yig’indisini toping." },
    ],
    options: [
      { type: "txt", value: "80" },
      { type: "txt", value: "77" },
      { type: "txt", value: "58" },
      { type: "txt", value: "66" },
    ],
    answer: 2,
  },
  {
    id: 14,
    question: [{ type: "txt", value: "Hisoblang: 42567∙42565-42568∙42564" }],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "-3" },
      { type: "txt", value: "-4" },
    ],
    answer: 1,
  },
  {
    id: 15,
    question: [
      {
        type: "txt",
        value: "140 sonining barcha natural bo’luvchilari sonini aniqlang.",
      },
    ],
    options: [
      { type: "txt", value: "10" },
      { type: "txt", value: "12" },
      { type: "txt", value: "16" },
      { type: "txt", value: "14" },
    ],
    answer: 1,
  },
  {
    id: 16,
    question: [
      {
        type: "txt",
        value:
          "Quyidagi tasdiqlardan qaysilari to'g'ri? 1. 0 sonining 0-darajasi aniqlanmagan; 2. Qoldiq eng kamida 1 ga teng bo’ladi; 3. Eng katta 5 xonali natural son 9999 ga teng;  4. Bir vaqtda 4ga ham, 15ga ham bo’linadigan sonlar 60ga ham bo’linadi; 5. Agar sonning raqamlari yig’indisi 7 ga bo’linsa, u holda shu sonning o’zi ham 7 ga bo’linadi",
      },
    ],
    options: [
      { type: "txt", value: "1, 4 va 5" },
      { type: "txt", value: "1, 3 va 4" },
      { type: "txt", value: "1, 2 va 4" },
      { type: "txt", value: "1 va 4" },
    ],
    answer: 3,
  },
  {
    id: 17,
    question: [
      { type: "img", value: "/quiz-images/applicant/img30.png" },
      { type: "txt", value: "bo'lsa" },
      { type: "img", value: "/quiz-images/applicant/img31.png" },
      { type: "txt", value: "ifodaning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "0" },
      { type: "txt", value: "1" },
    ],
    answer: 3,
  },
  {
    id: 18,
    question: [
      { type: "txt", value: "Hisoblang." },
      { type: "img", value: "/quiz-images/applicant/img32.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/applicant/img33.png" },
      { type: "img", value: "/quiz-images/applicant/img34.png" },
      { type: "img", value: "/quiz-images/applicant/img35.png" },
      { type: "img", value: "/quiz-images/applicant/img36.png" },
    ],
    answer: 1,
  },
  {
    id: 19,
    question: [
      { type: "txt", value: "Hisoblang." },
      { type: "img", value: "/quiz-images/applicant/img37.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/applicant/img38.png" },
      { type: "txt", value: "2" },
      { type: "txt", value: "1" },
      { type: "txt", value: "4" },
    ],
    answer: 2,
  },
  {
    id: 20,
    question: [{ type: "txt", value: "Eng kichik sonni toping" }],
    options: [
      { type: "img", value: "/quiz-images/applicant/img39.png" },
      { type: "img", value: "/quiz-images/applicant/img40.png" },
      { type: "img", value: "/quiz-images/applicant/img41.png" },
      { type: "img", value: "/quiz-images/applicant/img42.png" },
    ],
    answer: 1,
  },
  {
    id: 21,
    question: [
      { type: "img", value: "/quiz-images/applicant/img43.png" },
      { type: "txt", value: "ni hisoblang." },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "img", value: "/quiz-images/applicant/img44.png" },
      { type: "img", value: "/quiz-images/applicant/img45.png" },
    ],
    answer: 3,
  },
  {
    id: 22,
    question: [
      {
        type: "txt",
        value: "n raqamining qanday qiymatlarida",
      },
      { type: "img", value: "/quiz-images/applicant/img71.png" },
      { type: "txt", value: "soni 3 ga qoldiqsiz bo'linadi?" },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "4" },
      { type: "txt", value: "2" },
      { type: "txt", value: "4; 2" },
      { type: "txt", value: "1; 4; 7" },
    ],
    answer: 4,
  },
  {
    id: 23,
    question: [
      { type: "img", value: "/quiz-images/applicant/img46.png" },
      { type: "txt", value: "bo’lsa," },
      { type: "img", value: "/quiz-images/applicant/img47.png" },
    ],
    options: [
      { type: "txt", value: "4+a" },
      { type: "txt", value: "4-a" },
      { type: "txt", value: "4a" },
      { type: "txt", value: "a-4" },
    ],
    answer: 1,
  },
  {
    id: 24,
    question: [
      { type: "img", value: "/quiz-images/applicant/img48.png" },
      { type: "txt", value: "ning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "img", value: "/quiz-images/applicant/img49.png" },
      { type: "img", value: "/quiz-images/applicant/img50.png" },
      { type: "img", value: "/quiz-images/applicant/img51.png" },
    ],
    answer: 2,
  },
  {
    id: 25,
    question: [
      { type: "img", value: "/quiz-images/applicant/img52.png" },
      { type: "txt", value: "ni hisoblang." },
    ],
    options: [
      { type: "img", value: "/quiz-images/applicant/img53.png" },
      { type: "img", value: "/quiz-images/applicant/img54.png" },
      { type: "img", value: "/quiz-images/applicant/img55.png" },
      { type: "img", value: "/quiz-images/applicant/img56.png" },
    ],
    answer: 2,
  },
  {
    id: 26,
    question: [
      { type: "img", value: "/quiz-images/applicant/img57.png" },
      { type: "txt", value: "yig’indining yarmini hisoblang." },
    ],
    options: [
      { type: "img", value: "/quiz-images/applicant/img58.png" },
      { type: "img", value: "/quiz-images/applicant/img59.png" },
      { type: "img", value: "/quiz-images/applicant/img60.png" },
      { type: "txt", value: "A va C javoblar to’g’ri" },
    ],
    answer: 3,
  },
  {
    id: 27,
    question: [
      { type: "img", value: "/quiz-images/applicant/img61.png" },
      { type: "txt", value: "ni hisoblang." },
    ],
    options: [
      { type: "img", value: "/quiz-images/applicant/img62.png" },
      { type: "img", value: "/quiz-images/applicant/img63.png" },
      { type: "img", value: "/quiz-images/applicant/img64.png" },
      { type: "img", value: "/quiz-images/applicant/img65.png" },
    ],
    answer: 0,
  },
  {
    id: 28,
    question: [
      { type: "img", value: "/quiz-images/applicant/img66.png" },
      { type: "txt", value: "ni hisoblang." },
    ],
    options: [
      { type: "txt", value: "6" },
      { type: "txt", value: "2" },
      { type: "txt", value: "4" },
      { type: "txt", value: "3" },
    ],
    answer: 0,
  },
  {
    id: 29,
    question: [{ type: "img", value: "/quiz-images/applicant/img67.png" }],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "0" },
      { type: "img", value: "/quiz-images/applicant/img68.png" },
    ],
    answer: 2,
  },
  {
    id: 30,
    question: [
      { type: "img", value: "/quiz-images/applicant/img69.png" },
      { type: "txt", value: "ni hisoblang?" },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "img", value: "/quiz-images/applicant/img70.png" },
    ],
    answer: 1,
  },
];

const ixtisoslashtirilganMaktablar3Sinf = [
  {
    id: 0,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Hisoblang: 27 * 38 + 38 * 73",
      },
    ],
    options: [
      {
        type: "txt",
        value: "2774",
      },
      {
        type: "txt",
        value: "2106",
      },
      {
        type: "txt",
        value: "3800",
      },
      {
        type: "txt",
        value: "3756",
      },
    ],
  },
  {
    id: 1,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Hisoblang: 696 / 6 − 10",
      },
    ],
    options: [
      {
        type: "txt",
        value: "106",
      },
      {
        type: "txt",
        value: "96",
      },
      {
        type: "txt",
        value: "117",
      },
      {
        type: "txt",
        value: "116",
      },
    ],
  },
  {
    id: 2,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Tenglamani yeching. X − 360 / 3 = 80",
      },
    ],
    options: [
      {
        type: "txt",
        value: "80",
      },
      {
        type: "txt",
        value: "120",
      },
      {
        type: "txt",
        value: "200",
      },
      {
        type: "txt",
        value: "100",
      },
    ],
  },
  {
    id: 3,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Hisoblang: 3662 * 29",
      },
    ],
    options: [
      {
        type: "txt",
        value: "106198",
      },
      {
        type: "txt",
        value: "16198",
      },
      {
        type: "txt",
        value: "6198",
      },
      {
        type: "txt",
        value: "26198",
      },
    ],
  },
  {
    id: 4,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "Bir million to’qson olti ming bir yuz to’qqiz” sonini raqamlarda ifodalang",
      },
    ],
    options: [
      {
        type: "txt",
        value: "1960109",
      },
      {
        type: "txt",
        value: "1096109",
      },
      {
        type: "txt",
        value: "19601009",
      },
      {
        type: "txt",
        value: "10096109",
      },
    ],
  },
  {
    id: 5,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Hisoblang: 4305 / 35",
      },
    ],
    options: [
      {
        type: "txt",
        value: "223",
      },
      {
        type: "txt",
        value: "123",
      },
      {
        type: "txt",
        value: "202",
      },
      {
        type: "txt",
        value: "203",
      },
    ],
  },
  {
    id: 6,
    answer: 0,
    question: [
      {
        type: "txt",
        value:
          "2 * 8 < 258 tengsizlik o’rinli bo’lishi uchun yulduzcha o’rniga quydagi raqamlardan qaysi birini qo’yish mumkin emas?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "7",
      },
      {
        type: "txt",
        value: "1",
      },
      {
        type: "txt",
        value: "4",
      },
      {
        type: "txt",
        value: "3",
      },
      {
        type: "txt",
        value: "2",
      },
    ],
  },
  {
    id: 7,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "𝐇𝐢𝐬𝐨𝐛𝐥𝐚𝐧𝐠: 40 / 20 + 50 / 10",
      },
    ],
    options: [
      {
        type: "txt",
        value: "5",
      },
      {
        type: "txt",
        value: "7",
      },
      {
        type: "txt",
        value: "10",
      },
      {
        type: "txt",
        value: "8",
      },
    ],
  },
  {
    id: 8,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "Agar raqamlari yondagi kabi blegilansa, unda 6147 ni qaysi biriga to’g’rib keladi?",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/3-sinf/img1.png",
      },
    ],
    options: [
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/3-sinf/2.png",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/3-sinf/3.png",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/3-sinf/4.png",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/3-sinf/5.png",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/3-sinf/6.png",
      },
    ],
  },
  {
    id: 10,
    answer: 3,
    question: [
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/3-sinf/img2.png",
      },
      {
        type: "txt",
        value:
          "Yuqoridagi 5 ta tokchali kutubxona javonida yuqoridan pastgacha ketma-ket 1 dan boshlab toq sonlarga mutanosib qilib kitoblar joylangan bo’lsa kutubxona javonida jami nechta kitob bor?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "15",
      },
      {
        type: "txt",
        value: "14",
      },
      {
        type: "txt",
        value: "16",
      },
      {
        type: "txt",
        value: "25",
      },
      {
        type: "txt",
        value: "6",
      },
    ],
  },
  {
    id: 11,
    answer: 2,
    question: [
      {
        type: "txt",
        value:
          "Jorj bir xil raqamli paypoqlarini juftlab chiqmoqda. Unda nechta juft paypoq hosil bo`ladi?",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/3-sinf/img3.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "3",
      },
      {
        type: "txt",
        value: "4",
      },
      {
        type: "txt",
        value: "5",
      },
      {
        type: "txt",
        value: "6",
      },
      {
        type: "txt",
        value: "8",
      },
    ],
  },
  {
    id: 12,
    answer: 0,
    question: [
      {
        type: "txt",
        value:
          "Maya ismli asalari to`rtburchak ichidagi, ammo uchburchakdan tashqarida joylashgan gullardan gul changini yig`moqda. Maya nechta guldan gul changini yig`di?",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/3-sinf/img4.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "9",
      },
      {
        type: "txt",
        value: "10",
      },
      {
        type: "txt",
        value: "13",
      },
      {
        type: "txt",
        value: "17",
      },
      {
        type: "txt",
        value: "20",
      },
    ],
  },
  {
    id: 13,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "Zooparkka kirish uchun 12 ta bola navbatda turibdi. Lyusi eshikdan boshlab sanaganda 7-o`rinda turibdi, Kim esa oxiridan boshlab sanaganda 2-o`rinda turibdi. Lyusi va Kimning orasida navbatda nechta bola bor?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "2",
      },
      {
        type: "txt",
        value: "3",
      },
      {
        type: "txt",
        value: "4",
      },
      {
        type: "txt",
        value: "5",
      },
      {
        type: "txt",
        value: "6",
      },
    ],
  },
  {
    id: 14,
    answer: 2,
    question: [
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/3-sinf/img5.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "5tsent",
      },
      {
        type: "txt",
        value: "6tsent",
      },
      {
        type: "txt",
        value: "7tsent",
      },
      {
        type: "txt",
        value: "8tsent",
      },
    ],
  },
];

const ixtisoslashtirilganMaktablar4Sinf = [
  {
    id: 0,
    answer: 3,
    question: [
      {
        type: "txt",
        value:
          "Sonli ketma-ketlikning keyingi hadini toping. 4, 7, 13, 22, 34, 49, _____",
      },
    ],
    options: [
      {
        type: "txt",
        value: "64",
      },
      {
        type: "txt",
        value: "65",
      },
      {
        type: "txt",
        value: "66",
      },
      {
        type: "txt",
        value: "67",
      },
      {
        type: "txt",
        value: "68",
      },
    ],
  },
  {
    id: 1,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "Sotuvchi 60 kilogramm guruchning 4/5 qismini uch kilogrammlik qoplarga joyladi. Agar sotuvchi har bir qopni 9000 so`mdan sotsa qancha daromad oladi?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "156000",
      },
      {
        type: "txt",
        value: "144000",
      },
      {
        type: "txt",
        value: "624000",
      },
      {
        type: "txt",
        value: "180000",
      },
      {
        type: "txt",
        value: "240000",
      },
    ],
  },
  {
    id: 2,
    answer: 2,
    question: [
      {
        type: "txt",
        value:
          "* o’rniga qanday raqam qo’yilsa, to’g’ri tenglik hosil bo’ladi? \n 59181 - 459*3 = 13258",
      },
    ],
    options: [
      {
        type: "txt",
        value: "4",
      },
      {
        type: "txt",
        value: "3",
      },
      {
        type: "txt",
        value: "2",
      },
      {
        type: "txt",
        value: "1",
      },
      {
        type: "txt",
        value: "5",
      },
    ],
  },
  {
    id: 3,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "So’roq belgisi o’rnida turishi zarur bo’lgan sonlarning ko’paytmasini toping.",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/4-sinf/img1.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "67",
      },
      {
        type: "txt",
        value: "702",
      },
      {
        type: "txt",
        value: "663",
      },
      {
        type: "txt",
        value: "286",
      },
      {
        type: "txt",
        value: "207",
      },
    ],
  },
  {
    id: 4,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "Asliddin 7700 ni hosil qilish uchun bir nechta 7 raqamini qo’shib chiqdi. \n 7 + 7 + 7 + ... + 7 = 7700 \n Agar Asliddin qo’shib chiqqan 7 raqamlari sonidan besh marta kam sondagi 4 raqamlarini qo’shib chiqsa, yig’indi nechaga teng bo’ladi?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "220",
      },
      {
        type: "txt",
        value: "880",
      },
      {
        type: "txt",
        value: "660",
      },
      {
        type: "txt",
        value: "22000",
      },
      {
        type: "txt",
        value: "8800",
      },
    ],
  },
  {
    id: 5,
    answer: 3,
    question: [
      {
        type: "txt",
        value:
          "Tikuvchi matoning 5/15 qismidan yubka, 6/15 qismidan ko’ylak, 3/15 qismidan jaket , qolganidan esa shim tikdi. Tikuvchi qaysi kiyim uchun eng kam mato ishlatgan?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "Yubka",
      },
      {
        type: "txt",
        value: "Ko’ylak",
      },
      {
        type: "txt",
        value: "Jaket",
      },
      {
        type: "txt",
        value: "Shim",
      },
      {
        type: "txt",
        value: "Jaket va Shim",
      },
    ],
  },
  {
    id: 6,
    answer: 2,
    question: [
      {
        type: "txt",
        value:
          "Javoblardan qaysi biri quyida berilgan ifodalarning hech biriga teng emas?",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/4-sinf/img2.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "(4 * 1000) + (4 * 500) + (4 * 40)",
      },
      {
        type: "txt",
        value: "(7 * 4000) + (7 * 700) + (7 * 5)",
      },
      {
        type: "txt",
        value: "(8 * 5000) + (8 * 900)",
      },
      {
        type: "txt",
        value: "(2 * 7000) + (2 * 900) + (2 * 80) (2 * 1)",
      },
      {
        type: "txt",
        value: "(11 * 1000) + (11 * 1)",
      },
    ],
  },
  {
    id: 7,
    answer: 0,
    question: [
      {
        type: "txt",
        value:
          "Dehqon bozorga 480 kg kartoshka olib keldi. U har biri 8 kg dan bo’lgan 15 qop kartoshka sotdi. Dehqon olib kelgan kartoshkalarining qancha qismini sotgan?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "1/4",
      },
      {
        type: "txt",
        value: "2/5",
      },
      {
        type: "txt",
        value: "1/6",
      },
      {
        type: "txt",
        value: "3/8",
      },
      {
        type: "txt",
        value: "1/3",
      },
    ],
  },
  {
    id: 8,
    answer: 0,
    question: [
      {
        type: "txt",
        value:
          "Abror katakli daftarga 4 ta to’gri chiziqni chizdi. Har bir katakning bo’yi va eni 1sm.",
      },
      {
        type: "txt",
        value: "Birinchi chiziq (1;1) dan boshlanadi va (1;7) da tugaydi.",
      },
      {
        type: "txt",
        value: "Ikkinchi chiziq (1;7) dan boshlanadi va (5;7) da tugaydi.",
      },
      {
        type: "txt",
        value:
          "Uchinchi chiziq (5;7) dan boshlanadi va (5;1) da tugaydi. To’rtinchi chiziq esa hosil bo’lgan siniq chiziqni to’g’ri to’rtburchakka to’ldirdi. Abror chizgan figuraning yuzini toping. (sm^2)",
      },
    ],
    options: [
      {
        type: "txt",
        value: "35",
      },
      {
        type: "txt",
        value: "24",
      },
      {
        type: "txt",
        value: "30",
      },
      {
        type: "txt",
        value: "28",
      },
      {
        type: "txt",
        value: "20",
      },
    ],
  },
  {
    id: 9,
    answer: 3,
    question: [
      {
        type: "txt",
        value:
          "Agar 6 soat 21 daqiqa uxlagan inson tong vaqti bilan 07:00 da uyg'ongan bo'lsa, u nechada uxlagan?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "01:39",
      },
      {
        type: "txt",
        value: "23:52",
      },
      {
        type: "txt",
        value: "23:11",
      },
      {
        type: "txt",
        value: "00:39",
      },
      {
        type: "txt",
        value: "00:11",
      },
    ],
  },
  {
    id: 10,
    answer: 3,
    question: [
      {
        type: "txt",
        value:
          "Quyidagi taqqoslashga ko'ra, o’ngdagi katakka shu tengsizlikni qanoatlantiradigan eng katta son yozildi. Quyidagilardan nechtasida shu songa noto’g’ri ta’rif berilgan. ",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/4-sinf/img3.png",
      },
      {
        type: "txt",
        value: "O’nlar xonasigacha yaxlitlansa 9880 ga teng.",
      },
      {
        type: "txt",
        value: "9 * 1000 + 8 * 100 + 7 * 10 + 6 * 1",
      },
      {
        type: "txt",
        value: "Raqamlari har xil bo'lgan eng katta 4 xonali natural son.",
      },
      {
        type: "txt",
        value: "Birlar va yuzlar xonasidagi raqamlar bir xil.",
      },
      {
        type: "txt",
        value: "Juft son.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "1",
      },
      {
        type: "txt",
        value: "2",
      },
      {
        type: "txt",
        value: "3",
      },
      {
        type: "txt",
        value: "4",
      },
      {
        type: "txt",
        value: "5",
      },
    ],
  },
  {
    id: 11,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "Nodir toq raqamlarni yaxshi ko`radi. U telefoniga o`zi yaxshi ko`rgan raqamlardan foydalanib, turli raqamli uch xonali parol qo`ymoqchi. Nodir buni necha xil usulda qilishi mumkin? ",
      },
    ],
    options: [
      {
        type: "txt",
        value: "12",
      },
      {
        type: "txt",
        value: "20",
      },
      {
        type: "txt",
        value: "60",
      },
      {
        type: "txt",
        value: "75",
      },
      {
        type: "txt",
        value: "125",
      },
    ],
  },
  {
    id: 12,
    answer: 3,
    question: [
      {
        type: "txt",
        value:
          "Yondagi keltirilgan  rasmga ko’ra, bo’yalgan qism yuzini toping. (sm^2)",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/4-sinf/img4.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "260",
      },
      {
        type: "txt",
        value: "308",
      },
      {
        type: "txt",
        value: "490",
      },
      {
        type: "txt",
        value: "608",
      },
      {
        type: "txt",
        value: "460",
      },
    ],
  },
  {
    id: 13,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Muvozanatga erishish uchun X o‘rniga nechta Δ  bo`lishi kerak?",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/4-sinf/img5.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "3",
      },
      {
        type: "txt",
        value: "4",
      },
      {
        type: "txt",
        value: "5",
      },
      {
        type: "txt",
        value: "6",
      },
      {
        type: "txt",
        value: "2",
      },
    ],
  },
  {
    id: 14,
    answer: 4,
    question: [
      {
        type: "txt",
        value:
          "Buzilgan ikkita soatning birinchisi har 4 daqiqada 1 daqiqa, ikkinchisi esa har 5 daqiqada 1 daqiqa orqada qoladi. 3 soatdan keyin bir vaqtning o'zida to’g’rilangan ikkita soat orasidagi farq necha daqiqa bo'ladi?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "8",
      },
      {
        type: "txt",
        value: "3",
      },
      {
        type: "txt",
        value: "10",
      },
      {
        type: "txt",
        value: "12",
      },
      {
        type: "txt",
        value: "9",
      },
    ],
  },
  {
    id: 15,
    answer: 0,
    question: [
      {
        type: "txt",
        value:
          "Maftuna bir soatda 9 ta yostiqcha tikadi. Madina esa bir soatda undan 6 ta kam yostiqcha tikadi. Maftuna va Madina har biri bir kunda 9 soat ishlaydi. Ular 864 ta yostiqchani necha kunda tikib bo’lishadi?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "8",
      },
      {
        type: "txt",
        value: "7",
      },
      {
        type: "txt",
        value: "9",
      },
      {
        type: "txt",
        value: "5",
      },
      {
        type: "txt",
        value: "6",
      },
    ],
  },
  {
    id: 16,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "X ning qiymatini toping.",
      },
      {
        type: "txt",
        value: "88 - 44 / 11 + 22 = 111 - X",
      },
    ],
    options: [
      {
        type: "txt",
        value: "4",
      },
      {
        type: "txt",
        value: "5",
      },
      {
        type: "txt",
        value: "6",
      },
      {
        type: "txt",
        value: "84",
      },
      {
        type: "txt",
        value: "85",
      },
    ],
  },
  {
    id: 17,
    answer: 3,
    question: [
      {
        type: "txt",
        value:
          "Yo’l ta’mirlovchilar uzunligi 20 km bo’lgan yo’lni uch kunda ta’mirlashdi. Ular birinchi kuni butun yo’lning 2/5 qismini, ikkinchi kuni birinchi kundagidan 1 km 400 m kam yo’l ta’mirlashdi. Ular uchinchi kuni qancha yo’lni ta’mirlashgan?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "6 km 400 m",
      },
      {
        type: "txt",
        value: "6 km",
      },
      {
        type: "txt",
        value: "5 km 500 m",
      },
      {
        type: "txt",
        value: "5 km 400 m",
      },
      {
        type: "txt",
        value: "5 km 600 m",
      },
    ],
  },
  {
    id: 18,
    answer: 4,
    question: [
      {
        type: "txt",
        value: "Yondagi figura nechta kichik kublardan tashkil topgan?",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/4-sinf/img6.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "58",
      },
      {
        type: "txt",
        value: "57",
      },
      {
        type: "txt",
        value: "56",
      },
      {
        type: "txt",
        value: "55",
      },
      {
        type: "txt",
        value: "54",
      },
    ],
  },
  {
    id: 19,
    answer: 2,
    question: [
      {
        type: "txt",
        value:
          "Sayyodda 96000 so’m pul bor edi. U pulining 5/8 qismiga banan va qolganiga esa qulupnay sotib oldi. Agar 1 kg bananning narxi 12000 so’m va 1 kg qulupnayning narxi 9000 so’m bo’lsa, u necha kilogramm banan va qulupnay sotib olgan?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "8",
      },
      {
        type: "txt",
        value: "10",
      },
      {
        type: "txt",
        value: "9",
      },
      {
        type: "txt",
        value: "12",
      },
      {
        type: "txt",
        value: "11",
      },
    ],
  },
  {
    id: 20,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Quyidagi sonlardan qaysi biri 13 ga bo’linmaydi?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "68",
      },
      {
        type: "txt",
        value: "78",
      },
      {
        type: "txt",
        value: "104",
      },
      {
        type: "txt",
        value: "91",
      },
      {
        type: "txt",
        value: "52",
      },
    ],
  },
  {
    id: 21,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Yondagi shaklning qanday qismi bo’yalgan.",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/4-sinf/img7.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "14/24",
      },
      {
        type: "txt",
        value: "7/12",
      },
      {
        type: "txt",
        value: "3/4",
      },
      {
        type: "txt",
        value: "2/3",
      },
      {
        type: "txt",
        value: "1/4",
      },
    ],
  },
  {
    id: 22,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Kvadrat va uchburchak nechaga teng ekanligini toping.",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/4-sinf/img8.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "6 va 3",
      },
      {
        type: "txt",
        value: "4 va 6",
      },
      {
        type: "txt",
        value: "3 va 6",
      },
      {
        type: "txt",
        value: "5 va 3",
      },
      {
        type: "txt",
        value: "4 va 5",
      },
    ],
  },
  {
    id: 23,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "Yonda karra jadvali berilgan bo’lib, unda ayrim sonlar tushirib qoldirilgan. Uchburchakning qiymatini hisoblang.",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/4-sinf/img9.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "35",
      },
      {
        type: "txt",
        value: "42",
      },
      {
        type: "txt",
        value: "45",
      },
      {
        type: "txt",
        value: "48",
      },
      {
        type: "txt",
        value: "49",
      },
    ],
  },
  {
    id: 24,
    answer: 3,
    question: [
      {
        type: "txt",
        value:
          "Quyidagi taqqoslashlardan qaysi biri son nurida ko‘rsatilgan kasr uchun noto‘g‘ri? ",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/4-sinf/img10.png",
      },
    ],
    options: [
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/4-sinf/1.png",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/4-sinf/2.png",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/4-sinf/3.png",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/4-sinf/4.png",
      },
    ],
  },
  {
    id: 25,
    answer: 2,
    question: [
      {
        type: "txt",
        value:
          "Akmal, Suxrob, Javohir va Ahmad yaqin do’st. Akmal Suxrobdan 5 yosh va Javohirdan esa 2 yosh katta. Ahmad esa 40 yosh va Javohirdan 7 yosh katta bo’lsa, to’rtta do’stning yoshlari yig’indisini toping.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "144",
      },
      {
        type: "txt",
        value: "143",
      },
      {
        type: "txt",
        value: "138",
      },
      {
        type: "txt",
        value: "150",
      },
      {
        type: "txt",
        value: "148",
      },
    ],
  },
  {
    id: 26,
    answer: 2,
    question: [
      {
        type: "txt",
        value:
          "Sut sog`uvchi har kuni sog`adigan sutining yarmini sutni qayta ishlash korxonasiga jo‘natadi. Qolgan yarmining 3/8 qismi bilan pishloq, qolgan sutdan esa sariyog‘ tayyorlaydi. Agar bu sog`uvchi har kuni 192 litr sut ishlab chiqarsa, sariyog‘ uchun necha litr sut sarflaydi?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "12",
      },
      {
        type: "txt",
        value: "36",
      },
      {
        type: "txt",
        value: "60",
      },
      {
        type: "txt",
        value: "96",
      },
      {
        type: "txt",
        value: "72",
      },
    ],
  },
  {
    id: 27,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "Yondagi diagrammalarda bo’yoqchilar birinchi va ikkinchi kunda devorning qancha qismini bo’yagani tasvirlangan. Ishchilar shu ikki kun davomida devorning qancha qismini bo’yagan?",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/4-sinf/img11.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "8/4",
      },
      {
        type: "txt",
        value: "4/8",
      },
      {
        type: "txt",
        value: "5/8",
      },
      {
        type: "txt",
        value: "4/16",
      },
      {
        type: "txt",
        value: "3/8",
      },
    ],
  },
  {
    id: 28,
    answer: 0,
    question: [
      {
        type: "txt",
        value:
          "Uch yosh farq bilan tug'ilgan 3 aka-ukaning yoshlari yig’indisi eng kamida necha bo’lishi mumkin?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "11",
      },
      {
        type: "txt",
        value: "14",
      },
      {
        type: "txt",
        value: "12",
      },
      {
        type: "txt",
        value: "15",
      },
      {
        type: "txt",
        value: "10",
      },
    ],
  },
  {
    id: 29,
    answer: 0,
    question: [
      {
        type: "txt",
        value:
          "Bir duradgorda ikki xil uzunlikdagi, uzun va kalta jami 36 ta yog’och bor. Uzun yog’ochlarning  har biri beshta teng bo’lakka, kalta yog’ochlarning har biri esa to'rta teng bo’lakka kesildi. Agar uzun yog’ochlarni kesishdan hosil bo’lgan bo’laklar soni kalta yog’ochlarni kesishdan hosil bo’lgan bo’laklar soniga teng bo’lsa, bu durodgorda nechta uzun yog’och bo’lgan?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "16",
      },
      {
        type: "txt",
        value: "20",
      },
      {
        type: "txt",
        value: "80",
      },
      {
        type: "txt",
        value: "24",
      },
      {
        type: "txt",
        value: "12",
      },
    ],
  },
];

const ixtisoslashtirilganMaktablar5Sinf = [
  {
    id: 0,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Hisoblang: 123 * 45- 123 * 35 + 2 * 17^2 / 34",
      },
    ],
    options: [
      {
        type: "txt",
        value: "1247",
      },
      {
        type: "txt",
        value: "1347",
      },
      {
        type: "txt",
        value: "1230",
      },
      {
        type: "txt",
        value: "1330",
      },
    ],
  },
  {
    id: 1,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "777776+77776+7776+776+76 yig‘indining qiymatini 7 ga boʻlinganidagi qoldiqni toping.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "3",
      },
      {
        type: "txt",
        value: "2",
      },
      {
        type: "txt",
        value: "1",
      },
      {
        type: "txt",
        value: "5",
      },
    ],
  },
  {
    id: 2,
    answer: 0,
    question: [
      {
        type: "txt",
        value:
          "Natural sonlar qatorida 82 dan katta boʻlmagan barcha juft sonlar yig‘indisini toping.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "1722",
      },
      {
        type: "txt",
        value: "1800",
      },
      {
        type: "txt",
        value: "3403",
      },
      {
        type: "txt",
        value: "3500",
      },
    ],
  },
  {
    id: 3,
    answer: 3,
    question: [
      {
        type: "txt",
        value:
          "20 minut oldinga ildamlab ketgan soat hozir 9∶15 ni koʻrsatyapti. Ayni shu paytda 25 minut orqada qolgan soat nechani koʻrsatayotgan boʻladi?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "8∶55",
      },
      {
        type: "txt",
        value: "8∶35",
      },
      {
        type: "txt",
        value: "9∶05",
      },
      {
        type: "txt",
        value: "8∶30",
      },
    ],
  },
  {
    id: 4,
    answer: 2,
    question: [
      {
        type: "txt",
        value:
          "Bir-biriga qarab harakatlanayotgan avtomobillar orasidagi masofa 450 km . Ulardan birinchising tezligi ikkinchisidan 2 marta ko‘proq. Agar avtomobillar 2 soatdan keyin uchrashgan bo‘lsalar, birinchisining tezligini toping.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "70",
      },
      {
        type: "txt",
        value: "75",
      },
      {
        type: "txt",
        value: "150",
      },
      {
        type: "txt",
        value: "140",
      },
    ],
  },
  {
    id: 5,
    answer: 2,
    question: [
      {
        type: "txt",
        value:
          "Evkalipt daraxtining bo‘yi birinchi 5 yilda 12 m balandlikka yetadi. 35 yilda 48 metrga yetadi. Birinchi 25 yilda evkalipt daraxti necha cm ga o‘sadi?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "3400 cm",
      },
      {
        type: "txt",
        value: "2500 cm",
      },
      {
        type: "txt",
        value: "3600 cm",
      },
      {
        type: "txt",
        value: "3000 cm",
      },
    ],
  },
  {
    id: 6,
    answer: 0,
    question: [
      {
        type: "txt",
        value:
          "2022 - yil 20 - mart soat 12∶00 da Halimning tugʻilganiga 100 sutka boʻldi. Halim qaysi kuni tug‘ilgan?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "10 - dekabr",
      },
      {
        type: "txt",
        value: "20 - dekabr",
      },
      {
        type: "txt",
        value: "22 - dekabr",
      },
      {
        type: "txt",
        value: "12 - dekabr",
      },
    ],
  },
  {
    id: 7,
    answer: 2,
    question: [
      {
        type: "txt",
        value:
          "Olma narxi banan narxidan 4 marta arzon. 3 kg olma va 2 kg banan uchun 44000 soʻm toʻlangan boʻlsa, bananning narxini aniqlang.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "12000",
      },
      {
        type: "txt",
        value: "4000",
      },
      {
        type: "txt",
        value: "16000",
      },
      {
        type: "txt",
        value: "20000",
      },
    ],
  },
  {
    id: 8,
    answer: 2,
    question: [
      {
        type: "txt",
        value:
          "Qirralari uzunliklari 16 cm, 20 cm va 24 cm ga teng boʻlgan toʻgʻri burchakli parallelepiped shaklidagi taxtani qirrasi 4 cm boʻlgan nechta kubchaga ajratish mumkin?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "60",
      },
      {
        type: "txt",
        value: "40",
      },
      {
        type: "txt",
        value: "120",
      },
      {
        type: "txt",
        value: "90",
      },
    ],
  },
  {
    id: 9,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "Zavodda birinchi kun 568 ta , ikkinchi kun birinchi kundagidan 87 ta kam , uchinchi kun esa ikkinchi kundagidan 65 ta ortiq detal tayyorlandi. Shu uch kunda zavodda jami nechta detal tayyorlangan?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "1585",
      },
      {
        type: "txt",
        value: "1595",
      },
      {
        type: "txt",
        value: "1605",
      },
      {
        type: "txt",
        value: "1575",
      },
    ],
  },
  {
    id: 10,
    answer: 3,
    question: [
      {
        type: "txt",
        value: "Rasmdagi berilgan shakl perimetrini toping:",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/5-sinf/img1.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "𝑃 = 24 𝑐𝑚^2",
      },
      {
        type: "txt",
        value: "𝑃 = 28 𝑐𝑚^2",
      },
      {
        type: "txt",
        value: "𝑃 = 34 𝑐𝑚^2",
      },
      {
        type: "txt",
        value: "𝑃 = 38 𝑐𝑚^2",
      },
    ],
  },
  {
    id: 11,
    answer: 3,
    question: [
      {
        type: "txt",
        value: "Tenglamani yeching:\t2,25𝑥 + 7,23 + 7,75𝑥 = 430,23 − 223",
      },
    ],
    options: [
      {
        type: "txt",
        value: "13",
      },
      {
        type: "txt",
        value: "19",
      },
      {
        type: "txt",
        value: "21",
      },
      {
        type: "txt",
        value: "20",
      },
    ],
  },
  {
    id: 12,
    answer: 0,
    question: [
      {
        type: "txt",
        value:
          "Perimetri 20 m kvadrat shaklidagi xonaga boʻyi 4 m, eni esa 3 m boʻlgan toʻg‘ri toʻrtburchak shaklidagi gilam toʻshaldi. Bu xonaning necha kvadrat metr yuzasi gilam bilan qoplanmaydi?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "13 m^2",
      },
      {
        type: "txt",
        value: "8 m^2",
      },
      {
        type: "txt",
        value: "6 m^2",
      },
      {
        type: "txt",
        value: "12 m^2",
      },
    ],
  },
  {
    id: 13,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "Agar it 27 sekundda bolani quvib yetgan bo‘lsa, uning tezligi qancha bo‘lgan?",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/5-sinf/img2.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "200 m/min",
      },
      {
        type: "txt",
        value: "300 m/min",
      },
      {
        type: "txt",
        value: "350 m/min",
      },
      {
        type: "txt",
        value: "100 m/min",
      },
    ],
  },
  {
    id: 14,
    answer: 0,
    question: [
      {
        type: "txt",
        value:
          "Berilgan rasmning nechta joyida ikkita o‘quvchi chap qo‘llari bilan bir-birini ushlab turibdi?",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/5-sinf/img3.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "1",
      },
      {
        type: "txt",
        value: "2",
      },
      {
        type: "txt",
        value: "3",
      },
      {
        type: "txt",
        value: "4",
      },
    ],
  },
  {
    id: 15,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "X va Y nuqtalari orasida bitta poyezd yuradigan yo‘l mavjud.",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/5-sinf/img4.png",
      },
    ],
    options: [
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/5-sinf/1.png",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/5-sinf/2.png",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/5-sinf/3.png",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/5-sinf/4.png",
      },
    ],
  },
  {
    id: 16,
    answer: 3,
    question: [
      {
        type: "txt",
        value:
          "Ali, Bobur, Komil, Diyor va Elyor aylana stol atrofida o‘tirishibdi. Ali Boburning yonida emas, Diyor Elyorning yonida va Bobur esa Diyorning yonida emas. Komilning yonida kim o‘tiribdi?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "Elyor va Ali",
      },
      {
        type: "txt",
        value: "Bobur va Diyor",
      },
      {
        type: "txt",
        value: "Diyor va Elyor",
      },
      {
        type: "txt",
        value: "Ali va Bobur",
      },
    ],
  },
  {
    id: 17,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "Silindr atrofida santimetrli o‘lchash tasmasi o‘ralgan. So‘roq belgisi bilan ko‘rsatilgan joyda qaysi son bo‘lishi kerak?",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/5-sinf/img5.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "60",
      },
      {
        type: "txt",
        value: "69",
      },
      {
        type: "txt",
        value: "53",
      },
      {
        type: "txt",
        value: "77",
      },
    ],
  },
  {
    id: 18,
    answer: 0,
    question: [
      {
        type: "txt",
        value:
          "Qutida 20 ta olma va 20 ta nok bor edi. Komila tasodifiy tarzda qutidan 21 dona mevani oldi, qolganini esa Laylo oldi. Quyidagi gaplardan qaysi biri doimo to‘g‘ri?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "Laylo qancha olma olgan bo‘lsa, Komila ham shuncha nok oldi.",
      },
      {
        type: "txt",
        value: "Komila qancha nok olgan bo‘lsa, shuncha olma oldi.",
      },
      {
        type: "txt",
        value: "Laylo qancha olma olgan bo‘lsa, Komila ham shuncha olma oldi.",
      },
      {
        type: "txt",
        value: "Komila eng kamida bitta nok oldi.",
      },
      {
        type: "txt",
        value: "Laylo qancha nok olgan bo‘lsa, Komila ham shuncha nok oldi.",
      },
    ],
  },
];

const ixtisoslashtirilganMaktablar6Sinf = [
  {
    id: 0,
    answer: 3,
    question: [
      {
        type: "txt",
        value: "Hisoblang: (14 - 12(7/16)) + (26 - 23(9/16))",
      },
    ],
    options: [
      {
        type: "txt",
        value: "3",
      },
      {
        type: "txt",
        value: "2(7/16)",
      },
      {
        type: "txt",
        value: "3(9/16)",
      },
      {
        type: "txt",
        value: "4",
      },
    ],
  },
  {
    id: 1,
    answer: 0,
    question: [
      {
        type: "txt",
        value:
          "O‘lchov birliklari va ularga mos qiymatlar o‘rtasidagi moslikni o‘rnating:",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/6-sinf/img1.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "1) A , 2) E , 3) C , 4) B",
      },
      {
        type: "txt",
        value: "1) A , 2) E , 3) C , 4) E",
      },
      {
        type: "txt",
        value: "1) A , 2) D , 3) C , 4) B",
      },
      {
        type: "txt",
        value: "1) D , 2) A , 3) F , 4) B",
      },
    ],
  },
  {
    id: 2,
    answer: 2,
    question: [
      {
        type: "txt",
        value:
          "Noma’lum sonni 11 ga bo‘lganda to‘liqsiz bo‘linma ham qoldiq ham 5 chiqdi. Noma’lum sonni toping.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "65",
      },
      {
        type: "txt",
        value: "55",
      },
      {
        type: "txt",
        value: "60",
      },
      {
        type: "txt",
        value: "50",
      },
    ],
  },
  {
    id: 3,
    answer: 0,
    question: [
      {
        type: "txt",
        value:
          "Rasmda ob-havo grafigi keltirilgan. Unda ob-havo va kelgusi besh kun uchun maksimal harorat ko‘rsatilgan. Quyidagilardan qaysi biri maksimal haroratning tegishli grafigini aks ettirgan?",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/6-sinf/img2.png",
      },
    ],
    options: [
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/6-sinf/1.png",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/6-sinf/2.png",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/6-sinf/3.png",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/6-sinf/4.png",
      },
    ],
  },
  {
    id: 4,
    answer: 2,
    question: [
      {
        type: "txt",
        value:
          "Oltita to‘g‘ri to‘rtburchaklar rasmda ko‘rsatilganidek joylashtirilgan. Chapdan yuqoridagi to‘g‘ri to‘rtburchakning balandligi 6 cm ga teng. To‘g‘ri to‘rtburchaklar ichidagi sonlar ularning cm2 dagi yuzlarini bildiradi. O‘ngdan pastdagi to‘g‘ri to‘rtburchakning perimetrini toping .",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/6-sinf/img3.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "34 cm",
      },
      {
        type: "txt",
        value: "24 cm",
      },
      {
        type: "txt",
        value: "22 cm",
      },
      {
        type: "txt",
        value: "32 cm",
      },
    ],
  },
  {
    id: 5,
    answer: 0,
    question: [
      {
        type: "txt",
        value:
          "Quyidagi tasdiqlar ichidan to‘g‘ri yoki noto‘g‘riligi ketma-ketligi to‘g‘ri ko‘rsatilgan javob variantini aniqlang.",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/6-sinf/img4.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "I- yo‘q, II- ha, III-yo‘q, IV-ha",
      },
      {
        type: "txt",
        value: "I-ha, II-ha, III-yo‘q, IV-ha",
      },
      {
        type: "txt",
        value: "I-ha, II-yo‘q, III-ha, IV-ha",
      },
      {
        type: "txt",
        value: "I-yo‘q, II-yo‘q, III-ha, IV-yo‘q",
      },
    ],
  },
  {
    id: 6,
    answer: 2,
    question: [
      {
        type: "txt",
        value:
          "Natural ko‘paytuvchilardan birini 10 % ga, ikkinchisini 20 % ga oshirib, uchinchisini 25 % ga kamaytirsak, ko‘paytma necha foizga o‘zgaradi?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "1% ga oshadi",
      },
      {
        type: "txt",
        value: "5% ga oshadi",
      },
      {
        type: "txt",
        value: "1% ga kamayadi",
      },
      {
        type: "txt",
        value: "5% ga kamayadi",
      },
    ],
  },
  {
    id: 7,
    answer: 3,
    question: [
      {
        type: "txt",
        value:
          "Uchta sonning o‘rta arifmetigi 10 ga, boshqa ikki sonning o‘rta arifmetigi esa 15 ga teng. Shu beshta sonning o‘rta arifmetigini toping.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "13",
      },
      {
        type: "txt",
        value: "15",
      },
      {
        type: "txt",
        value: "12,5",
      },
      {
        type: "txt",
        value: "12",
      },
    ],
  },
  {
    id: 8,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "Harorati 36° bo‘lgan 6 l suvga harorati 15° bo‘lgan suv qo‘shilganida aralashtirilgan suvning harorati 24° bo‘ldi. Necha litr suv qo‘shilgan?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "10",
      },
      {
        type: "txt",
        value: "8",
      },
      {
        type: "txt",
        value: "12",
      },
      {
        type: "txt",
        value: "9",
      },
    ],
  },
  {
    id: 9,
    answer: 2,
    question: [
      {
        type: "txt",
        value:
          "Oralaridagi masofa 280 km bo‘lgan A va B shaharlardan bir vaqtda ikki avtomobil yo‘lga chiqdi. Agar avtomobillar bir – biriga qarab yursa, ular 2 soatdan keyin uchrashadi. Agar ular bir yo‘nalishda yursa, A shahardan chiqqan avtomobil B shahardan chiqqan avtomobilni 14 soatda quvib yetadi. B shahardan chiqqan avtomobil tezligi necha km/h ga teng?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "140 km/h",
      },
      {
        type: "txt",
        value: "60 km/h",
      },
      {
        type: "txt",
        value: "80 km/h",
      },
      {
        type: "txt",
        value: "20 km/h",
      },
    ],
  },
  {
    id: 10,
    answer: 0,
    question: [
      {
        type: "txt",
        value:
          "A, B, C, D, E, F variantlardan quyidagi I, II, III savollarning har biriga mos javobni qo‘ying:",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/6-sinf/img5.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "I-D, II-F, III-A",
      },
      {
        type: "txt",
        value: "I-D, II-C, III-A",
      },
      {
        type: "txt",
        value: "I-C, II-B, III-A",
      },
      {
        type: "txt",
        value: "I-C, II-F, III-D",
      },
    ],
  },
  {
    id: 11,
    answer: 2,
    question: [
      {
        type: "txt",
        value:
          "ABCD va OPRS kvadratlarning yuzlari mos ravishda 324 cm2 va 196 cm^2 ga teng. Bo‘yalgan sohaning yuzini cm2 da toping.",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/6-sinf/img6.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "36",
      },
      {
        type: "txt",
        value: "64",
      },
      {
        type: "txt",
        value: "56",
      },
      {
        type: "txt",
        value: "48",
      },
    ],
  },
  {
    id: 12,
    answer: 0,
    question: [
      {
        type: "txt",
        value:
          "Jalol otaning bog‘i to‘g‘ri to‘rtburchak shaklida. Bu yerning o‘ng va chap tomonidan 28 m bo‘sh joy qoldirildi va oralari 5 m bo‘lgan kvadrat shaklidagi uylar qurildi. Agar Jalol ota uylarni yerning o‘ng va chap tomonidan 4 metr bo‘sh joy qoldirib qurganida avvalgisidan 5 ta ko‘p uy qurilgan bo‘lar edi. Bunga ko‘ra, bu uyning tomoni necha metrga teng?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "4,6 m",
      },
      {
        type: "txt",
        value: "5,6 m",
      },
      {
        type: "txt",
        value: "4,7 m",
      },
      {
        type: "txt",
        value: "4,5 m",
      },
    ],
  },
  {
    id: 13,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Tenglamani yeching:\t4𝑥 + 6,4 = (8,3 + 2,1) ∙ (14,9 − 8,3)",
      },
    ],
    options: [
      {
        type: "txt",
        value: "15,6",
      },
      {
        type: "txt",
        value: "15,56",
      },
      {
        type: "txt",
        value: "17,18",
      },
      {
        type: "txt",
        value: "17,78",
      },
    ],
  },
  {
    id: 14,
    answer: 3,
    question: [
      {
        type: "txt",
        value:
          "Isomiddin uzunligi 60 metr bo‘lgan metro eskalatorida jim turgan holatda pastdan yuqoriga 2 minutda ko‘tariladi. Keyin harakatlanayotgan eskalatorda o‘zi ham pastdan tepaga harakatlanib 1 minutda ko‘tarildi. Isomiddin harakatlanayotgan eskalatordagi tezligi bilan 180 metr masofani qancha vaqtda bosib o‘tadi?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "2.5 minut",
      },
      {
        type: "txt",
        value: "3 minut",
      },
      {
        type: "txt",
        value: "4 minut",
      },
      {
        type: "txt",
        value: "6 minut",
      },
    ],
  },
  {
    id: 15,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "Yig‘indining oxirgi raqamini toping:\n 1 * 3 * 5 * 7 * ... * 31 + 2 * 4 * 6 * ... * 32 = ... ?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "1",
      },
      {
        type: "txt",
        value: "5",
      },
      {
        type: "txt",
        value: "0",
      },
      {
        type: "txt",
        value: "3",
      },
    ],
  },
  {
    id: 16,
    answer: 3,
    question: [
      {
        type: "txt",
        value:
          "Bir xil o‘lchamli oynalardan uchta akvarium yasalgan. Qaysi akvariumga ko‘proq oyna ketgan.",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/6-sinf/img7.png",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/6-sinf/img8.png",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/6-sinf/img9.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "uchalasiga ham bir xil ",
      },
      {
        type: "txt",
        value: "ikkinchisiga",
      },
      {
        type: "txt",
        value: "birinchisiga",
      },
      {
        type: "txt",
        value: "uchinchisiga",
      },
    ],
  },
];

const ixtisoslashtirilganMaktablar7Sinf = [
  {
    id: 0,
    answer: 0,
    question: [
      {
        type: "txt",
        value:
          "Quyidagi tasdiqlarning to‘g‘ri yoki noto‘g‘ri ekanligi ketma-ketligini to‘g‘ri keltirilgan javob variantini aniqlang.",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/7-sinf/img1.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "1-ha, 2-ha, 3-yo‘q, 4-yo‘q",
      },
      {
        type: "txt",
        value: "1-ha, 2-yo‘q, 3-yo‘q, 4-yo‘q",
      },
      {
        type: "txt",
        value: "1-yo‘q, 2-yo‘q, 3-ha, 4-yo‘q",
      },
      {
        type: "txt",
        value: "1-yo‘q, 2-ha, 3-ha, 4-yo‘q",
      },
    ],
  },
  {
    id: 1,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "24, 36 va 54 sonlarining EKUK ining EKUB iga nisbatini toping.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "72",
      },
      {
        type: "txt",
        value: "24",
      },
      {
        type: "txt",
        value: "36",
      },
      {
        type: "txt",
        value: "144",
      },
    ],
  },
  {
    id: 2,
    answer: 3,
    question: [
      {
        type: "txt",
        value:
          "Qalamdonda 5 ta qizil, 7 ta yashil, 4 ta qora rangli qalamlar bor. Qaramasdan: 2 ta turli rangdagi qalam olish uchun qalamdondan eng kami bilan nechta qalam olish kerak?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "3 ta",
      },
      {
        type: "txt",
        value: "6 ta",
      },
      {
        type: "txt",
        value: "5 ta",
      },
      {
        type: "txt",
        value: "8 ta",
      },
    ],
  },
  {
    id: 3,
    answer: 0,
    question: [
      {
        type: "txt",
        value:
          "Ali, Bobur, Komil, Diyor va Elyor aylana stol atrofida o‘tirishibdi. Ali Boburning yonida emas, Diyor Elyorning yonida va Bobur esa Diyorning yonida emas. Komilning yonida qaysi ikki kishi o‘tirganligini aniqlang.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "Ali va Bobur",
      },
      {
        type: "txt",
        value: "Bobur va Diyor",
      },
      {
        type: "txt",
        value: "Diyor va Elyor",
      },
      {
        type: "txt",
        value: "Elyor va Ali",
      },
    ],
  },
  {
    id: 4,
    answer: 2,
    question: [
      {
        type: "txt",
        value:
          "Rasmda Pekin, Anqara va Londonning ayni bir vaqtdagi soatlari ko‘rsatilgan:",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/7-sinf/img2.png",
      },
      {
        type: "txt",
        value:
          "Pekin, London va Anqarada joylashgan texnologiya firmalari konferensiya o‘tkazishdi. Konferensiya Anqarada mahalliy vaqt bilan 10:00 da boshlandi. So‘ngra Londondagi mahalliy vaqt bilan 13:00 da tugadi. Berilgan ma’lumotlarga ko‘ra quyidagi mulohazalarning qaysi biri to‘g‘ri?",
      },
      {
        type: "txt",
        value: "I. Uchrashuv 5 soat davom etdi.",
      },
      {
        type: "txt",
        value: "II. Uchrashuv Pekinda mahalliy vaqt bilan 15:00 da boshlandi.",
      },
      {
        type: "txt",
        value: "III. Uchrashuv Londonda mahalliy vaqt bilan 8:00 da boshlandi.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "III",
      },
      {
        type: "txt",
        value: "II",
      },
      {
        type: "txt",
        value: "I",
      },
      {
        type: "txt",
        value: "barchasi to‘g‘ri",
      },
    ],
  },
  {
    id: 5,
    answer: 3,
    question: [
      {
        type: "txt",
        value:
          "Kitob varaqlari quyidagicha raqamlangan 1, 2, 3, 4, 5 va hokazo. Bu kitobda 5 raqami 16 marta takrorlanadi. Kitob eng ko‘pi bilan necha betdan iborat bo‘lishi mumkin?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "80",
      },
      {
        type: "txt",
        value: "74",
      },
      {
        type: "txt",
        value: "66",
      },
      {
        type: "txt",
        value: "64",
      },
    ],
  },
  {
    id: 6,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Barcha uch xonali sonlar ichida 17 ga karrali sonlar nechta?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "49",
      },
      {
        type: "txt",
        value: "53",
      },
      {
        type: "txt",
        value: "52",
      },
      {
        type: "txt",
        value: "51",
      },
    ],
  },
  {
    id: 7,
    answer: 2,
    question: [
      {
        type: "txt",
        value:
          "12% li 300 g eritmaga 100 g suv qo‘shildi. Tuzning eritmadagi foiz miqdori qancha bo‘ldi?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "5",
      },
      {
        type: "txt",
        value: "16",
      },
      {
        type: "txt",
        value: "9",
      },
      {
        type: "txt",
        value: "10",
      },
    ],
  },
  {
    id: 8,
    answer: 3,
    question: [
      {
        type: "txt",
        value: "𝑦 + 16 = 25 bo‘lsa, (6𝑦 − 16) − (𝑦 + 4) ifoda qiymatini toping",
      },
    ],
    options: [
      {
        type: "txt",
        value: "35",
      },
      {
        type: "txt",
        value: "36",
      },
      {
        type: "txt",
        value: "26",
      },
      {
        type: "txt",
        value: "25",
      },
    ],
  },
  {
    id: 9,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "100 ta chittak 100 kunda 100 kg don yeydi. 10 ta chittak 10 kunda necha kilogramm don yeydi?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "10",
      },
      {
        type: "txt",
        value: "1",
      },
      {
        type: "txt",
        value: "5",
      },
      {
        type: "txt",
        value: "50",
      },
    ],
  },
  {
    id: 10,
    answer: 2,
    question: [
      {
        type: "txt",
        value:
          "Poyezdning tezligi 60 km/h. Masshtabi 1: 2000000 bo‘lgan xaritada 30 cm li kesma sifatida tasvirlangan haqiqiy masofani shu poyezd necha soatda  bosib o‘tadi?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "5",
      },
      {
        type: "txt",
        value: "1",
      },
      {
        type: "txt",
        value: "10",
      },
      {
        type: "txt",
        value: "12",
      },
    ],
  },
  {
    id: 11,
    answer: 0,
    question: [
      {
        type: "txt",
        value:
          "Uchburchakning bir tomoni 1,7 dm, ikkinchi tomoni 17 cm va perimetri 5,8 dm. Bu uchburchak uchun quyidagi mulohazalardan qaysilari to‘g‘ri?",
      },
      {
        type: "txt",
        value: "1. Uchburchak teng yonli;",
      },
      {
        type: "txt",
        value: "2. Uchburchak teng tomonli;",
      },
      {
        type: "txt",
        value: "3. Uchinchi tomon 24 dm ga teng;",
      },
      {
        type: "txt",
        value: "4. Uchburchakning ikkita burchagi teng;",
      },
    ],
    options: [
      {
        type: "txt",
        value: "1, 4",
      },
      {
        type: "txt",
        value: "3, 4",
      },
      {
        type: "txt",
        value: "2, 4",
      },
      {
        type: "txt",
        value: "1, 3",
      },
    ],
  },
  {
    id: 12,
    answer: 3,
    question: [
      {
        type: "txt",
        value:
          "Telefon stansiyasi mijozlarining uy telefon raqamlari 7 xonali sonlardan iborat va 234 sonidan boshlanadi. Telefon raqami turli raqamlardan iborat (bir xil raqam qatnashmagan) mijozlar sonini aniqlang.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "1639 ta",
      },
      {
        type: "txt",
        value: "720  ta",
      },
      {
        type: "txt",
        value: "49 ta",
      },
      {
        type: "txt",
        value: "840 ta",
      },
    ],
  },
  {
    id: 13,
    answer: 2,
    question: [
      {
        type: "txt",
        value:
          "Mohiraning qadami uzunligi 50 cm, Nodiraning qadami uzunligi esa 60 cm. Opa- singillar uylaridan maktabga bir vaqtda yo’lga chiqdilar. Uydan maktabgacha bo‘lgan masofada ularning qadam izlari 200 marta ustma-ust tushdi: Uydan maktabgacha bo‘lgan masofa necha metr?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "300 m",
      },
      {
        type: "txt",
        value: "6 dm",
      },
      {
        type: "txt",
        value: "600 m",
      },
      {
        type: "txt",
        value: "1200 m",
      },
    ],
  },
  {
    id: 14,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Bir haftalik havo harorati o‘zgarish grafigi berilgan:",
      },
      {
        type: "img",
        value: "/quiz-images/Ixtisoslashtirilgan-maktablar/7-sinf/img3.png",
      },
      {
        type: "txt",
        value:
          "Grafikda berilgan ma’lumotlarga asoslanib, quyidagi savollarga javob bering:",
      },
      {
        type: "txt",
        value: "I. Eng yuqori harorat qaysi kuni kuzatilgan?",
      },
      {
        type: "txt",
        value:
          "II. Qaysi kunlari oralig‘ida havo harorati nisbatan ko‘proq o‘zgargan?",
      },
      {
        type: "txt",
        value:
          "III. Haftaning nechta kunida havo harorati haftalik o‘rtacha haroratdan past bo‘lgan?",
      },
      {
        type: "txt",
        value:
          "IV. Haftaning qaysi kunidagi harorat haftalik o‘rtacha haroratga yaqin bo‘lgan?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "I-shanba; II-juma-shanba; III-3 ta; IV-payshanba;       ",
      },
      {
        type: "txt",
        value: "I-shanba; II-juma-shanba; III-4 ta; IV-seshanba",
      },
      {
        type: "txt",
        value: "I-shanba; II-juma-shanba; III-3 ta; IV-dushanba va chorshanba",
      },
      {
        type: "txt",
        value: "I-shanba; II-juma-shanba; III-4 ta; IV- dushanba va chorshanba",
      },
    ],
  },
];

const umumiyMatematika3SinfUZ = [
  {
    id: 0,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "No’malum kamayuvchini topish uchun ...",
      },
    ],
    options: [
      {
        type: "txt",
        value: "yig’indidan ma’lum qo’shiluvchi ayriladi",
      },
      {
        type: "txt",
        value: "ayirmaga ayriluvchi qo’shiladi",
      },
      {
        type: "txt",
        value: "ayirmadan ayriluvchi ayriladi.",
      },
    ],
  },
  {
    id: 1,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "45 bilan 3 ning ko’paytmasini toping",
      },
    ],
    options: [
      {
        type: "txt",
        value: "135",
      },
      {
        type: "txt",
        value: "125",
      },
      {
        type: "txt",
        value: "1215",
      },
    ],
  },
  {
    id: 2,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "3 bilan 63 ning  ko’paytmasiga 300ni  qo’shing",
      },
    ],
    options: [
      {
        type: "txt",
        value: "3+63+ 300",
      },
      {
        type: "txt",
        value: "3·(63+300)",
      },
      {
        type: "txt",
        value: "3·63+300",
      },
    ],
  },
  {
    id: 3,
    answer: 2,
    question: [
      {
        type: "txt",
        value:
          "Duradgor 8 soatda 32 ta ketmonga dastak tayyorlaydi. U bir soatda qancha dastak yasaydi?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "24ta",
      },
      {
        type: "txt",
        value: "64ta",
      },
      {
        type: "txt",
        value: "4ta",
      },
    ],
  },
  {
    id: 4,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Tenglama: x : 7 = 99 – 79",
      },
    ],
    options: [
      {
        type: "txt",
        value: "140",
      },
      {
        type: "txt",
        value: "14",
      },
      {
        type: "txt",
        value: "20",
      },
    ],
  },
  {
    id: 5,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Quvur diametri 120 mm. Uning radiusini toping.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "60 sm",
      },
      {
        type: "txt",
        value: "12 sm",
      },
      {
        type: "txt",
        value: "60 mm",
      },
    ],
  },
  {
    id: 6,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "264:2  bo’linmani toping",
      },
    ],
    options: [
      {
        type: "txt",
        value: "123",
      },
      {
        type: "txt",
        value: "132",
      },
      {
        type: "txt",
        value: "103",
      },
    ],
  },
  {
    id: 7,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "3 ga bo’lganda qoldiq  chiqmaydigan sonni toping.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "935",
      },
      {
        type: "txt",
        value: "184",
      },
      {
        type: "txt",
        value: "786",
      },
    ],
  },
  {
    id: 8,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "x ning  qiymatini toping   6 · x = 96",
      },
    ],
    options: [
      {
        type: "txt",
        value: "16",
      },
      {
        type: "txt",
        value: "36",
      },
      {
        type: "txt",
        value: "32",
      },
    ],
  },
  {
    id: 9,
    answer: 0,
    question: [
      {
        type: "txt",
        value:
          "Tomonlari 8 cm va 7cm bo’lgan to’g’ri to’rtburchakning perimetrini toping.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "30cm",
      },
      {
        type: "txt",
        value: "15cm",
      },
      {
        type: "txt",
        value: "56cm",
      },
    ],
  },
  {
    id: 10,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Taqqoslang: 56 : 4 * 84 : 7",
      },
    ],
    options: [
      {
        type: "txt",
        value: ">",
      },
      {
        type: "txt",
        value: "<",
      },
      {
        type: "txt",
        value: "=",
      },
    ],
  },
  {
    id: 11,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Tomoni 6 cm bo’lgan kvadratning yuzasi qancha bo’ladi?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "24 cm²",
      },
      {
        type: "txt",
        value: "12 cm²",
      },
      {
        type: "txt",
        value: "36 cm²",
      },
    ],
  },
  {
    id: 12,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "1 soatning chorak qismi necha minut?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "1 soat",
      },
      {
        type: "txt",
        value: "15 min",
      },
      {
        type: "txt",
        value: "30 min",
      },
    ],
  },
  {
    id: 13,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Perimetri 40cm bo’lgan kvadratning tomonlari qancha bo’ladi?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "10cm",
      },
      {
        type: "txt",
        value: "20 cm",
      },
      {
        type: "txt",
        value: "4 va 10 cm",
      },
    ],
  },
  {
    id: 14,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Faqat bir tomoni chegaralangan to’g’ri chiziq nima deyiladi?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "kesma",
      },
      {
        type: "txt",
        value: "To’g’ri chiziq",
      },
      {
        type: "txt",
        value: "nur",
      },
    ],
  },
  {
    id: 15,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Bo’linmani toping: 936 : 3",
      },
    ],
    options: [
      {
        type: "txt",
        value: "32",
      },
      {
        type: "txt",
        value: "312",
      },
      {
        type: "txt",
        value: "321",
      },
    ],
  },
  {
    id: 16,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Hisoblang: 113 · 6 – 40",
      },
    ],
    options: [
      {
        type: "txt",
        value: "638",
      },
      {
        type: "txt",
        value: "648",
      },
      {
        type: "txt",
        value: "679",
      },
    ],
  },
  {
    id: 17,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "To’g’ri yechilgan misolni belgilang",
      },
    ],
    options: [
      {
        type: "txt",
        value: "346: 2=123",
      },
      {
        type: "txt",
        value: "654:6=19",
      },
      {
        type: "txt",
        value: "326:2=163",
      },
    ],
  },
  {
    id: 18,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Taqqoslang: 6dm 2cm  * 60 cm",
      },
    ],
    options: [
      {
        type: "txt",
        value: ">",
      },
      {
        type: "txt",
        value: "<",
      },
      {
        type: "txt",
        value: "=",
      },
    ],
  },
  {
    id: 19,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "1kg 1g dan necha marta ortiq?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "100 marta",
      },
      {
        type: "txt",
        value: "1000 marta",
      },
      {
        type: "txt",
        value: "10 marta",
      },
    ],
  },
];

const umumiyMatematika3SinfRU = [
  {
    id: 0,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Чтобы найти неизвестное уменьшаемое ...",
      },
    ],
    options: [
      {
        type: "txt",
        value: "из суммы вычитается первое слагаемое",
      },
      {
        type: "txt",
        value: "к разности прибавить вычитаемое",
      },
      {
        type: "txt",
        value: "Из разности вычитается вычитаемое",
      },
    ],
  },
  {
    id: 1,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Найдите произведение 45 и 3.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "135",
      },
      {
        type: "txt",
        value: "125",
      },
      {
        type: "txt",
        value: "1215",
      },
    ],
  },
  {
    id: 2,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "К произведению 3 и 63 прибавьте 300.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "3+63+ 300",
      },
      {
        type: "txt",
        value: "3·(63+300)",
      },
      {
        type: "txt",
        value: "3·63+300",
      },
    ],
  },
  {
    id: 3,
    answer: 2,
    question: [
      {
        type: "txt",
        value:
          "Плотник изготавливает 32 детали за 8 часов. Сколько деталь он изготавливает за час?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "24",
      },
      {
        type: "txt",
        value: "64",
      },
      {
        type: "txt",
        value: "4",
      },
    ],
  },
  {
    id: 4,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Решите уравнение: x : 7 = 99 – 79",
      },
    ],
    options: [
      {
        type: "txt",
        value: "140",
      },
      {
        type: "txt",
        value: "14",
      },
      {
        type: "txt",
        value: "20",
      },
    ],
  },
  {
    id: 5,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Диаметр трубы 120 мм. Найдите его радиус",
      },
    ],
    options: [
      {
        type: "txt",
        value: "60 см",
      },
      {
        type: "txt",
        value: "12 см",
      },
      {
        type: "txt",
        value: "60 мм",
      },
    ],
  },
  {
    id: 6,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Выполните деление: 264:2",
      },
    ],
    options: [
      {
        type: "txt",
        value: "123",
      },
      {
        type: "txt",
        value: "132",
      },
      {
        type: "txt",
        value: "103",
      },
    ],
  },
  {
    id: 7,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Найдите число, которое делится на 3 без остатка.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "935",
      },
      {
        type: "txt",
        value: "184",
      },
      {
        type: "txt",
        value: "786",
      },
    ],
  },
  {
    id: 8,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Найдите значение х. 6 · x = 96 ",
      },
    ],
    options: [
      {
        type: "txt",
        value: "16",
      },
      {
        type: "txt",
        value: "36",
      },
      {
        type: "txt",
        value: "32",
      },
    ],
  },
  {
    id: 9,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Найдите периметр прямоугольника со сторонами 8 см и 7 см.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "30cм",
      },
      {
        type: "txt",
        value: "15cм",
      },
      {
        type: "txt",
        value: "56cм",
      },
    ],
  },
  {
    id: 10,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Сравните: 56 : 4 * 84 : 7",
      },
    ],
    options: [
      {
        type: "txt",
        value: ">",
      },
      {
        type: "txt",
        value: "<",
      },
      {
        type: "txt",
        value: "=",
      },
    ],
  },
  {
    id: 11,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Вычислите площадь квадрата со стороной 6 см?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "24 cm^2",
      },
      {
        type: "txt",
        value: "12 cm^2",
      },
      {
        type: "txt",
        value: "36 cm^2",
      },
    ],
  },
  {
    id: 12,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Сколько минут составляет четверть часа?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "1 час",
      },
      {
        type: "txt",
        value: "15 мин",
      },
      {
        type: "txt",
        value: "30 мин",
      },
    ],
  },
  {
    id: 13,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Чему равна сторона квадрата с периметром 40 см?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "10cм",
      },
      {
        type: "txt",
        value: "20 cм",
      },
      {
        type: "txt",
        value: "4 и 10 cм",
      },
    ],
  },
  {
    id: 14,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Как называется прямая, ограниченная с одной стороны?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "Отрезок",
      },
      {
        type: "txt",
        value: "Прямая линия",
      },
      {
        type: "txt",
        value: "Луч",
      },
    ],
  },
  {
    id: 15,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Разделите: 936 : 3",
      },
    ],
    options: [
      {
        type: "txt",
        value: "32",
      },
      {
        type: "txt",
        value: "312",
      },
      {
        type: "txt",
        value: "321",
      },
    ],
  },
  {
    id: 16,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Вычислите: 113 · 6 – 40",
      },
    ],
    options: [
      {
        type: "txt",
        value: "638",
      },
      {
        type: "txt",
        value: "648",
      },
      {
        type: "txt",
        value: "679",
      },
    ],
  },
  {
    id: 17,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Выберете правильный ответ.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "346: 2=123",
      },
      {
        type: "txt",
        value: "654:6=19",
      },
      {
        type: "txt",
        value: "326:2=163",
      },
    ],
  },
  {
    id: 18,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Сравните: 6дм 2см * 60 см",
      },
    ],
    options: [
      {
        type: "txt",
        value: ">",
      },
      {
        type: "txt",
        value: "<",
      },
      {
        type: "txt",
        value: "=",
      },
    ],
  },
  {
    id: 19,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Во сколько раз 1 кг больше 1 г?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "100 раз",
      },
      {
        type: "txt",
        value: "1000 раз",
      },
      {
        type: "txt",
        value: "10 раз",
      },
    ],
  },
];

const umumiyMatematika4SinfUZ = [
  {
    id: 0,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "To’g’ri javobini toping 63:7+40:8=",
      },
    ],
    options: [
      {
        type: "txt",
        value: "14",
      },
      {
        type: "txt",
        value: "13",
      },
      {
        type: "txt",
        value: "6",
      },
      {
        type: "txt",
        value: "15",
      },
    ],
  },
  {
    id: 1,
    answer: 3,
    question: [
      {
        type: "txt",
        value: "To’g’ri javobini toping 810 : 9 · 5 =",
      },
    ],
    options: [
      {
        type: "txt",
        value: "400",
      },
      {
        type: "txt",
        value: "500",
      },
      {
        type: "txt",
        value: "900",
      },
      {
        type: "txt",
        value: "450",
      },
    ],
  },
  {
    id: 2,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "40 kg temirdan 2 ta velosiped  yasash mumkin. 80 kg temirdan nechta velosiped yasash mumkin.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "2",
      },
      {
        type: "txt",
        value: "4",
      },
      {
        type: "txt",
        value: "10",
      },
      {
        type: "txt",
        value: "8",
      },
    ],
  },
  {
    id: 3,
    answer: 3,
    question: [
      {
        type: "txt",
        value: "Ifodaning qiymatini toping. 100 – [7+33]",
      },
    ],
    options: [
      {
        type: "txt",
        value: "50",
      },
      {
        type: "txt",
        value: "70",
      },
      {
        type: "txt",
        value: "126",
      },
      {
        type: "txt",
        value: "60",
      },
    ],
  },
  {
    id: 4,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Noma’lum sonni toping. X-55 = 45",
      },
    ],
    options: [
      {
        type: "txt",
        value: "10",
      },
      {
        type: "txt",
        value: "100",
      },
      {
        type: "txt",
        value: "110",
      },
      {
        type: "txt",
        value: "1000",
      },
    ],
  },
  {
    id: 5,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Noma’lum sonni toping? X : 4 = 72",
      },
    ],
    options: [
      {
        type: "txt",
        value: "288",
      },
      {
        type: "txt",
        value: "286",
      },
      {
        type: "txt",
        value: "18",
      },
      {
        type: "txt",
        value: "285",
      },
    ],
  },
  {
    id: 6,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Kvadratning perimetri 32 sm tomonlarini uzunligini toping?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "8 sm",
      },
      {
        type: "txt",
        value: "128 sm",
      },
      {
        type: "txt",
        value: "4 sm",
      },
      {
        type: "txt",
        value: "7 sm",
      },
    ],
  },
  {
    id: 7,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Ifodaning qiymatini toping: (98 – 90) · 8",
      },
    ],
    options: [
      {
        type: "txt",
        value: "63",
      },
      {
        type: "txt",
        value: "64",
      },
      {
        type: "txt",
        value: "200",
      },
      {
        type: "txt",
        value: "65",
      },
    ],
  },
  {
    id: 8,
    answer: 3,
    question: [
      {
        type: "txt",
        value: "To’g’ri kasrni toping?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "9/8",
      },
      {
        type: "txt",
        value: "8/4",
      },
      {
        type: "txt",
        value: "3/2",
      },
      {
        type: "txt",
        value: "1/4",
      },
    ],
  },
  {
    id: 9,
    answer: 2,
    question: [
      {
        type: "txt",
        value:
          "Хар бир синфга 30 нафар ўқувчи сиғади. 6-синфда қанча ўқувчи бор?",
      },
      {
        type: "img",
        value: "/quiz-images/umumiyMatematika/4-sinf/img1.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "20",
      },
      {
        type: "txt",
        value: "35",
      },
      {
        type: "txt",
        value: "25",
      },
      {
        type: "txt",
        value: "30",
      },
    ],
  },
  {
    id: 10,
    answer: 3,
    question: [
      {
        type: "txt",
        value:
          "3,2,0,6,4,7 raqamlarini qatnashtirib eng katta olti xonali son yozilgan javobni bеlgilang.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "203467",
      },
      {
        type: "txt",
        value: "230467",
      },
      {
        type: "txt",
        value: "240367",
      },
      {
        type: "txt",
        value: "607342",
      },
    ],
  },
  {
    id: 11,
    answer: 3,
    question: [
      {
        type: "txt",
        value: "Noma’lum sonni toping. 750–(x+ 130) = 90",
      },
    ],
    options: [
      {
        type: "txt",
        value: "620",
      },
      {
        type: "txt",
        value: "220",
      },
      {
        type: "txt",
        value: "710",
      },
      {
        type: "txt",
        value: "530",
      },
    ],
  },
  {
    id: 12,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Ifodaning qiymatini toping. 70 002 – (253 + 16 309)",
      },
    ],
    options: [
      {
        type: "txt",
        value: "53946",
      },
      {
        type: "txt",
        value: "53440",
      },
      {
        type: "txt",
        value: "16562",
      },
      {
        type: "txt",
        value: "16309",
      },
    ],
  },
  {
    id: 13,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Ifodaning qiymatini toping. 100 – 68 : 17 · 20",
      },
    ],
    options: [
      {
        type: "txt",
        value: "20",
      },
      {
        type: "txt",
        value: "40",
      },
      {
        type: "txt",
        value: "120",
      },
      {
        type: "txt",
        value: "15",
      },
    ],
  },
  {
    id: 14,
    answer: 2,
    question: [
      {
        type: "txt",
        value:
          "To‘g‘ri burchakli parallelepipedning nechta uchi va qirrasi bor?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "uchi – 6 ta, qirrasi – 8ta",
      },
      {
        type: "txt",
        value: "uchi – 6 ta, qirrasi – 12ta",
      },
      {
        type: "txt",
        value: "uchi – 8 ta, qirrasi – 12ta",
      },
      {
        type: "txt",
        value: "uchi – 8 ta, qirrasi – 6ta",
      },
    ],
  },
  {
    id: 15,
    answer: 2,
    question: [
      {
        type: "txt",
        value:
          "Hamma yoqlari kvadratdan iborat to‘g‘ri burchakli parallelepiped nima deb ataladi?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "kvadrat",
      },
      {
        type: "txt",
        value: "parallelepiped",
      },
      {
        type: "txt",
        value: "kub",
      },
      {
        type: "txt",
        value: "konus",
      },
    ],
  },
  {
    id: 16,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Noma’lum sonni toping. 54•x+ 96 = 204",
      },
    ],
    options: [
      {
        type: "txt",
        value: "2",
      },
      {
        type: "txt",
        value: "4",
      },
      {
        type: "txt",
        value: "5",
      },
      {
        type: "txt",
        value: "15",
      },
    ],
  },
  {
    id: 17,
    answer: 3,
    question: [
      {
        type: "txt",
        value: "Ifodaning qiymatini toping. 35 532 : 9•4 + 15 618•3",
      },
    ],
    options: [
      {
        type: "txt",
        value: "63646",
      },
      {
        type: "txt",
        value: "65046",
      },
      {
        type: "txt",
        value: "62546",
      },
      {
        type: "txt",
        value: "62646",
      },
    ],
  },
  {
    id: 18,
    answer: 2,
    question: [
      {
        type: "txt",
        value:
          "To‘g‘ri to‘rtburchakning bo‘yi 12 sm, eni esa undan 3 marta qisqa. To‘g‘ri to‘rtburchakning perimetrini toping.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "30",
      },
      {
        type: "txt",
        value: "42",
      },
      {
        type: "txt",
        value: "32",
      },
      {
        type: "txt",
        value: "36",
      },
    ],
  },
  {
    id: 19,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "Teng yonli va teng tomonli uchburchaklar bir xil perimetrga ega. Teng tomonli uchburchakning tomonlari 18 sm, teng yonli uchburchakning asosi esa 20 sm. Teng yonli uchburchakning yon tomonini toping.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "20",
      },
      {
        type: "txt",
        value: "17",
      },
      {
        type: "txt",
        value: "30",
      },
      {
        type: "txt",
        value: "18",
      },
    ],
  },
  {
    id: 20,
    answer: 0,
    question: [
      {
        type: "txt",
        value:
          "Toshbaqa va shilliqqurt qarama-qarshi tomonga harakat qilyapti. Agar toshbaqaning tezligi 22 sm/min, shilliqqurtning tezligi 14 sm/min bo‘lsa, 2 minutdan keyin ular orasidagi masofa qancha bo‘ladi?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "72m",
      },
      {
        type: "txt",
        value: "28m",
      },
      {
        type: "txt",
        value: "44m",
      },
      {
        type: "txt",
        value: "80m",
      },
    ],
  },
  {
    id: 21,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Ifodaning qiymatini toping. 57 342 : 114",
      },
    ],
    options: [
      {
        type: "txt",
        value: "513",
      },
      {
        type: "txt",
        value: "503",
      },
      {
        type: "txt",
        value: "500",
      },
      {
        type: "txt",
        value: "493",
      },
    ],
  },
  {
    id: 22,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Ifodaning qiymatini toping.  640 : 8 – 2 · 30 + 16",
      },
    ],
    options: [
      {
        type: "txt",
        value: "36",
      },
      {
        type: "txt",
        value: "30",
      },
      {
        type: "txt",
        value: "40",
      },
      {
        type: "txt",
        value: "10",
      },
    ],
  },
  {
    id: 23,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Noma’lum sonni toping. x:12 = 2040",
      },
    ],
    options: [
      {
        type: "txt",
        value: "24460",
      },
      {
        type: "txt",
        value: "170",
      },
      {
        type: "txt",
        value: "24480",
      },
      {
        type: "txt",
        value: "23460",
      },
    ],
  },
  {
    id: 24,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Noma’lum sonni toping. 174 :x=29",
      },
    ],
    options: [
      {
        type: "txt",
        value: "5",
      },
      {
        type: "txt",
        value: "6",
      },
      {
        type: "txt",
        value: "5046",
      },
      {
        type: "txt",
        value: "8",
      },
    ],
  },
];

const umumiyMatematika4SinfRU = [
  {
    id: 0,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Вычислите: 63:7+40:8=",
      },
    ],
    options: [
      {
        type: "txt",
        value: "14",
      },
      {
        type: "txt",
        value: "13",
      },
      {
        type: "txt",
        value: "6",
      },
      {
        type: "txt",
        value: "15",
      },
    ],
  },
  {
    id: 1,
    answer: 3,
    question: [
      {
        type: "txt",
        value: "Вычислите: 810 : 9 · 5 =",
      },
    ],
    options: [
      {
        type: "txt",
        value: "400",
      },
      {
        type: "txt",
        value: "500",
      },
      {
        type: "txt",
        value: "900",
      },
      {
        type: "txt",
        value: "450",
      },
    ],
  },
  {
    id: 2,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "Из 40 кг железа можно сделать 2 велосипеда. Сколько велосипедов можно сделать из 80 кг железа.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "2",
      },
      {
        type: "txt",
        value: "4",
      },
      {
        type: "txt",
        value: "10",
      },
      {
        type: "txt",
        value: "8",
      },
    ],
  },
  {
    id: 3,
    answer: 3,
    question: [
      {
        type: "txt",
        value: "Вычислите:  100 – [7+33]",
      },
    ],
    options: [
      {
        type: "txt",
        value: "50",
      },
      {
        type: "txt",
        value: "70",
      },
      {
        type: "txt",
        value: "126",
      },
      {
        type: "txt",
        value: "60",
      },
    ],
  },
  {
    id: 4,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Решите уравнение: X-55 = 45",
      },
    ],
    options: [
      {
        type: "txt",
        value: "10",
      },
      {
        type: "txt",
        value: "100",
      },
      {
        type: "txt",
        value: "110",
      },
      {
        type: "txt",
        value: "1000",
      },
    ],
  },
  {
    id: 5,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Решите уравнение: X : 4 = 72",
      },
    ],
    options: [
      {
        type: "txt",
        value: "288",
      },
      {
        type: "txt",
        value: "286",
      },
      {
        type: "txt",
        value: "18",
      },
      {
        type: "txt",
        value: "285",
      },
    ],
  },
  {
    id: 6,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Найдите периметр квадрата с периметром 32 см.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "8 см",
      },
      {
        type: "txt",
        value: "128 см",
      },
      {
        type: "txt",
        value: "4 см",
      },
      {
        type: "txt",
        value: "7 см",
      },
    ],
  },
  {
    id: 7,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Вычислите: (98 – 90) · 8 ",
      },
    ],
    options: [
      {
        type: "txt",
        value: "63",
      },
      {
        type: "txt",
        value: "64",
      },
      {
        type: "txt",
        value: "200",
      },
      {
        type: "txt",
        value: "65",
      },
    ],
  },
  {
    id: 8,
    answer: 3,
    question: [
      {
        type: "txt",
        value: "Найдите правильную дробь?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "9/8",
      },
      {
        type: "txt",
        value: "8/4",
      },
      {
        type: "txt",
        value: "3/2",
      },
      {
        type: "txt",
        value: "1/4",
      },
    ],
  },
  {
    id: 9,
    answer: 2,
    question: [
      {
        type: "txt",
        value:
          "В каждом классе могут разместиться 30 учеников. Сколько учеников в 6 классе?",
      },
      {
        type: "img",
        value: "/quiz-images/umumiyMatematika/4-sinf/img1.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "20",
      },
      {
        type: "txt",
        value: "35",
      },
      {
        type: "txt",
        value: "25",
      },
      {
        type: "txt",
        value: "30",
      },
    ],
  },
  {
    id: 10,
    answer: 3,
    question: [
      {
        type: "txt",
        value:
          "Запишите наибольшее шестизначное число, составленное из цифр 3,2,0,6,4,7.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "203467",
      },
      {
        type: "txt",
        value: "230467",
      },
      {
        type: "txt",
        value: "240367",
      },
      {
        type: "txt",
        value: "607342",
      },
    ],
  },
  {
    id: 11,
    answer: 3,
    question: [
      {
        type: "txt",
        value: "Решите уравнение: 750–(x+ 130) = 90",
      },
    ],
    options: [
      {
        type: "txt",
        value: "620",
      },
      {
        type: "txt",
        value: "220",
      },
      {
        type: "txt",
        value: "710",
      },
      {
        type: "txt",
        value: "530",
      },
    ],
  },
  {
    id: 12,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Вычислите: 70 002 – (253 + 16 309)",
      },
    ],
    options: [
      {
        type: "txt",
        value: "53946",
      },
      {
        type: "txt",
        value: "53440",
      },
      {
        type: "txt",
        value: "16562",
      },
      {
        type: "txt",
        value: "16309",
      },
    ],
  },
  {
    id: 13,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Вычислите: 100 – 68 : 17 · 20",
      },
    ],
    options: [
      {
        type: "txt",
        value: "20",
      },
      {
        type: "txt",
        value: "40",
      },
      {
        type: "txt",
        value: "120",
      },
      {
        type: "txt",
        value: "15",
      },
    ],
  },
  {
    id: 14,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Сколько вершин и ребер у прямоугольного параллелепипеда?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "6 вершин, 8 ребер",
      },
      {
        type: "txt",
        value: "8 вершин, 6 ребер",
      },
      {
        type: "txt",
        value: "8 вершин, 12 ребер",
      },
      {
        type: "txt",
        value: "12 вершин, 8 ребер",
      },
    ],
  },
  {
    id: 15,
    answer: 2,
    question: [
      {
        type: "txt",
        value:
          "Как называется прямоугольный параллелепипед, у которого все стороны квадратные?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "Квадрат",
      },
      {
        type: "txt",
        value: "Параллелепипед",
      },
      {
        type: "txt",
        value: "Куб",
      },
      {
        type: "txt",
        value: "Конус",
      },
    ],
  },
  {
    id: 16,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Решите уравнение: 54•x+ 96 = 204",
      },
    ],
    options: [
      {
        type: "txt",
        value: "2",
      },
      {
        type: "txt",
        value: "4",
      },
      {
        type: "txt",
        value: "5",
      },
      {
        type: "txt",
        value: "15",
      },
    ],
  },
  {
    id: 17,
    answer: 3,
    question: [
      {
        type: "txt",
        value: "Вычислите: 35 532 : 9•4 + 15 618•3",
      },
    ],
    options: [
      {
        type: "txt",
        value: "63646",
      },
      {
        type: "txt",
        value: "65046",
      },
      {
        type: "txt",
        value: "62546",
      },
      {
        type: "txt",
        value: "62646",
      },
    ],
  },
  {
    id: 18,
    answer: 2,
    question: [
      {
        type: "txt",
        value:
          "Длина прямоугольника 12 см, а ширина в 3 раза короче. Найдите периметр прямоугольника",
      },
    ],
    options: [
      {
        type: "txt",
        value: "30",
      },
      {
        type: "txt",
        value: "42",
      },
      {
        type: "txt",
        value: "32",
      },
      {
        type: "txt",
        value: "36",
      },
    ],
  },
  {
    id: 19,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "Равнобедренный и равносторонний треугольники имеют одинаковый периметр. Стороны равностороннего треугольника равны 18 см, а основание равнобедренного треугольника 20 см. Найдите боковую сторону равнобедреннего треугольника?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "20",
      },
      {
        type: "txt",
        value: "17",
      },
      {
        type: "txt",
        value: "30",
      },
      {
        type: "txt",
        value: "18",
      },
    ],
  },
  {
    id: 20,
    answer: 0,
    question: [
      {
        type: "txt",
        value:
          "Черепаха и улитка движутся в противоположных направлениях. Каково расстояние между ними через 2 минуты, если скорость черепахи 22 см/мин, а скорость улитки 14 см/мин?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "72м",
      },
      {
        type: "txt",
        value: "28м",
      },
      {
        type: "txt",
        value: "44м",
      },
      {
        type: "txt",
        value: "80м",
      },
    ],
  },
  {
    id: 21,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Вычислите:  57 342 : 114",
      },
    ],
    options: [
      {
        type: "txt",
        value: "513",
      },
      {
        type: "txt",
        value: "503",
      },
      {
        type: "txt",
        value: "500",
      },
      {
        type: "txt",
        value: "493",
      },
    ],
  },
  {
    id: 22,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Вычислите:  640 : 8 – 2 · 30 + 16",
      },
    ],
    options: [
      {
        type: "txt",
        value: "36",
      },
      {
        type: "txt",
        value: "30",
      },
      {
        type: "txt",
        value: "40",
      },
      {
        type: "txt",
        value: "10",
      },
    ],
  },
  {
    id: 23,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Решите уравнение:  x:12 = 2040",
      },
    ],
    options: [
      {
        type: "txt",
        value: "24460",
      },
      {
        type: "txt",
        value: "170",
      },
      {
        type: "txt",
        value: "24480",
      },
      {
        type: "txt",
        value: "23460",
      },
    ],
  },
  {
    id: 24,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Решите уравнение: 174 :x=29",
      },
    ],
    options: [
      {
        type: "txt",
        value: "5",
      },
      {
        type: "txt",
        value: "6",
      },
      {
        type: "txt",
        value: "5046",
      },
      {
        type: "txt",
        value: "8",
      },
    ],
  },
];

const umumiyMatematika5SinfUZ = [
  {
    id: 0,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "Shunday son yozingki unda quyidagi xona birliklari bo’lsin. 8 ta ming, 3 ta yuz, 2 ta o’n, 7 ta bir.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "80032",
      },
      {
        type: "txt",
        value: "8327",
      },
      {
        type: "txt",
        value: "83007",
      },
      {
        type: "txt",
        value: "83207",
      },
    ],
  },
  {
    id: 1,
    answer: 2,
    question: [
      {
        type: "txt",
        value:
          "Amudaryoning uzunligi 2540 km . Sirdaryo unga qaraganda 479 km uzun. Sirdaryoning uzunligini toping.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "2061",
      },
      {
        type: "txt",
        value: "4765",
      },
      {
        type: "txt",
        value: "3019",
      },
      {
        type: "txt",
        value: "18128",
      },
    ],
  },
  {
    id: 2,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Raqamlar bilan yozing:   102 mlrd;",
      },
    ],
    options: [
      {
        type: "txt",
        value: "102 000 000 000",
      },
      {
        type: "txt",
        value: "102 000 000",
      },
      {
        type: "txt",
        value: "102 000",
      },
      {
        type: "txt",
        value: "10 200 000",
      },
    ],
  },
  {
    id: 3,
    answer: 3,
    question: [
      {
        type: "txt",
        value: "Amallarni bajaring:  (232 + 8) : 10 + 124 ;",
      },
    ],
    options: [
      {
        type: "txt",
        value: "504",
      },
      {
        type: "txt",
        value: "308",
      },
      {
        type: "txt",
        value: "129",
      },
      {
        type: "txt",
        value: "602",
      },
    ],
  },
  {
    id: 4,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Millimetrda ifodalang:  4 dm 6 sm 7 mm",
      },
    ],
    options: [
      {
        type: "txt",
        value: "467",
      },
      {
        type: "txt",
        value: "46",
      },
      {
        type: "txt",
        value: "4,67",
      },
      {
        type: "txt",
        value: "4670",
      },
    ],
  },
  {
    id: 5,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "AB kesmani C nuqta AC va CB qismlarga ajratadi. Agar AC kesma uzunligi 56 sm bo’lib, u BC kesmadan 23 sm qisqa bo’lsa, AB kesma uzunligini toping.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "125",
      },
      {
        type: "txt",
        value: "135",
      },
      {
        type: "txt",
        value: "129",
      },
      {
        type: "txt",
        value: "233",
      },
    ],
  },
  {
    id: 6,
    answer: 0,
    question: [
      {
        type: "txt",
        value:
          "Qo’sh tengsizlik ko’rinishida ifodalang. 44 soni 55 sonidan kichik, lekin 33 sonidan katta;",
      },
    ],
    options: [
      {
        type: "txt",
        value: "33<44<55",
      },
      {
        type: "txt",
        value: "33<55>44",
      },
      {
        type: "txt",
        value: "55<44<33",
      },
      {
        type: "txt",
        value: "44<55<33",
      },
    ],
  },
  {
    id: 7,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "Kesma bir uchi tomonga cheksiz davom ettirilsa, qanday shakl hosil bo’ladi?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "Kesma",
      },
      {
        type: "txt",
        value: "Nur",
      },
      {
        type: "txt",
        value: "To’g’ri chiziq",
      },
      {
        type: "txt",
        value: "Tekislik",
      },
    ],
  },
  {
    id: 8,
    answer: 3,
    question: [
      {
        type: "txt",
        value: "Hisoblang: 2570 + 47534",
      },
    ],
    options: [
      {
        type: "txt",
        value: "50500",
      },
      {
        type: "txt",
        value: "52340",
      },
      {
        type: "txt",
        value: "56302",
      },
      {
        type: "txt",
        value: "50104",
      },
    ],
  },
  {
    id: 9,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Harfli ifodaning qiymatini toping. 128 - b, b = 59",
      },
    ],
    options: [
      {
        type: "txt",
        value: "53",
      },
      {
        type: "txt",
        value: "79",
      },
      {
        type: "txt",
        value: "69",
      },
      {
        type: "txt",
        value: "75",
      },
    ],
  },
  {
    id: 10,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Sonlarni yig’indisini toping. 112, 85, 2333;",
      },
    ],
    options: [
      {
        type: "txt",
        value: "2530",
      },
      {
        type: "txt",
        value: "3446",
      },
      {
        type: "txt",
        value: "2624",
      },
      {
        type: "txt",
        value: "4940",
      },
    ],
  },
  {
    id: 11,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Amallarni bajaring. 665 247 – 8296",
      },
    ],
    options: [
      {
        type: "txt",
        value: "626261",
      },
      {
        type: "txt",
        value: "656951",
      },
      {
        type: "txt",
        value: "602425",
      },
      {
        type: "txt",
        value: "632856",
      },
    ],
  },
  {
    id: 12,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Harfiy ifodaning qiymatini toping. d – 109, bunda d = 139;",
      },
    ],
    options: [
      {
        type: "txt",
        value: "40",
      },
      {
        type: "txt",
        value: "34",
      },
      {
        type: "txt",
        value: "30",
      },
      {
        type: "txt",
        value: "42",
      },
    ],
  },
  {
    id: 13,
    answer: 3,
    question: [
      {
        type: "txt",
        value:
          "Birinchi qalamdonda 12 ta, ikkinchisida esa birinchisidan m dona kam qalam bor. Ikkita qalamdonda nechta qalam bor?  m = 6;  8; bo’lganda maslani yeching.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "12 – m, 6; 5;",
      },
      {
        type: "txt",
        value: "24 – m;",
      },
      {
        type: "txt",
        value: "12 – m, 16; ",
      },
      {
        type: "txt",
        value: "12 – m, 18; 16;",
      },
    ],
  },
  {
    id: 14,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Bo’lishni bajaring: 1980 : 36",
      },
    ],
    options: [
      {
        type: "txt",
        value: "45",
      },
      {
        type: "txt",
        value: "35",
      },
      {
        type: "txt",
        value: "55",
      },
      {
        type: "txt",
        value: "65",
      },
    ],
  },
  {
    id: 15,
    answer: 3,
    question: [
      {
        type: "txt",
        value: "Tushirib qoldirilgan sonni ayting; ... : 3 = 13",
      },
    ],
    options: [
      {
        type: "txt",
        value: "48",
      },
      {
        type: "txt",
        value: "56",
      },
      {
        type: "txt",
        value: "59",
      },
      {
        type: "txt",
        value: "39",
      },
    ],
  },
  {
    id: 16,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "441 559 soni 109 sonidan necha marta katta?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "4051",
      },
      {
        type: "txt",
        value: "4050",
      },
      {
        type: "txt",
        value: "3261",
      },
      {
        type: "txt",
        value: "1642",
      },
    ],
  },
  {
    id: 17,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Tenglamani yeching ;   21 * x = 903",
      },
    ],
    options: [
      {
        type: "txt",
        value: "36",
      },
      {
        type: "txt",
        value: "43",
      },
      {
        type: "txt",
        value: "54",
      },
      {
        type: "txt",
        value: "28",
      },
    ],
  },
  {
    id: 18,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Qoldiqli bo’lishni bajaring ;    54 103 : 44;",
      },
    ],
    options: [
      {
        type: "txt",
        value: "123 (5q)",
      },
      {
        type: "txt",
        value: "136(2q)",
      },
      {
        type: "txt",
        value: "1229 (26q)",
      },
      {
        type: "txt",
        value: "1631 (8q)",
      },
    ],
  },
];

const umumiyMatematika5SinfRU = [
  {
    id: 0,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "Запишите число так, чтобы оно имело следующие единицы измерения. 8 тысяч, 3 сотни, 2 десятка, 7 единиц.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "80032",
      },
      {
        type: "txt",
        value: "8327",
      },
      {
        type: "txt",
        value: "83007",
      },
      {
        type: "txt",
        value: "83207",
      },
    ],
  },
  {
    id: 1,
    answer: 2,
    question: [
      {
        type: "txt",
        value:
          "Длина Амударьи составляет 2540 км. Сырдарья длиннее ее на 479 км. Найдите длину реки Сырдарья.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "2061",
      },
      {
        type: "txt",
        value: "4765",
      },
      {
        type: "txt",
        value: "3019",
      },
      {
        type: "txt",
        value: "18128",
      },
    ],
  },
  {
    id: 2,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Напишите цифрами: 102 миллиарда;",
      },
    ],
    options: [
      {
        type: "txt",
        value: "102 000 000 000",
      },
      {
        type: "txt",
        value: "102 000 000",
      },
      {
        type: "txt",
        value: "102 000",
      },
      {
        type: "txt",
        value: "10 200 000",
      },
    ],
  },
  {
    id: 3,
    answer: 3,
    question: [
      {
        type: "txt",
        value: "Вычислите:  (232 + 8) : 10 + 124 ;",
      },
    ],
    options: [
      {
        type: "txt",
        value: "504",
      },
      {
        type: "txt",
        value: "308",
      },
      {
        type: "txt",
        value: "129",
      },
      {
        type: "txt",
        value: "602",
      },
    ],
  },
  {
    id: 4,
    answer: 0,
    question: [
      {
        type: "txt",
        value: " Выразите в мм:  4 дм 6 см 7 мм",
      },
    ],
    options: [
      {
        type: "txt",
        value: "467",
      },
      {
        type: "txt",
        value: "46",
      },
      {
        type: "txt",
        value: "4,67",
      },
      {
        type: "txt",
        value: "4670",
      },
    ],
  },
  {
    id: 5,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "Точка С делит отрезок АВ на части АС и СВ. Найдите длину AB, если длина AC 56 см, а длина BC на 23 см больше чем AB",
      },
    ],
    options: [
      {
        type: "txt",
        value: "125",
      },
      {
        type: "txt",
        value: "135",
      },
      {
        type: "txt",
        value: "129",
      },
      {
        type: "txt",
        value: "233",
      },
    ],
  },
  {
    id: 6,
    answer: 0,
    question: [
      {
        type: "txt",
        value:
          "Выразите в виде двойного неравенства. 44 меньше 55, но больше 33;",
      },
    ],
    options: [
      {
        type: "txt",
        value: "33<44<55",
      },
      {
        type: "txt",
        value: "33<55>44",
      },
      {
        type: "txt",
        value: "55<44<33",
      },
      {
        type: "txt",
        value: "44<55<33",
      },
    ],
  },
  {
    id: 7,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Какая прямая линия, которая имеет начало, но не имеет конца.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "Отрезок",
      },
      {
        type: "txt",
        value: "Луч",
      },
      {
        type: "txt",
        value: "Прямая линия",
      },
      {
        type: "txt",
        value: "Поверхность",
      },
    ],
  },
  {
    id: 8,
    answer: 3,
    question: [
      {
        type: "txt",
        value: "Вычислите: 2570 + 47534",
      },
    ],
    options: [
      {
        type: "txt",
        value: "50500",
      },
      {
        type: "txt",
        value: "52340",
      },
      {
        type: "txt",
        value: "56302",
      },
      {
        type: "txt",
        value: "50104",
      },
    ],
  },
  {
    id: 9,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Найдите значение буквенного выражения. 128 - b,  где   b = 59",
      },
    ],
    options: [
      {
        type: "txt",
        value: "53",
      },
      {
        type: "txt",
        value: "79",
      },
      {
        type: "txt",
        value: "69",
      },
      {
        type: "txt",
        value: "75",
      },
    ],
  },
  {
    id: 10,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Найдите сумму чисел.   112, 85, 2333;",
      },
    ],
    options: [
      {
        type: "txt",
        value: "2530",
      },
      {
        type: "txt",
        value: "3446",
      },
      {
        type: "txt",
        value: "2624",
      },
      {
        type: "txt",
        value: "4940",
      },
    ],
  },
  {
    id: 11,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Вычислите: 665 247 – 8296",
      },
    ],
    options: [
      {
        type: "txt",
        value: "626261",
      },
      {
        type: "txt",
        value: "656951",
      },
      {
        type: "txt",
        value: "602425",
      },
      {
        type: "txt",
        value: "632856",
      },
    ],
  },
  {
    id: 12,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Найдите значение буквенного выражения. d – 109,  где  d = 139;",
      },
    ],
    options: [
      {
        type: "txt",
        value: "40",
      },
      {
        type: "txt",
        value: "34",
      },
      {
        type: "txt",
        value: "30",
      },
      {
        type: "txt",
        value: "42",
      },
    ],
  },
  {
    id: 13,
    answer: 3,
    question: [
      {
        type: "txt",
        value:
          "В первом пенале 12 карандашей, а во втором на m меньше карандашей, чем в первом. Сколько карандашей в двух пеналах?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "12 – m, 6; 5;",
      },
      {
        type: "txt",
        value: "24 – m;",
      },
      {
        type: "txt",
        value: "12 – m, 16; ",
      },
      {
        type: "txt",
        value: "12 – m, 18; 16;",
      },
    ],
  },
  {
    id: 14,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Вычислите: 1980 : 36",
      },
    ],
    options: [
      {
        type: "txt",
        value: "45",
      },
      {
        type: "txt",
        value: "35",
      },
      {
        type: "txt",
        value: "55",
      },
      {
        type: "txt",
        value: "65",
      },
    ],
  },
  {
    id: 15,
    answer: 3,
    question: [
      {
        type: "txt",
        value: "Напишите пропущенное число; ... : 3 = 13",
      },
    ],
    options: [
      {
        type: "txt",
        value: "48",
      },
      {
        type: "txt",
        value: "56",
      },
      {
        type: "txt",
        value: "59",
      },
      {
        type: "txt",
        value: "39",
      },
    ],
  },
  {
    id: 16,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Во сколько раз число 441 559 больше числа 109",
      },
    ],
    options: [
      {
        type: "txt",
        value: "4051",
      },
      {
        type: "txt",
        value: "4050",
      },
      {
        type: "txt",
        value: "3261",
      },
      {
        type: "txt",
        value: "1642",
      },
    ],
  },
  {
    id: 17,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Решите уравнение:   21 * x = 903",
      },
    ],
    options: [
      {
        type: "txt",
        value: "36",
      },
      {
        type: "txt",
        value: "43",
      },
      {
        type: "txt",
        value: "54",
      },
      {
        type: "txt",
        value: "28",
      },
    ],
  },
  {
    id: 18,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Сделайте деление с остатком:    54 103 : 44;",
      },
    ],
    options: [
      {
        type: "txt",
        value: "123 (5q)",
      },
      {
        type: "txt",
        value: "136(2q)",
      },
      {
        type: "txt",
        value: "1229 (26q)",
      },
      {
        type: "txt",
        value: "1631 (8q)",
      },
    ],
  },
];

const umumiyMatematika6SinfUZ = [
  {
    id: 0,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Amallarni bajaring: 7758 : 18 + 720 : 8;",
      },
    ],
    options: [
      {
        type: "txt",
        value: "521",
      },
      {
        type: "txt",
        value: "500",
      },
      {
        type: "txt",
        value: "511",
      },
    ],
  },
  {
    id: 1,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "Bir son ikkinchisidan 20 taga ko’p ularning yig’indisi 70 ga teng. Bu sonlarni toping?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "30 va 40",
      },
      {
        type: "txt",
        value: "25 va 45",
      },
      {
        type: "txt",
        value: "55 va 15",
      },
    ],
  },
  {
    id: 2,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "To’g’ri to’rtburchakning bo’yi 25 sm, eni esa 20 sm. Uning perimetrini toping?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "85",
      },
      {
        type: "txt",
        value: "90",
      },
      {
        type: "txt",
        value: "95",
      },
    ],
  },
  {
    id: 3,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Tenglamani yeching: x : 20 = 10",
      },
    ],
    options: [
      {
        type: "txt",
        value: "2",
      },
      {
        type: "txt",
        value: "2000",
      },
      {
        type: "txt",
        value: "200",
      },
    ],
  },
  {
    id: 4,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Gektarlarda ifodalang: 500 000 m^2;",
      },
    ],
    options: [
      {
        type: "txt",
        value: "500 g",
      },
      {
        type: "txt",
        value: "50 g",
      },
      {
        type: "txt",
        value: "5000 g",
      },
    ],
  },
  {
    id: 5,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Sonning bo’luvchilari nechta: 58;",
      },
    ],
    options: [
      {
        type: "txt",
        value: "4",
      },
      {
        type: "txt",
        value: "3",
      },
      {
        type: "txt",
        value: "5",
      },
    ],
  },
  {
    id: 6,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "1 dan 100 gacha sonlar ichida 2ga bo’linmaydigan sonlar nechta?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "60",
      },
      {
        type: "txt",
        value: "50",
      },
      {
        type: "txt",
        value: "70",
      },
    ],
  },
  {
    id: 7,
    answer: 0,
    question: [
      {
        type: "txt",
        value:
          "Ikkinchi raqami 4 bo’lgan uch xonali son 9 ga va 2 ga bo’linadi, ammo 5 ga bo’linmaydi. Shu sonni toping?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "944",
      },
      {
        type: "txt",
        value: "144",
      },
      {
        type: "txt",
        value: "244",
      },
    ],
  },
  {
    id: 8,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "Poyezdda 936 yo’lovchi bor edi. Erkaklar ayollardan 7 marta ko’p. Poyezdda qancha ayol bo’lgan?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "250",
      },
      {
        type: "txt",
        value: "156",
      },
      {
        type: "txt",
        value: "240",
      },
    ],
  },
  {
    id: 9,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Tub ko’paytuvchilarga ajrating.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "5175 = 5 * 7 * 3",
      },
      {
        type: "txt",
        value: "5175 = 5^2 * 3 * 7^2 * 23",
      },
      {
        type: "txt",
        value: "5175 = 5 * 7^2 * 3",
      },
    ],
  },
  {
    id: 10,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "EKUB (215, 435, 600) topilsin.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "3",
      },
      {
        type: "txt",
        value: "5",
      },
      {
        type: "txt",
        value: "2",
      },
    ],
  },
  {
    id: 11,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "EKUB (21, 84) topilsin.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "7",
      },
      {
        type: "txt",
        value: "3",
      },
      {
        type: "txt",
        value: "21",
      },
    ],
  },
  {
    id: 12,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "EKUK (28, 70, 140) topilsin.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "70",
      },
      {
        type: "txt",
        value: "140",
      },
      {
        type: "txt",
        value: "280",
      },
    ],
  },
  {
    id: 13,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "EKUK (106, 159, 530) topilsin.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "3000",
      },
      {
        type: "txt",
        value: "3180",
      },
      {
        type: "txt",
        value: "3280",
      },
    ],
  },
  {
    id: 14,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "36 sonining natural bo’luvchilari nechta?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "5",
      },
      {
        type: "txt",
        value: "7",
      },
      {
        type: "txt",
        value: "8",
      },
    ],
  },
  {
    id: 15,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Tenglamani yechimini toping: 3/4 = 15/(x+7)",
      },
    ],
    options: [
      {
        type: "txt",
        value: "13",
      },
      {
        type: "txt",
        value: "14",
      },
      {
        type: "txt",
        value: "15",
      },
    ],
  },
  {
    id: 16,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "125/725 kasrni qisqartiring.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "2/5",
      },
      {
        type: "txt",
        value: "5/29",
      },
      {
        type: "txt",
        value: "1/25",
      },
    ],
  },
  {
    id: 17,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Kasrni ayiring: 2/9 - 2/15",
      },
    ],
    options: [
      {
        type: "txt",
        value: "16/135",
      },
      {
        type: "txt",
        value: "18/60",
      },
      {
        type: "txt",
        value: "8/90",
      },
    ],
  },
  {
    id: 18,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Hisoblang: 9/20 - (3/8 - 1/8)",
      },
    ],
    options: [
      {
        type: "txt",
        value: "1/5",
      },
      {
        type: "txt",
        value: "4/20",
      },
      {
        type: "txt",
        value: "6/40",
      },
    ],
  },
  {
    id: 19,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Hisoblang: 30(7/12) - 10(12/124)",
      },
    ],
    options: [
      {
        type: "txt",
        value: "20(3/12)",
      },
      {
        type: "txt",
        value: "20(3/24)",
      },
      {
        type: "txt",
        value: "20(1/12)",
      },
    ],
  },
];

const umumiyMatematika6SinfRU = [
  {
    id: 0,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Вычислите: 7758 : 18 + 720 : 8;",
      },
    ],
    options: [
      {
        type: "txt",
        value: "521",
      },
      {
        type: "txt",
        value: "500",
      },
      {
        type: "txt",
        value: "511",
      },
    ],
  },
  {
    id: 1,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "Одно число на 20 больше другого, а их сумма равна 70. Найдите эти числа",
      },
    ],
    options: [
      {
        type: "txt",
        value: "30 и 40",
      },
      {
        type: "txt",
        value: "25 и 45",
      },
      {
        type: "txt",
        value: "55 и 15",
      },
    ],
  },
  {
    id: 2,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Длина прямоугольника 25 см, ширина 20 см. Найти его периметр?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "85",
      },
      {
        type: "txt",
        value: "90",
      },
      {
        type: "txt",
        value: "95",
      },
    ],
  },
  {
    id: 3,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Решите уравнение: x : 20 = 10",
      },
    ],
    options: [
      {
        type: "txt",
        value: "2",
      },
      {
        type: "txt",
        value: "2000",
      },
      {
        type: "txt",
        value: "200",
      },
    ],
  },
  {
    id: 4,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Выразите в гектарах: 500 000 m^2;",
      },
    ],
    options: [
      {
        type: "txt",
        value: "500 g",
      },
      {
        type: "txt",
        value: "50 g",
      },
      {
        type: "txt",
        value: "5000 g",
      },
    ],
  },
  {
    id: 5,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Найдите число делителей: 58;",
      },
    ],
    options: [
      {
        type: "txt",
        value: "4",
      },
      {
        type: "txt",
        value: "3",
      },
      {
        type: "txt",
        value: "5",
      },
    ],
  },
  {
    id: 6,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Сколько чисел от 1 до 100 не делятся на 2?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "60",
      },
      {
        type: "txt",
        value: "50",
      },
      {
        type: "txt",
        value: "70",
      },
    ],
  },
  {
    id: 7,
    answer: 0,
    question: [
      {
        type: "txt",
        value:
          "Трехзначное число, вторая цифра которого равна 4, делится на 9 и 2, но не делится на 5. Найдите это число?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "944",
      },
      {
        type: "txt",
        value: "144",
      },
      {
        type: "txt",
        value: "244",
      },
    ],
  },
  {
    id: 8,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "В поезде находилось 936 пассажиров. Мужчин в 7 раз больше, чем женщин. Сколько женщин было в поезде?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "250",
      },
      {
        type: "txt",
        value: "156",
      },
      {
        type: "txt",
        value: "240",
      },
    ],
  },
  {
    id: 9,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Разложите на простые множители.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "5175 = 5 * 7 * 3",
      },
      {
        type: "txt",
        value: "5175 = 5^2 * 3 * 7^2 * 23",
      },
      {
        type: "txt",
        value: "5175 = 5 * 7^2 * 3",
      },
    ],
  },
  {
    id: 10,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Найти НОД (215, 435, 600).",
      },
    ],
    options: [
      {
        type: "txt",
        value: "3",
      },
      {
        type: "txt",
        value: "5",
      },
      {
        type: "txt",
        value: "2",
      },
    ],
  },
  {
    id: 11,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Найти НОД (21, 84).",
      },
    ],
    options: [
      {
        type: "txt",
        value: "7",
      },
      {
        type: "txt",
        value: "3",
      },
      {
        type: "txt",
        value: "21",
      },
    ],
  },
  {
    id: 12,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Найти НОК (28, 70, 140).",
      },
    ],
    options: [
      {
        type: "txt",
        value: "70",
      },
      {
        type: "txt",
        value: "140",
      },
      {
        type: "txt",
        value: "280",
      },
    ],
  },
  {
    id: 13,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Найти НОК (106, 159, 530).",
      },
    ],
    options: [
      {
        type: "txt",
        value: "3000",
      },
      {
        type: "txt",
        value: "3180",
      },
      {
        type: "txt",
        value: "3280",
      },
    ],
  },
  {
    id: 14,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Сколько натуральные делители числа 36?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "5",
      },
      {
        type: "txt",
        value: "7",
      },
      {
        type: "txt",
        value: "8",
      },
    ],
  },
  {
    id: 15,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Решите уравнение: 3/4 = 15/(x+7)",
      },
    ],
    options: [
      {
        type: "txt",
        value: "13",
      },
      {
        type: "txt",
        value: "14",
      },
      {
        type: "txt",
        value: "15",
      },
    ],
  },
  {
    id: 16,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "сократите дробь 125/725",
      },
    ],
    options: [
      {
        type: "txt",
        value: "2/5",
      },
      {
        type: "txt",
        value: "5/29",
      },
      {
        type: "txt",
        value: "1/25",
      },
    ],
  },
  {
    id: 17,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Найдите разность: 2/9 - 2/15",
      },
    ],
    options: [
      {
        type: "txt",
        value: "16/135",
      },
      {
        type: "txt",
        value: "18/60",
      },
      {
        type: "txt",
        value: "8/90",
      },
    ],
  },
  {
    id: 18,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Вычислите: 9/20 - (3/8 - 1/8)",
      },
    ],
    options: [
      {
        type: "txt",
        value: "1/5",
      },
      {
        type: "txt",
        value: "4/20",
      },
      {
        type: "txt",
        value: "6/40",
      },
    ],
  },
  {
    id: 19,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Найдите разность: 30(7/12) - 10(12/124)",
      },
    ],
    options: [
      {
        type: "txt",
        value: "20(3/12)",
      },
      {
        type: "txt",
        value: "20(3/24)",
      },
      {
        type: "txt",
        value: "20(1/12)",
      },
    ],
  },
];

const umumiyMatematika7SinfUz = [
  {
    id: 0,
    question: [{ type: "txt", value: "Tenglamani yeching. 3x+2x=17+(-27)" }],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "-2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 1,
  },
  {
    id: 1,
    question: [
      {
        type: "txt",
        value:
          "To`g`ri to`rtburchakning yuzi 20, 25 dm² ga, eni 3,24 dm ga teng. Shu to`g`ri to`rtburchakning perimetrini toping.",
      },
    ],
    options: [
      { type: "txt", value: "18,98" },
      { type: "txt", value: "9,49" },
      { type: "txt", value: "3,24" },
      { type: "txt", value: "6,25" },
    ],
    answer: 3,
  },
  {
    id: 2,
    question: [
      {
        type: "txt",
        value: "Proporsiyaning noma`lum hadini toping. 3,5:x=2,4:4,8",
      },
    ],
    options: [
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
      { type: "txt", value: "7" },
    ],
    answer: 3,
  },
  {
    id: 3,
    question: [
      {
        type: "txt",
        value: "Amallarni bajaring; ((7,8 : 0,3 - 33) + 3,1) : 0,7",
      },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 2,
  },
  {
    id: 4,
    question: [{ type: "txt", value: "Amallarni bajaring. 9,49-(1,5+0,99)" }],
    options: [
      { type: "txt", value: "7" },
      { type: "txt", value: "6" },
      { type: "txt", value: "5" },
      { type: "txt", value: "4" },
    ],
    answer: 0,
  },
  {
    id: 5,
    question: [
      { type: "txt", value: "Algebraik ifodaning qiymatini toping;" },
      { type: "img", value: "/quiz-images/umumiyMatematika/7-sinf/img1.png" },
      { type: "txt", value: "bunda a=3, c=-1" },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "0" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
    ],
    answer: 1,
  },
  {
    id: 6,
    question: [
      {
        type: "txt",
        value:
          "Ifodani soddalashtiring va son qiymatini toping: 7(10-x)+3 (2x-1) bunda x=-0,048",
      },
    ],
    options: [
      { type: "txt", value: "025,4" },
      { type: "txt", value: "45,20" },
      { type: "txt", value: "67,048" },
      { type: "txt", value: "59,10" },
    ],
    answer: 2,
  },
  {
    id: 7,
    question: [
      {
        type: "txt",
        value: "Arifmetik amallarning hossalaridan foydalanib hisoblang.",
      },
      { type: "img", value: "/quiz-images/umumiyMatematika/7-sinf/img2.png" },
    ],
    options: [
      { type: "txt", value: "-0,28" },
      { type: "txt", value: "0,49" },
      { type: "txt", value: "0,50" },
      { type: "txt", value: "0,28" },
    ],
    answer: 3,
  },
  {
    id: 8,
    question: [{ type: "txt", value: "Soddalashtiring. 8x-(3x-2y)-5y" }],
    options: [
      { type: "txt", value: "7x-y" },
      { type: "txt", value: "5x-3y" },
      { type: "txt", value: "8x-2y" },
      { type: "txt", value: "2y-y" },
    ],
    answer: 1,
  },
  {
    id: 9,
    question: [
      {
        type: "txt",
        value:
          "Avval soddalashtirib keyin uning son qiymatini toping; (3a-4b)-(2a-3b) bunda a=0,12, b=1,28",
      },
    ],
    options: [
      { type: "txt", value: "1,17" },
      { type: "txt", value: "-1,16" },
      { type: "txt", value: "1,18" },
      { type: "txt", value: "2,1" },
    ],
    answer: 1,
  },
  {
    id: 10,
    question: [
      {
        type: "txt",
        value: "Algebraik son qiymatini toping. (a-b) c bunda a=3  b=1,2  c=5",
      },
    ],
    options: [
      { type: "txt", value: "9" },
      { type: "txt", value: "8" },
      { type: "txt", value: "7" },
      { type: "txt", value: "6" },
    ],
    answer: 0,
  },
  {
    id: 11,
    question: [
      {
        type: "txt",
        value: "a=5,1  b=4,7 bo`lsa P=2 (a+b) ifodaning son qiymatini toping. ",
      },
    ],
    options: [
      { type: "txt", value: "19,6" },
      { type: "txt", value: "20" },
      { type: "txt", value: "21" },
      { type: "txt", value: "12,7" },
    ],
    answer: 0,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value:
          "a=2,5  b=2,4 va c=3,5 bo`lsa V=abc ifodaning son qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "20" },
      { type: "txt", value: "21" },
      { type: "txt", value: "19" },
      { type: "txt", value: "18" },
    ],
    answer: 1,
  },
  {
    id: 13,
    question: [
      {
        type: "txt",
        value:
          "a sonini shunday tanlangki 4x-3=2x+a tenglama x=0,3 ildizga ega bo`lsin.",
      },
    ],
    options: [
      { type: "txt", value: "2,4" },
      { type: "txt", value: "-1,8" },
      { type: "txt", value: "1,8" },
      { type: "txt", value: "-2,4" },
    ],
    answer: 3,
  },
  {
    id: 14,
    question: [{ type: "txt", value: "Tenglamani yeching. 8x-(7x+8)=9" }],
    options: [
      { type: "txt", value: "14" },
      { type: "txt", value: "15" },
      { type: "txt", value: "16" },
      { type: "txt", value: "17" },
    ],
    answer: 3,
  },
  {
    id: 15,
    question: [
      { type: "img", value: "/quiz-images/umumiyMatematika/7-sinf/img3.png" },
      { type: "txt", value: "tenglamani yeching. " },
    ],
    options: [
      { type: "txt", value: "15" },
      { type: "txt", value: "14" },
      { type: "txt", value: "13" },
      { type: "txt", value: "12" },
    ],
    answer: 0,
  },
  {
    id: 16,
    question: [
      { type: "txt", value: "Tenglamani yeching. 0,71x+1,98=0,37x-1,76" },
    ],
    options: [
      { type: "txt", value: "-11" },
      { type: "txt", value: "10" },
      { type: "txt", value: "9" },
      { type: "txt", value: "8" },
    ],
    answer: 0,
  },
  {
    id: 17,
    question: [
      {
        type: "txt",
        value:
          "Teng yonli uchburchakning perimetri 51 sm ga teng. Asos yon tomondan 6 sm uzun. Shu uchburchak yon tomonining asosiga nisbatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "5" },
      { type: "img", value: "/quiz-images/umumiyMatematika/7-sinf/img4.png" },
      { type: "txt", value: "7" },
      { type: "txt", value: "-7" },
    ],
    answer: 1,
  },
  {
    id: 18,
    question: [
      {
        type: "txt",
        value: "Proporsiyaning noma`lum hadini toping.  30:10=6:x",
      },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 1,
  },
  {
    id: 19,
    question: [
      { type: "txt", value: "Tenglamani yeching." },
      { type: "img", value: "/quiz-images/umumiyMatematika/7-sinf/img5.png" },
    ],
    options: [
      { type: "txt", value: "5" },
      { type: "txt", value: "7" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 0,
  },
];

const umumiyMatematika7SinfRu = [
  {
    id: 0,
    question: [{ type: "txt", value: "Решите уравнение. 3x+2x=17+(-27)" }],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "-2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 1,
  },
  {
    id: 1,
    question: [
      {
        type: "txt",
        value:
          "Площадь прямоугольника 20, 25 дм², ширина 3,24 дм. Найдите периметр этого прямоугольника.",
      },
    ],
    options: [
      { type: "txt", value: "18,98" },
      { type: "txt", value: "9,49" },
      { type: "txt", value: "3,24" },
      { type: "txt", value: "6,25" },
    ],
    answer: 3,
  },
  {
    id: 2,
    question: [
      {
        type: "txt",
        value: "Найдите неизвестный член пропорции. 3,5:x=2,4:4,8",
      },
    ],
    options: [
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
      { type: "txt", value: "7" },
    ],
    answer: 3,
  },
  {
    id: 3,
    question: [
      {
        type: "txt",
        value: "Вычислите: ((7,8 : 0,3 - 33) + 3,1) : 0,7",
      },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 2,
  },
  {
    id: 4,
    question: [{ type: "txt", value: "Вычислите: 9,49-(1,5+0,99)" }],
    options: [
      { type: "txt", value: "7" },
      { type: "txt", value: "6" },
      { type: "txt", value: "5" },
      { type: "txt", value: "4" },
    ],
    answer: 0,
  },
  {
    id: 5,
    question: [
      { type: "txt", value: "Найдите значение алгебраического выражения:" },
      { type: "img", value: "/quiz-images/umumiyMatematika/7-sinf/img1.png" },
      { type: "txt", value: "где a=3, c=-1" },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "0" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
    ],
    answer: 1,
  },
  {
    id: 6,
    question: [
      {
        type: "txt",
        value:
          "Упростите выражение и найдите числовое значение: 7(10-x)+3 (2x-1), где x=-0,048",
      },
    ],
    options: [
      { type: "txt", value: "025,4" },
      { type: "txt", value: "45,20" },
      { type: "txt", value: "67,048" },
      { type: "txt", value: "59,10" },
    ],
    answer: 2,
  },
  {
    id: 7,
    question: [
      {
        type: "txt",
        value: "Выполните арифметические действия.",
      },
      { type: "img", value: "/quiz-images/umumiyMatematika/7-sinf/img2.png" },
    ],
    options: [
      { type: "txt", value: "-0,28" },
      { type: "txt", value: "0,49" },
      { type: "txt", value: "0,50" },
      { type: "txt", value: "0,28" },
    ],
    answer: 3,
  },
  {
    id: 8,
    question: [{ type: "txt", value: "Упростите. 8x-(3x-2y)-5y" }],
    options: [
      { type: "txt", value: "7x-y" },
      { type: "txt", value: "5x-3y" },
      { type: "txt", value: "8x-2y" },
      { type: "txt", value: "2y-y" },
    ],
    answer: 1,
  },
  {
    id: 9,
    question: [
      {
        type: "txt",
        value:
          "Упростите выражение и найдите числовое значение: (3a-4b)-(2a-3b), где a=0,12, b=1,28 ",
      },
    ],
    options: [
      { type: "txt", value: "1,17" },
      { type: "txt", value: "-1,16" },
      { type: "txt", value: "1,18" },
      { type: "txt", value: "2,1" },
    ],
    answer: 1,
  },
  {
    id: 10,
    question: [
      {
        type: "txt",
        value: "Найдите числовое значение:  (a-b)*c, где a=3  b=1,2  c=5",
      },
    ],
    options: [
      { type: "txt", value: "9" },
      { type: "txt", value: "8" },
      { type: "txt", value: "7" },
      { type: "txt", value: "6" },
    ],
    answer: 0,
  },
  {
    id: 11,
    question: [
      {
        type: "txt",
        value: "Если a=5,1  b=4,7;  P=2(a+b) найдите значение.",
      },
    ],
    options: [
      { type: "txt", value: "19,6" },
      { type: "txt", value: "20" },
      { type: "txt", value: "21" },
      { type: "txt", value: "12,7" },
    ],
    answer: 0,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value: "Если a=2,5  b=2,4 va c=3,5; V=abc найдите числовое значение.",
      },
    ],
    options: [
      { type: "txt", value: "20" },
      { type: "txt", value: "21" },
      { type: "txt", value: "19" },
      { type: "txt", value: "18" },
    ],
    answer: 1,
  },
  {
    id: 13,
    question: [
      {
        type: "txt",
        value: "4x-3=2x+a  Найдите значение a , при этом x=0,3;",
      },
    ],
    options: [
      { type: "txt", value: "2,4" },
      { type: "txt", value: "-1,8" },
      { type: "txt", value: "1,8" },
      { type: "txt", value: "-2,4" },
    ],
    answer: 3,
  },
  {
    id: 14,
    question: [{ type: "txt", value: "Решите уравнение. 8x-(7x+8)=9" }],
    options: [
      { type: "txt", value: "14" },
      { type: "txt", value: "15" },
      { type: "txt", value: "16" },
      { type: "txt", value: "17" },
    ],
    answer: 3,
  },
  {
    id: 15,
    question: [
      { type: "txt", value: "Решите уравнение: " },
      { type: "img", value: "/quiz-images/umumiyMatematika/7-sinf/img3.png" },
    ],
    options: [
      { type: "txt", value: "15" },
      { type: "txt", value: "14" },
      { type: "txt", value: "13" },
      { type: "txt", value: "12" },
    ],
    answer: 0,
  },
  {
    id: 16,
    question: [
      { type: "txt", value: "Решите уравнение: 0,71x+1,98=0,37x-1,76" },
    ],
    options: [
      { type: "txt", value: "-11" },
      { type: "txt", value: "10" },
      { type: "txt", value: "9" },
      { type: "txt", value: "8" },
    ],
    answer: 0,
  },
  {
    id: 17,
    question: [
      {
        type: "txt",
        value:
          "Периметр равнобедренного треугольника равен 51 см. Длина основания на 6 см больше чем боковая сторона. Найдите отношение боковой стороны этого треугольника к основанию.",
      },
    ],
    options: [
      { type: "txt", value: "5" },
      { type: "img", value: "/quiz-images/umumiyMatematika/7-sinf/img4.png" },
      { type: "txt", value: "7" },
      { type: "txt", value: "-7" },
    ],
    answer: 1,
  },
  {
    id: 18,
    question: [
      {
        type: "txt",
        value: "Найдите неизвестный член пропорции.  30:10=6:x",
      },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 1,
  },
  {
    id: 19,
    question: [
      { type: "txt", value: "Решите уравнение." },
      { type: "img", value: "/quiz-images/umumiyMatematika/7-sinf/img5.png" },
    ],
    options: [
      { type: "txt", value: "5" },
      { type: "txt", value: "7" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 0,
  },
];

/* const umumiyMatematika8SinfUz = [
  {
    id: 0,
    question: [
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img1.png" },
      {
        type: "txt",
        value: "ifodani x + 2 ikkihadga bo’lganda 8 qoldiq qolishi ma’lum bo’lsa, b ning qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "8,5" },
      { type: "txt", value: "10 " },
      { type: "txt", value: "−10" },
      { type: "txt", value: "−8,5" },
    ],
    answer: 0,
  },
  {
    id: 1,
    question: [
      {
        type: "txt",
        value:
          "Mahsulotning narxi ketma-ket ikki marta 10% dan oshirildi. Keyinchalik bu mahsulotga talabning kamligi tufayli uning narxi 20% ga kamaytirildi. Mahsulotning keyingi bahosi dastlabki bahosiga qaraganda  qanday o’zgargan?",
      },
    ],
    options: [
      { type: "txt", value: "3,2% ga kamaygan" },
      { type: "txt", value: "1,2% ortgan" },
      { type: "txt", value: "3,2% ortgan" },
      { type: "txt", value: "o’zgarmagan" },
    ],
    answer: 0,
  },
  {
    id: 2,
    question: [
      {
        type: "txt",
        value:
          "Eritma tarkibida 60 g tuz bor. Unga 400 g toza suv qo’shilsa, tuzning konsentratsiyasi 1,5 marta kamayadi. Dastlabki eritma necha gramm bo’lgan?",
      },
    ],
    options: [
      { type: "txt", value: "840" },
      { type: "txt", value: "800" },
      { type: "txt", value: "780" },
      { type: "txt", value: "900" },
    ],
    answer: 1,
  },
  {
    id: 3,
    question: [
      { type: "txt", value: "Ushbu chizmada nechta uchburchak bоr?" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img2.png" },
    ],
    options: [
      { type: "txt", value: "19 ta" },
      { type: "txt", value: "18 ta" },
      { type: "txt", value: "26ta" },
      { type: "txt", value: "21 ta" },
    ],
    answer: 1,
  },
  {
    id: 4,
    question: [
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img3.png" },
      { type: "txt", value: "bo’lsa," },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img4.png" },
      { type: "txt", value: "ifodaning son qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "488" },
      { type: "txt", value: "486" },
      { type: "txt", value: "484" },
      { type: "txt", value: "480" },
    ],
    answer: 1,
  },
  {
    id: 5,
    question: [
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img5.png" },
      {
        type: "txt",
        value: "ifoda qiymati necha xonali son bo’lishini aniqlang.",
      },
    ],
    options: [
      { type: "txt", value: "27" },
      { type: "txt", value: "28" },
      { type: "txt", value: "29" },
      { type: "txt", value: "30" },
    ],
    answer: 2,
  },
  {
    id: 6,
    question: [
      {
        type: "txt",
        value:
          "3, 4, 5, 6, 8, 9 raqamlari yordamida hammasi bo‘lib,  raqamlar takrorlanmasa, nechta uch xonali son tuzish mumkin?",
      },
    ],
    options: [
      { type: "txt", value: "100" },
      { type: "txt", value: "90" },
      { type: "txt", value: "80" },
      { type: "txt", value: "120" },
    ],
    answer: 3,
  },
  {
    id: 7,
    question: [
      {
        type: "txt",
        value:
          "Dastlabki natural sonlar tartib bilan ketma-ket  yozilib, 12345678910111213 ……  ko’rinishidagi ulkan son hosil qilindi. Bu ulkan sonning 2500 – o’rindagi raqamini toping.",
      },
    ],
    options: [
      { type: "txt", value: "7" },
      { type: "txt", value: "6" },
      { type: "txt", value: "8" },
      { type: "txt", value: "9" },
    ],
    answer: 2,
  },
  {
    id: 8,
    question: [
      {
        type: "txt",
        value:
          "It tulkining orqasidan quvdi. It sekundiga 8 m, tulki esa 6 m tezlik bilan chopmoqda. Ularning orasidagi masofa dastlab 140 m bo‘lgan.  It tulkiga yetib olguncha necha km chopishi kerak bo’ladi?",
      },
    ],
    options: [
      { type: "txt", value: "0,56" },
      { type: "txt", value: "0,42" },
      { type: "txt", value: "1,4" },
      { type: "txt", value: "0,7" },
    ],
    answer: 0,
  },
  {
    id: 9,
    question: [
      { type: "txt", value: "Ushbu" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img6.png" },
      {
        type: "txt",
        value: "ifoda n ning nechta natural qiymatida butun son bo’ladi?",
      },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 0,
  },
  {
    id: 10,
    question: [
      { type: "txt", value: "21∙22∙23∙24∙ …… ∙50∙51  ko’paytma qiymati" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img7.png" },
      {
        type: "txt",
        value: "ga bo’linadi. n ning qabul qilishi mumkin bo’lgan eng kichik qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "14" },
      { type: "txt", value: "15" },
      { type: "txt", value: "13" },
      { type: "txt", value: "17" },
    ],
    answer: 3,
  },
  {
    id: 11,
    question: [
      {
        type: "txt",
        value: "Rasmda  berilgan qutiga  eng  ko’pi  bilan rasmda  berilgan kitobdan  nechta sig’adi.",
      },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img8.png" },
    ],
    options: [
      { type: "txt", value: "36" },
      { type: "txt", value: "24" },
      { type: "txt", value: "12" },
      { type: "txt", value: "18" },
    ],
    answer: 2,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value:
          "Qo’shni burchaklardan birining choragi,  ikkinchisining ikkilanganidan  18° kichik. Ulardan kattasining burchak kattaligini toping.",
      },
    ],
    options: [
      { type: "txt", value: "154°" },
      { type: "txt", value: "152°" },
      { type: "txt", value: "156°" },
      { type: "txt", value: "158°" },
    ],
    answer: 1,
  },
  {
    id: 13,
    question: [
      {
        type: "txt",
        value:
          "To’g’ri burchakli uchburchakning  to’g’ri  burchagidan bissektrisa va balandlik chiqarilgan bo’lib, ular orasidagi burchak 15° ga teng. Uchburchakning balandligi va kichik kateti orasidagi burchakni toping.",
      },
    ],
    options: [
      { type: "txt", value: "40°" },
      { type: "txt", value: "20°" },
      { type: "txt", value: "35°" },
      { type: "txt", value: "30°" },
    ],
    answer: 3,
  },
  {
    id: 14,
    question: [
      {
        type: "txt",
        value:
          "Tomoni m sm bo’lgan ABCD kvadrat AB va CD tomonlari 5 sm ga uzaytirilib, AD va BC tomonlari 5 sm ga qisqartirilsa, uning yuzi qanday o’zgaradi?",
      },
    ],
    options: [
      { type: "txt", value: "25 sm² ga kamayadi" },
      { type: "txt", value: "25 sm² ga ortadi" },
      { type: "txt", value: "o’zgarmaydi" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img9.png" },
    ],
    answer: 0,
  },
  {
    id: 15,
    question: [
      {
        type: "txt",
        value:
          "Tomoni 2 m bo’lgan kvadrat  tomoni 5 sm bo’lgan kvadratchalarga ajratildi va bu kvadratchalar 2 qator qilib terildi. Bu qator uzunligini toping.",
      },
    ],
    options: [
      { type: "txt", value: "40m" },
      { type: "txt", value: "80 m" },
      { type: "txt", value: "400sm" },
      { type: "txt", value: "160m" },
    ],
    answer: 0,
  },
  {
    id: 16,
    question: [
      {
        type: "txt",
        value: "n−1 soni 15 ga va 1001 soni n+1 ga bo’linishi ma’lum bo’lsa, n ning qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
      { type: "txt", value: "7" },
    ],
    answer: 2,
  },
  {
    id: 17,
    question: [
      { type: "txt", value: "Hisoblang:" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img10.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img11.png" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img12.png" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img13.png" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img14.png" },
    ],
    answer: 3,
  },
  {
    id: 18,
    question: [
      {
        type: "txt",
        value:
          "Tomoni m sm bo’lgan ABCD kvadrat AB va CD tomonlari 5 sm ga uzaytirilib,  AD va BC tomonlari 5 sm ga qisqartirilsa, uning  perimetri qanday o’zgaradi?",
      },
    ],
    options: [
      { type: "txt", value: "10sm ga ortadi" },
      { type: "txt", value: "o’zgarmaydi" },
      { type: "txt", value: "10sm ga kamayadi" },
      { type: "txt", value: "20 sm  ga kamayadi" },
    ],
    answer: 1,
  },
  {
    id: 19,
    question: [
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img15.png" },
      { type: "txt", value: "bo’lsa," },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img16.png" },
      { type: "txt", value: "ifodaning son qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "-11" },
      { type: "txt", value: "-10" },
      { type: "txt", value: "-20" },
      { type: "txt", value: "-9" },
    ],
    answer: 0,
  },
]; */

const umumiyMatematika8SinfUz = [
  {
    id: 0,
    question: [
      {
        type: "txt",
        value: "Algebraik   ifodaning   son   qiymatini   toping. S = ",
      },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img17.png" },
      { type: "txt", value: "bunda  a=10, b=40, n=16" },
    ],
    options: [
      { type: "txt", value: "500" },
      { type: "txt", value: "400" },
      { type: "txt", value: "300" },
      { type: "txt", value: "200" },
    ],
    answer: 1,
  },
  {
    id: 1,
    question: [
      { type: "txt", value: "Tenglamani yeching. 2( x – 3 ) = 3( 2 - x)" },
    ],
    options: [
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img18.png" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img19.png" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img20.png" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img21.png" },
    ],
    answer: 3,
  },
  {
    id: 2,
    question: [
      { type: "txt", value: "Hisoblang." },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img22.png" },
    ],
    options: [
      { type: "txt", value: "3,6" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img23.png" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img24.png" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img22.png" },
    ],
    answer: 2,
  },
  {
    id: 3,
    question: [
      {
        type: "txt",
        value:
          "Kvadratning uchta uchi berilgan. A ( 3; -2 )   B ( 3; 1)  C ( 6; 1 ). ABCD kvadrat yasang. D  uchining koordinatalarini toping.",
      },
    ],
    options: [
      { type: "txt", value: "D( 6; -2)" },
      { type: "txt", value: "D( 3; -1)" },
      { type: "txt", value: "D( 0; 6)" },
      { type: "txt", value: "D( -6; -3)" },
    ],
    answer: 0,
  },
  {
    id: 4,
    question: [
      {
        type: "txt",
        value:
          "Quyidagi nuqtalarning qaysilari y = x + 3  funksiya  grafigiga tegishli. 1)  ( 0; 7)     2)  ( 0; 3)     3)   (-1; 4)     4)   ( 1; 4)      5)  ( -2; -1)    6)    ( -2; 1)",
      },
    ],
    options: [
      { type: "txt", value: "1; 2; 3." },
      { type: "txt", value: "2; 4; 6." },
      { type: "txt", value: "2; 3; 5." },
      { type: "txt", value: "1;  3;  5." },
    ],
    answer: 1,
  },
  {
    id: 5,
    question: [
      {
        type: "txt",
        value:
          "Quyidagi nuqtalarning qaysilari   y = -x + 5  funksiya  grafigiga tegishli  emas. 1)    ( 1; 4)      2)  ( -3; 8)       3)   (1; 11)     4)   ( 0; 5)     5)   ( -1; -3)     6)    ( 3; 0) ",
      },
    ],
    options: [
      { type: "txt", value: "1; 2; 3." },
      { type: "txt", value: "3; 5;  6." },
      { type: "txt", value: "1;  2; 4." },
      { type: "txt", value: " 1;  4;  6." },
    ],
    answer: 1,
  },
  {
    id: 6,
    question: [
      {
        type: "txt",
        value: "y =2x-1  funksiya  grafigi qaysi  chorakda yotadi?",
      },
    ],
    options: [
      { type: "txt", value: "I, III,  IV." },
      { type: "txt", value: "I,  II,  IV" },
      { type: "txt", value: "II,  III,  IV." },
      { type: "txt", value: "II,  III." },
    ],
    answer: 0,
  },
  {
    id: 7,
    question: [
      {
        type: "txt",
        value:
          "y = kx + 4  funksiya  grafigi  M ( 1; -3) nuqtadan o’tadi.  K ni toping.",
      },
    ],
    options: [
      { type: "txt", value: "8" },
      { type: "txt", value: "1" },
      { type: "txt", value: "-3" },
      { type: "txt", value: "-7" },
    ],
    answer: 3,
  },
  {
    id: 8,
    question: [
      {
        type: "txt",
        value:
          "y = -2x + b  funksiya  grafigi  M ( -2;  8)  nuqtadan o’tadi. B  ni toping.",
      },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
    ],
    answer: 1,
  },
  {
    id: 9,
    question: [
      {
        type: "txt",
        value:
          "y = kx + b  funksiya  grafigi  M ( 0; 1), N ( 1; -5) nuqtalardan o’tadi. K  va b ni toping.",
      },
    ],
    options: [
      { type: "txt", value: "k=-6,  b=1" },
      { type: "txt", value: "k=3,  b=2" },
      { type: "txt", value: "k=-4, b=-1" },
      { type: "txt", value: "k=2,  b=-3" },
    ],
    answer: 0,
  },
  {
    id: 10,
    question: [
      {
        type: "txt",
        value:
          "y = -1,5x - 6  funksiya  grafigining koordinata o’qlari bilan kesishish nuqtalarining  koordinatalarini  toping.",
      },
    ],
    options: [
      { type: "txt", value: "( 1,5; 6) va ( 0; 1,5)" },
      { type: "txt", value: "( 0; -6) va ( 3; 2)" },
      { type: "txt", value: "( 3; 2) va ( 1,5; 6)" },
      { type: "txt", value: "( 0; -6) va ( -4; 0)" },
    ],
    answer: 3,
  },
  {
    id: 11,
    question: [
      {
        type: "txt",
        value:
          "y=-2x funksiya  grafigidan y=-2x + 3 funksiyaning grafigini qanday qilib hosil qilish mumkin?",
      },
    ],
    options: [
      {
        type: "txt",
        value:
          "uch  birlik  y=-2x  funksiya  grafigini  yuqoriga  parallel  ko’chirish   kerak.",
      },
      {
        type: "txt",
        value:
          "uch  birlik  y=-2x  funksiya  grafigini  pastga  parallel  ko’chirish   kerak.",
      },
      {
        type: "txt",
        value:
          "uch  birlik  y=-2x  funksiya  grafigini  o’ngga  parallel  ko’chirish   kerak.",
      },
      {
        type: "txt",
        value:
          "uch  birlik  y=-2x  funksiya  grafigini  chapga  parallel  ko’chirish   kerak.",
      },
    ],
    answer: 0,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value: "y (x) = 3x -1 funksiya berilgan. Y(2) ni toping.",
      },
    ],
    options: [
      { type: "txt", value: "10" },
      { type: "txt", value: "7" },
      { type: "txt", value: "8" },
      { type: "txt", value: "5" },
    ],
    answer: 3,
  },
  {
    id: 13,
    question: [
      {
        type: "txt",
        value:
          "y (x) = 3x -1 funksiya berilgan. Y(x) = 8 bo’lsa,  x  ning qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
    ],
    answer: 0,
  },
  {
    id: 14,
    question: [
      {
        type: "txt",
        value:
          "Qavariq beshburchakning  burchaklari  kattaliklari  2:3:4:5:6  kabi nisbatda.  Burchaklardan kattasining miqdorini toping.",
      },
    ],
    options: [
      { type: "txt", value: "136°" },
      { type: "txt", value: "162°" },
      { type: "txt", value: "156°" },
      { type: "txt", value: "148°" },
    ],
    answer: 1,
  },
  {
    id: 15,
    question: [
      { type: "txt", value: "Tenglamalar  sistemasini  yeching." },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img26.png" },
    ],
    options: [
      { type: "txt", value: "x= 3;  y= 4." },
      { type: "txt", value: "x=-3;  y=-4." },
      { type: "txt", value: "x=2;  y=5." },
      { type: "txt", value: "x=6;  y=1." },
    ],
    answer: 0,
  },
  {
    id: 16,
    question: [
      { type: "txt", value: "Tenglamalar  sistemasini  yeching." },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img27.png" },
    ],
    options: [
      { type: "txt", value: "x= 5;  y= 5." },
      { type: "txt", value: "x=-2;  y=-8." },
      { type: "txt", value: "x=7;  y=3." },
      { type: "txt", value: "x=6;  y=4" },
    ],
    answer: 2,
  },
  {
    id: 17,
    question: [
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img28.png" },
      {
        type: "txt",
        value:
          "x=5  va  y=2  sonlar  juftligi uning yechimi ekanligi ma’lum,  a  va  b  ni toping.",
      },
    ],
    options: [
      { type: "txt", value: "a=-1;  b=18." },
      { type: "txt", value: "a=2;  b=20." },
      { type: "txt", value: "a=3;  b=-18." },
      { type: "txt", value: "a=4;  b=3." },
    ],
    answer: 0,
  },
  {
    id: 18,
    question: [
      { type: "txt", value: "Tenglamalar  sistemasini  yeching." },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img29.png" },
    ],
    options: [
      { type: "txt", value: "x= 6;  y= 4." },
      { type: "txt", value: "x=3;  y=7." },
      { type: "txt", value: "x=2;  y=5." },
      { type: "txt", value: "x=3;  y=2." },
    ],
    answer: 3,
  },
  {
    id: 19,
    question: [
      { type: "txt", value: "Tenglamalar  sistemasini  yeching." },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img30.png" },
    ],
    options: [
      { type: "txt", value: "x= -3;  y=-1." },
      { type: "txt", value: "x=-10;  y=3." },
      { type: "txt", value: "x=-2;  y=1." },
      { type: "txt", value: "x=2;  y=1." },
    ],
    answer: 1,
  },
  {
    id: 20,
    question: [
      { type: "txt", value: "Tenglamalar  sistemasini  yeching." },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img31.png" },
    ],
    options: [
      { type: "txt", value: "x=7;  y=1." },
      { type: "txt", value: "x=5;  y=-2." },
      { type: "txt", value: "x=4;  y=-3." },
      { type: "txt", value: "x=-3;  y=-3." },
    ],
    answer: 0,
  },
  {
    id: 21,
    question: [
      { type: "txt", value: "(x; y)  sonlar juftligi" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img32.png" },
      {
        type: "txt",
        value:
          "tenglamalar  sistemasining yechimi bo’lsa,  y-x   ni  aniqlang.",
      },
    ],
    options: [
      { type: "txt", value: "-1" },
      { type: "txt", value: "-3" },
      { type: "txt", value: "3" },
      { type: "txt", value: "-5" },
    ],
    answer: 3,
  },
  {
    id: 22,
    question: [
      { type: "txt", value: "(x; y)  sonlar juftligi" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img33.png" },
      {
        type: "txt",
        value: "tenglamalar  sistemasining yechimi bo’lsa, x+y   ni  aniqlang.",
      },
    ],
    options: [
      { type: "txt", value: "-4" },
      { type: "txt", value: "-1" },
      { type: "txt", value: "-3" },
      { type: "txt", value: "-2" },
    ],
    answer: 1,
  },
  {
    id: 23,
    question: [
      { type: "txt", value: "(x; y) sonlar juftligi" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img34.png" },
      { type: "txt", value: "tenglamalar  sistemasining yechimi bo’lsa," },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img35.png" },
      { type: "txt", value: "ni  aniqlang." },
    ],
    options: [
      { type: "txt", value: "32" },
      { type: "txt", value: "33" },
      { type: "txt", value: "34" },
      { type: "txt", value: "35" },
    ],
    answer: 0,
  },
  {
    id: 24,
    question: [
      { type: "txt", value: "(x; y)  sonlar juftligi" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img36.png" },
      {
        type: "txt",
        value:
          "tenglamalar  sistemasining yechimi bo’lsa, x² + y² ni  aniqlang.",
      },
    ],
    options: [
      { type: "txt", value: "12" },
      { type: "txt", value: "9" },
      { type: "txt", value: "10" },
      { type: "txt", value: "16" },
    ],
    answer: 2,
  },
  {
    id: 25,
    question: [
      {
        type: "txt",
        value:
          "Uchburchakning o’rta chizig’i  uning asosidan  5,4 sm qisqa. Uchburchakning o’rta chizig’i  bilan  asosining yig’indisini toping.",
      },
    ],
    options: [
      { type: "txt", value: "13,5 sm" },
      { type: "txt", value: "16,2 sm" },
      { type: "txt", value: "10,3 sm" },
      { type: "txt", value: "21,6 sm" },
    ],
    answer: 1,
  },
  {
    id: 26,
    question: [
      {
        type: "txt",
        value:
          "Teng yonli  trapetsiyaning perimetri 36 sm, o’rta chizig’i 10 sm. Yon tomonining uzunligini toping.",
      },
    ],
    options: [
      { type: "txt", value: "15 sm" },
      { type: "txt", value: "8 sm" },
      { type: "txt", value: "12 sm" },
      { type: "txt", value: "13 sm" },
    ],
    answer: 1,
  },
  {
    id: 27,
    question: [
      {
        type: "txt",
        value:
          "Trapetsiyaning  o’rta chizig’i  9 sm, asoslaridan  biri  ikkinchisidan 6 sm  qisqa. Trapersiyaning  katta  asosini  toping.",
      },
    ],
    options: [
      { type: "txt", value: "15 sm" },
      { type: "txt", value: "18 sm" },
      { type: "txt", value: "12 sm" },
      { type: "txt", value: "10 sm" },
    ],
    answer: 2,
  },
  {
    id: 28,
    question: [
      {
        type: "txt",
        value:
          "Trapetsiyaning  kichik  asosi 4 sm, o’rta chizig’i  katta  asosidan 4 sm qisqa. Trapetsiyaning  o’rta chizig’ini   toping.",
      },
    ],
    options: [
      { type: "txt", value: "6 sm" },
      { type: "txt", value: "10 sm" },
      { type: "txt", value: "8 sm" },
      { type: "txt", value: "12 sm" },
    ],
    answer: 2,
  },
  {
    id: 29,
    question: [
      {
        type: "txt",
        value:
          "Teng yonli  trapetsiyaning  diagonali o’tkir  burchagini teng  ikkiga bo’ladi. Agar trapetsiyaning  perimetri 48 sm ga, katta  asosi 18 sm ga teng bo’lsa, uning o’rta  chizig’ini toping.",
      },
    ],
    options: [
      { type: "txt", value: "14 sm" },
      { type: "txt", value: "15 sm" },
      { type: "txt", value: "12 sm" },
      { type: "txt", value: "13 sm" },
    ],
    answer: 0,
  },
];

/* const umumiyMatematika8SinfRu = [
  {
    id: 0,
    question: [
      {
        type: "txt",
        value: "Если при делении",
      },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img1.png" },
      {
        type: "txt",
        value: "на x + 2 в остатке 8 получилось. Найдите значение b",
      },
    ],
    options: [
      { type: "txt", value: "8,5" },
      { type: "txt", value: "10 " },
      { type: "txt", value: "−10" },
      { type: "txt", value: "−8,5" },
    ],
    answer: 0,
  },
  {
    id: 1,
    question: [
      {
        type: "txt",
        value:
          "Цена на товар повышалась на 10% дважды подряд. Позже, из-за низкого спроса на этот товар, его цена была снижена на 20%. Как изменилась последующая цена продукта по сравнению с первоначальной ценой?",
      },
    ],
    options: [
      { type: "txt", value: "На 3,2% уменьшилась" },
      { type: "txt", value: "На 1,2% увеличилась" },
      { type: "txt", value: "На 3,2% увеличилась" },
      { type: "txt", value: "Не изменилась" },
    ],
    answer: 0,
  },
  {
    id: 2,
    question: [
      {
        type: "txt",
        value:
          "Раствор содержит 60 г соли. Если к нему добавить 400 г чистой воды, концентрация соли уменьшится в 1,5 раза. Сколько граммов составлял первоначальный раствор?",
      },
    ],
    options: [
      { type: "txt", value: "840" },
      { type: "txt", value: "800" },
      { type: "txt", value: "780" },
      { type: "txt", value: "900" },
    ],
    answer: 1,
  },
  {
    id: 3,
    question: [
      { type: "txt", value: "Сколько треугольников на этом рисунке?" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img2.png" },
    ],
    options: [
      { type: "txt", value: "19 ta" },
      { type: "txt", value: "18 ta" },
      { type: "txt", value: "26ta" },
      { type: "txt", value: "21 ta" },
    ],
    answer: 1,
  },
  {
    id: 4,
    question: [
      { type: "txt", value: "Если" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img3.png" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img4.png" },
      { type: "txt", value: "найдите числовое значение выражения." },
    ],
    options: [
      { type: "txt", value: "488" },
      { type: "txt", value: "486" },
      { type: "txt", value: "484" },
      { type: "txt", value: "480" },
    ],
    answer: 1,
  },
  {
    id: 5,
    question: [
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img5.png" },
      {
        type: "txt",
        value: "Сколько n-значных число получится в итоге?",
      },
    ],
    options: [
      { type: "txt", value: "27" },
      { type: "txt", value: "28" },
      { type: "txt", value: "29" },
      { type: "txt", value: "30" },
    ],
    answer: 2,
  },
  {
    id: 6,
    question: [
      {
        type: "txt",
        value: "Сколько трехзначных чисел можно составить из чисел 3, 4, 5, 6, 8, 9, если числа не повторяются?",
      },
    ],
    options: [
      { type: "txt", value: "100" },
      { type: "txt", value: "90" },
      { type: "txt", value: "80" },
      { type: "txt", value: "120" },
    ],
    answer: 3,
  },
  {
    id: 7,
    question: [
      {
        type: "txt",
        value:
          "Первые натуральные числа были записаны последовательно по порядку, и образовалось огромное число в виде 12345678910111213……. Найдите 2500-ю цифру этого огромного числа.",
      },
    ],
    options: [
      { type: "txt", value: "7" },
      { type: "txt", value: "6" },
      { type: "txt", value: "8" },
      { type: "txt", value: "9" },
    ],
    answer: 2,
  },
  {
    id: 8,
    question: [
      {
        type: "txt",
        value:
          "Собака погналась за лисой. Собака бежит со скоростью 8 м/c, а лиса – со скоростью 6 м/c. Расстояние между ними изначально составляло 140 м. Сколько километров придется пробежать собаке, прежде чем она доберется до лисы?",
      },
    ],
    options: [
      { type: "txt", value: "0,56" },
      { type: "txt", value: "0,42" },
      { type: "txt", value: "1,4" },
      { type: "txt", value: "0,7" },
    ],
    answer: 0,
  },
  {
    id: 9,
    question: [
      { type: "txt", value: "Сколько n натуральных значений" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img6.png" },
      {
        type: "txt",
        value: "этого выражения будет иметь целое число?",
      },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 0,
  },
  {
    id: 10,
    question: [
      { type: "txt", value: "21∙22∙23∙24∙ …… ∙50∙51 произведение делится на" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img7.png" },
      {
        type: "txt",
        value: ". Найдите наименьшее возможное значение n.",
      },
    ],
    options: [
      { type: "txt", value: "14" },
      { type: "txt", value: "15" },
      { type: "txt", value: "13" },
      { type: "txt", value: "17" },
    ],
    answer: 3,
  },
  {
    id: 11,
    question: [
      {
        type: "txt",
        value:
          "Максимальное количество книг, изображенных на картинке, может поместиться в коробку, показанную на картинке.",
      },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img8.png" },
    ],
    options: [
      { type: "txt", value: "36" },
      { type: "txt", value: "24" },
      { type: "txt", value: "12" },
      { type: "txt", value: "18" },
    ],
    answer: 2,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value:
          "Четверть одного из смежных углов на 18° меньше удвоенного другого. Найдите угловой размер наибольшего из них.",
      },
    ],
    options: [
      { type: "txt", value: "154°" },
      { type: "txt", value: "152°" },
      { type: "txt", value: "156°" },
      { type: "txt", value: "158°" },
    ],
    answer: 1,
  },
  {
    id: 13,
    question: [
      {
        type: "txt",
        value:
          "Биссектриса и высота взяты из прямого угла прямоугольного треугольника, а угол между ними равен 15°. Найдите угол между высотой и малым катетом треугольника.",
      },
    ],
    options: [
      { type: "txt", value: "40°" },
      { type: "txt", value: "20°" },
      { type: "txt", value: "35°" },
      { type: "txt", value: "30°" },
    ],
    answer: 3,
  },
  {
    id: 14,
    question: [
      {
        type: "txt",
        value:
          "Если стороны AB и CD удлинить на 5 см, а стороны AD и BC укоротить на 5 см, как изменится площадь квадрата ABCD со стороной m см?",
      },
    ],
    options: [
      { type: "txt", value: "На 25 см² уменьшится" },
      { type: "txt", value: "На 25 см² увеличится" },
      { type: "txt", value: "Не изменится" },
      { type: "txt", value: "м2 меняется на см²" },
    ],
    answer: 0,
  },
  {
    id: 15,
    question: [
      {
        type: "txt",
        value:
          "Квадрат со стороной 2 м разделили на квадраты со стороной 5 см и выстроили эти квадраты в 2 ряда. Найдите длину этой линии.",
      },
    ],
    options: [
      { type: "txt", value: "40 м" },
      { type: "txt", value: "80 м" },
      { type: "txt", value: "400 см" },
      { type: "txt", value: "160 м" },
    ],
    answer: 0,
  },
  {
    id: 16,
    question: [
      {
        type: "txt",
        value: "Если число n−1 делится на 15 и число 1001 делится на n+1, то найдите значение n.",
      },
    ],
    options: [
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
      { type: "txt", value: "7" },
    ],
    answer: 2,
  },
  {
    id: 17,
    question: [
      { type: "txt", value: "Вычислите:" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img10.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img11.png" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img12.png" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img13.png" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img14.png" },
    ],
    answer: 3,
  },
  {
    id: 18,
    question: [
      {
        type: "txt",
        value:
          "Если, квадрат ABCD со стороной m, стороны AB и CD удлинить на 5 см, а стороны AD и BC укоротить на 5 см, как изменится его периметр?",
      },
    ],
    options: [
      { type: "txt", value: "На 10 см увеличится" },
      { type: "txt", value: "Не изменится" },
      { type: "txt", value: "На 10 см уменьшится" },
      { type: "txt", value: "На 20 см уменьшится" },
    ],
    answer: 1,
  },
  {
    id: 19,
    question: [
      { type: "txt", value: "Если" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img15.png" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img16.png" },
      { type: "txt", value: "найдите числовое значение выражения." },
    ],
    options: [
      { type: "txt", value: "-11" },
      { type: "txt", value: "-10" },
      { type: "txt", value: "-20" },
      { type: "txt", value: "-9" },
    ],
    answer: 0,
  },
]; */

const umumiyMatematika8SinfRu = [
  {
    id: 0,
    question: [
      {
        type: "txt",
        value: "Найдите числовое значение алгебраического выражения. S =",
      },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img17.png" },
      { type: "txt", value: ", где a=10, b=40, n=16" },
    ],
    options: [
      { type: "txt", value: "500" },
      { type: "txt", value: "400" },
      { type: "txt", value: "300" },
      { type: "txt", value: "200" },
    ],
    answer: 1,
  },
  {
    id: 1,
    question: [
      { type: "txt", value: "Решите уравнение. 2( x – 3 ) = 3( 2 - x)" },
    ],
    options: [
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img18.png" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img19.png" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img20.png" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img21.png" },
    ],
    answer: 3,
  },
  {
    id: 2,
    question: [
      { type: "txt", value: "Вычислите:" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img22.png" },
    ],
    options: [
      { type: "txt", value: "3,6" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img23.png" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img24.png" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img22.png" },
    ],
    answer: 2,
  },
  {
    id: 3,
    question: [
      {
        type: "txt",
        value:
          "Даны три вершин квадрата ABCD, A( 3; -2 ); B(3;1); C(6;1). Найти координаты  D вершины.",
      },
    ],
    options: [
      { type: "txt", value: "D( 6; -2)" },
      { type: "txt", value: "D( 3; -1)" },
      { type: "txt", value: "D( 0; 6)" },
      { type: "txt", value: "D( -6; -3)" },
    ],
    answer: 0,
  },
  {
    id: 4,
    question: [
      {
        type: "txt",
        value:
          "Какое из следующих чисел относится к графику функции y = x + 3. 1)  ( 0; 7)     2)  ( 0; 3)     3)   (-1; 4)     4)   ( 1; 4)      5)  ( -2; -1)    6)    ( -2; 1)",
      },
    ],
    options: [
      { type: "txt", value: "1; 2; 3." },
      { type: "txt", value: "2; 4; 6." },
      { type: "txt", value: "2; 3; 5." },
      { type: "txt", value: "1;  3;  5." },
    ],
    answer: 1,
  },
  {
    id: 5,
    question: [
      {
        type: "txt",
        value:
          "Какое из следующих чисел не принадлежит графику функции y = -x + 5. 1)    ( 1; 4)      2)  ( -3; 8)       3)   (1; 11)     4)   ( 0; 5)     5)   ( -1; -3)     6)    ( 3; 0) ",
      },
    ],
    options: [
      { type: "txt", value: "1; 2; 3." },
      { type: "txt", value: "3; 5;  6." },
      { type: "txt", value: "1;  2; 4." },
      { type: "txt", value: " 1;  4;  6." },
    ],
    answer: 1,
  },
  {
    id: 6,
    question: [
      { type: "txt", value: "В каком квадранте лежит график функции y =2x-1?" },
    ],
    options: [
      { type: "txt", value: "I, III,  IV." },
      { type: "txt", value: "I,  II,  IV" },
      { type: "txt", value: "II,  III,  IV." },
      { type: "txt", value: "II,  III." },
    ],
    answer: 0,
  },
  {
    id: 7,
    question: [
      {
        type: "txt",
        value:
          "y = kx + 4  график функции проходит через точку M ( 1; -3). Найдите k.",
      },
    ],
    options: [
      { type: "txt", value: "8" },
      { type: "txt", value: "1" },
      { type: "txt", value: "-3" },
      { type: "txt", value: "-7" },
    ],
    answer: 3,
  },
  {
    id: 8,
    question: [
      {
        type: "txt",
        value:
          "y = -2x + b  график функции проходит через точку M ( -2;  8). Найдите b.",
      },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
    ],
    answer: 1,
  },
  {
    id: 9,
    question: [
      {
        type: "txt",
        value:
          "y = kx + b  график функции проходит через точки M ( 0; 1), N ( 1; -5). Найдите k и b.",
      },
    ],
    options: [
      { type: "txt", value: "k=-6,  b=1" },
      { type: "txt", value: "k=3,  b=2" },
      { type: "txt", value: "k=-4, b=-1" },
      { type: "txt", value: "k=2,  b=-3" },
    ],
    answer: 0,
  },
  {
    id: 10,
    question: [
      {
        type: "txt",
        value:
          "y = -1,5x - 6 найти координаты точек пересечения с координатными осями графика функции.",
      },
    ],
    options: [
      { type: "txt", value: "( 1,5; 6) и ( 0; 1,5)" },
      { type: "txt", value: "( 0; -6) и ( 3; 2)" },
      { type: "txt", value: "( 3; 2) и ( 1,5; 6)" },
      { type: "txt", value: "( 0; -6) и ( -4; 0)" },
    ],
    answer: 3,
  },
  {
    id: 11,
    question: [
      {
        type: "txt",
        value: "Как построить график функции y=-2x + 3 из графика y=-2x?",
      },
    ],
    options: [
      {
        type: "txt",
        value:
          "три единицы y=-2x график функции следует переместить параллельно вверх.",
      },
      {
        type: "txt",
        value:
          "три единицы y=-2x график функции должен быть сдвинут параллельно вниз.",
      },
      {
        type: "txt",
        value:
          "три единицы y=-2x график функции необходимо сместить параллельно вправо.",
      },
      {
        type: "txt",
        value:
          "три единицы y=-2x график функции должен быть сдвинут параллельно влево.",
      },
    ],
    answer: 0,
  },
  {
    id: 12,
    question: [
      { type: "txt", value: "y(x)=3x -1   функция задана. Найдите y(2)." },
    ],
    options: [
      { type: "txt", value: "10" },
      { type: "txt", value: "7" },
      { type: "txt", value: "8" },
      { type: "txt", value: "5" },
    ],
    answer: 3,
  },
  {
    id: 13,
    question: [
      {
        type: "txt",
        value: "Дана функция y(x) =3x-1. Если y(x) = 8, найдите значение x.",
      },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
    ],
    answer: 0,
  },
  {
    id: 14,
    question: [
      {
        type: "txt",
        value:
          "Углы выпуклого пятиугольника относятся как 2:3:4:5:6. Найдите величину большего из углов.",
      },
    ],
    options: [
      { type: "txt", value: "136°" },
      { type: "txt", value: "162°" },
      { type: "txt", value: "156°" },
      { type: "txt", value: "148°" },
    ],
    answer: 1,
  },
  {
    id: 15,
    question: [
      { type: "txt", value: "Решите систему уравнений." },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img26.png" },
    ],
    options: [
      { type: "txt", value: "x= 3;  y= 4." },
      { type: "txt", value: "x=-3;  y=-4." },
      { type: "txt", value: "x=2;  y=5." },
      { type: "txt", value: "x=6;  y=1." },
    ],
    answer: 0,
  },
  {
    id: 16,
    question: [
      { type: "txt", value: "Решите систему уравнений." },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img27.png" },
    ],
    options: [
      { type: "txt", value: "x= 5;  y= 5." },
      { type: "txt", value: "x=-2;  y=-8." },
      { type: "txt", value: "x=7;  y=3." },
      { type: "txt", value: "x=6;  y=4" },
    ],
    answer: 2,
  },
  {
    id: 17,
    question: [
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img28.png" },
      {
        type: "txt",
        value:
          "x=5  и  y=2  известно, что пара чисел является ее решением, найдите a и b.",
      },
    ],
    options: [
      { type: "txt", value: "a=-1;  b=18." },
      { type: "txt", value: "a=2;  b=20." },
      { type: "txt", value: "a=3;  b=-18." },
      { type: "txt", value: "a=4;  b=3." },
    ],
    answer: 0,
  },
  {
    id: 18,
    question: [
      { type: "txt", value: "Решите систему уравнений." },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img29.png" },
    ],
    options: [
      { type: "txt", value: "x= 6;  y= 4." },
      { type: "txt", value: "x=3;  y=7." },
      { type: "txt", value: "x=2;  y=5." },
      { type: "txt", value: "x=3;  y=2." },
    ],
    answer: 3,
  },
  {
    id: 19,
    question: [
      { type: "txt", value: "Решите систему уравнений." },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img30.png" },
    ],
    options: [
      { type: "txt", value: "x= -3;  y=-1." },
      { type: "txt", value: "x=-10;  y=3." },
      { type: "txt", value: "x=-2;  y=1." },
      { type: "txt", value: "x=2;  y=1." },
    ],
    answer: 1,
  },
  {
    id: 20,
    question: [
      { type: "txt", value: "Решите систему уравнений." },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img31.png" },
    ],
    options: [
      { type: "txt", value: "x=7;  y=1." },
      { type: "txt", value: "x=5;  y=-2." },
      { type: "txt", value: "x=4;  y=-3." },
      { type: "txt", value: "x=-3;  y=-3." },
    ],
    answer: 0,
  },
  {
    id: 21,
    question: [
      {
        type: "txt",
        value: "Если пара чисел (x; y) является решением системы уравнений",
      },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img32.png" },
      { type: "txt", value: "определите у-х." },
    ],
    options: [
      { type: "txt", value: "-1" },
      { type: "txt", value: "-3" },
      { type: "txt", value: "3" },
      { type: "txt", value: "-5" },
    ],
    answer: 3,
  },
  {
    id: 22,
    question: [
      {
        type: "txt",
        value: "Если пара чисел (x; y) является решением системы уравнений",
      },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img33.png" },
      { type: "txt", value: "определите x+y." },
    ],
    options: [
      { type: "txt", value: "-4" },
      { type: "txt", value: "-1" },
      { type: "txt", value: "-3" },
      { type: "txt", value: "-2" },
    ],
    answer: 1,
  },
  {
    id: 23,
    question: [
      {
        type: "txt",
        value: "Если пара чисел (x; y) является решением системы уравнений",
      },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img34.png" },
      { type: "txt", value: ",определите" },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img35.png" },
    ],
    options: [
      { type: "txt", value: "32" },
      { type: "txt", value: "33" },
      { type: "txt", value: "34" },
      { type: "txt", value: "35" },
    ],
    answer: 0,
  },
  {
    id: 24,
    question: [
      {
        type: "txt",
        value: "Если пара чисел (x; y) является решением системы уравнений",
      },
      { type: "img", value: "/quiz-images/umumiyMatematika/8-sinf/img36.png" },
      { type: "txt", value: " определите x² + y² ni." },
    ],
    options: [
      { type: "txt", value: "12" },
      { type: "txt", value: "9" },
      { type: "txt", value: "10" },
      { type: "txt", value: "16" },
    ],
    answer: 2,
  },
  {
    id: 25,
    question: [
      {
        type: "txt",
        value:
          "Средняя линия треугольника на 5,4 см короче его основания. Найдите сумму основания и средней линии треугольника.",
      },
    ],
    options: [
      { type: "txt", value: "13,5 см" },
      { type: "txt", value: "16,2 см" },
      { type: "txt", value: "10,3 см" },
      { type: "txt", value: "21,6 см" },
    ],
    answer: 1,
  },
  {
    id: 26,
    question: [
      {
        type: "txt",
        value:
          "Периметр равнобедренной трапеции 36 см, средняя линия 10 см. Найдите длину боковой стороны.",
      },
    ],
    options: [
      { type: "txt", value: "15 см" },
      { type: "txt", value: "8 см" },
      { type: "txt", value: "12 см" },
      { type: "txt", value: "13 см" },
    ],
    answer: 1,
  },
  {
    id: 27,
    question: [
      {
        type: "txt",
        value:
          "Средняя линия трапеции равна 9 см, одно из ее оснований короче другого на 6 см. Найдите большее основание трапеции.",
      },
    ],
    options: [
      { type: "txt", value: "15 см" },
      { type: "txt", value: "18 см" },
      { type: "txt", value: "12 см" },
      { type: "txt", value: "10 см" },
    ],
    answer: 2,
  },
  {
    id: 28,
    question: [
      {
        type: "txt",
        value:
          "Меньшее основание трапеции 4 см, средняя линия на 4 см короче большого основания. Найдите среднюю линию трапеции.",
      },
    ],
    options: [
      { type: "txt", value: "6 см" },
      { type: "txt", value: "10 см" },
      { type: "txt", value: "8 см" },
      { type: "txt", value: "12 см" },
    ],
    answer: 2,
  },
  {
    id: 28,
    question: [
      {
        type: "txt",
        value:
          "Меньшее основание трапеции 4 см, средняя линия на 4 см короче большого основания. Найдите среднюю линию трапеции.",
      },
    ],
    options: [
      { type: "txt", value: "6 см" },
      { type: "txt", value: "10 см" },
      { type: "txt", value: "8 см" },
      { type: "txt", value: "12 см" },
    ],
    answer: 2,
  },
  {
    id: 29,
    question: [
      {
        type: "txt",
        value:
          "Диагональ равнобедренной трапеции делит ее острый угол пополам. Если периметр трапеции 48 см, а большое основание 18 см, найдите ее среднюю линию.",
      },
    ],
    options: [
      { type: "txt", value: "14 см" },
      { type: "txt", value: "15 см" },
      { type: "txt", value: "12 см" },
      { type: "txt", value: "13 см" },
    ],
    answer: 0,
  },
];

const umumiyMatematika9SinfUz = [
  {
    id: 0,
    question: [
      { type: "txt", value: "Qaysi  javobda kvadrat funksiya berilgan?" },
    ],
    options: [
      { type: "txt", value: "y = 2x - 3" },
      { type: "img", value: "/quiz-images/umumiyMatematika/9-sinf/img1.png" },
      { type: "img", value: "/quiz-images/umumiyMatematika/9-sinf/img2.png" },
      { type: "txt", value: "y = 3x + 6" },
    ],
    answer: 1,
  },
  {
    id: 1,
    question: [
      { type: "img", value: "/quiz-images/umumiyMatematika/9-sinf/img3.png" },
      { type: "txt", value: "funksiya grafigi qaysi chorakda yotadi?" },
    ],
    options: [
      { type: "txt", value: "I chorakda" },
      { type: "txt", value: "II chorakda" },
      { type: "txt", value: "III chorakda" },
      { type: "txt", value: "a,b" },
    ],
    answer: 3,
  },
  {
    id: 2,
    question: [
      { type: "img", value: "/quiz-images/umumiyMatematika/9-sinf/img4.png" },
      { type: "txt", value: "funksiyaga tegishli nuqtani toping?" },
    ],
    options: [
      { type: "txt", value: "( 1; 4 )" },
      { type: "txt", value: "( -1; 8 )" },
      { type: "txt", value: "( 1; 8 )" },
      { type: "txt", value: "( -2; 8)" },
    ],
    answer: 3,
  },
  {
    id: 3,
    question: [
      { type: "img", value: "/quiz-images/umumiyMatematika/9-sinf/img5.png" },
      {
        type: "txt",
        value: "funksiya x ning qanday qiymatida 30 ga teng bo’ladi?",
      },
    ],
    options: [
      { type: "txt", value: "x = 6" },
      { type: "txt", value: "x = - 6" },
      { type: "txt", value: "x = 5" },
      { type: "txt", value: "a,b" },
    ],
    answer: 3,
  },
  {
    id: 4,
    question: [
      { type: "img", value: "/quiz-images/umumiyMatematika/9-sinf/img6.png" },
      {
        type: "txt",
        value: "funksiya x = -3 da qanday qiymatiga  teng bo’ladi?",
      },
    ],
    options: [
      { type: "txt", value: "y(x) = -12" },
      { type: "txt", value: "y(x) = 12" },
      { type: "txt", value: "y(x) = 9" },
      { type: "txt", value: "a, b" },
    ],
    answer: 1,
  },
  {
    id: 5,
    question: [
      { type: "img", value: "/quiz-images/umumiyMatematika/9-sinf/img7.png" },
      { type: "txt", value: "funksiyaga tegishli nuqtani toping." },
    ],
    options: [
      { type: "txt", value: "( 3; 10 )" },
      { type: "txt", value: "( -1; 1 )" },
      { type: "txt", value: "( 1; -1 )" },
      { type: "txt", value: "( -3; -9 )" },
    ],
    answer: 1,
  },
  {
    id: 6,
    question: [
      { type: "img", value: "/quiz-images/umumiyMatematika/9-sinf/img8.png" },
      { type: "txt", value: "funksiyaga tegishli nuqtani toping." },
    ],
    options: [
      { type: "txt", value: "( 3; 0 )" },
      { type: "txt", value: "( -1; 1 )" },
      { type: "txt", value: "( 3; 27 )" },
      { type: "txt", value: "( 2; -10 )" },
    ],
    answer: 0,
  },
  {
    id: 7,
    question: [
      { type: "img", value: "/quiz-images/umumiyMatematika/9-sinf/img9.png" },
      { type: "txt", value: "funksiya grafigi qaysi chorakda yotadi?" },
    ],
    options: [
      { type: "txt", value: "I chorakda" },
      { type: "txt", value: "III chorakda" },
      { type: "txt", value: "IV chorakda" },
      { type: "txt", value: "b,d" },
    ],
    answer: 3,
  },
  {
    id: 8,
    question: [
      { type: "img", value: "/quiz-images/umumiyMatematika/9-sinf/img10.png" },
      { type: "txt", value: "tengsizlikni yeching?" },
    ],
    options: [
      { type: "txt", value: "x > 3" },
      { type: "txt", value: "x < - 3" },
      { type: "txt", value: "x >  - 3" },
      { type: "txt", value: "a,b" },
    ],
    answer: 3,
  },
  {
    id: 9,
    question: [
      { type: "img", value: "/quiz-images/umumiyMatematika/9-sinf/img11.png" },
      { type: "txt", value: "tengsizlikni yeching?" },
    ],
    options: [
      { type: "txt", value: "x > 13" },
      { type: "txt", value: "x < - 13" },
      { type: "txt", value: "-13 < x < 13" },
      { type: "txt", value: "a,b" },
    ],
    answer: 2,
  },
  {
    id: 10,
    question: [{ type: "txt", value: "(x-6)(x+7) < 0 tengsizlikni yeching?" }],
    options: [
      { type: "txt", value: "x > 6" },
      { type: "txt", value: "x < - 7" },
      { type: "txt", value: "-7 < x <  6" },
      { type: "txt", value: "a,b" },
    ],
    answer: 2,
  },
  {
    id: 11,
    question: [
      { type: "img", value: "/quiz-images/umumiyMatematika/9-sinf/img12.png" },
      { type: "txt", value: "tengsizlikni yechimi?" },
    ],
    options: [
      { type: "txt", value: "x > 5" },
      { type: "txt", value: "x <  2" },
      { type: "txt", value: "2 < x <  5" },
      { type: "txt", value: "a,b" },
    ],
    answer: 3,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value:
          "Teng yonli uchburchakning  perimetri  42 sm ga  teng. Yon tomoni asosining",
      },
      { type: "img", value: "/quiz-images/umumiyMatematika/9-sinf/img13.png" },
      {
        type: "txt",
        value:
          "qismini tashkil qiladi. Shu uchburchakning asosi yon tomonidan necha sm uzun?",
      },
    ],
    options: [
      { type: "txt", value: "7,5 sm" },
      { type: "txt", value: "6,5 sm" },
      { type: "txt", value: "6 sm" },
      { type: "txt", value: "7sm" },
    ],
    answer: 2,
  },
  {
    id: 13,
    question: [
      {
        type: "txt",
        value:
          "Perimetrlari 39 va 156  bo’lgan  ko’pburchaklarning  o’xshashlik koeffitsiyentini toping?",
      },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
    ],
    answer: 2,
  },
  {
    id: 14,
    question: [
      {
        type: "txt",
        value:
          "Katetlari   3 m  va 7 m bo’lgan to’g’ri burchakli uchburchakka o’xshash bo’lgan  uchburchakning  bir kateti  12 m bo’lsa, ikkinchi katetini  toping?",
      },
    ],
    options: [
      { type: "txt", value: "12 m" },
      { type: "txt", value: "28 m" },
      { type: "txt", value: "21 m" },
      { type: "txt", value: "14 m" },
    ],
    answer: 1,
  },
];

const umumiyMatematika9SinfRu = [
  {
    id: 0,
    question: [
      { type: "txt", value: "В каком ответе дано квадратная функция?" },
    ],
    options: [
      { type: "txt", value: "y = 2x - 3" },
      { type: "img", value: "/quiz-images/umumiyMatematika/9-sinf/img1.png" },
      { type: "img", value: "/quiz-images/umumiyMatematika/9-sinf/img2.png" },
      { type: "txt", value: "y = 3x + 6" },
    ],
    answer: 1,
  },
  {
    id: 1,
    question: [
      { type: "txt", value: "В каком четверти лежит график функции" },
      { type: "img", value: "/quiz-images/umumiyMatematika/9-sinf/img3.png" },
    ],
    options: [
      { type: "txt", value: "I" },
      { type: "txt", value: "II" },
      { type: "txt", value: "III" },
      { type: "txt", value: "a,b ответы верны" },
    ],
    answer: 3,
  },
  {
    id: 2,
    question: [
      { type: "txt", value: "Найдите точку, соответствующую функции" },
      { type: "img", value: "/quiz-images/umumiyMatematika/9-sinf/img4.png" },
    ],
    options: [
      { type: "txt", value: "( 1; 4 )" },
      { type: "txt", value: "( -1; 8 )" },
      { type: "txt", value: "( 1; 8 )" },
      { type: "txt", value: "( -2; 8)" },
    ],
    answer: 3,
  },
  {
    id: 3,
    question: [
      {
        type: "txt",
        value: "При каком значении x функция",
      },
      { type: "img", value: "/quiz-images/umumiyMatematika/9-sinf/img5.png" },
      {
        type: "txt",
        value: "будет равна 30?",
      },
    ],
    options: [
      { type: "txt", value: "x = 6" },
      { type: "txt", value: "x = - 6" },
      { type: "txt", value: "x = 5" },
      { type: "txt", value: "a,b ответы верны" },
    ],
    answer: 3,
  },
  {
    id: 4,
    question: [
      { type: "txt", value: "Каково значение функции" },
      { type: "txt", value: "/quiz-images/umumiyMatematika/9-sinf/img6.png" },
      { type: "txt", value: "при x = -3?" },
    ],
    options: [
      { type: "txt", value: "y(x) = -12" },
      { type: "txt", value: "y(x) = 12" },
      { type: "txt", value: "y(x) = 9" },
      { type: "txt", value: "a,b ответы верны" },
    ],
    answer: 1,
  },
  {
    id: 5,
    question: [
      { type: "txt", value: "Найдите точку, соответствующую функции" },
      { type: "img", value: "/quiz-images/umumiyMatematika/9-sinf/img7.png" },
    ],
    options: [
      { type: "txt", value: "( 3; 10 )" },
      { type: "txt", value: "( -1; 1 )" },
      { type: "txt", value: "( 1; -1 )" },
      { type: "txt", value: "( -3; -9 )" },
    ],
    answer: 1,
  },
  {
    id: 6,
    question: [
      { type: "txt", value: "Найдите точку, соответствующую функции" },
      { type: "img", value: "/quiz-images/umumiyMatematika/9-sinf/img8.png" },
    ],
    options: [
      { type: "txt", value: "( 3; 0 )" },
      { type: "txt", value: "( -1; 1 )" },
      { type: "txt", value: "( 3; 27 )" },
      { type: "txt", value: "( 2; -10 )" },
    ],
    answer: 0,
  },
  {
    id: 7,
    question: [
      { type: "img", value: "/quiz-images/umumiyMatematika/9-sinf/img9.png" },
      { type: "txt", value: "В каком четверти лежит график функции" },
    ],
    options: [
      { type: "txt", value: "I" },
      { type: "txt", value: "III" },
      { type: "txt", value: "IV" },
      { type: "txt", value: "b,d ответы верны" },
    ],
    answer: 3,
  },
  {
    id: 8,
    question: [
      { type: "txt", value: "Решите неравенство?" },
      { type: "img", value: "/quiz-images/umumiyMatematika/9-sinf/img10.png" },
    ],
    options: [
      { type: "txt", value: "x > 3" },
      { type: "txt", value: "x < - 3" },
      { type: "txt", value: "x >  - 3" },
      { type: "txt", value: "a,b ответы верны" },
    ],
    answer: 3,
  },
  {
    id: 9,
    question: [
      { type: "txt", value: "Решите неравенство?" },
      { type: "img", value: "/quiz-images/umumiyMatematika/9-sinf/img11.png" },
    ],
    options: [
      { type: "txt", value: "x > 13" },
      { type: "txt", value: "x < - 13" },
      { type: "txt", value: "-13 < x < 13" },
      { type: "txt", value: "a,b ответы верны" },
    ],
    answer: 2,
  },
  {
    id: 10,
    question: [{ type: "txt", value: "Решите неравенство? (x-6)(x+7) < 0" }],
    options: [
      { type: "txt", value: "x > 6" },
      { type: "txt", value: "x < - 7" },
      { type: "txt", value: "-7 < x <  6" },
      { type: "txt", value: "a,b ответы верны" },
    ],
    answer: 2,
  },
  {
    id: 11,
    question: [
      { type: "txt", value: "Решите неравенство?" },
      { type: "img", value: "/quiz-images/umumiyMatematika/9-sinf/img12.png" },
    ],
    options: [
      { type: "txt", value: "x > 5" },
      { type: "txt", value: "x <  2" },
      { type: "txt", value: "2 < x <  5" },
      { type: "txt", value: "a,b ответы верны" },
    ],
    answer: 3,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value:
          "Периметр равнобедреннего треугольника равен 42 см. Сторона составляет",
      },
      { type: "img", value: "/quiz-images/umumiyMatematika/9-sinf/img13.png" },
      {
        type: "txt",
        value:
          "часть основания. Сколько см длина стороны основания этого треугольника?",
      },
    ],
    options: [
      { type: "txt", value: "7,5 см" },
      { type: "txt", value: "6,5 см" },
      { type: "txt", value: "6 см" },
      { type: "txt", value: "см" },
    ],
    answer: 2,
  },
  {
    id: 13,
    question: [
      {
        type: "txt",
        value:
          "Найдите коэффициент подобия многоугольников с периметрами 39 и 156?",
      },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
    ],
    answer: 2,
  },
  {
    id: 14,
    question: [
      {
        type: "txt",
        value:
          "Прямоугольного треугольника со сторонами 3 м и 7 м подобный ко второму треугольнику, который один катет равен 12 м. Найдите другой катет?",
      },
    ],
    options: [
      { type: "txt", value: "12 m" },
      { type: "txt", value: "28 m" },
      { type: "txt", value: "21 m" },
      { type: "txt", value: "14 m" },
    ],
    answer: 1,
  },
];

const applicantRu = [
  {
    id: 0,
    question: [{ type: "txt", value: "Найдите остаток при делении -27 на 5?" }],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "1" },
    ],
    answer: 1,
  },
  {
    id: 1,
    question: [
      { type: "img", value: "/quiz-images/applicant/img1.png" },
      { type: "txt", value: "Решите систему уравнений." },
    ],
    options: [
      { type: "txt", value: "[-3; 5)" },
      { type: "txt", value: "(2; 4]" },
      { type: "txt", value: "[-6; 6)" },
      { type: "txt", value: "[6; ∞)" },
      { type: "img", value: "/quiz-images/applicant/img2.png" },
    ],
    answer: 2,
  },
  {
    id: 2,
    question: [
      { type: "img", value: "/quiz-images/applicant/img3.png" },
      { type: "txt", value: "Вычислите." },
    ],
    options: [
      { type: "img", value: "/quiz-images/applicant/img4.png" },
      { type: "img", value: "/quiz-images/applicant/img5.png" },
      { type: "img", value: "/quiz-images/applicant/img6.png" },
      { type: "img", value: "/quiz-images/applicant/img7.png" },
      { type: "img", value: "/quiz-images/applicant/img8.png" },
    ],
    answer: 4,
  },
  {
    id: 3,
    question: [
      { type: "txt", value: "Если" },
      { type: "img", value: "/quiz-images/applicant/img9.png" },
      { type: "txt", value: ", то найдите" },
      { type: "img", value: "/quiz-images/applicant/img10.png" },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "txt", value: "-1.2" },
      { type: "txt", value: "-0.6" },
      { type: "txt", value: "-1" },
    ],
    answer: 1,
  },
  {
    id: 4,
    question: [
      { type: "txt", value: "Решите уравнение" },
      { type: "img", value: "/quiz-images/applicant/img11.png" },
    ],
    options: [
      { type: "txt", value: "7" },
      { type: "txt", value: "6" },
      { type: "txt", value: "5" },
      { type: "txt", value: "4" },
    ],
    answer: 0,
  },
  {
    id: 5,
    question: [
      { type: "txt", value: "Вычислите:" },
      { type: "img", value: "/quiz-images/applicant/img12.png" },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "img", value: "/quiz-images/applicant/img13.png" },
      { type: "img", value: "/quiz-images/applicant/img14.png" },
      { type: "txt", value: "3" },
    ],
    answer: 0,
  },
  {
    id: 6,
    question: [{ type: "txt", value: "Вычислите: 1-2+3-4+...+195-196" }],
    options: [
      { type: "txt", value: "98" },
      { type: "txt", value: "-98" },
      { type: "txt", value: "97" },
      { type: "txt", value: "-97" },
    ],
    answer: 1,
  },
  {
    id: 7,
    question: [
      { type: "txt", value: "Вычислите:" },
      { type: "img", value: "/quiz-images/applicant/img15.png" },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "img", value: "/quiz-images/applicant/img16.png" },
      { type: "img", value: "/quiz-images/applicant/img17.png" },
      { type: "img", value: "/quiz-images/applicant/img18.png" },
    ],
    answer: 0,
  },
  {
    id: 8,
    question: [
      { type: "txt", value: "Упростите выражение:" },
      { type: "img", value: "/quiz-images/applicant/img19.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/applicant/img20.png" },
      { type: "img", value: "/quiz-images/applicant/img21.png" },
      { type: "img", value: "/quiz-images/applicant/img22.png" },
      { type: "img", value: "/quiz-images/applicant/img23.png" },
    ],
    answer: 2,
  },
  {
    id: 9,
    question: [
      { type: "txt", value: "Решите уравнение." },
      { type: "img", value: "/quiz-images/applicant/img24.png" },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "-1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "-2" },
    ],
    answer: 1,
  },
  {
    id: 10,
    question: [
      { type: "txt", value: "Сократите дробь." },
      { type: "img", value: "/quiz-images/applicant/img25.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/applicant/img26.png" },
      { type: "img", value: "/quiz-images/applicant/img27.png" },
      { type: "img", value: "/quiz-images/applicant/img28.png" },
      { type: "img", value: "/quiz-images/applicant/img29.png" },
    ],
    answer: 0,
  },
  {
    id: 11,
    question: [{ type: "txt", value: "Вычислите: 38∙45-45∙26+12∙5" }],
    options: [
      { type: "txt", value: "560" },
      { type: "txt", value: "600" },
      { type: "txt", value: "400" },
      { type: "txt", value: "500" },
    ],
    answer: 1,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value: "Найдите сумму цифр числа: 101112131415...47484950",
      },
    ],
    options: [
      { type: "txt", value: "280" },
      { type: "txt", value: "265" },
      { type: "txt", value: "275" },
      { type: "txt", value: "285" },
    ],
    answer: 3,
  },
  {
    id: 13,
    question: [
      { type: "txt", value: "Найдите сумму простых чисел меньше 20." },
    ],
    options: [
      { type: "txt", value: "80" },
      { type: "txt", value: "77" },
      { type: "txt", value: "58" },
      { type: "txt", value: "66" },
    ],
    answer: 2,
  },
  {
    id: 14,
    question: [{ type: "txt", value: "Вычислите: 42567∙42565-42568∙42564" }],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "-3" },
      { type: "txt", value: "-4" },
    ],
    answer: 1,
  },
  {
    id: 15,
    question: [
      {
        type: "txt",
        value: "Определите количество всех натуральных делителей числа 140.",
      },
    ],
    options: [
      { type: "txt", value: "10" },
      { type: "txt", value: "12" },
      { type: "txt", value: "16" },
      { type: "txt", value: "14" },
    ],
    answer: 1,
  },
  // {
  //   id: 16,
  //   question: [
  //     {
  //       type: "txt",
  //       value:
  //         "Quyidagi tasdiqlardan qaysilari to'g'ri? 1. 0 sonining 0-darajasi aniqlanmagan; 2. Qoldiq eng kamida 1 ga teng bo’ladi; 3. Eng katta 5 xonali natural son 9999 ga teng;  4. Bir vaqtda 4ga ham, 15ga ham bo’linadigan sonlar 60ga ham bo’linadi; 5. Agar sonning raqamlari yig’indisi 7 ga bo’linsa, u holda shu sonning o’zi ham 7 ga bo’linadi",
  //     },
  //   ],
  //   options: [
  //     { type: "txt", value: "1, 4 va 5" },
  //     { type: "txt", value: "1, 3 va 4" },
  //     { type: "txt", value: "1, 2 va 4" },
  //     { type: "txt", value: "1 va 4" },
  //   ],
  //   answer: 3,
  // },
  {
    id: 16,
    question: [
      { type: "txt", value: "Если" },
      { type: "img", value: "/quiz-images/applicant/img30.png" },
      { type: "img", value: "/quiz-images/applicant/img31.png" },
      { type: "txt", value: "найдите значение выражения." },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "0" },
      { type: "txt", value: "1" },
    ],
    answer: 3,
  },
  {
    id: 17,
    question: [
      { type: "txt", value: "Вычислите:" },
      { type: "img", value: "/quiz-images/applicant/img32.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/applicant/img33.png" },
      { type: "img", value: "/quiz-images/applicant/img34.png" },
      { type: "img", value: "/quiz-images/applicant/img35.png" },
      { type: "img", value: "/quiz-images/applicant/img36.png" },
    ],
    answer: 1,
  },
  {
    id: 18,
    question: [
      { type: "txt", value: "Вычислите:" },
      { type: "img", value: "/quiz-images/applicant/img37.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/applicant/img38.png" },
      { type: "txt", value: "2" },
      { type: "txt", value: "1" },
      { type: "txt", value: "4" },
    ],
    answer: 2,
  },
  {
    id: 19,
    question: [
      { type: "txt", value: "Найдите наименьшее число среди данных чисел" },
    ],
    options: [
      { type: "img", value: "/quiz-images/applicant/img39.png" },
      { type: "img", value: "/quiz-images/applicant/img40.png" },
      { type: "img", value: "/quiz-images/applicant/img41.png" },
      { type: "img", value: "/quiz-images/applicant/img42.png" },
    ],
    answer: 1,
  },
  {
    id: 20,
    question: [
      { type: "txt", value: "Вычислите:" },
      { type: "img", value: "/quiz-images/applicant/img43.png" },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "img", value: "/quiz-images/applicant/img44.png" },
      { type: "img", value: "/quiz-images/applicant/img45.png" },
    ],
    answer: 3,
  },
  {
    id: 21,
    question: [
      {
        type: "txt",
        value: "n raqamining qanday qiymatlarida",
      },
      { type: "img", value: "/quiz-images/applicant/img71.png" },
      { type: "txt", value: "soni 3 ga qoldiqsiz bo'linadi?" },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "4" },
      { type: "txt", value: "2" },
      { type: "txt", value: "4; 2" },
      { type: "txt", value: "1; 4; 7" },
    ],
    answer: 4,
  },
  {
    id: 22,
    question: [
      { type: "txt", value: "Если" },
      { type: "img", value: "/quiz-images/applicant/img46.png" },
      { type: "txt", value: "то вычислите:" },
      { type: "img", value: "/quiz-images/applicant/img47.png" },
    ],
    options: [
      { type: "txt", value: "4+a" },
      { type: "txt", value: "4-a" },
      { type: "txt", value: "4a" },
      { type: "txt", value: "a-4" },
    ],
    answer: 1,
  },
  {
    id: 23,
    question: [
      { type: "txt", value: "Найдите значение выражения." },
      { type: "img", value: "/quiz-images/applicant/img48.png" },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "img", value: "/quiz-images/applicant/img49.png" },
      { type: "img", value: "/quiz-images/applicant/img50.png" },
      { type: "img", value: "/quiz-images/applicant/img51.png" },
    ],
    answer: 2,
  },
  {
    id: 24,
    question: [
      { type: "txt", value: "Вычислите:" },
      { type: "img", value: "/quiz-images/applicant/img52.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/applicant/img53.png" },
      { type: "img", value: "/quiz-images/applicant/img54.png" },
      { type: "img", value: "/quiz-images/applicant/img55.png" },
      { type: "img", value: "/quiz-images/applicant/img56.png" },
    ],
    answer: 2,
  },
  {
    id: 25,
    question: [
      { type: "img", value: "/quiz-images/applicant/img57.png" },
      { type: "txt", value: "Вычислите половину суммы." },
    ],
    options: [
      { type: "img", value: "/quiz-images/applicant/img58.png" },
      { type: "img", value: "/quiz-images/applicant/img59.png" },
      { type: "img", value: "/quiz-images/applicant/img60.png" },
      { type: "txt", value: "A и C" },
    ],
    answer: 3,
  },
  {
    id: 26,
    question: [
      { type: "txt", value: "Вычислите:" },
      { type: "img", value: "/quiz-images/applicant/img61.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/applicant/img62.png" },
      { type: "img", value: "/quiz-images/applicant/img63.png" },
      { type: "img", value: "/quiz-images/applicant/img64.png" },
      { type: "img", value: "/quiz-images/applicant/img65.png" },
    ],
    answer: 0,
  },
  {
    id: 27,
    question: [
      { type: "txt", value: "Вычислите:" },
      { type: "img", value: "/quiz-images/applicant/img66.png" },
    ],
    options: [
      { type: "txt", value: "6" },
      { type: "txt", value: "2" },
      { type: "txt", value: "4" },
      { type: "txt", value: "3" },
    ],
    answer: 0,
  },
  {
    id: 28,
    question: [{ type: "img", value: "/quiz-images/applicant/img67.png" }],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "0" },
      { type: "img", value: "/quiz-images/applicant/img68.png" },
    ],
    answer: 2,
  },
  {
    id: 29,
    question: [
      { type: "txt", value: "Вычислите:" },
      { type: "img", value: "/quiz-images/applicant/img69.png" },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "img", value: "/quiz-images/applicant/img70.png" },
    ],
    answer: 1,
  },
];

const olympicsClass3 = [
  {
    id: 0,
    question: [
      { type: "txt", value: "So’roq o’rnidagi sonni toping: 3h 35min= ? min" },
    ],
    options: [
      { type: "txt", value: "205" },
      { type: "txt", value: "215" },
      { type: "txt", value: "180" },
      { type: "txt", value: "185" },
    ],
    answer: 1,
  },
  {
    id: 1,
    question: [
      {
        type: "txt",
        value:
          "3 ta mushuk, 2 ta tovuq va 1 ta o’rgimchakning nechta oyog’i bor? (Bitta o’rgimchakning 8 ta oyog’i bor)",
      },
    ],
    options: [
      { type: "txt", value: "22" },
      { type: "txt", value: "18" },
      { type: "txt", value: "24" },
      { type: "txt", value: "20" },
    ],
    answer: 2,
  },
  {
    id: 2,
    question: [
      {
        type: "txt",
        value:
          "Zumrad birinchi haftada 250 betli kitobning 120 betini, ikkinchi haftada esa birinchi haftaga qaraganda 10 bet kam o’qidi. Zumradga o’qishi uchun kitobning yana necha beti qoldi?",
      },
    ],
    options: [
      { type: "txt", value: "30" },
      { type: "txt", value: "25" },
      { type: "txt", value: "20" },
      { type: "txt", value: "15" },
    ],
    answer: 2,
  },
  {
    id: 3,
    question: [{ type: "txt", value: "Hisoblang: 950-(850-36)" }],
    options: [
      { type: "txt", value: "136" },
      { type: "txt", value: "64" },
      { type: "txt", value: "48" },
      { type: "txt", value: "100" },
    ],
    answer: 1,
  },
  {
    id: 4,
    question: [{ type: "txt", value: "Tenglamani yeching. 506-x=640-379" }],
    options: [
      { type: "txt", value: "145" },
      { type: "txt", value: "261" },
      { type: "txt", value: "134" },
      { type: "txt", value: "245" },
    ],
    answer: 3,
  },
  {
    id: 5,
    question: [
      {
        type: "txt",
        value:
          "Daraxtzorda 957 ta daraxt bor. Ushbu daraxtlardan 400 tasi archa, 334 tasi terak, qolganlari esa tut daraxti. Daraxtzorda  nechta tut daraxti bor?",
      },
    ],
    options: [
      { type: "txt", value: "223" },
      { type: "txt", value: "557" },
      { type: "txt", value: "443" },
      { type: "txt", value: "127" },
    ],
    answer: 0,
  },
  {
    id: 6,
    question: [
      {
        type: "txt",
        value: "Ushbu tenglikda 44 nimani bildiradi? 2×22=44",
      },
    ],
    options: [
      { type: "txt", value: "bo’linma" },
      { type: "txt", value: "ko’paytma" },
      { type: "txt", value: "ko’paytuvchi" },
      { type: "txt", value: "bo’linuvchi" },
    ],
    answer: 1,
  },
  {
    id: 7,
    question: [
      {
        type: "txt",
        value:
          "Agar bitta paketga ko’pi bilan 6 ta futbolka joylashtirish mumkin bo’lsa, 503 ta futbolkaning hammasini joylashtirish uchun nechta paket kerak bo’ladi?",
      },
    ],
    options: [
      { type: "txt", value: "83" },
      { type: "txt", value: "84" },
      { type: "txt", value: "85" },
      { type: "txt", value: "82" },
    ],
    answer: 1,
  },
  {
    id: 8,
    question: [
      {
        type: "txt",
        value:
          "Bo’yi 5cm, eni bo’yidan 3cm ga uzun bo’lgan to’g’ri to’rtburchakning perimetrini toping.",
      },
    ],
    options: [
      { type: "txt", value: "20cm" },
      { type: "txt", value: "26cm" },
      { type: "txt", value: "28cm" },
      { type: "txt", value: "30cm" },
    ],
    answer: 1,
  },
  {
    id: 9,
    question: [
      {
        type: "txt",
        value:
          "Sayfiddin va Bahodir uzoq masofaga yugurdi. Sayfiddin 2700 metr masofaga yugurdi. Bahodir esa Sayfiddindan 3 marta kam masofaga yugurdi. Sayfiddin Bahodirdan qancha masofa ko’proq yugurgan?",
      },
    ],
    options: [
      { type: "txt", value: "900 metr" },
      { type: "txt", value: "1200 metr" },
      { type: "txt", value: "1800 metr" },
      { type: "txt", value: "300 metr" },
    ],
    answer: 2,
  },
  {
    id: 10,
    question: [
      {
        type: "txt",
        value:
          "Muslima 3-sinfda o’qiydi. Quyidagi javoblardan qaysi biri Muslimaning og’irligi bo’lishi mumkin?",
      },
    ],
    options: [
      { type: "txt", value: "30kg" },
      { type: "txt", value: "500kg" },
      { type: "txt", value: "1t" },
      { type: "txt", value: "7q" },
    ],
    answer: 0,
  },
  {
    id: 11,
    question: [
      {
        type: "txt",
        value:
          "Yura bog’iga 1-kuni 122 kishi tashrif buyurdi. Yomon ob-havo tufayli 2-kuni dastlabki kundan 56 kishi kamroq tashrif buyurdi. 3-kuni ob-havo yaxshi edi va tashrif buyuruvchilar soni 2-kuni tashrif buyuruvchilar sonidan 3 marta ko’p bo’ldi. Yura bog’iga shu 3 kun davomida necha kishi tashrif buyurdi?",
      },
    ],
    options: [
      { type: "txt", value: "350" },
      { type: "txt", value: "442" },
      { type: "txt", value: "320" },
      { type: "txt", value: "386" },
    ],
    answer: 3,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value:
          "Zuhridin aka ishdan kech qaytdi. Qorong’u tushgan edi. Soatiga qaraganda vaqt quyidagicha edi. Zuhriddin aka uyiga kelganda soat 24 soatli vaqt bo’yicha nechani ko’rsatayotgan edi?",
      },
      { type: "img", value: "/quiz-images/olympics/class-3/img1.png" },
    ],
    options: [
      { type: "txt", value: "09:45" },
      { type: "txt", value: "09:50" },
      { type: "txt", value: "21:45" },
      { type: "txt", value: "21:50" },
    ],
    answer: 2,
  },
  {
    id: 13,
    question: [
      {
        type: "txt",
        value: "Massasi",
      },
      { type: "img", value: "/quiz-images/olympics/class-3/img2.png" },
      { type: "txt", value: "bo’lgan pomidorga," },
      { type: "img", value: "/quiz-images/olympics/class-3/img3.png" },
      { type: "txt", value: "bodring va" },
      { type: "img", value: "/quiz-images/olympics/class-3/img4.png" },
      {
        type: "txt",
        value: "piyoz qo’shib salat tayyorlandi. Salatning massasini toping?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/olympics/class-3/img5.png" },
      { type: "img", value: "/quiz-images/olympics/class-3/img6.png" },
      { type: "img", value: "/quiz-images/olympics/class-3/img7.png" },
      { type: "img", value: "/quiz-images/olympics/class-3/img8.png" },
    ],
    answer: 1,
  },
  {
    id: 14,
    question: [
      {
        type: "txt",
        value: "Berilgan shaklning perimetrini toping.",
      },
      { type: "img", value: "/quiz-images/olympics/class-3/img9.png" },
    ],
    options: [
      { type: "txt", value: "12m" },
      { type: "txt", value: "18m" },
      { type: "txt", value: "7m" },
      { type: "txt", value: "9m" },
    ],
    answer: 1,
  },
];

const olympicsClass4 = [
  {
    id: 0,
    question: [
      {
        type: "txt",
        value: "So’roq o’rnidagi sonni toping: ? ta minglar = 7000 ta o’nlar",
      },
    ],
    options: [
      { type: "txt", value: "70" },
      { type: "txt", value: "7" },
      { type: "txt", value: "10" },
      { type: "txt", value: "1" },
    ],
    answer: 0,
  },
  {
    id: 1,
    question: [
      {
        type: "txt",
        value: "Ifodaning qiymatini toping: 1+1⸳1-1:1+(1+1-1):1+1-(1+1)",
      },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
    ],
    answer: 1,
  },
  {
    id: 2,
    question: [
      {
        type: "txt",
        value:
          "Lift binoning 1-qavatidan 5-qavatigacha 24 sekundda chiqadi. Liftda 1-qavatdan 6-qavatga necha sekundda chiqiladi?",
      },
    ],
    options: [
      { type: "txt", value: "30" },
      { type: "txt", value: "25" },
      { type: "txt", value: "20" },
      { type: "txt", value: "28" },
    ],
    answer: 0,
  },
  {
    id: 3,
    question: [
      {
        type: "txt",
        value:
          "Ikkita javonda 12 ta kitob bor. Birinchi javondan ikkinchi javonga, ikkinchi javonda nechta kitob bo’lsa, shuncha kitob qo’yilsa, javonlardagi kitoblar soni tenglashadi. Birinchi javonda nechta kitob bo’lgan?",
      },
    ],
    options: [
      { type: "txt", value: "6" },
      { type: "txt", value: "9" },
      { type: "txt", value: "8" },
      { type: "txt", value: "10" },
    ],
    answer: 1,
  },
  {
    id: 4,
    question: [
      {
        type: "txt",
        value: "Tenglamani yeching. 6305:x=11640-10379",
      },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "1261" },
      { type: "txt", value: "5" },
      { type: "txt", value: "15" },
    ],
    answer: 2,
  },
  {
    id: 5,
    question: [
      {
        type: "txt",
        value:
          "Zafarning uyida kichik ziyofat bo’lib o’tdi. Unda 5 ta odam yig’ilgan edi. Agar ular hammasi bir-biri bilan salomlashib chiqishsa, jami salomlashishlar soni nechta bo’ladi?",
      },
    ],
    options: [
      { type: "txt", value: "8" },
      { type: "txt", value: "10" },
      { type: "txt", value: "5" },
      { type: "txt", value: "15" },
    ],
    answer: 1,
  },
  {
    id: 6,
    question: [
      {
        type: "txt",
        value:
          "Jahongir ikki kunda jami 120 ta masala yechdi. U birinchi kuni 45 ta masala yechdi. Jahongir ikkinchi kuni birinchi kunga qaraganda nechta ko’p masala yechgan?",
      },
    ],
    options: [
      { type: "txt", value: "75" },
      { type: "txt", value: "65" },
      { type: "txt", value: "45" },
      { type: "txt", value: "30" },
    ],
    answer: 3,
  },
  {
    id: 7,
    question: [
      {
        type: "txt",
        value:
          "Qonuniyatni aniqlab so’roq o’rnidagi 3 ta sonni toping: 15, 5, 8, 24, 21, 7, 10, 10, 30, ?, ?, ?, 36, 33",
      },
    ],
    options: [
      { type: "txt", value: "27, 9, 12" },
      { type: "txt", value: "10, 13, 39" },
      { type: "txt", value: "33, 11, 12" },
      { type: "txt", value: "32, 34, 35" },
    ],
    answer: 1,
  },
  {
    id: 8,
    question: [
      {
        type: "txt",
        value:
          "Toshbaqa va shilliqurt qarama-qarshi tomonga harakat qilyapti. Agar toshbaqaning tezligi 22 cm/min, shilliqurtning tezligi 14 cm/min bo’lsa, 2 minutdan keyin ular orasidagi masofa qancha bo’ladi?",
      },
    ],
    options: [
      { type: "txt", value: "28cm" },
      { type: "txt", value: "44cm" },
      { type: "txt", value: "72cm" },
      { type: "txt", value: "80cm" },
    ],
    answer: 2,
  },
  {
    id: 9,
    question: [
      {
        type: "txt",
        value:
          "Bog’da bir nechta daraxt va qushlar bor.Har bir daraxtga bittadan qush qo’nsa, bitta daraxt yetmay qoladi, agar juft-juft bo’lib qo’nsa, bitta daraxt ortib qoladi. Bog’da nechta daraxt va qushlar bor?",
      },
    ],
    options: [
      { type: "txt", value: "4 ta daraxt, 2 ta qush" },
      { type: "txt", value: "3 ta daraxt, 4 ta qush" },
      { type: "txt", value: "4 ta daraxt, 2 ta qush" },
      { type: "txt", value: "4 ta daraxt, 3 ta qush" },
    ],
    answer: 1,
  },
  {
    id: 10,
    question: [
      {
        type: "txt",
        value: "Quyidagi rim raqami qaysi sonni bildiradi: MCDXLIX",
      },
    ],
    options: [
      { type: "txt", value: "1150" },
      { type: "txt", value: "1449" },
      { type: "txt", value: "1411" },
      { type: "txt", value: "14419" },
    ],
    answer: 1,
  },
  {
    id: 11,
    question: [
      {
        type: "txt",
        value:
          "Oshxonada 5 ta katta qozon bor. Har bir katta qozon ichida 2 tadan kichik qozon bor. Har bir kichik qozon ichida 3 tadan qoshiq bor. Oshxonada jami nechta qoshiq bor?",
      },
    ],
    options: [
      { type: "txt", value: "30" },
      { type: "txt", value: "10" },
      { type: "txt", value: "15" },
      { type: "txt", value: "40" },
    ],
    answer: 0,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value: "Qaysi nuqta koordinatasi (3;5) ni ko’rsatadi.",
      },
      {
        type: "img",
        value: "/quiz-images/olympics/class-4/img1.png",
      },
    ],
    options: [
      { type: "txt", value: "4" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "1" },
    ],
    answer: 3,
  },
  {
    id: 13,
    question: [
      {
        type: "txt",
        value: "Massasi",
      },
      {
        type: "img",
        value: "/quiz-images/olympics/class-4/img2.png",
      },
      {
        type: "txt",
        value: "bo’lgan pomidorga,",
      },
      {
        type: "img",
        value: "/quiz-images/olympics/class-4/img3.png",
      },
      {
        type: "txt",
        value: "bodring va",
      },
      {
        type: "img",
        value: "/quiz-images/olympics/class-4/img4.png",
      },
      {
        type: "txt",
        value: "piyoz qo’shib salat tayyorlandi. Salatning massasini toping?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/olympics/class-4/img5.png" },
      { type: "img", value: "/quiz-images/olympics/class-4/img6.png" },
      { type: "img", value: "/quiz-images/olympics/class-4/img7.png" },
      { type: "img", value: "/quiz-images/olympics/class-4/img8.png" },
    ],
    answer: 1,
  },
  {
    id: 14,
    question: [
      {
        type: "txt",
        value: "Berilgan shaklning perimetrini toping.",
      },
      {
        type: "img",
        value: "/quiz-images/olympics/class-4/img9.png",
      },
    ],
    options: [
      { type: "txt", value: "12m" },
      { type: "txt", value: "18m" },
      { type: "txt", value: "7m" },
      { type: "txt", value: "9m" },
    ],
    answer: 1,
  },
];

const olympicsClass5 = [
  {
    id: 0,
    question: [
      { type: "txt", value: "Hisoblang:" },
      { type: "img", value: "/quiz-images/olympics/class-5/img1.png" },
    ],
    options: [
      { type: "txt", value: "699" },
      { type: "txt", value: "709" },
      { type: "txt", value: "691" },
      { type: "txt", value: "698" },
    ],
    answer: 0,
  },
  {
    id: 1,
    question: [
      {
        type: "txt",
        value: "Berilgan rasmdan bo‘yalgan uchburchak yuzini toping:",
      },
      { type: "img", value: "/quiz-images/olympics/class-5/img2.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/olympics/class-5/img3.png" },
      { type: "img", value: "/quiz-images/olympics/class-5/img4.png" },
      { type: "img", value: "/quiz-images/olympics/class-5/img5.png" },
      { type: "img", value: "/quiz-images/olympics/class-5/img6.png" },
    ],
    answer: 1,
  },
  {
    id: 2,
    question: [
      {
        type: "txt",
        value:
          "Botir, Nodir va Qodir birgalikda bog‘dan 92 ta olma terib olishdi. Tushlikda Botir 5 ta, Nodir 2 ta, Qodir esa 4 ta olma yedi. Shundan so‘ng har bir bolada baravar miqdorda olma qoldi. Har bir bola bog‘dan nechtadan olma tergan?",
      },
    ],
    options: [
      { type: "txt", value: "Botir – 32 ta,	Nodir – 29 ta, Qodir – 31ta." },
      { type: "txt", value: "Botir – 31 ta,	Nodir – 28 ta, Qodir – 33ta." },
      { type: "txt", value: "Botir – 31 ta,	Nodir – 30 ta, Qodir – 31ta." },
      { type: "txt", value: "Botir – 29 ta,	Nodir – 31 ta, Qodir – 32ta." },
    ],
    answer: 0,
  },
  {
    id: 3,
    question: [
      {
        type: "txt",
        value:
          "Kinoteatrning tomosha zalida 18 ta qator, har bir qatorda 22 tadan joy bor. Agar chiptaning narxi 200 so‘mdan bo‘lib, 3 seansning hammasiga chiptalar sotilsa, kinoteatrga qancha pul tushadi?",
      },
    ],
    options: [
      { type: "txt", value: "237 000 so‘m" },
      { type: "txt", value: "253 800 so‘m" },
      { type: "txt", value: "237 600 so‘m" },
      { type: "txt", value: "79 200 so‘m" },
    ],
    answer: 2,
  },
  {
    id: 4,
    question: [
      {
        type: "txt",
        value:
          "Sinfda beshta o‘quvchi bir-biri bilan qo‘l berib salomlashmoqda. Salomlashishlar soni nechta bo‘ladi?",
      },
    ],
    options: [
      { type: "txt", value: "10" },
      { type: "txt", value: "20" },
      { type: "txt", value: "24" },
      { type: "txt", value: "16" },
    ],
    answer: 0,
  },
  {
    id: 5,
    question: [
      {
        type: "txt",
        value:
          "Firma birinchi 5 yilda 400 ta mahsulot ishlab chiqdi. Keyin har yili bir xil miqdorda mahsulot ishlab chiqara boshladi va jami 35 yilda 4000 ta mahsulot ishlab chiqdi. Firma birinchi 29 yilda jami nechta mahsulot ishlab chiqqan?",
      },
    ],
    options: [
      { type: "txt", value: "3260 ta" },
      { type: "txt", value: "3260 ta" },
      { type: "txt", value: "3480 ta" },
      { type: "txt", value: "3280 ta" },
    ],
    answer: 3,
  },
  {
    id: 6,
    question: [
      {
        type: "txt",
        value:
          "Koordinata nurida 𝐴(16), 𝐵(24) va 𝐷(23) nuqtalar berilgan. 𝐴𝐵 kesma o‘rtasidan  𝐷 nuqtagacha nechta birlik kesma bor?",
      },
    ],
    options: [
      { type: "txt", value: "4" },
      { type: "txt", value: "3" },
      { type: "txt", value: "5" },
      { type: "txt", value: "2" },
    ],
    answer: 1,
  },
  {
    id: 7,
    question: [
      {
        type: "txt",
        value: "Tenglamani yeching: (14 − 56 ∶ (3 + 𝑥)) ∙ 7 = 42",
      },
    ],
    options: [
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
      { type: "txt", value: "1" },
    ],
    answer: 0,
  },
  {
    id: 8,
    question: [
      {
        type: "txt",
        value:
          "Bir son ikkinchisidan 330 ga katta. Agar katta sonni kichigiga bo‘linsa, bo‘linma 3, qoldiqda 10 chiqadi. Shu sonlar yig‘indisini toping.",
      },
    ],
    options: [
      { type: "txt", value: "550" },
      { type: "txt", value: "343" },
      { type: "txt", value: "650" },
      { type: "txt", value: "490" },
    ],
    answer: 2,
  },
  {
    id: 9,
    question: [
      {
        type: "txt",
        value:
          "10.	Qonuniyatni aniqlang va bo‘sh kataklarda qaysi sonlar bo‘lishini aniqlang.",
      },
      { type: "img", value: "/quiz-images/olympics/class-5/img7.png" },
    ],
    options: [
      { type: "txt", value: "80; 115." },
      { type: "txt", value: "63; 70." },
      { type: "txt", value: "77; 84." },
      { type: "txt", value: "84; 98." },
    ],
    answer: 0,
  },
  {
    id: 10,
    question: [
      {
        type: "txt",
        value:
          "Anor nokdan 3 marta arzon. 5 kg anor va 3 kg nok uchun 61600 so‘m to‘langan bo‘lsa, 1 kg nok 2 kg anordan necha so‘mga qimmat ekanligini aniqlang.",
      },
    ],
    options: [
      { type: "txt", value: "4000 so‘m" },
      { type: "txt", value: "4600 so‘m" },
      { type: "txt", value: "13200 so‘m" },
      { type: "txt", value: "4400 so‘m" },
    ],
    answer: 3,
  },
  {
    id: 11,
    question: [
      {
        type: "txt",
        value:
          "Mohinur 24400 so‘mga kitob va ruchka oldi. Kitobning narxi ruchkaning narxidan 16000 so‘m qimmat turadi. Agar Mohinur 6 ta ruchka olganligi ma’lum bo‘lsa, kitob narxini aniqlang.",
      },
    ],
    options: [
      { type: "txt", value: "18200 so‘m" },
      { type: "txt", value: "17200 so‘m" },
      { type: "txt", value: "17600 so‘m" },
      { type: "txt", value: "18600 so‘m" },
    ],
    answer: 1,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value:
          "Qonuniyatni aniqlang va so‘roq belgisi o‘rnidagi mos sonni toping.",
      },
      { type: "img", value: "/quiz-images/olympics/class-5/img8.png" },
    ],
    options: [
      { type: "txt", value: "57" },
      { type: "txt", value: "64" },
      { type: "txt", value: "72" },
      { type: "txt", value: "86" },
    ],
    answer: 0,
  },
  {
    id: 13,
    question: [
      {
        type: "txt",
        value:
          "Qonuniyatni aniqlang va so‘roq belgisi o‘rnidagi mos qiymatni toping.",
      },
      { type: "img", value: "/quiz-images/olympics/class-5/img9.png" },
    ],
    options: [
      { type: "txt", value: "19" },
      { type: "txt", value: "4" },
      { type: "txt", value: "13" },
      { type: "txt", value: "8" },
    ],
    answer: 2,
  },
  {
    id: 14,
    question: [
      {
        type: "txt",
        value:
          "Sayyoh rejalashtirgan manziliga yetib borishi uchun 84 km yo‘l bosishi kerak. U yo‘lning",
      },
      { type: "img", value: "/quiz-images/olympics/class-5/img10.png" },
      { type: "txt", value: "qismini velosipedda," },
      { type: "img", value: "/quiz-images/olympics/class-5/img11.png" },
      {
        type: "txt",
        value:
          "qismini piyoda o’tdi. Manzilga yetish uchun yana necha kilometr yo‘l qoldi?",
      },
    ],
    options: [
      { type: "txt", value: "12 km" },
      { type: "txt", value: "8 km" },
      { type: "txt", value: "9 km" },
      { type: "txt", value: "7 km" },
    ],
    answer: 3,
  },
];

const olympicsClass6 = [
  {
    id: 0,
    question: [
      {
        type: "txt",
        value: "Tenglamani yeching: -4x + 3 - 2 ( -1 - 3x ) = -5",
      },
    ],
    options: [
      { type: "txt", value: "-1" },
      { type: "txt", value: "-5" },
      { type: "txt", value: "1" },
      { type: "txt", value: "0" },
    ],
    answer: 1,
  },
  {
    id: 1,
    question: [
      {
        type: "txt",
        value: "Ifodaning qiymatini toping:",
      },
      {
        type: "img",
        value: "/quiz-images/olympics/class-6/img1.png",
      },
    ],
    options: [
      { type: "txt", value: "0,09" },
      { type: "txt", value: "-0,9" },
      { type: "txt", value: "-0,09" },
      { type: "txt", value: "0,9" },
    ],
    answer: 1,
  },
  {
    id: 2,
    question: [
      {
        type: "txt",
        value: "Radiusi 8,1 sm bo‘lgan doira yuzining",
      },
      {
        type: "img",
        value: "/quiz-images/olympics/class-6/img2.png",
      },
      {
        type: "txt",
        value: "qismini toping. (π = 3,1 deb hisoblang)",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/olympics/class-6/img3.png" },
      { type: "img", value: "/quiz-images/olympics/class-6/img4.png" },
      { type: "img", value: "/quiz-images/olympics/class-6/img5.png" },
      { type: "img", value: "/quiz-images/olympics/class-6/img6.png" },
    ],
    answer: 1,
  },
  {
    id: 3,
    question: [
      {
        type: "txt",
        value: "Radiusi 7,2 sm va aylana uzunligining",
      },
      {
        type: "img",
        value: "/quiz-images/olympics/class-6/img7.png",
      },
      {
        type: "txt",
        value:
          "qismiga teng bo‘lgan yoyning uzunligini toping. Bunda π = 3,14 .",
      },
    ],
    options: [
      { type: "txt", value: "5,652 sm" },
      { type: "txt", value: "16,96 sm" },
      { type: "txt", value: "16,956 sm" },
      { type: "txt", value: "16,965 sm" },
    ],
    answer: 2,
  },
  {
    id: 4,
    question: [
      {
        type: "img",
        value: "/quiz-images/olympics/class-6/img8.png",
      },
      {
        type: "txt",
        value: "sonining oxirgi raqamini toping.",
      },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "7" },
      { type: "txt", value: "3" },
      { type: "txt", value: "9" },
    ],
    answer: 3,
  },
  {
    id: 5,
    question: [
      {
        type: "txt",
        value:
          "Ikkita sonning yig‘indisi 140 ga teng. Birinchi sonning 8 % i ikkinchi sonning 6 % i ga teng. Shu sonlarni toping.",
      },
    ],
    options: [
      { type: "txt", value: "60; 80" },
      { type: "txt", value: "75; 65" },
      { type: "txt", value: "50; 90" },
      { type: "txt", value: "40; 100" },
    ],
    answer: 0,
  },
  {
    id: 6,
    question: [
      {
        type: "txt",
        value:
          "Agar tijoratchi molning 1 kg ini 40 so‘mdan sotsa, 1800 so‘m zarar ko‘radi. 1 kg ini 70 so‘mdan sotsa, 900 so‘m foyda ko‘radi. Tijoratchida necha kg mol bo‘lgan?",
      },
    ],
    options: [
      { type: "txt", value: "90" },
      { type: "txt", value: "100" },
      { type: "txt", value: "60" },
      { type: "txt", value: "70" },
    ],
    answer: 0,
  },
  {
    id: 7,
    question: [
      {
        type: "txt",
        value:
          "Hovuzga ikkita quvur o‘tkazilgan. Birinchi quvur bo‘sh hovuzni 10 soatda to‘ldiradi, ikinchisi esa to‘la hovuzni 15 soatda bo‘shatadi. Hovuz bo‘sh bo‘lgan vaqtda ikkala quvur birdaniga ochilsa, hovuz necha soatdan keyin to‘ladi?",
      },
    ],
    options: [
      { type: "txt", value: "30" },
      { type: "txt", value: "32" },
      { type: "txt", value: "28" },
      { type: "txt", value: "26" },
    ],
    answer: 0,
  },
  {
    id: 8,
    question: [
      {
        type: "txt",
        value:
          "a va b natural sonlar bo‘lib, a + b = 32 bo‘lsa, a · b ning eng katta qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "230" },
      { type: "txt", value: "256" },
      { type: "txt", value: "64" },
      { type: "txt", value: "320" },
    ],
    answer: 2,
  },
  {
    id: 9,
    question: [
      {
        type: "txt",
        value:
          "n sonini 5 ga bo‘lganda qoldiq 3 ga, m sonini 5 ga bo‘lganda qoldiq 4 ga teng bo‘lsa, shu sonlar kvadratlarining yig‘indisini 5 ga bo‘lgandagi qoldiqni toping?",
      },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "2" },
      { type: "txt", value: "4" },
      { type: "txt", value: "0" },
    ],
    answer: 1,
  },
  {
    id: 10,
    question: [
      { type: "txt", value: "Yulduzdagi pulning" },
      { type: "img", value: "/quiz-images/olympics/class-6/img9.png" },
      { type: "txt", value: "qismi Dildoradagi pulning" },
      { type: "img", value: "/quiz-images/olympics/class-6/img10.png" },
      {
        type: "txt",
        value:
          "qismiga teng. Yulduz pulning qancha qismini Dildoraga bersa ulaning pullari o‘zaro teng bo‘ladi?",
      },
    ],
    options: [
      { type: "txt", value: "0,25" },
      { type: "txt", value: "0,5" },
      { type: "txt", value: "0,75" },
      { type: "txt", value: "0,1(6)" },
    ],
    answer: 3,
  },
  {
    id: 11,
    question: [
      { type: "txt", value: "Hajmi" },
      { type: "img", value: "/quiz-images/olympics/class-6/img11.png" },
      {
        type: "txt",
        value: "bo‘lgan kubning barcha qirralari yig‘indisini toping.",
      },
    ],
    options: [
      { type: "txt", value: "9 sm" },
      { type: "txt", value: "3 sm" },
      { type: "txt", value: "12 sm" },
      { type: "txt", value: "32 sm" },
    ],
    answer: 0,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value:
          "Bahovuddin al-Omiliy masalasi: Bir kishiga ikki qismdan iborat mukofot tavsiya qilinadi. Uning ayirmasi 20 ga, ko‘paytmasi 96 ga teng. Mukofotning katta qismini toping.",
      },
    ],
    options: [
      { type: "txt", value: "14" },
      { type: "txt", value: "17" },
      { type: "txt", value: "12" },
      { type: "txt", value: "24" },
    ],
    answer: 0,
  },
  {
    id: 13,
    question: [
      {
        type: "txt",
        value:
          "a - 3b va 3, 3b - a va 4 sonlari proporsiyaning ketma-ket hadlari bo‘lsa,",
      },
      { type: "img", value: "/quiz-images/olympics/class-6/img12.png" },
      { type: "txt", value: "kasrning qiymatini toping." },
    ],
    options: [
      { type: "img", value: "/quiz-images/olympics/class-6/img13.png" },
      { type: "img", value: "/quiz-images/olympics/class-6/img14.png" },
      { type: "img", value: "/quiz-images/olympics/class-6/img15.png" },
      { type: "img", value: "/quiz-images/olympics/class-6/img16.png" },
    ],
    answer: 3,
  },
  {
    id: 14,
    question: [
      {
        type: "txt",
        value:
          "28 ning juft bo‘luvchilari va tub bo‘luvchilari yig‘indisini toping.",
      },
    ],
    options: [
      { type: "txt", value: "57" },
      { type: "txt", value: "55" },
      { type: "txt", value: "58" },
      { type: "txt", value: "30" },
    ],
    answer: 2,
  },
  {
    id: 15,
    question: [
      {
        type: "txt",
        value:
          "Bir kombayn daladagi hosilni 15 soatda, boshqasi esa shu hosilni 10 soatda yig‘ib olishi mumkin. Ikkala kombayn birgalikda hosilni necha soatda yig‘ib olishi mumkin?",
      },
    ],
    options: [
      { type: "txt", value: "7" },
      { type: "txt", value: "5,5" },
      { type: "txt", value: "6" },
      { type: "txt", value: "8" },
    ],
    answer: 1,
  },
  {
    id: 16,
    question: [
      {
        type: "txt",
        value:
          "Do‘kondagi choynak 6400 so‘m turadi. Oyning oxirida narxlar pasayib, choynakning narxi 5440 so‘m bo‘ldi. Choynakning narxi necha foizga kamaygan?",
      },
    ],
    options: [
      { type: "txt", value: "10 %" },
      { type: "txt", value: "25%" },
      { type: "txt", value: "20 %" },
      { type: "txt", value: "15%" },
    ],
    answer: 2,
  },
  {
    id: 17,
    question: [
      {
        type: "txt",
        value:
          "Ikkita bolada bir xil sondagi tangalar bor. Ulatdan birida faqat 5 so‘mlik, ikkinchisida esa faqat 10 so‘mlik. Ikkalasidagi pul 105 so‘m bo‘lsa, ularning har birida nechtadan tanga pul bor?",
      },
    ],
    options: [
      { type: "txt", value: "5 ta" },
      { type: "txt", value: "7 ta" },
      { type: "txt", value: "10 ta" },
      { type: "txt", value: "15 ta" },
    ],
    answer: 3,
  },
  {
    id: 18,
    question: [
      {
        type: "txt",
        value:
          "Birinchi son 82,5 ikkinchi son birinchisidan 1,5 marta kichik, uchinchi son esa birinchi va ikkinchi sonlar ayirmasining 40% ini tashkil qiladi. Bu sonlarning o‘rta arifmetigini toping.",
      },
    ],
    options: [
      { type: "txt", value: "51,5" },
      { type: "txt", value: "50,5" },
      { type: "txt", value: "49,5" },
      { type: "txt", value: "48,5" },
    ],
    answer: 1,
  },
  {
    id: 19,
    question: [
      {
        type: "txt",
        value:
          "3375 va 2250 sonlarining eng kichik umumiy karralisini va bu sonlarning umumiy bo‘luvchilari sonini toping.",
      },
    ],
    options: [
      { type: "txt", value: "6750; 1125" },
      { type: "txt", value: "1125; 6750" },
      { type: "txt", value: "6750; 12" },
      { type: "txt", value: "1125; 12" },
    ],
    answer: 2,
  },
];

const olympicsClass7 = [
  {
    id: 0,
    question: [
      { type: "img", value: "/quiz-images/olympics/class-7/img1.png" },
      { type: "txt", value: "ni 8 ga bo‘lgandagi qoldiqni toping." },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "7" },
      { type: "txt", value: "5" },
      { type: "txt", value: "3" },
    ],
    answer: 2,
  },
  {
    id: 1,
    question: [
      {
        type: "txt",
        value:
          "Aziz yonidagi pulning   qismiga kitob, qolgan pulning   qismiga daftar sotib oldi. Shundan so‘ng hisoblab qarasa, yonida qolgan pul kitob sotib olish uchun ishlatilgan pulning 40 foizidan 120 so‘m ko‘p ekan. Aziz daftar sotib olish uchun qancha pul ishlatgan?",
      },
    ],
    options: [
      { type: "txt", value: "1800 so‘m" },
      { type: "txt", value: "1200 so‘m" },
      { type: "txt", value: "2400 so‘m" },
      { type: "txt", value: "600 so‘m" },
    ],
    answer: 0,
  },
  {
    id: 2,
    question: [
      { type: "img", value: "/quiz-images/olympics/class-7/img2.png" },
      { type: "txt", value: "ko‘phadni" },
      { type: "img", value: "/quiz-images/olympics/class-7/img3.png" },
    ],
    options: [
      { type: "txt", value: "8x - 5" },
      { type: "txt", value: "-8x + 5" },
      { type: "txt", value: "-8x - 5" },
      { type: "txt", value: "8x + 5" },
    ],
    answer: 0,
  },
  {
    id: 3,
    question: [
      {
        type: "txt",
        value:
          "Ikkita firma bitta binoni ijaraga olishgan va har oyda 6000 so‘m to‘lashadi. Agar birinchi firma soat 8:00 dan 14:00 gacha, ikkinchisi esa, soat 16:30 dan 22:00 gacha binoni band qilishsa, ikkinchi firma qancha ijara puli to‘laydi?",
      },
    ],
    options: [
      { type: "txt", value: "2750 so‘m" },
      { type: "txt", value: "3000 so‘m" },
      { type: "txt", value: "2850 so‘m" },
      { type: "txt", value: "3250 so‘m" },
    ],
    answer: 0,
  },
  {
    id: 4,
    question: [
      { type: "txt", value: "To‘g‘ri tengliklarni aniqlang:" },
      { type: "img", value: "/quiz-images/olympics/class-7/img4.png" },
    ],
    options: [
      { type: "txt", value: "1, 2, 3" },
      { type: "txt", value: "1, 2" },
      { type: "txt", value: "1, 3" },
      { type: "txt", value: "2, 3" },
    ],
    answer: 1,
  },
  {
    id: 5,
    question: [
      { type: "txt", value: "Yig‘indini hisoblang:" },
      { type: "img", value: "/quiz-images/olympics/class-7/img5.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/olympics/class-7/img6.png" },
      { type: "img", value: "/quiz-images/olympics/class-7/img7.png" },
      { type: "img", value: "/quiz-images/olympics/class-7/img8.png" },
      { type: "txt", value: "1" },
    ],
    answer: 1,
  },
  {
    id: 6,
    question: [
      { type: "img", value: "/quiz-images/olympics/class-7/img9.png" },
      { type: "txt", value: "soni necha xonali?" },
    ],
    options: [
      { type: "txt", value: "39" },
      { type: "txt", value: "43" },
      { type: "txt", value: "40" },
      { type: "txt", value: "41" },
    ],
    answer: 3,
  },
  {
    id: 7,
    question: [
      { type: "txt", value: "3+7+11+...+4x-3 = 820 bo‘lsa, x ni toping." },
    ],
    options: [
      { type: "txt", value: "79" },
      { type: "txt", value: "20" },
      { type: "txt", value: "20,5" },
      { type: "txt", value: "41" },
    ],
    answer: 2,
  },
  {
    id: 8,
    question: [
      { type: "img", value: "/quiz-images/olympics/class-7/img10.png" },
      { type: "txt", value: "bo‘lsa," },
      { type: "img", value: "/quiz-images/olympics/class-7/img11.png" },
      { type: "txt", value: "ni toping." },
    ],
    options: [
      { type: "img", value: "/quiz-images/olympics/class-7/img12.png" },
      { type: "img", value: "/quiz-images/olympics/class-7/img13.png" },
      { type: "txt", value: "3a" },
      { type: "img", value: "/quiz-images/olympics/class-7/img14.png" },
    ],
    answer: 3,
  },
  {
    id: 9,
    question: [
      { type: "txt", value: "Agar a+ 4b - c = 0 bo‘lsa," },
      { type: "img", value: "/quiz-images/olympics/class-7/img15.png" },
      { type: "txt", value: "ifodaning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "txt", value: "-4" },
      { type: "txt", value: "1,(6)" },
    ],
    answer: 2,
  },
  {
    id: 10,
    question: [
      { type: "txt", value: "Hisoblang:" },
      { type: "img", value: "/quiz-images/olympics/class-7/img16.png" },
    ],
    options: [
      { type: "txt", value: "4082421" },
      { type: "txt", value: "4072429" },
      { type: "txt", value: "4082429" },
      { type: "txt", value: "4072421" },
    ],
    answer: 0,
  },
  {
    id: 11,
    question: [
      {
        type: "txt",
        value:
          "Toq sonlardan (1), (3,5), (7,9,11), … kabi guruhlar tuzilgan. 100-guruhdagi sonlar yig‘indisini toping.",
      },
    ],
    options: [
      { type: "txt", value: "9702998" },
      { type: "txt", value: "922368" },
      { type: "txt", value: "1000000" },
      { type: "txt", value: "10000000" },
    ],
    answer: 2,
  },
  {
    id: 12,
    question: [
      { type: "txt", value: "Hisoblang:" },
      { type: "img", value: "/quiz-images/olympics/class-7/img17.png" },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "2" },
      { type: "txt", value: "1" },
      { type: "txt", value: "4" },
    ],
    answer: 2,
  },
  {
    id: 13,
    question: [
      {
        type: "txt",
        value:
          "Teng yonli trapetsiyaning diagonali uni ikkita teng yonli uchburchakka ajratadi. Trapetsiya burchaklarini toping.",
      },
    ],
    options: [
      { type: "txt", value: "720°, 1080°" },
      { type: "txt", value: "450°, 1350°" },
      { type: "txt", value: "800°, 1000°" },
      { type: "txt", value: "820°, 980°" },
    ],
    answer: 0,
  },
  {
    id: 14,
    question: [
      { type: "txt", value: "Yig‘indini hisoblang:" },
      { type: "img", value: "/quiz-images/olympics/class-7/img18.png" },
    ],
    options: [
      { type: "txt", value: "5050" },
      { type: "txt", value: "5151" },
      { type: "txt", value: "10201" },
      { type: "txt", value: "5051" },
    ],
    answer: 1,
  },
];

const olympicsClass8 = [
  {
    id: 0,
    question: [
      { type: "img", value: "/quiz-images/olympics/class-8/img1.png" },
      { type: "txt", value: "va" },
      { type: "img", value: "/quiz-images/olympics/class-8/img2.png" },
      { type: "txt", value: "kasrlarni o‘sish tartibida yozing" },
    ],
    options: [
      { type: "txt", value: "b < c < a" },
      { type: "txt", value: "c < a < b" },
      { type: "txt", value: "a < b < c" },
      { type: "txt", value: "a < c < b" },
    ],
    answer: 3,
  },
  {
    id: 1,
    question: [
      { type: "txt", value: "Tenglama ildizlari yig‘indisini toping:" },
      { type: "img", value: "/quiz-images/olympics/class-8/img3.png" },
    ],
    options: [
      { type: "txt", value: "-0,5" },
      { type: "txt", value: "0,5" },
      { type: "txt", value: "1,5" },
      { type: "txt", value: "-1,5" },
    ],
    answer: 0,
  },
  {
    id: 2,
    question: [
      {
        type: "txt",
        value:
          "LMN uchburchakda LK balandlik o‘tkazilgan. Agar LMN uchburchakning perimetri 36 ga, KLM niki 20 ga va NLK uchburchakniki 28 ga teng bo‘lsa, LK balandlikni toping",
      },
    ],
    options: [
      { type: "txt", value: "8" },
      { type: "txt", value: "5" },
      { type: "txt", value: "7" },
      { type: "txt", value: "6" },
    ],
    answer: 3,
  },
  {
    id: 3,
    question: [
      { type: "txt", value: "Hisoblang:" },
      { type: "img", value: "/quiz-images/olympics/class-8/img4.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/olympics/class-8/img5.png" },
      { type: "img", value: "/quiz-images/olympics/class-8/img6.png" },
      { type: "img", value: "/quiz-images/olympics/class-8/img7.png" },
      { type: "img", value: "/quiz-images/olympics/class-8/img8.png" },
    ],
    answer: 0,
  },
  {
    id: 4,
    question: [
      {
        type: "txt",
        value:
          "Barcha ikki xonali sonlar ko‘paytmasi 3 ning qanday eng katta darajasiga qoldiqsiz bo‘linadi?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/olympics/class-8/img9.png" },
      { type: "img", value: "/quiz-images/olympics/class-8/img10.png" },
      { type: "img", value: "/quiz-images/olympics/class-8/img11.png" },
      { type: "img", value: "/quiz-images/olympics/class-8/img12.png" },
    ],
    answer: 2,
  },
  {
    id: 5,
    question: [
      {
        type: "txt",
        value:
          "PQRS kvadratda M nuqta PS ning o‘rtasi va N nuqta SR ning o‘rtasi. Agar ∆SMN ning yuzasi 18 bo‘lsa, ∆QMN ning yuzasini toping",
      },
    ],
    options: [
      { type: "txt", value: "27" },
      { type: "txt", value: "54" },
      { type: "txt", value: "36" },
      { type: "txt", value: "72" },
    ],
    answer: 1,
  },
  {
    id: 6,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/olympics/class-8/img13.png" },
      { type: "txt", value: "bo‘lsa" },
      { type: "img", value: "/quiz-images/olympics/class-8/img14.png" },
      { type: "txt", value: "ni toping" },
    ],
    options: [
      { type: "txt", value: "7" },
      { type: "txt", value: "8" },
      { type: "txt", value: "5" },
      { type: "txt", value: "4" },
    ],
    answer: 0,
  },
  {
    id: 7,
    question: [
      {
        type: "txt",
        value: "ABCDE beshburchakning A,C,D burchaklari to‘g‘ri burchak va",
      },
      { type: "img", value: "/quiz-images/olympics/class-8/img15.png" },
      {
        type: "txt",
        value:
          "Agar G nuqta CD ning o‘rtasi va AG = CD = 17 bo‘lsa, ABCDE beshburchakning yuzini toping",
      },
    ],
    options: [
      { type: "txt", value: "216,75" },
      { type: "txt", value: "289" },
      { type: "txt", value: "212,5" },
      { type: "txt", value: "aniqlab bo‘lmaydi" },
    ],
    answer: 0,
  },
  {
    id: 8,
    question: [
      { type: "img", value: "/quiz-images/olympics/class-8/img16.png" },
      { type: "txt", value: "soni necha xonali?" },
    ],
    options: [
      { type: "txt", value: "121" },
      { type: "txt", value: "122" },
      { type: "txt", value: "123" },
      { type: "txt", value: "124" },
    ],
    answer: 3,
  },
  {
    id: 9,
    question: [
      { type: "img", value: "/quiz-images/olympics/class-8/img17.png" },
      {
        type: "txt",
        value:
          "soni tub bo‘ladigan barcha natural n larning yig‘indisini toping",
      },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "19" },
    ],
    answer: 0,
  },
  {
    id: 10,
    question: [
      {
        type: "txt",
        value:
          "ABC teng yonli (AB=BC) uchburchakda K va L nuqtalar AB va BC tomonlarida belgilangan va AK = KL = LB va KB = AC ga mos keladi.",
      },
      { type: "img", value: "/quiz-images/olympics/class-8/img18.png" },
      { type: "txt", value: "nimaga teng?" },
    ],
    options: [
      { type: "txt", value: "30°" },
      { type: "txt", value: "35°" },
      { type: "txt", value: "36°" },
      { type: "txt", value: "40°" },
    ],
    answer: 2,
  },
  {
    id: 11,
    question: [
      { type: "txt", value: "Agar" },
      { type: "txt", value: "abc=1 bo‘lsa," },
      { type: "img", value: "/quiz-images/olympics/class-8/img19.png" },
      { type: "txt", value: "ni soddalashtiring" },
    ],
    options: [
      { type: "txt", value: "2021" },
      { type: "txt", value: "2020" },
      { type: "txt", value: "1" },
      { type: "txt", value: "a+b+c" },
    ],
    answer: 1,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value:
          "To‘g‘ri burchakli parallelepipedning bo‘yi 50 dm ga teng. Eni bo‘yining 80% iga, balandligi esa bo‘yining",
      },
      { type: "img", value: "/quiz-images/olympics/class-8/img20.png" },
      {
        type: "txt",
        value: "qismiga teng. Parallelepipedning hajmini toping(metr kublarda)",
      },
    ],
    options: [
      { type: "txt", value: "10" },
      { type: "txt", value: "20" },
      { type: "txt", value: "40" },
      { type: "txt", value: "50" },
    ],
    answer: 2,
  },
  {
    id: 13,
    question: [
      { type: "img", value: "/quiz-images/olympics/class-8/img21.png" },
      { type: "txt", value: "sonini 11 ga bo‘lgandagi qoldiqni toping" },
    ],
    options: [
      { type: "txt", value: "6" },
      { type: "txt", value: "4" },
      { type: "txt", value: "2" },
      { type: "txt", value: "0" },
    ],
    answer: 2,
  },
  {
    id: 14,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/olympics/class-8/img22.png" },
      { type: "txt", value: "bo‘lsa," },
      { type: "img", value: "/quiz-images/olympics/class-8/img23.png" },
      { type: "txt", value: "ning qiymatini toping" },
    ],
    options: [
      { type: "img", value: "/quiz-images/olympics/class-8/img24.png" },
      { type: "txt", value: "10" },
      { type: "img", value: "/quiz-images/olympics/class-8/img25.png" },
      { type: "txt", value: "8" },
    ],
    answer: 1,
  },
];

const olympicsClass9 = [
  {
    id: 0,
    question: [
      { type: "img", value: "/quiz-images/olympics/class-9/img1.png" },
      { type: "txt", value: "bo‘lsa," },
      { type: "img", value: "/quiz-images/olympics/class-9/img2.png" },
      { type: "txt", value: "ifodaning qiymatini toping" },
    ],
    options: [
      { type: "img", value: "/quiz-images/olympics/class-9/img3.png" },
      { type: "img", value: "/quiz-images/olympics/class-9/img4.png" },
      { type: "img", value: "/quiz-images/olympics/class-9/img5.png" },
      { type: "img", value: "/quiz-images/olympics/class-9/img6.png" },
    ],
    answer: 1,
  },
  {
    id: 1,
    question: [
      { type: "img", value: "/quiz-images/olympics/class-9/img7.png" },
      { type: "txt", value: "ni hisoblang." },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "img", value: "/quiz-images/olympics/class-9/img8.png" },
      { type: "img", value: "/quiz-images/olympics/class-9/img9.png" },
    ],
    answer: 0,
  },
  {
    id: 2,
    question: [
      {
        type: "txt",
        value:
          "Hovuzga uchta quvur o‘tkazilgan. Birinchisi hovuzni 12 soatda, ikkinchisi 8 soatda to‘ldiradi. Uchinchisi hovuzni 1 sutkada bo‘shatadi. Agar uchala quvur birgalikda ochib qo‘yilsa, bo‘sh hovuzning yarmi qancha vaqt(soat)da to‘ladi?",
      },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "2" },
      { type: "txt", value: "2,5" },
      { type: "txt", value: "1,5" },
    ],
    answer: 0,
  },
  {
    id: 3,
    question: [
      {
        type: "txt",
        value:
          "Abituriyentga 36 ta masala berildi. To‘g‘ri yechilgan har biriga 3 ball beriladi. Noto‘g‘risiga 2 ball chegiriladi. 88 ball to‘plashi uchun o‘quvchi nechta masalani to‘g‘ri yechishi kerak?",
      },
    ],
    options: [
      { type: "txt", value: "30" },
      { type: "txt", value: "32" },
      { type: "txt", value: "34" },
      { type: "txt", value: "38" },
    ],
    answer: 1,
  },
  {
    id: 4,
    question: [
      {
        type: "txt",
        value:
          "Agar k > 0 va b = 0 bo‘lsa, u holda y = kx + b to‘g‘ri chiziq grafigi qaysi choraklardan o‘tadi?",
      },
    ],
    options: [
      { type: "txt", value: "I, II" },
      { type: "txt", value: "I, III" },
      { type: "txt", value: "I, II, III" },
      { type: "txt", value: "II, IV" },
    ],
    answer: 1,
  },
  {
    id: 5,
    question: [
      { type: "img", value: "/quiz-images/olympics/class-9/img10.png" },
      { type: "txt", value: "sistemadan" },
      { type: "img", value: "/quiz-images/olympics/class-9/img11.png" },
      { type: "txt", value: "ni toping." },
    ],
    options: [
      { type: "img", value: "/quiz-images/olympics/class-9/img12.png" },
      { type: "img", value: "/quiz-images/olympics/class-9/img13.png" },
      { type: "img", value: "/quiz-images/olympics/class-9/img14.png" },
      { type: "img", value: "/quiz-images/olympics/class-9/img15.png" },
    ],
    answer: 0,
  },
  {
    id: 6,
    question: [
      { type: "img", value: "/quiz-images/olympics/class-9/img16.png" },
      { type: "txt", value: "bo‘lsa," },
      { type: "img", value: "/quiz-images/olympics/class-9/img17.png" },
      { type: "txt", value: "ni hisoblang. (Bunda abc ≠ 0)" },
    ],
    options: [
      { type: "img", value: "/quiz-images/olympics/class-9/img18.png" },
      { type: "img", value: "/quiz-images/olympics/class-9/img19.png" },
      { type: "img", value: "/quiz-images/olympics/class-9/img20.png" },
      { type: "img", value: "/quiz-images/olympics/class-9/img21.png" },
    ],
    answer: 2,
  },
  {
    id: 7,
    question: [
      {
        type: "txt",
        value:
          "Teng yonli trapetsiyaning diagonali uni ikkita teng yonli uchburchakka ajratadi. Trapetsiyaning burchaklarini toping.",
      },
    ],
    options: [
      { type: "txt", value: "820°, 980°" },
      { type: "txt", value: "1350°, 45°" },
      { type: "txt", value: "1000°, 80°" },
      { type: "txt", value: "720°, 108°" },
    ],
    answer: 3,
  },
  {
    id: 8,
    question: [
      {
        type: "txt",
        value: "P(x) ko‘phadni x - a ga bo‘lgandagi qoldiqni toping.",
      },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "txt", value: "P(x - a)" },
      { type: "txt", value: "P(a)" },
      { type: "txt", value: "P(x)" },
    ],
    answer: 2,
  },
  {
    id: 9,
    question: [
      {
        type: "txt",
        value: "Tenglamaning natural sonlardagi yechimida z ni toping:",
      },
      { type: "img", value: "/quiz-images/olympics/class-9/img22.png" },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "2" },
    ],
    answer: 2,
  },
  {
    id: 10,
    question: [{ type: "txt", value: "Tenglamani yeching: |x+6| = |x+10|" }],
    options: [
      { type: "txt", value: "4" },
      { type: "txt", value: "6" },
      { type: "txt", value: "-6" },
      { type: "txt", value: "-8" },
    ],
    answer: 3,
  },
  {
    id: 11,
    question: [
      {
        type: "txt",
        value:
          "Asoslari 18 va 12 ga teng bo‘lgan teng yonli trapetsiyaning diagonallari o‘zaro perpendikulyar. Trapetsiyaning yuzini toping.",
      },
    ],
    options: [
      { type: "txt", value: "150" },
      { type: "txt", value: "125" },
      { type: "txt", value: "400" },
      { type: "txt", value: "225" },
    ],
    answer: 3,
  },
  {
    id: 12,
    question: [
      { type: "txt", value: "Yuzi" },
      { type: "img", value: "/quiz-images/olympics/class-9/img23.png" },
      {
        type: "txt",
        value:
          ", balandliklari 6 sm va 8 sm bo‘lgan parallelogrammning perimetrini toping.",
      },
    ],
    options: [
      { type: "txt", value: "40 sm" },
      { type: "txt", value: "20 sm" },
      { type: "txt", value: "50 sm" },
      { type: "txt", value: "56 sm" },
    ],
    answer: 3,
  },
  {
    id: 13,
    question: [
      {
        type: "txt",
        value:
          "Rombning diagonallari 6 va 8 ga teng bo‘lsa, uning tomonini toping.",
      },
    ],
    options: [
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
      { type: "img", value: "/quiz-images/olympics/class-9/img24.png" },
      { type: "img", value: "/quiz-images/olympics/class-9/img25.png" },
    ],
    answer: 0,
  },
  {
    id: 14,
    question: [
      {
        type: "txt",
        value:
          "Tarkibida 85% suv bo‘lgan 0,5 t sellyuloza qorishmasida 75% suv bo‘lgan qorishma olish uchun necha kg suvni bug‘lantirish kerak?",
      },
    ],
    options: [
      { type: "txt", value: "100" },
      { type: "txt", value: "200" },
      { type: "txt", value: "300" },
      { type: "txt", value: "400" },
    ],
    answer: 1,
  },
];

const olympicsClass10 = [
  {
    id: 0,
    question: [
      { type: "img", value: "/quiz-images/olympics/class-10/img1.png" },
      { type: "txt", value: "tenglama   ning qanday qiymatlarida o‘rinli." },
    ],
    options: [
      { type: "txt", value: "343" },
      { type: "txt", value: "3" },
      { type: "txt", value: "9" },
      { type: "txt", value: "729" },
    ],
    answer: 3,
  },
  {
    id: 1,
    question: [
      { type: "img", value: "/quiz-images/olympics/class-10/img2.png" },
      { type: "txt", value: "tenglama ildizlari yig‘indisini toping." },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "0" },
      { type: "txt", value: "3" },
      { type: "txt", value: "1" },
    ],
    answer: 2,
  },
  {
    id: 2,
    question: [
      {
        type: "txt",
        value: "Tenglama ildizlarining o‘rta arifmetik qiymatini toping:",
      },
      { type: "img", value: "/quiz-images/olympics/class-10/img3.png" },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "5" },
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
    ],
    answer: 0,
  },
  {
    id: 3,
    question: [
      { type: "img", value: "/quiz-images/olympics/class-10/img4.png" },
      {
        type: "txt",
        value: "ko‘phadning bir ko‘paytuvchisi x+1 bo‘lsa, m ni toping.",
      },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "2" },
      { type: "txt", value: "1" },
      { type: "txt", value: "4" },
    ],
    answer: 0,
  },
  {
    id: 4,
    question: [
      {
        type: "txt",
        value:
          "Tengsizlikning butun yechimlarining o‘rta arifmetik qiymatini toping:",
      },
      { type: "img", value: "/quiz-images/olympics/class-10/img5.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/olympics/class-10/img6.png" },
      { type: "img", value: "/quiz-images/olympics/class-10/img7.png" },
      { type: "img", value: "/quiz-images/olympics/class-10/img8.png" },
      { type: "txt", value: "-3" },
    ],
    answer: 0,
  },
  {
    id: 5,
    question: [
      { type: "txt", value: "Hisoblang:" },
      { type: "img", value: "/quiz-images/olympics/class-10/img9.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/olympics/class-10/img10.png" },
      { type: "img", value: "/quiz-images/olympics/class-10/img11.png" },
      { type: "img", value: "/quiz-images/olympics/class-10/img12.png" },
      { type: "img", value: "/quiz-images/olympics/class-10/img13.png" },
    ],
    answer: 2,
  },
  {
    id: 6,
    question: [
      {
        type: "txt",
        value:
          "Sonlarni o‘sish tartibida joylashtiring: a = sin1, b = cos22, c = sin61°.",
      },
    ],
    options: [
      { type: "txt", value: "c, b, a" },
      { type: "txt", value: "a, c, b" },
      { type: "txt", value: "b, c, a" },
      { type: "txt", value: "b, a, c" },
    ],
    answer: 3,
  },
  {
    id: 7,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/olympics/class-10/img14.png" },
      { type: "txt", value: "bo‘lsa," },
      { type: "img", value: "/quiz-images/olympics/class-10/img15.png" },
      { type: "txt", value: "ni toping." },
    ],
    options: [
      { type: "txt", value: "-5" },
      { type: "txt", value: "-7" },
      { type: "txt", value: "-3" },
      { type: "txt", value: "-1" },
    ],
    answer: 1,
  },
  {
    id: 8,
    question: [
      { type: "img", value: "/quiz-images/olympics/class-10/img16.png" },
      {
        type: "txt",
        value:
          "ko‘phadni standart ko‘rinishda ifodalangandagi koeffitsientlar yig‘indisini toping.",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/olympics/class-10/img16.png" },
      { type: "img", value: "/quiz-images/olympics/class-10/img18.png" },
      { type: "img", value: "/quiz-images/olympics/class-10/img19.png" },
      { type: "txt", value: "1" },
    ],
    answer: 3,
  },
  {
    id: 9,
    question: [
      { type: "txt", value: "Hisoblang:" },
      { type: "img", value: "/quiz-images/olympics/class-10/img20.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/olympics/class-10/img21.png" },
      { type: "img", value: "/quiz-images/olympics/class-10/img22.png" },
      { type: "img", value: "/quiz-images/olympics/class-10/img23.png" },
      { type: "img", value: "/quiz-images/olympics/class-10/img24.png" },
    ],
    answer: 2,
  },
  {
    id: 10,
    question: [
      { type: "txt", value: "a ning qanday qiymatlarida" },
      { type: "img", value: "/quiz-images/olympics/class-10/img24.png" },
      {
        type: "txt",
        value: "kvadrat tenglamaning ildizlari yig‘indisi manfiy bo‘ladi?",
      },
    ],
    options: [
      { type: "txt", value: "a < 1" },
      { type: "txt", value: "0 < a < 2" },
      { type: "txt", value: "0 < a < 1" },
      { type: "txt", value: "a < 0" },
    ],
    answer: 3,
  },
  {
    id: 11,
    question: [
      {
        type: "txt",
        value:
          "Ninachi va chivin to‘g‘ri chiziq bo‘ylab harakatlanmoqda. Ninachi chivinni quvib yetmoqchi. Ninachining tezligi 1,2 m/s va chivinniki 30 sm/s. Necha sekunddan so‘ng ular orasidagi masofa 6,5 m dan 20 sm gacha qisqaradi?",
      },
    ],
    options: [
      { type: "txt", value: "5 s" },
      { type: "txt", value: "6 s" },
      { type: "txt", value: "7 s" },
      { type: "txt", value: "10 s" },
    ],
    answer: 2,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value:
          "Teng yonli uchburchakning asosi 12 sm, yon tomoni 18 sm. Yon tomonlariga bissektrisalar tushirilgan. Bissektrisalarning tomonlari bilan kesishgan nuqtalari orasidagoi masofani toping.",
      },
    ],
    options: [
      { type: "txt", value: "5,6 sm" },
      { type: "txt", value: "6,4 sm" },
      { type: "txt", value: "7,2 sm" },
      { type: "txt", value: "8,1 sm" },
    ],
    answer: 2,
  },
  {
    id: 13,
    question: [
      { type: "txt", value: "Hisoblang:" },
      { type: "img", value: "/quiz-images/olympics/class-10/img26.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/olympics/class-10/img27.png" },
      { type: "img", value: "/quiz-images/olympics/class-10/img28.png" },
      { type: "img", value: "/quiz-images/olympics/class-10/img29.png" },
      { type: "img", value: "/quiz-images/olympics/class-10/img30.png" },
    ],
    answer: 0,
  },
  {
    id: 14,
    question: [
      { type: "img", value: "/quiz-images/olympics/class-11/img31.png" },
      { type: "txt", value: "tenglamaning ildizi qaysi oraliqda joylashgan?" },
    ],
    options: [
      { type: "txt", value: "[-2; 0]" },
      { type: "txt", value: "[2; 4]" },
      { type: "txt", value: "[4; 9]" },
      { type: "txt", value: "[0; 2]" },
    ],
    answer: 3,
  },
];

const olympicsClass11 = [
  {
    id: 0,
    question: [
      { type: "txt", value: "sonning oxirgi ikkita raqamini toping." },
    ],
    options: [
      { type: "txt", value: "13" },
      { type: "txt", value: "03" },
      { type: "txt", value: "63" },
      { type: "txt", value: "83" },
    ],
    answer: 2,
  },
  {
    id: 1,
    question: [
      {
        type: "txt",
        value:
          "Doiraga ichki chizilgan kvadrat undan yuzasi 2π-4 ga teng bo‘lgan segmentni ajratadi. Kvadratning yuzasini toping.",
      },
    ],
    options: [
      { type: "txt", value: "8" },
      { type: "txt", value: "4" },
      { type: "txt", value: "2" },
      { type: "txt", value: "16" },
    ],
    answer: 3,
  },
  {
    id: 2,
    question: [
      {
        type: "txt",
        value:
          "ABC uchburchakka aylana tashqi chizilgan. Uchburchakning AM medianasi aylana bilan K nuqtada kesishguncha davom ettirilgan. Agar AM = 18, MK = 8, BK = 10 bo‘lsa, AC tomonini toping.",
      },
    ],
    options: [
      { type: "txt", value: "12" },
      { type: "txt", value: "15" },
      { type: "txt", value: "14" },
      { type: "txt", value: "16" },
    ],
    answer: 1,
  },
  {
    id: 3,
    question: [
      { type: "txt", value: "Ifodaning oxirgi raqamini aniqlang:" },
      { type: "img", value: "/quiz-images/olympics/class-11/img2.png" },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "txt", value: "1" },
      { type: "txt", value: "3" },
      { type: "txt", value: "6" },
    ],
    answer: 0,
  },
  {
    id: 4,
    question: [
      { type: "img", value: "/quiz-images/olympics/class-11/img3.png" },
      { type: "txt", value: "tenglama nechta ildizga ega?" },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 3,
  },
  {
    id: 5,
    question: [
      {
        type: "txt",
        value:
          "Koordinata boshidan o‘tuvchi, simmetriya o‘qi ordinata o‘qiga parallel, cho‘qqisi y = x va x + y - 2 = 0 to‘g‘ri chiziqlar kesishgan nuqtada bo‘lgan parabolaning tenglamasini toping.",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/olympics/class-11/img4.png" },
      { type: "img", value: "/quiz-images/olympics/class-11/img5.png" },
      { type: "img", value: "/quiz-images/olympics/class-11/img6.png" },
      { type: "img", value: "/quiz-images/olympics/class-11/img7.png" },
    ],
    answer: 0,
  },
  {
    id: 6,
    question: [
      { type: "img", value: "/quiz-images/olympics/class-11/img8.png" },
      { type: "txt", value: "funksiyaning qiymatlar to‘plamini toping." },
    ],
    options: [
      { type: "txt", value: "[1;3]" },
      { type: "txt", value: "[-1;3]" },
      { type: "txt", value: "[-2;2]" },
      { type: "txt", value: "[1;4]" },
    ],
    answer: 1,
  },
  {
    id: 7,
    question: [
      { type: "img", value: "/quiz-images/olympics/class-11/img9.png" },
      {
        type: "txt",
        value: "tengsizlik yechimlari joylashgan interval uzunligini toping.",
      },
    ],
    options: [
      { type: "txt", value: "0,25" },
      { type: "txt", value: "0,75" },
      { type: "txt", value: "0,125" },
      { type: "txt", value: "0,5" },
    ],
    answer: 0,
  },
  {
    id: 8,
    question: [
      {
        type: "txt",
        value:
          "2, 3, 4, 5, 6, 7, 8, 9, 10 sonlariga bo‘lganda mos ravishda 1, 2, 3, 4, 5, 6, 7, 8, 9 qoldiqlar hosil bo‘ladigan eng kichik natural sonni toping.",
      },
    ],
    options: [
      { type: "txt", value: "5039" },
      { type: "txt", value: "1019" },
      { type: "txt", value: "1519" },
      { type: "txt", value: "2519" },
    ],
    answer: 3,
  },
  {
    id: 9,
    question: [
      {
        type: "txt",
        value: "1! + 2! + 3! +...+ 10! sonining oxirgi raqamini toping.",
      },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "0" },
      { type: "txt", value: "5" },
      { type: "txt", value: "7" },
    ],
    answer: 0,
  },
  {
    id: 10,
    question: [
      { type: "txt", value: "Tenglamani yeching:" },
      { type: "img", value: "/quiz-images/olympics/class-11/img10.png" },
    ],
    options: [
      { type: "txt", value: "(-2;1)" },
      { type: "txt", value: "(2;1)" },
      { type: "txt", value: "(1;-2)" },
      { type: "txt", value: "(1;-2)" },
    ],
    answer: 0,
  },
  {
    id: 11,
    question: [
      { type: "img", value: "/quiz-images/olympics/class-11/img11.png" },
      { type: "txt", value: "tenglamaning ildizlari yig‘indisini toping." },
    ],
    options: [
      { type: "txt", value: "10" },
      { type: "txt", value: "-6" },
      { type: "txt", value: "6" },
      { type: "txt", value: "-2" },
    ],
    answer: 0,
  },
  {
    id: 12,
    question: [
      { type: "img", value: "/quiz-images/olympics/class-11/img12.png" },
      { type: "txt", value: "va a,b > 0 bo‘lsa," },
      { type: "img", value: "/quiz-images/olympics/class-11/img13.png" },
      { type: "txt", value: "ifodaning eng kichik qiymatini toping." },
    ],
    options: [
      { type: "img", value: "/quiz-images/olympics/class-11/img14.png" },
      { type: "img", value: "/quiz-images/olympics/class-11/img15.png" },
      { type: "img", value: "/quiz-images/olympics/class-11/img16.png" },
      { type: "txt", value: "2" },
    ],
    answer: 2,
  },
  {
    id: 13,
    question: [
      { type: "img", value: "/quiz-images/olympics/class-11/img17.png" },
      { type: "txt", value: "bo‘lsa," },
      { type: "img", value: "/quiz-images/olympics/class-11/img18.png" },
      { type: "txt", value: "nimaga teng?," },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "5" },
      { type: "txt", value: "9" },
      { type: "txt", value: "7" },
    ],
    answer: 3,
  },
  {
    id: 14,
    question: [
      {
        type: "txt",
        value:
          "a,b - to‘g‘ri burchakli uchburchakning katetlari va h - gipotenuzaga tushirilgan balandligi bo‘lsa,",
      },
      { type: "img", value: "/quiz-images/olympics/class-11/img19.png" },
      { type: "txt", value: "yig‘indini toping." },
    ],
    options: [
      { type: "img", value: "/quiz-images/olympics/class-11/img20.png" },
      { type: "img", value: "/quiz-images/olympics/class-11/img21.png" },
      { type: "img", value: "/quiz-images/olympics/class-11/img22.png" },
      { type: "img", value: "/quiz-images/olympics/class-11/img23.png" },
    ],
    answer: 0,
  },
];

const olimpiada9_10Uz = [
  {
    id: 0,
    question: [{ type: "txt", value: "Hisoblang:  38∙45-45∙26+12∙5" }],
    options: [
      { type: "txt", value: "560" },
      { type: "txt", value: "600" },
      { type: "txt", value: "400" },
      { type: "txt", value: "500" },
    ],
    answer: 1,
  },
  {
    id: 1,
    question: [{ type: "txt", value: "Hisoblang:  47∙64-64∙27+20∙9+73∙80" }],
    options: [
      { type: "txt", value: "7300" },
      { type: "txt", value: "8000" },
      { type: "txt", value: "7500" },
      { type: "txt", value: "5000" },
    ],
    answer: 0,
  },
  {
    id: 2,
    question: [
      { type: "txt", value: "20 dan kichik tub sonlar yig’indisini toping." },
    ],
    options: [
      { type: "txt", value: "80" },
      { type: "txt", value: "77" },
      { type: "txt", value: "58" },
      { type: "txt", value: "66" },
    ],
    answer: 1,
  },
  {
    id: 3,
    question: [{ type: "txt", value: "Hisoblang:  42567∙42565-42568∙42564" }],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "-3" },
      { type: "txt", value: "-4" },
    ],
    answer: 0,
  },
  {
    id: 4,
    question: [
      {
        type: "txt",
        value: "80 sonining barcha natural bo’luvchilari yig’indisini toping?",
      },
    ],
    options: [
      { type: "txt", value: "160" },
      { type: "txt", value: "186" },
      { type: "txt", value: "106" },
      { type: "txt", value: "150" },
    ],
    answer: 1,
  },
  {
    id: 5,
    question: [
      {
        type: "txt",
        value:
          "101112131415...47484950 sonining raqamlari yig’indisini toping.",
      },
    ],
    options: [
      { type: "txt", value: "280" },
      { type: "txt", value: "265" },
      { type: "txt", value: "275" },
      { type: "txt", value: "285" },
    ],
    answer: 3,
  },
  {
    id: 6,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img1.png" },
      { type: "txt", value: "yig’indining yarmini hisoblang." },
    ],
    options: [
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img2.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img3.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img4.png" },
      { type: "txt", value: "A va C javoblar to’g’ri" },
    ],
    answer: 0,
  },
  {
    id: 7,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img5.png" },
      { type: "txt", value: "bo’lsa," },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img6.png" },
      { type: "txt", value: "ifodaning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "10" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
      { type: "txt", value: "4" },
    ],
    answer: 2,
  },
  {
    id: 8,
    question: [
      {
        type: "txt",
        value:
          "a=654236,b=754272,c=783124 va d=378756 sonlaridan qaysilari 36 soniga qoldiqsiz bo’linadi?",
      },
    ],
    options: [
      { type: "txt", value: "a va d" },
      { type: "txt", value: "faqat b" },
      { type: "txt", value: "b va c" },
      { type: "txt", value: "b va d" },
    ],
    answer: 3,
  },
  {
    id: 9,
    question: [
      {
        type: "txt",
        value: "Agar a juft son bo’lsa, quyidagilardan qaysi biri doimo toq?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img7.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img8.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img9.png" },
      { type: "txt", value: "Hech biri" },
    ],
    answer: 1,
  },
  {
    id: 10,
    question: [
      {
        type: "txt",
        value:
          "Quyidagilardan qaysilari qoldiqli bo'lishni ifodalaydi? 1) 48=5·9+3; 2) 48=7·7-1; 3) 48=3·15+3; 4) 48=6·8-0; ",
      },
    ],
    options: [
      { type: "txt", value: "1 va 4" },
      { type: "txt", value: "1 va 3" },
      { type: "txt", value: "2 va 4" },
      { type: "txt", value: "faqat 1" },
    ],
    answer: 3,
  },
  {
    id: 11,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img10.png" },
      { type: "txt", value: "sonini 7 ga bo’lgandagi qoldiqni toping." },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "5" },
      { type: "txt", value: "4" },
      { type: "txt", value: "1" },
    ],
    answer: 3,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value: "140 sonining barcha natural bo’luvchilari sonini aniqlang.",
      },
    ],
    options: [
      { type: "txt", value: "10" },
      { type: "txt", value: "12" },
      { type: "txt", value: "16" },
      { type: "txt", value: "14" },
    ],
    answer: 1,
  },
  {
    id: 13,
    question: [
      {
        type: "txt",
        value:
          "Quyidagi tasdiqlardan qaysilari to'g'ri? 1) 0 sonining 0-darajasi aniqlanmagan;2) Qoldiq eng kamida 1 ga teng bo’ladi; 3) Eng katta 5 xonali natural son 9999 ga teng; 4) Bir vaqtda 4 ga ham, 15 ga ham bo’linadigan sonlar 60 ga ham bo’linadi; 5) Agar sonning raqamlari yig’indisi 7 ga bo’linsa, u holda shu sonning o’zi ham 7 ga bo’linadi",
      },
    ],
    options: [
      { type: "txt", value: "1, 4 va 5" },
      { type: "txt", value: "1, 3 va 4" },
      { type: "txt", value: "1 va 4" },
      { type: "txt", value: "1, 2 va 4" },
    ],
    answer: 2,
  },
  {
    id: 14,
    question: [
      {
        type: "txt",
        value:
          "a=467820,b=754870,c=867450 va d=478750 sonlaridan qaysilari 30 soniga qoldiqsiz bo’linadi?",
      },
    ],
    options: [
      { type: "txt", value: "a va c" },
      { type: "txt", value: "faqat a" },
      { type: "txt", value: "b va c" },
      { type: "txt", value: "hammasi" },
    ],
    answer: 0,
  },
  {
    id: 15,
    question: [
      {
        type: "txt",
        value:
          "Quyidagilardan qaysilari qoldiqli bo'lishni ifodalamaydi? 1) 54=10·6-6;    2) 54=11·4+10;    3) 54=5·9+9;    4) 54=13·4+2;",
      },
    ],
    options: [
      { type: "txt", value: "2 va 4" },
      { type: "txt", value: "1 va 2" },
      { type: "txt", value: "1va 3" },
      { type: "txt", value: "faqat 1" },
    ],
    answer: 2,
  },
  {
    id: 16,
    question: [
      {
        type: "txt",
        value:
          "a va b natural sonlarning EKUBi 3 ga, ko’paytmasi 3600 ga teng bo’lsa, shu sonlarning EKUKini toping.",
      },
    ],
    options: [
      { type: "txt", value: "1200" },
      { type: "txt", value: "1800" },
      { type: "txt", value: "-1200" },
      { type: "txt", value: "-1800" },
    ],
    answer: 0,
  },
  {
    id: 17,
    question: [
      { type: "txt", value: "Hisoblang:" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img11.png" },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "416" },
      { type: "txt", value: "426" },
      { type: "txt", value: "0" },
    ],
    answer: 2,
  },
  {
    id: 18,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img12.png" },
      { type: "txt", value: "bo'lsa," },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img13.png" },
      { type: "txt", value: "ning son qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "10" },
      { type: "txt", value: "12" },
      { type: "txt", value: "14" },
      { type: "txt", value: "16" },
    ],
    answer: 1,
  },
  {
    id: 19,
    question: [
      { type: "txt", value: "Hisoblang:" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img14.png" },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "-3" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img15.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img16.png" },
    ],
    answer: 3,
  },
  {
    id: 20,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img17.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img18.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img19.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img20.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img21.png" },
    ],
    answer: 1,
  },
  {
    id: 21,
    question: [
      { type: "txt", value: "Ifodani soddalashtiring:" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img22.png" },
    ],
    options: [
      { type: "txt", value: "1-b" },
      { type: "txt", value: "-b" },
      { type: "txt", value: "b-a" },
      { type: "txt", value: "1+b" },
    ],
    answer: 0,
  },
  {
    id: 22,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img23.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img24.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img25.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img26.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img27.png" },
    ],
    answer: 3,
  },
  {
    id: 23,
    question: [
      { type: "txt", value: "(x;y)  sonlar juftligi" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img28.png" },
      { type: "txt", value: "sistemaning yechimi bo’lsa, x⋅y  ni toping. " },
    ],
    options: [
      { type: "txt", value: "-6" },
      { type: "txt", value: "-8" },
      { type: "txt", value: "8" },
      { type: "txt", value: "6" },
    ],
    answer: 3,
  },
  {
    id: 24,
    question: [
      { type: "txt", value: "Barcha raqamlarning yig’indisi nechaga teng?" },
    ],
    options: [
      { type: "txt", value: "30" },
      { type: "txt", value: "45" },
      { type: "txt", value: "∞" },
      { type: "txt", value: "40" },
    ],
    answer: 1,
  },
  {
    id: 25,
    question: [
      { type: "txt", value: "Hisoblang:" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img29.png" },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "10" },
    ],
    answer: 0,
  },
  {
    id: 26,
    question: [
      { type: "txt", value: "Tenglama ildizlari yig’indisini toping." },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img30.png" },
    ],
    options: [
      { type: "txt", value: "-0,5" },
      { type: "txt", value: "-0,4" },
      { type: "txt", value: "-0,3" },
      { type: "txt", value: "-0,2" },
    ],
    answer: 0,
  },
  {
    id: 27,
    question: [
      {
        type: "txt",
        value:
          "Agar x=0,(201),y=0,2(01),z=0,20(1) bo’lsa, shu sonlarni o’sish tartibida joylashtiring.",
      },
    ],
    options: [
      { type: "txt", value: "z<y<x" },
      { type: "txt", value: "y<z<x" },
      { type: "txt", value: "x<y<z" },
      { type: "txt", value: "y<x<z" },
    ],
    answer: 1,
  },
  {
    id: 28,
    question: [
      {
        type: "txt",
        value:
          "Raqamlari yig’indisi 2001 ga teng bo’lgan eng kichik natural sonning birinchi raqami nimaga teng?",
      },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
    ],
    answer: 1,
  },
  {
    id: 29,
    question: [
      { type: "txt", value: "Tenglamani yeching." },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img31.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img32.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img33.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img34.png" },
      { type: "txt", value: "21" },
    ],
    answer: 2,
  },
  {
    id: 30,
    question: [
      { type: "txt", value: "Soddalashtiring." },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img35.png" },
    ],
    options: [
      { type: "txt", value: "x+2" },
      { type: "txt", value: "x+3" },
      { type: "txt", value: "x-2" },
      { type: "txt", value: "x-3" },
    ],
    answer: 0,
  },
  {
    id: 31,
    question: [
      { type: "txt", value: "Hisoblang:" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img36.png" },
    ],
    options: [
      { type: "txt", value: "7" },
      { type: "txt", value: "5" },
      { type: "txt", value: "0" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img37.png" },
    ],
    answer: 1,
  },
  {
    id: 32,
    question: [
      { type: "txt", value: "abc=16 va ab+ac+bc=12 bo’lsa," },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img38.png" },
      { type: "txt", value: "ni toping." },
    ],
    options: [
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img39.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img40.png" },
      { type: "txt", value: "2" },
      { type: "txt", value: "1" },
    ],
    answer: 0,
  },
  {
    id: 33,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img41.png" },
      { type: "txt", value: "ni hisoblang." },
    ],
    options: [
      { type: "txt", value: "56" },
      { type: "txt", value: "53" },
      { type: "txt", value: "72" },
      { type: "txt", value: "81" },
    ],
    answer: 0,
  },
  {
    id: 34,
    question: [
      {
        type: "txt",
        value:
          "101112131415...75767778 sonining raqamlari yig’indisini toping.",
      },
    ],
    options: [
      { type: "txt", value: "603" },
      { type: "txt", value: "595" },
      { type: "txt", value: "578" },
      { type: "txt", value: "579" },
    ],
    answer: 3,
  },
  {
    id: 35,
    question: [
      { type: "txt", value: "Soddalashtiring:" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img42.png" },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img43.png" },
      { type: "txt", value: "3" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img44.png" },
    ],
    answer: 3,
  },
  {
    id: 36,
    question: [
      { type: "txt", value: "Hisoblang:" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img45.png" },
    ],
    options: [
      { type: "txt", value: "144" },
      { type: "txt", value: "95" },
      { type: "txt", value: "104" },
      { type: "txt", value: "128" },
    ],
    answer: 1,
  },
  {
    id: 37,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img46.png" },
      { type: "txt", value: "ning oxirgi raqamini toping?" },
    ],
    options: [
      { type: "txt", value: "7" },
      { type: "txt", value: "9" },
      { type: "txt", value: "3" },
      { type: "txt", value: "1" },
    ],
    answer: 2,
  },
  {
    id: 38,
    question: [
      { type: "txt", value: "24 ning" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img47.png" },
      { type: "txt", value: "qismi 34 ning" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img48.png" },
      { type: "txt", value: "qismidan qanchaga kam?" },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 0,
  },
  {
    id: 39,
    question: [
      {
        type: "txt",
        value:
          "Har qanday n soni uchun quyidagilardan qaysi biri har doim juft son bo’ladi?",
      },
    ],
    options: [
      { type: "txt", value: "n!+n+1" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img49.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img50.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img51.png" },
    ],
    answer: 3,
  },
  {
    id: 40,
    question: [
      { type: "txt", value: "2400 ning natural bo’luvchilar sonini toping?" },
    ],
    options: [
      { type: "txt", value: "72" },
      { type: "txt", value: "10" },
      { type: "txt", value: "36" },
      { type: "txt", value: "55" },
    ],
    answer: 2,
  },
  {
    id: 41,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img52.png" },
      { type: "txt", value: "ni hisoblang." },
    ],
    options: [
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img53.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img54.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img55.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img56.png" },
    ],
    answer: 1,
  },
  {
    id: 42,
    question: [{ type: "txt", value: "Hisoblang: (-25)∙4+(-64):8-250:(-5)" }],
    options: [
      { type: "txt", value: "-158" },
      { type: "txt", value: "-58" },
      { type: "txt", value: "158" },
      { type: "txt", value: "-48" },
    ],
    answer: 1,
  },
  {
    id: 43,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img57.png" },
      { type: "txt", value: "ni soddalashtiring." },
    ],
    options: [
      { type: "txt", value: "x" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img58.png" },
      { type: "txt", value: "2x" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img59.png" },
    ],
    answer: 0,
  },
  {
    id: 44,
    question: [
      { type: "txt", value: "Tenglamani yeching:" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img60.png" },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "2" },
      { type: "txt", value: "-2" },
      { type: "txt", value: "Ø" },
    ],
    answer: 3,
  },
  {
    id: 45,
    question: [
      { type: "txt", value: "Hisoblang:" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img61.png" },
    ],
    options: [
      { type: "txt", value: "36" },
      { type: "txt", value: "52" },
      { type: "txt", value: "48" },
      { type: "txt", value: "60" },
    ],
    answer: 1,
  },
  {
    id: 46,
    question: [
      { type: "txt", value: "3456………89909192 ushbu son necha xonali?" },
    ],
    options: [
      { type: "txt", value: "172" },
      { type: "txt", value: "173" },
      { type: "txt", value: "171" },
      { type: "txt", value: "170" },
    ],
    answer: 1,
  },
  {
    id: 47,
    question: [
      {
        type: "txt",
        value:
          "1 dan 100 gacha natural sonlar ketma-ket yozilganda 100-o’rinda turuvchi raqamni toping.",
      },
    ],
    options: [
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
      { type: "txt", value: "7" },
    ],
    answer: 1,
  },
  {
    id: 48,
    question: [
      {
        type: "txt",
        value:
          "500 ni 40% ga oshirib, so’ngra 40% ga kamaytirsa qancha bo’ladi?",
      },
    ],
    options: [
      { type: "txt", value: "580" },
      { type: "txt", value: "480" },
      { type: "txt", value: "520" },
      { type: "txt", value: "420" },
    ],
    answer: 3,
  },
  {
    id: 49,
    question: [
      { type: "txt", value: "-27 ni 5 ga bo’lgandagi qoldiqni toping?" },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "1" },
    ],
    answer: 1,
  },
  {
    id: 50,
    question: [
      {
        type: "txt",
        value:
          "Alkogol miqdori 40% bo’lgan spirtli ichimlikning 1/5 qismi olinib o’rniga alkogol miqdori 80% bo’lgan ichimlik qo’shildi. Hosil bo’lgan ichimlikdagi spirt foizi qancha?",
      },
    ],
    options: [
      { type: "txt", value: "48" },
      { type: "txt", value: "50" },
      { type: "txt", value: "52" },
      { type: "txt", value: "54" },
    ],
    answer: 1,
  },
  {
    id: 51,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img62.png" },
      {
        type: "txt",
        value: "ni ochganda",
      },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img74.png" },
      { type: "txt", value: "ni oldidagi koeffitsiyentni toping?" },
    ],
    options: [
      { type: "txt", value: "-24" },
      { type: "txt", value: "-20" },
      { type: "txt", value: "-16" },
      { type: "txt", value: "8" },
    ],
    answer: 2,
  },
  {
    id: 52,
    question: [
      { type: "txt", value: "Sotuvchi molning" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img63.png" },
      {
        type: "txt",
        value:
          "qismini 20% zarariga qolganini 60% foyda bilan sotdi. Sotuvchi bu savdodan necha foiz foyda ko’rgan?",
      },
    ],
    options: [
      { type: "txt", value: "30" },
      { type: "txt", value: "25" },
      { type: "txt", value: "20" },
      { type: "txt", value: "15" },
    ],
    answer: 0,
  },
  {
    id: 53,
    question: [
      {
        type: "txt",
        value: "3!+6!+9!+⋯+33! yig’indining birlar xonasidagi raqami topilsin?",
      },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "txt", value: "6" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
    ],
    answer: 1,
  },
  {
    id: 54,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img64.png" },
      { type: "txt", value: "ko’phadi " },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img65.png" },
      { type: "txt", value: "ga bo’lgandagi qoldiqni toping." },
    ],
    options: [
      { type: "txt", value: "x-2" },
      { type: "txt", value: "2x+1" },
      { type: "txt", value: "x-5" },
      { type: "txt", value: "x+3" },
    ],
    answer: 2,
  },
  {
    id: 55,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img66.png" },
      { type: "txt", value: "ko’phadni yoyganda" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img67.png" },
      { type: "txt", value: "ni oldidagi koeffitsiyentni toping." },
    ],
    options: [
      { type: "txt", value: "232" },
      { type: "txt", value: "242" },
      { type: "txt", value: "252" },
      { type: "txt", value: "262" },
    ],
    answer: 2,
  },
  {
    id: 56,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img68.png" },
      {
        type: "txt",
        value:
          "ifoda farqli raqamlar uchun o’rinli bo’lsa, a+b+c ning maksimum qiymatini toping?",
      },
    ],
    options: [
      { type: "txt", value: "16" },
      { type: "txt", value: "17" },
      { type: "txt", value: "18" },
      { type: "txt", value: "19" },
    ],
    answer: 0,
  },
  {
    id: 57,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img69.png" },
      {
        type: "txt",
        value: "bo’lsa, P(x+2) ko’phadni x-2 ga bo’lgandagi qoldiqni toping?",
      },
    ],
    options: [
      { type: "txt", value: "-3" },
      { type: "txt", value: "-2" },
      { type: "txt", value: "-1" },
      { type: "txt", value: "2" },
    ],
    answer: 3,
  },
  {
    id: 58,
    question: [
      {
        type: "txt",
        value:
          "Ketma ket kelgan 15 ta sonning yig’indisi 285 bo’lsa, shu sonlarning eng kattasini toping?",
      },
    ],
    options: [
      { type: "txt", value: "5" },
      { type: "txt", value: "19" },
      { type: "txt", value: "26" },
      { type: "txt", value: "31" },
    ],
    answer: 2,
  },
  {
    id: 59,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img70.png" },
      { type: "txt", value: "bo’lsa, x+y+z ni toping." },
    ],
    options: [
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img71.png" },
      { type: "txt", value: "0" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img72.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img73.png" },
    ],
    answer: 3,
  },
];

const olimpiada9_10Ru = [
  {
    id: 0,
    question: [{ type: "txt", value: "Вычислите:  38∙45-45∙26+12∙5" }],
    options: [
      { type: "txt", value: "560" },
      { type: "txt", value: "600" },
      { type: "txt", value: "400" },
      { type: "txt", value: "500" },
    ],
    answer: 1,
  },
  {
    id: 1,
    question: [{ type: "txt", value: "Вычислите:  47∙64-64∙27+20∙9+73∙80" }],
    options: [
      { type: "txt", value: "7300" },
      { type: "txt", value: "8000" },
      { type: "txt", value: "7500" },
      { type: "txt", value: "5000" },
    ],
    answer: 0,
  },
  {
    id: 2,
    question: [
      { type: "txt", value: "Найдите сумму простых чисел меньше 20." },
    ],
    options: [
      { type: "txt", value: "80" },
      { type: "txt", value: "77" },
      { type: "txt", value: "58" },
      { type: "txt", value: "66" },
    ],
    answer: 1,
  },
  {
    id: 3,
    question: [{ type: "txt", value: "Вычислите:  42567∙42565-42568∙42564" }],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "-3" },
      { type: "txt", value: "-4" },
    ],
    answer: 0,
  },
  {
    id: 4,
    question: [
      {
        type: "txt",
        value: "Найдите сумму всех натуральных делителей числа 80.",
      },
    ],
    options: [
      { type: "txt", value: "160" },
      { type: "txt", value: "186" },
      { type: "txt", value: "106" },
      { type: "txt", value: "150" },
    ],
    answer: 1,
  },
  {
    id: 5,
    question: [
      {
        type: "txt",
        value: "101112131415...47484950 найдите сумму цифр числа.",
      },
    ],
    options: [
      { type: "txt", value: "280" },
      { type: "txt", value: "265" },
      { type: "txt", value: "275" },
      { type: "txt", value: "285" },
    ],
    answer: 3,
  },
  {
    id: 6,
    question: [
      { type: "txt", value: "Посчитайте половину суммы" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img1.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img2.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img3.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img4.png" },
      { type: "txt", value: "A и C ответы верны" },
    ],
    answer: 3,
  },
  {
    id: 7,
    question: [
      { type: "txt", value: "Если" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img5.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img6.png" },
      { type: "txt", value: "найдите значение выражения." },
    ],
    options: [
      { type: "txt", value: "10" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
      { type: "txt", value: "4" },
    ],
    answer: 2,
  },
  {
    id: 8,
    question: [
      {
        type: "txt",
        value:
          "a=654236,b=754272,c=783124 и d=378756 какое из этих чисел делится на 36 без остатка?",
      },
    ],
    options: [
      { type: "txt", value: "a и d" },
      { type: "txt", value: "только b" },
      { type: "txt", value: "b и c" },
      { type: "txt", value: "b и d" },
    ],
    answer: 3,
  },
  {
    id: 9,
    question: [
      {
        type: "txt",
        value:
          "Если a — чётное число, то какое из следующих чисел всегда нечётное?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img7.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img8.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img9.png" },
      { type: "txt", value: "ни один из них" },
    ],
    answer: 1,
  },
  {
    id: 10,
    question: [
      {
        type: "txt",
        value:
          "Какое из выражений означает остаток при делении? 1) 48=5·9+3; 2) 48=7·7-1; 3) 48=3·15+3; 4) 48=6·8-0; ",
      },
    ],
    options: [
      { type: "txt", value: "1 и a 4" },
      { type: "txt", value: "1 и 3" },
      { type: "txt", value: "2 и 4" },
      { type: "txt", value: "только 1" },
    ],
    answer: 3,
  },
  {
    id: 11,
    question: [
      { type: "txt", value: "Найдите остаток от деления" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img10.png" },
      { type: "txt", value: "на 7." },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "5" },
      { type: "txt", value: "4" },
      { type: "txt", value: "1" },
    ],
    answer: 3,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value: "Найдите число всех натуральных делителей числа 140.",
      },
    ],
    options: [
      { type: "txt", value: "10" },
      { type: "txt", value: "12" },
      { type: "txt", value: "16" },
      { type: "txt", value: "14" },
    ],
    answer: 1,
  },
  {
    id: 13,
    question: [
      {
        type: "txt",
        value:
          "Какие из следующих утверждений верны? 1) 0 в степени 0 не сушествует; 2) остаток будет не менее 1; 3) самое большое пятизначное натуральное число — 9999; 4) если числа одновременно делятся на 4 и 15, то они делятся и на 60; 5) если сумма чисел делится на 7, то и само число делится на 7.",
      },
    ],
    options: [
      { type: "txt", value: "1, 4 и 5" },
      { type: "txt", value: "1, 3 и 4" },
      { type: "txt", value: "1 и 4" },
      { type: "txt", value: "1, 2 и 4" },
    ],
    answer: 2,
  },
  {
    id: 14,
    question: [
      {
        type: "txt",
        value:
          "a=467820,b=754870,c=867450 и d=478750 какое из этих чисел делится на 30 без остатка?",
      },
    ],
    options: [
      { type: "txt", value: "a и c" },
      { type: "txt", value: "только a" },
      { type: "txt", value: "b и c" },
      { type: "txt", value: "все ответы верны" },
    ],
    answer: 0,
  },
  {
    id: 15,
    question: [
      {
        type: "txt",
        value:
          "Какое из выражений означает остаток при делении? 1) 54=10·6-6;    2) 54=11·4+10;    3) 54=5·9+9;    4) 54=13·4+2;",
      },
    ],
    options: [
      { type: "txt", value: "2 и 4" },
      { type: "txt", value: "1 и 2" },
      { type: "txt", value: "1 и 3" },
      { type: "txt", value: "только 1" },
    ],
    answer: 2,
  },
  {
    id: 16,
    question: [
      {
        type: "txt",
        value:
          "Если НОД чисел А и B равен 3, а произведение этих чисел равно 3600, найдите НОК.",
      },
    ],
    options: [
      { type: "txt", value: "1200" },
      { type: "txt", value: "1800" },
      { type: "txt", value: "-1200" },
      { type: "txt", value: "-1800" },
    ],
    answer: 0,
  },
  {
    id: 17,
    question: [
      { type: "txt", value: "Вычислите:" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img11.png" },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "416" },
      { type: "txt", value: "426" },
      { type: "txt", value: "0" },
    ],
    answer: 2,
  },
  {
    id: 18,
    question: [
      { type: "txt", value: "Если" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img12.png" },
      { type: "txt", value: ", найдите числовое значение" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img13.png" },
    ],
    options: [
      { type: "txt", value: "10" },
      { type: "txt", value: "12" },
      { type: "txt", value: "14" },
      { type: "txt", value: "16" },
    ],
    answer: 1,
  },
  {
    id: 19,
    question: [
      { type: "txt", value: "Вычислите:" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img14.png" },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "-3" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img15.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img16.png" },
    ],
    answer: 3,
  },
  {
    id: 20,
    question: [
      { type: "txt", value: "Решите уравнение:" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img17.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img18.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img19.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img20.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img21.png" },
    ],
    answer: 1,
  },
  {
    id: 21,
    question: [
      { type: "txt", value: "Упростите выражение:" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img22.png" },
    ],
    options: [
      { type: "txt", value: "1-b" },
      { type: "txt", value: "-b" },
      { type: "txt", value: "b-a" },
      { type: "txt", value: "1+b" },
    ],
    answer: 0,
  },
  {
    id: 22,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img23.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img24.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img25.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img26.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img27.png" },
    ],
    answer: 3,
  },
  {
    id: 23,
    question: [
      { type: "txt", value: "Если пара чисел (x;y) является решением системы" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img28.png" },
      { type: "txt", value: "найдите x∙y." },
    ],
    options: [
      { type: "txt", value: "-6" },
      { type: "txt", value: "-8" },
      { type: "txt", value: "8" },
      { type: "txt", value: "6" },
    ],
    answer: 3,
  },
  {
    id: 24,
    question: [{ type: "txt", value: "Найдите сумму всех цифр?" }],
    options: [
      { type: "txt", value: "30" },
      { type: "txt", value: "45" },
      { type: "txt", value: "∞" },
      { type: "txt", value: "40" },
    ],
    answer: 1,
  },
  {
    id: 25,
    question: [
      { type: "txt", value: "Вычислите:" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img29.png" },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "10" },
    ],
    answer: 0,
  },
  {
    id: 26,
    question: [
      { type: "txt", value: "Найдите сумму корней уравнения" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img30.png" },
    ],
    options: [
      { type: "txt", value: "-0,5" },
      { type: "txt", value: "-0,4" },
      { type: "txt", value: "-0,3" },
      { type: "txt", value: "-0,2" },
    ],
    answer: 0,
  },
  {
    id: 27,
    question: [
      {
        type: "txt",
        value:
          "Если x=0,(201),y=0,2(01),z=0,20(1), то расположите числа в порядке возрастания.",
      },
    ],
    options: [
      { type: "txt", value: "z<y<x" },
      { type: "txt", value: "y<z<x" },
      { type: "txt", value: "x<y<z" },
      { type: "txt", value: "y<x<z" },
    ],
    answer: 1,
  },
  {
    id: 28,
    question: [
      {
        type: "txt",
        value:
          "Какова последняя цифра наименьшего натурального числа, сумма цифр которого равна 2001?",
      },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
    ],
    answer: 1,
  },
  {
    id: 29,
    question: [
      { type: "txt", value: "Решите уравнение." },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img31.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img32.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img33.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img34.png" },
      { type: "txt", value: "21" },
    ],
    answer: 2,
  },
  {
    id: 30,
    question: [
      { type: "txt", value: "Упростите." },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img35.png" },
    ],
    options: [
      { type: "txt", value: "x+2" },
      { type: "txt", value: "x+3" },
      { type: "txt", value: "x-2" },
      { type: "txt", value: "x-3" },
    ],
    answer: 0,
  },
  {
    id: 31,
    question: [
      { type: "txt", value: "Вычислите:" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img36.png" },
    ],
    options: [
      { type: "txt", value: "7" },
      { type: "txt", value: "5" },
      { type: "txt", value: "0" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img37.png" },
    ],
    answer: 1,
  },
  {
    id: 32,
    question: [
      { type: "txt", value: "Если abc=16 и ab+ac+bc=12, найдите" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img38.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img39.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img40.png" },
      { type: "txt", value: "2" },
      { type: "txt", value: "1" },
    ],
    answer: 0,
  },
  {
    id: 33,
    question: [
      { type: "txt", value: "Вычислите:" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img41.png" },
    ],
    options: [
      { type: "txt", value: "56" },
      { type: "txt", value: "53" },
      { type: "txt", value: "72" },
      { type: "txt", value: "81" },
    ],
    answer: 0,
  },
  {
    id: 34,
    question: [
      {
        type: "txt",
        value: "Найдите сумму цифр числа 101112131415...75767778",
      },
    ],
    options: [
      { type: "txt", value: "603" },
      { type: "txt", value: "595" },
      { type: "txt", value: "578" },
      { type: "txt", value: "579" },
    ],
    answer: 3,
  },
  {
    id: 35,
    question: [
      { type: "txt", value: "Упростите:" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img42.png" },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img43.png" },
      { type: "txt", value: "3" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img44.png" },
    ],
    answer: 3,
  },
  {
    id: 36,
    question: [
      { type: "txt", value: "Вычислите:" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img45.png" },
    ],
    options: [
      { type: "txt", value: "144" },
      { type: "txt", value: "95" },
      { type: "txt", value: "104" },
      { type: "txt", value: "128" },
    ],
    answer: 1,
  },
  {
    id: 37,
    question: [
      { type: "txt", value: "Найдите последнюю цифру числа" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img46.png" },
    ],
    options: [
      { type: "txt", value: "7" },
      { type: "txt", value: "9" },
      { type: "txt", value: "3" },
      { type: "txt", value: "1" },
    ],
    answer: 2,
  },
  {
    id: 38,
    question: [
      { type: "txt", value: "На сколько меньше" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img47.png" },
      { type: "txt", value: "часть из 24, чем" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img48.png" },
      { type: "txt", value: "часть из 34?" },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 0,
  },
  {
    id: 39,
    question: [
      {
        type: "txt",
        value: "Какое из следующих чисел для любого числа n является чётным?",
      },
    ],
    options: [
      { type: "txt", value: "n!+n+1" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img49.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img50.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img51.png" },
    ],
    answer: 3,
  },
  {
    id: 40,
    question: [
      { type: "txt", value: "Найдите число натуральных делителей числа 2400." },
    ],
    options: [
      { type: "txt", value: "72" },
      { type: "txt", value: "10" },
      { type: "txt", value: "36" },
      { type: "txt", value: "55" },
    ],
    answer: 2,
  },
  {
    id: 41,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img52.png" },
      { type: "txt", value: "Вычислите:" },
    ],
    options: [
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img53.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img54.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img55.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img56.png" },
    ],
    answer: 1,
  },
  {
    id: 42,
    question: [{ type: "txt", value: "Вычислите: (-25)∙4+(-64):8-250:(-5)" }],
    options: [
      { type: "txt", value: "-158" },
      { type: "txt", value: "-58" },
      { type: "txt", value: "158" },
      { type: "txt", value: "-48" },
    ],
    answer: 1,
  },
  {
    id: 43,
    question: [
      { type: "txt", value: "Упростите:" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img57.png" },
    ],
    options: [
      { type: "txt", value: "x" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img58.png" },
      { type: "txt", value: "2x" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img59.png" },
    ],
    answer: 0,
  },
  {
    id: 44,
    question: [
      { type: "txt", value: "Решите уравнение:" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img60.png" },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "2" },
      { type: "txt", value: "-2" },
      { type: "txt", value: "Ø" },
    ],
    answer: 3,
  },
  {
    id: 45,
    question: [
      { type: "txt", value: "Вычислите:" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img61.png" },
    ],
    options: [
      { type: "txt", value: "36" },
      { type: "txt", value: "52" },
      { type: "txt", value: "48" },
      { type: "txt", value: "60" },
    ],
    answer: 1,
  },
  {
    id: 46,
    question: [
      { type: "txt", value: "Сколько цифр имеется в числе 3456………89909192?" },
    ],
    options: [
      { type: "txt", value: "172" },
      { type: "txt", value: "173" },
      { type: "txt", value: "171" },
      { type: "txt", value: "170" },
    ],
    answer: 1,
  },
  {
    id: 47,
    question: [
      {
        type: "txt",
        value: "Найдите 100-ю цифру в числе 123456…9899100.",
      },
    ],
    options: [
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
      { type: "txt", value: "7" },
    ],
    answer: 1,
  },
  {
    id: 48,
    question: [
      {
        type: "txt",
        value:
          "Чему будет равно число 500, если его увеличить на 40%, а затем уменьшить на 40%?",
      },
    ],
    options: [
      { type: "txt", value: "580" },
      { type: "txt", value: "480" },
      { type: "txt", value: "520" },
      { type: "txt", value: "420" },
    ],
    answer: 3,
  },
  {
    id: 49,
    question: [{ type: "txt", value: "Найдите остаток от деления -27 на 5?" }],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "1" },
    ],
    answer: 1,
  },
  {
    id: 50,
    question: [
      {
        type: "txt",
        value:
          "Брали 1/5 часть 40% спиртного напитка и заменяли 80% спиртом. Какой процент алкоголя в полученном напитке?",
      },
    ],
    options: [
      { type: "txt", value: "48" },
      { type: "txt", value: "50" },
      { type: "txt", value: "52" },
      { type: "txt", value: "54" },
    ],
    answer: 1,
  },
  {
    id: 51,
    question: [
      { type: "txt", value: "Найдите коэффициент перед" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img74.png" },
      { type: "txt", value: "при разложении" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img62.png" },
    ],
    options: [
      { type: "txt", value: "-24" },
      { type: "txt", value: "-20" },
      { type: "txt", value: "-16" },
      { type: "txt", value: "8" },
    ],
    answer: 2,
  },
  {
    id: 52,
    question: [
      { type: "txt", value: "Продавец продал" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img63.png" },
      {
        type: "txt",
        value:
          "часть товара с убытком в 20%, а остальную часть с прибылью в 60%. Какую прибыль получил продавец от этой продажи?",
      },
    ],
    options: [
      { type: "txt", value: "30" },
      { type: "txt", value: "25" },
      { type: "txt", value: "20" },
      { type: "txt", value: "15" },
    ],
    answer: 0,
  },
  {
    id: 53,
    question: [
      {
        type: "txt",
        value: "3!+6!+9!+⋯+33! Найдите последнюю цифру суммы.",
      },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "txt", value: "6" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
    ],
    answer: 1,
  },
  {
    id: 54,
    question: [
      { type: "txt", value: "Найдите остаток при делении" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img64.png" },
      { type: "txt", value: "на" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img65.png" },
    ],
    options: [
      { type: "txt", value: "x-2" },
      { type: "txt", value: "2x+1" },
      { type: "txt", value: "x-5" },
      { type: "txt", value: "x+3" },
    ],
    answer: 2,
  },
  {
    id: 55,
    question: [
      {
        type: "txt",
        value: "Найдите коэффициент при x² при разложении многочлена",
      },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img66.png" },
    ],
    options: [
      { type: "txt", value: "232" },
      { type: "txt", value: "242" },
      { type: "txt", value: "252" },
      { type: "txt", value: "262" },
    ],
    answer: 2,
  },
  {
    id: 56,
    question: [
      {
        type: "txt",
        value: "Если выражение",
      },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img68.png" },
      {
        type: "txt",
        value:
          "справедливо для нечётных чисел, найдите максимальное значение a+b+c.",
      },
    ],
    options: [
      { type: "txt", value: "16" },
      { type: "txt", value: "17" },
      { type: "txt", value: "18" },
      { type: "txt", value: "19" },
    ],
    answer: 0,
  },
  {
    id: 57,
    question: [
      {
        type: "txt",
        value: "Если",
      },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img69.png" },
      {
        type: "txt",
        value: "найдите остаток от деления многочлена P(x+2) на x-2.",
      },
    ],
    options: [
      { type: "txt", value: "-3" },
      { type: "txt", value: "-2" },
      { type: "txt", value: "-1" },
      { type: "txt", value: "2" },
    ],
    answer: 3,
  },
  {
    id: 58,
    question: [
      {
        type: "txt",
        value:
          "Если сумма последовательных чисел равна 285, найдите наибольшее из этих чисел.",
      },
    ],
    options: [
      { type: "txt", value: "5" },
      { type: "txt", value: "19" },
      { type: "txt", value: "26" },
      { type: "txt", value: "31" },
    ],
    answer: 2,
  },
  {
    id: 59,
    question: [
      { type: "txt", value: "Если" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img70.png" },
      { type: "txt", value: "найдите x+y+z." },
    ],
    options: [
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img71.png" },
      { type: "txt", value: "0" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img72.png" },
      { type: "img", value: "/quiz-images/olimpiada/9-10-sinf/img73.png" },
    ],
    answer: 3,
  },
];

const olimpiada11SinfUz = [
  {
    id: 0,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Tenglamani yeching. |𝑥 − 1| + |𝑥 − 3| = 2𝑥 − 4",
      },
    ],
    options: [
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/1.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/2.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/3.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/4.png",
      },
    ],
  },
  {
    id: 1,
    answer: 3,
    question: [
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img2.png",
      },
      {
        type: "txt",
        value: "tenglamaning haqiqiy ildizlari ko`paytmasini toping?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "-10",
      },
      {
        type: "txt",
        value: "-18",
      },
      {
        type: "txt",
        value: "-32",
      },
      {
        type: "txt",
        value: "-25",
      },
    ],
  },
  {
    id: 2,
    answer: 0,
    question: [
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img3.png",
      },
      {
        type: "txt",
        value: "a ning qanday qiymatlarida sistema yechimga ega bo`lmaydi.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "0",
      },
      {
        type: "txt",
        value: "1",
      },
      {
        type: "txt",
        value: "2",
      },
      {
        type: "txt",
        value: "±1",
      },
    ],
  },
  {
    id: 3,
    answer: 0,
    question: [
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img4.png",
      },
      {
        type: "txt",
        value: "bo`lsa, x+y+z ni toping?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "7 yoki 2",
      },
      {
        type: "txt",
        value: "5 yoki 3",
      },
      {
        type: "txt",
        value: "4 yoki 7",
      },
      {
        type: "txt",
        value: "6 yoki 5",
      },
    ],
  },
  {
    id: 4,
    answer: 3,
    question: [
      {
        type: "txt",
        value: "Quyidagilardan eng katta sonni toping.",
      },
    ],
    options: [
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img80.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img81.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img82.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img83.png",
      },
    ],
  },
  {
    id: 5,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Arifmetik progressiyada",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img84.png",
      },
      {
        type: "txt",
        value: "va d=5 bo’lsa,",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img5.png",
      },
      {
        type: "txt",
        value: "ni toping.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "80",
      },
      {
        type: "txt",
        value: "81",
      },
      {
        type: "txt",
        value: "82",
      },
      {
        type: "txt",
        value: "83",
      },
    ],
  },
  {
    id: 6,
    answer: 3,
    question: [
      {
        type: "txt",
        value: "Arifmetik progressiya n ta hadining yig’indisi",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img6.png",
      },
      {
        type: "txt",
        value: "bo’lsa, progressiyaning ayirmasini toping.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "9",
      },
      {
        type: "txt",
        value: "6",
      },
      {
        type: "txt",
        value: "7",
      },
      {
        type: "txt",
        value: "8",
      },
    ],
  },
  {
    id: 7,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "16 va 25 sonlar orasiga shunday musbat son qo’yilganki, ular geometrik progressiya hosil qiladi. Shu progressiya maxrajini toping.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "1,2",
      },
      {
        type: "txt",
        value: "1,25",
      },
      {
        type: "txt",
        value: "2,4",
      },
      {
        type: "txt",
        value: "1,5",
      },
    ],
  },
  {
    id: 8,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "Cheksiz kamayuvchi geometrik progressiya dastlabki 6 ta hadi yig’indisi uning barcha hadlari yig’indisining 7/8 qismiga teng. Progressiya maxrajini toping.",
      },
    ],
    options: [
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/5.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/6.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/7.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/8.png",
      },
    ],
  },
  {
    id: 9,
    answer: 2,
    question: [
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img7.png",
      },
      {
        type: "txt",
        value: "tengsizlikni yeching.",
      },
    ],
    options: [
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/9.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/10.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/11.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/12.png",
      },
    ],
  },
  {
    id: 10,
    answer: 1,
    question: [
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img8.png",
      },
      {
        type: "txt",
        value: "tengsizlik nechta butun yechimga ega?",
      },
    ],
    options: [
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/13.png",
      },
      {
        type: "txt",
        value: "4 ta",
      },
      {
        type: "txt",
        value: "3 ta",
      },
      {
        type: "txt",
        value: "cheksiz ko’p",
      },
    ],
  },
  {
    id: 11,
    answer: 1,
    question: [
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img9.png",
      },
      {
        type: "txt",
        value: "tengsizlikni yeching.",
      },
    ],
    options: [
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/14.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/15.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/16.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/17.png",
      },
    ],
  },
  {
    id: 12,
    answer: 3,
    question: [
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img10.png",
      },
      {
        type: "txt",
        value:
          "funksiya aniqlanish sohasiga tegishli butun sonlar yig’indisini toping.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "19",
      },
      {
        type: "txt",
        value: "21",
      },
      {
        type: "txt",
        value: "14",
      },
      {
        type: "txt",
        value: "16",
      },
    ],
  },
  {
    id: 13,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Hisoblang: ",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img11.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "1/3",
      },
      {
        type: "txt",
        value: "2",
      },
      {
        type: "txt",
        value: "3",
      },
      {
        type: "txt",
        value: "6",
      },
    ],
  },
  {
    id: 14,
    answer: 1,
    question: [
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img12.png",
      },
      {
        type: "txt",
        value: "bo’lsa, ",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img13.png",
      },
      {
        type: "txt",
        value: "ni toping.",
      },
    ],
    options: [
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/18.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/19.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/20.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/21.png",
      },
    ],
  },
  {
    id: 15,
    answer: 0,
    question: [
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img14.png",
      },
      {
        type: "txt",
        value: "tenglama nechta yechimga ega?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "1 ta",
      },
      {
        type: "txt",
        value: "2 ta",
      },
      {
        type: "txt",
        value: "3 ta",
      },
      {
        type: "txt",
        value: "4 ta",
      },
    ],
  },
  {
    id: 16,
    answer: 3,
    question: [
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img15.png",
      },
      {
        type: "txt",
        value: "tenglama ildizlari ko’paytmasini toping.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "1",
      },
      {
        type: "txt",
        value: "30",
      },
      {
        type: "txt",
        value: "24",
      },
      {
        type: "txt",
        value: "60",
      },
    ],
  },
  {
    id: 17,
    answer: 3,
    question: [
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img16.png",
      },
      {
        type: "txt",
        value: "tenglama nechta manfiy ildizga ega?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "1 ta",
      },
      {
        type: "txt",
        value: "2 ta",
      },
      {
        type: "txt",
        value: "3 ta",
      },
      {
        type: "txt",
        value: "manfiy ildizi yo’q",
      },
    ],
  },
  {
    id: 18,
    answer: 3,
    question: [
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img17.png",
      },
      {
        type: "txt",
        value: "tenglama ildizlari yigindisini toping.  ",
      },
    ],
    options: [
      {
        type: "txt",
        value: "∅",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img18.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img19.png",
      },
      {
        type: "txt",
        value: "-2",
      },
    ],
  },
  {
    id: 19,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img20.png" },
      { type: "txt", value: "tenglamani yeching." },
    ],
    options: [
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img21.png" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img22.png" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img23.png" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img24.png" },
    ],
    answer: 0,
  },
  {
    id: 20,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img25.png" },
      { type: "txt", value: "ni hisoblang." },
    ],
    options: [
      { type: "txt", value: "200" },
      { type: "txt", value: "300" },
      { type: "txt", value: "400" },
      { type: "txt", value: "500" },
    ],
    answer: 0,
  },
  {
    id: 21,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img26.png" },
      { type: "txt", value: "ni hisoblang." },
    ],
    options: [
      { type: "txt", value: "1032" },
      { type: "txt", value: "120" },
      { type: "txt", value: "1024" },
      { type: "txt", value: "20" },
    ],
    answer: 2,
  },
  {
    id: 22,
    question: [
      { type: "txt", value: "Yig'indi qanday raqam bilan tugaydi?" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img27.png" },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
    ],
    answer: 0,
  },
  {
    id: 23,
    question: [
      { type: "txt", value: "Hisoblang:" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img28.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img29.png" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img30.png" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img31.png" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img32.png" },
    ],
    answer: 3,
  },
  {
    id: 24,
    question: [{ type: "txt", value: "-57 ni 8 ga bo’lgandagi qoldiq?" }],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "7" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 1,
  },
  {
    id: 25,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img33.png" },
      { type: "txt", value: "sonlarni o’sish tartibida joylashtiring." },
    ],
    options: [
      { type: "txt", value: "a<b<c" },
      { type: "txt", value: "b<a<c" },
      { type: "txt", value: "c<a<b" },
      { type: "txt", value: "b<c<a" },
    ],
    answer: 2,
  },
  {
    id: 26,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img34.png" },
      { type: "txt", value: "bo’lsa," },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img35.png" },
      { type: "txt", value: "ni toping." },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img36.png" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img37.png" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img38.png" },
    ],
    answer: 0,
  },
  {
    id: 27,
    question: [
      {
        type: "txt",
        value: "Agar a juft son bo’lsa, quyidagilardan qaysi biri doimo toq?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img39.png" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img40.png" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img41.png" },
      { type: "txt", value: "Hech biri" },
    ],
    answer: 1,
  },
  {
    id: 28,
    question: [{ type: "txt", value: "Hisoblang: 42567∙42565-42568∙42564" }],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "-3" },
      { type: "txt", value: "-4" },
    ],
    answer: 0,
  },
  {
    id: 29,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img42.png" },
      { type: "txt", value: "ni hisoblang." },
    ],
    options: [
      { type: "txt", value: "6" },
      { type: "txt", value: "2" },
      { type: "txt", value: "4" },
      { type: "txt", value: "3" },
    ],
    answer: 0,
  },
  {
    id: 30,
    question: [
      {
        type: "txt",
        value:
          "Agar m-n=(4x-y) va n-m=(x-4y-30) bo’lsa, y-x ning qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "-30" },
      { type: "txt", value: "-15" },
      { type: "txt", value: "-10" },
      { type: "txt", value: "-6" },
    ],
    answer: 3,
  },
  {
    id: 31,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img43.png" },
      { type: "txt", value: "tengsizlikni yeching." },
    ],
    options: [
      { type: "txt", value: "x∈R" },
      { type: "txt", value: "x∈R-{3}" },
      { type: "txt", value: "x∈R-{1}" },
      { type: "txt", value: "(0;∞)" },
    ],
    answer: 1,
  },
  {
    id: 32,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img44.png" },
      { type: "txt", value: "tenglamalar sistemasini yeching. " },
    ],
    options: [
      { type: "txt", value: "[-3; 5)" },
      { type: "txt", value: "(2; 4]" },
      { type: "txt", value: "[-6; 6)" },
      { type: "txt", value: "[6; ∞) " },
    ],
    answer: 2,
  },
  {
    id: 33,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img45.png" },
      {
        type: "txt",
        value:
          "tengsizlikning eng katta va eng kichik yechimlari ayirmasini toping.",
      },
    ],
    options: [
      { type: "txt", value: "6" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "2" },
    ],
    answer: 1,
  },
  {
    id: 34,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img46.png" },
      {
        type: "txt",
        value: "ayniyatdagi no’malum koeffitsiyentlardan biri β ni toping.",
      },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "-1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "-2" },
    ],
    answer: 1,
  },

  {
    id: 35,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img47.png" },
      { type: "txt", value: "ni hisoblang." },
    ],
    options: [
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img48.png" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img49.png" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img50.png" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img51.png" },
    ],
    answer: 2,
  },
  {
    id: 36,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img52.png" },
      { type: "txt", value: "tenglamaning ildizlari bo’lib," },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img53.png" },
      {
        type: "txt",
        value: "tenglikni qanoatlantirsa, a ning qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "-1" },
      { type: "txt", value: "-2" },
      { type: "txt", value: "-3" },
      { type: "txt", value: "3" },
    ],
    answer: 2,
  },
  {
    id: 37,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img54.png" },
      { type: "txt", value: "bo’lsa," },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img55.png" },
      { type: "txt", value: "ni toping." },
    ],
    options: [
      { type: "txt", value: "4-2x" },
      { type: "txt", value: "4-3x" },
      { type: "txt", value: "4-3x" },
      { type: "txt", value: "2x+4" },
    ],
    answer: 0,
  },
  {
    id: 38,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img56.png" },
      { type: "txt", value: "bo’lsa, x ni toping." },
    ],
    options: [
      { type: "txt", value: "181" },
      { type: "txt", value: "180" },
      { type: "txt", value: "179" },
      { type: "txt", value: "178" },
    ],
    answer: 2,
  },
  {
    id: 39,
    question: [
      {
        type: "txt",
        value: "|| x - 2 | -3 | = 4 tenglama nechta ildizga ega?",
      },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 1,
  },
  {
    id: 40,
    question: [
      { type: "txt", value: "k ning qanday qiymatlarida" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img57.png" },
      { type: "txt", value: "tenglama manfiy yechimga ega?" },
    ],
    options: [
      { type: "txt", value: "k≤3" },
      { type: "txt", value: "k<3,k>5" },
      { type: "txt", value: "k> -5" },
      { type: "txt", value: "k<5" },
    ],
    answer: 1,
  },
  {
    id: 41,
    question: [
      { type: "txt", value: "|(x+3)(x+1)+1|≤0 tengsizlikni yeching?" },
    ],
    options: [
      { type: "txt", value: "∅" },
      { type: "txt", value: "2" },
      { type: "txt", value: "-2" },
      { type: "txt", value: "0" },
    ],
    answer: 2,
  },
  {
    id: 42,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img58.png" },
      { type: "txt", value: "tenglamaning ildizlari uchun" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img59.png" },
      { type: "txt", value: "ni hisoblang?" },
    ],
    options: [
      { type: "txt", value: "21" },
      { type: "txt", value: "12" },
      { type: "txt", value: "17" },
      { type: "txt", value: "31" },
    ],
    answer: 3,
  },
  {
    id: 43,
    question: [
      {
        type: "txt",
        value: "A=1-2+3-4+…+25; B=4-5+6-7+…-29 bo’lsa, A+B ni toping?",
      },
    ],
    options: [
      { type: "txt", value: "-26" },
      { type: "txt", value: "-27" },
      { type: "txt", value: "0" },
      { type: "txt", value: "27" },
    ],
    answer: 2,
  },
  {
    id: 44,
    question: [
      {
        type: "txt",
        value: "9,(9)+10,(9) +11,(9)+…+59,(9)=59,(9)x bo’lsa, x ni toping.",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img60.png" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img61.png" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img62.png" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img63.png" },
    ],
    answer: 2,
  },
  {
    id: 45,
    question: [
      {
        type: "txt",
        value: "323334….7374 sonning raqamlari yig’indisini hisoblang?",
      },
    ],
    options: [
      { type: "txt", value: "368" },
      { type: "txt", value: "391" },
      { type: "txt", value: "398" },
      { type: "txt", value: "405" },
    ],
    answer: 2,
  },
  {
    id: 46,
    question: [
      { type: "txt", value: "Hisoblang" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img64.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img65.png" },
      { type: "txt", value: "0" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img66.png" },
      { type: "txt", value: "1" },
    ],
    answer: 1,
  },
  {
    id: 47,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img67.png" },
      { type: "txt", value: "funksiyaning aniqlanish sohasini toping." },
    ],
    options: [
      { type: "txt", value: "(-∞;-1]∪(0;1)∪(1;2]" },
      { type: "txt", value: "(-∞;-1)∪(0;1)∪(1;2)" },
      { type: "txt", value: "[-1;0)∪(0;1)∪(1;2]" },
      { type: "txt", value: "(-∞;-1]∪(0;2]" },
    ],
    answer: 0,
  },
  {
    id: 48,
    question: [
      { type: "txt", value: "a<0,b<0,c>0 bo’lsa," },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img68.png" },
      { type: "txt", value: "ifodani soddalashtiring." },
    ],
    options: [
      { type: "txt", value: "2a-2c" },
      { type: "txt", value: "a-2b+c" },
      { type: "txt", value: "-a" },
      { type: "txt", value: "a-2b" },
    ],
    answer: 0,
  },
  {
    id: 49,
    question: [
      {
        type: "txt",
        value:
          "|x-4|≤|x+4| Tengsizlikning eng kichik natural yechimini toping.",
      },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "4" },
      { type: "txt", value: "3" },
      { type: "txt", value: "1" },
    ],
    answer: 3,
  },
  {
    id: 50,
    question: [
      {
        type: "txt",
        value: "|x+1| = |2x-1| Tenglamaning ildizlari yig’indisini toping.",
      },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "4" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4,2" },
    ],
    answer: 0,
  },
  {
    id: 51,
    question: [
      { type: "txt", value: "a∙b∙c=5 bo’lsa," },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img69.png" },
      { type: "txt", value: "ni hisoblang." },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "txt", value: "-1,2" },
      { type: "txt", value: "-0,6" },
      { type: "txt", value: "-1" },
    ],
    answer: 1,
  },
  {
    id: 52,
    question: [
      { type: "txt", value: "Tengsizlikni yeching." },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img70.png" },
    ],
    options: [
      { type: "txt", value: "(2; 4)" },
      { type: "txt", value: "(2; 5)" },
      { type: "txt", value: "(1; 6)" },
      { type: "txt", value: "(3; 4)" },
    ],
    answer: 1,
  },
  {
    id: 53,
    question: [{ type: "txt", value: "||x-1|-2|≥1 tengsizlikni yeching." }],
    options: [
      { type: "txt", value: "(-∞;-3)" },
      { type: "txt", value: "(-∞;-2] ∪ [0;2] ∪ [4; ∞)" },
      { type: "txt", value: "(-2;10)" },
      { type: "txt", value: "(-2; ∞)" },
    ],
    answer: 1,
  },
  {
    id: 54,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img71.png" },
      { type: "txt", value: "bo’lsa  x-y=?" },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "5" },
      { type: "txt", value: "7" },
    ],
    answer: 1,
  },
  {
    id: 55,
    question: [
      { type: "txt", value: "Hisoblang:" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img72.png" },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img73.png" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img74.png" },
    ],
    answer: 1,
  },
  {
    id: 56,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img75.png" },
      {
        type: "txt",
        value:
          "tengsizlikni qanoatlantiradigan butun sonlar yig’indisini toping?",
      },
    ],
    options: [
      { type: "txt", value: "-3" },
      { type: "txt", value: "0" },
      { type: "txt", value: "5" },
      { type: "txt", value: "8" },
    ],
    answer: 3,
  },
  {
    id: 57,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img76.png" },
      { type: "txt", value: "tenglamaning ildizlari uchun" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img77.png" },
      { type: "txt", value: "bo’lsa, n nimaga teng?" },
    ],
    options: [
      { type: "txt", value: "(0;4)" },
      { type: "txt", value: "(-4;4)" },
      { type: "txt", value: "(-4;0)" },
      { type: "txt", value: "R-(-4;4)" },
    ],
    answer: 1,
  },
  {
    id: 58,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img78.png" },
      { type: "txt", value: "tenglamaning ildizlari yig’indisini toping?" },
    ],
    options: [
      { type: "txt", value: "4" },
      { type: "txt", value: "6" },
      { type: "txt", value: "9" },
      { type: "txt", value: "11" },
    ],
    answer: 0,
  },
  {
    id: 59,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img79.png" },
      { type: "txt", value: "tengsizlikning butun yechimlari nechta?" },
    ],
    options: [
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
      { type: "txt", value: "7" },
      { type: "txt", value: "9" },
    ],
    answer: 2,
  },
];

const olimpiada11SinfRu = [
  {
    id: 0,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Решите уравнение. |𝑥 − 1| + |𝑥 − 3| = 2𝑥 − 4",
      },
    ],
    options: [
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/1.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/2.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/3.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/4.png",
      },
    ],
  },
  {
    id: 1,
    answer: 3,
    question: [
      {
        type: "txt",
        value: "Найдите произведение действительных корней уравнения",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img2.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "-10",
      },
      {
        type: "txt",
        value: "-18",
      },
      {
        type: "txt",
        value: "-32",
      },
      {
        type: "txt",
        value: "-25",
      },
    ],
  },
  {
    id: 2,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "При каких значениях a система уравнения не имеет решения?",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img3.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "0",
      },
      {
        type: "txt",
        value: "1",
      },
      {
        type: "txt",
        value: "2",
      },
      {
        type: "txt",
        value: "±1",
      },
    ],
  },
  {
    id: 3,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Если ",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img4.png",
      },
      {
        type: "txt",
        value: "найдите x+y+z. ",
      },
    ],
    options: [
      {
        type: "txt",
        value: "7 или 2",
      },
      {
        type: "txt",
        value: "5 или 3",
      },
      {
        type: "txt",
        value: "4 или 7",
      },
      {
        type: "txt",
        value: "6 или 5",
      },
    ],
  },
  {
    id: 4,
    answer: 3,
    question: [
      {
        type: "txt",
        value: "Найдите наибольшее число из нижеперечисленных.",
      },
    ],
    options: [
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img80.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img81.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img82.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img83.png",
      },
    ],
  },
  {
    id: 5,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Если в арифметической прогрессии ",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img84.png",
      },
      {
        type: "txt",
        value: "и d=5, то найдите",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img5.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "80",
      },
      {
        type: "txt",
        value: "81",
      },
      {
        type: "txt",
        value: "82",
      },
      {
        type: "txt",
        value: "83",
      },
    ],
  },
  {
    id: 6,
    answer: 3,
    question: [
      {
        type: "txt",
        value:
          "Если сумма первых n-членов арифметической прогрессии определяется по формуле ",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img6.png",
      },
      {
        type: "txt",
        value: "найдите разность прогрессии.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "9",
      },
      {
        type: "txt",
        value: "6",
      },
      {
        type: "txt",
        value: "7",
      },
      {
        type: "txt",
        value: "8",
      },
    ],
  },
  {
    id: 7,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "Между числами 16 и 25 вставлено положительное число таким образом, что они образуют геометрическую прогрессию. Найдите знаменатель этой прогрессии.",
      },
    ],
    options: [
      {
        type: "txt",
        value: "1,2",
      },
      {
        type: "txt",
        value: "1,25",
      },
      {
        type: "txt",
        value: "2,4",
      },
      {
        type: "txt",
        value: "1,5",
      },
    ],
  },
  {
    id: 8,
    answer: 1,
    question: [
      {
        type: "txt",
        value:
          "Сумма первых шести членов бесконечно убывающей геометрической прогрессии составляет  7/8 части от суммы всех членов этой прогрессии. Найдите знаменатель этой прогрессии.",
      },
    ],
    options: [
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/5.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/6.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/7.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/8.png",
      },
    ],
  },
  {
    id: 9,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Решите неравенство",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img7.png",
      },
    ],
    options: [
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/9.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/10.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/11.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/12.png",
      },
    ],
  },
  {
    id: 10,
    answer: 1,
    question: [
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img8.png",
      },
      {
        type: "txt",
        value: "сколько целых решений имеет неравенство?",
      },
    ],
    options: [
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/13.png",
      },
      {
        type: "txt",
        value: "4 ta",
      },
      {
        type: "txt",
        value: "3 ta",
      },
      {
        type: "txt",
        value: "бесконечно",
      },
    ],
  },
  {
    id: 11,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Решите неравенство",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img9.png",
      },
    ],
    options: [
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/14.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/15.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/16.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/17.png",
      },
    ],
  },
  {
    id: 12,
    answer: 3,
    question: [
      {
        type: "txt",
        value:
          "Найдите сумму целых чисел, принадлежащих области определения функции. ",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img10.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "19",
      },
      {
        type: "txt",
        value: "21",
      },
      {
        type: "txt",
        value: "14",
      },
      {
        type: "txt",
        value: "16",
      },
    ],
  },
  {
    id: 13,
    answer: 2,
    question: [
      {
        type: "txt",
        value: "Вычислите: ",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img11.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "1/3",
      },
      {
        type: "txt",
        value: "2",
      },
      {
        type: "txt",
        value: "3",
      },
      {
        type: "txt",
        value: "6",
      },
    ],
  },
  {
    id: 14,
    answer: 1,
    question: [
      {
        type: "txt",
        value: "Если",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img12.png",
      },
      {
        type: "txt",
        value: "то найдите",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img13.png",
      },
    ],
    options: [
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/18.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/19.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/20.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/21.png",
      },
    ],
  },
  {
    id: 15,
    answer: 0,
    question: [
      {
        type: "txt",
        value: "Сколько решений имеет уравнение?",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img14.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "1 ta",
      },
      {
        type: "txt",
        value: "2 ta",
      },
      {
        type: "txt",
        value: "3 ta",
      },
      {
        type: "txt",
        value: "4 ta",
      },
    ],
  },
  {
    id: 16,
    answer: 3,
    question: [
      {
        type: "txt",
        value: "Найдите произведение корней уравнения.",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img15.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "1",
      },
      {
        type: "txt",
        value: "30",
      },
      {
        type: "txt",
        value: "24",
      },
      {
        type: "txt",
        value: "60",
      },
    ],
  },
  {
    id: 17,
    answer: 3,
    question: [
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img16.png",
      },
      {
        type: "txt",
        value: "сколько отрицательных корней имеет уравнение?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "1 ta",
      },
      {
        type: "txt",
        value: "2 ta",
      },
      {
        type: "txt",
        value: "3 ta",
      },
      {
        type: "txt",
        value: "0",
      },
    ],
  },
  {
    id: 18,
    answer: 3,
    question: [
      {
        type: "txt",
        value: "Найдите сумму корней уравнения",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img17.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "∅",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img18.png",
      },
      {
        type: "img",
        value: "/quiz-images/olimpiada/11-sinf/img19.png",
      },
      {
        type: "txt",
        value: "-2",
      },
    ],
  },
  {
    id: 19,
    question: [
      { type: "txt", value: "Решите уравнение:" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img20.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img21.png" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img22.png" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img23.png" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img24.png" },
    ],
    answer: 0,
  },
  {
    id: 20,
    question: [
      { type: "txt", value: "Вычислите:" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img25.png" },
    ],
    options: [
      { type: "txt", value: "200" },
      { type: "txt", value: "300" },
      { type: "txt", value: "400" },
      { type: "txt", value: "500" },
    ],
    answer: 0,
  },
  {
    id: 21,
    question: [
      { type: "txt", value: "Вычислите:" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img26.png" },
    ],
    options: [
      { type: "txt", value: "1032" },
      { type: "txt", value: "120" },
      { type: "txt", value: "1024" },
      { type: "txt", value: "20" },
    ],
    answer: 2,
  },
  {
    id: 22,
    question: [
      { type: "txt", value: "Найдите последнюю цифру суммы?" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img27.png" },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
    ],
    answer: 0,
  },
  {
    id: 23,
    question: [
      { type: "txt", value: "Вычислите:" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img28.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img29.png" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img30.png" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img31.png" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img32.png" },
    ],
    answer: 3,
  },
  {
    id: 24,
    question: [{ type: "txt", value: "Найдите остаток от деления -57 на 8." }],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "7" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 1,
  },
  {
    id: 25,
    question: [
      { type: "txt", value: "Расположите числа в порядке возрастания:" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img33.png" },
    ],
    options: [
      { type: "txt", value: "a<b<c" },
      { type: "txt", value: "b<a<c" },
      { type: "txt", value: "c<a<b" },
      { type: "txt", value: "b<c<a" },
    ],
    answer: 2,
  },
  {
    id: 26,
    question: [
      { type: "txt", value: "Если" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img34.png" },
      { type: "txt", value: "найдите" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img35.png" },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img36.png" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img37.png" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img38.png" },
    ],
    answer: 0,
  },
  {
    id: 27,
    question: [
      {
        type: "txt",
        value:
          "Если a чётное число, то какое из следующих чисел всегда нечётное?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img39.png" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img40.png" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img41.png" },
      { type: "txt", value: "ни одно из них" },
    ],
    answer: 1,
  },
  {
    id: 28,
    question: [{ type: "txt", value: "Вычислите: 42567∙42565-42568∙42564" }],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "-3" },
      { type: "txt", value: "-4" },
    ],
    answer: 0,
  },
  {
    id: 29,
    question: [
      { type: "txt", value: "Вычислите:" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img42.png" },
    ],
    options: [
      { type: "txt", value: "6" },
      { type: "txt", value: "2" },
      { type: "txt", value: "4" },
      { type: "txt", value: "3" },
    ],
    answer: 0,
  },
  {
    id: 30,
    question: [
      {
        type: "txt",
        value: "Если m-n=(4x-y) и n-m=(x-4y-30) найдите значение y-x.",
      },
    ],
    options: [
      { type: "txt", value: "-30" },
      { type: "txt", value: "-15" },
      { type: "txt", value: "-10" },
      { type: "txt", value: "-6" },
    ],
    answer: 3,
  },
  {
    id: 31,
    question: [
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img43.png" },
      { type: "txt", value: "докажите равенство." },
    ],
    options: [
      { type: "txt", value: "x∈R" },
      { type: "txt", value: "x∈R-{3}" },
      { type: "txt", value: "x∈R-{1}" },
      { type: "txt", value: "(0;∞)" },
    ],
    answer: 1,
  },
  {
    id: 32,
    question: [
      { type: "txt", value: "Решите систему уравнения" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img44.png" },
    ],
    options: [
      { type: "txt", value: "[-3; 5)" },
      { type: "txt", value: "(2; 4]" },
      { type: "txt", value: "[-6; 6)" },
      { type: "txt", value: "[6; ∞) " },
    ],
    answer: 2,
  },
  {
    id: 33,
    question: [
      {
        type: "txt",
        value:
          "Найдите разность между наибольшим и наименьшим решениями неравенства",
      },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img45.png" },
    ],
    options: [
      { type: "txt", value: "6" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "2" },
    ],
    answer: 1,
  },
  {
    id: 34,
    question: [
      {
        type: "txt",
        value: "Найдите коэффициент β из тождества.",
      },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img46.png" },
      {
        type: "txt",
        value: "найдите β.",
      },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "-1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "-2" },
    ],
    answer: 1,
  },

  {
    id: 35,
    question: [
      { type: "txt", value: "Вычислите:" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img47.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img48.png" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img49.png" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img50.png" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img51.png" },
    ],
    answer: 2,
  },
  {
    id: 36,
    question: [
      { type: "txt", value: "Если" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img85.png" },
      { type: "txt", value: "являются корнем уравнения" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img86.png" },
      {
        type: "txt",
        value: "и удовлетворяют условию",
      },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img53.png" },
      {
        type: "txt",
        value: "то найдите значение a.",
      },
    ],
    options: [
      { type: "txt", value: "-1" },
      { type: "txt", value: "-2" },
      { type: "txt", value: "-3" },
      { type: "txt", value: "3" },
    ],
    answer: 2,
  },
  {
    id: 37,
    question: [
      { type: "txt", value: "Если" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img54.png" },
      { type: "txt", value: "найдите" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img55.png" },
    ],
    options: [
      { type: "txt", value: "4-2x" },
      { type: "txt", value: "4-3x" },
      { type: "txt", value: "4-3x" },
      { type: "txt", value: "2x+4" },
    ],
    answer: 0,
  },
  {
    id: 38,
    question: [
      { type: "txt", value: "Если" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img56.png" },
      { type: "txt", value: "найдите х." },
    ],
    options: [
      { type: "txt", value: "181" },
      { type: "txt", value: "180" },
      { type: "txt", value: "179" },
      { type: "txt", value: "178" },
    ],
    answer: 2,
  },
  {
    id: 39,
    question: [
      {
        type: "txt",
        value: "|| x - 2 | -3 | = 4 сколько корней имеет уравнение?",
      },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 1,
  },
  {
    id: 40,
    question: [
      { type: "txt", value: "При каких значениях k уравнение" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img57.png" },
      { type: "txt", value: "имеет отрицательное решение?" },
    ],
    options: [
      { type: "txt", value: "k≤3" },
      { type: "txt", value: "k<3,k>5" },
      { type: "txt", value: "k> -5" },
      { type: "txt", value: "k<5" },
    ],
    answer: 1,
  },
  {
    id: 41,
    question: [{ type: "txt", value: "Решите неравенство |(x+3)(x+1)+1|≤0" }],
    options: [
      { type: "txt", value: "∅" },
      { type: "txt", value: "2" },
      { type: "txt", value: "-2" },
      { type: "txt", value: "0" },
    ],
    answer: 2,
  },
  {
    id: 42,
    question: [
      { type: "txt", value: "Вычислите" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img59.png" },
      { type: "txt", value: "для корней уравнения" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img58.png" },
    ],
    options: [
      { type: "txt", value: "21" },
      { type: "txt", value: "12" },
      { type: "txt", value: "17" },
      { type: "txt", value: "31" },
    ],
    answer: 3,
  },
  {
    id: 43,
    question: [
      {
        type: "txt",
        value: "Если A=1-2+3-4+…+25; B=4-5+6-7+…-29, найдите A+B?",
      },
    ],
    options: [
      { type: "txt", value: "-26" },
      { type: "txt", value: "-27" },
      { type: "txt", value: "0" },
      { type: "txt", value: "27" },
    ],
    answer: 2,
  },
  {
    id: 44,
    question: [
      {
        type: "txt",
        value: "Если 9,(9)+10,(9) +11,(9)+…+59,(9)=59,(9)x, найдите x?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img60.png" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img61.png" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img62.png" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img63.png" },
    ],
    answer: 2,
  },
  {
    id: 45,
    question: [
      {
        type: "txt",
        value: "Вычислите сумму цифр числа 323334…7374.",
      },
    ],
    options: [
      { type: "txt", value: "368" },
      { type: "txt", value: "391" },
      { type: "txt", value: "398" },
      { type: "txt", value: "405" },
    ],
    answer: 2,
  },
  {
    id: 46,
    question: [
      { type: "txt", value: "Вычислите:" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img64.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img65.png" },
      { type: "txt", value: "0" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img66.png" },
      { type: "txt", value: "1" },
    ],
    answer: 1,
  },
  {
    id: 47,
    question: [
      { type: "txt", value: "Найдите область определения функции" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img67.png" },
    ],
    options: [
      { type: "txt", value: "(-∞;-1]∪(0;1)∪(1;2]" },
      { type: "txt", value: "(-∞;-1)∪(0;1)∪(1;2)" },
      { type: "txt", value: "[-1;0)∪(0;1)∪(1;2]" },
      { type: "txt", value: "(-∞;-1]∪(0;2]" },
    ],
    answer: 0,
  },
  {
    id: 48,
    question: [
      { type: "txt", value: "Если a<0,b<0,c>0, упростите выражение" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img68.png" },
    ],
    options: [
      { type: "txt", value: "2a-2c" },
      { type: "txt", value: "a-2b+c" },
      { type: "txt", value: "-a" },
      { type: "txt", value: "a-2b" },
    ],
    answer: 0,
  },
  {
    id: 49,
    question: [
      {
        type: "txt",
        value: "Найдите наименьшее натуральное решение неравенство |x-4|≤|x+4|",
      },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "4" },
      { type: "txt", value: "3" },
      { type: "txt", value: "1" },
    ],
    answer: 3,
  },
  {
    id: 50,
    question: [
      {
        type: "txt",
        value: "Найдите сумму корней уравнения |x+1| = |2x-1|",
      },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "4" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4,2" },
    ],
    answer: 0,
  },
  {
    id: 51,
    question: [
      { type: "txt", value: "Если a∙b∙c=5, вычислите" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img69.png" },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "txt", value: "-1,2" },
      { type: "txt", value: "-0,6" },
      { type: "txt", value: "-1" },
    ],
    answer: 1,
  },
  {
    id: 52,
    question: [
      { type: "txt", value: "Решите неравенство." },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img70.png" },
    ],
    options: [
      { type: "txt", value: "(2; 4)" },
      { type: "txt", value: "(2; 5)" },
      { type: "txt", value: "(1; 6)" },
      { type: "txt", value: "(3; 4)" },
    ],
    answer: 1,
  },
  {
    id: 53,
    question: [{ type: "txt", value: "Решите неравенство ||x-1|-2|≥1" }],
    options: [
      { type: "txt", value: "(-∞;-3)" },
      { type: "txt", value: "(-∞;-2] ∪ [0;2] ∪ [4; ∞)" },
      { type: "txt", value: "(-2;10)" },
      { type: "txt", value: "(-2; ∞)" },
    ],
    answer: 1,
  },
  {
    id: 54,
    question: [
      { type: "txt", value: "Если" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img71.png" },
      { type: "txt", value: "то чему равно  x-y=?" },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "5" },
      { type: "txt", value: "7" },
    ],
    answer: 1,
  },
  {
    id: 55,
    question: [
      { type: "txt", value: "Вычислите:" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img72.png" },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img73.png" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img74.png" },
    ],
    answer: 1,
  },
  {
    id: 56,
    question: [
      {
        type: "txt",
        value: "Найдите сумму целых решений, удовлетворяющих неравенству ",
      },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img75.png" },
    ],
    options: [
      { type: "txt", value: "-3" },
      { type: "txt", value: "0" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" }, // warning!
    ],
    answer: 3,
  },
  {
    id: 57,
    question: [
      { type: "txt", value: "Если" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img77.png" },
      { type: "txt", value: "для корней уравнения" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img76.png" },
      { type: "txt", value: "то чему равно n?" },
    ],
    options: [
      { type: "txt", value: "(0;4)" },
      { type: "txt", value: "(-4;4)" },
      { type: "txt", value: "(-4;0)" },
      { type: "txt", value: "R-(-4;4)" },
    ],
    answer: 1,
  },
  {
    id: 58,
    question: [
      { type: "txt", value: "Найдите сумму корней уравнения" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img78.png" },
    ],
    options: [
      { type: "txt", value: "4" },
      { type: "txt", value: "6" },
      { type: "txt", value: "9" },
      { type: "txt", value: "11" },
    ],
    answer: 0,
  },
  {
    id: 59,
    question: [
      { type: "txt", value: "Сколько целых решений имеет неравенства" },
      { type: "img", value: "/quiz-images/olimpiada/11-sinf/img79.png" },
    ],
    options: [
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
      { type: "txt", value: "7" }, // warning!
      { type: "txt", value: "9" },
    ],
    answer: 2,
  },
];

const GRE = [
  {
    id: 0,
    question: [
      { type: "txt", value: "Let C denote an arbitrary constant. Then" },
      { type: "img", value: "/quiz-images-2/GRE/img1.png" },
      { type: "txt", value: "dx =" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GRE/img2.png" },
      { type: "img", value: "/quiz-images-2/GRE/img3.png" },
      { type: "img", value: "/quiz-images-2/GRE/img4.png" },
      { type: "img", value: "/quiz-images-2/GRE/img5.png" },
      { type: "img", value: "/quiz-images-2/GRE/img6.png" },
    ],
    answer: 0,
  },
  {
    id: 1,
    question: [{ type: "txt", value: "/quiz-images-2/GRE/img7.png" }],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "6" },
      { type: "txt", value: "8" },
    ],
    answer: 4,
  },
  {
    id: 2,
    question: [
      {
        type: "txt",
        value:
          "Let r and A be the radius and the area, respectively, of a circle. If r increases by 40 percent will A increase?",
      },
    ],
    options: [
      { type: "txt", value: "40%" },
      { type: "txt", value: "49%" },
      { type: "txt", value: "80%" },
      { type: "txt", value: "96%" },
      { type: "txt", value: "130%" },
    ],
    answer: 3,
  },
  {
    id: 3,
    question: [
      {
        type: "txt",
        value: "If differentiable function y(x) satisfies the equation",
      },
      { type: "img", value: "/quiz-images-2/GRE/img11.png" },
      { type: "txt", value: "for" },
      { type: "img", value: "/quiz-images-2/GRE/img12.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GRE/img13.png" },
      { type: "img", value: "/quiz-images-2/GRE/img14.png" },
      { type: "img", value: "/quiz-images-2/GRE/img15.png" },
      { type: "img", value: "/quiz-images-2/GRE/img16.png" },
      { type: "img", value: "/quiz-images-2/GRE/img17.png" },
    ],
    answer: 1,
  },
  {
    id: 4,
    question: [
      {
        type: "txt",
        value:
          "Let g be a differentiable function on R, and let h be function defined by h(x)=",
      },
      { type: "img", value: "/quiz-images-2/GRE/img18.png" },
      {
        type: "txt",
        value:
          "for all x ∈ R. Which of the following is equal to h’(x) for all x ∈ R?",
      },
    ],
    options: [
      { type: "txt", value: "g(x²)" },
      { type: "txt", value: "2xg`(x²)" },
      { type: "txt", value: "2 xg(x²)" },
      { type: "txt", value: "g`(x²)-g`(0)" },
      { type: "txt", value: "2xg(x²)-g(0)" },
    ],
    answer: 2,
  },
  {
    id: 5,
    question: [
      {
        type: "txt",
        value:
          "Let ⨍(⨍(x))=x for all real numbers x in the domain of ⨍. Which of the following could be the grap of ⨍ in the xy-plane?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GRE/img19.png" },
      { type: "img", value: "/quiz-images-2/GRE/img20.png" },
      { type: "img", value: "/quiz-images-2/GRE/img21.png" },
      { type: "img", value: "/quiz-images-2/GRE/img22.png" },
      { type: "img", value: "/quiz-images-2/GRE/img23.png" },
    ],
    answer: 3,
  },
  {
    id: 6,
    question: [
      { type: "txt", value: "If a and b are positive numbers, then" },
      { type: "img", value: "/quiz-images-2/GRE/img24.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GRE/img25.png" },
      { type: "img", value: "/quiz-images-2/GRE/img26.png" },
      { type: "img", value: "/quiz-images-2/GRE/img27.png" },
      { type: "txt", value: "1" },
      { type: "txt", value: "∞" },
    ],
    answer: 1,
  },
  {
    id: 7,
    question: [
      { type: "txt", value: "If the function" },
      { type: "img", value: "/quiz-images-2/GRE/img28.png" },
      {
        type: "txt",
        value:
          "for all positive numbers x except x=1, and if ⨍(1) = a, for what value of a is ⨍ continuous x=1 ?",
      },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "img", value: "/quiz-images-2/GRE/img29.png" },
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "There is no such vaule." },
    ],
    answer: 1,
  },
  {
    id: 8,
    question: [
      {
        type: "txt",
        value:
          "Of the 10 lightbulbs in a box, 3 are defective. If 12 lightbuls are to be chosen from the box at random and without replacement, what is the probability that at least 1 of the 2 lightbubls will be defective?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GRE/img30.png" },
      { type: "img", value: "/quiz-images-2/GRE/img31.png" },
      { type: "img", value: "/quiz-images-2/GRE/img32.png" },
      { type: "img", value: "/quiz-images-2/GRE/img33.png" },
      { type: "img", value: "/quiz-images-2/GRE/img34.png" },
    ],
    answer: 2,
  },
  {
    id: 9,
    question: [
      { type: "txt", value: "In the power series expansion" },
      { type: "img", value: "/quiz-images-2/GRE/img35.png" },
      { type: "txt", value: "where x<1, what are the coefficients" },
      { type: "img", value: "/quiz-images-2/GRE/img36.png" },
      { type: "txt", value: "respectively?" },
    ],
    options: [
      { type: "txt", value: "1, 1 and 1" },
      { type: "txt", value: "1, 2 and 3" },
      { type: "txt", value: "1, 2 and 6" },
      { type: "txt", value: "2, 4 and 6" },
      { type: "img", value: "/quiz-images-2/GRE/img37.png" },
    ],
    answer: 1,
  },
  {
    id: 10,
    question: [
      {
        type: "txt",
        value:
          "Let ⨍ : [-3, ∞)→[-8, ∞) be defined by ⨍ (x) =x²+6x+1.   Which of the following statements is true?",
      },
    ],
    options: [
      { type: "txt", value: "⨍ is not one-to-one (that is, not injective)." },
      { type: "txt", value: "⨍ is not onto (that is, not surjective)." },
      { type: "img", value: "/quiz-images-2/GRE/img38.png" },
      { type: "img", value: "/quiz-images-2/GRE/img39.png" },
      { type: "img", value: "/quiz-images-2/GRE/img40.png" },
    ],
    answer: 4,
  },
  {
    id: 11,
    question: [
      {
        type: "txt",
        value:
          "Let p and q be prime numbers, where 2 < p < q, and let M be the set of all positive integers n such that n⁵ is divisible by p³ and by",
      },
      { type: "img", value: "/quiz-images-2/GRE/img41.png" },
      { type: "txt", value: "What is the least integer in M?" },
    ],
    options: [
      { type: "txt", value: "2pq" },
      { type: "txt", value: "4p³q" },
      { type: "txt", value: "4pq³" },
      { type: "txt", value: "4p³q³" },
      { type: "img", value: "/quiz-images-2/GRE/img42.png" },
    ],
    answer: 2,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value:
          "Let G be a graph such every two distinct vertices in G are connected by exactly one edge and every edge connects two distinct vertices. If G has 190 edges, how many vertices does G have?",
      },
    ],
    options: [
      { type: "txt", value: "18" },
      { type: "txt", value: "19" },
      { type: "txt", value: "20" },
      { type: "txt", value: "94" },
      { type: "txt", value: "95" },
    ],
    answer: 2,
  },
  {
    id: 13,
    question: [
      {
        type: "txt",
        value:
          "Suppose ⨍ is a real-valued differentiable function defined on the interval (0,2) such that  ⨍`(x)=x²-x+1 on (0,2). Which of the following statements must be true?",
      },
    ],
    options: [
      { type: "txt", value: "If 0 < x < 2, then ⨍ (x) =⨍(2-x)" },
      { type: "txt", value: "If 0 < x < 2, then ⨍ (x) =-⨍(2-x)" },
      { type: "txt", value: "If 0 < x < y < 2, then ⨍ (x) < ⨍(y)" },
      { type: "txt", value: "The graph of ⨍is concave upward on (0,2)" },
      { type: "txt", value: "The graph of ⨍is concave downward on (0,2)" },
    ],
    answer: 2,
  },
  {
    id: 14,
    question: [
      {
        type: "txt",
        value:
          "If a and b are positive numbers, which of the following is a necessary and sufficient condition for the system of equations  x²+y²=a  xy=b        to have at least one solution (x, y), where x and y are real?",
      },
    ],
    options: [
      { type: "txt", value: "a≥2b" },
      { type: "txt", value: "2a≤b" },
      { type: "txt", value: "a≥b²" },
      { type: "txt", value: "a≤b²" },
      { type: "txt", value: "a≤2b²" },
    ],
    answer: 0,
  },
  {
    id: 15,
    question: [
      {
        type: "txt",
        value:
          "Suppose that X and  Y are sets, ⨍ : X→Y is a function, A and B are subsets of X, and C is a subset of B. Which of the following must be true?",
      },
      { type: "img", value: "/quiz-images-2/GRE/img43.png" },
    ],
    options: [
      { type: "txt", value: "None" },
      { type: "txt", value: "III only" },
      { type: "txt", value: "I and II only" },
      { type: "txt", value: "I and III only" },
      { type: "txt", value: "I, II, and III" },
    ],
    answer: 3,
  },
  {
    id: 16,
    question: [
      {
        type: "txt",
        value: "Which of the following statements about sequences",
      },
      { type: "img", value: "/quiz-images-2/GRE/img44.png" },
      { type: "txt", value: "of real numbers is FALSE?" },
    ],
    options: [
      { type: "txt", value: "Every unbounded sequence is divergent." },
      { type: "txt", value: "Every bounded sequence is convergent." },
      { type: "img", value: "/quiz-images-2/GRE/img45.png" },
      { type: "img", value: "/quiz-images-2/GRE/img46.png" },
      { type: "img", value: "/quiz-images-2/GRE/img47.png" },
    ],
    answer: 1,
  },
  {
    id: 17,
    question: [
      { type: "img", value: "/quiz-images-2/GRE/img48.png" },
      {
        type: "txt",
        value:
          "The graph of the derivative g` of a function g with domain 0,7 is shown above. Which of the following must be true about g?  I. g has a local maximum value at x=0 and a local minimum value at x=4  II. g has a local maximum value at x=2  and a local minimum value at x=5   III. g(2) = g(5)",
      },
    ],
    options: [
      { type: "txt", value: "I only" },
      { type: "txt", value: "II only" },
      { type: "txt", value: "III only" },
      { type: "txt", value: "I and II" },
      { type: "txt", value: "II and III" },
    ],
    answer: 1,
  },
  {
    id: 18,
    question: [
      { type: "txt", value: "In the xy-plane, what is the point on the curve" },
      { type: "img", value: "/quiz-images-2/GRE/img49.png" },
      { type: "txt", value: "that is closest to the origin?" },
    ],
    options: [
      { type: "txt", value: "(-3, 0)" },
      { type: "img", value: "/quiz-images-2/GRE/img50.png" },
      { type: "img", value: "/quiz-images-2/GRE/img51.png" },
      { type: "img", value: "/quiz-images-2/GRE/img52.png" },
      { type: "img", value: "/quiz-images-2/GRE/img53.png" },
    ],
    answer: 2,
  },
  {
    id: 19,
    question: [
      {
        type: "txt",
        value:
          "If ⨍ and g are twice-differentiable functions of a real variable, then the second derivative of the composition ⨍ ° g is given by which of the following?",
      },
    ],
    options: [
      { type: "txt", value: "⨍`` ° g``" },
      { type: "txt", value: "(⨍`` ° g) (g`)²" },
      { type: "txt", value: "(⨍`` ° g)+(2⨍`° g`)+(⨍ ° g``)" },
      { type: "txt", value: "(⨍`` ° g)(g`)²+(⨍` ° g)g``" },
      { type: "txt", value: "(⨍`` ° g)g`+(⨍` ° g`)(g`)²" },
    ],
    answer: 3,
  },
  {
    id: 20,
    question: [
      {
        type: "txt",
        value:
          "If x and y are integers such that 3x+2y≡5 (mod 13) and x+7y≡1(mod 13), then 5x+3y is congruent modulo 13 to",
      },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "5" },
      { type: "txt", value: "7" },
      { type: "txt", value: "10" },
      { type: "txt", value: "11" },
    ],
    answer: 2,
  },
  {
    id: 21,
    question: [
      {
        type: "txt",
        value:
          "Consider triangle ABC with sides AB =6 ,   AC = 8,  BC =12. Let P be a point on side AB such that AP = 4, and let Q be a point on side AC such that angles APQ and ACB are congruent. What is the length of line segment PQ?",
      },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
      { type: "txt", value: "8" },
    ],
    answer: 3,
  },
  {
    id: 22,
    question: [
      { type: "img", value: "/quiz-images-2/GRE/img54.png" },
      {
        type: "txt",
        value: "In the system of equations in the real variables",
      },
      { type: "img", value: "/quiz-images-2/GRE/img55.png" },
      {
        type: "txt",
        value:
          "above, a,b, and c are real constans. What is the set of all (a,b,c)R³  for which the system is consistent?",
      },
    ],
    options: [
      { type: "txt", value: "{(a,b,c):a=b=c}" },
      { type: "txt", value: "{(a,b,c):a+b+=-1c}" },
      { type: "txt", value: "{(a,b,c):a+b+c=0}" },
      { type: "txt", value: "{(a,b,c):a+b+c=1}" },
      { type: "txt", value: "R³" },
    ],
    answer: 4,
  },
  {
    id: 23,
    question: [
      {
        type: "txt",
        value:
          "Which of the following sets of vectors is a spanning set for the null space of the real matrix",
      },
      { type: "img", value: "/quiz-images-2/GRE/img56.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GRE/img57.png" },
      { type: "img", value: "/quiz-images-2/GRE/img58.png" },
      { type: "img", value: "/quiz-images-2/GRE/img59.png" },
      { type: "img", value: "/quiz-images-2/GRE/img60.png" },
      { type: "img", value: "/quiz-images-2/GRE/img61.png" },
    ],
    answer: 1,
  },
  {
    id: 24,
    question: [
      { type: "img", value: "/quiz-images-2/GRE/img62.png" },
      {
        type: "txt",
        value:
          "The graph of the function ⨍ (x) = A cos ( ωx-∅) is shown in the xy-plane above, where A, , and ∅ are nonnegative constants. Which of the following could be the value of ∅?",
      },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "img", value: "/quiz-images-2/GRE/img63.png" },
      { type: "img", value: "/quiz-images-2/GRE/img64.png" },
      { type: "img", value: "/quiz-images-2/GRE/img65.png" },
      { type: "img", value: "/quiz-images-2/GRE/img66.png" },
    ],
    answer: 3,
  },
  {
    id: 25,
    question: [
      { type: "txt", value: "If ⨍ is the function defined by ⨍ (x) = max" },
      { type: "img", value: "/quiz-images-2/GRE/img67.png" },
      { type: "txt", value: "for  -2 x  2, what is the value of " },
      { type: "img", value: "/quiz-images-2/GRE/img68.png" },
    ],
    options: [
      { type: "txt", value: "2+π" },
      { type: "txt", value: "4+π" },
      { type: "txt", value: "6+π" },
      { type: "txt", value: "2+2π" },
      { type: "txt", value: "6+2π" },
    ],
    answer: 2,
  },
  {
    id: 26,
    question: [
      {
        type: "txt",
        value:
          "Let P, Q, and R be logical propositions. Consider the statement ``If P is true, then Q is true and R is true.``   Which of the following is the negation of the statement?",
      },
    ],
    options: [
      { type: "txt", value: "P is true, and Q is false or R is false." },
      { type: "txt", value: "P is false or Q is true or R is true." },
      { type: "txt", value: "P is false or Q is false or R is false." },
      { type: "txt", value: "If Q is true and R is true, then P is true." },
      { type: "txt", value: "If P is false, then Q is false or R is false." },
    ],
    answer: 0,
  },
  {
    id: 27,
    question: [
      { type: "txt", value: "The value of the omproper integral" },
      { type: "img", value: "/quiz-images-2/GRE/img69.png" },
      { type: "txt", value: "dx is √π. What is the value of" },
      { type: "img", value: "/quiz-images-2/GRE/img70.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GRE/img71.png" },
      { type: "img", value: "/quiz-images-2/GRE/img72.png" },
      { type: "txt", value: "1" },
      { type: "img", value: "/quiz-images-2/GRE/img73.png" },
      { type: "img", value: "/quiz-images-2/GRE/img74.png" },
    ],
    answer: 1,
  },
  {
    id: 28,
    question: [
      {
        type: "txt",
        value:
          "For all real values of x, let  ⨍ be the function defined by ⨍(x) =",
      },
      { type: "img", value: "/quiz-images-2/GRE/img75.png" },
      {
        type: "txt",
        value: "On which of the following intervals is ⨍ increasing?",
      },
    ],
    options: [
      { type: "txt", value: "(-π,0)" },
      { type: "img", value: "/quiz-images-2/GRE/img76.png" },
      { type: "txt", value: "(0,-π)" },
      { type: "img", value: "/quiz-images-2/GRE/img77.png" },
      { type: "img", value: "/quiz-images-2/GRE/img78.png" },
    ],
    answer: 1,
  },
  {
    id: 29,
    question: [
      {
        type: "txt",
        value:
          "Which of the following best represents the graph of the curve c : 0,2π → R²  given by c(t) = (cos³t ,sin³t) in the xy-plane?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GRE/img79.png" },
      { type: "img", value: "/quiz-images-2/GRE/img80.png" },
      { type: "img", value: "/quiz-images-2/GRE/img81.png" },
      { type: "img", value: "/quiz-images-2/GRE/img82.png" },
      { type: "img", value: "/quiz-images-2/GRE/img83.png" },
    ],
    answer: 2,
  },
  {
    id: 30,
    question: [
      {
        type: "txt",
        value:
          "Four cubes, each with faces numbered 1 to 6, are to be rolled. When each cube is rolled, each number is equally likely to appear on its top face. What is the probability that at least two of the cubes will have the same number appear on their top faces?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GRE/img84.png" },
      { type: "img", value: "/quiz-images-2/GRE/img85.png" },
      { type: "img", value: "/quiz-images-2/GRE/img86.png" },
      { type: "img", value: "/quiz-images-2/GRE/img87.png" },
      { type: "img", value: "/quiz-images-2/GRE/img88.png" },
    ],
    answer: 1,
  },
  {
    id: 31,
    question: [
      {
        type: "txt",
        value:
          "Which of the following sets of complex numbers is NOT a group under multiplication?",
      },
    ],
    options: [
      { type: "txt", value: "{a+b: a  and  b  are positive rational numbers}" },
      {
        type: "txt",
        value: "{a+bi:  a and  b are real numbers such that a²+b²≠0}",
      },
      {
        type: "txt",
        value: "{a+bi:  a  and  b   are integers such that a²+b²=1}",
      },
      {
        type: "txt",
        value: "{a+bi:  a  and  b  are rational numbers such that a²+b²=1}",
      },
      {
        type: "txt",
        value: "{a+bi:  a  and  b  are real numbers such that a²+b²=1}",
      },
    ],
    answer: 0,
  },
  {
    id: 32,
    question: [
      {
        type: "txt",
        value:
          "A spherical tank of radius 5 meters contains water that is slowly draining out. At the instant that measurements are taken, the maximum depth of the water in the tank is 2 meters, and the depth is decreasing by",
      },
      { type: "img", value: "/quiz-images-2/GRE/img89.png" },
      {
        type: "txt",
        value:
          "meter per second. What is the rate of decrease of the volume of the water, in cubic meters per second, at that instant?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GRE/img90.png" },
      { type: "img", value: "/quiz-images-2/GRE/img91.png" },
      { type: "txt", value: "22" },
      { type: "img", value: "/quiz-images-2/GRE/img92.png" },
      { type: "img", value: "/quiz-images-2/GRE/img93.png" },
    ],
    answer: 0,
  },
  {
    id: 33,
    question: [
      { type: "txt", value: "Let ⨍ be the function defined by" },
      { type: "img", value: "/quiz-images-2/GRE/img94.png" },
      { type: "txt", value: "for all real numbers x except 0 and 1. Let" },
      { type: "img", value: "/quiz-images-2/GRE/img95.png" },
      { type: "txt", value: "denote the composition⨍ ° ⨍, and let" },
      { type: "img", value: "/quiz-images-2/GRE/img97.png" },
      { type: "txt", value: "denote the composition" },
      { type: "img", value: "/quiz-images-2/GRE/img98.png" },
      { type: "txt", value: "for all integers n3. Then" },
      { type: "img", value: "/quiz-images-2/GRE/img99.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GRE/img100.png" },
      { type: "img", value: "/quiz-images-2/GRE/img101.png" },
      { type: "txt", value: "x" },
      { type: "img", value: "/quiz-images-2/GRE/img102.png" },
      { type: "img", value: "/quiz-images-2/GRE/img103.png" },
    ],
    answer: 0,
  },
  {
    id: 34,
    question: [
      { type: "txt", value: "Consider the sequence" },
      { type: "img", value: "/quiz-images-2/GRE/img104.png" },
      { type: "txt", value: "for all n1. What is" },
      { type: "img", value: "/quiz-images-2/GRE/img105.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GRE/img106.png" },
      { type: "img", value: "/quiz-images-2/GRE/img107.png" },
      { type: "img", value: "/quiz-images-2/GRE/img108.png" },
      { type: "txt", value: "long 2" },
      { type: "txt", value: "2 long 2" },
    ],
    answer: 1,
  },
  {
    id: 35,
    question: [
      {
        type: "txt",
        value:
          "Let S be a bounded  subset of the real line that is connected and has more that one point. Let ⨍ be a continuous real-valued function defined on S. Which of the following statements must be true?    I. S is a closed interval  II. The set ⨍ (S) has a maximum value  III. The set  ⨍ (S) has a least upper bound",
      },
    ],
    options: [
      { type: "txt", value: "None" },
      { type: "txt", value: "III only" },
      { type: "txt", value: "I and II only" },
      { type: "txt", value: "II and III only" },
      { type: "txt", value: "I, II,  and III" },
    ],
    answer: 0,
  },
  {
    id: 36,
    question: [
      { type: "txt", value: "What is the maximum value of the function on " },
      { type: "img", value: "/quiz-images-2/GRE/img109.png" },
      {
        type: "txt",
        value:
          "defiend by ⨍ (x,y,z) = x-3y +2z   subject to the constraint x²+y²+z²=9?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GRE/img110.png" },
      { type: "img", value: "/quiz-images-2/GRE/img111.png" },
      { type: "img", value: "/quiz-images-2/GRE/img112.png" },
      { type: "img", value: "/quiz-images-2/GRE/img113.png" },
      { type: "img", value: "/quiz-images-2/GRE/img114.png" },
    ],
    answer: 2,
  },
  {
    id: 37,
    question: [
      {
        type: "txt",
        value:
          "Let A and B be linear transformations from R¹² to R¹²  such that the null space of A has dimension 3 and the null space of B has dimension 5. If d is the dimension of the null space of the null space of the linear transformation given by the composition A ° B, which of the following indicates all of the possible values of the integer d?",
      },
    ],
    options: [
      { type: "txt", value: "2≤d≤8 " },
      { type: "txt", value: "3≤d≤5" },
      { type: "txt", value: "3≤d≤8" },
      { type: "txt", value: "5≤d≤8" },
      { type: "txt", value: "5≤d≤10" },
    ],
    answer: 3,
  },
  {
    id: 38,
    question: [
      { type: "img", value: "/quiz-images-2/GRE/img115.png" },
      {
        type: "txt",
        value:
          "In the matrix shown above, x∈𝕔. For how many distinct values of x is the matrix noninvertible?",
      },
    ],
    options: [
      { type: "txt", value: "None" },
      { type: "txt", value: "One" },
      { type: "txt", value: "Two" },
      { type: "txt", value: "Three" },
      { type: "txt", value: "Four" },
    ],
    answer: 4,
  },
  {
    id: 39,
    question: [
      {
        type: "txt",
        value:
          "Consider 10 lines in the plane such that no two of the lines are parallel and no three of the lines have a common point.",
      },
    ],
    options: [
      { type: "txt", value: "36" },
      { type: "txt", value: "45" },
      { type: "txt", value: "46" },
      { type: "txt", value: "55" },
      { type: "txt", value: "56" },
    ],
    answer: 4,
  },
  {
    id: 40,
    question: [
      {
        type: "txt",
        value:
          "Which of the following statements  is true for every 3x3 matrix M whit real entries?",
      },
    ],
    options: [
      { type: "txt", value: "M has 3 linearly independent eigenvectors." },
      { type: "txt", value: "M has at most one complex eigenvalue." },
      { type: "txt", value: "M has at least one real eigenvalue." },
      {
        type: "txt",
        value: "If M is invertible, then M has 3 distinct eigenvalues.",
      },
      {
        type: "txt",
        value:
          "If M has 2 orthogonal eigenvectors, then M has at least 2 distinct eigenvalues.",
      },
    ],
    answer: 2,
  },
  {
    id: 41,
    question: [
      {
        type: "txt",
        value:
          "What is the area the triangle in R³ with vertices (1,3,2), (3,1,2), and (-2,0,4)?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GRE/img116.png" },
      { type: "img", value: "/quiz-images-2/GRE/img117.png" },
      { type: "img", value: "/quiz-images-2/GRE/img118.png" },
      { type: "img", value: "/quiz-images-2/GRE/img119.png" },
      { type: "img", value: "/quiz-images-2/GRE/img120.png" },
    ],
    answer: 0,
  },
  {
    id: 42,
    question: [
      {
        type: "txt",
        value:
          "The relation R is defined on ℝ as following. For x, y∈R, xRy if (x-y)(xy+2)=0. Which of the following statements are true?    I. xRx for all x∈R   II.If x, y∈R and xRy, then yRx   III. If x, y, z∈R and xRy and yRz, then xRz",
      },
    ],
    options: [
      { type: "txt", value: "I only" },
      { type: "txt", value: "I and II only" },
      { type: "txt", value: "I and III only" },
      { type: "txt", value: "II and III only" },
      { type: "txt", value: "I, II, and III" },
    ],
    answer: 4,
  },
  {
    id: 43,
    question: [
      {
        type: "txt",
        value:
          "Two cell phone towers, A and B, are in a flat region 10 miles apart. If a new tower S is to be located in the region such that distance between S and A is 2 miles greater than the distance between S and B, then the locus of possible points for S is best described by",
      },
    ],
    options: [
      { type: "txt", value: "a branch of a hyperbola" },
      { type: "txt", value: "a circle" },
      { type: "txt", value: "an ellipse (noncircular)" },
      { type: "txt", value: "a line" },
      { type: "txt", value: "a parabola" },
    ],
    answer: 0,
  },
  {
    id: 44,
    question: [
      {
        type: "txt",
        value:
          "Let u(x,y) and v(x,y) be real-valued differentiable functions that are implicitly defined by the equations x=⨍(u,v) and y=g(u,v), where ⨍ and g are real-valued differentiable functions. Which of the following is an expression for",
      },
      { type: "img", value: "/quiz-images-2/GRE/img121.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GRE/img122.png" },
      { type: "img", value: "/quiz-images-2/GRE/img123.png" },
      { type: "img", value: "/quiz-images-2/GRE/img124.png" },
      { type: "img", value: "/quiz-images-2/GRE/img125.png" },
      { type: "img", value: "/quiz-images-2/GRE/img126.png" },
    ],
    answer: 4,
  },
  {
    id: 45,
    question: [
      {
        type: "txt",
        value:
          "Which of the following represents the general real solution y(t) of the differential equation y’’ + 2 y’ +3 y=t, where",
      },
      { type: "img", value: "/quiz-images-2/GRE/img127.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GRE/img128.png" },
      { type: "img", value: "/quiz-images-2/GRE/img129.png" },
      { type: "img", value: "/quiz-images-2/GRE/img130.png" },
      { type: "img", value: "/quiz-images-2/GRE/img131.png" },
      { type: "img", value: "/quiz-images-2/GRE/img132.png" },
    ],
    answer: 4,
  },
  {
    id: 46,
    question: [
      { type: "txt", value: "What is the value of the line integral" },
      { type: "img", value: "/quiz-images-2/GRE/img133.png" },
      {
        type: "txt",
        value:
          "where C is a straight path in the xy-plane from the point (2, 0) to the point (0, 3)?",
      },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "txt", value: "10" },
      { type: "txt", value: "26" },
      { type: "txt", value: "41" },
      { type: "txt", value: "46" },
    ],
    answer: 2,
  },
  {
    id: 47,
    question: [
      {
        type: "txt",
        value:
          "Let ⨍ be a real-valued function defined for all (x, y) ϵ R², and consider the graph of z=  ⨍ (x, y) in xyz – space. Which of the following statements are true?   I. If all the level curves of ⨍ are parallel lines, then the graph is a plane.  II. if",
      },
      { type: "img", value: "/quiz-images-2/GRE/img134.png" },
      {
        type: "txt",
        value:
          "exist for all (x, y) R² and both are constant, the graph is a plane.  III.  if ",
      },
      { type: "img", value: "/quiz-images-2/GRE/img135.png" },
      {
        type: "txt",
        value:
          "exist for  all (x, y) R² and both are identically zero, then the graph is a plane.",
      },
    ],
    options: [
      { type: "txt", value: "I only" },
      { type: "txt", value: "II only" },
      { type: "txt", value: "III only" },
      { type: "txt", value: "I, II, and III" },
    ],
    answer: 1,
  },
  {
    id: 48,
    question: [
      { type: "txt", value: "In the complex plane, let C be the circle" },
      { type: "img", value: "/quiz-images-2/GRE/img136.png" },
      { type: "txt", value: "oriented counterclockwise. What is the value of" },
      { type: "img", value: "/quiz-images-2/GRE/img137.png" },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "txt", value: "cos 1" },
      { type: "txt", value: "sin 1" },
      { type: "txt", value: "sin²1" },
      { type: "txt", value: "sin 2" },
    ],
    answer: 4,
  },
  {
    id: 49,
    question: [{ type: "img", value: "/quiz-images-2/GRE/img138.png" }],
    options: [
      { type: "img", value: "/quiz-images-2/GRE/img139.png" },
      { type: "txt", value: "e – 1" },
      { type: "img", value: "/quiz-images-2/GRE/img140.png" },
      { type: "txt", value: "2 (e²-1) " },
      { type: "img", value: "/quiz-images-2/GRE/img141.png" },
    ],
    answer: 4,
  },
  {
    id: 50,
    question: [
      {
        type: "txt",
        value:
          "The flowchart above prints out a sequence of integers. Which of the following is a term in the sequence?",
      },
    ],
    options: [
      { type: "txt", value: "32" },
      { type: "txt", value: "59" },
      { type: "txt", value: "81" },
      { type: "txt", value: "360" },
      { type: "txt", value: "1,000" },
    ],
    answer: 2,
  },
  {
    id: 51,
    question: [
      {
        type: "txt",
        value:
          "Let G be the group of permutations of 4 objects. What is yhe total number of conjugacy classes of elements of G?",
      },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
    ],
    answer: 4,
  },
  {
    id: 52,
    question: [
      { type: "img", value: "/quiz-images-2/GRE/img142.png" },
      {
        type: "txt",
        value: "be vectors in R², and consider the following six dot products.",
      },
      { type: "img", value: "/quiz-images-2/GRE/img143.png" },
      {
        type: "txt",
        value:
          "Which of the following statements about the six dot products could be true?   I. All six are negative   II. Two are negative, and four are equal to 0  III. Two are positive , and four are equal to 0",
      },
    ],
    options: [
      { type: "txt", value: "II only" },
      { type: "txt", value: "I and II only" },
      { type: "txt", value: "I and III only" },
      { type: "txt", value: "II and III only" },
      { type: "txt", value: "I, II, and III" },
    ],
    answer: 3,
  },
  {
    id: 53,
    question: [
      {
        type: "txt",
        value: "Let y(t) be the solution to the differential equation",
      },
      { type: "img", value: "/quiz-images-2/GRE/img144.png" },
      { type: "txt", value: "sin t such that y(0) = 0. Then y()=" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GRE/img145.png" },
      { type: "img", value: "/quiz-images-2/GRE/img146.png" },
      { type: "txt", value: "0" },
      { type: "img", value: "/quiz-images-2/GRE/img147.png" },
      { type: "img", value: "/quiz-images-2/GRE/img148.png" },
    ],
    answer: 4,
  },
  {
    id: 54,
    question: [
      {
        type: "txt",
        value:
          "Let X be a continuous random variable. The standard deviation of the samping distribution of the sample mean for random samples of 30 observations of X is equal to 8. What is the standard deviation of the sampling distribution of the sample mean for random samples of 120 observations of X?",
      },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "4" },
      { type: "txt", value: "8" },
      { type: "txt", value: "12" },
      { type: "txt", value: "32" },
    ],
    answer: 1,
  },
  {
    id: 55,
    question: [
      {
        type: "txt",
        value:
          "Let R be a ring with identity such that a²=a for all aR. Which of the following statements must be true?    I. a+a=0 for all aR  II. If b∈R, then",
      },
      { type: "img", value: "/quiz-images-2/GRE/img149.png" },
      {
        type: "txt",
        value: "for some positive integer n.    III. Ab=ba  for all  a,b∈R.",
      },
    ],
    options: [
      { type: "txt", value: "None" },
      { type: "txt", value: "I only" },
      { type: "txt", value: "II only" },
      { type: "txt", value: "I and II" },
      { type: "txt", value: "I and III" },
    ],
    answer: 4,
  },
  {
    id: 56,
    question: [
      { type: "txt", value: "For each integer n1, define the function" },
      { type: "img", value: "/quiz-images-2/GRE/img150.png" },
      {
        type: "txt",
        value:
          "dt  for all x>1. Which of the following must be true for each n3  and for all x>1?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GRE/img151.png" },
      { type: "img", value: "/quiz-images-2/GRE/img152.png" },
      { type: "img", value: "/quiz-images-2/GRE/img153.png" },
      { type: "img", value: "/quiz-images-2/GRE/img154.png" },
      { type: "img", value: "/quiz-images-2/GRE/img155.png" },
    ],
    answer: 1,
  },
  {
    id: 57,
    question: [
      {
        type: "txt",
        value:
          "A total of 25 identical rental trucks are to be distributed among 5 different cities. Each city can receive any number of trucks from 0 to 25, as long as the total number of trucks received is 25. For which of the following subsets of the set of all possible distributions is the number of distributions equal to ",
      },
      { type: "img", value: "/quiz-images-2/GRE/img156.png" },
    ],
    options: [
      {
        type: "txt",
        value: "The disributions for which at least 1 city receives 0 trucks",
      },
      {
        type: "txt",
        value: "The disributions for which each city receives at least 1 truck",
      },
      {
        type: "txt",
        value: "The disributions for which each city receives at least 4 truck",
      },
      {
        type: "txt",
        value:
          "The disributions for which 4 or 5 cities receive the same number of trucks",
      },
      {
        type: "txt",
        value:
          "The disributions for which 4 cities receives at least 1 truck each and the other city receives 0 trucks",
      },
    ],
    answer: 0,
  },
  {
    id: 58,
    question: [
      { type: "img", value: "/quiz-images-2/GRE/img157.png" },
      { type: "txt", value: "be the ring of integers modulo 30, and let" },
      { type: "img", value: "/quiz-images-2/GRE/img158.png" },
      { type: "txt", value: "be the group of all invertible elements in" },
      { type: "img", value: "/quiz-images-2/GRE/img159.png" },
      {
        type: "txt",
        value: "under multiplication. Let ∅ be a group homomorphism from",
      },
      { type: "img", value: "/quiz-images-2/GRE/img158.png" },
      { type: "txt", value: "to" },
      { type: "img", value: "/quiz-images-2/GRE/img158.png" },
      {
        type: "txt",
        value:
          "with ker (∅)={1,11}. If ∅ (7) =7, which of the following elements does ∅ also map to 7?",
      },
    ],
    options: [
      { type: "txt", value: "11" },
      { type: "txt", value: "13" },
      { type: "txt", value: "17" },
      { type: "txt", value: "19" },
      { type: "txt", value: "29" },
    ],
    answer: 2,
  },
  {
    id: 59,
    question: [
      { type: "txt", value: "A matrix M can be factored as M=LU, where" },
      { type: "img", value: "/quiz-images-2/GRE/img160.png" },
      { type: "img", value: "/quiz-images-2/GRE/img161.png" },
      {
        type: "txt",
        value:
          "and x is the solution  of the system Mx=b, what is the first  coordinate of the vector x?",
      },
    ],
    options: [
      { type: "txt", value: "-2" },
      { type: "txt", value: "-1" },
      { type: "txt", value: "0" },
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
    ],
    answer: 1,
  },
  {
    id: 60,
    question: [
      {
        type: "txt",
        value:
          "A primitive 10th root of unity is defined as a complex number z such that",
      },
      { type: "img", value: "/quiz-images-2/GRE/img162.png" },
      {
        type: "txt",
        value:
          "for each integer k, where 1k9. If S is the sum and P is the product of all the primitive 10th roots of unity, then",
      },
    ],
    options: [
      { type: "txt", value: "S= - 1 and P=1" },
      { type: "txt", value: "S=0  and P= - 1" },
      { type: "txt", value: "S=0 and P=1" },
      { type: "txt", value: "S= 1 and P= - 1" },
      { type: "txt", value: "S=1  and P=1" },
    ],
    answer: 4,
  },
  {
    id: 61,
    question: [
      {
        type: "txt",
        value:
          "For each of the following metrics is (ℝ, d). For which of the metrics is (ℝ, d) NOT a complete metric space?",
      },
    ],
    options: [
      { type: "txt", value: "d (x,y) = |x-y|" },
      { type: "txt", value: "d (x,y) = |arctan x-arctan y|" },
      { type: "txt", value: "d (x,y) = |x³-y³|" },
      { type: "img", value: "/quiz-images-2/GRE/img163.png" },
      { type: "txt", value: "/quiz-images-2/GRE/img164.png" },
    ],
    answer: 1,
  },
  {
    id: 62,
    question: [{ type: "img", value: "/quiz-images-2/GRE/img165.png" }],
    options: [
      { type: "txt", value: "0" },
      { type: "img", value: "/quiz-images-2/GRE/img166.png" },
      { type: "img", value: "/quiz-images-2/GRE/img168.png" },
      { type: "txt", value: "50" },
      { type: "txt", value: "50π" },
    ],
    answer: 0,
  },
  {
    id: 63,
    question: [
      {
        type: "txt",
        value:
          "Suppose that ⨍ and g are continuously differentiable real-valued functions on ℝ such that ⨍ (0)=0 and g`(0)≠0. Which of the following statements must be true?   I. The function",
      },
      { type: "img", value: "/quiz-images-2/GRE/img168.png" },
      {
        type: "txt",
        value:
          "can be extended to a continuous function in a neighborhood of 0.  II. The function",
      },
      { type: "img", value: "/quiz-images-2/GRE/img169.png" },
      {
        type: "txt",
        value:
          "can be extended to a continuous function in a neighborhood of 0.  III. The function",
      },
      { type: "img", value: "/quiz-images-2/GRE/img170.png" },
      {
        type: "txt",
        value:
          "can be extended to a differentiable function in a neighborhood of 0.",
      },
    ],
    options: [
      { type: "txt", value: "None" },
      { type: "txt", value: "I only " },
      { type: "txt", value: "I and II only" },
      { type: "txt", value: "I and III only" },
      { type: "txt", value: "I, II, and III" },
    ],
    answer: 2,
  },
  {
    id: 64,
    question: [
      {
        type: "txt",
        value:
          "In a certain population of men, 5 percent of the men have coronary artery disease. A diagnostic test is used to indicate whether or not a man has the disease , but the test is not always correct . For men who have the disease, the test is correct 24 percent of the time, while for men who do not have the disease, the test is correct 98 percent of the time. If a men is randomly chosen from the population and the test indicates that he has the disease, then the probability that the test is correct is closest to which of the following?",
      },
    ],
    options: [
      { type: "txt", value: "1%" },
      { type: "txt", value: "12%" },
      { type: "txt", value: "24%" },
      { type: "txt", value: "29%" },
      { type: "txt", value: "39%" },
    ],
    answer: 4,
  },
  {
    id: 65,
    question: [
      {
        type: "txt",
        value:
          "Let X={n∈Z:n≥2} be the topological space with its topology generated by the sets of the form",
      },
      { type: "img", value: "/quiz-images-2/GRE/img171.png" },
      {
        type: "txt",
        value:
          "so that the open sets in X are the emty set and arbitrary unions of the sets",
      },
      { type: "img", value: "/quiz-images-2/GRE/img172.png" },
      { type: "txt", value: "For any n∈X, the closure of {n} in X is" },
    ],
    options: [
      { type: "txt", value: "{n}" },
      { type: "txt", value: "{sn:s is a positive integer}" },
      { type: "txt", value: "{t∈X :t divides n}" },
      { type: "txt", value: "{p:p is a prime divisor of n}" },
      { type: "img", value: "/quiz-images-2/GRE/img173.png" },
    ],
    answer: 1,
  },
  {
    id: 66,
    question: [{ type: "img", value: "/quiz-images-2/GRE/img174.png" }],
    options: [
      { type: "txt", value: "X" },
      { type: "img", value: "/quiz-images-2/GRE/img175.png" },
      { type: "txt", value: "log x" },
      { type: "txt", value: "X log x" },
      { type: "txt", value: "X log x-1" },
    ],
    answer: 2,
  },
  {
    id: 67,
    question: [
      { type: "txt", value: "If F(1) = 2 and F(n)=" },
      { type: "img", value: "/quiz-images-2/GRE/img176.png" },
      { type: "txt", value: "for all integers n>1, then F(101)=" },
    ],
    options: [
      { type: "txt", value: "49" },
      { type: "txt", value: "50" },
      { type: "txt", value: "51" },
      { type: "txt", value: "52" },
      { type: "txt", value: "53" },
    ],
    answer: 3,
  },
  {
    id: 68,
    question: [
      { type: "img", value: "/quiz-images-2/GRE/img177.png" },
      {
        type: "txt",
        value: "is invertible under matrix multiplication, then its inverse is",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GRE/img178.png" },
      { type: "img", value: "/quiz-images-2/GRE/img179.png" },
      { type: "img", value: "/quiz-images-2/GRE/img180.png" },
      { type: "img", value: "/quiz-images-2/GRE/img181.png" },
      { type: "img", value: "/quiz-images-2/GRE/img182.png" },
    ],
    answer: 2,
  },
  {
    id: 69,
    question: [
      { type: "img", value: "/quiz-images-2/GRE/img183.png" },
      { type: "txt", value: "If b>0 and if" },
      { type: "img", value: "/quiz-images-2/GRE/img184.png" },
      {
        type: "txt",
        value: "then the area of the shaded region in the figure above is",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GRE/img185.png" },
      { type: "img", value: "/quiz-images-2/GRE/img186.png" },
      { type: "img", value: "/quiz-images-2/GRE/img187.png" },
      { type: "img", value: "/quiz-images-2/GRE/img188.png" },
      { type: "img", value: "/quiz-images-2/GRE/img189.png" },
    ],
    answer: 1,
  },
  {
    id: 70,
    question: [
      {
        type: "txt",
        value:
          "Consider the following sequence of instructions.  1.Set k=999, i=1, and p=0   2. If k>i, then go to step 3; otherwise go to step 5    3. Replace i with 2i and replace p with p+1   4. Print p  If these instructions are followed, what number will be printed at step 5?",
      },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "10" },
      { type: "txt", value: "512" },
      { type: "txt", value: "999" },
    ],
    answer: 2,
  },
  {
    id: 71,
    question: [{ type: "img", value: "/quiz-images-2/GRE/img191.png" }],
    options: [
      { type: "txt", value: "1" },
      { type: "img", value: "/quiz-images-2/GRE/img192.png" },
      { type: "img", value: "/quiz-images-2/GRE/img193.png" },
      { type: "txt", value: "log2" },
      { type: "img", value: "/quiz-images-2/GRE/img194.png" },
    ],
    answer: 4,
  },
  {
    id: 72,
    question: [
      {
        type: "txt",
        value:
          "If S is a nonempty finite set with k elements, then the number of one-to-one functions from S onto S is ",
      },
    ],
    options: [
      { type: "txt", value: "k!" },
      { type: "txt", value: "k²" },
      { type: "img", value: "/quiz-images-2/GRE/img195.png" },
      { type: "txt", value: "/quiz-images-2/GRE/img196.png" },
    ],
    answer: 4,
  },
  {
    id: 73,
    question: [
      {
        type: "txt",
        value: "Let g be the fuction defined on the set of all real numbers by",
      },
      { type: "img", value: "/quiz-images-2/GRE/img197.png" },
      {
        type: "txt",
        value: "Then the set of numbers at which g is continuous is",
      },
    ],
    options: [
      { type: "txt", value: "The empty set" },
      { type: "txt", value: "{0}" },
      { type: "txt", value: "{1}" },
      { type: "txt", value: "The set of rational numbers" },
      { type: "txt", value: "The set of irrational numbers" },
    ],
    answer: 1,
  },
  {
    id: 74,
    question: [
      { type: "txt", value: "For all real numbers x and y, the expression" },
      { type: "img", value: "/quiz-images-2/GRE/img198.png" },
      { type: "txt", value: "is equal to " },
    ],
    options: [
      { type: "txt", value: "The maximum of x and y" },
      { type: "txt", value: "The minimum of x and y" },
      { type: "txt", value: "|x+y|" },
      { type: "txt", value: "The average of |x| and |y|" },
      { type: "txt", value: "The average of |x+y| and x – y" },
    ],
    answer: 0,
  },
  {
    id: 75,
    question: [
      {
        type: "txt",
        value:
          "Let B be a nonempty bounded set of real numbers and let b be the least upper bound of B. If b is not a number of B, which of the following is necessarily true?",
      },
    ],
    options: [
      { type: "txt", value: "B is closed" },
      { type: "txt", value: "B is not open" },
      { type: "txt", value: "b is a limit point of B" },
      { type: "txt", value: "No sequence in B converges to b" },
      {
        type: "txt",
        value:
          "There is an open interval containing b but containing no point of B",
      },
    ],
    answer: 2,
  },
  {
    id: 76,
    question: [
      {
        type: "txt",
        value:
          "A drawer contains 2 blue, 4 red, and 2 yellow socks. If 2 socks are to be randomly selected from the drawer, what is the probability that they will be the same color?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GRE/img199.png" },
      { type: "img", value: "/quiz-images-2/GRE/img200.png" },
      { type: "img", value: "/quiz-images-2/GRE/img201.png" },
      { type: "img", value: "/quiz-images-2/GRE/img202.png" },
      { type: "img", value: "/quiz-images-2/GRE/img203.png" },
    ],
    answer: 0,
  },
  {
    id: 77,
    question: [
      {
        type: "txt",
        value:
          "Let ℝ be the set of real numbers and let ⨍ and g functions from ℝ into ℝ. The negation of the statement     ``For each s in ℝ, there exists an r in ℝ such that if ⨍(r) >0, then g(s)>0.`` is which of the following?",
      },
    ],
    options: [
      {
        type: "txt",
        value:
          "For each s in ℝ, there does not exist an r in ℝ such that if ⨍(r) >0, then g(s) >0",
      },
      {
        type: "txt",
        value:
          "For each s in ℝ, there exists an r in ℝ such that ⨍(r) >0 and g(s) 0",
      },
      {
        type: "txt",
        value:
          "There exists an s in ℝ such that for each r in ℝ, ⨍(r) >0 and g(s) ≤0",
      },
      {
        type: "txt",
        value:
          "There exists an s in ℝ and there exists an r in ℝ such that ⨍(r) and g (s) ≤0",
      },
      {
        type: "txt",
        value:
          "For each r in ℝ, there exists an s in ℝ such that ⨍(r) ≤0 and g(s) ≤0",
      },
    ],
    answer: 2,
  },
  {
    id: 78,
    question: [
      {
        type: "txt",
        value:
          "If g is function defined on the open interval (a,b) such that a<g(x)<x  for all x(a,b), then g is",
      },
    ],
    options: [
      { type: "txt", value: "an unbounded function" },
      { type: "txt", value: "a nonconstant function" },
      { type: "txt", value: "a nonnegative function" },
      { type: "txt", value: "a strictly increasing function" },
      { type: "txt", value: "a polynomial function of degree 1" },
    ],
    answer: 1,
  },
  {
    id: 79,
    question: [
      {
        type: "txt",
        value:
          "For what value (or values) of m is the vector (1,2,m,5) a linear combination of the vectors (0,1,1,1), (0,0,0,1), and (1,1,2,0)?",
      },
      { type: "img", value: "/quiz-images-2/GRE/img204.png" },
    ],
    options: [
      { type: "txt", value: "For no value of m" },
      { type: "txt", value: "-1 only" },
      { type: "txt", value: "1 only" },
      { type: "txt", value: "3 only" },
      { type: "txt", value: "For infinitely many values of m" },
    ],
    answer: 3,
  },
  {
    id: 80,
    question: [
      {
        type: "txt",
        value:
          "For a function ⨍, the finite differences ∆⨍(x) and ∆²f(x)  are defined by ∆ ⨍(x)=⨍(x+1)-⨍(x) and ∆²⨍(x) = ∆⨍(x+1)-∆⨍(x). What is the value of ⨍(4), given the following partially completed finite difference table?",
      },
    ],
    options: [
      { type: "txt", value: "-5" },
      { type: "txt", value: "-1" },
      { type: "txt", value: "1" },
      { type: "txt", value: "3" },
      { type: "txt", value: "5" },
    ],
    answer: 4,
  },
  {
    id: 81,
    question: [
      {
        type: "txt",
        value:
          "In the figure above, the annulus with center C has inner radius r and outer radius 1. As r increases, the circle with center   O contracts and remains tangent to the inner circle. If A(r) is the area of the annulus and a(r) is the area of the circular region with center O, then",
      },
      { type: "img", value: "/quiz-images-2/GRE/img205.png" },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "img", value: "/quiz-images-2/GRE/img206.png" },
      { type: "txt", value: "1" },
      { type: "img", value: "/quiz-images-2/GRE/img207.png" },
      { type: "txt", value: "∞" },
    ],
    answer: 4,
  },
  {
    id: 82,
    question: [
      {
        type: "txt",
        value:
          "Which of the following are multiplication tables for groups with four elements?",
      },
      { type: "img", value: "/quiz-images-2/GRE/img208.png" },
    ],
    options: [
      { type: "txt", value: "None" },
      { type: "txt", value: "I only" },
      { type: "txt", value: "I and II only" },
      { type: "txt", value: "II and III only" },
      { type: "txt", value: "I, II, and III" },
    ],
    answer: 1,
  },
  {
    id: 83,
    question: [
      {
        type: "txt",
        value:
          "Which of the following statements are true for every function ⨍, defined on the set of all real numbers, such that ",
      },
      { type: "img", value: "/quiz-images-2/GRE/img209.png" },
      {
        type: "txt",
        value:
          "is a real number L and ⨍(0)=0?     I. ⨍ is differentiable at 0.   II. L = 0   III. ",
      },
      { type: "img", value: "/quiz-images-2/GRE/img210.png" },
    ],
    options: [
      { type: "txt", value: "None" },
      { type: "txt", value: "I only" },
      { type: "txt", value: "III only" },
      { type: "txt", value: "I and III only" },
      { type: "txt", value: "I, II, and III" },
    ],
    answer: 3,
  },
  {
    id: 84,
    question: [
      {
        type: "txt",
        value:
          "What is the area of the region bounded by the coordinate axes and the line tangent to the graph of",
      },
      { type: "img", value: "/quiz-images-2/GRE/img211.png" },
      { type: "txt", value: "at the point (0,1)?" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GRE/img212.png" },
      { type: "img", value: "/quiz-images-2/GRE/img213.png" },
      { type: "img", value: "/quiz-images-2/GRE/img214.png" },
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
    ],
    answer: 3,
  },
  {
    id: 85,
    question: [
      {
        type: "txt",
        value:
          "Let ℤ be the group of all integers under the operation of addition. Which of the following subsets of ℤ is NOT a subgroup of ℤ?",
      },
    ],
    options: [
      { type: "txt", value: "{0}" },
      { type: "txt", value: "{n∈Z:n≥0}" },
      { type: "txt", value: "{n∈Z:n is an even integer}" },
      { type: "txt", value: "{n∈Z:n is divisible by both 6 and 9}" },
      { type: "txt", value: "ℤ" },
    ],
    answer: 1,
  },
  {
    id: 86,
    question: [
      {
        type: "txt",
        value:
          "In the Euclidean plane, point A is on a circle centered at point O, and O is on a circle centered at A. The circles intersect at points B and C. What is the measure of angle BAC?",
      },
    ],
    options: [
      { type: "txt", value: "60°" },
      { type: "txt", value: "90°" },
      { type: "txt", value: "120°" },
      { type: "txt", value: "135°" },
      { type: "txt", value: "150°" },
    ],
    answer: 2,
  },
  {
    id: 87,
    question: [
      {
        type: "txt",
        value:
          "Which of the following sets of vectors is a basis for the subspace of Euclidean 4-space consisting of all vectors that are orthogonal to both (0,1,1,1) and (1,1,1,0)?",
      },
    ],
    options: [
      { type: "txt", value: "{(0,-1,1,0)}" },
      { type: "txt", value: "{(1,0,0,0),-(0,00,1)}" },
      { type: "txt", value: "{-2,1,1,-2,(0,1,-1,0)}" },
      { type: "txt", value: "{1,-1,0,1, -1,1,0,-1, (0,1,-1,0)}" },
      { type: "txt", value: "{0,0,0,0, -1,1,0,-1, (0,1,-1,0)}" },
    ],
    answer: 2,
  },
  {
    id: 88,
    question: [
      {
        type: "txt",
        value:
          "Let ⨍ be the function defined by ⨍(x,y)=5x-4y   on the region in the xy-plane satisfying the inequalities x≤2, y≥0, x+y≥1, and y-x≤0. The maximum value of ⨍ on this region is",
      },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "5" },
      { type: "txt", value: "10" },
      { type: "txt", value: "15" },
    ],
    answer: 3,
  },
  {
    id: 89,
    question: [
      { type: "txt", value: "Let ⨍ be the function defined by " },
      { type: "img", value: "/quiz-images-2/GRE/img215.png" },
      {
        type: "txt",
        value: "Which of the following statements about ⨍ is true?",
      },
    ],
    options: [
      { type: "txt", value: "⨍ has an absolute maximum at x=0" },
      { type: "txt", value: "⨍ has an absolute maximum at x=1" },
      { type: "txt", value: "⨍ has an absolute maximum at x=2" },
      { type: "txt", value: "⨍ has an absolute maximum" },
      { type: "txt", value: "⨍ has local maxima at both x=0  and  x=2" },
    ],
    answer: 1,
  },
  {
    id: 90,
    question: [
      {
        type: "txt",
        value:
          "Let be a function such that ⨍(x) = ⨍(1-x) for all real numbers x. If ⨍ is differentiable everywhere, then ⨍`(0)=",
      },
    ],
    options: [
      { type: "txt", value: "⨍(0)" },
      { type: "txt", value: "⨍(1)" },
      { type: "txt", value: "-⨍(0)" },
      { type: "txt", value: "⨍`(1)" },
      { type: "txt", value: "-⨍`(1)" },
    ],
    answer: 4,
  },
  {
    id: 91,
    question: [
      { type: "img", value: "/quiz-images-2/GRE/img216.png" },
      {
        type: "txt",
        value:
          "are 6-dimensional subspaces of a 10-dimensional vector space V, what is the smallest possible dimension that",
      },
      { type: "img", value: "/quiz-images-2/GRE/img217.png" },
      { type: "txt", value: "can have?" },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "4" },
      { type: "txt", value: "6" },
    ],
    answer: 2,
  },
  {
    id: 92,
    question: [
      {
        type: "txt",
        value:
          "Assume that p is a polynomial function on the set of real numbers. If p(0)=p(2)=3 and p`(0)=p`(2)=-1, then",
      },
      { type: "img", value: "/quiz-images-2/GRE/img218.png" },
    ],
    options: [
      { type: "txt", value: "-3" },
      { type: "txt", value: "-2" },
      { type: "txt", value: "-1" },
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
    ],
    answer: 1,
  },
  {
    id: 93,
    question: [
      {
        type: "txt",
        value:
          "Suppose B is a basis for a real vector space V of dimension greater than 1. Which of the following statements cloud be true?",
      },
    ],
    options: [
      { type: "txt", value: "The zero vector of is an element of B" },
      { type: "txt", value: "B has a proper subset that spans V" },
      {
        type: "txt",
        value: "B is a proper subset of a linearly independent subset of V",
      },
      { type: "txt", value: "There is a basis for V that is disjoint from B" },
      {
        type: "txt",
        value:
          "One of the vectors in B is a linear combination of the vectors in B",
      },
    ],
    answer: 3,
  },
  {
    id: 94,
    question: [
      {
        type: "txt",
        value:
          "Which of the following CANNOT be a root of a polynomial in x of the form 9 x⁵+ax³+b, where a and b are integers?",
      },
    ],
    options: [
      { type: "txt", value: "-9" },
      { type: "txt", value: "-5" },
      { type: "img", value: "/quiz-images-2/GRE/img219.png" },
      { type: "img", value: "/quiz-images-2/GRE/img220.png" },
      { type: "txt", value: "9" },
    ],
    answer: 2,
  },
  {
    id: 95,
    question: [
      {
        type: "txt",
        value:
          "When 20 children in a classroom line up for lunch, Pat insists on being somewhere ahead of Lynn. If Pat`s demand is to be satisfied, in how many ways can the children line up?",
      },
    ],
    options: [
      { type: "txt", value: "20!" },
      { type: "txt", value: "19!" },
      { type: "txt", value: "18!" },
      { type: "img", value: "/quiz-images-2/GRE/img221.png" },
      { type: "txt", value: "20∙19" },
    ],
    answer: 3,
  },
  {
    id: 96,
    question: [
      {
        type: "txt",
        value:
          "How many integers from 1 to 1,000 are divisible by 30 but not by 16?",
      },
    ],
    options: [
      { type: "txt", value: "29" },
      { type: "txt", value: "31" },
      { type: "txt", value: "32" },
      { type: "txt", value: "33" },
      { type: "txt", value: "38" },
    ],
    answer: 0,
  },
  {
    id: 97,
    question: [
      {
        type: "txt",
        value: "Suppose ⨍ is a differentiable function for which",
      },
      { type: "img", value: "/quiz-images-2/GRE/img222.png" },
      {
        type: "txt",
        value:
          "both exist and are finite. Which of the following must be true?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GRE/img223.png" },
      { type: "img", value: "/quiz-images-2/GRE/img224.png" },
      { type: "img", value: "/quiz-images-2/GRE/img225.png" },
      { type: "txt", value: "⨍ is a constant function " },
      { type: "txt", value: "⨍` is a constant function" },
    ],
    answer: 0,
  },
  {
    id: 98,
    question: [
      {
        type: "txt",
        value: "In xyz-space, an equation of the plane tangent to the surface",
      },
      { type: "img", value: "/quiz-images-2/GRE/img226.png" },
      { type: "txt", value: "sin y at the point where x=0 and" },
      { type: "img", value: "/quiz-images-2/GRE/img227.png" },
      { type: "txt", value: "is" },
    ],
    options: [
      { type: "txt", value: "x+y=1" },
      { type: "txt", value: "x+z=1" },
      { type: "txt", value: "x-z=1" },
      { type: "txt", value: "y+z=1" },
      { type: "txt", value: "y-z=1" },
    ],
    answer: 1,
  },
  {
    id: 99,
    question: [
      {
        type: "txt",
        value:
          "For each real number x, let μ(x) be the mean of the numbers 4,9,7,5, and x; and let ƞ(x) be the median of these five numbers. For how many values of x is μ(x) = ƞ(x)?",
      },
    ],
    options: [
      { type: "txt", value: "None" },
      { type: "txt", value: "One" },
      { type: "txt", value: "Two" },
      { type: "txt", value: "Three" },
      { type: "txt", value: "Infinitely many" },
    ],
    answer: 3,
  },
  {
    id: 100,
    question: [{ type: "img", value: "/quiz-images-2/GRE/img228.png" }],
    options: [
      { type: "txt", value: "e" },
      { type: "txt", value: "2e" },
      { type: "txt", value: "(e+1)(e-1)" },
      { type: "txt", value: "e²" },
      { type: "txt", value: "∞" },
    ],
    answer: 1,
  },
  {
    id: 101,
    question: [
      {
        type: "txt",
        value: "Which of the following integrals on the interval",
      },
      { type: "img", value: "/quiz-images-2/GRE/img229.png" },
      { type: "txt", value: "has the greatest value?" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GRE/img230.png" },
      { type: "img", value: "/quiz-images-2/GRE/img231.png" },
      { type: "img", value: "/quiz-images-2/GRE/img232.png" },
      { type: "img", value: "/quiz-images-2/GRE/img233.png" },
      { type: "img", value: "/quiz-images-2/GRE/img234.png" },
    ],
    answer: 1,
  },
  {
    id: 102,
    question: [
      {
        type: "txt",
        value: "In xyz-space, an equation of the plane tangent to the surface",
      },
      { type: "img", value: "/quiz-images-2/GRE/img235.png" },
      { type: "txt", value: "sin y at the point where x=0 and" },
      { type: "img", value: "/quiz-images-2/GRE/img236.png" },
    ],
    options: [
      { type: "txt", value: "x+y=1" },
      { type: "txt", value: "x+z=1" },
      { type: "txt", value: "x-z=1" },
      { type: "txt", value: "y+z=1" },
      { type: "txt", value: "y-z=1" },
    ],
    answer: 1,
  },
  {
    id: 103,
    question: [
      {
        type: "txt",
        value:
          "For each real number x, let μ(x) be the mean of the numbers 4,9,7,5, and x; and let ƞ(x) be the median of these five numbers. For how many values of x is μ(x)=ƞ(x)?",
      },
    ],
    options: [
      { type: "txt", value: "None" },
      { type: "txt", value: "One" },
      { type: "txt", value: "Two" },
      { type: "txt", value: "Three" },
      { type: "txt", value: "Infinitely many" },
    ],
    answer: 3,
  },
  {
    id: 104,
    question: [{ type: "img", value: "/quiz-images-2/GRE/img237.png" }],
    options: [
      { type: "txt", value: "e" },
      { type: "txt", value: "2e" },
      { type: "txt", value: "(e+1)(e-1)" },
      { type: "txt", value: "e²" },
      { type: "txt", value: "∞" },
    ],
    answer: 1,
  },
  {
    id: 105,
    question: [
      {
        type: "txt",
        value: "Which of the following integrals on the interval",
      },
      { type: "img", value: "/quiz-images-2/GRE/img238.png" },
      { type: "txt", value: "has the greatest value?" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GRE/img239.png" },
      { type: "img", value: "/quiz-images-2/GRE/img240.png" },
      { type: "img", value: "/quiz-images-2/GRE/img241.png" },
      { type: "img", value: "/quiz-images-2/GRE/img242.png" },
      { type: "img", value: "/quiz-images-2/GRE/img243.png" },
    ],
    answer: 1,
  },
  {
    id: 106,
    question: [
      { type: "txt", value: "Consider the function ⨍ defined by" },
      { type: "img", value: "/quiz-images-2/GRE/img244.png" },
      { type: "txt", value: "on the interval [0,10]. Let n>1 and let" },
      { type: "img", value: "/quiz-images-2/GRE/img245.png" },
      { type: "txt", value: "be numbers such that 0=" },
      { type: "img", value: "/quiz-images-2/GRE/img246.png" },
      { type: "txt", value: "Which of the following is greatest?" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GRE/img247.png" },
      { type: "img", value: "/quiz-images-2/GRE/img248.png" },
      { type: "img", value: "/quiz-images-2/GRE/img249.png" },
      { type: "img", value: "/quiz-images-2/GRE/img250.png" },
      { type: "txt", value: "0" },
    ],
    answer: 1,
  },
  {
    id: 107,
    question: [
      {
        type: "txt",
        value:
          "A fair coin is to be tossed 8 times. What is the probability that more of the tosses will result in heads than will result in tails?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GRE/img251.png" },
      { type: "img", value: "/quiz-images-2/GRE/img252.png" },
      { type: "img", value: "/quiz-images-2/GRE/img253.png" },
      { type: "img", value: "/quiz-images-2/GRE/img254.png" },
      { type: "img", value: "/quiz-images-2/GRE/img255.png" },
    ],
    answer: 4,
  },
  {
    id: 108,
    question: [
      {
        type: "txt",
        value:
          "The function ⨍(x,y) = xy-x³-y³ has a relative maximum at the point",
      },
    ],
    options: [
      { type: "txt", value: "(0,0)" },
      { type: "txt", value: "(1,1)" },
      { type: "txt", value: "(-1,-1)" },
      { type: "txt", value: "(1,3)" },
      { type: "img", value: "/quiz-images-2/GRE/img256.png" },
    ],
    answer: 4,
  },
  {
    id: 109,
    question: [
      {
        type: "txt",
        value:
          "Consider the points A=(-1,2), B=(6,4), and C=(1, -20) in the plane. For how many different points D in the plane are A, B, C, and D the vertices of a  parallelogram?",
      },
    ],
    options: [
      { type: "txt", value: "None" },
      { type: "txt", value: "One" },
      { type: "txt", value: "Two" },
      { type: "txt", value: "Three" },
      { type: "txt", value: "Four" },
    ],
    answer: 3,
  },
  {
    id: 110,
    question: [
      { type: "txt", value: "If A is a 3x3 matrix such that A" },
      { type: "img", value: "/quiz-images-2/GRE/img257.png" },
      { type: "txt", value: "then the product " },
      { type: "img", value: "/quiz-images-2/GRE/img258.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GRE/img259.png" },
      { type: "img", value: "/quiz-images-2/GRE/img260.png" },
      { type: "img", value: "/quiz-images-2/GRE/img261.png" },
      { type: "img", value: "/quiz-images-2/GRE/img262.png" },
      {
        type: "txt",
        value: "Not uniquely determined by the information given",
      },
    ],
    answer: 1,
  },
  {
    id: 111,
    question: [
      { type: "txt", value: "Let" },
      { type: "img", value: "/quiz-images-2/GRE/img263.png" },
      { type: "txt", value: "denote the function defined for all x>0 by" },
      { type: "img", value: "/quiz-images-2/GRE/img264.png" },
      { type: "txt", value: "Which of the following statements is FALSE?" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GRE/img265.png" },
      { type: "img", value: "/quiz-images-2/GRE/img266.png" },
      { type: "img", value: "/quiz-images-2/GRE/img267.png" },
      { type: "txt", value: "The derivative ⨍`(x) is positive for all x>0" },
      { type: "txt", value: "The derivative ⨍`(x) is increasing for all x>0" },
    ],
    answer: 3,
  },
  {
    id: 112,
    question: [
      {
        type: "txt",
        value:
          "An experimental car is found to have a fuel efficiency E(v), in miles per gallon of fuel, where v is the speed of the car, in miles per hour. For a certain 4-hour trip, if v=v(t) is the speed of the car t hours after the trip started, which of the following integrals represents the number of gallons of fuel that the car used on the trip?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GRE/img268.png" },
      { type: "img", value: "/quiz-images-2/GRE/img269.png" },
      { type: "img", value: "/quiz-images-2/GRE/img270.png" },
      { type: "img", value: "/quiz-images-2/GRE/img271.png" },
      { type: "img", value: "/quiz-images-2/GRE/img272.png" },
    ],
    answer: 0,
  },
  {
    id: 113,
    question: [
      { type: "txt", value: "For 0<t<, the matrix" },
      { type: "img", value: "/quiz-images-2/GRE/img273.png" },
      { type: "txt", value: "has distinct complex eigenvalues" },
      { type: "img", value: "/quiz-images-2/GRE/img274.png" },
      { type: "txt", value: "For what value of t," },
      { type: "img", value: "/quiz-images-2/GRE/img275.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GRE/img276.png" },
      { type: "img", value: "/quiz-images-2/GRE/img277.png" },
      { type: "img", value: "/quiz-images-2/GRE/img278.png" },
      { type: "img", value: "/quiz-images-2/GRE/img279.png" },
      { type: "img", value: "/quiz-images-2/GRE/img280.png" },
    ],
    answer: 2,
  },
  {
    id: 114,
    question: [
      {
        type: "txt",
        value:
          "Let x and y be uniformly distributed, independent random variables on",
      },
      { type: "img", value: "/quiz-images-2/GRE/img281.png" },
      {
        type: "txt",
        value: "The probability that the distance between x and y is less than",
      },
      { type: "img", value: "/quiz-images-2/GRE/img282.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GRE/img283.png" },
      { type: "img", value: "/quiz-images-2/GRE/img284.png" },
      { type: "img", value: "/quiz-images-2/GRE/img285.png" },
      { type: "img", value: "/quiz-images-2/GRE/img286.png" },
      { type: "img", value: "/quiz-images-2/GRE/img287.png" },
    ],
    answer: 4,
  },
  {
    id: 115,
    question: [
      {
        type: "txt",
        value:
          "Consider tha change of variables from the xy-plane to the uv-plane given by the equations",
      },
      { type: "img", value: "/quiz-images-2/GRE/img288.png" },
      {
        type: "txt",
        value:
          "v=1+y  Under this transformation, the image of the region {x,y:0≤x≤1 and 0≤y≤1}  is which of the following shaded regions?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GRE/img289.png" },
      { type: "img", value: "/quiz-images-2/GRE/img290.png" },
      { type: "img", value: "/quiz-images-2/GRE/img291.png" },
      { type: "img", value: "/quiz-images-2/GRE/img292.png" },
      { type: "img", value: "/quiz-images-2/GRE/img293.png" },
    ],
    answer: 0,
  },
  {
    id: 116,
    question: [
      {
        type: "txt",
        value:
          "If ⨍ is a continuous function on the set of real numbers and if a and b are real numbers, which of the following must be true? I. ",
      },
      { type: "img", value: "/quiz-images-2/GRE/img294.png" },
      { type: "img", value: "/quiz-images-2/GRE/img295.png" },
      { type: "img", value: "/quiz-images-2/GRE/img296.png" },
    ],
    options: [
      { type: "txt", value: "I only" },
      { type: "txt", value: "II only" },
      { type: "txt", value: "I and II only" },
      { type: "txt", value: "II and III only" },
      { type: "txt", value: "I, II, and III" },
    ],
    answer: 4,
  },
  {
    id: 117,
    question: [
      {
        type: "txt",
        value:
          "How many continuous real- valued functions ⨍ are there with domain {-1, 1} such that (⨍x)² = x² for each x in {-1,1}?",
      },
    ],
    options: [
      { type: "txt", value: "One" },
      { type: "txt", value: "Two" },
      { type: "txt", value: "Three" },
      { type: "txt", value: "Four" },
      { type: "txt", value: "Infinitely many" },
    ],
    answer: 3,
  },
  {
    id: 118,
    question: [
      {
        type: "txt",
        value: "Let D be the region in the xy-plane in which the series",
      },
      { type: "img", value: "/quiz-images-2/GRE/img297.png" },
      { type: "txt", value: " convergesThen the interior of D is" },
    ],
    options: [
      { type: "txt", value: "an open disk" },
      { type: "txt", value: "the open region bounded by an ellipse" },
      { type: "txt", value: "the open region bounded by a quadrilateral" },
      { type: "txt", value: "the open region between two parallel lines" },
      { type: "txt", value: "an open half plane" },
    ],
    answer: 3,
  },
  {
    id: 119,
    question: [
      {
        type: "txt",
        value:
          "Consider the following system of linear equations over the real numbers, where x,y, and z are variables and b is a real constant.    x+y+z=0      x+2y+3y=0     x+3y+bz=0    which of the following statements are true?   I. There exists a valua of b for which the system has no solution  II. There exists a value of b for which the system has one solution   III.  There exists a value of b for which the system has more one solution",
      },
    ],
    options: [
      { type: "txt", value: "II only" },
      { type: "txt", value: "I and II only" },
      { type: "txt", value: "I and III only" },
      { type: "txt", value: "II and III only" },
      { type: "txt", value: "I,II, and III" },
    ],
    answer: 3,
  },
  {
    id: 120,
    question: [
      {
        type: "txt",
        value:
          "In the complex plane, let C be the circle z=2 with positive (counterclockwise) orientation. Then",
      },
      { type: "img", value: "/quiz-images-2/GRE/img298.png" },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "txt", value: "2πi" },
      { type: "img", value: "/quiz-images-2/GRE/img299.png" },
      { type: "img", value: "/quiz-images-2/GRE/img300.png" },
      { type: "img", value: "/quiz-images-2/GRE/img301.png" },
    ],
    answer: 3,
  },
  {
    id: 121,
    question: [
      {
        type: "txt",
        value:
          "The insider of a certain water tank is a cube measuring 10feet on each edge and having vertical sides and no top. Let h(t) denote the water level, in feet, above the floor of the tank at time t seconds. Starting at time t=0, water pours into the tank at a constant rate of 1 cubic foot per second, and simultaneously, water is removed from the tank at a rate of 0.25h(t) cubic feet per second. As t→∞, what is the limit of the volume of the water in the tank?",
      },
    ],
    options: [
      { type: "txt", value: "400 cubic feet" },
      { type: "txt", value: "600 cubic feet" },
      { type: "txt", value: "1,000 cubic feet" },
      { type: "txt", value: "the limit does not exist" },
      {
        type: "txt",
        value:
          "the limit exists, but it cannot be determined without knowingh(0)",
      },
    ],
    answer: 0,
  },
  {
    id: 122,
    question: [
      {
        type: "txt",
        value:
          "Suppose that ⨍ is a twice-differentiable function on the set of real numbers and that ⨍(0), ⨍`(0), and ⨍``(0) are all negative. Suppose ⨍`` has all three of the following properties.   I. It is increasing on the interval [0,∞)  II. It has a unique zero in the interval [0,∞)   III. It is unbounded on the interval [0,∞)  Which of the same three properties does ⨍ necessarily have?",
      },
    ],
    options: [
      { type: "txt", value: "I only" },
      { type: "txt", value: "II only" },
      { type: "txt", value: "III only" },
      { type: "txt", value: "II and III only" },
      { type: "txt", value: "I, II, and III" },
    ],
    answer: 3,
  },
  {
    id: 123,
    question: [
      {
        type: "txt",
        value:
          "For every set S and every metric d on S, which of the following is a metric on S?",
      },
    ],
    options: [
      { type: "txt", value: "4+d" },
      { type: "img", value: "/quiz-images-2/GRE/img302.png" },
      { type: "txt", value: "d−|d|" },
      { type: "txt", value: "d²" },
      { type: "img", value: "/quiz-images-2/GRE/img303.png" },
    ],
    answer: 4,
  },
  {
    id: 124,
    question: [
      {
        type: "txt",
        value:
          "Let R be the field of real numbers and ℝ [x] the ring of polynomials in x with coefficients in ℝ. Which of the following subsets of ℝ [x] is a subring of ℝ [x]?  I. All polynomials whose coefficient of x is zero   II. All polynomials whose degree is an even integer, together with the zero polynomial    III. All polynomials whose coefficients are rational numbers",
      },
    ],
    options: [
      { type: "txt", value: "I only" },
      { type: "txt", value: "II only" },
      { type: "txt", value: "I and III only" },
      { type: "txt", value: "I, II, and III" },
    ],
    answer: 2,
  },
  {
    id: 125,
    question: [
      {
        type: "txt",
        value:
          "Let ⨍ be a real-valued function defined and continuous on the set of real numbers ℝ. Which of the following must be true of the set   S={⨍c:0<c<1?}  I. S is a connected subset of ℝ   II. S is an open subset of ℝ  III. S is a bounded subset of ℝ",
      },
    ],
    options: [
      { type: "txt", value: "I only" },
      { type: "txt", value: "I and II only" },
      { type: "txt", value: "I and III only" },
      { type: "txt", value: "II and III only" },
      { type: "txt", value: "I, II, and III" },
    ],
    answer: 2,
  },
  {
    id: 126,
    question: [
      {
        type: "txt",
        value:
          "A cyclic group of order 15 has an element x such that the set  {x³, x⁵, x⁹}  has exactly two elements  The number of elements in the set",
      },
      { type: "img", value: "/quiz-images-2/GRE/img304.png" },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "5" },
      { type: "txt", value: "8" },
      { type: "txt", value: "15" },
      { type: "txt", value: "Infinite" },
    ],
    answer: 0,
  },
  {
    id: 127,
    question: [
      {
        type: "txt",
        value:
          "If S is a ring with the property that s=s² for each s∈S, which of the following must be true?  I. s+s=0 for each s∈S   II. (s+t)²=s²+t² for each s, t∈S   III. S is commutative",
      },
    ],
    options: [
      { type: "txt", value: "III only" },
      { type: "txt", value: "I and II only" },
      { type: "txt", value: "I and III only" },
      { type: "txt", value: "II and III only" },
      { type: "txt", value: "I, II, and III" },
    ],
    answer: 4,
  },
  {
    id: 128,
    question: [
      { type: "txt", value: "What is the greatest integer that divides" },
      { type: "img", value: "/quiz-images-2/GRE/img305.png" },
      { type: "txt", value: "for every prime number p greater than 5?" },
    ],
    options: [
      { type: "txt", value: "12" },
      { type: "txt", value: "30" },
      { type: "txt", value: "48" },
      { type: "txt", value: "120" },
      { type: "txt", value: "240" },
    ],
    answer: 4,
  },
  {
    id: 129,
    question: [
      {
        type: "txt",
        value: "The coefficient of x³ in the expansion of (1+x)³(2+x²)¹⁰ is ",
      },
    ],
    options: [
      { type: "txt", value: "2¹⁴" },
      { type: "txt", value: "31" },
      { type: "img", value: "/quiz-images-2/GRE/img306.png" },
      { type: "img", value: "/quiz-images-2/GRE/img307.png" },
      { type: "img", value: "/quiz-images-2/GRE/img308.png" },
    ],
    answer: 0,
  },
  {
    id: 130,
    question: [
      {
        type: "txt",
        value: "At how many points in the xy-plane do the graphs of y=x¹² and",
      },
      { type: "img", value: "/quiz-images-2/GRE/img309.png" },
      { type: "txt", value: "intersect?" },
    ],
    options: [
      { type: "txt", value: "None" },
      { type: "txt", value: "One" },
      { type: "txt", value: "Two" },
      { type: "txt", value: "Three" },
      { type: "txt", value: "Four" },
    ],
    answer: 3,
  },
  {
    id: 131,
    question: [
      {
        type: "txt",
        value:
          "Suppose that ⨍ is a continuous real-valued function defined on the closed interval [0,1]. Which of the following must be true?  I. There is a constant C>0 such that |⨍x-⨍(y)| ≤C  for all x and y in [0,1]   II. There is a constant D>0 such that |⨍x-⨍(y)|≤1  for all x and y in [0,1] that satisfy [x-y]≤D  III. There is a constant E>0 such that |⨍x-⨍(y)|≤E |x-y|  for all x and y in [0,1]",
      },
      { type: "img", value: "/quiz-images-2/GRE/img310.png" },
    ],
    options: [
      { type: "txt", value: "I only " },
      { type: "txt", value: "III only" },
      { type: "txt", value: "I and II only" },
      { type: "txt", value: "II and III only" },
      { type: "txt", value: "I, II, and III" },
    ],
    answer: 2,
  },
  {
    id: 132,
    question: [
      {
        type: "txt",
        value:
          "Let p(x) be the polynomial x³+ax²+bx +c, where a, b, and c are real constants. If p(-3)=p(2)=0 and p`(-3)<0, which of the following is a possible value pf c?",
      },
    ],
    options: [
      { type: "txt", value: "-27" },
      { type: "txt", value: "-18" },
      { type: "txt", value: "-6" },
      { type: "txt", value: "-3" },
      { type: "img", value: "/quiz-images-2/GRE/img310.png" },
    ],
    answer: 0,
  },
  {
    id: 133,
    question: [
      {
        type: "txt",
        value:
          "In the xy-plane, if C is the circle x²+y²=9, oriented counterclockwise, then ",
      },
      { type: "img", value: "/quiz-images-2/GRE/img311.png" },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "txt", value: "6π" },
      { type: "txt", value: "9π" },
      { type: "txt", value: "12π" },
      { type: "txt", value: "18π" },
    ],
    answer: 4,
  },
];

const GMAT = [
  {
    id: 0,
    question: [
      {
        type: "txt",
        value: "What is the area of the circle shown above with center O?",
      },
      { type: "img", value: "/quiz-images-2/GMAT/img1.png" },
      {
        type: "txt",
        value:
          "What is the area of the circle shown above with center O?  I. W is the mid-point of chord XY.  II. The ratio of ZW to OW is 3:5",
      },
    ],
    options: [
      {
        type: "txt",
        value:
          "Statement I alone is sufficient but statement II alone is not sufficient to answer the question asked.",
      },
      {
        type: "txt",
        value:
          "Statement II alone is sufficient but statement I alone is not sufficient to answer the question asked.",
      },
      {
        type: "txt",
        value:
          "Both statements I and II together are sufficient to answer the question but neither statement is sufficient alone.",
      },
      {
        type: "txt",
        value: "Each statement alone is sufficient to answer the question.",
      },
      {
        type: "txt",
        value:
          "Statements I and II are not sufficient to answer the question asked and additional data is needed to answer the statements.",
      },
    ],
    answer: 4,
  },
  {
    id: 1,
    question: [
      {
        type: "txt",
        value:
          "If 𝐴⁴ + 𝐵⁴ = 100, then the greatest possible value of “A” lies between",
      },
    ],
    options: [
      { type: "txt", value: "0 and 3" },
      { type: "txt", value: "3 and 6" },
      { type: "txt", value: "6 and 9" },
      { type: "txt", value: "9 and 12" },
      { type: "txt", value: "12 and 15" },
    ],
    answer: 1,
  },
  {
    id: 2,
    question: [
      { type: "img", value: "/quiz-images-2/GMAT/img2.png" },
      {
        type: "txt",
        value:
          "In the above figure, if 𝑦 + 𝑧 = 280°, what is the degree measure of angle x? ",
      },
    ],
    options: [
      { type: "txt", value: "120" },
      { type: "txt", value: "100" },
      { type: "txt", value: "90" },
      { type: "txt", value: "80" },
      { type: "txt", value: "60" },
    ],
    answer: 1,
  },
  {
    id: 3,
    question: [
      {
        type: "txt",
        value:
          "In a circus company the price of tickets for adult and children were $50 and $30 respectively. The company has sold a total of 1000 tickets. The average (arithmetic mean) price per ticket sold was $42. How many tickets were sold for children?",
      },
    ],
    options: [
      { type: "txt", value: "200" },
      { type: "txt", value: "300" },
      { type: "txt", value: "400" },
      { type: "txt", value: "600" },
      { type: "txt", value: "800" },
    ],
    answer: 2,
  },
  {
    id: 4,
    question: [
      {
        type: "txt",
        value:
          "There are two vessels. In the first vessels, the ratio of milk to water is 1:2 and in the second vessel the milk and water are in the ratio 2:3. In what ratio the contents in two vessels must be mixed such that the resulting mixture will have milk and water in the ratio 5:8?",
      },
    ],
    options: [
      { type: "txt", value: "1:3" },
      { type: "txt", value: "3:10" },
      { type: "txt", value: "3:5" },
      { type: "txt", value: "10:3" },
      { type: "txt", value: "Cannot be determined" },
    ],
    answer: 1,
  },
  {
    id: 5,
    question: [
      {
        type: "txt",
        value:
          "A chemical factory produces two kinds of unnatural amino acids: acid A and acid B. Of the acids produced by the factory last year, 1/3 were acid A and the rest were acid B. If it takes 2/5 as many hours to produce acid B per unit as it does to produce acid A per unit, then the number of hours it took to produce the acid B last year was what fraction of the total number of hours it took to produce all the acids?",
      },
    ],
    options: [
      { type: "txt", value: "2/5" },
      { type: "txt", value: "4/9" },
      { type: "txt", value: "17/35" },
      { type: "txt", value: "1/2" },
      { type: "txt", value: "5/9" },
    ],
    answer: 1,
  },
  {
    id: 6,
    question: [
      {
        type: "txt",
        value:
          "The figure above represents a picture set in a square wooden frame that is “p” inches wide on all sides. If the combined area of picture and the frame is equal to “q” square inches, then in terms of p and q, what is the perimeter of the picture?",
      },
      { type: "img", value: "/quiz-images-2/GMAT/img3.png" },
    ],
    options: [
      { type: "txt", value: "−8𝑝 + 4𝑞" },
      { type: "txt", value: "2𝑝 + 2𝑞" },
      { type: "img", value: "/quiz-images-2/GMAT/img4.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img5.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img6.png" },
    ],
    answer: 4,
  },
  {
    id: 7,
    question: [
      {
        type: "txt",
        value:
          "Is parallelogram PQRS a rhombus?   I. PQ=QR=RS=SP   II. The line segments SQ and RP are perpendicular bisectors of each other.",
      },
    ],
    options: [
      {
        type: "txt",
        value:
          "Statement I alone is sufficient but statement II alone is not sufficient to answer the question asked.",
      },
      {
        type: "txt",
        value:
          "Statement II alone is sufficient but statement I alone is not sufficient to answer the question asked.",
      },
      {
        type: "txt",
        value:
          "Both statements I and II together are sufficient to answer the question but neither statement is sufficient alone.",
      },
      {
        type: "txt",
        value: "Each statement alone is sufficient to answer the question.",
      },
      {
        type: "txt",
        value:
          "Statements I and II are not sufficient to answer the question asked and additional data is needed to answer the statements.",
      },
    ],
    answer: 3,
  },
  {
    id: 8,
    question: [
      {
        type: "txt",
        value:
          "If 𝑦 − 𝑥 > 𝑥 + 𝑦, where “x” and “y” are integers, which of the following must be true?  I. 𝑥 < 0   II. 𝑦 > 0   III. 𝑥𝑦 > 0",
      },
    ],
    options: [
      { type: "txt", value: "I only" },
      { type: "txt", value: "II only" },
      { type: "txt", value: "I and II only" },
      { type: "txt", value: "I and III only" },
      { type: "txt", value: "II and III only." },
    ],
    answer: 0,
  },
  {
    id: 9,
    question: [
      {
        type: "txt",
        value: "If p < x < q and r < y < s, is x > y?   I. p < r  II. q < r",
      },
    ],
    options: [
      {
        type: "txt",
        value:
          "Statement I alone is sufficient but statement II alone is not sufficient to answer the question asked.",
      },
      {
        type: "txt",
        value:
          "Statement II alone is sufficient but statement I alone is not sufficient to answer the question asked.",
      },
      {
        type: "txt",
        value:
          "Both statements I and II together are sufficient to answer the question but neither statement is sufficient alone.",
      },
      {
        type: "txt",
        value: "Each statement alone is sufficient to answer the question.",
      },
      {
        type: "txt",
        value:
          "Statements I and II are not sufficient to answer the question asked and additional data is needed to answer the statements.",
      },
    ],
    answer: 1,
  },
  {
    id: 10,
    question: [
      {
        type: "txt",
        value:
          "A book shop sold a set of harry potter book series to a book collector for 40 percent more than the store had originally paid for the books. When the collector tried to resell the books to the store, the store bought it back at 50 percent of what the book collector had paid. The shop then sold the book again at a profit of 70 percent on its buy-back price. If the difference between the series of book's original cost to the shop and the book's buy-back price was $100, for approximately how much did the shop sell the books the second time?",
      },
    ],
    options: [
      { type: "txt", value: "600" },
      { type: "txt", value: "567" },
      { type: "txt", value: "396" },
      { type: "txt", value: "333" },
      { type: "txt", value: "330" },
    ],
    answer: 2,
  },
  {
    id: 11,
    question: [
      {
        type: "txt",
        value:
          "By what percent was the price of a certain Tab discounted for a sale?  I. The price of the tab was sold with a discount of $50.  II. The price of the tab before it was discounted for the sale was 25 percent greater than the discounted price.",
      },
    ],
    options: [
      {
        type: "txt",
        value:
          "Statement I alone is sufficient but statement II alone is not sufficient to answer the question asked.",
      },
      {
        type: "txt",
        value:
          "Statement II alone is sufficient but statement I alone is not sufficient to answer the question asked.",
      },
      {
        type: "txt",
        value:
          "Both statements I and II together are sufficient to answer the question but neither statement is sufficient alone.",
      },
      {
        type: "txt",
        value: "Each statement alone is sufficient to answer the question.",
      },
      {
        type: "txt",
        value:
          "Statements I and II are not sufficient to answer the question asked and additional data is needed to answer the statements.",
      },
    ],
    answer: 1,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value:
          "The colored roses in the bouquet of flowers are red, yellow and pink. The ratio of the number of red to the number of yellow to the number of Pink in the bouquet is 7:4:6, respectively. If there are more than 7 yellow-colored roses, what is the minimum number of total roses in the bouquet?",
      },
    ],
    options: [
      { type: "txt", value: "8" },
      { type: "txt", value: "12" },
      { type: "txt", value: "14" },
      { type: "txt", value: "24" },
      { type: "txt", value: "34" },
    ],
    answer: 4,
  },
  {
    id: 13,
    question: [
      {
        type: "txt",
        value:
          "If Polygon A has fewer than 10 sides and the sum of the interior angles of polygon A is divisible by 16, how many sides does Polygon A have?",
      },
    ],
    options: [
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
      { type: "txt", value: "7" },
      { type: "txt", value: "8" },
    ],
    answer: 2,
  },
  {
    id: 14,
    question: [
      { type: "img", value: "/quiz-images-2/GMAT/img7.png" },
      {
        type: "txt",
        value:
          "In the figure above PRS is a triangle, what is the measure of the angle PSQ?   I. QS=QR=1   II. PR=2",
      },
    ],
    options: [
      {
        type: "txt",
        value:
          "Statement I alone is sufficient but statement II alone is not sufficient to answer the question asked.",
      },
      {
        type: "txt",
        value:
          "Statement II alone is sufficient but statement I alone is not sufficient to answer the question asked.",
      },
      {
        type: "txt",
        value:
          "Both statements I and II together are sufficient to answer the question but neither statement is sufficient alone.",
      },
      {
        type: "txt",
        value: "Each statement alone is sufficient to answer the question.",
      },
      {
        type: "txt",
        value:
          "Statements I and II are not sufficient to answer the question asked and additional data is needed to answer the statements.",
      },
    ],
    answer: 2,
  },
  {
    id: 15,
    question: [
      { type: "img", value: "/quiz-images-2/GMAT/img8.png" },
      {
        type: "txt",
        value:
          "In the diagram above, triangle PQR has a right angle at Q. If PQ > QR, then what is the ratio of the area of triangle PQS to the area of triangle RQS?  I. Line segment QS is perpendicular to PR and has a length of 12.   II. PQR has a perimeter of 60.",
      },
    ],
    options: [
      {
        type: "txt",
        value:
          "Statement I alone is sufficient but statement II alone is not sufficient to answer the question asked.",
      },
      {
        type: "txt",
        value:
          "Statement II alone is sufficient but statement I alone is not sufficient to answer the question asked.",
      },
      {
        type: "txt",
        value:
          "Both statements I and II together are sufficient to answer the question but neither statement is sufficient alone.",
      },
      {
        type: "txt",
        value: "Each statement alone is sufficient to answer the question.",
      },
      {
        type: "txt",
        value:
          "Statements I and II are not sufficient to answer the question asked and additional data is needed to answer the statements.",
      },
    ],
    answer: 2,
  },
  {
    id: 16,
    question: [
      {
        type: "txt",
        value:
          "In a certain show, a lottery ticket is numbered consecutively from 100 through 999(both inclusive). What is the probability that a randomly selected ticket will have a number with a ten’s digit as “3”?",
      },
    ],
    options: [
      { type: "txt", value: "1/5" },
      { type: "txt", value: "90/899" },
      { type: "txt", value: "1/10" },
      { type: "txt", value: "1/11" },
      { type: "txt", value: "10/111" },
    ],
    answer: 2,
  },
  {
    id: 17,
    question: [
      {
        type: "txt",
        value:
          "In a certain linguistics school, there are totally 250 students. Of those 250 students, 40 percent study French as a foreign language, 30 percent study German as a foreign language and 50 percent study Spanish as a foreign language. If 10 students study all these three foreign languages and 10 students didn’t choose these three foreign languages, then how many students are studying in exactly two of these foreign languages?",
      },
    ],
    options: [
      { type: "txt", value: "20" },
      { type: "txt", value: "30" },
      { type: "txt", value: "40" },
      { type: "txt", value: "50" },
      { type: "txt", value: "60" },
    ],
    answer: 1,
  },
  {
    id: 18,
    question: [
      {
        type: "txt",
        value:
          "The interior of a rectangular box is designed by a certain manufacturer to have a volume of “m” cubic feet and ratio of length to width to height of 5:3:2. In term of “m”, which of the following equals the length of the box in feet?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GMAT/img9.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img10.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img11.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img12.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img13.png" },
    ],
    answer: 0,
  },
  {
    id: 19,
    question: [
      {
        type: "txt",
        value:
          "Lines “l” and “k” are perpendicular to each other. And line “l” passes through points (4,1) and (8,-1). What is the equation of the line “k” which passes through the point (3,1)?",
      },
    ],
    options: [
      { type: "txt", value: "2y – x = 5" },
      { type: "txt", value: "2x – y = 5" },
      { type: "txt", value: "y – 2x = 5" },
      { type: "txt", value: "y + 2x = 5" },
      { type: "txt", value: "2y + x = 5" },
    ],
    answer: 1,
  },
  {
    id: 20,
    question: [
      {
        type: "txt",
        value:
          "A certain cafeteria sells donuts and pizzas. Is the number of people who bought donuts are more than the number of people who bought pizzas?  I. Of the people who bought donuts, 30 percent of them also bought pizzas.  II. Of the people who bought pizzas, 40 percent of them also bought donuts.",
      },
    ],
    options: [
      {
        type: "txt",
        value:
          "Statement I alone is sufficient but statement II alone is not sufficient to answer the question asked.",
      },
      {
        type: "txt",
        value:
          "Statement II alone is sufficient but statement I alone is not sufficient to answer the question asked.",
      },
      {
        type: "txt",
        value:
          "Both statements I and II together are sufficient to answer the question but neither statement is sufficient alone.",
      },
      {
        type: "txt",
        value: "Each statement alone is sufficient to answer the question.",
      },
      {
        type: "txt",
        value:
          "Statements I and II are not sufficient to answer the question asked and additional data is needed to answer the statements.",
      },
    ],
    answer: 2,
  },
  {
    id: 21,
    question: [
      {
        type: "txt",
        value:
          "Alan purchased pens and pencils at a certain shop, where each pen costs 3 dollars and each pencil cost 2 dollars. What is the total number of pen and pencils Alan purchased?  I. Alan bought pen and pencils for the total cost of 10 dollars.  II. Total cost of the pens which Allan bought is less than 10 dollars.",
      },
    ],
    options: [
      {
        type: "txt",
        value:
          "Statement I alone is sufficient but statement II alone is not sufficient to answer the question asked.",
      },
      {
        type: "txt",
        value:
          "Statement II alone is sufficient but statement I alone is not sufficient to answer the question asked.",
      },
      {
        type: "txt",
        value:
          "Both statements I and II together are sufficient to answer the question but neither statement is sufficient alone.",
      },
      {
        type: "txt",
        value: "Each statement alone is sufficient to answer the question.",
      },
      {
        type: "txt",
        value:
          "Statements I and II are not sufficient to answer the question asked and additional data is needed to answer the statements.",
      },
    ],
    answer: 0,
  },
  {
    id: 22,
    question: [
      {
        type: "txt",
        value:
          "Water is pumped into the completely empty tank at a constant rate through an inlet pipe. At the same time, there is a leak at the bottom of the tank which leaks water at a constant rate. How long it will take the tank get filled completely?  I. Total capacity of water the tank can hold is 120 gallons.   II. Inlet pipe can completely fill the empty tank in 10 hours if there is no leak in the tank, and also the leak at the bottom of the tank can completely empty the filled tank in 15 hours if there is no water pumped into the tank.",
      },
    ],
    options: [
      {
        type: "txt",
        value:
          "Statement I alone is sufficient but statement II alone is not sufficient to answer the question asked.",
      },
      {
        type: "txt",
        value:
          "Statement II alone is sufficient but statement I alone is not sufficient to answer the question asked.",
      },
      {
        type: "txt",
        value:
          "Both statements I and II together are sufficient to answer the question but neither statement is sufficient alone.",
      },
      {
        type: "txt",
        value: "Each statement alone is sufficient to answer the question.",
      },
      {
        type: "txt",
        value:
          "Statements I and II are not sufficient to answer the question asked and additional data is needed to answer the statements.",
      },
    ],
    answer: 1,
  },
  {
    id: 23,
    question: [
      { type: "txt", value: "If ‘x’ is a number such that" },
      { type: "img", value: "/quiz-images-2/GMAT/img14.png" },
      {
        type: "txt",
        value: ", which of the following can be the value of ‘x’?",
      },
    ],
    options: [
      { type: "txt", value: "3.5" },
      { type: "txt", value: "3.0" },
      { type: "txt", value: "2.4" },
      { type: "txt", value: "1.6" },
      { type: "txt", value: "0.8" },
    ],
    answer: 3,
  },
  {
    id: 24,
    question: [
      { type: "txt", value: "If p² is an integer and" },
      { type: "img", value: "/quiz-images-2/GMAT/img15.png" },
      { type: "txt", value: "what is the value of “p²”?" },
      { type: "img", value: "/quiz-images-2/GMAT/img16.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img17.png" },
      {
        type: "txt",
        value:
          "Statement I alone is sufficient but statement II alone is not sufficient to answer the question asked.",
      },
    ],
    options: [
      {
        type: "txt",
        value:
          "Statement II alone is sufficient but statement I alone is not sufficient to answer the question asked.",
      },
      {
        type: "txt",
        value:
          "Both statements I and II together are sufficient to answer the question but neither statement is sufficient alone.",
      },
      {
        type: "txt",
        value: "Each statement alone is sufficient to answer the question.",
      },
      {
        type: "txt",
        value:
          "Statements I and II are not sufficient to answer the question asked and additional data is needed to answer the statements.",
      },
    ],
    answer: 3,
  },
  {
    id: 25,
    question: [
      {
        type: "txt",
        value:
          "If “P” is a positive integer, is 𝑃⁴ + 7 an odd number?  I. “P” is the smallest integer such that is divisible by all the integers from 51 to 55, inclusive.   II. ",
      },
      { type: "img", value: "/quiz-images-2/GMAT/img18.png" },
      { type: "txt", value: "is an odd number." },
    ],
    options: [
      {
        type: "txt",
        value:
          "Statement I alone is sufficient but statement II alone is not sufficient to answer the question asked.",
      },
      {
        type: "txt",
        value:
          "Statement II alone is sufficient but statement I alone is not sufficient to answer the question asked.",
      },
      {
        type: "txt",
        value:
          "Both statements I and II together are sufficient to answer the question but neither statement is sufficient alone.",
      },
      {
        type: "txt",
        value: "Each statement alone is sufficient to answer the question.",
      },
      {
        type: "txt",
        value:
          "Statements I and II are not sufficient to answer the question asked and additional data is needed to answer the statements.",
      },
    ],
    answer: 0,
  },
  {
    id: 26,
    question: [
      {
        type: "txt",
        value:
          "If ‘m’ is a positive integer, is “m²+1” when divided by 10 leaves remainder ZERO?  I. 101¹⁶*m, when divided by 2 leaves a remainder 1.   II. 101¹⁶*m, when divided by 5 leaves a remainder 2.",
      },
    ],
    options: [
      {
        type: "txt",
        value:
          "Statement I alone is sufficient but statement II alone is not sufficient to answer the question asked.",
      },
      {
        type: "txt",
        value:
          "Statement II alone is sufficient but statement I alone is not sufficient to answer the question asked.",
      },
      {
        type: "txt",
        value:
          "Both statements I and II together are sufficient to answer the question but neither statement is sufficient alone.",
      },
      {
        type: "txt",
        value: "Each statement alone is sufficient to answer the question.",
      },
      {
        type: "txt",
        value:
          "Statements I and II are not sufficient to answer the question asked and additional data is needed to answer the statements.",
      },
    ],
    answer: 2,
  },
  {
    id: 27,
    question: [
      { type: "txt", value: "If “x” is a positive integer, is 𝑥 > 3?  I. " },
      { type: "img", value: "/quiz-images-2/GMAT/img19.png" },
      { type: "txt", value: "II. " },
      { type: "img", value: "/quiz-images-2/GMAT/img20.png" },
    ],
    options: [
      {
        type: "txt",
        value:
          "Statement I alone is sufficient but statement II alone is not sufficient to answer the question asked.",
      },
      {
        type: "txt",
        value:
          "Statement II alone is sufficient but statement I alone is not sufficient to answer the question asked.",
      },
      {
        type: "txt",
        value:
          "Both statements I and II together are sufficient to answer the question but neither statement is sufficient alone.",
      },
      {
        type: "txt",
        value: "Each statement alone is sufficient to answer the question.",
      },
      {
        type: "txt",
        value:
          "Statements I and II are not sufficient to answer the question asked and additional data is needed to answer the statements.",
      },
    ],
    answer: 4,
  },
  {
    id: 28,
    question: [
      {
        type: "txt",
        value:
          "If “p” is completely divisible by the number 17, and p=x²*y, where “x” and “y” are distinct prime numbers, which of these numbers must be divisible by 289?",
      },
    ],
    options: [
      { type: "txt", value: "x²" },
      { type: "txt", value: "y²" },
      { type: "txt", value: "xy" },
      { type: "txt", value: "x²y²" },
      { type: "txt", value: "x³y" },
    ],
    answer: 3,
  },
  {
    id: 29,
    question: [
      {
        type: "txt",
        value:
          "List A has seven integers; whose range is 80 and median is 240. The median for the three smallest integers in List A is 180. What is the possible range for the largest three integers in the List A?  I. 75  II. 24   III. 0 ",
      },
    ],
    options: [
      { type: "txt", value: "I only" },
      { type: "txt", value: "II only" },
      { type: "txt", value: "I and III only" },
      { type: "txt", value: "II and III only" },
      { type: "txt", value: "III only" },
    ],
    answer: 4,
  },
  {
    id: 30,
    question: [
      {
        type: "txt",
        value:
          "In a list A = p, 24, 24, 24, 28, 20, 16; Is “p” positive?   I. The mean of list A is lesser than the mode of list A.   II. The range of list A is lesser than the mode of list A.",
      },
    ],
    options: [
      {
        type: "txt",
        value:
          "Statement I alone is sufficient but statement II alone is not sufficient to answer the question asked.",
      },
      {
        type: "txt",
        value:
          "Statement II alone is sufficient but statement I alone is not sufficient to answer the question asked.",
      },
      {
        type: "txt",
        value:
          "Both statements I and II together are sufficient to answer the question but neither statement is sufficient alone.",
      },
      {
        type: "txt",
        value: "Each statement alone is sufficient to answer the question.",
      },
      {
        type: "txt",
        value:
          "Statements I and II are not sufficient to answer the question asked and additional data is needed to answer the statements.",
      },
    ],
    answer: 1,
  },
  {
    id: 31,
    question: [
      {
        type: "txt",
        value: "Suppose ⨍ is a differentiable function for which",
      },
      { type: "img", value: "/quiz-images-2/GMAT/img21.png" },
      {
        type: "txt",
        value:
          "both exist and are finite. Which of the following must be true?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GMAT/img22.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img24.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img25.png" },
      { type: "txt", value: "⨍ is a constant function " },
      { type: "txt", value: "⨍` is a constant function" },
    ],
    answer: 0,
  },
  {
    id: 32,
    question: [
      {
        type: "txt",
        value: "In xyz-space, an equation of the plane tangent to the surface",
      },
      { type: "img", value: "/quiz-images-2/GMAT/img26.png" },
      { type: "txt", value: "sin y at the point where x=0 and " },
      { type: "img", value: "/quiz-images-2/GMAT/img27.png" },
      { type: "txt", value: "is" },
    ],
    options: [
      { type: "txt", value: "x+y=1" },
      { type: "txt", value: "x+z=1" },
      { type: "txt", value: "x-z=1" },
      { type: "txt", value: "y+z=1" },
      { type: "txt", value: "y-z=1" },
    ],
    answer: 1,
  },
  {
    id: 33,
    question: [
      {
        type: "txt",
        value:
          "For each real number x, let μ(x) be the mean of the numbers 4,9,7,5, and x; and let ƞ(x) be the median of these five numbers. For how many values of x is μ(x) = ƞ(x)?",
      },
    ],
    options: [
      { type: "txt", value: "None" },
      { type: "txt", value: "One" },
      { type: "txt", value: "Two " },
      { type: "txt", value: "Three" },
      { type: "txt", value: "Infinitely many" },
    ],
    answer: 3,
  },
  {
    id: 34,
    question: [{ type: "img", value: "/quiz-images-2/GMAT/img28.png" }],
    options: [
      { type: "txt", value: "e" },
      { type: "txt", value: "2e" },
      { type: "txt", value: "(e+1)(e-1)" },
      { type: "txt", value: "e²" },
      { type: "txt", value: "∞" },
    ],
    answer: 1,
  },
  {
    id: 35,
    question: [
      {
        type: "txt",
        value: "Which of the following integrals on the interval",
      },
      { type: "img", value: "/quiz-images-2/GMAT/img29.png" },
      { type: "txt", value: "has the greatest value?" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GMAT/img30.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img31.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img32.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img33.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img34.png" },
    ],
    answer: 1,
  },
  {
    id: 36,
    question: [
      {
        type: "txt",
        value: "In xyz-space, an equation of the plane tangent to the surface",
      },
      { type: "img", value: "/quiz-images-2/GMAT/img35.png" },
      { type: "txt", value: "sin y at the point where x=0 and" },
      { type: "img", value: "/quiz-images-2/GMAT/img36.png" },
    ],
    options: [
      { type: "txt", value: "x+y=1" },
      { type: "txt", value: "x+z=1" },
      { type: "txt", value: "x-z=1" },
      { type: "txt", value: "y+z=1" },
      { type: "txt", value: "y-z=1" },
    ],
    answer: 1,
  },
  {
    id: 37,
    question: [
      {
        type: "txt",
        value:
          "For each real number x, let μ(x) be the mean of the numbers 4,9,7,5, and x; and let ƞ(x) be the median of these five numbers. For how many values of x is μ(x)=ƞ(x)?",
      },
    ],
    options: [
      { type: "txt", value: "None" },
      { type: "txt", value: "One" },
      { type: "txt", value: "Two" },
      { type: "txt", value: "Three" },
      { type: "txt", value: "Infinitely many" },
    ],
    answer: 3,
  },
  {
    id: 38,
    question: [{ type: "img", value: "/quiz-images-2/GMAT/img37.png" }],
    options: [
      { type: "txt", value: "e" },
      { type: "txt", value: "2e" },
      { type: "txt", value: "(e+1)(e-1)" },
      { type: "txt", value: "e²" },
      { type: "txt", value: "∞" },
    ],
    answer: 1,
  },
  {
    id: 39,
    question: [
      {
        type: "txt",
        value: "Which of the following integrals on the interval",
      },
      { type: "img", value: "/quiz-images-2/GMAT/img38.png" },
      { type: "txt", value: "has the greatest value?" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GMAT/img39.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img40.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img41.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img42.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img43.png" },
    ],
    answer: 1,
  },
  {
    id: 40,
    question: [
      { type: "txt", value: "Consider the function ⨍ defined by " },
      { type: "img", value: "/quiz-images-2/GMAT/img44.png" },
      { type: "txt", value: "on the interval [0,10]. Let n>1 and let" },
      { type: "img", value: "/quiz-images-2/GMAT/img45.png" },
      { type: "txt", value: "be numbers such that" },
      { type: "img", value: "/quiz-images-2/GMAT/img46.png" },
      { type: "txt", value: "Which of the following is greatest?" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GMAT/img47.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img48.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img49.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img50.png" },
      { type: "txt", value: "0" },
    ],
    answer: 1,
  },
  {
    id: 41,
    question: [
      {
        type: "txt",
        value:
          "A fair coin is to be tossed 8 times. What is the probability that more of the tosses will result in heads than will result in tails?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GMAT/img51.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img52.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img53.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img54.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img55.png" },
    ],
    answer: 4,
  },
  {
    id: 42,
    question: [
      {
        type: "txt",
        value:
          "The function ⨍(x,y) = xy-x³-y³ has a relative maximum at the point ",
      },
    ],
    options: [
      { type: "txt", value: "(0,0)" },
      { type: "txt", value: "(1,1)" },
      { type: "txt", value: "(-1,-1)" },
      { type: "txt", value: "(1,3)" },
      { type: "img", value: "/quiz-images-2/GMAT/img56.png" },
    ],
    answer: 4,
  },
  {
    id: 43,
    question: [
      {
        type: "txt",
        value:
          "Consider the points A=(-1,2), B=(6,4), and C=(1, -20) in the plane. For how many different points D in the plane are A, B, C, and D the vertices of a  parallelogram?",
      },
    ],
    options: [
      { type: "txt", value: "None" },
      { type: "txt", value: "One" },
      { type: "txt", value: "Two" },
      { type: "txt", value: "Three" },
      { type: "txt", value: "Four" },
    ],
    answer: 3,
  },
  {
    id: 44,
    question: [
      { type: "txt", value: "If A is a 3x3 matrix such that A" },
      { type: "img", value: "/quiz-images-2/GMAT/img57.png" },
      { type: "txt", value: "then the product A" },
      { type: "img", value: "/quiz-images-2/GMAT/img58.png" },
      { type: "txt", value: "is" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GMAT/img59.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img60.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img61.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img62.png" },
    ],
    answer: 1,
  },
  {
    id: 45,
    question: [
      {
        type: "txt",
        value: "Let ⨍ denote the function defined for all x>0 by",
      },
      { type: "img", value: "/quiz-images-2/GMAT/img63.png" },
      { type: "txt", value: "Which of the following statements is FALSE?" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GMAT/img64.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img65.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img66.png" },
      { type: "txt", value: "The derivative ⨍`(x) is positive for all x>0" },
      { type: "txt", value: "The derivative ⨍`(x) is increasing for all x>0" },
    ],
    answer: 3,
  },
  {
    id: 46,
    question: [
      {
        type: "txt",
        value:
          "An experimental car is found to have a fuel efficiency E(v), in miles per gallon of fuel, where v is the speed of the car, in miles per hour. For a certain 4-hour trip, if v=v(t) is the speed of the car t hours after the trip started, which of the following integrals represents the number of gallons of fuel that the car used on the trip?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GMAT/img67.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img68.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img69.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img70.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img71.png" },
    ],
    answer: 0,
  },
  {
    id: 47,
    question: [
      { type: "txt", value: "For 0<t<π, the matrix" },
      { type: "img", value: "/quiz-images-2/GMAT/img72.png" },
      { type: "txt", value: "has distinct complex eigenvalues" },
      { type: "img", value: "/quiz-images-2/GMAT/img73.png" },
      { type: "txt", value: "For what value of t, 0<t<π, is" },
      { type: "img", value: "/quiz-images-2/GMAT/img74.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GMAT/img75.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img76.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img77.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img78.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img79.png" },
    ],
    answer: 2,
  },
  {
    id: 48,
    question: [
      {
        type: "txt",
        value:
          "Let x and y be uniformly distributed, independent random variables on [0,1]. The probability that the distance between x and y is less than",
      },
      { type: "img", value: "/quiz-images-2/GMAT/img80.png" },
      { type: "txt", value: "is" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GMAT/img81.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img82.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img83.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img84.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img85.png" },
    ],
    answer: 4,
  },
  {
    id: 49,
    question: [
      {
        type: "txt",
        value:
          "Consider tha change of variables from the xy-plane to the uv-plane given by the equations",
      },
      { type: "img", value: "/quiz-images-2/GMAT/img86.png" },
      {
        type: "txt",
        value:
          "v=1+y  Under this transformation, the image of the region {x,y:0≤x≤1 and 0≤y≤1}  is which of the following shaded regions?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GMAT/img87.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img88.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img89.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img90.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img91.png" },
    ],
    answer: 0,
  },
  {
    id: 50,
    question: [
      {
        type: "txt",
        value:
          "If ⨍ is a continuous function on the set of real numbers and if a and b are real numbers , which of the following must be true? ",
      },
      { type: "img", value: "/quiz-images-2/GMAT/img92.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img93.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img94.png" },
    ],
    options: [
      { type: "txt", value: "I only" },
      { type: "txt", value: "II only" },
      { type: "txt", value: "I and II only" },
      { type: "txt", value: "II and III only" },
      { type: "txt", value: "I, II, and III " },
    ],
    answer: 4,
  },
  {
    id: 51,
    question: [
      {
        type: "txt",
        value:
          "How many continuous real- valued functions ⨍ are there with domain {-1, 1} such that",
      },
      { type: "img", value: "/quiz-images-2/GMAT/img95.png" },
      { type: "txt", value: "for each x in [-1,1]?" },
    ],
    options: [
      { type: "txt", value: "One" },
      { type: "txt", value: "Two" },
      { type: "txt", value: "Three" },
      { type: "txt", value: "Four" },
      { type: "txt", value: "Infinitely many" },
    ],
    answer: 3,
  },
  {
    id: 52,
    question: [
      {
        type: "txt",
        value: "Let D be the region in the xy-plane in which the series",
      },
      { type: "img", value: "/quiz-images-2/GMAT/img96.png" },
      { type: "txt", value: "converges Then the interior of D is" },
    ],
    options: [
      { type: "txt", value: "an open disk" },
      { type: "txt", value: "the open region bounded by an ellipse" },
      { type: "txt", value: "the open region bounded by a quadrilateral" },
      { type: "txt", value: "the open region between two parallel lines" },
      { type: "txt", value: "an open half plane" },
    ],
    answer: 3,
  },
  {
    id: 53,
    question: [
      {
        type: "txt",
        value:
          "Consider the following system of linear equations over the real numbers, where x,y, and z are variables and b is a real constant  x+y+z=0 x+2y+3y=0  x+3y+bz=0  which of the following statements are true?  I. There exists a valua of b for which the system has no solution   II. There exists a value of b for which the system has one solution   III. There exists a value of b for which the system has more one solution",
      },
    ],
    options: [
      { type: "txt", value: "II only" },
      { type: "txt", value: "I and II only" },
      { type: "txt", value: "I and III only" },
      { type: "txt", value: "II and III only" },
      { type: "txt", value: "I,II, and III" },
    ],
    answer: 3,
  },
  {
    id: 54,
    question: [
      {
        type: "txt",
        value:
          "In the complex plane, let C be the circle |z|=2 with positive (counterclockwise) orientation. Then ",
      },
      { type: "img", value: "/quiz-images-2/GMAT/img97.png" },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "txt", value: "2πi" },
      { type: "img", value: "/quiz-images-2/GMAT/img98.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img99.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img100.png" },
    ],
    answer: 3,
  },
  {
    id: 55,
    question: [
      {
        type: "txt",
        value:
          "The insider of a certain water tank is a cube measuring 10feet on each edge and having vertical sides and no top. Let h(t) denote the water level, in feet, above the floor of the tank at time t seconds. Starting at time t=0, water pours into the tank at a constant rate of 1 cubic foot per second, and simultaneously, water is removed from the tank at a rate of 0.25h(t) cubic feet per second. As t, what is the limit of the volume of the water in the tank?",
      },
    ],
    options: [
      { type: "txt", value: "400 cubic feet" },
      { type: "txt", value: "600 cubic feet" },
      { type: "txt", value: "1,000 cubic feet" },
      { type: "txt", value: "the limit does not exist" },
      {
        type: "txt",
        value:
          "the limit exists, but it cannot be determined without knowingh(0)",
      },
    ],
    answer: 0,
  },
  {
    id: 56,
    question: [
      {
        type: "txt",
        value:
          "Suppose that ⨍ is a twice-differentiable function on the set of real numbers and that ⨍(0), ⨍`(0), and ⨍``(0) are all negative. Suppose ⨍`` has all three of the following properties. I. It is increasing on the interval [0,∞)  II.  It has a unique zero in the interval [0,∞)  III. It is unbounded on the interval [0,∞)   Which of the same three properties does ⨍ necessarily have?",
      },
    ],
    options: [
      { type: "txt", value: "I only" },
      { type: "txt", value: "II only" },
      { type: "txt", value: "III only" },
      { type: "txt", value: "II and III only" },
      { type: "txt", value: "I, II, and III" },
    ],
    answer: 3,
  },
  {
    id: 57,
    question: [
      {
        type: "txt",
        value:
          "For every set S and every metric d on S, which of the following is a metric on S?",
      },
    ],
    options: [
      { type: "txt", value: "4+d" },
      { type: "img", value: "/quiz-images-2/GMAT/img101.png" },
      { type: "txt", value: "d−|d|" },
      { type: "txt", value: "d²" },
      { type: "img", value: "/quiz-images-2/GMAT/img102.png" },
    ],
    answer: 4,
  },
  {
    id: 58,
    question: [
      {
        type: "txt",
        value:
          "Let R be the field of real numbers and ℝ x the ring of polynomials in x with coefficients in ℝ. Which of the following subsets of ℝ x is a subring of ℝ x?  I. All polynomials whose coefficient of x is zero   II. All polynomials whose degree is an even integer, together with the zero polynomial   III. All polynomials whose coefficients are rational numbers",
      },
    ],
    options: [
      { type: "txt", value: "I only" },
      { type: "txt", value: "II only" },
      { type: "txt", value: "I and III only" },
      { type: "txt", value: "I, II, and III" },
    ],
    answer: 2,
  },
  {
    id: 59,
    question: [
      {
        type: "txt",
        value:
          "Let ⨍ be a real-valued function defined and continuous on the set of real numbers ℝ. Which of the following must be true of the set S={⨍c:0<c<1}?  I. S is a connected subset of ℝ   II. S is an open subset of ℝ   III. S is a bounded subset of ℝ",
      },
    ],
    options: [
      { type: "txt", value: "I only" },
      { type: "txt", value: "I and II only" },
      { type: "txt", value: "I and III only" },
      { type: "txt", value: "II and III only" },
      { type: "txt", value: "I, II, and III" },
    ],
    answer: 2,
  },
  {
    id: 60,
    question: [
      {
        type: "txt",
        value:
          "A cyclic group of order 15 has an element x such that the set {x³, x⁵, x⁹} has exactly two elements The number of elements in the set",
      },
      { type: "img", value: "/quiz-images-2/GMAT/img103.png" },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "5" },
      { type: "txt", value: "8" },
      { type: "txt", value: "15" },
      { type: "txt", value: "Infinite" },
    ],
    answer: 0,
  },
  {
    id: 61,
    question: [
      {
        type: "txt",
        value:
          "If S is a ring with the property that s=s² for each sS, which of the following must be true? I.s+s=0 for each s∈S   II. (s+t)²=s²+t² for each s, t∈S  III. S is commutative",
      },
    ],
    options: [
      { type: "txt", value: "III only" },
      { type: "txt", value: "I and II only" },
      { type: "txt", value: "I and III only" },
      { type: "txt", value: "II and III only" },
      { type: "txt", value: "I, II, and III" },
    ],
    answer: 4,
  },
  {
    id: 62,
    question: [
      {
        type: "txt",
        value:
          "What is the greatest integer that divides p⁴-1 for every prime number p greater than 5?",
      },
    ],
    options: [
      { type: "txt", value: "12" },
      { type: "txt", value: "30" },
      { type: "txt", value: "48" },
      { type: "txt", value: "120" },
      { type: "txt", value: "240" },
    ],
    answer: 4,
  },
  {
    id: 63,
    question: [
      {
        type: "txt",
        value: "The coefficient of x³  in the expansion of (1+x)³(2+x²)¹⁰ is ",
      },
    ],
    options: [
      { type: "txt", value: "2¹⁴" },
      { type: "txt", value: "31" },
      { type: "img", value: "/quiz-images-2/GMAT/img104.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img105.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img106.png" },
    ],
    answer: 0,
  },
  {
    id: 64,
    question: [
      {
        type: "txt",
        value: "At how many points in the xy-plane do the graphs of y=x¹² and",
      },
      { type: "img", value: "/quiz-images-2/GMAT/img107.png" },
      { type: "txt", value: "intersect?" },
    ],
    options: [
      { type: "txt", value: "None" },
      { type: "txt", value: "One" },
      { type: "txt", value: "Two" },
      { type: "txt", value: "Three" },
      { type: "txt", value: "Four" },
    ],
    answer: 3,
  },
  {
    id: 65,
    question: [
      {
        type: "txt",
        value:
          "Suppose that ⨍ is a continuous real-valued function defined on the closed interval [0,1]. Which of the following must be true?     I. There is a constant C>0 such that |⨍x-⨍(y)| ≤C  for all x and y in [0,1]      II. There is a constant D>0 such that |⨍x-⨍(y)|≤1  for all x and y in [0,1] that satisfy |x-y|≤D    III. There is a constant E>0 such that |⨍x-⨍(y)|≤E |x-y|  for all x and y in [0,1]",
      },
    ],
    options: [
      { type: "txt", value: "I only" },
      { type: "txt", value: "III only" },
      { type: "txt", value: "I and II only" },
      { type: "txt", value: "II and III only" },
      { type: "txt", value: "I, II, and III" },
    ],
    answer: 2,
  },
  {
    id: 66,
    question: [
      {
        type: "txt",
        value:
          "Let p(x) be the polynomial x³+ax²+bx +c, where a, b, and c are real constants. If p(-3)=p(2)=0 and p`(-3)<0, which of the following is a possible value pf c?",
      },
    ],
    options: [
      { type: "txt", value: "-27" },
      { type: "txt", value: "-18" },
      { type: "txt", value: "-6" },
      { type: "txt", value: "-3" },
      { type: "img", value: "/quiz-images-2/GMAT/img108.png" },
    ],
    answer: 0,
  },
  {
    id: 67,
    question: [
      {
        type: "txt",
        value:
          "In the xy-plane, if C is the circle x²+y²=9, oriented counterclockwise, then",
      },
      { type: "img", value: "/quiz-images-2/GMAT/img109.png" },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "txt", value: "6π" },
      { type: "txt", value: "9π" },
      { type: "txt", value: "12π" },
      { type: "txt", value: "18π" },
    ],
    answer: 4,
  },
  {
    id: 68,
    question: [
      { type: "txt", value: "Let C denote an arbitrary constant. Then" },
      { type: "img", value: "/quiz-images-2/GMAT/img110.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GMAT/img111.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img112.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img113.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img114.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img115.png" },
    ],
    answer: 0,
  },
  {
    id: 69,
    question: [{ type: "img", value: "/quiz-images-2/GMAT/img116.png" }],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "6" },
      { type: "txt", value: "8" },
    ],
    answer: 4,
  },
  {
    id: 70,
    question: [
      {
        type: "txt",
        value:
          "Let r and A be the radius and the area, respectively, of a circle. If r increases by 40 percent will A increase?",
      },
    ],
    options: [
      { type: "txt", value: "40%" },
      { type: "txt", value: "49%" },
      { type: "txt", value: "80%" },
      { type: "txt", value: "96%" },
      { type: "txt", value: "130%" },
    ],
    answer: 3,
  },
  {
    id: 71,
    question: [
      {
        type: "txt",
        value:
          "If differentiable function y(x) satisfies the equation  x+y⁴=10 for ",
      },
      { type: "img", value: "/quiz-images-2/GMAT/img117.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GMAT/img118.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img119.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img120.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img121.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img122.png" },
    ],
    answer: 0,
  },
  {
    id: 72,
    question: [
      {
        type: "txt",
        value:
          "Let g be a differentiable function on R, and let h be function defined by",
      },
      { type: "img", value: "/quiz-images-2/GMAT/img123.png" },
      {
        type: "txt",
        value:
          "for all x∈R. Which of the following is equal to h’(x) for all x∈R?",
      },
    ],
    options: [
      { type: "txt", value: "g(x²)" },
      { type: "txt", value: "2xg`(x²)" },
      { type: "txt", value: "2 xg(x²)" },
      { type: "txt", value: "g`(x²)-g`(0) " },
      { type: "txt", value: "2xg(x²)-g(0)" },
    ],
    answer: 2,
  },
  {
    id: 73,
    question: [
      {
        type: "txt",
        value:
          "Let ⨍⨍x=x for all real numbers x in the domain of ⨍. Which of the following could be the grap of ⨍ in the xy-plane?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GMAT/img124.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img125.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img126.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img127.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img128.png" },
    ],
    answer: 3,
  },
  {
    id: 74,
    question: [
      { type: "txt", value: "If a and b are positive numbers, then" },
      { type: "img", value: "/quiz-images-2/GMAT/img129.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GMAT/img130.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img131.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img132.png" },
      { type: "txt", value: "1" },
      { type: "txt", value: "∞" },
    ],
    answer: 1,
  },
  {
    id: 75,
    question: [
      { type: "txt", value: "If the function" },
      { type: "img", value: "/quiz-images-2/GMAT/img133.png" },
      { type: "txt", value: "for all positive numbers x except x=1, and if" },
      { type: "img", value: "/quiz-images-2/GMAT/img134.png" },
      { type: "txt", value: "for what value of a is" },
      { type: "img", value: "/quiz-images-2/GMAT/img135.png" },
      { type: "txt", value: "continuous x=1 ?" },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "img", value: "/quiz-images-2/GMAT/img136.png" },
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "There is no such vaule." },
    ],
    answer: 1,
  },
  {
    id: 76,
    question: [
      {
        type: "txt",
        value:
          "Of the 10 lightbulbs in a box, 3 are defective. If 12 lightbuls are to be chosen from the box at random and without replacement, what is the probability that at least 1 of the 2 lightbubls will be defective?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GMAT/img137.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img138.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img139.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img140.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img141.png" },
    ],
    answer: 2,
  },
  {
    id: 77,
    question: [
      { type: "txt", value: "In the power series expansion " },
      { type: "img", value: "/quiz-images-2/GMAT/img142.png" },
      { type: "txt", value: "where |x|<1, what are the coefficients " },
      { type: "img", value: "/quiz-images-2/GMAT/img143.png" },
    ],
    options: [
      { type: "txt", value: "1, 1 and 1" },
      { type: "txt", value: "1, 2 and 3" },
      { type: "txt", value: "1, 2 and 6" },
      { type: "txt", value: "2, 4 and 6" },
      { type: "img", value: "/quiz-images-2/GMAT/img144.png" },
    ],
    answer: 1,
  },
  {
    id: 78,
    question: [
      { type: "img", value: "/quiz-images-2/GMAT/img145.png" },
      { type: "txt", value: "Which of the following statements is true?" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GMAT/img146.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img147.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img148.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img149.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img150.png" },
    ],
    answer: 4,
  },
  {
    id: 79,
    question: [
      {
        type: "txt",
        value:
          "Let p and q be prime numbers, where 2 < p < q, and let M be the set of all positive integers n such that n⁵ is divisible by p³ and by 64q¹¹. What is the least integer in M?",
      },
    ],
    options: [
      { type: "txt", value: "2pq" },
      { type: "txt", value: "4p³q" },
      { type: "txt", value: "4pq³" },
      { type: "txt", value: "4p³q³" },
      { type: "txt", value: "32pq¹⁰" },
    ],
    answer: 2,
  },
  {
    id: 80,
    question: [
      {
        type: "txt",
        value:
          "Let G be a graph such every two distinct vertices in G are connected by exactly one edge and every edge connects two distinct vertices. If G has 190 edges, how many vertices does G have?",
      },
    ],
    options: [
      { type: "txt", value: "18" },
      { type: "txt", value: "19" },
      { type: "txt", value: "20" },
      { type: "txt", value: "94" },
      { type: "txt", value: "95" },
    ],
    answer: 2,
  },
  {
    id: 81,
    question: [
      {
        type: "txt",
        value:
          "Suppose ⨍ is a real-valued differentiable function defined on the interval (0,2) such that ⨍`(x)=x²-x+1 on (0,2). Which of the following statements must be true?",
      },
    ],
    options: [
      { type: "txt", value: "If 0 < x < 2, then ⨍ (x) =⨍(2-x)" },
      { type: "txt", value: "If 0 < x < 2, then ⨍ (x) =-⨍(2-x)" },
      { type: "txt", value: "If 0 < x < y < 2, then ⨍ (x) < ⨍(y)" },
      { type: "txt", value: "The graph of ⨍is concave upward on (0,2)" },
      { type: "txt", value: "The graph of ⨍is concave downward on (0,2)" },
    ],
    answer: 2,
  },
  {
    id: 82,
    question: [
      {
        type: "txt",
        value:
          "If a and b are positive numbers, which of the following is a necessary and sufficient condition for the system of equations x²+y²=a xy=b to have at least one solution (x, y), where x and y are real?",
      },
    ],
    options: [
      { type: "txt", value: "a≥2b" },
      { type: "txt", value: "2a≤b" },
      { type: "txt", value: "a≥b²" },
      { type: "txt", value: "a≤b²" },
      { type: "txt", value: "a≤2b²" },
    ],
    answer: 0,
  },
  {
    id: 83,
    question: [
      {
        type: "txt",
        value:
          "Suppose that X and  Y are sets, ⨍ : X→Y is a function, A and B are subsets of X, and C is a subset of B. Which of the following must be true? ",
      },
      { type: "img", value: "/quiz-images-2/GMAT/img151.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img152.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img153.png" },
    ],
    options: [
      { type: "txt", value: "None" },
      { type: "txt", value: "III only" },
      { type: "txt", value: "I and II only" },
      { type: "txt", value: "I and III only" },
      { type: "txt", value: "I, II, and III" },
    ],
    answer: 3,
  },
  {
    id: 84,
    question: [
      {
        type: "txt",
        value: "Which of the following statements about sequences",
      },
      { type: "img", value: "/quiz-images-2/GMAT/img154.png" },
      { type: "txt", value: "of real numbers is FALSE?" },
    ],
    options: [
      { type: "txt", value: "Every unbounded sequence is divergent." },
      { type: "txt", value: "Every bounded sequence is convergent." },
      { type: "img", value: "/quiz-images-2/GMAT/img155.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img156.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img157.png" },
    ],
    answer: 1,
  },
  {
    id: 85,
    question: [
      { type: "img", value: "/quiz-images-2/GMAT/img158.png" },
      {
        type: "txt",
        value:
          "The graph of the derivative g` of a function g with domain [0,7] is shown above. Which of the following must be true about g?   I. g has a local maximum value at x=0 and a local minimum value at x=4    II. g has a local maximum value at x=2  and a local minimum value at x=5    III. g(2) = g(5)",
      },
    ],
    options: [
      { type: "txt", value: "I only" },
      { type: "txt", value: "II only" },
      { type: "txt", value: "III only" },
      { type: "txt", value: "I and II" },
      { type: "txt", value: "II and III" },
    ],
    answer: 1,
  },
  {
    id: 86,
    question: [
      { type: "txt", value: "In the xy-plane, what is the point on the curve" },
      { type: "img", value: "/quiz-images-2/GMAT/img159.png" },
      { type: "txt", value: "that is closest to the origin?" },
    ],
    options: [
      { type: "txt", value: "(-3, 0)" },
      { type: "img", value: "/quiz-images-2/GMAT/img160.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img161.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img162.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img163.png" },
    ],
    answer: 2,
  },
  {
    id: 87,
    question: [
      {
        type: "txt",
        value:
          "If ⨍ and g are twice-differentiable functions of a real variable, then the second derivative of the composition ⨍ ° g is given by which of the following?",
      },
    ],
    options: [
      { type: "txt", value: "⨍`` ° g``" },
      { type: "txt", value: "(⨍`` ° g) (g`)²" },
      { type: "txt", value: "(⨍`` ° g)+(2⨍`° g`)+(⨍ ° g``)" },
      { type: "txt", value: "(⨍`` ° g)(g`)²+(⨍` ° g)g``" },
      { type: "txt", value: "(⨍`` ° g)g`+(⨍` ° g`)(g`)²" },
    ],
    answer: 3,
  },
  {
    id: 88,
    question: [
      {
        type: "txt",
        value:
          "If x and y are integers such that 3x+2y≡5 (mod 13) and x+7y≡1(mod 13), then 5x+3y is congruent modulo 13 to",
      },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "5" },
      { type: "txt", value: "7" },
      { type: "txt", value: "10" },
      { type: "txt", value: "11" },
    ],
    answer: 2,
  },
  {
    id: 89,
    question: [
      {
        type: "txt",
        value:
          "Consider triangle ABC with sides AB =6 ,   AC = 8,  BC =12. Let P be a point on side AB such that AP = 4, and let Q be a point on side AC such that angles APQ and ACB are congruent. What is the length of line segment PQ?",
      },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
      { type: "txt", value: "8" },
    ],
    answer: 3,
  },
  {
    id: 90,
    question: [
      { type: "img", value: "/quiz-images-2/GMAT/img168.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img169.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img170.png" },
      {
        type: "txt",
        value: "In the system of equations in the real variables",
      },
      { type: "img", value: "/quiz-images-2/GMAT/img171.png" },
      {
        type: "txt",
        value:
          "above, a,b, and c are real constans. What is the set of all (a,b,c)∈R³   for which the system is consistent?",
      },
    ],
    options: [
      { type: "txt", value: "{a,b,c:a=b=c}" },
      { type: "txt", value: "{a,b,c:a+b+=-1c}" },
      { type: "txt", value: "{a,b,c:a+b+c=0}" },
      { type: "txt", value: "{a,b,c:a+b+c=1}" },
      { type: "txt", value: "R³" },
    ],
    answer: 4,
  },
  {
    id: 91,
    question: [
      {
        type: "txt",
        value:
          "Which of the following sets of vectors is a spanning set for the null space of the real matrix",
      },
      { type: "img", value: "/quiz-images-2/GMAT/img172.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GMAT/img173.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img174.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img175.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img176.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img177.png" },
    ],
    answer: 1,
  },
  {
    id: 92,
    question: [
      { type: "img", value: "/quiz-images-2/GMAT/img178.png" },
      {
        type: "txt",
        value:
          "The graph of the function ⨍ (x) = A cos ( ωx-∅) is shown in the xy-plane above, where A,ω, and ∅ are nonnegative constants. Which of the following could be the value of ∅?",
      },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "img", value: "/quiz-images-2/GMAT/img179.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img180.png" },
      { type: "txt", value: "π" },
      { type: "img", value: "/quiz-images-2/GMAT/img181.png" },
    ],
    answer: 3,
  },
  {
    id: 93,
    question: [
      { type: "txt", value: "If" },
      { type: "img", value: "/quiz-images-2/GMAT/img182.png" },
      { type: "txt", value: "is the function defined by" },
      { type: "img", value: "/quiz-images-2/GMAT/img183.png" },
      { type: "txt", value: "what is the value of" },
      { type: "img", value: "/quiz-images-2/GMAT/img184.png" },
    ],
    options: [
      { type: "txt", value: "2+π" },
      { type: "txt", value: "4+π" },
      { type: "txt", value: "6+π" },
      { type: "txt", value: "2+2π" },
      { type: "txt", value: "6+2π" },
    ],
    answer: 2,
  },
  {
    id: 94,
    question: [
      {
        type: "txt",
        value:
          "Let P, Q, and R be logical propositions. Consider the statement ``If P is true, then Q is true and R is true.``   Which of the following is the negation of the statement?",
      },
    ],
    options: [
      { type: "txt", value: "P is true, and Q is false or R is false." },
      { type: "txt", value: "P is false or Q is true or R is true." },
      { type: "txt", value: "P is false or Q is false or R is false." },
      { type: "txt", value: "If Q is true and R is true, then P is true." },
      { type: "txt", value: "If P is false, then Q is false or R is false." },
    ],
    answer: 0,
  },
  {
    id: 95,
    question: [
      { type: "txt", value: "The value of the omproper integral" },
      { type: "img", value: "/quiz-images-2/GMAT/img185.png" },
      { type: "txt", value: "What is the value of" },
      { type: "img", value: "/quiz-images-2/GMAT/img186.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GMAT/img187.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img188.png" },
      { type: "txt", value: "1" },
      { type: "img", value: "/quiz-images-2/GMAT/img189.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img190.png" },
    ],
    answer: 0,
  },
  {
    id: 96,
    question: [
      { type: "txt", value: "For all real values of x, let" },
      { type: "img", value: "/quiz-images-2/GMAT/img191.png" },
      { type: "txt", value: "be the function defined by" },
      { type: "img", value: "/quiz-images-2/GMAT/img191.png" },
      { type: "txt", value: "(x) =" },
      { type: "img", value: "/quiz-images-2/GMAT/img192.png" },
      { type: "txt", value: "On which of the following intervals is" },
      { type: "img", value: "/quiz-images-2/GMAT/img191.png" },
      { type: "txt", value: "increasing?" },
    ],
    options: [
      { type: "txt", value: "(-π, 0)" },
      { type: "img", value: "/quiz-images-2/GMAT/img193.png" },
      { type: "txt", value: "(0, π)" },
      { type: "img", value: "/quiz-images-2/GMAT/img194.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img195.png" },
    ],
    answer: 1,
  },
  {
    id: 97,
    question: [
      {
        type: "txt",
        value:
          "Which of the following best represents the graph of the curve c : [0,2π]→ R²   given by c(t) = (cos³t ,sin³t) in the xy-plane?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GMAT/img196.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img197.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img198.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img199.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img200.png" },
    ],
    answer: 2,
  },
  {
    id: 98,
    question: [
      {
        type: "txt",
        value:
          "Four cubes, each with faces numbered 1 to 6, are to be rolled. When each cube is rolled, each number is equally likely to appear on its top face. What is the probability that at least two of the cubes will have the same number appear on their top faces?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GMAT/img201.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img202.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img203.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img204.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img205.png" },
    ],
    answer: 1,
  },
  {
    id: 99,
    question: [
      {
        type: "txt",
        value:
          "Which of the following sets of complex numbers is NOT a group under multiplication?",
      },
    ],
    options: [
      { type: "txt", value: "{a+b: a  and  b  are positive rational numbers}" },
      {
        type: "txt",
        value: "{a+bi:  a   and  b are real numbers such that a²+b²≠0 }",
      },
      {
        type: "txt",
        value: "{a+bi:  a  and  b   are integers such that a²+b²=1}",
      },
      {
        type: "txt",
        value: "{a+bi:  a  and  b  are rational numbers such that a²+b²=1 }",
      },
      {
        type: "txt",
        value: "{a+bi:  a  and  b  are real numbers such that a²+b²=1}",
      },
    ],
    answer: 0,
  },
  {
    id: 100,
    question: [
      {
        type: "txt",
        value:
          "A spherical tank of radius 5 meters contains water that is slowly draining out. At the instant that measurements are taken, the maximum depth of the water in the tank is 2 meters, and the depth is decreasing by",
      },
      { type: "img", value: "/quiz-images-2/GMAT/img206.png" },
      {
        type: "txt",
        value:
          "meter per second. What is the rate of decrease of the volume of the water, in cubic meters per second, at that instant?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/GMAT/img207.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img208.png" },
      { type: "txt", value: "22π" },
      { type: "img", value: "/quiz-images-2/GMAT/img209.png" },
      { type: "img", value: "/quiz-images-2/GMAT/img210.png" },
    ],
    answer: 0,
  },
];

const SAT_no_calculator = [
  {
    id: 0,
    question: [
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img1.png" },
      { type: "txt", value: "and k=3, what is the value of x?" },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "4" },
      { type: "txt", value: "9" },
      { type: "txt", value: "10" },
    ],
    answer: 3,
  },
  {
    id: 1,
    question: [
      {
        type: "txt",
        value: "For i =−1 , what is the sum (7 + 3i) + (−8 + 9i)?",
      },
    ],
    options: [
      { type: "txt", value: "−1 + 12i" },
      { type: "txt", value: "−1 − 6i" },
      { type: "txt", value: "15 + 12i" },
      { type: "txt", value: "15 − 6i" },
    ],
    answer: 0,
  },
  {
    id: 2,
    question: [
      {
        type: "txt",
        value:
          "On Saturday afternoon, Armand sent m text messages each hour for 5 hours, and Tyrone sent p text messages each hour for 4 hours. Which of the following represents the total number of messages sent by Armand and Tyrone on Saturday afternoon?",
      },
    ],
    options: [
      { type: "txt", value: "9mp" },
      { type: "txt", value: "20mp" },
      { type: "txt", value: "5m + 4p" },
      { type: "txt", value: "4m + 5p" },
    ],
    answer: 2,
  },
  {
    id: 3,
    question: [
      {
        type: "txt",
        value:
          "Kathy is a repair technician for a phone company. Each week, she receives a batch of phones that need repairs. The number of phones that she has left to fix at the end of each day can be estimated with the equation P = 108 − 23d, where P is the number of phones left and d is the number of days she has worked that week. What is the meaning of the value 108 in this equation?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "Kathy will complete the repairs within 108 days.",
      },
      { type: "txt", value: "Kathy starts each week with 108 phones to fix." },
      { type: "txt", value: "Kathy repairs phones at a rate of 108 per hour." },
      { type: "txt", value: "Kathy repairs phones at a rate of 108 per day." },
    ],
    answer: 1,
  },
  {
    id: 4,
    question: [
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img2.png" },
      {
        type: "txt",
        value: "Which of the following is equivalent to the expression above?",
      },
    ],
    options: [
      { type: "txt", value: "4x²y²" },
      { type: "txt", value: "8xy²-6y²" },
      { type: "txt", value: "2x²y+2xy²" },
      { type: "txt", value: "2x²y+8xy²-6y²" },
    ],
    answer: 2,
  },
  {
    id: 5,
    question: [
      {
        type: "txt",
        value:
          "h=3a+28.6    A pediatrician uses the model above to estimate the height h of a boy, in inches, in terms of the boy`s age a, in years, between the ages of 2 and 5. Based on the model, what is the estimated increase, in inches, of a boy`s height each year?",
      },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "5.7" },
      { type: "txt", value: "9.5" },
      { type: "txt", value: "14.3" },
    ],
    answer: 0,
  },
  {
    id: 6,
    question: [
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img3.png" },
      {
        type: "txt",
        value:
          "The formula above gives the monthly payment m needed to pay off a loan of P dollars at r percent annual interest over N months. Which of the folloeing give P in terms of m, r, and N?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img4.png" },
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img5.png" },
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img6.png" },
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img7.png" },
    ],
    answer: 1,
  },
  {
    id: 7,
    question: [
      { type: "txt", value: "If 2, what is the value of" },
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img8.png" },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "4" },
    ],
    answer: 2,
  },
  {
    id: 8,
    question: [
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img9.png" },
      {
        type: "txt",
        value: "What is the solution (x,y) to the system of equations above?",
      },
    ],
    options: [
      { type: "txt", value: "(-5, -2)" },
      { type: "txt", value: "(3, -8)" },
      { type: "txt", value: "(4, -6)" },
      { type: "txt", value: "(9, -6)" },
    ],
    answer: 1,
  },
  {
    id: 9,
    question: [
      {
        type: "txt",
        value:
          "g(x)=ax²ax²+24 For the function g defined above, a is a constant and g(4) =8. What is the value of g(-4)?",
      },
    ],
    options: [
      { type: "txt", value: "8" },
      { type: "txt", value: "0" },
      { type: "txt", value: "-1" },
      { type: "txt", value: "-8" },
    ],
    answer: 0,
  },
  {
    id: 10,
    question: [
      {
        type: "txt",
        value:
          "b=2.35+0.25x        c=1.75+0.40x     In the equations above, b and c represent the price per pound, in dollars, of beef and chicken, respectively, x weeks after July 1 during last summer. What was the price per pound of beef when it was equal to the price per pound of chicken?",
      },
    ],
    options: [
      { type: "txt", value: "$2.60" },
      { type: "txt", value: "$2.85" },
      { type: "txt", value: "$2.95" },
      { type: "txt", value: "$3.35" },
    ],
    answer: 3,
  },
  {
    id: 11,
    question: [
      {
        type: "txt",
        value:
          "A line in the xy-plane passes through the origin and has a slope of",
      },
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img10.png" },
      { type: "txt", value: "Which of the following points lies on the line?" },
    ],
    options: [
      { type: "txt", value: "(0,7)" },
      { type: "txt", value: "(1,7)" },
      { type: "txt", value: "(7,7)" },
      { type: "txt", value: "(14,2)" },
    ],
    answer: 3,
  },
  {
    id: 12,
    question: [
      { type: "txt", value: "If x>3, which of the following is equivalent to" },
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img11.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img12.png" },
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img13.png" },
      { type: "txt", value: "2x+5" },
      { type: "txt", value: "x²+5x+6" },
    ],
    answer: 1,
  },
  {
    id: 13,
    question: [
      { type: "txt", value: "If 3x-y=12, what is the value of" },
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img14.png" },
    ],
    options: [
      { type: "txt", value: "2¹²" },
      { type: "txt", value: "4⁴" },
      { type: "txt", value: "8²" },
      {
        type: "txt",
        value: "The value cannot be determined from the information given.",
      },
    ],
    answer: 0,
  },
  {
    id: 14,
    question: [
      {
        type: "txt",
        value:
          " If (ax+2)(bx+7) =15x²+cx+14 for all value of x, and a+b=8, what are the two possible values for c?",
      },
    ],
    options: [
      { type: "txt", value: "3 and 5" },
      { type: "txt", value: "6 and 35" },
      { type: "txt", value: "10 and 21" },
      { type: "txt", value: "31 and 41" },
    ],
    answer: 3,
  },
  {
    id: 15,
    question: [
      { type: "txt", value: "If t>0 and t²-4=0, what is the value of t?" },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "1" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 0,
  },
  {
    id: 16,
    question: [
      {
        type: "txt",
        value:
          "A summer camp conselor wants to find a length, x, in feet, across a lake as represented in the sketch above. The lengths represented by AB, EB, BD, and CD on the sketch were determined to be 1800 feet, 1400 feet, 700 feet, and 800 feet, respectively. Segments AC and DE intersect at B, and ∠AEB and ∠CDB have the same measure. What is the value of x? ",
      },
    ],
    options: [
      { type: "txt", value: "1600" },
      { type: "txt", value: "1400" },
      { type: "txt", value: "1500" },
      { type: "txt", value: "1700" },
    ],
    answer: 0,
  },
  {
    id: 17,
    question: [
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img15.png" },
      {
        type: "txt",
        value:
          "According to the system of equations above, what is the value of x ?",
      },
    ],
    options: [
      { type: "txt", value: "7" },
      { type: "txt", value: "5" },
      { type: "txt", value: "8" },
      { type: "txt", value: "6" },
    ],
    answer: 0,
  },
  {
    id: 18,
    question: [
      { type: "txt", value: "In a right triangle, one angle measures" },
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img16.png" },
      { type: "txt", value: "where" },
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img17.png" },
      { type: "txt", value: "What is" },
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img18.png" },
    ],
    options: [
      { type: "txt", value: "6 or 0.8" },
      { type: "txt", value: "4/5 or 0.8" },
      { type: "txt", value: "4 or 0.7" },
      { type: "txt", value: "4/5 or 0.9" },
    ],
    answer: 1,
  },
  {
    id: 19,
    question: [
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img19.png" },
      { type: "txt", value: "what is the value of x?" },
    ],
    options: [
      { type: "txt", value: "100" },
      { type: "txt", value: "110" },
      { type: "txt", value: "90" },
      { type: "txt", value: "120" },
    ],
    answer: 0,
  },
  {
    id: 20,
    question: [
      {
        type: "txt",
        value:
          "A painter will paint n walls with the same size and shape in a building using a specific the same size and shape in a building using a specific brand of paint. The painter`s fee can be calculated by the expression nKℓh, where n is the number of walls, K is a constant with units of dollars per square foot, ℓ is the length of each wall in feet, and h is the height of each wall in feet. If the customer asks the painter to use a more expensive brand of paint, which of the factors in the expression would change?",
      },
    ],
    options: [
      { type: "txt", value: "h" },
      { type: "txt", value: "ℓ" },
      { type: "txt", value: "K" },
      { type: "txt", value: "n" },
    ],
    answer: 2,
  },
  {
    id: 21,
    question: [{ type: "txt", value: "If 3r=18, what is the value of 6r+3?" }],
    options: [
      { type: "txt", value: "6" },
      { type: "txt", value: "27" },
      { type: "txt", value: "36" },
      { type: "txt", value: "39" },
    ],
    answer: 3,
  },
  {
    id: 22,
    question: [
      { type: "txt", value: "Which of the following is equal to" },
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img20.png" },
      { type: "txt", value: "for all values of a ?" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img21.png" },
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img22.png" },
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img23.png" },
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img24.png" },
    ],
    answer: 3,
  },
  {
    id: 23,
    question: [
      {
        type: "txt",
        value:
          "The number of states that joined the United States between 1776 and 1849 is twice the number of states that joined between 1850 and 1900. If 30 states joined the United States between 1776 and 1849 and x states joined between 1850 and 1900, which of the following equations is true?",
      },
    ],
    options: [
      { type: "txt", value: "30x=2" },
      { type: "txt", value: "2x=30" },
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img25.png" },
      { type: "txt", value: "x+30=2" },
    ],
    answer: 2,
  },
  {
    id: 24,
    question: [
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img26.png" },
      { type: "txt", value: "what is the value of" },
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img27.png" },
    ],
    options: [
      { type: "txt", value: "10" },
      { type: "txt", value: "5" },
      { type: "txt", value: "2" },
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img28.png" },
    ],
    answer: 2,
  },
  {
    id: 25,
    question: [
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img29.png" },
      {
        type: "txt",
        value:
          "If (x,y) is a solution to the system of equations above, what is the value of x-y?",
      },
    ],
    options: [
      { type: "txt", value: "-20" },
      { type: "txt", value: "-8" },
      { type: "txt", value: "-4" },
      { type: "txt", value: "8" },
    ],
    answer: 2,
  },
  {
    id: 26,
    question: [
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img30.png" },
      {
        type: "txt",
        value:
          "The function ⨍ is defined by a polynomial. Some values of x and ⨍ (x) are shown in the table above. Which of the following must be a factor of ⨍ (x)?",
      },
    ],
    options: [
      { type: "txt", value: "x-2" },
      { type: "txt", value: "x-3" },
      { type: "txt", value: "x-4" },
      { type: "txt", value: "x-5" },
    ],
    answer: 2,
  },
  {
    id: 27,
    question: [
      {
        type: "txt",
        value:
          "The line y=kx+4, where k is a constant, is graphed in the xy-plane. If the line contains the point (c,d), where c≠0 and d≠0, what is the slope of the line in terms of c and d?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img31.png" },
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img32.png" },
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img33.png" },
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img34.png" },
    ],
    answer: 0,
  },
  {
    id: 28,
    question: [
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img35.png" },
      {
        type: "txt",
        value:
          "In the system of equations above, k is a constant and x and y are variables. For what value of k will the system of equations have no solution?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img36.png" },
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img37.png" },
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img38.png" },
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img39.png" },
    ],
    answer: 0,
  },
  {
    id: 29,
    question: [
      {
        type: "txt",
        value:
          "In the xy-plane, the parabola with equation y=(x-11)² intersects the line with equation y=25 at two points, A and B. What is the length of",
      },
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img40.png" },
    ],
    options: [
      { type: "txt", value: "10" },
      { type: "txt", value: "12" },
      { type: "txt", value: "14" },
      { type: "txt", value: "16" },
    ],
    answer: 0,
  },
  {
    id: 30,
    question: [
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img41.png" },
      {
        type: "txt",
        value:
          "Note: Figure not drawn to scale.   In the figure above, lines k, ℓ, and m intersect at a point. If x+y=u+w, which of the following must be true?    I. x=z     II. y=w   III. z=t",
      },
    ],
    options: [
      { type: "txt", value: "I and II only" },
      { type: "txt", value: "I and III only" },
      { type: "txt", value: "II and III only" },
      { type: "txt", value: "I, II, and III" },
    ],
    answer: 1,
  },
  {
    id: 31,
    question: [
      {
        type: "txt",
        value:
          "In the quadratic equation above, a is a nonzero constant. The graph of the equation in the xy-plane is a parabola with vertex (c,d). Which of the following is equal to d ?",
      },
    ],
    options: [
      { type: "txt", value: "-9a" },
      { type: "txt", value: "-8a" },
      { type: "txt", value: "-5a" },
      { type: "txt", value: "-2a" },
    ],
    answer: 0,
  },
  {
    id: 32,
    question: [
      { type: "txt", value: "The equation" },
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img42.png" },
      { type: "txt", value: "is true for all values of" },
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img43.png" },
      { type: "txt", value: "where a is a constant. What is the value of a?" },
    ],
    options: [
      { type: "txt", value: "-16" },
      { type: "txt", value: "-3" },
      { type: "txt", value: "3" },
      { type: "txt", value: "16" },
    ],
    answer: 1,
  },
  {
    id: 33,
    question: [
      { type: "txt", value: "What are the solutions to 3x²+12x+6=0?" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img44.png" },
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img45.png" },
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img46.png" },
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img47.png" },
    ],
    answer: 0,
  },
  {
    id: 34,
    question: [
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img48.png" },
      {
        type: "txt",
        value:
          "The equation above shows how a temperature F, measured in degrees Fahrenheit, relates to a temperature C, measured in degrees Celsius. Based on the equation, which of the following must be true?    I. A temperature increase of 1 degree Celsius is equivalent to a temperature increase of ",
      },
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img49.png" },
      {
        type: "txt",
        value:
          "degree Celsius.     II. A temperature increase of 1 degrease of 1 degree Celsius is equivalent to a temperature increase of 1.8 degrees Fahrenheit.   III. A temperature increase of",
      },
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img50.png" },
      {
        type: "txt",
        value:
          "degree Fahrenheit is equivalent to a temperature increase of 1 degree Celsius.",
      },
    ],
    options: [
      { type: "txt", value: "I only" },
      { type: "txt", value: "II only" },
      { type: "txt", value: "III only" },
      { type: "txt", value: "I and II only" },
    ],
    answer: 3,
  },
  {
    id: 35,
    question: [
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img51.png" },
      { type: "txt", value: "what is the value of x?" },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "1" },
      { type: "txt", value: "3" },
      { type: "txt", value: "5" },
    ],
    answer: 0,
  },
  {
    id: 36,
    question: [
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img52.png" },
      {
        type: "txt",
        value:
          "Note: Figure not drawn to scale.   Two isosceles triangles are shown above. If 180-z=2y and y=75, what is the value of x?    ",
      },
    ],
    options: [
      { type: "txt", value: "105" },
      { type: "txt", value: "110" },
      { type: "txt", value: "120" },
      { type: "txt", value: "100" },
    ],
    answer: 0,
  },
  {
    id: 37,
    question: [
      {
        type: "txt",
        value:
          "At a lunch stand, each hamburger has 50 more calories than each order of fries. If 2 hamburgers and 3 orders of fries have a total of 1700 calories, how many calories does a hamburger have?",
      },
    ],
    options: [
      { type: "txt", value: "370" },
      { type: "txt", value: "380" },
      { type: "txt", value: "385" },
      { type: "txt", value: "350" },
    ],
    answer: 0,
  },
  {
    id: 38,
    question: [
      {
        type: "txt",
        value:
          "In triangle ABC, the measure of ∠ B is 90° , BC =16, and AC =20. Triangle DEF is similar to triangle ABC, where vertices D, E, and F correspond to vertices A, B, and C, respectively, and each side of triangle DEF is",
      },
      { type: "img", value: "/quiz-images-2/SAT_no_calc/img53.png" },
      {
        type: "txt",
        value:
          "the length of the corresponding side of triangle ABC. What is the value of sinF?",
      },
    ],
    options: [
      { type: "txt", value: "0.7 or 3/5" },
      { type: "txt", value: "0.8 or 3/7" },
      { type: "txt", value: "1 or 3/5" },
      { type: "txt", value: "0.6 or 3/5" },
    ],
    answer: 3,
  },
];

const SAT_calculator = [
  {
    id: 0,
    question: [
      {
        type: "txt",
        value:
          "John runs at different speeds as part of his training program. The graph shows his target heart rate at different times during his workout. On which interval is the target heart rate strictly increasing then strictly decreasing?",
      },
      { type: "img", value: "/quiz-images-2/SAT_calc/img1.png" },
    ],
    options: [
      { type: "txt", value: "Between 0 and 30 minutes" },
      { type: "txt", value: "Between 40 and 60 minutes" },
      { type: "txt", value: "Between 50 and 65 minutes" },
      { type: "txt", value: "Between 70 and 90 minutes" },
    ],
    answer: 1,
  },
  {
    id: 1,
    question: [
      {
        type: "txt",
        value:
          "If y=kx, where k is a constant, and y=24 when x=6, what is the value of y when x=5?",
      },
    ],
    options: [
      { type: "txt", value: "6" },
      { type: "txt", value: "15" },
      { type: "txt", value: "20" },
      { type: "txt", value: "23" },
    ],
    answer: 2,
  },
  {
    id: 2,
    question: [
      { type: "img", value: "/quiz-images-2/SAT_calc/img2.png" },
      {
        type: "txt",
        value:
          "In the figure above, lines ℓ and m are parallel and lines s and t are parallel. If the measure of ∠1 is 35°, what is the measure of ∠2?",
      },
    ],
    options: [
      { type: "txt", value: "35°" },
      { type: "txt", value: "55°" },
      { type: "txt", value: "70°" },
      { type: "txt", value: "145°" },
    ],
    answer: 3,
  },
  {
    id: 3,
    question: [
      {
        type: "txt",
        value: "If 16+4x is 10 more than 14, what is the value of 8x?",
      },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "6" },
      { type: "txt", value: "16" },
      { type: "txt", value: "80" },
    ],
    answer: 2,
  },
  {
    id: 4,
    question: [
      {
        type: "txt",
        value:
          "Which of the following graphs best shows a stong negative association between a and t?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/SAT_calc/img3.png" },
      { type: "img", value: "/quiz-images-2/SAT_calc/img4.png" },
      { type: "img", value: "/quiz-images-2/SAT_calc/img5.png" },
      { type: "img", value: "/quiz-images-2/SAT_calc/img6.png" },
    ],
    answer: 3,
  },
  {
    id: 5,
    question: [
      { type: "img", value: "/quiz-images-2/SAT_calc/img7.png" },
      {
        type: "txt",
        value:
          "A hospital stores one type of medicine in 2-decagram containers. Based on the information given in the box above, how many 1-milligram doses are there in one 2-decagram container?",
      },
    ],
    options: [
      { type: "txt", value: "0.002" },
      { type: "txt", value: "200" },
      { type: "txt", value: "2,000" },
      { type: "txt", value: "20,000" },
    ],
    answer: 3,
  },
  {
    id: 6,
    question: [
      { type: "img", value: "/quiz-images-2/SAT_calc/img8.png" },
      {
        type: "txt",
        value:
          "The number of rooftops with solar panel installations in 5 cities is shown in the graph above. If the total number of installations is 27,500, what is an appropriate label for the vertical axis of the graph?",
      },
    ],
    options: [
      { type: "txt", value: "Number of installations (in tens)" },
      { type: "txt", value: "Number of installations (in hundreds)" },
      { type: "txt", value: "Number of installations (in thousands)" },
      { type: "txt", value: "Number of installations (in tens of thousands)" },
    ],
    answer: 2,
  },
  {
    id: 7,
    question: [
      { type: "txt", value: "For what value of n is |n-1|+1 equal to 0?" },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "There is no such value of n." },
    ],
    answer: 3,
  },
  {
    id: 8,
    question: [
      {
        type: "txt",
        value:
          "Which of the following expresses the air temperature in terms of the speed of a sound wave?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/SAT_calc/img9.png" },
      { type: "img", value: "/quiz-images-2/SAT_calc/img10.png" },
      { type: "img", value: "/quiz-images-2/SAT_calc/img11.png" },
      { type: "img", value: "/quiz-images-2/SAT_calc/img12.png" },
    ],
    answer: 0,
  },
  {
    id: 9,
    question: [
      {
        type: "txt",
        value:
          "At which of the following air temperatures will the speed of a sound wave be closest to 1,000 feet per second?",
      },
    ],
    options: [
      { type: "txt", value: "-46°F" },
      { type: "txt", value: "-48°F" },
      { type: "txt", value: "-49°F" },
      { type: "txt", value: "-50°F" },
    ],
    answer: 1,
  },
  {
    id: 10,
    question: [
      {
        type: "txt",
        value:
          "Which of the following numbers is NOT a solution of the inequality 3x-5≥4x-3?",
      },
    ],
    options: [
      { type: "txt", value: "-1" },
      { type: "txt", value: "-2" },
      { type: "txt", value: "-3" },
      { type: "txt", value: "-5" },
    ],
    answer: 0,
  },
  {
    id: 11,
    question: [
      { type: "img", value: "/quiz-images-2/SAT_calc/img13.png" },
      {
        type: "txt",
        value:
          "Based on the histogram above, of the following, which is closest to the average (arithmetic mean) numbers of seeds per apple?",
      },
    ],
    options: [
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
      { type: "txt", value: "7" },
    ],
    answer: 2,
  },
  {
    id: 12,
    question: [
      { type: "img", value: "/quiz-images-2/SAT_calc/img14.png" },
      {
        type: "txt",
        value:
          "A group of tenth-grade students responded to a survey that asked which math course they were currently enrolled in. The survey data were broken down as shown in the table above. Which of the following categories accounts for approximately 19 percent of all the survey responndents?",
      },
    ],
    options: [
      { type: "txt", value: "Females taking Geometry" },
      { type: "txt", value: "Females taking Algebra II" },
      { type: "txt", value: "Males taking Geometry" },
      { type: "txt", value: "Males taking Algebra I" },
    ],
    answer: 2,
  },
  {
    id: 13,
    question: [
      { type: "img", value: "/quiz-images-2/SAT_calc/img15.png" },
      {
        type: "txt",
        value:
          "The table above lists the lengths, to the nearest inch, of a random sample of 21 brown bullhead fish. The outlier measurement of 24 inches is an error. Of the mean, median, and range of the values listed, which will change the most if the 24-inch measurement is removed from the data?",
      },
    ],
    options: [
      { type: "txt", value: "Mean" },
      { type: "txt", value: "Median" },
      { type: "txt", value: "Range" },
      { type: "txt", value: "They will all change by the same amount." },
    ],
    answer: 2,
  },
  {
    id: 14,
    question: [
      {
        type: "txt",
        value: "What does the C-intercept represent in the graph?",
      },
    ],
    options: [
      { type: "txt", value: "The initial cost of renting the boat" },
      { type: "txt", value: "The total number of boats rented" },
      { type: "txt", value: "The total number of hours the boat is rented" },
      {
        type: "txt",
        value: "The increase in cost to rent the boat for each additional hour",
      },
    ],
    answer: 0,
  },
  {
    id: 15,
    question: [
      {
        type: "txt",
        value:
          "Which of the following represents the relationship between h and C?",
      },
    ],
    options: [
      { type: "txt", value: "C=5h" },
      { type: "img", value: "/quiz-images-2/SAT_calc/img16.png" },
      { type: "txt", value: "C=3h+5" },
      { type: "txt", value: "h=3C" },
    ],
    answer: 2,
  },
  {
    id: 16,
    question: [
      { type: "img", value: "/quiz-images-2/SAT_calc/img17.png" },
      {
        type: "txt",
        value:
          "The complete graph of the function ⨍ is shown in the xy-plane above. For what value of x is the value of ⨍(x) at its minimum?",
      },
    ],
    options: [
      { type: "txt", value: "-5" },
      { type: "txt", value: "-3" },
      { type: "txt", value: "-2" },
      { type: "txt", value: "3" },
    ],
    answer: 1,
  },
  {
    id: 17,
    question: [
      {
        type: "txt",
        value:
          "In the xy-plane, if (0,0) is a solution to the system of inequalities above , which of the following relationships between a and b must be true?",
      },
    ],
    options: [
      { type: "txt", value: "a>b" },
      { type: "txt", value: "b>a" },
      { type: "txt", value: "|a| > |b|" },
      { type: "txt", value: "a= -b" },
    ],
    answer: 0,
  },
  {
    id: 18,
    question: [
      {
        type: "txt",
        value:
          "A food truck sells salads for $6.50 each and drinks for $2.00 each. The food truck`s revenue from selling a total of 209 salads and drinks in one day was $836.50. How many salads were sold that day?",
      },
    ],
    options: [
      { type: "txt", value: "77" },
      { type: "txt", value: "93" },
      { type: "txt", value: "99" },
      { type: "txt", value: "105" },
    ],
    answer: 1,
  },
  {
    id: 19,
    question: [
      {
        type: "txt",
        value:
          "Alma bought a laptop computer at a store that gave a 20 percent discount off its original price. The total amount she paid to the cashier was p dollars, including an 8 percent sales tax on the discounted price. Which of the following represents the original price of the computer in terms of p?",
      },
    ],
    options: [
      { type: "txt", value: "0.88p" },
      { type: "img", value: "/quiz-images-2/SAT_calc/img18.png" },
      { type: "txt", value: "(0.8)(1.08)p" },
      { type: "img", value: "/quiz-images-2/SAT_calc/img19.png" },
    ],
    answer: 3,
  },
  {
    id: 20,
    question: [
      { type: "img", value: "/quiz-images-2/SAT_calc/img20.png" },
      {
        type: "txt",
        value:
          "The data in the table above were produced by a sleep researcher studying the number of dreams people recall when asked to record their dreams for one week. Group X consisted of 100 people who observed early bedtimes, and Group Y consisted of 100 people who observed later bedtimes. If a person is chosen at random from those who recalled at least 1 dream, what is the probability that the person belonged to Group Y?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/SAT_calc/img21.png" },
      { type: "img", value: "/quiz-images-2/SAT_calc/img22.png" },
      { type: "img", value: "/quiz-images-2/SAT_calc/img23.png" },
      { type: "img", value: "/quiz-images-2/SAT_calc/img24.png" },
    ],
    answer: 2,
  },
  {
    id: 21,
    question: [
      {
        type: "txt",
        value:
          "Which of the following best approximates the average rate of change in the annual budget for agriculture/natural resources in Kansas from 2008 to 2010?",
      },
    ],
    options: [
      { type: "txt", value: "$50,000,000 per year" },
      { type: "txt", value: "$65,000,000 per year" },
      { type: "txt", value: "$75,000,000 per year" },
      { type: "txt", value: "$130,000,000 per year" },
    ],
    answer: 1,
  },
  {
    id: 22,
    question: [
      {
        type: "txt",
        value:
          "Of the following, which program`s ratio of its 2007 budget to its 2010 budget is closest to the  human resources program`s ratio of its 2007 budget to its 2010 budget?",
      },
    ],
    options: [
      { type: "txt", value: "Agriculture/natural resources" },
      { type: "txt", value: "Education" },
      { type: "txt", value: "Highways and transportation" },
      { type: "txt", value: "Public safety" },
    ],
    answer: 1,
  },
  {
    id: 23,
    question: [
      {
        type: "txt",
        value:
          "Which of the following is an equation of a circle in the xy-plane with center (0,4) and a radius with endpoint",
      },
      { type: "img", value: "/quiz-images-2/SAT_calc/img25.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/SAT_calc/img26.png" },
      { type: "img", value: "/quiz-images-2/SAT_calc/img27.png" },
      { type: "img", value: "/quiz-images-2/SAT_calc/img28.png" },
      { type: "img", value: "/quiz-images-2/SAT_calc/img29.png" },
    ],
    answer: 0,
  },
  {
    id: 24,
    question: [
      {
        type: "txt",
        value:
          "h= -4.9t²+25t    The equation above expresses the approximate height h, in meters, of a ball t seconds after it is launched vertically upward from the ground with an initial velocity of 25 meters per second. After approximately how many seconds will the ball hit the ground?",
      },
    ],
    options: [
      { type: "txt", value: "3.5" },
      { type: "txt", value: "4.0" },
      { type: "txt", value: "4.5" },
      { type: "txt", value: "5.0" },
    ],
    answer: 3,
  },
  {
    id: 25,
    question: [
      {
        type: "txt",
        value:
          "Kartina is a botanist studying the production of pears by two types of pear trees. She noticed that Type A trees produced 20 percent more pears than Type B trees did. Based on Katarina`s observation, if the Type A trees produced 144 pears, how many pears did the Type B trees produce?",
      },
    ],
    options: [
      { type: "txt", value: "155" },
      { type: "txt", value: "120" },
      { type: "txt", value: "124" },
      { type: "txt", value: "173" },
    ],
    answer: 1,
  },
  {
    id: 26,
    question: [
      {
        type: "txt",
        value:
          "A square field measures 10 meters by 10 meters. Ten students each mark off a randomly selected region of the field; each region is square and has side lengths of 1 meter, and no two regions overlap. The students count the earthworms contained in the soil to a depth of 5 centimeters beneath the ground`s surface in each region. The results are shown in the table below.",
      },
      { type: "img", value: "/quiz-images-2/SAT_calc/img30.png" },
      {
        type: "txt",
        value:
          "Which of the following is a reasonable approximation of the number of earthworms to a depth of 5 centimeters beneath the ground`s surface in the entire field?",
      },
    ],
    options: [
      { type: "txt", value: "150" },
      { type: "txt", value: "1,500" },
      { type: "txt", value: "15,000" },
      { type: "txt", value: "150,000" },
    ],
    answer: 2,
  },
  {
    id: 27,
    question: [
      { type: "img", value: "/quiz-images-2/SAT_calc/img31.png" },
      { type: "txt", value: "If the system of inequalities y2x+1 and" },
      { type: "img", value: "/quiz-images-2/SAT_calc/img32.png" },
      {
        type: "txt",
        value:
          "is graphed in the xy-plane above, which quadrant contains no solutions to the system?",
      },
    ],
    options: [
      { type: "txt", value: "Quadrant II" },
      { type: "txt", value: "Quadrant III" },
      { type: "txt", value: "Quadrant IV" },
      { type: "txt", value: "There are solutions in all four quadrants." },
    ],
    answer: 2,
  },
  {
    id: 28,
    question: [
      {
        type: "txt",
        value:
          "For a polynomial p(x), the value of p (3) is -2. Which of the following must be true about p(x) ?",
      },
    ],
    options: [
      { type: "txt", value: "x-5 is a factor of p(x)" },
      { type: "txt", value: "x-2 is a factor of p(x)" },
      { type: "txt", value: "x+2 is a factor of p(x)" },
      {
        type: "txt",
        value: "The remainder when p(x) is divided by x-3 is -2.",
      },
    ],
    answer: 3,
  },
  {
    id: 29,
    question: [
      { type: "img", value: "/quiz-images-2/SAT_calc/img33.png" },
      {
        type: "txt",
        value:
          "Which of the following is an equivalent from of the equation of the graph shown in the xy-plane above, from which the coordinates of vertex A can be identified as constants in the equation?",
      },
    ],
    options: [
      { type: "txt", value: "y=(x+3) (x-5)" },
      { type: "txt", value: "y=(x-3) (x+5)" },
      { type: "txt", value: "y=x(x-2) -15" },
      { type: "txt", value: "y=(x-1)² -16" },
    ],
    answer: 3,
  },
  {
    id: 30,
    question: [
      { type: "img", value: "/quiz-images-2/SAT_calc/img34.png" },
      {
        type: "txt",
        value:
          "The graph above shows Marilyn`s distance from  her campsite during a 3-hour hike. She stopped for 30 minutes during her hike to have lunch. Based on the graph, which of the following is closest to the time she finished lunch and continued her hike?",
      },
    ],
    options: [
      { type: "txt", value: "12:40 P.M." },
      { type: "txt", value: "1:10 P.M." },
      { type: "txt", value: "1:40 P.M." },
      { type: "txt", value: "2:00 P.M." },
    ],
    answer: 2,
  },
  {
    id: 31,
    question: [
      { type: "img", value: "/quiz-images-2/SAT_calc/img35.png" },
      {
        type: "txt",
        value:
          "The table above shows the distribution of age and gender for 25 people who entered a contest. If the contest winner will be selected at random, what is the probability that the winner will be either a female under age 40 or a male age 40 or older ?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/SAT_calc/img36.png" },
      { type: "img", value: "/quiz-images-2/SAT_calc/img37.png" },
      { type: "img", value: "/quiz-images-2/SAT_calc/img38.png" },
      { type: "img", value: "/quiz-images-2/SAT_calc/img39.png" },
    ],
    answer: 1,
  },
  {
    id: 32,
    question: [
      {
        type: "txt",
        value:
          "The graph below shows the total number of music album sales, in millions, each year from 1997 through 2009.",
      },
      { type: "img", value: "/quiz-images-2/SAT_calc/img40.png" },
      {
        type: "txt",
        value:
          "Based on the graph, which of the following best describes the general trend in music albem sales from 1997 through 2009?",
      },
    ],
    options: [
      { type: "txt", value: "Sales generally increased each year since 1997" },
      { type: "txt", value: "Sales generally decreased each year since 1997" },
      {
        type: "txt",
        value: "Sales increased until 2000 and then generally decreased",
      },
      {
        type: "txt",
        value: "Sales generally remained steady from 1997 through 2009",
      },
    ],
    answer: 2,
  },
  {
    id: 33,
    question: [
      { type: "img", value: "/quiz-images-2/SAT_calc/img41.png" },
      {
        type: "txt",
        value:
          "The table above shows some values of the linear function ⨍. Which of the following defines ⨍ ?",
      },
    ],
    options: [
      { type: "txt", value: "⨍ (n) =n-3" },
      { type: "txt", value: "⨍ (n) =2n-4" },
      { type: "txt", value: "⨍ (n) =3n-5" },
      { type: "txt", value: "⨍ (n) =4n-6" },
    ],
    answer: 2,
  },
  {
    id: 34,
    question: [
      {
        type: "txt",
        value:
          "At Lincoln High School, approximately 7 percent of enrolled juniors and 5 percent of enrolled seniors were inducted into the National Honor Society last year. If there were 562 juniors and 602 seniors enrolled at Lincoln High School last year, which of the following is closest to the total number of juniors and seniors at Lincoln High School last year who were inducted into the National Honor Society?",
      },
    ],
    options: [
      { type: "txt", value: "140" },
      { type: "txt", value: "69" },
      { type: "txt", value: "39" },
      { type: "txt", value: "30" },
    ],
    answer: 1,
  },
  {
    id: 35,
    question: [
      { type: "img", value: "/quiz-images-2/SAT_calc/img42.png" },
      {
        type: "txt",
        value:
          "Which of the following is the sum of the two polynomials shown above?",
      },
    ],
    options: [
      { type: "txt", value: "8x²-7x-4" },
      { type: "txt", value: "8x²+7x-4" },
      { type: "txt", value: "8x⁴-7x²-4" },
      { type: "txt", value: "8x⁴+7x²-4" },
    ],
    answer: 0,
  },
  {
    id: 36,
    question: [
      { type: "img", value: "/quiz-images-2/SAT_calc/img43.png" },
      { type: "txt", value: "what is the value of w ?" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/SAT_calc/img44.png" },
      { type: "img", value: "/quiz-images-2/SAT_calc/img45.png" },
      { type: "img", value: "/quiz-images-2/SAT_calc/img46.png" },
      { type: "img", value: "/quiz-images-2/SAT_calc/img47.png" },
    ],
    answer: 3,
  },
  {
    id: 37,
    question: [
      {
        type: "txt",
        value:
          "The average number of students per classroom at Central High School from 2000 to 2010 can be modeled by equation y=0.56x+27.2, where x represents the average number of years since 2000, and y represents the average number of students per classroom . Which of the following best describes the meaning of the number 0.56 in the equation? ",
      },
    ],
    options: [
      {
        type: "txt",
        value: "The total number of students at the school in 2000",
      },
      {
        type: "txt",
        value: "the average number of students per classroom in 2000",
      },
      {
        type: "txt",
        value:
          "the estimated increase in the average number of students per classroom each year",
      },
      {
        type: "txt",
        value:
          "The estimated difference between the average number of students per classroom in 2010 and in 2000",
      },
    ],
    answer: 2,
  },
  {
    id: 38,
    question: [
      {
        type: "txt",
        value:
          "Nate walks 25 meters in 13.7 seconds . If he walks at this same rate , which of the following is closest to the distance he will walk in 4 minutes ?",
      },
    ],
    options: [
      { type: "txt", value: "150 meters" },
      { type: "txt", value: "450 meters" },
      { type: "txt", value: "700 meters" },
      { type: "txt", value: "1,400 meters" },
    ],
    answer: 1,
  },
  {
    id: 39,
    question: [
      {
        type: "txt",
        value:
          "What is the weight, in newtons, of an object on Mercury with a mass of 90 kilograms?",
      },
    ],
    options: [
      { type: "txt", value: "25" },
      { type: "txt", value: "86" },
      { type: "txt", value: "101" },
      { type: "txt", value: "324" },
    ],
    answer: 3,
  },
  {
    id: 40,
    question: [
      {
        type: "txt",
        value:
          "An object on Earth has a weight of 150 newtons. On which planet would the same object have an approximate weight of 170 newtons?",
      },
    ],
    options: [
      { type: "txt", value: "Venus" },
      { type: "txt", value: "Saturn" },
      { type: "txt", value: "Uranus" },
      { type: "txt", value: "Neptune" },
    ],
    answer: 1,
  },
  {
    id: 41,
    question: [
      {
        type: "txt",
        value:
          "If the function ⨍ has five distinct zeros, which of the following could represent the complete graph of ⨍ in the xy-plane?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/SAT_calc/img48.png" },
      { type: "img", value: "/quiz-images-2/SAT_calc/img49.png" },
      { type: "img", value: "/quiz-images-2/SAT_calc/img50.png" },
      { type: "img", value: "/quiz-images-2/SAT_calc/img51.png" },
    ],
    answer: 3,
  },
  {
    id: 42,
    question: [
      {
        type: "txt",
        value:
          "h= - 16t²+vt+k     The equation above gives the height h, in feet, of a ball t seconds after it is thrown straight up with an initial speed of v feet per second from a height of k feet. Which of the following gives v in terms of h, t, and k ?",
      },
    ],
    options: [
      { type: "txt", value: "v=h+k-16t" },
      { type: "img", value: "/quiz-images-2/SAT_calc/img52.png" },
      { type: "img", value: "/quiz-images-2/SAT_calc/img53.png" },
      { type: "img", value: "/quiz-images-2/SAT_calc/img54.png" },
    ],
    answer: 3,
  },
  {
    id: 43,
    question: [
      {
        type: "txt",
        value:
          "The cost of using a telephone in a hotel meeting room is $0.20 per minute. Which of the following equations represents the total cost c, in dollars, for h hours of phone use?",
      },
    ],
    options: [
      { type: "txt", value: "c=0.20(60h)" },
      { type: "txt", value: "c=0.20h +60" },
      { type: "img", value: "/quiz-images-2/SAT_calc/img55.png" },
      { type: "img", value: "/quiz-images-2/SAT_calc/img56.png" },
    ],
    answer: 0,
  },
  {
    id: 44,
    question: [
      {
        type: "txt",
        value:
          "In order to determine if treatment X is successful in improving eyesight , A research study was conducted . From a large population  of people with poor eyesight, 300 participants were selected at random . Half of the participants were randomly assignment to receive treatment X , and the other half did not receive treatment X . The resulting data showed that participants who received treatment X had significantly improved eyesight as compared to those who did not receive treatment X. Based on the design and results of the study , which of the following is an appropriate conclusion?",
      },
    ],
    options: [
      {
        type: "txt",
        value:
          "treatment X is likely to improve the eyesight of people who have poor eyesight ",
      },
      {
        type: "txt",
        value:
          "treatment X improves eyesight better than all other available treatments",
      },
      {
        type: "txt",
        value: "treatment X will Improve the eyesight of anyone who takes it .",
      },
      {
        type: "txt",
        value: "Treatment X will cause a substantial improvement in eyesight",
      },
    ],
    answer: 0,
  },
  {
    id: 45,
    question: [
      {
        type: "txt",
        value:
          "Graphs of the functions ⨍ and g are shown in the XY- plan above . Which of the following values of X does ⨍(x)+ g(x)=0 ",
      },
      { type: "img", value: "/quiz-images-2/SAT_calc/img57.png" },
    ],
    options: [
      { type: "txt", value: "-3" },
      { type: "txt", value: "-2" },
      { type: "txt", value: "-1" },
      { type: "txt", value: "0" },
    ],
    answer: 1,
  },
  {
    id: 46,
    question: [
      {
        type: "txt",
        value:
          "How will the quantity of the product supplied to the market change if the price of the product is increased by $10 ?",
      },
    ],
    options: [
      { type: "txt", value: "The quantity supplied will decrease by 5 units." },
      { type: "txt", value: "The quantity supplied will increase by 5 units." },
      {
        type: "txt",
        value: "The quantity supplied will increase by 10 units.",
      },
      { type: "txt", value: "The quantity supplied will increase by 50 units" },
    ],
    answer: 1,
  },
  {
    id: 47,
    question: [
      {
        type: "txt",
        value:
          "At what price will the quantity of the product supplied to the market equal the quantity of the product demanded by the market?",
      },
    ],
    options: [
      { type: "txt", value: "$90" },
      { type: "txt", value: "$120" },
      { type: "txt", value: "$133" },
      { type: "txt", value: "$155" },
    ],
    answer: 1,
  },
  {
    id: 48,
    question: [
      {
        type: "txt",
        value:
          "Graphene, which is used in the manufacture of integrated circuits, is so thin that a sheet weighing one ounce can cover up to 7 football fields. If a football field has an area of approximately",
      },
      { type: "img", value: "/quiz-images-2/SAT_calc/img58.png" },
      {
        type: "txt",
        value: "acres, about how many acres could 48 ounces of graphene cover?",
      },
    ],
    options: [
      { type: "txt", value: "250" },
      { type: "txt", value: "350" },
      { type: "txt", value: "450" },
      { type: "txt", value: "1,350" },
    ],
    answer: 2,
  },
  {
    id: 49,
    question: [
      { type: "img", value: "/quiz-images-2/SAT_calc/img59.png" },
      {
        type: "txt",
        value:
          "Michael swam 2,000 yards on each of eighteen days. The scatterplot above shows his swim time for and corresponding heart rate after each swim. The line of best fit for the data is also shown. For the swim that took 34 minutes, Michael’s actual heart rate was about how many beats per minutes less than the rate predicted by the line of best fit?",
      },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 1,
  },
  {
    id: 50,
    question: [
      {
        type: "txt",
        value:
          "Of the following four types of savings account plans, which option would yield exponential growth of the money in the account?",
      },
    ],
    options: [
      {
        type: "txt",
        value:
          "Each successive year, 2% of the initial savings is added to the value of the account.",
      },
      {
        type: "txt",
        value:
          "Each successive year, 1.5% of the initial savings and $100 is added to the value of the account.",
      },
      {
        type: "txt",
        value:
          "Each successive year, 1% of the current value is added to the value of the account. ",
      },
      {
        type: "txt",
        value:
          "Each successive year, $100 is added to the value of the account. ",
      },
    ],
    answer: 2,
  },
  {
    id: 51,
    question: [
      {
        type: "txt",
        value:
          "The sum of three numbers is 855. One of the numbers, x, is 50% more than the sum of the other two numbers. What is the value of x?",
      },
    ],
    options: [
      { type: "txt", value: "570" },
      { type: "txt", value: "513" },
      { type: "txt", value: "214" },
      { type: "txt", value: "155" },
    ],
    answer: 1,
  },
  {
    id: 52,
    question: [
      { type: "img", value: "/quiz-images-2/SAT_calc/img60.png" },
      {
        type: "txt",
        value:
          "The angles shown above are acute and sin(a°) = cos(b°). If a = 4k − 22 and b = 6k − 13, what is the value of k?",
      },
    ],
    options: [
      { type: "txt", value: "4.5" },
      { type: "txt", value: "5.5" },
      { type: "txt", value: "12.5" },
      { type: "txt", value: "21.5" },
    ],
    answer: 2,
  },
  {
    id: 53,
    question: [
      {
        type: "txt",
        value:
          "Mr. Kohl has a beaker containing n milliliters of solution to distribute to the students in his chemistry class. If he gives each student 3 milliliters of solution, he will have 5 milliliters left over. In order to give each student 4 milliliters of solution, he will need an additional 21 milliliters. How many students are in the class? ",
      },
    ],
    options: [
      { type: "txt", value: "16" },
      { type: "txt", value: "21" },
      { type: "txt", value: "23" },
      { type: "txt", value: "26" },
    ],
    answer: 3,
  },
  {
    id: 54,
    question: [
      { type: "img", value: "/quiz-images-2/SAT_calc/img61.png" },
      {
        type: "txt",
        value:
          "A grain silo is built from two right circular cones and a right circular cylinder with internal measurements represented by the figure above. Of the following, which is closest to the volume of the grain silo, in cubic feet?",
      },
    ],
    options: [
      { type: "txt", value: "261.8" },
      { type: "txt", value: "785.4" },
      { type: "txt", value: "916.3" },
      { type: "txt", value: "1,047.2" },
    ],
    answer: 3,
  },
  {
    id: 55,
    question: [
      {
        type: "txt",
        value:
          "In the xy-plane, the line determined by the points (2, k) and (k, 32) passes through the origin. Which of the following could be the value of k?",
      },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "txt", value: "4" },
      { type: "txt", value: "8" },
      { type: "txt", value: "16" },
    ],
    answer: 2,
  },
  {
    id: 56,
    question: [
      {
        type: "txt",
        value:
          "A rectangle was altered by increasing its length by 10 percent and decreasing its width by p percent. If these alterations decreased the area of the rectangle by 12 percent, what is the value of p ?",
      },
    ],
    options: [
      { type: "txt", value: "12" },
      { type: "txt", value: "15" },
      { type: "txt", value: "20" },
      { type: "txt", value: "22" },
    ],
    answer: 2,
  },
  {
    id: 57,
    question: [
      {
        type: "txt",
        value:
          "In planning maintenance for a city’s infrastructure, a civil engineer estimates that, starting from the present, the population of the city will decrease by 10 percent every 20 years. If the present population of the city is 50,000, which of the following expressions represents the engineer’s estimate of the population of the city t years from now?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/SAT_calc/img62.png" },
      { type: "img", value: "/quiz-images-2/SAT_calc/img63.png" },
      { type: "img", value: "/quiz-images-2/SAT_calc/img64.png" },
      { type: "img", value: "/quiz-images-2/SAT_calc/img65.png" },
    ],
    answer: 3,
  },
  {
    id: 58,
    question: [
      {
        type: "txt",
        value:
          "The incomplete table above summarizes the number of left-handed students and right-handed students by gender for the eighth-grade students at Keisel Middle School. There are 5 times as many right-handed female students as there are left-handed female students, and there are 9 times as many right-handed male students as there are left-handed male students. If there is a total of 18 left-handed students and 122 right-handed students in the school, which of the following is closest to the probability that a right-handed student selected at random is female? (Note: Assume that none of the eighth-grade students are both right-handed and left-handed.)",
      },
      { type: "img", value: "/quiz-images-2/SAT_calc/img66.png" },
    ],
    options: [
      { type: "txt", value: "0.410" },
      { type: "txt", value: "0.357" },
      { type: "txt", value: "0.333" },
      { type: "txt", value: "0.250" },
    ],
    answer: 0,
  },
  {
    id: 59,
    question: [
      { type: "img", value: "/quiz-images-2/SAT_calc/img67.png" },
      {
        type: "txt",
        value:
          "In the equations above, b and c are constants. If b is c minus 1/2, which of the following is true?",
      },
    ],
    options: [
      { type: "txt", value: "x is y minus 1/4" },
      { type: "txt", value: "x is y minus 1/2" },
      { type: "txt", value: "x is y minus 1." },
      { type: "txt", value: "x is y plus 1/2" },
    ],
    answer: 0,
  },
  {
    id: 60,
    question: [
      {
        type: "txt",
        value:
          "Tickets for a school talent show cost $2 for students and $3 for adults. If Chris spends at least $11 but no more than $14 on x student tickets and 1 adult ticket, what is one possible value of x?",
      },
    ],
    options: [
      { type: "txt", value: "4 or 5" },
      { type: "txt", value: "3 or 4" },
      { type: "txt", value: "4 or 6" },
      { type: "txt", value: "4 or 8" },
    ],
    answer: 0,
  },
  {
    id: 61,
    question: [
      {
        type: "txt",
        value:
          "The table above lists the ages of the first 12 United States presidents when they began their terms in office. According to the table, what was the mean age, in years, of these presidents at the beginning of their terms? (Round your answer to the nearest tenth.)",
      },
      { type: "img", value: "/quiz-images-2/SAT_calc/img68.png" },
    ],
    options: [
      { type: "txt", value: "58.6" },
      { type: "txt", value: "57.6" },
      { type: "txt", value: "59" },
      { type: "txt", value: "55.6" },
    ],
    answer: 0,
  },
  {
    id: 62,
    question: [
      {
        type: "txt",
        value:
          "(−3x² + 5x − 2) − 2(x² − 2x − 1) If the expression above is rewritten in the form ax² + bx + c,  where a, b, and c are constants, what is the value of b?",
      },
    ],
    options: [
      { type: "txt", value: "9" },
      { type: "txt", value: "10" },
      { type: "txt", value: "8" },
      { type: "txt", value: "11" },
    ],
    answer: 0,
  },
  {
    id: 63,
    question: [
      {
        type: "txt",
        value: "In a circle with center O, central angle AOB has a measure of",
      },
      { type: "img", value: "/quiz-images-2/SAT_calc/img69.png" },
      {
        type: "txt",
        value:
          "radians. The area of the sector formed by central angle AOB is what fraction of the area of the circle?",
      },
    ],
    options: [
      { type: "txt", value: "5/8 or 0.625" },
      { type: "txt", value: "6/8 or 0.75" },
      { type: "txt", value: "7/8 or 0.875" },
      { type: "txt", value: "8/9 or 0.(8)" },
    ],
    answer: 0,
  },
  {
    id: 64,
    question: [
      {
        type: "txt",
        value:
          "An online store receives customer satisfaction ratings between 0 and 100, inclusive. In the first 10 ratings the store received, the average (arithmetic mean) of the ratings was 75. What is the least value the store can receive for the 11th rating and still be able to have an average of at least 85 for the first 20 ratings?",
      },
    ],
    options: [
      { type: "txt", value: "60" },
      { type: "txt", value: "50" },
      { type: "txt", value: "70" },
      { type: "txt", value: "75" },
    ],
    answer: 1,
  },
  {
    id: 65,
    question: [
      { type: "img", value: "/quiz-images-2/SAT_calc/img70.png" },
      {
        type: "txt",
        value:
          "In the xy‑plane, if a point with coordinates (a, b) lies in the solution set of the system of inequalities above, what is the maximum possible value of b?",
      },
    ],
    options: [
      { type: "txt", value: "760" },
      { type: "txt", value: "780" },
      { type: "txt", value: "750" },
      { type: "txt", value: "790" },
    ],
    answer: 2,
  },
  {
    id: 66,
    question: [
      {
        type: "txt",
        value:
          "Little’s law can be applied to any part of the store, such as a particular department or the checkout lines. The store owner determines that, during business hours, approximately 84 shoppers per hour make a purchase and each of these shoppers spend an average of 5 minutes in the checkout line. At any time during business hours, about how many shoppers, on average, are waiting in the checkout line to make a purchase at the Good Deals Store?",
      },
    ],
    options: [
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
      { type: "txt", value: "7" },
      { type: "txt", value: "8" },
    ],
    answer: 2,
  },
  {
    id: 67,
    question: [
      {
        type: "txt",
        value:
          "The owner of the Good Deals Store opens a new store across town. For the new store, the owner estimates that, during business hours, an average of 90 shoppers per hour enter the store and each of them stays an average of 12 minutes. The average number of shoppers in the new store at any time is what percent less than the average number of shoppers in the original store at any time? (Note: Ignore the percent symbol when entering your answer. For example, if the answer is 42.1%, enter 42.1% )",
      },
    ],
    options: [
      { type: "txt", value: "30" },
      { type: "txt", value: "40" },
      { type: "txt", value: "50" },
      { type: "txt", value: "60" },
    ],
    answer: 3,
  },
];

const planametriya7_sinf = [
  {
    id: 0,
    question: [{ type: "txt", value: "Kesmaning  o`rtasi nima deb ataladi ?" }],
    options: [
      {
        type: "txt",
        value:
          "Kesmani  o`rtasi deb uni o`zaro teng ikkita kesmaga ajratuvchi nuqtaga aytiladi.",
      },
      {
        type: "txt",
        value:
          "Istalgan nurga uning boshlang`ich nuqtasidan berilgan qismiga aytiladi.",
      },
    ],
    answer: 0,
  },
  {
    id: 1,
    question: [
      { type: "txt", value: "Rasmda nechta kesma bor." },
      { type: "img", value: "/quiz-images/planametriya/img1.png" },
    ],
    options: [
      { type: "txt", value: "7" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
    ],
    answer: 3,
  },
  {
    id: 2,
    question: [
      {
        type: "txt",
        value:
          "To`g`ri chiziqda A, B, C, D nuqtalar berilgan. D nuqta B va C nuqtalar orasida yotadi. DC=4,5 sm va BD=2,5 sm ekani ma'lum, AB kesma DC kesmadan ikki marta uzun. AC kesmaning uzunligini toping.",
      },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 0,
  },
  {
    id: 3,
    question: [
      {
        type: "txt",
        value:
          "To`g`ri chiziqdagi A, B, C, nuqtalar uchun AB=5,8 sm AC=9,1 sm va BC=3,5 sm  ekani ma'lum, A, B, C nuqtalarning qaysi biri qolgan ikkitasining orasida yotadi.",
      },
    ],
    options: [
      { type: "txt", value: "A va B ning orasida, C yotadi." },
      { type: "txt", value: "A va C ning orasida, B yotadi." },
      { type: "txt", value: "B va C ning orasida A yotadi." },
    ],
    answer: 1,
  },
  {
    id: 4,
    question: [
      {
        type: "txt",
        value:
          "Agar AB va DE kesmalar bitta nurda yotadigan bo`lsa, AB=10 sm, DE=20 sm bo`lsa,  E nuqta AB kesma orasida yotishi mumkinmi?",
      },
    ],
    options: [
      { type: "txt", value: "Ha" },
      { type: "txt", value: "Yo`q" },
      { type: "txt", value: "To`g`ri javob berilmagan" },
    ],
    answer: 1,
  },
  {
    id: 5,
    question: [
      {
        type: "txt",
        value: "Rasmda berilganlardan foydalanib AC kesma uzunligini toping?",
      },
      { type: "img", value: "/quiz-images/planametriya/img2.png" },
      { type: "txt", value: "AB=24,  BC=AC+6,   AC=?" },
    ],
    options: [
      { type: "txt", value: "11" },
      { type: "txt", value: "10" },
      { type: "txt", value: "9" },
    ],
    answer: 2,
  },
  {
    id: 6,
    question: [
      { type: "txt", value: "B Є AC. AB=8,2 sm. AC=3 dm. BC ni toping?" },
    ],
    options: [
      { type: "txt", value: "21,8 dm" },
      { type: "txt", value: "218 sm" },
      { type: "txt", value: "21,8 sm" },
    ],
    answer: 2,
  },
  {
    id: 7,
    question: [
      {
        type: "txt",
        value: "Radiusi 11 m bo`lgan doiraning eng katta vatarini toping.",
      },
    ],
    options: [
      { type: "txt", value: "11 sm" },
      { type: "txt", value: "22 sm" },
      { type: "txt", value: "21 sm" },
    ],
    answer: 1,
  },
  {
    id: 8,
    question: [
      {
        type: "txt",
        value: "Doiraning diametri 1 m 14 sm bo`lsa, uning radiusini toping?",
      },
    ],
    options: [
      { type: "txt", value: "63sm" },
      { type: "txt", value: "57 sm" },
      { type: "txt", value: "59 sm" },
    ],
    answer: 1,
  },
  {
    id: 9,
    question: [
      {
        type: "txt",
        value:
          "Aylananing diametri radiusidan 70 sm uzun. Aylana diametrini toping.",
      },
    ],
    options: [
      { type: "txt", value: "70" },
      { type: "txt", value: "140" },
      { type: "txt", value: "150" },
    ],
    answer: 1,
  },
  {
    id: 10,
    question: [
      {
        type: "txt",
        value:
          "Tarifsiz qabul qilingan asosiy geometrik tushunchalarni ko`rsating.  a) tekislik    b) nuqta    c) kesma    d) nur    e) to'g'ri chiziq",
      },
    ],
    options: [
      { type: "txt", value: "a,b,c" },
      { type: "txt", value: "b,c,e" },
      { type: "txt", value: "a,b,c,e" },
      { type: "txt", value: "a,b,e" },
    ],
    answer: 3,
  },
  {
    id: 11,
    question: [
      {
        type: "txt",
        value:
          "AB kesmani 2 ta to'g'ri chiziq kesib o'tsa, ko'pi bilan AB kesmada yotgan nechta kesma hosil bo'ladi?",
      },
    ],
    options: [
      { type: "txt", value: "3." },
      { type: "txt", value: "4." },
      { type: "txt", value: "5." },
      { type: "txt", value: "6." },
    ],
    answer: 3,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value:
          "AB nurda C nuqta, BA nurda D nuqta shunday olinganki, AC=0,7 va BD=2,1. Agar AB=1,5 bo'lsa, CD ni toping.",
      },
    ],
    options: [
      { type: "txt", value: "4.2" },
      { type: "txt", value: "4.3" },
      { type: "txt", value: "4.1" },
      { type: "txt", value: "4.4" },
    ],
    answer: 1,
  },
  {
    id: 13,
    question: [
      {
        type: "txt",
        value:
          "Doira diametri 36 sm. Doira markazida 19 sm uzoqlikdagi nuqta ushbu doiraga tegishli bo'ladimi?",
      },
    ],
    options: [
      { type: "txt", value: "yo'q" },
      { type: "txt", value: "ha." },
      { type: "txt", value: "bo'lishi mumkin" },
    ],
    answer: 0,
  },
  {
    id: 14,
    question: [
      {
        type: "txt",
        value:
          "45° li burchakka qo'shni bo'lgan burchak necha gradusli bo'ladi ?",
      },
    ],
    options: [
      { type: "txt", value: "45" },
      { type: "txt", value: "125" },
      { type: "txt", value: "135" },
    ],
    answer: 2,
  },
  {
    id: 15,
    question: [
      {
        type: "txt",
        value:
          "Agar qo'shni burchaklarning biri ikkinchisidan 3marta katta bo'lsa , ularni toping.",
      },
    ],
    options: [
      { type: "txt", value: "125, 55" },
      { type: "txt", value: "135, 45" },
      { type: "txt", value: "115, 65" },
    ],
    answer: 1,
  },
  {
    id: 16,
    question: [
      { type: "img", value: "/quiz-images/planametriya/img3.png" },
      { type: "txt", value: "x ni toping?" },
    ],
    options: [
      { type: "txt", value: "30" },
      { type: "txt", value: "45" },
      { type: "txt", value: "60" },
      { type: "txt", value: "75" },
    ],
    answer: 1,
  },
  {
    id: 17,
    question: [
      {
        type: "txt",
        value:
          "Agar qo'shni burchaklar gradus o'lchovlarining nisbati 11:25 bo'lsa , ularni toping .",
      },
    ],
    options: [
      { type: "txt", value: "45; 135" },
      { type: "txt", value: "125; 55" },
      { type: "txt", value: "165; 15" },
    ],
    answer: 1,
  },
  {
    id: 18,
    question: [{ type: "img", value: "/quiz-images/planametriya/img4.png" }],
    options: [
      { type: "txt", value: "60°" },
      { type: "txt", value: "45°" },
      { type: "txt", value: "30°" },
    ],
    answer: 2,
  },
  {
    id: 19,
    question: [
      {
        type: "txt",
        value:
          "Ikkita to'g'ri chiziqning kesishishidan hosil bo'lgan burchaklardan ikkitasining ayirmasi 20°. Bu burchaklarni toping .",
      },
    ],
    options: [
      { type: "txt", value: "110; 70" },
      { type: "txt", value: "100; 80" },
      { type: "txt", value: "120; 60" },
    ],
    answer: 1,
  },
  {
    id: 20,
    question: [
      {
        type: "txt",
        value:
          "Ikki qo'shni burchakning ayirmasi 24° ga teng bo'lsa . Ulardan kichigini toping .",
      },
    ],
    options: [
      { type: "txt", value: "72°" },
      { type: "txt", value: "76°" },
      { type: "txt", value: "78°" },
      { type: "txt", value: "82°" },
    ],
    answer: 2,
  },
  {
    id: 21,
    question: [{ type: "txt", value: "AB = 18 C€ AB  AC - BC = 4.  BC= ?" }],
    options: [
      { type: "txt", value: "7" },
      { type: "txt", value: "8" },
      { type: "txt", value: "10" },
      { type: "txt", value: "11" },
    ],
    answer: 0,
  },
  {
    id: 22,
    question: [
      { type: "img", value: "/quiz-images/planametriya/img5.png" },
      { type: "txt", value: "x ni toping." },
    ],
    options: [
      { type: "txt", value: "15" },
      { type: "txt", value: "30" },
      { type: "txt", value: "46" },
      { type: "txt", value: "60" },
    ],
    answer: 0,
  },
  {
    id: 23,
    question: [
      {
        type: "txt",
        value:
          "ABC uchburchakdan AB=BC va BD medianasi 4 sm .Agar ABD uchburchak Perimetri 12 sm bòlsa,ABC uchburchak perimetrini toping.",
      },
    ],
    options: [
      { type: "txt", value: "24" },
      { type: "txt", value: "36" },
      { type: "txt", value: "12" },
    ],
    answer: 0,
  },
  {
    id: 24,
    question: [
      {
        type: "txt",
        value:
          "Perimetri 36 ga teng bólgan uchburchakning balandligi uni perimetrlari 18 va 24 ga teng bólgan uchburchaklarga ajratadi . Berilgan uchburchakning balandligini toping .",
      },
    ],
    options: [
      { type: "txt", value: "5" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 1,
  },
  {
    id: 25,
    question: [
      { type: "img", value: "/quiz-images/planametriya/img6.png" },
      { type: "txt", value: "x ni toping." },
    ],
    options: [
      { type: "txt", value: "85" },
      { type: "txt", value: "75" },
      { type: "txt", value: "65" },
    ],
    answer: 0,
  },
  {
    id: 26,
    question: [
      { type: "img", value: "/quiz-images/planametriya/img7.png" },
      { type: "txt", value: "a va b ni toping." },
    ],
    options: [
      { type: "txt", value: "a=8 b = 12" },
      { type: "txt", value: "a=12 b=8" },
      { type: "txt", value: "a=15 b =9" },
    ],
    answer: 1,
  },
  {
    id: 27,
    question: [
      {
        type: "txt",
        value:
          "Teng yonli uchurchakning asosi yon tomonidan 3 sm katta , lekin yon tomonlarining yig`indisidan 5 sm kichik. Uchburchakning tomonlarini toping .",
      },
    ],
    options: [
      { type: "txt", value: "12;8" },
      { type: "txt", value: "10;9" },
      { type: "txt", value: "11;8" },
    ],
    answer: 0,
  },
  {
    id: 28,
    question: [
      {
        type: "txt",
        value:
          "ABC uchburchakning BC tomoniga o`tkazilgan o`rta perpendikulyar AC tomonni D nuqtada kesib o`tadi .Agar BD=7,2 sm AD=3,2sm bo`lsa,AC nimaga teng?",
      },
    ],
    options: [
      { type: "txt", value: "11,4" },
      { type: "txt", value: "10,4" },
      { type: "txt", value: "9,8" },
      { type: "txt", value: "6,4" },
    ],
    answer: 3,
  },
  {
    id: 29,
    question: [
      {
        type: "txt",
        value:
          "ABC va DEF teng yonli uchurchaklar o`zaro teng .ABC uchburchakda AC=BC va AB=2sm .Agar DE=4 sm bo`lsa, har bir uchburchak perimetrini toping.",
      },
    ],
    options: [
      { type: "txt", value: "10;10sm" },
      { type: "txt", value: "10;12sm" },
      { type: "txt", value: "13;15sm" },
    ],
    answer: 0,
  },
  {
    id: 30,
    question: [
      {
        type: "txt",
        value:
          "Teng yonli uchburchakning perimetri 36 ga teng . Asosi yon tomoni dan 3sm qisqa.Uchburchakning yon tomonini toping .",
      },
    ],
    options: [
      { type: "txt", value: "15" },
      { type: "txt", value: "18" },
      { type: "txt", value: "13" },
    ],
    answer: 2,
  },
  {
    id: 31,
    question: [
      {
        type: "txt",
        value:
          "Teng yonli uchburchakning perimetri 48 ga teng. Uning tomonlaridan biri 1w ga teng bo`lsa, qolgan tomonlarini toping.",
      },
    ],
    options: [
      { type: "txt", value: "12;12" },
      { type: "txt", value: "18;24" },
      { type: "txt", value: "18;18" },
    ],
    answer: 2,
  },
  {
    id: 32,
    question: [{ type: "txt", value: "Uchburchakda nechta medianasi bor." }],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
    ],
    answer: 2,
  },
  {
    id: 33,
    question: [
      { type: "img", value: "/quiz-images/planametriya/img8.png" },
      { type: "txt", value: "x ni toping." },
    ],
    options: [
      { type: "txt", value: "30°" },
      { type: "txt", value: "125°" },
      { type: "txt", value: "75°" },
      { type: "txt", value: "50°" },
    ],
    answer: 3,
  },
  {
    id: 34,
    question: [
      {
        type: "txt",
        value:
          "Agar uchburchakning ikkita burchagi 60° va 40° ga teng bo`lsa, uning uchinchi burchagini toping.",
      },
    ],
    options: [
      { type: "txt", value: "70" },
      { type: "txt", value: "90" },
      { type: "txt", value: "80" },
    ],
    answer: 2,
  },
  {
    id: 35,
    question: [{ type: "img", value: "/quiz-images/planametriya/img9.png" }],
    options: [
      { type: "txt", value: "60°;48°" },
      { type: "txt", value: "50°;48°" },
      { type: "txt", value: "48°;90°" },
    ],
    answer: 0,
  },
  {
    id: 36,
    question: [
      {
        type: "txt",
        value:
          "Uchburchakning burchalari a,b,j      a) a=b+j.       b)a=(b+j)/2 Bo`lsa, a ni toping. ",
      },
    ],
    options: [
      { type: "txt", value: "90°;60°" },
      { type: "txt", value: "70°;60°" },
      { type: "txt", value: "50°;30°" },
    ],
    answer: 0,
  },
  {
    id: 37,
    question: [
      {
        type: "txt",
        value:
          "ABC to`g`ri burchakli uchburchak, burchak ⦟B=90° AB=1,6 sm, AC=3,2 sm bo`lsa, burchak ⦟A ni toping .",
      },
    ],
    options: [
      { type: "txt", value: "40" },
      { type: "txt", value: "45" },
      { type: "txt", value: "30" },
      { type: "txt", value: "60" },
    ],
    answer: 2,
  },
  {
    id: 38,
    question: [
      {
        type: "txt",
        value:
          "To`g`ri burchakli uchburchakning gipotenuzaga tushurilgan medianasi 8 sm. Agar uchburch⦟akning bir burchagi 60° ga teng bólsa, bu burchakka yopishgan tomonlarini toping.",
      },
    ],
    options: [
      { type: "txt", value: "8 sm; 15 sm" },
      { type: "txt", value: "8 sm ; 16sm" },
      { type: "txt", value: "7sm ; 5 sm" },
    ],
    answer: 1,
  },
  {
    id: 39,
    question: [
      {
        type: "txt",
        value:
          "To`g`ri burchakli uchburchakning bitta o`tkir burchagi ikkinchisidan 2 marta katta . Uning kichik tomoni 6 sm bo`lsa , katta tomonini toping .",
      },
    ],
    options: [
      { type: "txt", value: "6√3" },
      { type: "txt", value: "5√5" },
      { type: "txt", value: "6√2" },
    ],
    answer: 0,
  },
  {
    id: 40,
    question: [
      {
        type: "txt",
        value:
          "Uzunliklari 1m,2m va 3m bo`lgan kesmalardan uchburchak yasash mumkunmi ?",
      },
    ],
    options: [
      { type: "txt", value: "ha" },
      { type: "txt", value: "yo`q" },
      { type: "txt", value: "bo`lishi mumkin" },
    ],
    answer: 1,
  },
  {
    id: 41,
    question: [
      {
        type: "txt",
        value:
          "Agar uchburchak burchaklari 2:3:4 kabi nisbatda bo`lsa, uning burchaklarini toping ",
      },
    ],
    options: [
      { type: "txt", value: "20°; 30°;40°" },
      { type: "txt", value: "40°;60°;85°" },
      { type: "txt", value: "36°;54°;90°" },
    ],
    answer: 1,
  },
  {
    id: 42,
    question: [
      { type: "img", value: "/quiz-images/planametriya/img10.png" },
      { type: "txt", value: "x ni toping." },
    ],
    options: [
      { type: "txt", value: "60°" },
      { type: "txt", value: "55°" },
      { type: "txt", value: "65°" },
      { type: "txt", value: "70°" },
    ],
    answer: 2,
  },
  {
    id: 43,
    question: [
      {
        type: "txt",
        value:
          "Uchburchakning perimetri tomonlaridan mos ravishda 14 sm, 16 sm va 24 sm uzun  bo`lsa, uchburchakning eng katta tomonini toping.",
      },
    ],
    options: [
      { type: "txt", value: "11 sm" },
      { type: "txt", value: "12 sm" },
      { type: "txt", value: "15 sm" },
      { type: "txt", value: "16 sm" },
    ],
    answer: 0,
  },
  {
    id: 44,
    question: [
      { type: "img", value: "/quiz-images/planametriya/img11.png" },
      { type: "txt", value: "x ni toping." },
    ],
    options: [
      { type: "txt", value: "72°" },
      { type: "txt", value: "60°" },
      { type: "txt", value: "90°" },
    ],
    answer: 0,
  },
  {
    id: 45,
    question: [
      {
        type: "txt",
        value:
          "A nuqta BC to`g`ri chiziqning B va C nuqtalar orasida yotadi. Agar BC=15 sm, AC kesma esa AB kesmadan 3 sm ga qisqa bo`lsa, AB kesmaning uzunligini toping.",
      },
    ],
    options: [
      { type: "txt", value: "10 sm" },
      { type: "txt", value: "11 sm" },
      { type: "txt", value: "9 sm" },
    ],
    answer: 2,
  },
  {
    id: 46,
    question: [
      {
        type: "txt",
        value:
          "Ikki to`g`ri chiziqning kesishishidan hosil bo`lgan burchaklarning nisbati 7:3 ga teng. Shu burchaklardan kichigini toping.",
      },
    ],
    options: [
      { type: "txt", value: "54" },
      { type: "txt", value: "84" },
      { type: "txt", value: "75" },
      { type: "txt", value: "60" },
    ],
    answer: 0,
  },
  {
    id: 47,
    question: [
      {
        type: "txt",
        value:
          "Uchburchakning 5 sm ga teng bo`lgan balandligi uni perimetri 18 sm va 26 sm bo`lgan ikkita uchburchakka ajratadi. Berilgan uchburchakning perimetrini toping.",
      },
    ],
    options: [
      { type: "txt", value: "34" },
      { type: "txt", value: "44" },
      { type: "txt", value: "36" },
    ],
    answer: 0,
  },
];

const planametriya8_sinf = [
  {
    id: 0,
    question: [
      {
        type: "txt",
        value:
          "Qavariq o’ttizburchakning bir uchidan chiqqan va jami diagonallari soni nechta?",
      },
    ],
    options: [
      { type: "txt", value: "27;405 ta" },
      { type: "txt", value: "31;400 ta" },
      { type: "txt", value: "27;305 ta" },
    ],
    answer: 0,
  },
  {
    id: 1,
    question: [
      {
        type: "txt",
        value:
          "Qavariq to’rtburchakning burchaklari 1, 2, 3, 4, sonlariga proporsional. Shu burchuklarni toping.",
      },
    ],
    options: [
      { type: "txt", value: "36;72;108;154" },
      { type: "txt", value: "36;64;108;144" },
      { type: "txt", value: "36;72;108;144" },
    ],
    answer: 2,
  },
  {
    id: 2,
    question: [
      {
        type: "txt",
        value: "O`nikkiburchakning burchaklar yig’indisini toping.",
      },
    ],
    options: [
      { type: "txt", value: "180" },
      { type: "txt", value: "1800" },
      { type: "txt", value: "360" },
    ],
    answer: 1,
  },
  {
    id: 3,
    question: [
      {
        type: "txt",
        value:
          "Har bir ichki burchagi 1440 ga teng bo’lgan qavariq ko’pburchakning nechta tomoni bor?",
      },
    ],
    options: [
      { type: "txt", value: "10" },
      { type: "txt", value: "9" },
      { type: "txt", value: "6" },
    ],
    answer: 0,
  },
  {
    id: 4,
    question: [
      {
        type: "txt",
        value:
          "Tashqi burchagining har biri 360 ga teng bo’lgan qavariq ko’pburchakning nechta tomoni bor?",
      },
    ],
    options: [
      { type: "txt", value: "9" },
      { type: "txt", value: "8" },
      { type: "txt", value: "10" },
    ],
    answer: 2,
  },
  {
    id: 5,
    question: [
      {
        type: "txt",
        value:
          "Parallelogrammning ikki tomoni 5:7 kabi nisbatda, perimetri esa 4,8 sm  ga teng. Parallelogrammning tomonlarini toping",
      },
    ],
    options: [
      { type: "txt", value: "1 sm; 1,4 sm; 11 m; 1,4 sm." },
      { type: "txt", value: "1 sm; 1,4 sm; 1,4 sm; 1 sm." },
      { type: "txt", value: "1,4sm; 1 sm; 1,4 sm; 1 sm." },
    ],
    answer: 0,
  },
  {
    id: 6,
    question: [
      {
        type: "txt",
        value:
          "Parallegrammning qo’shni tomonlari yig’indisi 20 sm ga, ayirmasi esa 12 sm ga teng. Shu parallelogram tomonlarini toping.",
      },
    ],
    options: [
      { type: "txt", value: "16 sm; 4 sm; 16 sm; 4 sm." },
      { type: "txt", value: "4 sm; 4 sm; 16 m; 16 sm." },
      { type: "txt", value: "4 sm; 16 sm; 16 sm; 4 sm." },
    ],
    answer: 0,
  },
  {
    id: 7,
    question: [
      {
        type: "txt",
        value:
          "Parallelogramning diagonali uning ikki tomoni bilan 20° va 55° li burchaklar tashkil qiladi. Shu parallelogrammning burchaklarini toping.",
      },
    ],
    options: [
      { type: "txt", value: "75°; 105°; 75°; 105°" },
      { type: "txt", value: "60°; 120°; 120°; 60°" },
      { type: "txt", value: "110°; 110°; 70°; 70°" },
    ],
    answer: 0,
  },
  {
    id: 8,
    question: [
      {
        type: "txt",
        value:
          "ABCD parallelogrammda: AB=7sm, BC=11sm, AC=14sm, BD=12sm; O – diogonallarning kesishish nuqtasi ekani ma’lum. ABO va BOC uchburchaklarning perimetrlarini toping.",
      },
    ],
    options: [
      { type: "txt", value: "20sm; 24dm" },
      { type: "txt", value: "24sm; 20dm" },
      { type: "txt", value: "20sm; 24sm" },
    ],
    answer: 0,
  },
  {
    id: 9,
    question: [
      {
        type: "txt",
        value:
          "ABCD to’g’ri to’rtburchakning AB tomoni 6 sm ga, diagonallari esa 10 sm ga teng. O – tog’ri to’rtburchak diagonallarining kesishish nuqtasi. COD uchburchakning perimetrini toping.",
      },
    ],
    options: [
      { type: "txt", value: "10" },
      { type: "txt", value: "15" },
      { type: "txt", value: "17" },
      { type: "txt", value: "16" },
    ],
    answer: 3,
  },
  {
    id: 10,
    question: [
      {
        type: "txt",
        value:
          "Burchaklaridan biri 60°, kichik diagonallari bilan tomonlari orasida hosil bo’lgan burchaklarning nisbati 2:7 kabi. Rombning burchaklarini toping.",
      },
    ],
    options: [
      { type: "txt", value: "16" },
      { type: "txt", value: "64" },
      { type: "txt", value: "56" },
      { type: "txt", value: "72" },
    ],
    answer: 1,
  },
  {
    id: 11,
    question: [
      {
        type: "txt",
        value:
          "Rombning diagonallari bilan tomonlari orasida hosil bo’lgan burchaklarning nisbati 2:7 kabi. Romning burchaklarini toping.",
      },
    ],
    options: [
      { type: "txt", value: "140°;40°; 140°;40°" },
      { type: "txt", value: "150°; 30°; 150°; 30°" },
      { type: "txt", value: "145°;45°; 145°;45°" },
    ],
    answer: 0,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value: "Rombning perimetri 72 sm ga teng. Uning tomonlarini toping.",
      },
    ],
    options: [
      { type: "txt", value: "16" },
      { type: "txt", value: "18" },
      { type: "txt", value: "20" },
    ],
    answer: 1,
  },
  {
    id: 13,
    question: [
      {
        type: "txt",
        value: "Kvadratning perimetri 30 sm ga teng. Uning tomoni nimaga teng.",
      },
    ],
    options: [
      { type: "txt", value: "6,5" },
      { type: "txt", value: "8,5" },
      { type: "txt", value: "7,5" },
    ],
    answer: 2,
  },
  {
    id: 14,
    question: [
      {
        type: "txt",
        value:
          "Kvadratning diagonallari kesishish nuqtasidan tomonlaridan birigacha bo’lgan masofa 2dm 3sm ga teng. Kvadratning perimetrini toping.",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/planametriya/img12.png" },
      { type: "img", value: "/quiz-images/planametriya/img13.png" },
      { type: "img", value: "/quiz-images/planametriya/img14.png" },
    ],
    answer: 0,
  },
  {
    id: 15,
    question: [
      {
        type: "txt",
        value:
          "To’g’ri to’rtburchakning bo`yi 32sm, eni esa 28sm ga teng. Shu to’g’ri to’rt burchakning perimetri ga teng bo’lgan kvadrat tomonini toping.",
      },
    ],
    options: [
      { type: "txt", value: "120" },
      { type: "txt", value: "30" },
      { type: "txt", value: "40" },
    ],
    answer: 1,
  },
  {
    id: 16,
    question: [
      {
        type: "txt",
        value:
          "Kvadratning tomoni 20sm ga teng. Diagonallari kesishish nuqtasidan tomonlaridan birigacha bo’lgan masofani toping.",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/planametriya/img15.png" },
      { type: "img", value: "/quiz-images/planametriya/img16.png" },
      { type: "img", value: "/quiz-images/planametriya/img18.png" },
    ],
    answer: 0,
  },
  {
    id: 17,
    question: [
      {
        type: "txt",
        value:
          "Uchburchak tomonlarining nisbatlari 6:18:10 kabi, perimetri 120sm. Uchlari berilgan uchburchak tomonlarining o’rtalarida bo’lgan uchburchakning tomonlari va perimetrini toping.",
      },
    ],
    options: [
      { type: "txt", value: "20; 15; 25; P=40" },
      { type: "txt", value: "20; 15; 35; P=60" },
      { type: "txt", value: "20; 15; 25; P=60" },
    ],
    answer: 0,
  },
  {
    id: 18,
    question: [
      {
        type: "txt",
        value:
          "Kvadratning diagonali 14sm ga teng. Berilgan kvadrat tomonlarining o’rtalarini ketma-ket tutashtiruvchi kesmalar hosil qilgan to’rtburchakning perimetrini toping.",
      },
    ],
    options: [
      { type: "txt", value: "7" },
      { type: "txt", value: "28" },
      { type: "txt", value: "35" },
    ],
    answer: 1,
  },
  {
    id: 19,
    question: [
      {
        type: "txt",
        value:
          "To’g’ri burchakli trapetsiyaning qarama-qarshi burchaklaridan biri ikkinchisidan 40° ga katta. Shu trapetsiyaning burchaklarining toping.",
      },
    ],
    options: [
      { type: "txt", value: "90°; 90°; 140°; 40°" },
      { type: "txt", value: "90°; 90°; 130°; 50°" },
    ],
    answer: 0,
  },
  {
    id: 20,
    question: [
      {
        type: "txt",
        value:
          "Teng yonli trapetsiyaning qarama-qarshi barchaklari ayirmasi 70° ga teng. Uning burchaklarini toping.",
      },
    ],
    options: [
      { type: "txt", value: "125°; 55°; 125°; 55°" },
      { type: "txt", value: "135°; 45°; 135°; 45°" },
      { type: "txt", value: "145°; 35°; 145°; 35°" },
    ],
    answer: 1,
  },
  {
    id: 21,
    question: [
      {
        type: "txt",
        value:
          "Teng yonli trapetsiyaning o’tmas burchagi uchidan o’tkazilgan balandlik katta asosini 6sm va 30sm li kesmalarga bo’ladi. Shu trapetsiyaning  asoslarini toping.",
      },
    ],
    options: [
      { type: "txt", value: "36; 24" },
      { type: "txt", value: "38; 24" },
      { type: "txt", value: "24; 40" },
    ],
    answer: 0,
  },
  {
    id: 22,
    question: [
      {
        type: "txt",
        value:
          "Trapetsiyaning asoslari 11sm va 17sm bo’lsa uning o’rta chizig`ini toping.",
      },
    ],
    options: [
      { type: "txt", value: "15" },
      { type: "txt", value: "14" },
      { type: "txt", value: "16" },
    ],
    answer: 1,
  },
  {
    id: 23,
    question: [
      {
        type: "txt",
        value:
          "Trapetsiyaning katta asosi 16sm ga teng, kichik asosi esa o’rta chiziqdan 6sm qisqa. Trapetsiyaning kichik va o’rta chizigini toping.",
      },
    ],
    options: [
      { type: "txt", value: "11; 4" },
      { type: "txt", value: "10; 5" },
      { type: "txt", value: "10; 4" },
    ],
    answer: 2,
  },
  {
    id: 24,
    question: [
      {
        type: "txt",
        value:
          "Teng yonli trapetsiyaning diagonallari, o’tkir burchagini teng ikkiga bo’ladi. Trapetsiyaning perimetri 66sm, asoslarining nisbati 2:5 kabi. Trapetsiyaning o’rta chizigini toping. ",
      },
    ],
    options: [
      { type: "txt", value: "18" },
      { type: "txt", value: "21" },
      { type: "txt", value: "19" },
    ],
    answer: 1,
  },
  {
    id: 25,
    question: [
      {
        type: "txt",
        value:
          "Trapetsiyaning o’rta chizig’i 30sm ga, kichik asosi esa 20sm ga teng. Shu trapetsiyaning katta asosini toping.",
      },
    ],
    options: [
      { type: "txt", value: "40" },
      { type: "txt", value: "30" },
      { type: "txt", value: "50" },
    ],
    answer: 0,
  },
  {
    id: 26,
    question: [
      {
        type: "txt",
        value:
          "Teng yonli trapetsiyaning o’tmas burchagi uchidan o’tkazilgan balandlik katta asosini 5sm li va 20sm li kesmalarga ajratadi. Shu trapetsiyaning asoslarini toping.",
      },
    ],
    options: [
      { type: "txt", value: "25; 15" },
      { type: "txt", value: "20; 15" },
      { type: "txt", value: "25; 27" },
    ],
    answer: 0,
  },
  {
    id: 27,
    question: [
      {
        type: "txt",
        value:
          "ABCD trapetsiyada AC diagonal CD tomonga perpendikular. Agar ⦟D=72° va AB=BC, bo’lsa ⦟ABC ni toping.",
      },
    ],
    options: [
      { type: "txt", value: "150°" },
      { type: "txt", value: "144°" },
      { type: "txt", value: "136°" },
      { type: "txt", value: "108°" },
    ],
    answer: 1,
  },
  {
    id: 28,
    question: [
      {
        type: "txt",
        value:
          "Trapetsiyaning uchta tomoni 4sm dan, to’rtinchi tomoni esa 8sm. Trapetsiyaning eng katta burchagini toping.",
      },
    ],
    options: [
      { type: "txt", value: "140°" },
      { type: "txt", value: "120°" },
      { type: "txt", value: "150°" },
      { type: "txt", value: "60°" },
    ],
    answer: 2,
  },
  {
    id: 29,
    question: [
      {
        type: "txt",
        value:
          "Tomonlari 54sm va 42sm ga teng bo’lgan to’g’ri to’rtburchakning perimetriga teng bo’lgan kvadratning yuzini toping.",
      },
    ],
    options: [
      { type: "txt", value: "2304" },
      { type: "txt", value: "2205" },
      { type: "txt", value: "2307" },
    ],
    answer: 0,
  },
  {
    id: 30,
    question: [
      {
        type: "txt",
        value:
          "To’g’ri to’rtburchakning bir tomoni 36dm, ikkinchisi esa 16dm. Unga tengdosh kvadratning tomonini toping.",
      },
    ],
    options: [
      { type: "txt", value: "26" },
      { type: "txt", value: "24" },
      { type: "txt", value: "28" },
    ],
    answer: 1,
  },
  {
    id: 31,
    question: [
      {
        type: "txt",
        value:
          "To’g’ri to’rtburchakning perimetri 26sm ga teng, tomonlaridan biri esa 98m. Shu to’rtburchakning perimetrini toping.",
      },
    ],
    options: [
      { type: "txt", value: "5" },
      { type: "txt", value: "7" },
      { type: "txt", value: "6" },
    ],
    answer: 2,
  },
  {
    id: 32,
    question: [
      {
        type: "txt",
        value:
          "ABCD to’g’ri to’rtburchak B burchagining bissektrisasi AD tomonini K nuqtada kesadi, AK=5sm VA KD=7sm. Shu to’g’ri to’rtburchakning yuzini toping.",
      },
    ],
    options: [
      { type: "txt", value: "50" },
      { type: "txt", value: "60" },
      { type: "txt", value: "70" },
    ],
    answer: 1,
  },
  {
    id: 33,
    question: [
      {
        type: "txt",
        value:
          "Parallelogrammning yuzi 41sm bo’lsa tomonlari 5sm va 10sm. Uning ikkala balandligini toping.",
      },
    ],
    options: [
      { type: "txt", value: "8,2; 4,1" },
      { type: "txt", value: "8,2; 4,1" },
      { type: "txt", value: "1,3; 4,1" },
    ],
    answer: 1,
  },
  {
    id: 34,
    question: [
      {
        type: "txt",
        value:
          "Perimetri 80sm ga teng bo’lgan parallelogrammning tomonlari nisbati 2:3 ga, o’tkir burchagi esa 30° ga teng. Parallelogrammning yuzini toping.",
      },
    ],
    options: [
      { type: "txt", value: "162" },
      { type: "txt", value: "182" },
      { type: "txt", value: "192" },
    ],
    answer: 2,
  },
  {
    id: 35,
    question: [
      {
        type: "txt",
        value:
          "Parallelogrammning ikkita balandligi 8sm, 10sm ga teng. Bitta tomoni 12sm bo’lsa, uning perimetrini toping.",
      },
    ],
    options: [
      { type: "txt", value: "35,2" },
      { type: "txt", value: "24,8" },
      { type: "txt", value: "9,6" },
    ],
    answer: 0,
  },
  {
    id: 36,
    question: [
      {
        type: "txt",
        value:
          "Uchburchakning ikki tomoni 6sm va 5sm ga teng. Uning yuzi 15sm ga teng bo’lishi mumkinmi?",
      },
    ],
    options: [
      { type: "txt", value: "Yo’q" },
      { type: "txt", value: "Ha" },
      { type: "txt", value: "Bo’lishi mumkin emas" },
    ],
    answer: 1,
  },
  {
    id: 37,
    question: [
      {
        type: "txt",
        value:
          "Rombning yuzi 30sm, perimetri esa 24sm ga teng. Shu rombning balandligini toping.",
      },
    ],
    options: [
      { type: "txt", value: "6" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
    ],
    answer: 2,
  },
  {
    id: 38,
    question: [
      {
        type: "txt",
        value:
          "Rombning burchaklar nisbati 1:5 ga, tomoni esa a ga teng. Shu rombning yuzini toping.",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/planametriya/img18.png" },
      { type: "img", value: "/quiz-images/planametriya/img19.png" },
      { type: "img", value: "/quiz-images/planametriya/img20.png" },
    ],
    answer: 1,
  },
  {
    id: 39,
    question: [
      {
        type: "txt",
        value:
          "Teng yonli trapetsiyaning perimetri 32sm, yon tomoni 5sm va yuzi 44sm ga teng. Trapetsiyaning balandligini toping.",
      },
    ],
    options: [
      { type: "txt", value: "5sm" },
      { type: "txt", value: "4sm" },
      { type: "txt", value: "7sm" },
    ],
    answer: 1,
  },
  {
    id: 40,
    question: [
      {
        type: "txt",
        value:
          "ABCD to’g’ri to’rtburchak A burchagining bissektrisasi BC tomonni P nuqtada 10sm va 15sm ga teng bo’laklarga  bo’ladi. APCD trapetsiyaning yuzini toping.",
      },
    ],
    options: [
      { type: "txt", value: "262,5" },
      { type: "txt", value: "272,5" },
      { type: "txt", value: "282,5" },
    ],
    answer: 0,
  },
  {
    id: 41,
    question: [
      {
        type: "txt",
        value:
          "ABCD parallelogrammning AC diagonaliga BO perpendikular tushirilgan. AO=8, OC=6 va OB=4 bo’lsa, parallelogramning yuzini toping.",
      },
    ],
    options: [
      { type: "txt", value: "50" },
      { type: "txt", value: "28" },
      { type: "txt", value: "52" },
      { type: "txt", value: "56" },
    ],
    answer: 2,
  },
  {
    id: 42,
    question: [
      {
        type: "txt",
        value:
          "To’g’ri burchakli uchburchakning tomonlari 7,24 va 25 sonlariga proporsional bo`lishi mumkinmi?",
      },
    ],
    options: [
      { type: "txt", value: "Ha" },
      { type: "txt", value: "Yo’q" },
    ],
    answer: 0,
  },
  {
    id: 43,
    question: [
      {
        type: "txt",
        value:
          "To’g’ri burchakli trapetsiyaning yon tomonlari 15sm va 9sm, katta asosi esa 20sm ga teng. Trapetsiyaning yuzini toping.",
      },
    ],
    options: [
      { type: "txt", value: "136" },
      { type: "txt", value: "116" },
      { type: "txt", value: "126" },
    ],
    answer: 2,
  },
  {
    id: 44,
    question: [
      {
        type: "txt",
        value:
          " Teng yonli ABC uchburchakda: AC – asos, BD – balandlik. Agar uchburchakning yuzini  yon tomonini toping.  AC=16 vca BD=6   bo’lsa tomoni va yuzini toping.",
      },
    ],
    options: [
      { type: "txt", value: "48:10" },
      { type: "txt", value: "48:12" },
      { type: "txt", value: "10:49" },
    ],
    answer: 0,
  },
  {
    id: 45,
    question: [
      {
        type: "txt",
        value:
          "ABCD to’g’ri to’rtburchakda  1)agar AB=15 va AC=39 bo’lsa, AD ni toping. ",
      },
    ],
    options: [
      { type: "txt", value: "36" },
      { type: "txt", value: "48" },
      { type: "txt", value: "72" },
    ],
    answer: 0,
  },
  {
    id: 46,
    question: [
      {
        type: "txt",
        value:
          "Teng yonli uchburchakning yon tomoni 17sm, asosi esa 16sm ga teng. Asosiga tushirilgan balandlikni toping.",
      },
    ],
    options: [
      { type: "txt", value: "25" },
      { type: "txt", value: "35" },
      { type: "txt", value: "15" },
    ],
    answer: 2,
  },
  {
    id: 47,
    question: [
      {
        type: "txt",
        value:
          "Uchburchakning tomonlari 16sm, 12sm va 8sm ga teng. Shu uchburchakning kichik balandligini toping. ",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/planametriya/img21.png" },
      { type: "img", value: "/quiz-images/planametriya/img22.png" },
      { type: "img", value: "/quiz-images/planametriya/img23.png" },
    ],
    answer: 0,
  },
  {
    id: 48,
    question: [
      {
        type: "txt",
        value:
          "Rombning tomoni 26sm, ga diagonallaridan biri esa 48sm ga teng. Shu rombning yuzini toping.",
      },
    ],
    options: [
      { type: "txt", value: "480" },
      { type: "txt", value: "360" },
      { type: "txt", value: "100" },
    ],
    answer: 0,
  },
  {
    id: 49,
    question: [
      {
        type: "txt",
        value:
          "To’g’ri burchakli ABCD(⦟D=90°) trapetsiyaning asoslari 17sm va 9sm, kichik yon tomoni esa 15sm ga teng. AB tomonni toping. ",
      },
    ],
    options: [
      { type: "txt", value: "15" },
      { type: "txt", value: "16" },
      { type: "txt", value: "17" },
    ],
    answer: 2,
  },
  {
    id: 50,
    question: [
      {
        type: "txt",
        value:
          "A, B, C nuqtalar markazi O nuqtada bo’lgan aylanada yotadi. Agar ∪ABC=70° bo’lsa, AOC burchakni toping.",
      },
    ],
    options: [
      { type: "txt", value: "220°" },
      { type: "txt", value: "110°" },
      { type: "txt", value: "100°" },
    ],
    answer: 1,
  },
  {
    id: 51,
    question: [
      {
        type: "txt",
        value:
          "O markazli va radiusi 8sm bo’lgan aylanaga A nuqtadan AB urinma o’tkazilgan. A va B nuqtalar orasidagi masofa 16sm bo’lsa, AOB burchakni toping.",
      },
    ],
    options: [
      { type: "txt", value: "30" },
      { type: "txt", value: "90" },
      { type: "txt", value: "60" },
    ],
    answer: 2,
  },
  {
    id: 52,
    question: [
      {
        type: "txt",
        value:
          "Perimetri 56sm ga teng bo’lgan trapetsiyaga aylana ichki chizilgan. Trapetsiyaning ketma-ket uchta tomoni nisbati 2:7:12 kabi. Shu trapetsiyaning tomonlarini toping. ",
      },
    ],
    options: [
      { type: "txt", value: "4; 14; 28" },
      { type: "txt", value: "4; 14; 14" },
      { type: "txt", value: "4; 14; 15" },
    ],
    answer: 0,
  },
  {
    id: 53,
    question: [
      {
        type: "txt",
        value:
          "Ichki aylana chizish mumkin bo’lgan to’rtbutchakning ketma-ket uchta tomonlari 6sm, 8sm va 9sm ga teng. Shu to’rtburchakning to’rtinchi Tomoni va perimetrini toping.",
      },
    ],
    options: [
      { type: "txt", value: "6" },
      { type: "txt", value: "7" },
      { type: "txt", value: "8" },
    ],
    answer: 1,
  },
  {
    id: 54,
    question: [
      {
        type: "txt",
        value:
          "To’g’ri burchakli uchburchakning katetlari 40sm va 30sm ga teng. Shu uchburchakning perimetri va unga ichki chizilgan aylana radiusini toping.",
      },
    ],
    options: [
      { type: "txt", value: "120; 10" },
      { type: "txt", value: "120; 20" },
      { type: "txt", value: "10; 120" },
    ],
    answer: 0,
  },
  {
    id: 55,
    question: [
      {
        type: "txt",
        value:
          "Uchburchakning burchaklari 40°, 55°, va 85° ga teng. Uchburchakning qaysi tomoni tashqi chizilgan aylana markazidan uzoqda joylashgan?",
      },
    ],
    options: [
      { type: "txt", value: "40° li qarshisidagi tomon" },
      { type: "txt", value: "55° li qarshisidagi tomon" },
      { type: "txt", value: "85° li qarshisidagi tomon" },
    ],
    answer: 0,
  },
  {
    id: 56,
    question: [
      {
        type: "txt",
        value:
          "Aylanaga ichki chizilgan to’rtburchakning ikkita burchagi 70° va 95° ga teng. To’rtburchakning qolgan ikki burchagini toping.",
      },
    ],
    options: [
      { type: "txt", value: "85°; 115" },
      { type: "txt", value: "95°; 105" },
      { type: "txt", value: "75°; 120" },
    ],
    answer: 0,
  },
  {
    id: 57,
    question: [
      {
        type: "txt",
        value:
          "Teng tomonli uchburchakning balandligi 9sm. Shu uchburchakka chizilgan aylananing radiusini toping.",
      },
    ],
    options: [
      { type: "txt", value: "3sm" },
      { type: "txt", value: "4,5sm" },
      { type: "txt", value: "6sm" },
    ],
    answer: 0,
  },
  {
    id: 58,
    question: [
      { type: "img", value: "/quiz-images/planametriya/img24.png" },
      { type: "txt", value: "(-2;6) va" },
      { type: "img", value: "/quiz-images/planametriya/img25.png" },
      { type: "txt", value: "(-2;4) vektorlar berilgan." },
      { type: "img", value: "/quiz-images/planametriya/img24.png" },
      { type: "txt", value: "+" },
      { type: "img", value: "/quiz-images/planametriya/img25.png" },
      { type: "txt", value: "va . " },
      { type: "img", value: "/quiz-images/planametriya/img24.png" },
      { type: "txt", value: "-" },
      { type: "img", value: "/quiz-images/planametriya/img25.png" },
      { type: "txt", value: "ni toping." },
    ],
    options: [
      { type: "txt", value: "28; - 20" },
      { type: "txt", value: "30; - 20" },
      { type: "txt", value: "38; 30" },
    ],
    answer: 0,
  },
  {
    id: 59,
    question: [
      { type: "img", value: "/quiz-images/planametriya/img24.png" },
      { type: "txt", value: "(2; 3) va" },
      { type: "img", value: "/quiz-images/planametriya/img25.png" },
      { type: "txt", value: "(-2; 0) vektorlar berilgan. 2" },
      { type: "img", value: "/quiz-images/planametriya/img24.png" },
      { type: "txt", value: "+" },
      { type: "img", value: "/quiz-images/planametriya/img25.png" },
      { type: "txt", value: "vektorning koordinatalarini toping." },
    ],
    options: [
      { type: "txt", value: "(4;6)" },
      { type: "txt", value: "(7;8)" },
      { type: "txt", value: "(3;6)" },
    ],
    answer: 2,
  },
  {
    id: 60,
    question: [
      { type: "txt", value: "Vektorlarning skalyar ko`paytmasini toping." },
      { type: "img", value: "/quiz-images/planametriya/img26.png" },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "txt", value: "3" },
      { type: "txt", value: "2" },
    ],
    answer: 0,
  },
  {
    id: 61,
    question: [
      { type: "img", value: "/quiz-images/planametriya/img27.png" },
      { type: "txt", value: "vektorlar berilgan," },
      { type: "img", value: "/quiz-images/planametriya/img28.png" },
      { type: "txt", value: "vektorning uzunligini toping." },
    ],
    options: [
      { type: "txt", value: "17" },
      { type: "txt", value: "14" },
      { type: "txt", value: "15" },
    ],
    answer: 1,
  },
  {
    id: 62,
    question: [
      { type: "img", value: "/quiz-images/planametriya/img24.png" },
      {
        type: "txt",
        value:
          "(-4; x) vektorning moduli 5 ga teng bo’lsa, x ning qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "– 3" },
      { type: "txt", value: "6" },
      { type: "txt", value: "3" },
    ],
    answer: 2,
  },
  {
    id: 63,
    question: [
      { type: "img", value: "/quiz-images/planametriya/img30.png" },
      { type: "txt", value: "vektorlar berilgan." },
      { type: "img", value: "/quiz-images/planametriya/img31.png" },
      { type: "txt", value: "vektorning koordinatlarini toping." },
    ],
    options: [
      { type: "txt", value: "(-3;6)" },
      { type: "txt", value: "(6;3)" },
      { type: "txt", value: "(2;-3)" },
      { type: "txt", value: "(-2;-9)" },
    ],
    answer: 1,
  },
  {
    id: 64,
    question: [
      { type: "txt", value: "Ifodani soddalashtiring:" },
      { type: "img", value: "/quiz-images/planametriya/img32.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/planametriya/img33.png" },
      { type: "img", value: "/quiz-images/planametriya/img34.png" },
      { type: "img", value: "/quiz-images/planametriya/img35.png" },
      { type: "img", value: "/quiz-images/planametriya/img36.png" },
    ],
    answer: 0,
  },
  {
    id: 65,
    question: [
      {
        type: "txt",
        value:
          "To’g’ri burchakli uchburchakning katetlari 6 va 8 ga teng. Uning gipotenuzasiga tushirilgan balandligini toping.",
      },
    ],
    options: [
      { type: "txt", value: "4,8" },
      { type: "txt", value: "5" },
      { type: "txt", value: "4,5" },
      { type: "txt", value: "4,7" },
    ],
    answer: 0,
  },
  {
    id: 66,
    question: [
      {
        type: "txt",
        value:
          "Rombning balandligi 5 ga, diagonallarining ko`paytmasi 80 ga teng. Uning perimetrini toping.",
      },
    ],
    options: [
      { type: "txt", value: "32" },
      { type: "txt", value: "16" },
      { type: "txt", value: "24" },
      { type: "txt", value: "28" },
    ],
    answer: 0,
  },
  {
    id: 67,
    question: [
      {
        type: "txt",
        value:
          "To’g’ri to’rtburchakning bo`yi 20% va eni 10% ga orttirilsa, uning yuzi necha foiz ortadi.",
      },
    ],
    options: [
      { type: "txt", value: "20%" },
      { type: "txt", value: "35%" },
      { type: "txt", value: "27%" },
      { type: "txt", value: "32%" },
    ],
    answer: 3,
  },
  {
    id: 68,
    question: [
      { type: "img", value: "/quiz-images/planametriya/img37.png" },
      { type: "txt", value: "vektor berilgan." },
      { type: "img", value: "/quiz-images/planametriya/img38.png" },
    ],
    options: [
      { type: "txt", value: "10" },
      { type: "txt", value: "6" },
      { type: "txt", value: "8" },
      { type: "txt", value: "3" },
    ],
    answer: 0,
  },
  {
    id: 69,
    question: [
      { type: "img", value: "/quiz-images/planametriya/img39.png" },
      { type: "txt", value: "vektor berilgan." },
      { type: "img", value: "/quiz-images/planametriya/img40.png" },
    ],
    options: [
      { type: "txt", value: "(-6;-3)" },
      { type: "txt", value: "(-3;6)" },
      { type: "txt", value: "(-2;-9)" },
    ],
    answer: 0,
  },
];

const planametriya9_sinf = [
  {
    id: 0,
    question: [
      {
        type: "txt",
        value:
          "ABC uchburchakda balandliklari H nuqtada kesishadi. Agar A=50°    B=60° bo’lsa, AHB, BHC va CHA burchaklarni toping.",
      },
    ],
    options: [
      { type: "txt", value: "110°; 130°; 120° " },
      { type: "txt", value: "110°; 120°; 130°" },
      { type: "txt", value: "130°; 110°; 120°" },
    ],
    answer: 1,
  },
  {
    id: 1,
    question: [
      {
        type: "txt",
        value:
          "ABC uchburchakning A va B uchlaridagi tashqi burchaklarining bissektrisalari D nuqtada kesishadi. Agar ADB=75° bo’lsa, uchburchakning ACB burchagini toping.",
      },
    ],
    options: [
      { type: "txt", value: "30°" },
      { type: "txt", value: "60°" },
      { type: "txt", value: "45°" },
    ],
    answer: 0,
  },
  {
    id: 2,
    question: [
      {
        type: "txt",
        value:
          "Teng yonli uchburchakning yon tomoni 10sm, asosi esa 103sm. Uchburchakning asosiga tushirilgan balandligini, yuzini va burchaklarini toping.",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/planametriya/img41.png" },
      { type: "img", value: "/quiz-images/planametriya/img42.png" },
      { type: "img", value: "/quiz-images/planametriya/img43.png" },
    ],
    answer: 0,
  },
  {
    id: 3,
    question: [
      {
        type: "txt",
        value:
          "ABC uchburchakning A va B uchlaridagi burchaklarining bissektrisalari O nuqtuda kesishadi. Agar ACB=80° bo’lsa, AOB burchakni toping.",
      },
    ],
    options: [
      { type: "txt", value: "70°" },
      { type: "txt", value: "60°" },
      { type: "txt", value: "50°" },
      { type: "txt", value: "40°" },
    ],
    answer: 2,
  },
  {
    id: 4,
    question: [
      {
        type: "txt",
        value:
          "To’gri burchakli trapetsiyaning bir burchagi 135° ga, o’rta chizigi esa 18sm ga teng. Agar trapetsiya asoslari nisbati 1:8 ga teng bo’lsa, trapetsiyaning yon tomonlarini toping:",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/planametriya/img44.png" },
      { type: "img", value: "/quiz-images/planametriya/img45.png" },
    ],
    answer: 1,
  },
  {
    id: 5,
    question: [
      {
        type: "txt",
        value:
          "To’g’ri burchakli uchburchakning gipotenuzasi 13sm, katetlarining yig’indisi esa 17sm. Uchburchak yuzini toping. ",
      },
    ],
    options: [
      { type: "txt", value: "30sm²" },
      { type: "txt", value: "20sm² " },
      { type: "txt", value: "10sm²" },
    ],
    answer: 0,
  },
  {
    id: 6,
    question: [
      {
        type: "txt",
        value:
          "Rombning diagonallaridan biri 80sm, tomoni esa 81sm. Romb yuzini toping.",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/planametriya/img46.png" },
      { type: "img", value: "/quiz-images/planametriya/img47.png" },
      { type: "img", value: "/quiz-images/planametriya/img48.png" },
    ],
    answer: 2,
  },
  {
    id: 7,
    question: [{ type: "img", value: "/quiz-images/planametriya/img49.png" }],
    options: [
      { type: "txt", value: "162; 90; 62" },
      { type: "txt", value: "72; 162; 90" },
      { type: "txt", value: "72; 152; 90" },
    ],
    answer: 1,
  },
  {
    id: 8,
    question: [{ type: "img", value: "/quiz-images/planametriya/img50.png" }],
    options: [
      { type: "img", value: "/quiz-images/planametriya/img51.png" },
      { type: "img", value: "/quiz-images/planametriya/img52.png" },
      { type: "img", value: "/quiz-images/planametriya/img53.png" },
    ],
    answer: 0,
  },
  {
    id: 9,
    question: [
      {
        type: "txt",
        value:
          "Agar ABC va DEF uchburchaklarda A=105°; B=35°; E=105°; F=40°, AC=4,4sm, AB=5,2sm, BC=7,6sm, DE=15,6sm, DF=22,8sm, EF=13,2sm bo’lsa, ular o’xshash bo’ladimi?",
      },
    ],
    options: [
      { type: "txt", value: "Ha" },
      { type: "txt", value: "Yo’q" },
    ],
    answer: 0,
  },
  {
    id: 10,
    question: [
      {
        type: "txt",
        value:
          "Ikkita o’xshash uchburchak yuzlari 65m² va 260m². Birinchi uchburchakning bir tomoni 6m bo’lsa, ikkinchi uchburchakning unga mos tomonini toping.",
      },
    ],
    options: [
      { type: "txt", value: "14" },
      { type: "txt", value: "16" },
      { type: "txt", value: "12" },
      { type: "txt", value: "10" },
    ],
    answer: 2,
  },
  {
    id: 11,
    question: [{ type: "img", value: "/quiz-images/planametriya/img54.png" }],
    options: [
      { type: "txt", value: "3,5" },
      { type: "txt", value: "4,5" },
      { type: "txt", value: "2,5" },
      { type: "txt", value: "1,5" },
    ],
    answer: 1,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value:
          "ABCD parallelogrammning CD tomonidan E nuqta olingan. AE va BC nurlar F nuqtada kesishadi. Agar DE=8sm, EC=4sm, BC=7sm, AE=10sm bo’lsa, EF va FC ni toping",
      },
    ],
    options: [
      { type: "txt", value: "5; 4,5" },
      { type: "txt", value: "5; 3,5" },
      { type: "txt", value: "4; 3,5" },
    ],
    answer: 1,
  },
  {
    id: 13,
    question: [{ type: "img", value: "/quiz-images/planametriya/img55.png" }],
    options: [
      { type: "txt", value: "10" },
      { type: "txt", value: "11" },
      { type: "txt", value: "8" },
      { type: "txt", value: "13" },
    ],
    answer: 2,
  },
  {
    id: 14,
    question: [
      {
        type: "txt",
        value:
          "ABC uchburchakning AC tomonida D nuqta olingan. Agar ABC   BDC bo’lsa, ABC va BDC uchburchaklar o’xshash. Shuningdek, 3AB=4BD va BC=9sm bo’lsa, AC kesmani toping.",
      },
    ],
    options: [
      { type: "txt", value: "15" },
      { type: "txt", value: "17" },
      { type: "txt", value: "12" },
    ],
    answer: 2,
  },
  {
    id: 15,
    question: [
      {
        type: "txt",
        value:
          "ABC uchburchakda AB=15m, AC=20m, BC=32m. Uchburchakning AB tomoniga AD=9m kesma, AC tomoniga esa AE=12m kesma qo’yildi. DE kesmani toping.",
      },
    ],
    options: [
      { type: "txt", value: "18,6" },
      { type: "txt", value: "19,2" },
      { type: "txt", value: "16,7" },
    ],
    answer: 1,
  },
  {
    id: 16,
    question: [
      {
        type: "txt",
        value:
          "Ikkita o’xshash uchburchak 6 va 24 ga teng. Ulardan birining perimetri ikkinchisinikidan 6 ga teng. Ulardan birining perimetri ikkinchisinikidan 6 ga ortiq. Katta uchburchakning perimetrini toping. ",
      },
    ],
    options: [
      { type: "txt", value: "6" },
      { type: "txt", value: "18" },
      { type: "txt", value: "12" },
      { type: "txt", value: "20" },
    ],
    answer: 2,
  },
  {
    id: 17,
    question: [
      {
        type: "txt",
        value:
          "Asosi 12sm, balandligi 8sm bo’lgan teng yonli uchburchak ichiga kvadrat shunday ichki chizilganki, kvadratning ikkita uchi uchburchak asosida, qolgan ikki uchi esa yon tomonlarda yotadi. Kvadrat tomonini toping.",
      },
    ],
    options: [
      { type: "txt", value: "3,6" },
      { type: "txt", value: "4,8" },
      { type: "txt", value: "5,2" },
    ],
    answer: 1,
  },
  {
    id: 18,
    question: [
      {
        type: "txt",
        value:
          "Trapetsiyaning asoslari 6sm va 9sm, balandligi 10sm. Trapetsiyaning diagonallari kesishgan nuqtadan asoslarigacha bo’lgan masofani toping.",
      },
    ],
    options: [
      { type: "txt", value: "4sm 6sm" },
      { type: "txt", value: "4sm 5sm" },
      { type: "txt", value: "7sm 6sm" },
    ],
    answer: 0,
  },
  {
    id: 19,
    question: [
      {
        type: "txt",
        value:
          "ABCD trapetsiyaning AB va CD yon tomonlari davom ettirilsa, E nuqtada kesishadi. Agar AB=5sm, BC=10sm, CD=6sm, AD=15sm bo’lsa, AED uchburchak  yuzini  toping. ",
      },
    ],
    options: [
      { type: "txt", value: "100" },
      { type: "txt", value: "106" },
      { type: "txt", value: "108" },
      { type: "txt", value: "110" },
    ],
    answer: 2,
  },
  {
    id: 20,
    question: [
      {
        type: "txt",
        value:
          "Katetlari 10sm va 12sm bo’lgan to’g’ri burchakli uchburchaklar bitta burchagi umumiy bo’lgan kvadrat ichki chizilgan. Agar kvadratning bitta uchi gipotenuzada ekanligi ma’lum bo’lsa, kvadratning tomonini toping. ",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/planametriya/img56.png" },
      { type: "img", value: "/quiz-images/planametriya/img57.png" },
      { type: "img", value: "/quiz-images/planametriya/img58.png" },
    ],
    answer: 1,
  },
  {
    id: 21,
    question: [
      {
        type: "txt",
        value:
          "ABC uchburchaklarning AD bessektrisasi o’tkazilgan. Agar CD=4,5m; BD=13,5m va ABC uchburchak perimetri 42m bo’lsa, uning AB va AC tomonlarini toping.",
      },
    ],
    options: [
      { type: "txt", value: "15; 6" },
      { type: "txt", value: "18; 6" },
      { type: "txt", value: "17; 5" },
    ],
    answer: 1,
  },
  {
    id: 22,
    question: [
      {
        type: "txt",
        value:
          "ABC uchburchak medianalari kesishgan N nuqtadan AB va BC tomonlargacha bo’lgan masofalar mos ravishda 3dm va 4dm. Agar AB=8dm bo’lsa, BC tomonini hisoblang.",
      },
    ],
    options: [
      { type: "txt", value: "5" },
      { type: "txt", value: "7" },
      { type: "txt", value: "9" },
      { type: "txt", value: "6" },
    ],
    answer: 3,
  },
  {
    id: 23,
    question: [
      {
        type: "txt",
        value:
          "Trapetsiyaning diagonallari kesishish nuqtusidan o’tuvchi to’g’ri chiziq trapetsiya asoslaridan birini m:n nisbatda bo’ladi. Bu to’g’ri chiziq ikkinchi asosni qanday nisbatda bo’ladi?",
      },
    ],
    options: [
      { type: "txt", value: "m:n" },
      { type: "txt", value: "n:m" },
      { type: "txt", value: "n·m" },
    ],
    answer: 1,
  },
  {
    id: 24,
    question: [{ type: "img", value: "/quiz-images/planametriya/img59.png" }],
    options: [
      { type: "txt", value: "2S" },
      { type: "img", value: "/quiz-images/planametriya/img60.png" },
      { type: "txt", value: "S:2" },
    ],
    answer: 2,
  },
  {
    id: 25,
    question: [
      {
        type: "txt",
        value:
          "Ikkita o’xshash uchburchakning yuzi 50dm² va 32dm², ularning perimetrlari  yig’indisi  117dm bo’lsa, har bir uchburchakning  perimetrini  toping.",
      },
    ],
    options: [
      { type: "txt", value: "65;55" },
      { type: "txt", value: "67;57" },
      { type: "txt", value: "65;52" },
    ],
    answer: 2,
  },
  {
    id: 26,
    question: [
      {
        type: "txt",
        value:
          "ABC uchburchak  o’xshashlik  almashtirishida  A*B*C  uchburchakka almashdi. Agar  o’xshashlik  koeffitsiyenti  0,6 ga  va  ABC  uchburchak  perimetri  12sm ga teng bo’lsa, A*B*C  uchburchak  perimetrini toping.",
      },
    ],
    options: [
      { type: "txt", value: "6,8" },
      { type: "txt", value: "7,2" },
      { type: "txt", value: "8,7" },
    ],
    answer: 1,
  },
  {
    id: 27,
    question: [
      {
        type: "txt",
        value:
          "Yuzlari 8sm² va 32sm² bo’lgan ikkita o’xshash uchburchak perimetrlarining  yig’indisi  48sm ga teng. Uchburchakning perimetrlarini toping.",
      },
    ],
    options: [
      { type: "txt", value: "16;32" },
      { type: "txt", value: "18;32" },
      { type: "txt", value: "16;36" },
    ],
    answer: 0,
  },
  {
    id: 28,
    question: [
      {
        type: "txt",
        value:
          "To’rtburchak shaklidagi paxta maydoni xaritada 12sm² bo’lgan to`rtburchak bilan tasvirlangan. Agar xarita mashtabi 1:1000 bo’lsa, maydonning haqiqiy yuzini hisoblang.",
      },
    ],
    options: [
      { type: "txt", value: "1,6m²" },
      { type: "txt", value: "1,2m²" },
      { type: "txt", value: "1,9m²" },
    ],
    answer: 1,
  },
  {
    id: 29,
    question: [
      {
        type: "txt",
        value:
          "Perimetri 84sm bo’lgan uchburchakning bir tomoniga parallel qilib o’tkazilgan to’g’ri chiziq undan perimetri 42sm va yuzi 26sm² ga teng uchburchak  ajratdi. Berilgan uchburchak yuzini toping.",
      },
    ],
    options: [
      { type: "txt", value: "100sm²" },
      { type: "txt", value: "102sm²" },
      { type: "txt", value: "104sm²" },
    ],
    answer: 2,
  },
  {
    id: 30,
    question: [{ type: "img", value: "/quiz-images/planametriya/img61.png" }],
    options: [
      { type: "txt", value: "5,6" },
      { type: "txt", value: "6,6" },
      { type: "txt", value: "7,6" },
    ],
    answer: 1,
  },
  {
    id: 31,
    question: [
      {
        type: "txt",
        value:
          "Ikkita  o’xshash  uchburchakning perimetrlari 18dm va 36dm ga, yuzlarining yig’indisi 30dm² ga teng. Katta uchburchak yuzini toping.",
      },
    ],
    options: [
      { type: "txt", value: "36" },
      { type: "txt", value: "25" },
      { type: "txt", value: "24" },
    ],
    answer: 2,
  },
  {
    id: 32,
    question: [
      {
        type: "txt",
        value:
          "ABC uchburchakning AB va BC tomolari 4 ta teng kesmalarga bo`lindi v abo`linish nuqtalari AC tomoga parallel kesmalar bilan tutashtirildi. Agar AC 24 sm bo`lsa, hosil bo`lgan kesmalar uzunliklarini toping.",
      },
    ],
    options: [
      { type: "txt", value: "6 sm,  12 sm,   18 sm" },
      { type: "txt", value: "5 sm,    10 sm,    15 sm" },
      { type: "txt", value: "6 sm,    12 sm,    20 sm" },
    ],
    answer: 1,
  },
  {
    id: 33,
    question: [
      { type: "txt", value: "Agar ABC uchburchakda ∠ C=90°," },
      { type: "img", value: "/quiz-images/planametriya/img62.png" },
      {
        type: "txt",
        value: "va BC=16 sm bo`lsa, uchburchakning qolgan tomonlarini toping.",
      },
    ],
    options: [
      { type: "txt", value: "AB=30  AC=34" },
      { type: "txt", value: "AB=34    AC=30" },
      { type: "txt", value: "AB=36     AC=48" },
    ],
    answer: 1,
  },
  {
    id: 34,
    question: [
      {
        type: "txt",
        value:
          "Teng yonli uchburchakning asosiga tushirilgan balandligi 5 sm, asosi esa ",
      },
      { type: "img", value: "/quiz-images/planametriya/img63.png" },
      { type: "txt", value: "sm bo`lsa, uchburchakning burchaklarini toping." },
    ],
    options: [
      { type: "txt", value: "120°; 30°; 30°" },
      { type: "txt", value: "150°; 15°;  15°" },
      { type: "txt", value: "135°; 25°; 25°" },
    ],
    answer: 0,
  },
  {
    id: 35,
    question: [
      {
        type: "txt",
        value:
          "ABCD rombda sinA=0.8  va AB=15 sm bo`lsa, rombning yuzini toping.",
      },
    ],
    options: [
      { type: "txt", value: "108 sm²" },
      { type: "txt", value: "180 sm²" },
      { type: "txt", value: "150 sm²" },
    ],
    answer: 1,
  },
  {
    id: 36,
    question: [
      {
        type: "txt",
        value:
          "ABC uchburchakda BD balandlik, AC=7 sm, AD=2 sm va tg A=3 bo`lsa, uchburchak yuzini toping.",
      },
    ],
    options: [
      { type: "txt", value: "21 sm²" },
      { type: "txt", value: "25 sm²" },
      { type: "txt", value: "28 sm²" },
    ],
    answer: 0,
  },
  {
    id: 37,
    question: [
      { type: "txt", value: "Dioganallari" },
      { type: "img", value: "/quiz-images/planametriya/img64.png" },
      {
        type: "txt",
        value: "sm va 5 sm bo`lgan rombning burchaklarini toping.",
      },
    ],
    options: [
      { type: "txt", value: "120°;  120°; 60°; 60°" },
      { type: "txt", value: "130°; 130°; 50°; 50°" },
      { type: "txt", value: "110°; 110°; 70°; 70°" },
    ],
    answer: 0,
  },
  {
    id: 38,
    question: [
      {
        type: "txt",
        value:
          "Teng yonli uchburchakning asosi 10 sm ga teng. Bir burchagi 120° ga teng. Uning yuzini toping.",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/planametriya/img65.png" },
      { type: "img", value: "/quiz-images/planametriya/img66.png" },
      { type: "img", value: "/quiz-images/planametriya/img67.png" },
    ],
    answer: 0,
  },
  {
    id: 39,
    question: [
      {
        type: "txt",
        value:
          " Yengil mashina dovonning yuqoriga ko`tarilish qismida 340 m yo`l bosdi. Agar yo`lning gorizontga nisbatan ko`tarilish burchagi 15° bo`lsa, yengil mashina necha metr balandlikka ko`tarilgan?",
      },
    ],
    options: [
      { type: "txt", value: "≈87 m" },
      { type: "txt", value: "≈88 m" },
      { type: "txt", value: "≈86 m" },
    ],
    answer: 1,
  },
  {
    id: 40,
    question: [
      {
        type: "txt",
        value:
          "Teng yonli uchburchakning uchidagi burchagi 120°. Uchburchak balandligining asosiga nisbatini hisoblang .",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/planametriya/img68.png" },
      { type: "img", value: "/quiz-images/planametriya/img69.png" },
      { type: "img", value: "/quiz-images/planametriya/img70.png" },
    ],
    answer: 0,
  },
  {
    id: 41,
    question: [
      { type: "txt", value: "Balandligi" },
      { type: "img", value: "/quiz-images/planametriya/img71.png" },
      {
        type: "txt",
        value: "sm bo`lgan teng tomonli uchburchak perimetrini toping.",
      },
    ],
    options: [
      { type: "txt", value: "26 sm" },
      { type: "txt", value: "24 sm" },
      { type: "txt", value: "28 sm" },
    ],
    answer: 1,
  },
  {
    id: 42,
    question: [
      {
        type: "txt",
        value:
          "Balandligi 3 sm va o`tkir burchagi 30° bo`lgan rombning perimetri va yuzini hisoblang.",
      },
    ],
    options: [
      { type: "txt", value: "24 sm; 18 sm²" },
      { type: "txt", value: "16 sm; 18 sm²" },
      { type: "txt", value: "18 sm; 24 sm²" },
    ],
    answer: 0,
  },
  {
    id: 43,
    question: [
      {
        type: "txt",
        value:
          "OA nur bilan OX nur orasidagi burchak ɑ ga teng. Agar OA 2 sm ɑ=180° bo`lsa, A nuqtaning kordinatalarini toping.",
      },
    ],
    options: [
      { type: "txt", value: "(0; -2)" },
      { type: "txt", value: "(-2; 0)" },
      { type: "txt", value: "(-1; -2)" },
    ],
    answer: 0,
  },
  {
    id: 44,
    question: [
      {
        type: "txt",
        value:
          "To`g`ri to`rtburchakning 12 sm ga teng dioganali bir Tomoni bilan 30° ga teng burchak hosil qiladi. To`g`ri to`rtburchakning yuzini toping.",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/planametriya/img72.png" },
      { type: "img", value: "/quiz-images/planametriya/img73.png" },
      { type: "img", value: "/quiz-images/planametriya/img74.png" },
    ],
    answer: 0,
  },
  {
    id: 45,
    question: [
      {
        type: "txt",
        value:
          "ABC uchburchakda AB=8 sm, AC=12 sm va ∠ A=60° bo`lsa, uning AD bessektrisasini toping.",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/planametriya/img75.png" },
      { type: "img", value: "/quiz-images/planametriya/img76.png" },
      { type: "img", value: "/quiz-images/planametriya/img77.png" },
    ],
    answer: 1,
  },
  {
    id: 46,
    question: [
      {
        type: "txt",
        value:
          "ABC uchburchakda ∠A=ɑ ga uning B va C  uchlaridan tutashtirilgan balandliklari mos ravishda",
      },
      { type: "img", value: "/quiz-images/planametriya/img78.png" },
      { type: "txt", value: "bo`lsa, uchburchak yuzini toping." },
    ],
    options: [
      { type: "img", value: "/quiz-images/planametriya/img79.png" },
      { type: "img", value: "/quiz-images/planametriya/img80.png" },
      { type: "img", value: "/quiz-images/planametriya/img81.png" },
    ],
    answer: 2,
  },
  {
    id: 47,
    question: [
      {
        type: "txt",
        value:
          "Dioganali 12 sm va dioganallari orasidagi burchak 30° bo`lgan to`g`ri to`rtburchak yuzini toping.",
      },
    ],
    options: [
      { type: "txt", value: "36 sm²" },
      { type: "txt", value: "48 sm²" },
      { type: "txt", value: "54 sm²" },
    ],
    answer: 0,
  },
  {
    id: 48,
    question: [
      { type: "txt", value: "Tomoni" },
      { type: "img", value: "/quiz-images/planametriya/img82.png" },
      {
        type: "txt",
        value: "sm va o`tmas burchagi 135° bo`lgan romb yuzini toping.",
      },
    ],
    options: [
      { type: "txt", value: "36 sm²" },
      { type: "img", value: "/quiz-images/planametriya/img83.png" },
      { type: "img", value: "/quiz-images/planametriya/img84.png" },
    ],
    answer: 2,
  },
  {
    id: 49,
    question: [
      {
        type: "txt",
        value:
          "Rombning katta dioganali 18 sm va bir burchagi 120°. Rombning yuzini toping.",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/planametriya/img85.png" },
      { type: "img", value: "/quiz-images/planametriya/img86.png" },
      { type: "img", value: "/quiz-images/planametriya/img87.png" },
    ],
    answer: 1,
  },
  {
    id: 50,
    question: [
      { type: "img", value: "/quiz-images/planametriya/img88.png" },
      {
        type: "txt",
        value:
          "ga teng bo`lgan ABC uchburchakda AB=9 sm, ∠ A=45° .  Uchburchakning AC tomonini vas hu tomoniga tushurilgan balandligini toping.",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/planametriya/img89.png" },
      { type: "img", value: "/quiz-images/planametriya/img90.png" },
      { type: "img", value: "/quiz-images/planametriya/img91.png" },
    ],
    answer: 0,
  },
  {
    id: 51,
    question: [
      {
        type: "txt",
        value:
          "Agar ABC uchburchakda BC=18 dm, ∠A=42°, ∠B=62° bo`lsa, uchburchakning C burchagini, AB va AC  tomonlarini toping.",
      },
    ],
    options: [
      { type: "txt", value: "76° ; 26.1 sm; 23.8 sm" },
      { type: "txt", value: "67°; 26.1 sm; 23.8 sm" },
      { type: "txt", value: "47°; 23.8 sm ;  26.1 sm" },
    ],
    answer: 0,
  },
  {
    id: 52,
    question: [
      {
        type: "txt",
        value:
          "Agar ABC uchburchakda BC=20 m, AC=13 m va ∠A =67° bo`lsa, uchburchakning AB tomonini B va C burchaklarini toping.",
      },
    ],
    options: [
      { type: "txt", value: "AB≈21.1 m; ∠В≈37°; ∠С≈76° " },
      { type: "txt", value: "AB≈20 m; ∠В≈42°; ∠С≈67° " },
      { type: "txt", value: "AB≈22 m; ∠В≈67°; ∠С≈42° " },
    ],
    answer: 0,
  },
  {
    id: 53,
    question: [
      {
        type: "txt",
        value:
          "ABC uchburchak uchun AB:BC:CA=sinC:sinA:sinB tenglik o`rinli bo`lishni asoslang.  sinA:sinB:sinC=3:5:7  to`g`rib o`lishi mumkinmi?",
      },
    ],
    options: [
      { type: "txt", value: "Mumkin emas" },
      { type: "txt", value: "Mumkin" },
    ],
    answer: 1,
  },
  {
    id: 54,
    question: [
      {
        type: "txt",
        value:
          "Tomonlari 6 m, 7 m va 8 m bo`lgan uchburchak medeanalarini toping.",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/planametriya/img93.png" },
      { type: "img", value: "/quiz-images/planametriya/img94.png" },
      { type: "img", value: "/quiz-images/planametriya/img95.png" },
    ],
    answer: 0,
  },
  {
    id: 55,
    question: [
      {
        type: "txt",
        value:
          "Tomonlari 5 sm va 7 sm bo`lgan parallelogrammning bir burchagi 120° ga teng. Uning deoganallarini toping.",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/planametriya/img96.png" },
      { type: "img", value: "/quiz-images/planametriya/img97.png" },
      { type: "img", value: "/quiz-images/planametriya/img98.png" },
    ],
    answer: 0,
  },
  {
    id: 56,
    question: [
      {
        type: "txt",
        value:
          "Parallelogrammning dioganallari 10 sm va 12 sm , ularning orasidagi burchagi 60° ga teng. Parallelogrammning tomonlarini toping.",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/planametriya/img99.png" },
      { type: "img", value: "/quiz-images/planametriya/img100.png" },
      { type: "img", value: "/quiz-images/planametriya/img101.png" },
    ],
    answer: 1,
  },
  {
    id: 57,
    question: [
      {
        type: "txt",
        value:
          "ABC uchburchakda AB 10 sm, BC 12 m va sinB=0.6 bo`lsa, AC tomonini toping.",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/planametriya/img102.png" },
      { type: "img", value: "/quiz-images/planametriya/img103.png" },
      { type: "img", value: "/quiz-images/planametriya/img104.png" },
    ],
    answer: 2,
  },
  {
    id: 58,
    question: [
      {
        type: "txt",
        value:
          "Uchburchakning uchta tomoni  berilgan. a=5, b=4, c=4. Uchburchak o`tkir burchakli,  to`g`ri burchakli yoki o`tmas burchakli ekanligini aniqlang.",
      },
    ],
    options: [
      { type: "txt", value: "To`g`ri" },
      { type: "txt", value: "O`tkir" },
      { type: "txt", value: "O`tmas" },
    ],
    answer: 1,
  },
  {
    id: 59,
    question: [
      { type: "img", value: "/quiz-images/planametriya/img105.png" },
      {
        type: "txt",
        value: "vektorlar orasidan o`zaro perependikulyar juftlarini toping.",
      },
    ],
    options: [
      { type: "txt", value: "a⊥b;    c⊥d" },
      { type: "txt", value: "a⊥c;    b⊥d" },
      { type: "txt", value: "d⊥a;    b⊥c" },
    ],
    answer: 0,
  },
  {
    id: 60,
    question: [
      { type: "txt", value: "x ni qanday qiymatlarida" },
      { type: "img", value: "/quiz-images/planametriya/img106.png" },
      { type: "txt", value: "vektorlar o`zaro perpendikulyar bo`ladi. " },
    ],
    options: [
      { type: "txt", value: "–7.6" },
      { type: "txt", value: "-7.7" },
      { type: "txt", value: "-7.5" },
    ],
    answer: 2,
  },
  {
    id: 61,
    question: [
      {
        type: "txt",
        value:
          "Uchburchakning ikki Tomoni va bu tomonlaridan birining qarshisidagi burchagi berilgan. Uchburchakning qolgan Tomoni va burchaklarini toping. a= 12 sm, b= 5sm, ɑ=120°",
      },
    ],
    options: [
      { type: "txt", value: "5 sm" },
      { type: "txt", value: "6 sm" },
      { type: "txt", value: "7 sm" },
      { type: "txt", value: "9 sm" },
    ],
    answer: 0,
  },
  {
    id: 62,
    question: [
      {
        type: "txt",
        value:
          "Tomonlari 6 sm va 8sm bo`lgan parallelogrammning bir dioganali 12 sm bo`lsa, uning ikkinchi dioganalini toping.",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/planametriya/img107.png" },
      { type: "img", value: "/quiz-images/planametriya/img108.png" },
      { type: "img", value: "/quiz-images/planametriya/img109.png" },
    ],
    answer: 0,
  },
  {
    id: 63,
    question: [
      {
        type: "txt",
        value:
          "Teng yonli tropetsiyaning kichik asosi yon tomoniga teng, katta asosi esa 20 sm. Agar tropetsiyaning bir burchagi 120° bo`lsa, uning perimetrini toping.",
      },
    ],
    options: [
      { type: "txt", value: "49 sm" },
      { type: "txt", value: "51 sm" },
      { type: "txt", value: "50 sm" },
    ],
    answer: 2,
  },
  {
    id: 64,
    question: [
      {
        type: "txt",
        value:
          "Tomonlari 16 sm, 10 sm va 10 sm bo`lgan uchburchakka tashqi chizilgan aylana radiusini toping.",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/planametriya/img110.png" },
      { type: "img", value: "/quiz-images/planametriya/img111.png" },
      { type: "img", value: "/quiz-images/planametriya/img112.png" },
    ],
    answer: 0,
  },
  {
    id: 65,
    question: [
      {
        type: "txt",
        value:
          "Asoslari 9 sm va 16 sm bo`lgan teng yonli tropetsiya aylanaga tashqi chizilgan aylana radiusini toping.",
      },
    ],
    options: [
      { type: "txt", value: "8" },
      { type: "txt", value: "6" },
      { type: "txt", value: "5" },
    ],
    answer: 0,
  },
  {
    id: 66,
    question: [
      {
        type: "txt",
        value:
          "Tomoni 10 sm bo`lgan muntazam beshburchakning kichik dioganalini hisoblang.",
      },
    ],
    options: [
      { type: "txt", value: "12" },
      { type: "txt", value: "15" },
      { type: "txt", value: "13" },
    ],
    answer: 1,
  },
  {
    id: 67,
    question: [
      {
        type: "txt",
        value:
          "Katetlari 10 sm va 24 sm bo`lgan to`g`ri burchakli uchburchakka ichki chizilgan aylana radiusini toping. ",
      },
    ],
    options: [
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
    ],
    answer: 0,
  },
];

const DTM_milliy_sertifikat = [
  {
    id: 0,
    question: [
      { type: "txt", value: "Ifodaning qiymatini toping:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img1.png" },
    ],
    options: [
      { type: "txt", value: "-24" },
      { type: "txt", value: "-20" },
      { type: "txt", value: "-10" },
      { type: "txt", value: "10" },
    ],
    answer: 2,
  },
  {
    id: 1,
    question: [{ type: "txt", value: "Hisoblang: (-2)³:(-2)²-(-5)²(3)⁰" }],
    options: [
      { type: "txt", value: "-27" },
      { type: "txt", value: "-25" },
      { type: "txt", value: "-20" },
      { type: "txt", value: "-18" },
    ],
    answer: 0,
  },
  {
    id: 2,
    question: [{ type: "txt", value: "Hisoblang: |-3|+|4|-|-3|-|-2|" }],
    options: [
      { type: "txt", value: "7" },
      { type: "txt", value: "6" },
      { type: "txt", value: "5" },
      { type: "txt", value: "4" },
    ],
    answer: 1,
  },
  {
    id: 3,
    question: [
      { type: "txt", value: "Hisoblang:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img2.png" },
    ],
    options: [
      { type: "txt", value: "143" },
      { type: "txt", value: "130" },
      { type: "txt", value: "85" },
      { type: "txt", value: "68" },
    ],
    answer: 3,
  },
  {
    id: 4,
    question: [
      { type: "txt", value: "Ifodani soddalashtiring:  (x-4)(x+3)-(x+1)(x+2)" },
    ],
    options: [
      { type: "txt", value: "2 x+10" },
      { type: "txt", value: "-2 x+5" },
      { type: "txt", value: "-4 x-14" },
      { type: "txt", value: "-2 x-10" },
    ],
    answer: 2,
  },
  {
    id: 5,
    question: [
      { type: "txt", value: "Ifodani soddalashtiring: (x+y)²+(x-y)²" },
    ],
    options: [
      { type: "txt", value: "2x²-2y²" },
      { type: "txt", value: "2xy" },
      { type: "txt", value: "2x²+2y²" },
      { type: "txt", value: "4xy" },
    ],
    answer: 2,
  },
  {
    id: 6,
    question: [
      { type: "txt", value: "Agar x=212 bo’lsa," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img3.png" },
      { type: "txt", value: "ifodaning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "112" },
      { type: "txt", value: "210" },
      { type: "txt", value: "214" },
      { type: "txt", value: "412" },
    ],
    answer: 1,
  },
  {
    id: 7,
    question: [{ type: "txt", value: "Hisoblang: 78²+44∙78+22²" }],
    options: [
      { type: "txt", value: "25⁴" },
      { type: "txt", value: "20⁴" },
      { type: "txt", value: "12⁴" },
      { type: "txt", value: "10⁴" },
    ],
    answer: 3,
  },
  {
    id: 8,
    question: [
      {
        type: "txt",
        value:
          "a, b va c musbat butun sonlardir. Agar a ∙b=17 va b ∙c=15 tengliklar o’rinli bo’lsa, a+b+c ning qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "36" },
      { type: "txt", value: "33" },
      { type: "txt", value: "30" },
      { type: "txt", value: "27" },
    ],
    answer: 1,
  },
  {
    id: 9,
    question: [
      {
        type: "txt",
        value:
          "a, b va c manfiy butun sonlardir. Agar a ∙b=18 va a ∙c=12 tengliklar o’rinli bo’lsa, b+c-a ning eng katta qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "-4" },
      { type: "txt", value: "-3" },
      { type: "txt", value: "-1" },
      { type: "txt", value: "1" },
    ],
    answer: 3,
  },
  {
    id: 10,
    question: [
      { type: "txt", value: "a va b musbat butun sonlardir. Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img4.png" },
      {
        type: "txt",
        value:
          "tenglik o’rinli bo’lsa, a-3b ning eng kichik qiymatini toping. ",
      },
    ],
    options: [
      { type: "txt", value: "-4" },
      { type: "txt", value: "-2" },
      { type: "txt", value: "-1" },
      { type: "txt", value: "3" },
    ],
    answer: 2,
  },
  {
    id: 11,
    question: [
      { type: "txt", value: "a, b va c musbat butun sonlari uchun 3a=7b va" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img5.png" },
      {
        type: "txt",
        value:
          "tengliklar o’rinli bo’lsa, a+b+c ning eng kichik qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "56" },
      { type: "txt", value: "57" },
      { type: "txt", value: "58" },
      { type: "txt", value: "59" },
    ],
    answer: 3,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value:
          "Bir-biridan farqli ikki xonali 6ta natural sonlarning yig’indisi 526 ga teng. Bu sonlardan eng kichigi nechaga teng bo’lishi mumkin?",
      },
    ],
    options: [
      { type: "txt", value: "32" },
      { type: "txt", value: "38" },
      { type: "txt", value: "39" },
      { type: "txt", value: "41" },
    ],
    answer: 3,
  },
  {
    id: 13,
    question: [
      { type: "txt", value: "a, b va c musbat butun sonlar bo’lib," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img6.png" },
      {
        type: "txt",
        value:
          "tenglik bajarilsa, a+b+c yig’indi eng ko’pi bilan nechaga teng bo’lishi mumkin?",
      },
    ],
    options: [
      { type: "txt", value: "57" },
      { type: "txt", value: "59" },
      { type: "txt", value: "61" },
      { type: "txt", value: "63" },
    ],
    answer: 0,
  },
  {
    id: 14,
    question: [
      {
        type: "txt",
        value:
          "x va y natural sonlar bo’lib, x+y=35 tenglik bajarilsa, (x+1)·(y+1) ifodaning eng katta qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "342" },
      { type: "txt", value: "346" },
      { type: "txt", value: "354" },
      { type: "txt", value: "362" },
    ],
    answer: 0,
  },
  {
    id: 15,
    question: [
      { type: "txt", value: "x va y natural sonlar bo’lib," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img7.png" },
      {
        type: "txt",
        value:
          "tenglik bajariladigan y ning qabul qilishi mumkin bo’lgan qiymatlari yig’indisini toping.",
      },
    ],
    options: [
      { type: "txt", value: "22" },
      { type: "txt", value: "23" },
      { type: "txt", value: "24" },
      { type: "txt", value: "25" },
    ],
    answer: 0,
  },
  {
    id: 16,
    question: [
      {
        type: "txt",
        value:
          "a, b va c biror raqamlar bo`lsin. a-b=6 va a-c=3 tengliklar o`rinli bo`lsa, a+b+c yig`indining eng katta qiymati nimaga teng?",
      },
    ],
    options: [
      { type: "txt", value: "7" },
      { type: "txt", value: "8" },
      { type: "txt", value: "9" },
      { type: "txt", value: "10" },
    ],
    answer: 2,
  },
  {
    id: 17,
    question: [
      { type: "txt", value: "x, y va z musbat butun sonlardir. Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img8.png" },
      {
        type: "txt",
        value:
          "tenglik bajarilsa, x+y+z yig`indi quyidagilardan qaysi biriga teng bo`lishi mumkin?",
      },
    ],
    options: [
      { type: "txt", value: "8" },
      { type: "txt", value: "9" },
      { type: "txt", value: "12" },
      { type: "txt", value: "13" },
    ],
    answer: 1,
  },
  {
    id: 18,
    question: [{ type: "txt", value: "Quyidagilardan qaysi biri toq son?" }],
    options: [
      { type: "txt", value: "4!" },
      { type: "txt", value: "2⁵+6⁷" },
      { type: "txt", value: "3¹⁴+5¹⁵" },
      { type: "txt", value: "7⁷+2³⁰" },
    ],
    answer: 3,
  },
  {
    id: 19,
    question: [
      {
        type: "txt",
        value:
          "Agar x∙y∙z> bo`lsa, javoblardan qaysi biri x, y va z ning ishoralariga mos keladi?",
      },
    ],
    options: [
      { type: "txt", value: "+,+,–" },
      { type: "txt", value: "–,–,–" },
      { type: "txt", value: "–,+,–" },
      { type: "txt", value: "+,–,+" },
    ],
    answer: 2,
  },
  {
    id: 20,
    question: [
      {
        type: "txt",
        value:
          "x natural son bo`lib, 5x⁴+60! juft son bo`lsa, quyidagilardan qaysi toq son bo`ladi?",
      },
    ],
    options: [
      { type: "txt", value: "x+2" },
      { type: "txt", value: "x²+4" },
      { type: "txt", value: "3x+5" },
      { type: "txt", value: "x²+x" },
    ],
    answer: 2,
  },
  {
    id: 21,
    question: [
      {
        type: "txt",
        value:
          "x butun son bo`lib, (x+2)(x+4)(x+6) toq son bo`lsa, quyidagilardan qaysi juft son bo`ladi?",
      },
    ],
    options: [
      { type: "txt", value: "x²" },
      { type: "txt", value: "x+2" },
      { type: "txt", value: "3x+4" },
      { type: "txt", value: "7x+1" },
    ],
    answer: 3,
  },
  {
    id: 22,
    question: [
      {
        type: "txt",
        value:
          "a, b va c biror haqiqiy son bo`lib, a⁴b³c⁷> 0 ma’lumotdan foydalanib, quyidagi ifodalardan qaysilari har doim o`rinli? 1) a – manfiy   2) b/c – musbat   3) a+c – musbat",
      },
    ],
    options: [
      { type: "txt", value: "faqat 1" },
      { type: "txt", value: "faqat 2" },
      { type: "txt", value: "1 va 2" },
      { type: "txt", value: "2 va 3" },
    ],
    answer: 1,
  },
  {
    id: 23,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img9.png" },
      {
        type: "txt",
        value:
          "ifoda toq son bo`ladigan n ning barcha qiymatlari kvadratlari yig`indisini toping.",
      },
    ],
    options: [
      { type: "txt", value: "13" },
      { type: "txt", value: "17" },
      { type: "txt", value: "20" },
      { type: "txt", value: "24" },
    ],
    answer: 0,
  },
  {
    id: 24,
    question: [
      {
        type: "txt",
        value:
          "x, y, va z haqiqiy sonlar bo`lib, quyidagi ma’lumotlardan foydalangan holda, x, y va z ning ishoralarini aniqlang.  1)x²y<0   2)",
      },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img10.png" },
      { type: "txt", value: "3) x ∙y∙z > 0" },
    ],
    options: [
      { type: "txt", value: "+,+,+" },
      { type: "txt", value: "-,-,-" },
      { type: "txt", value: "+,–,–" },
      { type: "txt", value: "+,–,+" },
    ],
    answer: 2,
  },
  {
    id: 25,
    question: [
      {
        type: "txt",
        value:
          "x, y, va z – haqiqiy sonlar. Agar 3x + y=z²+z tenglik o`rinli bo`lsa, quyidagi ifodalardan qaysilari doimo juft bo`ladi? 1)x ∙y   2)",
      },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img11.png" },
      { type: "txt", value: "3x+5y" },
    ],
    options: [
      { type: "txt", value: "faqat 1" },
      { type: "txt", value: "faqat 2" },
      { type: "txt", value: "faqat 3" },
      { type: "txt", value: "1 va 3" },
    ],
    answer: 2,
  },
  {
    id: 26,
    question: [
      { type: "txt", value: "a, b va c ketma – ket toq sonlar bo`lib," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img12.png" },
      {
        type: "txt",
        value:
          "tenglik o`rinli bo`lsa, shu sonlar ichidan eng kichigini toping. ",
      },
    ],
    options: [
      { type: "txt", value: "33" },
      { type: "txt", value: "27" },
      { type: "txt", value: "31" },
      { type: "txt", value: "25" },
    ],
    answer: 1,
  },
  {
    id: 27,
    question: [
      { type: "txt", value: "Hisoblang:   1∙3-3∙5+5∙7-7∙9+…+57∙59-59∙61" },
    ],
    options: [
      { type: "txt", value: "-1860" },
      { type: "txt", value: "-1760" },
      { type: "txt", value: "-1800" },
      { type: "txt", value: "-1700" },
    ],
    answer: 0,
  },
  {
    id: 28,
    question: [
      {
        type: "txt",
        value:
          "Musbat sonlardan tuzilgan quyidagi ketma– ketlikning 104–o`rinda turgan sonni toping: 1, 2, 2, 3, 3, 3, 4, 4, 4, 4, 5,…",
      },
    ],
    options: [
      { type: "txt", value: "11" },
      { type: "txt", value: "12" },
      { type: "txt", value: "13" },
      { type: "txt", value: "14" },
    ],
    answer: 3,
  },
  {
    id: 29,
    question: [
      { type: "txt", value: "Hisoblang:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img13.png" },
    ],
    options: [
      { type: "txt", value: "40" },
      { type: "txt", value: "56" },
      { type: "txt", value: "60" },
      { type: "txt", value: "72" },
    ],
    answer: 0,
  },
  {
    id: 30,
    question: [
      { type: "txt", value: "Hisoblang:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img14.png" },
    ],
    options: [
      { type: "txt", value: "28" },
      { type: "txt", value: "32" },
      { type: "txt", value: "36" },
      { type: "txt", value: "42" },
    ],
    answer: 3,
  },
  {
    id: 31,
    question: [
      {
        type: "txt",
        value: "x= 23·5! bo`lsa, 7!+6!-2·5! ni x orqali ifodalang. ",
      },
    ],
    options: [
      { type: "txt", value: "2x" },
      { type: "txt", value: "6x" },
      { type: "txt", value: "8x" },
      { type: "txt", value: "12x" },
    ],
    answer: 0,
  },
  {
    id: 32,
    question: [
      { type: "txt", value: "(2n-4)!=(20-n)! tenglikdan foydalanib," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img15.png" },
      { type: "txt", value: "ifodaning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "7" },
      { type: "txt", value: "56" },
      { type: "txt", value: "8" },
      { type: "txt", value: "42" },
    ],
    answer: 1,
  },
  {
    id: 33,
    question: [{ type: "txt", value: "Tenglamani yeching: 5!·x+6!=4!∙x+7!" }],
    options: [
      { type: "txt", value: "56" },
      { type: "txt", value: "45" },
      { type: "txt", value: "42" },
      { type: "txt", value: "36" },
    ],
    answer: 1,
  },
  {
    id: 34,
    question: [{ type: "txt", value: "Hisoblang: ((x-3)!+(3-x)!)!∙x!" }],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "6" },
      { type: "txt", value: "12" },
      { type: "txt", value: "48" },
    ],
    answer: 2,
  },
  {
    id: 35,
    question: [
      {
        type: "txt",
        value:
          "3∙5∙7∙9∙…∙33 ko’paytmaning har bir ko`paytuvchisini birga orttirib, hisoblanganda quyidagilardan qaysi biriga teng bo`ladi?",
      },
    ],
    options: [
      { type: "txt", value: "33!·2¹⁶" },
      { type: "txt", value: "17!·2¹⁶" },
      { type: "txt", value: "16!·2¹⁶" },
      { type: "txt", value: "16!·2¹⁴" },
    ],
    answer: 1,
  },
  {
    id: 36,
    question: [
      {
        type: "txt",
        value:
          "m ning qanday natural qiymatida quyidagi ifoda biror butun sonning kvadrati bo’ladi?",
      },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img16.png" },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
    ],
    answer: 0,
  },
  {
    id: 37,
    question: [
      { type: "txt", value: "Hisoblang:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img17.png" },
    ],
    options: [
      { type: "txt", value: "4" },
      { type: "txt", value: "8" },
      { type: "txt", value: "12" },
      { type: "txt", value: "16" },
    ],
    answer: 3,
  },
  {
    id: 38,
    question: [
      {
        type: "txt",
        value:
          "Quyidagi ma’lumotlardan foydalanib, (n-m)! ni toping.  (n-1)!=24, (m+2)!=120",
      },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "6" },
      { type: "txt", value: "24" },
    ],
    answer: 2,
  },
  {
    id: 39,
    question: [
      { type: "txt", value: "Tenglikdan n ni toping." },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img18.png" },
      { type: "txt", value: "=720" },
    ],
    options: [
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
      { type: "txt", value: "7" },
      { type: "txt", value: "8" },
    ],
    answer: 1,
  },
  {
    id: 40,
    question: [
      {
        type: "txt",
        value: "9!-8!+7! Ifoda quyidagilarning qaysi biriga bo’linmaydi?",
      },
    ],
    options: [
      { type: "txt", value: "51" },
      { type: "txt", value: "12" },
      { type: "txt", value: "25" },
      { type: "txt", value: "8" },
    ],
    answer: 0,
  },
  {
    id: 41,
    question: [{ type: "txt", value: "52! Ifoda nechta nol bilan tugaydi?" }],
    options: [
      { type: "txt", value: "9" },
      { type: "txt", value: "10" },
      { type: "txt", value: "11" },
      { type: "txt", value: "12" },
    ],
    answer: 3,
  },
  {
    id: 42,
    question: [
      {
        type: "txt",
        value:
          "a va b natural son bo’lib, ((3!)!)!=4!∙a∙b! bo’lsa, a + b ning eng kichik qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "737" },
      { type: "txt", value: "742" },
      { type: "txt", value: "749" },
      { type: "txt", value: "747" },
    ],
    answer: 2,
  },
  {
    id: 43,
    question: [
      { type: "txt", value: "a va b natutal sonlardir." },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img19.png" },
      { type: "txt", value: "bo’lsa, b ning eng kichik qiymatini toping. " },
    ],
    options: [
      { type: "txt", value: "9" },
      { type: "txt", value: "8" },
      { type: "txt", value: "7" },
      { type: "txt", value: "6" },
    ],
    answer: 0,
  },
  {
    id: 44,
    question: [
      { type: "txt", value: "a, b va c musbat butun sonlardir." },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img20.png" },
      { type: "txt", value: "bo’lsa, a ning eng kichik qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "25" },
      { type: "txt", value: "175" },
      { type: "txt", value: "125" },
      { type: "txt", value: "55" },
    ],
    answer: 1,
  },
  {
    id: 45,
    question: [
      { type: "txt", value: "n – musbat butun son." },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img21.png" },
      { type: "txt", value: "ifoda toq son bo’lsa, n ning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 3,
  },
  {
    id: 46,
    question: [
      { type: "txt", value: "a va b natural sonlardir. Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img22.png" },
      { type: "txt", value: "bo’lsa, b ning eng kichik qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "16" },
      { type: "txt", value: "17" },
      { type: "txt", value: "18" },
      { type: "txt", value: "19" },
    ],
    answer: 2,
  },
  {
    id: 47,
    question: [{ type: "txt", value: "Hisoblang: 5-5∙(1+4∙10⁻²)" }],
    options: [
      { type: "txt", value: "-0,3" },
      { type: "txt", value: "-0,2" },
      { type: "txt", value: "-0,1" },
      { type: "txt", value: "0,1" },
    ],
    answer: 1,
  },
  {
    id: 48,
    question: [
      {
        type: "txt",
        value:
          "Ketma-ket ikki musbat toq sonlarning kvadratlari ayirmasi 120 ga teng. Bu sonlardan kichigini toping.",
      },
    ],
    options: [
      { type: "txt", value: "29" },
      { type: "txt", value: "17" },
      { type: "txt", value: "19" },
      { type: "txt", value: "27" },
    ],
    answer: 0,
  },
  {
    id: 49,
    question: [
      {
        type: "txt",
        value:
          "a, b va c  juft sonlar bo’lsa, quyidagilardan qaysilari doimo juft bo’lafi?",
      },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img23.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img24.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img25.png" },
    ],
    options: [
      { type: "txt", value: "faqat 1" },
      { type: "txt", value: "faqat 2" },
      { type: "txt", value: "2 va 3" },
      { type: "txt", value: "1 va 3" },
    ],
    answer: 1,
  },
  {
    id: 50,
    question: [
      { type: "txt", value: "a, b va c musbat butun sonlar uchun" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img26.png" },
      {
        type: "txt",
        value: "tenglik o’rinli bo’lsa, a+b+c yig’indi nechaga teng?",
      },
    ],
    options: [
      { type: "txt", value: "10" },
      { type: "txt", value: "9" },
      { type: "txt", value: "11" },
      { type: "txt", value: "13" },
    ],
    answer: 2,
  },
  {
    id: 51,
    question: [
      {
        type: "txt",
        value:
          "a, b va c butun sonlar va a>b>0>c bo’lsa, quyidagilardan qaysilari doimo manfiy butun son bo’ladi?  1)a∙c  2)",
      },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img27.png" },
      { type: "txt", value: "3)(c-a)∙(b-c)  4)" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img28.png" },
    ],
    options: [
      { type: "txt", value: "1 va 3" },
      { type: "txt", value: "1 va 2" },
      { type: "txt", value: "1 va 4" },
      { type: "txt", value: "1,2 va 3" },
    ],
    answer: 0,
  },
  {
    id: 52,
    question: [{ type: "txt", value: "Hisoblang: 200-199+198-197+…+4-3 " }],
    options: [
      { type: "txt", value: "98" },
      { type: "txt", value: "99" },
      { type: "txt", value: "100" },
      { type: "txt", value: "101" },
    ],
    answer: 1,
  },
  {
    id: 53,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img29.png" },
    ],
    options: [
      { type: "txt", value: "216" },
      { type: "txt", value: "264" },
      { type: "txt", value: "279" },
      { type: "txt", value: "289" },
    ],
    answer: 3,
  },
  {
    id: 54,
    question: [
      {
        type: "txt",
        value:
          "aa, bb va cc sonlar ikki xonali natural sonlar bo’lib, (aa)²+(bb)²+(cc)²=3509 tenglik o’rinli bo’lsa, a²+b²+c² ning qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "20" },
      { type: "txt", value: "24" },
      { type: "txt", value: "29" },
      { type: "txt", value: "33" },
    ],
    answer: 2,
  },
  {
    id: 55,
    question: [
      {
        type: "txt",
        value:
          "1!+2!+3!+4!+…+27! Yig’indini 12 ga bo’lgandagi qoldiqni toping.",
      },
    ],
    options: [
      { type: "txt", value: "7" },
      { type: "txt", value: "8" },
      { type: "txt", value: "9" },
      { type: "txt", value: "10" },
    ],
    answer: 2,
  },
  {
    id: 56,
    question: [
      {
        type: "txt",
        value:
          "Besh xonali 705ab natural son bo’lib, 30 ga bo’linsa, a+b ning eng katta qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "6" },
      { type: "txt", value: "9" },
      { type: "txt", value: "15" },
      { type: "txt", value: "16" },
    ],
    answer: 1,
  },
  {
    id: 57,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img30.png" },
      { type: "txt", value: "to’rt xonali natural son bo’lsa," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img31.png" },
      {
        type: "txt",
        value:
          "-(x+y+z+a) ifoda quyidagilardan qaysi biriga har doim bo’linadi?",
      },
    ],
    options: [
      { type: "txt", value: "6" },
      { type: "txt", value: "7" },
      { type: "txt", value: "8" },
      { type: "txt", value: "9" },
    ],
    answer: 3,
  },
  {
    id: 58,
    question: [
      { type: "txt", value: "c va d biror musbat sonlar uchun" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img31.png" },
      {
        type: "txt",
        value:
          "va EKUB(A; B)=100 tenglik o’rinli bo’lsa, c+d ning eng kichik qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 3,
  },
  {
    id: 59,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img32.png" },
      {
        type: "txt",
        value: "turli raqamlardan iborat uch xonali natural son bo’lsa,",
      },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img33.png" },
      { type: "txt", value: "ifodaning eng kichik qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "5" },
      { type: "txt", value: "7" },
      { type: "txt", value: "9" },
      { type: "txt", value: "12" },
    ],
    answer: 2,
  },
  {
    id: 60,
    question: [
      {
        type: "txt",
        value:
          "a va b musbat butun sonlar bo’lib EKUK(a;b)=30 tenglik o’rinli bo’lsa, a+b ifodaning eng kichik qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "9" },
      { type: "txt", value: "10" },
      { type: "txt", value: "9" },
      { type: "txt", value: "12" },
    ],
    answer: 2,
  },
  {
    id: 61,
    question: [
      { type: "txt", value: "a va b musbat butun sonlar. Agar EKUK(a;b)=6 va" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img34.png" },
      { type: "txt", value: "bo’lsa, a+2b ni toping." },
    ],
    options: [
      { type: "txt", value: "210" },
      { type: "txt", value: "108" },
      { type: "txt", value: "144" },
      { type: "txt", value: "240" },
    ],
    answer: 0,
  },
  {
    id: 62,
    question: [
      {
        type: "txt",
        value: ". a va b o`zaro tub sonlardir. Agar EKUK (a;b)=120 va",
      },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img35.png" },
      { type: "txt", value: "+19=a   bo’lsa, a+b ni toping." },
    ],
    options: [
      { type: "txt", value: "25" },
      { type: "txt", value: "27" },
      { type: "txt", value: "29" },
      { type: "txt", value: "31" },
    ],
    answer: 2,
  },
  {
    id: 63,
    question: [
      {
        type: "txt",
        value:
          " a va b natural sonlardir. Agar EKUB(a;b)=4 va a·b=192 bo’lsa, a+b ning eng kichik qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "26" },
      { type: "txt", value: "28" },
      { type: "txt", value: "30" },
      { type: "txt", value: "32" },
    ],
    answer: 1,
  },
  {
    id: 64,
    question: [
      {
        type: "txt",
        value:
          "Agar x=6!+7! va y=7!+8! bo’lsa, EKUK(x;y) ning qiymatini toping. ",
      },
    ],
    options: [
      { type: "txt", value: "7!" },
      { type: "txt", value: "5∙7!" },
      { type: "txt", value: "9!" },
      { type: "txt", value: "8!" },
    ],
    answer: 2,
  },
  {
    id: 65,
    question: [
      { type: "txt", value: "a va b musbat butun sonlar. Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img36.png" },
      { type: "txt", value: "∙EKUBa;b+EKUKa;b=96 bo’lsa, a ni toping." },
    ],
    options: [
      { type: "txt", value: "10" },
      { type: "txt", value: "12" },
      { type: "txt", value: "15" },
      { type: "txt", value: "18" },
    ],
    answer: 2,
  },
  {
    id: 66,
    question: [
      {
        type: "txt",
        value:
          "m musbat butun son bo’lsa, EKUK(13;m)-EKUB(13;m) ifoda quyidagilardan qaysi biriga teng bo’la olmaydi.",
      },
    ],
    options: [
      { type: "txt", value: "25" },
      { type: "txt", value: "57" },
      { type: "txt", value: "38" },
      { type: "txt", value: "51" },
    ],
    answer: 1,
  },
  {
    id: 67,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img37.png" },
    ],
    options: [
      { type: "txt", value: "111" },
      { type: "txt", value: "114" },
      { type: "txt", value: "120" },
      { type: "txt", value: "117" },
    ],
    answer: 2,
  },
  {
    id: 68,
    question: [
      {
        type: "txt",
        value:
          "22222 sonini 2 ga bo’lganda qoldiq a ga, 3 ga bo’lganda qoldiq b ga, 4 ga bo’lganda qoldiq c ga teng bo’lsa, a+b+c ning qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "6" },
      { type: "txt", value: "5" },
    ],
    answer: 0,
  },
  {
    id: 69,
    question: [
      {
        type: "txt",
        value:
          "a va b ketma – ket juft natural sonlar. Agar EKUB(a;b)=2x-22 va EKUK(a;b)=x+132 bo’lsa, a+b ning qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "32" },
      { type: "txt", value: "34" },
      { type: "txt", value: "36" },
      { type: "txt", value: "38" },
    ],
    answer: 1,
  },
  {
    id: 70,
    question: [
      {
        type: "txt",
        value:
          "a va b bir - biridan  farqli tub sonlar uchun quyidagi mulohazalardan qaysilari har doim o’rinli?   1) a – b tub son    2) a va b o’zaro tub sonlar",
      },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img38.png" },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2;3" },
      { type: "txt", value: "2" },
      { type: "txt", value: "1;2" },
    ],
    answer: 1,
  },
  {
    id: 71,
    question: [
      { type: "txt", value: "Tenglimani yeching:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img39.png" },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "6" },
      { type: "txt", value: "5" },
    ],
    answer: 0,
  },
  {
    id: 72,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img40.png" },
      {
        type: "txt",
        value: "tenglik o’rinli bo’lsa, 15a+12b+20c ning qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "460" },
      { type: "txt", value: "300" },
      { type: "txt", value: "480" },
      { type: "txt", value: "500" },
    ],
    answer: 1,
  },
  {
    id: 73,
    question: [
      {
        type: "txt",
        value: "a va b bir – biridan farqli haqiqiy sonlardir. Agar",
      },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img41.png" },
      { type: "txt", value: "bo’lsa," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img42.png" },
      { type: "txt", value: "ning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "32" },
      { type: "txt", value: "45" },
      { type: "txt", value: "42" },
      { type: "txt", value: "36" },
    ],
    answer: 0,
  },
  {
    id: 74,
    question: [
      { type: "txt", value: "Soddalashtiring:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img43.png" },
    ],
    options: [
      { type: "txt", value: "7" },
      { type: "txt", value: "6" },
      { type: "txt", value: "5" },
      { type: "txt", value: "4" },
    ],
    answer: 1,
  },
  {
    id: 75,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img44.png" },
      { type: "txt", value: "bo’lsa," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img45.png" },
      { type: "txt", value: "ning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img46.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img47.png" },
    ],
    answer: 0,
  },
  {
    id: 76,
    question: [
      { type: "txt", value: "Hisoblang:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img48.png" },
    ],
    options: [
      { type: "txt", value: "24" },
      { type: "txt", value: "21" },
      { type: "txt", value: "18" },
      { type: "txt", value: "15" },
    ],
    answer: 3,
  },
  {
    id: 77,
    question: [
      { type: "txt", value: "Hisoblang:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img49.png" },
    ],
    options: [
      { type: "txt", value: "2018" },
      { type: "txt", value: "2008" },
      { type: "txt", value: "2017" },
      { type: "txt", value: "2016" },
    ],
    answer: 0,
  },
  {
    id: 78,
    question: [
      { type: "txt", value: "Agar x=4⁶ bo’lsa," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img50.png" },
      { type: "txt", value: "ning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "6" },
      { type: "txt", value: "7" },
      { type: "txt", value: "8" },
      { type: "txt", value: "10" },
    ],
    answer: 3,
  },
  {
    id: 79,
    question: [
      { type: "txt", value: "Tenglamani yeching:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img51.png" },
    ],
    options: [
      { type: "txt", value: "0,25" },
      { type: "txt", value: "0,(3)" },
      { type: "txt", value: "4" },
      { type: "txt", value: "3" },
    ],
    answer: 1,
  },
  {
    id: 80,
    question: [
      {
        type: "txt",
        value: "Agar x+y=6 va x+z=2 bo’lsa, x²+xy+xz+yz ning qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "18" },
      { type: "txt", value: "15" },
      { type: "txt", value: "12" },
      { type: "txt", value: "10" },
    ],
    answer: 2,
  },
  {
    id: 81,
    question: [
      {
        type: "txt",
        value:
          "Agar a²+b²+c²=13 va a – b-c=5 bo’lsa, bc – ab – ac ning qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
      { type: "txt", value: "7" },
    ],
    answer: 2,
  },
  {
    id: 82,
    question: [
      { type: "txt", value: "Agar x²-x-1=0 bo’lsa," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img52.png" },
      { type: "txt", value: "ning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "4" },
      { type: "txt", value: "3" },
      { type: "txt", value: "-4" },
      { type: "txt", value: "-3" },
    ],
    answer: 0,
  },
  {
    id: 83,
    question: [
      { type: "txt", value: "Agar t³-10=0 bo’lsa," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img53.png" },
      { type: "txt", value: "ni t orqali ifodalang." },
    ],
    options: [
      { type: "txt", value: "t+2" },
      { type: "txt", value: "t-2" },
      { type: "txt", value: "t" },
      { type: "txt", value: "t+3" },
    ],
    answer: 1,
  },
  {
    id: 84,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img54.png" },
      { type: "txt", value: "bo’lsa, x+y+z ning qiymatini toping. " },
    ],
    options: [
      { type: "txt", value: "10" },
      { type: "txt", value: "11" },
      { type: "txt", value: "12" },
      { type: "txt", value: "14" },
    ],
    answer: 2,
  },
  {
    id: 85,
    question: [
      { type: "txt", value: "x musbat haqiqiy son bo’lib," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img55.png" },
      { type: "txt", value: "bo’lsa," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img56.png" },
      { type: "txt", value: "ning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "6" },
    ],
    answer: 1,
  },
  {
    id: 86,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img57.png" },
      { type: "txt", value: "bo’lsa," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img58.png" },
      { type: "txt", value: "ning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "2" },
      { type: "txt", value: "1" },
      { type: "txt", value: "0" },
    ],
    answer: 0,
  },
  {
    id: 87,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img59.png" },
      { type: "txt", value: "va a+b+3=c bo’lsa, a ni toping." },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "6" },
      { type: "txt", value: "15" },
      { type: "txt", value: "36" },
    ],
    answer: 1,
  },
  {
    id: 88,
    question: [
      {
        type: "txt",
        value: "a, b va c bir – biridan farqli musbat haqiqiy sonlardir. Agar",
      },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img60.png" },
      { type: "txt", value: "bo'lsa, a+b+c ni toping." },
    ],
    options: [
      { type: "txt", value: "36" },
      { type: "txt", value: "32" },
      { type: "txt", value: "30" },
      { type: "txt", value: "24" },
    ],
    answer: 0,
  },
  {
    id: 89,
    question: [
      {
        type: "txt",
        value:
          "a, b va c musbat butun sonlardir. Agar   |2a-3b|+|2b-5c|=0  bo’lsa, a +b + c ning kichik qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "32" },
      { type: "txt", value: "30" },
      { type: "txt", value: "29" },
      { type: "txt", value: "34" },
    ],
    answer: 2,
  },
  {
    id: 90,
    question: [
      {
        type: "txt",
        value: "a, b va c musbat haqiqiy sonlar uchun  a∙b+a∙c+a²=45 va ",
      },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img61.png" },
      { type: "txt", value: "tengliklar o’rinli bo’lsa, a ni toping." },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "1,5" },
      { type: "txt", value: "4" },
      { type: "txt", value: "6" },
    ],
    answer: 3,
  },
  {
    id: 91,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img62.png" },
      { type: "txt", value: "bo’lsa," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img63.png" },
      { type: "txt", value: "ni toping." },
    ],
    options: [
      { type: "txt", value: "18" },
      { type: "txt", value: "16" },
      { type: "txt", value: "14" },
      { type: "txt", value: "12" },
    ],
    answer: 2,
  },
  {
    id: 92,
    question: [
      { type: "txt", value: "Agar ⨍ x+1=2x+1! bo’lsa," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img64.png" },
      { type: "txt", value: "ning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "350" },
      { type: "txt", value: "420" },
      { type: "txt", value: "360" },
      { type: "txt", value: "400" },
    ],
    answer: 1,
  },
  {
    id: 93,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img65.png" },
      { type: "txt", value: "bo’lsa,⨍(2) ning qiymatini toping. " },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
      { type: "txt", value: "8" },
    ],
    answer: 1,
  },
  {
    id: 94,
    question: [
      {
        type: "txt",
        value: "Agar ⨍(x)=3x+1 bo’lsa, ⨍(2x-1)+⨍(x+1) ning qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "9x + 2" },
      { type: "txt", value: "9x – 7" },
      { type: "txt", value: "8x + 4" },
      { type: "txt", value: "8x – 1" },
    ],
    answer: 0,
  },
  {
    id: 95,
    question: [
      {
        type: "txt",
        value: "Agar ⨍(g(x))=2g(x)+5 bo’lsa, ⨍(2) ning qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "12" },
      { type: "txt", value: "11" },
      { type: "txt", value: "10" },
      { type: "txt", value: "9" },
    ],
    answer: 3,
  },
  {
    id: 96,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img66.png" },
      { type: "txt", value: "bo’lsa, m ni toping. " },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
    ],
    answer: 2,
  },
  {
    id: 97,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img67.png" },
      { type: "txt", value: "ning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "x³+2x²-x+3" },
      { type: "txt", value: "x³+4x²-4x+1" },
      { type: "txt", value: "x³-5x²+x+1" },
      { type: "txt", value: "x³+x²-4x+4" },
    ],
    answer: 1,
  },
  {
    id: 98,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img68.png" },
      { type: "txt", value: " bo’lsa, ⨍(3) ning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "3" },
      { type: "txt", value: "5" },
      { type: "txt", value: "7" },
    ],
    answer: 0,
  },
  {
    id: 99,
    question: [
      {
        type: "txt",
        value:
          "⨍(-1)=1 va ⨍(2)=3 bo’lib, g(x²+⨍(x))=6∙⨍(x)+x bo’lsa, g(2)+g(7) ning qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "23" },
      { type: "txt", value: "24" },
      { type: "txt", value: "25" },
      { type: "txt", value: "26" },
    ],
    answer: 2,
  },
  {
    id: 100,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img69.png" },
      { type: "txt", value: "bo’lsa ⨍(-8)+⨍(-13) ning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "19" },
      { type: "txt", value: "20" },
      { type: "txt", value: "21" },
      { type: "txt", value: "22" },
    ],
    answer: 0,
  },
  {
    id: 101,
    question: [
      { type: "txt", value: "Agar ⨍ x-3=3x+7 bo’lsa, ⨍(x) funksiyani toping." },
    ],
    options: [
      { type: "txt", value: "3x+1" },
      { type: "txt", value: "3x+4" },
      { type: "txt", value: "3x+16" },
      { type: "txt", value: "3x+12" },
    ],
    answer: 2,
  },
  {
    id: 102,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img70.png" },
      { type: "txt", value: "ning qiymatini toping." },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img71.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img72.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img73.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img74.png" },
    ],
    answer: 3,
  },
  {
    id: 103,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img75.png" },
      {
        type: "txt",
        value: "bo’lsa, g(x+1) funksiyani ⨍(x+2) orqali ifodalang.",
      },
    ],
    options: [
      { type: "txt", value: "64⨍²(x+2)" },
      { type: "txt", value: "32⨍²(x+2)" },
      { type: "txt", value: "16⨍²(x+2)" },
      { type: "txt", value: "4⨍²(x+2)" },
    ],
    answer: 3,
  },
  {
    id: 104,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img76.png" },
      { type: "txt", value: "funksiyaning aniqlanish sodasini toping." },
    ],
    options: [
      { type: "txt", value: "(0;2)" },
      { type: "txt", value: "(-4; 12]" },
      { type: "txt", value: "[0;6]" },
      { type: "txt", value: "(-4;6]" },
    ],
    answer: 2,
  },
  {
    id: 105,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img77.png" },
      { type: "txt", value: "ning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "-2" },
      { type: "txt", value: "-1,5" },
      { type: "txt", value: "-1" },
      { type: "txt", value: "0,5" },
    ],
    answer: 2,
  },
  {
    id: 106,
    question: [
      {
        type: "txt",
        value: "Agar (⨍⁻¹)⁻¹x=2x+5 bo’lsa, ⨍(2)ning qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "10" },
      { type: "txt", value: "9" },
      { type: "txt", value: "8" },
      { type: "txt", value: "7" },
    ],
    answer: 1,
  },
  {
    id: 107,
    question: [
      {
        type: "txt",
        value: "Agar ⨍⁻¹(2a-1)=5 va ⨍(5)=a+2 bo’lsa, a ni toping.",
      },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "2" },
      { type: "txt", value: "1" },
      { type: "txt", value: "0" },
    ],
    answer: 0,
  },
  {
    id: 108,
    question: [
      { type: "txt", value: "Quyidagilarga ko’ra a ni toping. " },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img78.png" },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img79.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img80.png" },
      { type: "txt", value: "2" },
    ],
    answer: 2,
  },
  {
    id: 109,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img81.png" },
      { type: "txt", value: "tenglamadan x ni toping. " },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img82.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img83.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img84.png" },
      { type: "txt", value: "7" },
    ],
    answer: 1,
  },
  {
    id: 110,
    question: [
      {
        type: "txt",
        value:
          "Agar ⨍x, g(x) va h(x) funksiyalar uchun ⨍⁻¹(hx+g(x))=h(x)∙g(x), h(4)=3 va g4=2 shart bajarilsa, ⨍(6) ning qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
      { type: "txt", value: "7" },
    ],
    answer: 1,
  },
  {
    id: 111,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img85.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img86.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img87.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img88.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img89.png" },
    ],
    answer: 3,
  },
  {
    id: 112,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img90.png" },
      { type: "txt", value: "ning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "18" },
      { type: "txt", value: "8" },
      { type: "txt", value: "6" },
      { type: "txt", value: "5" },
    ],
    answer: 0,
  },
  {
    id: 113,
    question: [
      {
        type: "txt",
        value: "Agar ⨍(3x-2)=4x+7 va g(x+15)=2x-6 bo’lsa, g(⨍(10))ni toping.",
      },
    ],
    options: [
      { type: "txt", value: "9" },
      { type: "txt", value: "10" },
      { type: "txt", value: "11" },
      { type: "txt", value: "13" },
    ],
    answer: 1,
  },
  {
    id: 114,
    question: [
      {
        type: "txt",
        value: "Agar ⨍(x)=mx+2, g(x)=4x+m va g⁻¹(⨍(2))=3 bo’lsa, m ni toping.",
      },
    ],
    options: [
      { type: "txt", value: "8" },
      { type: "txt", value: "9" },
      { type: "txt", value: "10" },
      { type: "txt", value: "7" },
    ],
    answer: 2,
  },
  {
    id: 115,
    question: [
      {
        type: "txt",
        value:
          "⨍(x-2)=x-1, g(x+2)=2x-3 ga ko’ra, ⨍(g(x)) funksiyaning teskari funksiyasini aniqlang.",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img91.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img92.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img93.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img94.png" },
    ],
    answer: 2,
  },
  {
    id: 116,
    question: [
      { type: "txt", value: "Agar ⨍gx=x+6 va" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img95.png" },
      {
        type: "txt",
        value: "bo’lsa, g⁻¹(x)funksiya quyidagilardan qaysi biriga teng?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img96.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img97.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img98.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img99.png" },
    ],
    answer: 2,
  },
  {
    id: 117,
    question: [
      { type: "txt", value: "Agar ⨍x=2x+3 va" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img100.png" },
      { type: "txt", value: "bo’lsa, ⨍(g(1))+g(⨍(2)) ni toping." },
    ],
    options: [
      { type: "txt", value: "10" },
      { type: "txt", value: "9" },
      { type: "txt", value: "8" },
      { type: "txt", value: "7" },
    ],
    answer: 1,
  },
  {
    id: 118,
    question: [
      { type: "txt", value: "Agar ⨍⁻¹(g(x))=x+1 bo’lsa," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img101.png" },
      { type: "txt", value: "ni toping." },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "0,(3)" },
      { type: "txt", value: "0,5" },
      { type: "txt", value: "2" },
    ],
    answer: 0,
  },
  {
    id: 119,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img102.png" },
      { type: "txt", value: "bo’lsa," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img103.png" },
      { type: "txt", value: "ni toping." },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "0,(3)" },
      { type: "txt", value: "0,5" },
      { type: "txt", value: "2" },
    ],
    answer: 0,
  },
  {
    id: 120,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img104.png" },
      { type: "txt", value: "bo’lsa, ⨍(1)ni toping." },
    ],
    options: [
      { type: "txt", value: "-7" },
      { type: "txt", value: "-5" },
      { type: "txt", value: "-6" },
      { type: "txt", value: "-4" },
    ],
    answer: 0,
  },
  {
    id: 121,
    question: [
      {
        type: "txt",
        value:
          "Agar f(x) va g(x) funksiyalar uchun g⁻¹(f⁻¹(x))= 2x + 3 va g(x) = 4x - 7 tenglik o`rinli bo`lsa, f(x) ning qiymatini toping.",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img105.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img106.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img107.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img108.png" },
    ],
    answer: 3,
  },
  {
    id: 122,
    question: [
      {
        type: "txt",
        value:
          "Agar ⨍(x) toq va g(x) juft funksiyaning bo`lsa, quyidagilardan qaysi biri doimo juft funksiya bo`ladi?",
      },
    ],
    options: [
      { type: "txt", value: "⨍³(x)" },
      { type: "txt", value: "⨍(x)+g(x)" },
      { type: "txt", value: "⨍(g(x))" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img109.png" },
    ],
    answer: 2,
  },
  {
    id: 123,
    question: [
      {
        type: "txt",
        value:
          "33022 besh xonali sonning raqamlaridan foydalanib yana nechta bir – biridan farqli besh xonali son yozish mumkin?",
      },
    ],
    options: [
      { type: "txt", value: "30" },
      { type: "txt", value: "24" },
      { type: "txt", value: "21" },
      { type: "txt", value: "18" },
    ],
    answer: 1,
  },
  {
    id: 124,
    question: [
      {
        type: "txt",
        value:
          "A={0,1,2,3,4,5,6} to`plam elementlaridan foydalanib turli raqamli nechta to`rt xonali juft natural sonlar yozish mumkin?",
      },
    ],
    options: [
      { type: "txt", value: "500" },
      { type: "txt", value: "480" },
      { type: "txt", value: "420" },
      { type: "txt", value: "450" },
    ],
    answer: 2,
  },
  {
    id: 125,
    question: [
      {
        type: "txt",
        value:
          "A={0,2,4,5,6,7} to`plam elementlaridan foydalanib turli raqamli uch xonali beshga bo`linadigan nechta natural sonlar yozish mumkin?",
      },
    ],
    options: [
      { type: "txt", value: "36" },
      { type: "txt", value: "32" },
      { type: "txt", value: "48" },
      { type: "txt", value: "42" },
    ],
    answer: 0,
  },
  {
    id: 126,
    question: [
      {
        type: "txt",
        value:
          "A={0,1,2,5,6} to`plam elementlaridan foydalanib yozilgan uch xonali sonlar ichidan nechtasi 500 dan kichik bo`ladi?",
      },
    ],
    options: [
      { type: "txt", value: "25" },
      { type: "txt", value: "30" },
      { type: "txt", value: "50" },
      { type: "txt", value: "75" },
    ],
    answer: 2,
  },
  {
    id: 127,
    question: [
      {
        type: "txt",
        value:
          "1, 2, 3, 4, 5 va 6 raqamlaridan foydalanib bir – biridan farqli 4 xonali sonlar yozilgan. Bu sonlar ichida 3 va 4 raqamlari yonma – yon yozilgan sonlar nechta?",
      },
    ],
    options: [
      { type: "txt", value: "108" },
      { type: "txt", value: "72" },
      { type: "txt", value: "96" },
      { type: "txt", value: "84" },
    ],
    answer: 1,
  },
  {
    id: 128,
    question: [
      {
        type: "txt",
        value:
          "A={0,1,2,3,4,5} to`plam elementlaridan foydalanib bir – biridan farqli uch xonali sonlar ichida 300 dan kichik juft sonlar nechta?",
      },
    ],
    options: [
      { type: "txt", value: "18" },
      { type: "txt", value: "36" },
      { type: "txt", value: "24" },
      { type: "txt", value: "20" },
    ],
    answer: 3,
  },
  {
    id: 129,
    question: [
      {
        type: "txt",
        value:
          "“KAPALAK” so`zining harflaridan foydalanib yozilgan 7 ta harfli so`zlaridan nechtasi L harfi bilan boshlanib P harfi bilan tugaydi?",
      },
    ],
    options: [
      { type: "txt", value: "8" },
      { type: "txt", value: "10" },
      { type: "txt", value: "12" },
      { type: "txt", value: "15" },
    ],
    answer: 1,
  },
  {
    id: 130,
    question: [
      {
        type: "txt",
        value:
          "A=0,1,2,3,4,5to’plam elementaridan foydalanib tuzilgan uch xonali natural sonlar ichida 3 raqami mavjud bo’lganlari nechta?",
      },
    ],
    options: [
      { type: "txt", value: "91" },
      { type: "txt", value: "72" },
      { type: "txt", value: "100" },
      { type: "txt", value: "216" },
    ],
    answer: 0,
  },
  {
    id: 131,
    question: [
      {
        type: "txt",
        value:
          "A={a,b,c,d,e,f,g,h} to’plam elementaridan foydalanib undosh harflardan iborat 3 harfli so’z yozish mumkin?",
      },
    ],
    options: [
      { type: "txt", value: "216" },
      { type: "txt", value: "210" },
      { type: "txt", value: "120" },
      { type: "txt", value: "96" },
    ],
    answer: 0,
  },
  {
    id: 132,
    question: [
      {
        type: "txt",
        value:
          "A={a,b,c,d,e,f} to’plam elementaridan foydalanib 5 harfli so’zlar yozilgan. Bu so’zlar ichidan nechtasi ‘‘ba’’ bo’g’ini bilan boshlanadi?",
      },
    ],
    options: [
      { type: "txt", value: "18" },
      { type: "txt", value: "48" },
      { type: "txt", value: "72" },
      { type: "txt", value: "24" },
    ],
    answer: 3,
  },
  {
    id: 133,
    question: [
      {
        type: "txt",
        value:
          "123450 soning raqamlaridan foydalanib nechta raqamlari turlicha bo’lgan 6 xonali sonlar yozilgan?",
      },
    ],
    options: [
      { type: "txt", value: "4∙5!" },
      { type: "txt", value: "5∙5!" },
      { type: "txt", value: "4∙4!" },
      { type: "txt", value: "6!" },
    ],
    answer: 1,
  },
  {
    id: 134,
    question: [
      {
        type: "txt",
        value:
          "(x²-2x)⁶yoyilmasining hadlaridan biri a∙x¹¹bo’lsa, a x¹¹ bo’lsa, a ni toping.",
      },
    ],
    options: [
      { type: "txt", value: "-20" },
      { type: "txt", value: "-18" },
      { type: "txt", value: "-15" },
      { type: "txt", value: "-12" },
    ],
    answer: 3,
  },
  {
    id: 135,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img110.png" },
      { type: "txt", value: "yoyilmasining musbat koeffitseyentlari nechta?" },
    ],
    options: [
      { type: "txt", value: "7" },
      { type: "txt", value: "6" },
      { type: "txt", value: "5" },
      { type: "txt", value: "4" },
    ],
    answer: 1,
  },
  {
    id: 136,
    question: [
      {
        type: "txt",
        value:
          "(1+2a)⁷ ifoda yoyilmasining a² hadi oldidagi koeffitsenti toping.",
      },
    ],
    options: [
      { type: "txt", value: "84" },
      { type: "txt", value: "72" },
      { type: "txt", value: "60" },
      { type: "txt", value: "56" },
    ],
    answer: 0,
  },
  {
    id: 137,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img111.png" },
      { type: "txt", value: "ifoda yoyilmasining hadlaridan biri" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img112.png" },
      { type: "txt", value: "bo’lsa, n ni toping." },
    ],
    options: [
      { type: "txt", value: "18" },
      { type: "txt", value: "28" },
      { type: "txt", value: "30" },
      { type: "txt", value: "20" },
    ],
    answer: 2,
  },
  {
    id: 138,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img113.png" },
      { type: "txt", value: "yoyilmadan" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img114.png" },
      { type: "txt", value: "yig’indini toping." },
    ],
    options: [
      { type: "txt", value: "100" },
      { type: "txt", value: "110" },
      { type: "txt", value: "120" },
      { type: "txt", value: "122" },
    ],
    answer: 3,
  },
  {
    id: 139,
    question: [
      { type: "txt", value: "a = 12 va b = 15 uchun" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img115.png" },
      { type: "txt", value: "ifodaning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "3¹²" },
      { type: "txt", value: "3¹⁵" },
      { type: "txt", value: "3¹⁶" },
      { type: "txt", value: "3¹⁸" },
    ],
    answer: 3,
  },
  {
    id: 140,
    question: [
      { type: "txt", value: "(x²− 2x + 1)⁶ ifoda nechta haddan iborat?" },
    ],
    options: [
      { type: "txt", value: "13" },
      { type: "txt", value: "12" },
      { type: "txt", value: "8" },
      { type: "txt", value: "7" },
    ],
    answer: 0,
  },
  {
    id: 141,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img116.png" },
      {
        type: "txt",
        value: "ifodaning hadlaridan biri m⋅ a² ⋅ b³ bo`lsa, m ni toping.",
      },
    ],
    options: [
      { type: "txt", value: "180" },
      { type: "txt", value: "160" },
      { type: "txt", value: "150" },
      { type: "txt", value: "140" },
    ],
    answer: 1,
  },
  {
    id: 142,
    question: [
      {
        type: "txt",
        value:
          "P(x) = (x³ −1)³ + (x + 2)⁶  ko`phadning x³  birhadi oldidagi koeffitseyentini toping.",
      },
    ],
    options: [
      { type: "txt", value: "163" },
      { type: "txt", value: "156" },
      { type: "txt", value: "144" },
      { type: "txt", value: "139" },
    ],
    answer: 0,
  },
  {
    id: 143,
    question: [
      {
        type: "txt",
        value:
          "(2x + 3y)¹⁰  ifodaning binom yoyilmasidagi x⁷ ⋅ y³ hadi koeffitseyentining musbat bo`luvchilari sonini toping.",
      },
    ],
    options: [
      { type: "txt", value: "180" },
      { type: "txt", value: "240" },
      { type: "txt", value: "110" },
      { type: "txt", value: "120" },
    ],
    answer: 2,
  },
  {
    id: 144,
    question: [
      {
        type: "txt",
        value:
          "(a² ⋅ b + a ⋅ b³)⁶ yoyilmasidagi hadlaridan biri m ⋅ a⁸ ⋅b¹⁴ bo`lsa, m ni toping.",
      },
    ],
    options: [
      { type: "txt", value: "6" },
      { type: "txt", value: "10" },
      { type: "txt", value: "15" },
      { type: "txt", value: "20" },
    ],
    answer: 2,
  },
  {
    id: 145,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img117.png" },
      {
        type: "txt",
        value:
          "ning koeffitsiyentlari yig`indisi 243 bo`lsa, a² hadi oldidagi koeffitsiyentini toping.",
      },
    ],
    options: [
      { type: "txt", value: "40" },
      { type: "txt", value: "45" },
      { type: "txt", value: "20" },
      { type: "txt", value: "24" },
    ],
    answer: 0,
  },
  {
    id: 146,
    question: [
      { type: "txt", value: "Hisoblang:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img118.png" },
    ],
    options: [
      { type: "txt", value: "72" },
      { type: "txt", value: "84" },
      { type: "txt", value: "88" },
      { type: "txt", value: "92" },
    ],
    answer: 1,
  },
  {
    id: 147,
    question: [
      { type: "txt", value: "a, b va c bir – biridan farqli raqamlar bo`lsa," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img119.png" },
      {
        type: "txt",
        value: "ifodaning a=54 va b=27 bo`lgandagi qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "6" },
      { type: "txt", value: "5" },
      { type: "txt", value: "4" },
      { type: "txt", value: "3" },
    ],
    answer: 3,
  },
  {
    id: 148,
    question: [
      { type: "txt", value: "Tenglamani yeching:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img120.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img121.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img122.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img123.png" },
      { type: "txt", value: "1" },
    ],
    answer: 2,
  },
  {
    id: 149,
    question: [
      {
        type: "txt",
        value:
          "Tenglamaning ildizlari yig`indisini toping: (3x − 2)² ⋅ (x² − x −13)= 0",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img124.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img125.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img126.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img127.png" },
    ],
    answer: 3,
  },
  {
    id: 150,
    question: [
      {
        type: "txt",
        value:
          "n biror haqiqiy son bo`lsa, x² − (2n +1)x + n² + n − 6 = 0 ikkinchi darajali tenglamaning ildizlarini to`plami quyidagilarda qaysi biriga teng?",
      },
    ],
    options: [
      { type: "txt", value: "{1; n − 2}" },
      { type: "txt", value: "{1 + n ; n + 2}" },
      { type: "txt", value: "{1;n + 3}" },
      { type: "txt", value: "{n − 2;n + 3}" },
    ],
    answer: 3,
  },
  {
    id: 151,
    question: [
      {
        type: "txt",
        value:
          "(x − y)² − 4(x + y)² + x² − y² = 0 ko`ra, ifodaning olishi mumkin bo`lgan qiymatlari ko`paytmasini toping.",
      },
    ],
    options: [
      { type: "txt", value: "–1" },
      { type: "txt", value: "–2" },
      { type: "txt", value: "–3" },
      { type: "txt", value: "–4" },
    ],
    answer: 3,
  },
  {
    id: 152,
    question: [
      {
        type: "txt",
        value:
          "x² − x − 4 = 0 tenglamaning ildizlari m va n bo`lsa, (m −1)⋅(n − 2)⋅(m − 3)⋅(m + 2)⋅(n +1)⋅ m ning qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "–16" },
      { type: "txt", value: "–8" },
      { type: "txt", value: "0" },
      { type: "txt", value: "8" },
    ],
    answer: 0,
  },
  {
    id: 153,
    question: [
      { type: "txt", value: "x²-4x-12=0 tenglamaning x1 va x2 bo`lsa," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img128.png" },
      { type: "txt", value: "ning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "-60" },
      { type: "txt", value: "-56" },
      { type: "txt", value: "-48" },
      { type: "txt", value: "48" },
    ],
    answer: 2,
  },
  {
    id: 154,
    question: [
      {
        type: "txt",
        value: "x² − ax + 3 = 0 tenglamaning ildizlari x1 va x1 bo`lib,",
      },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img129.png" },
      { type: "txt", value: "bo`lsa, a ni toping." },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img130.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img131.png" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
    ],
    answer: 0,
  },
  {
    id: 155,
    question: [
      {
        type: "txt",
        value: "x² + x − 3 = 0 tenglamaning ildizlari x1 va x2  bo`lsa,",
      },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img132.png" },
      { type: "txt", value: "ning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "–4" },
      { type: "txt", value: "–3" },
      { type: "txt", value: "–2" },
      { type: "txt", value: "–1" },
    ],
    answer: 1,
  },
  {
    id: 156,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img133.png" },
      {
        type: "txt",
        value:
          "tenglamaning ildizlari x1 va x2 bo`lsa, uning diskriminantini toping.",
      },
    ],
    options: [
      { type: "txt", value: "24" },
      { type: "txt", value: "30" },
      { type: "txt", value: "32" },
      { type: "txt", value: "36" },
    ],
    answer: 2,
  },
  {
    id: 157,
    question: [
      {
        type: "txt",
        value:
          "x²-6x+3m=0 ikkinchi darajali tenglamaning ildizlari x1 va x2 bo`lib,",
      },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img134.png" },
      { type: "txt", value: "tenglik o`rinli bo`lsa, m ni toping." },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 2,
  },
  {
    id: 158,
    question: [
      {
        type: "txt",
        value:
          "x² −(a −1)x + a +1 = 0 ikkinchi darajali  tenglamaning ildizlari x1 va x2 bo`lib,",
      },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img135.png" },
      { type: "txt", value: "tenglik o`rinli bo`lsa, a ni toping. " },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 3,
  },
  {
    id: 159,
    question: [
      {
        type: "txt",
        value: "x² − (m − 2)x +16 = 0  tenglamaning ildizlari x1 va x2 bo`lib,",
      },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img136.png" },
      { type: "txt", value: "tenglik o`rinli bo`lsa, m ni toping. " },
    ],
    options: [
      { type: "txt", value: "17" },
      { type: "txt", value: "18" },
      { type: "txt", value: "19" },
      { type: "txt", value: "20" },
    ],
    answer: 2,
  },
  {
    id: 160,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img137.png" },
      { type: "txt", value: "ikkinchi darajali tenglamaning ildizlari" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img138.png" },
      { type: "txt", value: "tenglik o’rinli bo’lsa, m ni toping." },
    ],
    options: [
      { type: "txt", value: "−16" },
      { type: "txt", value: "−15" },
      { type: "txt", value: "−12" },
      { type: "txt", value: "−10" },
    ],
    answer: 2,
  },
  {
    id: 161,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img139.png" },
      { type: "txt", value: "ga ko’ra, ildizlari" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img140.png" },
      { type: "txt", value: "bo’lgan ikkichi darajali tenglama tuzing." },
    ],
    options: [
      { type: "txt", value: "x²-11x+22=0" },
      { type: "txt", value: "x²-10x+12=0 " },
      { type: "txt", value: "x²+10x+22=0" },
      { type: "txt", value: "x²-7x+15=0" },
    ],
    answer: 0,
  },
  {
    id: 162,
    question: [
      { type: "txt", value: "4x²-2x-4=0 tenglamaning ildizlari" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img141.png" },
      { type: "txt", value: ". Bunga ko’ra, ildizlari" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img142.png" },
      { type: "txt", value: "bo’lgan ikkinchi darajali tenglama tuzing." },
    ],
    options: [
      { type: "txt", value: "2x²-5x+3=0" },
      { type: "txt", value: "x²-3x-7=0" },
      { type: "txt", value: "2x²-5x+2=0" },
      { type: "txt", value: "2x²-5x+1=0" },
    ],
    answer: 2,
  },
  {
    id: 163,
    question: [
      {
        type: "txt",
        value:
          "Agar P(x²+x+7)=14-3x²-3x bo’lsa, P(x) ko’phad quyidagilardan qaysi biriga teng?",
      },
    ],
    options: [
      { type: "txt", value: "-3x+35" },
      { type: "txt", value: "-3x+15" },
      { type: "txt", value: "-3x+20" },
      { type: "txt", value: "-3x+30" },
    ],
    answer: 0,
  },
  {
    id: 164,
    question: [
      { type: "txt", value: "P(x) ko’phad uchun" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img143.png" },
      { type: "txt", value: "o’rinli bo’lsa, P(4)·P(6) ni toping." },
    ],
    options: [
      { type: "txt", value: "6" },
      { type: "txt", value: "8" },
      { type: "txt", value: "10" },
      { type: "txt", value: "12" },
    ],
    answer: 3,
  },
  {
    id: 165,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img144.png" },
      { type: "txt", value: "ko’phadni x-5 ga bolgandagi qoldiqni toping." },
    ],
    options: [
      { type: "txt", value: "4" },
      { type: "txt", value: "6" },
      { type: "txt", value: "8" },
      { type: "txt", value: "10" },
    ],
    answer: 1,
  },
  {
    id: 166,
    question: [
      {
        type: "txt",
        value:
          "Agar P(x+3)ko’phadni x – 2  ga bo’lgandagi qoldiq 5 ga teng bo’lsa, quyidagilardan qaysi biri x – 1 ga qoldiqsiz bo’linadi?",
      },
    ],
    options: [
      { type: "txt", value: "Px-3" },
      { type: "txt", value: "Px-2+x" },
      { type: "txt", value: "Px+3+3" },
      { type: "txt", value: "P6x-1-5x" },
    ],
    answer: 3,
  },
  {
    id: 167,
    question: [
      {
        type: "txt",
        value:
          "Agar 2Px+1+3Px=10x+9 bo’lsa, Px ko’phadning koyeffitseyentlari yig’indisini toping.",
      },
    ],
    options: [
      { type: "txt", value: "5" },
      { type: "txt", value: "4" },
      { type: "txt", value: "3" },
      { type: "txt", value: "2" },
    ],
    answer: 2,
  },
  {
    id: 168,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img145.png" },
      {
        type: "txt",
        value: "ko’phadni x²-2 ga bo’lganda qoldiq 6 bo’lsa, m ni toping.",
      },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
    ],
    answer: 1,
  },
  {
    id: 169,
    question: [
      {
        type: "txt",
        value:
          "Agar P(2)=3  va P(3)=5 bo’lsa,  P(x) ko’phadni (x-2)(x-3)ga bo’lgandagi qoldiqni toping.",
      },
    ],
    options: [
      { type: "txt", value: "2x – 1" },
      { type: "txt", value: "x – 1" },
      { type: "txt", value: "2x + 1" },
      { type: "txt", value: "3x – 1" },
    ],
    answer: 0,
  },
  {
    id: 170,
    question: [
      {
        type: "txt",
        value:
          "Agar Px+4=x²+4x+a, Qx+2=x²+4 va Px+2=Q(x+2) bo’lsa, a ni toping.",
      },
    ],
    options: [
      { type: "txt", value: "6" },
      { type: "txt", value: "7" },
      { type: "txt", value: "8" },
      { type: "txt", value: "9" },
    ],
    answer: 2,
  },
  {
    id: 171,
    question: [
      {
        type: "txt",
        value: "P(x)=2x-3 bo’lsa, P(m-1)+P(m+1)=14 tenglikdan m ni toping.",
      },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
    ],
    answer: 3,
  },
  {
    id: 172,
    question: [
      {
        type: "txt",
        value:
          "x⁵+x⁴+ax+b ko’phad x²+x+1 ga qoldiqsiz bo’linsa, a+b ni toping.",
      },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
    ],
    answer: 1,
  },
  {
    id: 173,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img146.png" },
      { type: "txt", value: "bo’lsa, P(4) ni topiing." },
    ],
    options: [
      { type: "txt", value: "24" },
      { type: "txt", value: "21" },
      { type: "txt", value: "18" },
      { type: "txt", value: "16" },
    ],
    answer: 0,
  },
  {
    id: 174,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img147.png" },
      {
        type: "txt",
        value:
          "bo’lib, P(x+1) ko’phadni x – 3 ga bo’lgandagi qoldiq 31 ga teng. Q(x) ko’phadni x – 2ga bo’lgandagi qoldiqni toping.",
      },
    ],
    options: [
      { type: "txt", value: "7" },
      { type: "txt", value: "8" },
      { type: "txt", value: "9" },
      { type: "txt", value: "10" },
    ],
    answer: 0,
  },

  {
    id: 175,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img148.png" },
      { type: "txt", value: "ifodaning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "120!" },
      { type: "txt", value: "72!" },
      { type: "txt", value: "48!" },
      { type: "txt", value: "24!" },
    ],
    answer: 3,
  },
  {
    id: 176,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img149.png" },
      {
        type: "txt",
        value:
          "bo`lsa, f(-2)>f(10)  tengsizlikni qanoatlantiruvchi m ning eng kichik qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "-2" },
      { type: "txt", value: "-1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "1" },
    ],
    answer: 2,
  },
  {
    id: 177,
    question: [
      { type: "txt", value: "Soddalashtiring:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img150.png" },
    ],
    options: [
      { type: "txt", value: "x⁴" },
      { type: "txt", value: "x³" },
      { type: "txt", value: "x²" },
      { type: "txt", value: "x" },
    ],
    answer: 2,
  },
  {
    id: 178,
    question: [
      { type: "txt", value: "a, b va c musbat butun sonlar uchun" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img151.png" },
      {
        type: "txt",
        value:
          "tengliklar o`rinli bo`lsa, a+b+c  yig`indining  eng kichik butun qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "15" },
      { type: "txt", value: "23" },
      { type: "txt", value: "37" },
      { type: "txt", value: "54" },
    ],
    answer: 2,
  },
  {
    id: 179,
    question: [
      { type: "txt", value: "1+5+9+...n=A va 1+4+7+...+n=B bo’lsa," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img152.png" },
      { type: "txt", value: "ga ko’ ra, n ning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "45" },
      { type: "txt", value: "49" },
      { type: "txt", value: "51" },
      { type: "txt", value: "53" },
    ],
    answer: 1,
  },
  {
    id: 180,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img153.png" },
      { type: "txt", value: "bo’lsa, ." },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img154.png" },
      { type: "txt", value: "ifodani m orqali ifodalang. " },
    ],
    options: [
      { type: "txt", value: "m – 4" },
      { type: "txt", value: "2m – 1 " },
      { type: "txt", value: "m + 6" },
      { type: "txt", value: "4 – m" },
    ],
    answer: 3,
  },
  {
    id: 181,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img155.png" },
      { type: "txt", value: "ko’paytmaning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "txt", value: "1" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img156.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img157.png" },
    ],
    answer: 0,
  },
  {
    id: 182,
    question: [
      {
        type: "txt",
        value:
          "A=18²+24²+30² bo’lsa, A sonining tub bo’luvchilari yig’indisini toping. ",
      },
    ],
    options: [
      { type: "txt", value: "6" },
      { type: "txt", value: "10" },
      { type: "txt", value: "18" },
      { type: "txt", value: "25" },
    ],
    answer: 1,
  },
  {
    id: 183,
    question: [
      {
        type: "txt",
        value:
          "x, y, z bir - biridan farqli musbat butun sonlardir. Agar EKUK (x; y; z) = 100 bo’lsa, x+y+z yig’indining eng katta qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "150" },
      { type: "txt", value: "175" },
      { type: "txt", value: "200" },
      { type: "txt", value: "255" },
    ],
    answer: 1,
  },
  {
    id: 184,
    question: [
      {
        type: "txt",
        value:
          "EKUBi 4 ga va EKUKi 72 bo’lgan ikki musbat butun sonlar yig’indisining eng kichik qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "36" },
      { type: "txt", value: "40" },
      { type: "txt", value: "44" },
      { type: "txt", value: "56" },
    ],
    answer: 3,
  },
  {
    id: 185,
    question: [
      {
        type: "txt",
        value:
          "a,b,c bir – biridan farqli tub sonlardir. Aagr A=a²∙b³∙c² B=a³∙b∙c³, C= a⁴∙b² bo’lsa,",
      },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img158.png" },
      { type: "txt", value: "ning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "a³b²∙c" },
      { type: "txt", value: "a²b²c³" },
      { type: "txt", value: "a∙b²c³" },
      { type: "txt", value: "a∙b²∙c" },
    ],
    answer: 1,
  },
  {
    id: 186,
    question: [
      { type: "txt", value: "x, y musbat butun sonlardir." },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img159.png" },
      { type: "txt", value: "va EKUK(x;y)=200 bo’lsa, EKUB(x;y) ni toping." },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "5" },
      { type: "txt", value: "10" },
      { type: "txt", value: "16" },
    ],
    answer: 1,
  },
  {
    id: 187,
    question: [
      {
        type: "txt",
        value:
          "x,y,z ∈Z bo’lsa, A=7x – 4=8y+4=9z+5 ga ko’ra A sonining olishi mumkin bo’lgan uch xonali butun sonni toping.",
      },
    ],
    options: [
      { type: "txt", value: "500" },
      { type: "txt", value: "504" },
      { type: "txt", value: "618" },
      { type: "txt", value: "770" },
    ],
    answer: 0,
  },
  {
    id: 188,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img160.png" },
      {
        type: "txt",
        value: "bo’lsa, quyidagilarning qaysi biri doimo o’rinli?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img161.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img162.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img163.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img164.png" },
    ],
    answer: 1,
  },
  {
    id: 189,
    question: [
      { type: "txt", value: "Agar x>0 bo’lsa," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img165.png" },
      { type: "txt", value: "ni soddalashtiring." },
    ],
    options: [
      { type: "txt", value: "-x⁻²⁵" },
      { type: "txt", value: "-x¹⁷" },
      { type: "txt", value: "1" },
      { type: "txt", value: "x¹⁷" },
    ],
    answer: 0,
  },
  {
    id: 190,
    question: [
      { type: "txt", value: "x va y butun sonlar bo’lib," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img166.png" },
      { type: "txt", value: "bo’lsa, x²-y² ning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "– 21" },
      { type: "txt", value: "– 15" },
      { type: "txt", value: "1" },
      { type: "txt", value: "25" },
    ],
    answer: 0,
  },
  {
    id: 191,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img167.png" },
      { type: "txt", value: "bo’lsa," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img168.png" },
      { type: "txt", value: "ni x roqali ifodalang." },
    ],
    options: [
      { type: "txt", value: "2x" },
      { type: "txt", value: "x" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img169.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img170.png" },
    ],
    answer: 1,
  },
  {
    id: 192,
    question: [
      { type: "txt", value: "Hisoblang:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img171.png" },
    ],
    options: [
      { type: "txt", value: "16" },
      { type: "txt", value: "12" },
      { type: "txt", value: "10" },
      { type: "txt", value: "9" },
    ],
    answer: 3,
  },
  {
    id: 193,
    question: [
      {
        type: "txt",
        value:
          "a, b, c  haqiqiy sonlar uchun a∙b+a∙c+b∙c=-12  tenglik o’rinli bo’lsa, a²+b²+c² ning eng lichik qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "48" },
      { type: "txt", value: "36" },
      { type: "txt", value: "24" },
      { type: "txt", value: "0" },
    ],
    answer: 2,
  },
  {
    id: 194,
    question: [
      { type: "txt", value: "Soddalashtiring: " },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img172.png" },
    ],
    options: [
      { type: "txt", value: "x – 2" },
      { type: "txt", value: "x – 2y" },
      { type: "txt", value: "y – 2x" },
      { type: "txt", value: "x + y" },
    ],
    answer: 0,
  },
  {
    id: 195,
    question: [
      { type: "txt", value: "Hisoblang: " },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img173.png" },
    ],
    options: [
      { type: "txt", value: "74" },
      { type: "txt", value: "54" },
      { type: "txt", value: "60" },
      { type: "txt", value: "64" },
    ],
    answer: 0,
  },
  {
    id: 196,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img174.png" },
      { type: "txt", value: "ifodadan n ning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "4" },
      { type: "txt", value: "3" },
      { type: "txt", value: "5" },
    ],
    answer: 2,
  },
  {
    id: 197,
    question: [
      {
        type: "txt",
        value:
          "(a+2b+3c)⁵ yoyilmaning a²b²c birhadi oldidagi koyeffitsiyentini toping.",
      },
    ],
    options: [
      { type: "txt", value: "360" },
      { type: "txt", value: "120" },
      { type: "txt", value: "80" },
      { type: "txt", value: "240" },
    ],
    answer: 0,
  },
  {
    id: 198,
    question: [
      {
        type: "txt",
        value:
          "Agar ⨍x=(x+1)²∙(x-4)³ bo’lsa, ⨍’(x)=0 tenglamaning ildizlari yig’indisini toping.",
      },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "– 1" },
      { type: "txt", value: "0" },
    ],
    answer: 1,
  },
  {
    id: 199,
    question: [
      {
        type: "txt",
        value:
          "Agar f (x) = x⁵⁵ + x⁵⁴ + x⁵³ + ... +1 bo`lsa, f `(0) ning qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
    ],
    answer: 1,
  },
  {
    id: 200,
    question: [
      {
        type: "txt",
        value:
          "Agar f (x) = x⁵⁵ + x⁵⁴ + x⁵³ + ... +1 bo`lsa,  f `(1) ning qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "1450" },
      { type: "txt", value: "1540" },
      { type: "txt", value: "1050" },
      { type: "txt", value: "928" },
    ],
    answer: 1,
  },
  {
    id: 201,
    question: [
      { type: "txt", value: "f (x) = x3 − 5ax + 4 funksiya berilgan. Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img175.png" },
      { type: "txt", value: "bo`lsa, a ning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "txt", value: "1" },
      { type: "txt", value: "–5" },
      { type: "txt", value: "–3" },
    ],
    answer: 3,
  },
  {
    id: 202,
    question: [
      {
        type: "txt",
        value:
          "f (x) = ax³ + 2x²b + 5x − 2 funksiya berilgan. Agar f `(1) = 3 va f ``(−1) = 5 bo`lsa, a ning qiymatini toping.",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img176.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img177.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img178.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img179.png" },
    ],
    answer: 0,
  },
  {
    id: 203,
    question: [
      {
        type: "txt",
        value:
          "f (x) = −x³ + ax² + 5x + 3 va g(x) = 3x⁴ + 2x funksiyalar berilgan. Agar  f `(1)+ g `(1) = 8 bo`lsa, a ning qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "txt", value: "0,5" },
      { type: "txt", value: "1" },
      { type: "txt", value: "1,5" },
    ],
    answer: 2,
  },
  {
    id: 204,
    question: [
      {
        type: "txt",
        value:
          "Agar f (x) = cos x va g(x) =f (x)⋅ f `(x) bo`lsa, g `(x) ni toping.",
      },
    ],
    options: [
      { type: "txt", value: "cos 2x" },
      { type: "txt", value: "–sin 2x" },
      { type: "txt", value: "sin 2x" },
      { type: "txt", value: "–cos 2x" },
    ],
    answer: 3,
  },
  {
    id: 205,
    question: [
      {
        type: "txt",
        value: "Agar f (x) = arctg(sin x) bo`lsa, f `(x) ni toping.",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img180.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img181.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img182.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img183.png" },
    ],
    answer: 1,
  },
  {
    id: 206,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img184.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img185.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img186.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img187.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img188.png" },
    ],
    answer: 1,
  },
  {
    id: 207,
    question: [
      {
        type: "txt",
        value:
          "3, 9, 9, 27, 27, 27, 81, 81, 81, 81 … ketma – ketlikning 40 – hadi A, 15 – hadi B ga teng bo`lsa,",
      },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img189.png" },
      { type: "txt", value: "ni toping" },
    ],
    options: [
      { type: "txt", value: "81" },
      { type: "txt", value: "9" },
      { type: "txt", value: "27" },
      { type: "txt", value: "243" },
    ],
    answer: 0,
  },
  {
    id: 208,
    question: [
      {
        type: "txt",
        value:
          "Agar M = 1− 2 + 2² − 2³ + ... + 2⁸ − 2⁹ bo`lsa, 1− 2 + 2² − 2³ +... + 2⁶ − 2⁷ ni M orqali ifodalang.",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img190.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img191.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img192.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img193.png" },
    ],
    answer: 0,
  },
  {
    id: 209,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img194.png" },
      { type: "txt", value: "  bo`lsa," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img195.png" },
      { type: "txt", value: "ni toping.  (x va y haqiqiy sonlar)" },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "–4" },
      { type: "txt", value: "–2" },
    ],
    answer: 3,
  },
  {
    id: 210,
    question: [
      { type: "txt", value: "Hisoblang:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img196.png" },
    ],
    options: [
      { type: "txt", value: "213" },
      { type: "txt", value: "2" },
      { type: "txt", value: "226" },
      { type: "txt", value: "224" },
    ],
    answer: 2,
  },
  {
    id: 211,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img197.png" },
      {
        type: "txt",
        value:
          "bo`lsa, a ning qabul qilishi mumkin bo`lgan nutaral qiymatlari yig`indisini toping. ",
      },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "6" },
      { type: "txt", value: "3" },
      { type: "txt", value: "10" },
    ],
    answer: 2,
  },
  {
    id: 212,
    question: [
      {
        type: "txt",
        value:
          "a, b va c musbat butun sonlardir. Agar a+b =10 va b+c=14 bo`lsa, a·b·c  ko`paytmaning eng katta qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "225" },
      { type: "txt", value: "240" },
      { type: "txt", value: "162" },
      { type: "txt", value: "96" },
    ],
    answer: 1,
  },
  {
    id: 213,
    question: [
      { type: "txt", value: "a, b va c musbat butun sonlar va  a>b>c. Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img198.png" },
      {
        type: "txt",
        value: "bo`lsa,  a+ b+ c ning eng katta qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "16" },
      { type: "txt", value: "22" },
      { type: "txt", value: "24" },
      { type: "txt", value: "18" },
    ],
    answer: 1,
  },
  {
    id: 214,
    question: [
      {
        type: "txt",
        value:
          "m va n natural sonlar uchun (m- 2n)·(m+ n)=13 o`rinli bo`lsa,  m·n  ko`paytmani toping.",
      },
    ],
    options: [
      { type: "txt", value: "33" },
      { type: "txt", value: "36" },
      { type: "txt", value: "40" },
      { type: "txt", value: "30" },
    ],
    answer: 1,
  },
  {
    id: 215,
    question: [
      {
        type: "txt",
        value:
          "Quyida keltirilgan tasdiqlardan nechtasi to`g`ri? 1)  2¹³-3¹⁰ toq son  2) 3⁸+5⁷  toq son   3) 4⁻²+10  juft son   4) 8⁰+1  toq son",
      },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "txt", value: "2" },
      { type: "txt", value: "1" },
      { type: "txt", value: "3" },
    ],
    answer: 2,
  },
  {
    id: 216,
    question: [
      { type: "txt", value: "a + b  va  a -b sonlar o`zaro tub. Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img199.png" },
      { type: "txt", value: "tenglik o`rinli bo`lsa, a × b  ni toping." },
    ],
    options: [
      { type: "txt", value: "40" },
      { type: "txt", value: "44" },
      { type: "txt", value: "38" },
      { type: "txt", value: "35" },
    ],
    answer: 1,
  },
  {
    id: 217,
    question: [
      {
        type: "txt",
        value:
          "Agar m = 2 + 4 + 6 +...+ 20  va n=1+3+ 5+...+19 bo`lsa, m²-n² ifodaning  qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "2400" },
      { type: "txt", value: "2100" },
      { type: "txt", value: "120" },
      { type: "txt", value: "180" },
    ],
    answer: 1,
  },
  {
    id: 218,
    question: [
      {
        type: "txt",
        value:
          "Agar a=5·6+6∙7+7∙8+…+14∙15 va b=6∙9+7∙10+8∙11+…+15∙18 bo`lsa, b-a ifodaning qiymatini toping. ",
      },
    ],
    options: [
      { type: "txt", value: "480" },
      { type: "txt", value: "360" },
      { type: "txt", value: "310" },
      { type: "txt", value: "420" },
    ],
    answer: 3,
  },
  {
    id: 219,
    question: [{ type: "txt", value: "(n+2)!=20·n! bo`lsa, n ni toping." }],
    options: [
      { type: "txt", value: "6" },
      { type: "txt", value: "10" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 2,
  },
  {
    id: 220,
    question: [
      {
        type: "txt",
        value:
          "Ifodaning birlar xonasidagi raqamini aniqlang: 0! +2!+4!+6!+...+34! ",
      },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "5" },
      { type: "txt", value: "7" },
      { type: "txt", value: "6" },
    ],
    answer: 2,
  },
  {
    id: 221,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img200.png" },
      { type: "txt", value: "bo`lsa, n ni toping." },
    ],
    options: [
      { type: "txt", value: "11" },
      { type: "txt", value: "9" },
      { type: "txt", value: "12" },
      { type: "txt", value: "8" },
    ],
    answer: 0,
  },
  {
    id: 222,
    question: [
      { type: "txt", value: "a, b, c musbat butun sonlardir. Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img201.png" },
      { type: "txt", value: "bo`lsa, c ning eng kichik qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "35" },
      { type: "txt", value: "175" },
      { type: "txt", value: "350" },
      { type: "txt", value: "120" },
    ],
    answer: 1,
  },
  {
    id: 223,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img202.png" },
      { type: "txt", value: "tenglikdan a ni toping." },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "3" },
    ],
    answer: 1,
  },
  {
    id: 224,
    question: [
      { type: "txt", value: "124!-13!+12  sonining mezonini toping. " },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "5" },
      { type: "txt", value: "3" },
      { type: "txt", value: "8" },
    ],
    answer: 2,
  },
  {
    id: 225,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img203.png" },
      { type: "txt", value: "bo`lsa," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img204.png" },
      { type: "txt", value: "ni toping." },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img205.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img206.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img207.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img208.png" },
    ],
    answer: 0,
  },
  {
    id: 226,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img209.png" },
      { type: "txt", value: "bo`lsa, m+n ni toping." },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "txt", value: "8" },
      { type: "txt", value: "3" },
      { type: "txt", value: "6" },
    ],
    answer: 3,
  },
  {
    id: 227,
    question: [
      { type: "txt", value: "Hisoblang:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img210.png" },
    ],
    options: [
      { type: "txt", value: "–1" },
      { type: "txt", value: "–2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "10" },
    ],
    answer: 1,
  },
  {
    id: 228,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img211.png" },
      { type: "txt", value: "bo`lsa, " },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img212.png" },
      { type: "txt", value: "ni x orqali ifodalang." },
    ],
    options: [
      { type: "txt", value: "x–4" },
      { type: "txt", value: "x+2" },
      { type: "txt", value: "x–2" },
      { type: "txt", value: "x" },
    ],
    answer: 2,
  },
  {
    id: 229,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img213.png" },
      { type: "txt", value: "bo`lsa," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img214.png" },
      { type: "txt", value: "a orqali ifodalang." },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img215.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img216.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img217.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img218.png" },
    ],
    answer: 2,
  },
  {
    id: 230,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img219.png" },
      { type: "txt", value: "ga ko`ra, x ni toping." },
    ],
    options: [
      { type: "txt", value: "17" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img220.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img221.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img222.png" },
    ],
    answer: 0,
  },
  {
    id: 231,
    question: [
      { type: "txt", value: "Hisoblang:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img223.png" },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "3" },
    ],
    answer: 1,
  },
  {
    id: 232,
    question: [
      { type: "txt", value: "Soddalashtiring:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img224.png" },
    ],
    options: [
      { type: "txt", value: "–1" },
      { type: "txt", value: "1" },
      { type: "txt", value: "–2" },
      { type: "txt", value: "2" },
    ],
    answer: 2,
  },
  {
    id: 233,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img225.png" },
      { type: "txt", value: "bo`lsa,  cos2x  ni toping. " },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img226.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img227.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img228.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img229.png" },
    ],
    answer: 0,
  },
  {
    id: 234,
    question: [
      {
        type: "txt",
        value:
          "Agar  4cos x -6sin x = 0 bo`lsa, |sin 2 x|  ning qiymatini toping.",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img230.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img231.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img232.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img233.png" },
    ],
    answer: 1,
  },
  {
    id: 235,
    question: [
      {
        type: "txt",
        value: "Agar f(x) =arcsin(3-2x) bo`lsa, f⁻¹(x)ni toping.",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img234.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img235.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img236.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img237.png" },
    ],
    answer: 1,
  },
  {
    id: 236,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img238.png" },
      { type: "txt", value: "ni toping." },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img239.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img240.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img241.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img242.png" },
    ],
    answer: 3,
  },
  {
    id: 237,
    question: [
      { type: "txt", value: "Taqqoslang." },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img243.png" },
    ],
    options: [
      { type: "txt", value: "x>y>z" },
      { type: "txt", value: "z>y>x" },
      { type: "txt", value: "x=z<y" },
      { type: "txt", value: "x=z>y" },
    ],
    answer: 2,
  },
  {
    id: 238,
    question: [
      { type: "txt", value: "Agar a+b+c=0 bo`lsa," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img244.png" },
      { type: "txt", value: "ning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "–3" },
      { type: "txt", value: "–1" },
      { type: "txt", value: "3" },
      { type: "txt", value: "0" },
    ],
    answer: 0,
  },
  {
    id: 239,
    question: [
      { type: "txt", value: "Agar a+b+c+d=0 bo`lsa," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img245.png" },
      { type: "txt", value: "ning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "–3" },
      { type: "txt", value: "–1" },
      { type: "txt", value: "3" },
      { type: "txt", value: "0" },
    ],
    answer: 0,
  },
  {
    id: 240,
    question: [
      { type: "txt", value: "Soddalashtiring: " },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img246.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img247.png" },
      { type: "txt", value: "cos 20°" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img248.png" },
      { type: "txt", value: "sin20°" },
    ],
    answer: 2,
  },
  {
    id: 241,
    question: [
      { type: "txt", value: "Hisoblang:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img249.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img250.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img251.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img252.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img253.png" },
    ],
    answer: 2,
  },
  {
    id: 242,
    question: [
      {
        type: "txt",
        value:
          "Agar sin17°=x va cos17°=y bo’lsa, cos56°=x ni x va y orqali ifodalang.",
      },
    ],
    options: [
      { type: "txt", value: "2xy" },
      { type: "txt", value: "2x+y" },
      { type: "txt", value: "xy" },
      { type: "txt", value: "4xy" },
    ],
    answer: 0,
  },
  {
    id: 243,
    question: [
      {
        type: "txt",
        value: "Agar sin85°=a bo’lsa, sin80° ni a orqali ifodalang.",
      },
    ],
    options: [
      { type: "txt", value: "1 – 2a²" },
      { type: "txt", value: "2a² -1" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img254.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img255.png" },
    ],
    answer: 1,
  },
  {
    id: 244,
    question: [
      { type: "txt", value: "Agar tg22°=x bo’lsa," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img256.png" },
      { type: "txt", value: "ni x orqali ifodalang." },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img257.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img258.png" },
      { type: "txt", value: "1+x" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img259.png" },
    ],
    answer: 1,
  },
  {
    id: 245,
    question: [
      { type: "txt", value: "Agar  tg35°=n bo’lsa," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img260.png" },
      { type: "txt", value: "ni n orqali ifodalang." },
    ],
    options: [
      { type: "txt", value: "2n" },
      { type: "txt", value: "n" },
      { type: "txt", value: "– 2 n" },
      { type: "txt", value: "0" },
    ],
    answer: 2,
  },
  {
    id: 246,
    question: [
      { type: "txt", value: "Agar ctg50°=x bo’lsa," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img261.png" },
      { type: "txt", value: "ni x orqali ifodalang." },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img262.png" },
      { type: "txt", value: "1" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img263.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img264.png" },
    ],
    answer: 1,
  },
  {
    id: 247,
    question: [
      { type: "txt", value: "Soddalashtiring:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img265.png" },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "1" },
      { type: "txt", value: "0,5" },
      { type: "txt", value: "0,25" },
    ],
    answer: 1,
  },
  {
    id: 248,
    question: [
      { type: "txt", value: "Agar x+y+z=3π bo’lsa," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img266.png" },
      { type: "txt", value: "ning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "2,5" },
      { type: "txt", value: "1" },
      { type: "txt", value: "0,5" },
      { type: "txt", value: "0,25" },
    ],
    answer: 1,
  },
  {
    id: 249,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img267.png" },
      {
        type: "txt",
        value:
          "ifoda  yoyilganda, birhadlaridan biri Ax⁶y⁵ ga teng bo’lsa, n ning qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "11" },
      { type: "txt", value: "8" },
      { type: "txt", value: "9" },
      { type: "txt", value: "10" },
    ],
    answer: 1,
  },
  {
    id: 250,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img268.png" },
      {
        type: "txt",
        value:
          "ifoda  yoyilganda, birhadlaridan biri Ax⁸y⁹ ga teng bo’lsa, A  ning qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "35" },
      { type: "txt", value: "– 28" },
      { type: "txt", value: "28" },
      { type: "txt", value: "– 35" },
    ],
    answer: 3,
  },
  {
    id: 251,
    question: [
      {
        type: "txt",
        value: "Agar lg(a – b)=lga+lgb bo’lsa, a ni b orqali ifodalang.",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img269.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img270.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img271.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img272.png" },
    ],
    answer: 2,
  },
  {
    id: 252,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img273.png" },
      { type: "txt", value: "arifmetik progressiya berilgan." },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img274.png" },
      { type: "txt", value: "bo’lsa" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img275.png" },
      { type: "txt", value: "ni toping." },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 1,
  },
  {
    id: 253,
    question: [
      { type: "txt", value: "Aniqmas integralni hisoblang:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img276.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img277.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img278.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img279.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img280.png" },
    ],
    answer: 1,
  },
  {
    id: 254,
    question: [
      { type: "txt", value: "Aniqmas integralni hisoblang:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img281.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img282.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img283.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img284.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img285.png" },
    ],
    answer: 2,
  },
  {
    id: 255,
    question: [
      { type: "txt", value: "Aniqmas integralni hisoblang:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img286.png" },
    ],
    options: [
      { type: "txt", value: "arcsin(x – 4)+C" },
      { type: "txt", value: "arcctg(x – 4)" },
      { type: "txt", value: "arctg(x – 4)" },
      { type: "txt", value: "arctg(x – 4)+C" },
    ],
    answer: 3,
  },
  {
    id: 256,
    question: [
      { type: "txt", value: "Aniqmas integralni hisoblang:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img287.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img288.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img289.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img290.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img291.png" },
    ],
    answer: 2,
  },
  {
    id: 257,
    question: [
      { type: "txt", value: "Aniqmas integralni hisoblang:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img292.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img293.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img294.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img295.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img296.png" },
    ],
    answer: 2,
  },
  {
    id: 258,
    question: [
      { type: "txt", value: "Aniqmas integralni hisoblang:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img297.png" },
    ],
    options: [
      { type: "txt", value: "arctg(3x³+1)+C" },
      { type: "txt", value: "arctg(x³+1)+C" },
      { type: "txt", value: "arcctg(x³+1)+C" },
      { type: "txt", value: "arcctg(x³-1)+C" },
    ],
    answer: 1,
  },
  {
    id: 259,
    question: [
      { type: "txt", value: "Aniqmas integralni hisoblang:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img298.png" },
    ],
    options: [
      { type: "txt", value: "2 arcsin(x – 2)+C" },
      { type: "txt", value: "arcsin(x – 2)+C" },
      { type: "txt", value: "arccos(x – 2)+C" },
      { type: "txt", value: "arcsin(2 – x)+C" },
    ],
    answer: 1,
  },
  {
    id: 260,
    question: [
      { type: "txt", value: "Aniqmas integralni hisoblang: " },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img299.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img300.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img301.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img302.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img303.png" },
    ],
    answer: 1,
  },
  {
    id: 261,
    question: [
      { type: "txt", value: "Aniqmas integralni hisoblang:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img304.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img305.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img306.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img307.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img308.png" },
    ],
    answer: 2,
  },
  {
    id: 262,
    question: [
      { type: "txt", value: "Aniq integralni hisoblang:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img309.png" },
    ],
    options: [
      { type: "txt", value: "–3" },
      { type: "txt", value: "–2" },
      { type: "txt", value: "–1" },
      { type: "txt", value: "–4" },
    ],
    answer: 0,
  },
  {
    id: 263,
    question: [
      { type: "txt", value: "Aniq integralni hisoblang:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img310.png" },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "txt", value: "10" },
      { type: "txt", value: "1" },
      { type: "txt", value: "5" },
    ],
    answer: 0,
  },
  {
    id: 264,
    question: [
      { type: "txt", value: "Aniq integralni hisoblang:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img311.png" },
    ],
    options: [
      { type: "txt", value: "6" },
      { type: "txt", value: "4" },
      { type: "txt", value: "8" },
      { type: "txt", value: "10" },
    ],
    answer: 0,
  },
  {
    id: 265,
    question: [
      { type: "txt", value: "Aniq integralni hisoblang:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img312.png" },
    ],
    options: [
      { type: "txt", value: "32" },
      { type: "txt", value: "5" },
      { type: "txt", value: "27" },
      { type: "txt", value: "36" },
    ],
    answer: 0,
  },
  {
    id: 266,
    question: [
      { type: "txt", value: "Aniqmas integralni hisoblang:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img313.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img314.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img315.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img316.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img317.png" },
    ],
    answer: 2,
  },
  {
    id: 267,
    question: [
      { type: "txt", value: "Aniqmas integralni hisoblang:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img318.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img319.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img320.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img321.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img322.png" },
    ],
    answer: 3,
  },
  {
    id: 268,
    question: [
      { type: "txt", value: "Aniqmas integralni hisoblang:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img323.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img324.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img325.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img326.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img327.png" },
    ],
    answer: 1,
  },
  {
    id: 269,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img328.png" },
      {
        type: "txt",
        value: "funksiyalar berilgan. Agar g(1)=12 bo’lsa, g(x) ni toping. ",
      },
    ],
    options: [
      { type: "txt", value: "x³-1nx+7" },
      { type: "txt", value: "x³-1nx+7x+4" },
      { type: "txt", value: "x³+1nx-7x+7" },
      { type: "txt", value: "x³-1nx+7x-4" },
    ],
    answer: 1,
  },
  {
    id: 270,
    question: [
      {
        type: "txt",
        value: "Ko’paytuvchilarga ajrating: (x – y)²y-z+(y-x)∙(z-y)²",
      },
    ],
    options: [
      { type: "txt", value: "(x – y)(y-z)(y-x)" },
      { type: "txt", value: "(x – y)(y-z)(x+z-2y)" },
      { type: "txt", value: "(x – y)(y-z)(z-x)" },
      { type: "txt", value: "(x – y)(y-z)(x-z+2y)" },
    ],
    answer: 1,
  },
  {
    id: 271,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img329.png" },
      { type: "txt", value: "bo’lsa, (x+1)³-3(x+1)²+3x+5 ni toping. " },
    ],
    options: [
      { type: "txt", value: "2022" },
      { type: "txt", value: "2021" },
      { type: "txt", value: "2020" },
      { type: "txt", value: "2019" },
    ],
    answer: 0,
  },
  {
    id: 272,
    question: [
      { type: "txt", value: "n natural soni uchun" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img330.png" },
      { type: "txt", value: "o’rinlii bo’lsa, n ni toping." },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "7" },
    ],
    answer: 1,
  },
  {
    id: 273,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img331.png" },
      { type: "txt", value: "bo’lsa, |x-y|+|z-y|+|-x| ni toping." },
    ],
    options: [
      { type: "txt", value: "z" },
      { type: "txt", value: "2x – z" },
      { type: "txt", value: "z – 2x" },
      { type: "txt", value: "z – 2y" },
    ],
    answer: 2,
  },
  {
    id: 274,
    question: [
      { type: "txt", value: "Agar – 3 <x< -2 bo’lsa, " },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img332.png" },
      { type: "txt", value: "ni toping." },
    ],
    options: [
      { type: "txt", value: "x – 3" },
      { type: "txt", value: "5 – x" },
      { type: "txt", value: "x – 5" },
      { type: "txt", value: "3 – x" },
    ],
    answer: 3,
  },
  {
    id: 275,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img333.png" },
      { type: "txt", value: "bo’lsa," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img334.png" },
      { type: "txt", value: "ni x orqali ifodalang." },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img335.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img336.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img337.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img338.png" },
    ],
    answer: 3,
  },
  {
    id: 276,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img339.png" },
      { type: "txt", value: "ga ko’ra," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img340.png" },
      { type: "txt", value: "ni toping. " },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "1" },
      { type: "txt", value: "2,5" },
      { type: "txt", value: "2" },
    ],
    answer: 2,
  },
  {
    id: 277,
    question: [
      {
        type: "txt",
        value: "Agar ⨍(x – a)=3x+5 va ⨍(-1)=20 bo’lsa, ⨍(a – 5) ni toping.",
      },
    ],
    options: [
      { type: "txt", value: "29" },
      { type: "txt", value: "26" },
      { type: "txt", value: "25" },
      { type: "txt", value: "24" },
    ],
    answer: 1,
  },
  {
    id: 278,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img341.png" },
      {
        type: "txt",
        value:
          "tenglik o’rinli bo’lsa, ⨍xfunksiya javoblardan qaysi biriga teng?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img342.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img343.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img344.png" },
      { type: "txt", value: "– x " },
    ],
    answer: 0,
  },
  {
    id: 279,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img345.png" },
      {
        type: "txt",
        value:
          "tenglik o`rinli bo`lsa,  f (x) funksiya javoblardan qaysi biriga teng?",
      },
    ],
    options: [
      { type: "txt", value: "x⁴-1+C" },
      { type: "txt", value: "2x³+2x" },
      { type: "txt", value: "x⁴-1" },
      { type: "txt", value: "x³+1" },
    ],
    answer: 1,
  },
  {
    id: 280,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img346.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img347.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img348.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img349.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img350.png" },
    ],
    answer: 2,
  },
  {
    id: 281,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img351.png" },
      { type: "txt", value: "integralni hisoblang." },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img352.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img353.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img354.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img355.png" },
    ],
    answer: 3,
  },
  {
    id: 282,
    question: [
      { type: "txt", value: "Hisoblang:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img356.png" },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "txt", value: "1" },
      { type: "txt", value: "–1" },
      { type: "txt", value: "–2" },
    ],
    answer: 1,
  },
  {
    id: 283,
    question: [
      { type: "txt", value: "Hisoblang:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img357.png" },
    ],
    options: [
      { type: "txt", value: "–1" },
      { type: "txt", value: "1" },
      { type: "txt", value: "0" },
      { type: "txt", value: "0,5" },
    ],
    answer: 1,
  },
  {
    id: 284,
    question: [
      { type: "txt", value: "Hisoblang:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img358.png" },
    ],
    options: [
      { type: "txt", value: "19" },
      { type: "txt", value: "18" },
      { type: "txt", value: "17" },
      { type: "txt", value: "16" },
    ],
    answer: 1,
  },
  {
    id: 285,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img359.png" },
      {
        type: "txt",
        value: "bo`lsa, f(a+b) quyidagilardan  qaysi biriga teng?",
      },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img360.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img361.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img362.png" },
      { type: "txt", value: "f(a)+f(b)" },
    ],
    answer: 1,
  },
  {
    id: 286,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img363.png" },
      { type: "txt", value: "ni A orqali ifodalang." },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img364.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img365.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img366.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img367.png" },
    ],
    answer: 2,
  },
  {
    id: 287,
    question: [
      { type: "txt", value: "Agar x haqiqiy musbat son bo`lib," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img368.png" },
      { type: "txt", value: "bo`lsa," },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img369.png" },
      { type: "txt", value: "ning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "9" },
      { type: "txt", value: "5" },
      { type: "txt", value: "3" },
      { type: "txt", value: "14" },
    ],
    answer: 3,
  },
  {
    id: 288,
    question: [
      { type: "txt", value: "Soddalashtiring:" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img370.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img371.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img372.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img373.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img374.png" },
    ],
    answer: 1,
  },
  {
    id: 289,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img375.png" },
      { type: "txt", value: "ning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "34" },
      { type: "txt", value: "7" },
      { type: "txt", value: "11" },
      { type: "txt", value: "19" },
    ],
    answer: 1,
  },
  {
    id: 290,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img376.png" },
      { type: "txt", value: "ning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "28" },
      { type: "txt", value: "32" },
      { type: "txt", value: "34" },
      { type: "txt", value: "36" },
    ],
    answer: 2,
  },
  {
    id: 291,
    question: [
      {
        type: "txt",
        value: "Agar x²+x+1=0 bo`lsa,  x⁹⁹+x⁹⁹+…+x+1 ning  qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "x+1" },
      { type: "txt", value: "0" },
      { type: "txt", value: "1" },
      { type: "txt", value: "1–x" },
    ],
    answer: 2,
  },
  {
    id: 292,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img377.png" },
      { type: "txt", value: "ning qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "9" },
      { type: "txt", value: "13" },
      { type: "txt", value: "11" },
    ],
    answer: 2,
  },
  {
    id: 293,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img378.png" },
      { type: "txt", value: "bo`lsa, f(8) ning  qiymatini toping." },
    ],
    options: [
      { type: "txt", value: "15" },
      { type: "txt", value: "9" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img379.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img380.png" },
    ],
    answer: 1,
  },
  {
    id: 294,
    question: [
      { type: "txt", value: "Agar" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img381.png" },
      { type: "txt", value: "f(2x+1) ni f(x) orqali ifodalang." },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img382.png" },
      { type: "txt", value: "3 f(x) " },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img383.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img384.png" },
    ],
    answer: 0,
  },
  {
    id: 295,
    question: [
      {
        type: "txt",
        value:
          "Agar f(2x+1)=4∙f(7)-9 va g(x)=x²-2x+5  bo`lsa, g(f(13))ni toping.",
      },
    ],
    options: [
      { type: "txt", value: "8" },
      { type: "txt", value: "13" },
      { type: "txt", value: "29" },
      { type: "txt", value: "50" },
    ],
    answer: 0,
  },
  {
    id: 296,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img385.png" },
      {
        type: "txt",
        value: "tengsizlikni  qanoatlantiruvchi yechimlari to`plamini toping.",
      },
    ],
    options: [
      { type: "txt", value: "[-5;2]" },
      { type: "txt", value: "(-5;2]" },
      { type: "txt", value: "(0;5)" },
      { type: "txt", value: "[0;2]" },
    ],
    answer: 1,
  },
  {
    id: 297,
    question: [
      { type: "txt", value: "5x²-(7m-1)x-11=0  0tenglamaning ildizlari " },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img386.png" },
      { type: "txt", value: "Agar  " },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img387.png" },
      { type: "txt", value: "bo`lsa, m ning qiymatini  toping." },
    ],
    options: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img388.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img389.png" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img390.png" },
      { type: "txt", value: "0" },
    ],
    answer: 2,
  },
  {
    id: 298,
    question: [
      { type: "txt", value: "Tengsizliklar sistemasini yeching: " },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img391.png" },
    ],
    options: [
      { type: "txt", value: "(13;2] " },
      { type: "txt", value: "[2;∞)" },
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img392.png" },
      { type: "txt", value: "∅" },
    ],
    answer: 3,
  },
  {
    id: 299,
    question: [
      { type: "img", value: "/quiz-images/dtm-milliy-sertifikat/img393.png" },
      { type: "txt", value: "dan x+y+z ning qiymatini  toping." },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
    ],
    answer: 3,
  },
];

const inha = [
  {
    id: 0,
    question: [
      { type: "txt", value: "Find the sum of solutions to" },
      { type: "img", value: "/quiz-images/inha/img1.png" },
    ],
    options: [{ type: "txt", value: "6" }],
    answer: 0,
  },
  {
    id: 1,
    question: [
      {
        type: "txt",
        value:
          "Find the sum all natural numbers which satisfy the following inequality equation",
      },
      { type: "img", value: "/quiz-images/inha/img2.png" },
    ],
    options: [{ type: "txt", value: "12" }],
    answer: 0,
  },
  {
    id: 2,
    question: [
      { type: "txt", value: "Find the sum of all solutions of " },
      { type: "img", value: "/quiz-images/inha/img3.png" },
    ],
    options: [{ type: "txt", value: "-3" }],
    answer: 0,
  },
  {
    id: 3,
    question: [
      { type: "txt", value: "Find the sum of solutions to" },
      { type: "img", value: "/quiz-images/inha/img4.png" },
    ],
    options: [{ type: "txt", value: "2" }],
    answer: 0,
  },
  {
    id: 4,
    question: [
      {
        type: "txt",
        value:
          "Find the sum of all natural numbers which satisfy the following inequality equation",
      },
      { type: "img", value: "/quiz-images/inha/img5.png" },
    ],
    options: [{ type: "txt", value: "20" }],
    answer: 0,
  },
  {
    id: 5,
    question: [
      { type: "txt", value: "Find the sum of all solutions of" },
      { type: "img", value: "/quiz-images/inha/img6.png" },
    ],
    options: [{ type: "txt", value: "-5" }],
    answer: 0,
  },
  {
    id: 6,
    question: [
      { type: "txt", value: "When" },
      { type: "img", value: "/quiz-images/inha/img7.png" },
      { type: "txt", value: "find" },
      { type: "img", value: "/quiz-images/inha/img8.png" },
    ],
    options: [{ type: "img", value: "/quiz-images/inha/img9.png" }],
    answer: 0,
  },
  {
    id: 7,
    question: [
      { type: "txt", value: "Evaluate" },
      { type: "img", value: "/quiz-images/inha/img10.png" },
    ],
    options: [{ type: "img", value: "/quiz-images/inha/img11.png" }],
    answer: 0,
  },
  {
    id: 8,
    question: [
      { type: "txt", value: "When" },
      { type: "img", value: "/quiz-images/inha/img12.png" },
      { type: "txt", value: "find" },
      { type: "img", value: "/quiz-images/inha/img13.png" },
    ],
    options: [{ type: "txt", value: "-2" }],
    answer: 0,
  },
  {
    id: 9,
    question: [
      {
        type: "txt",
        value: "Find the sum of all solutions of sinx-cos(2x) =0 where",
      },
      { type: "img", value: "/quiz-images/inha/img14.png" },
    ],
    options: [{ type: "img", value: "/quiz-images/inha/img15.png" }],
    answer: 0,
  },
  {
    id: 10,
    question: [
      {
        type: "txt",
        value:
          "Find the number of all negative integer solutions of the inequality",
      },
      { type: "img", value: "/quiz-images/inha/img16.png" },
    ],
    options: [{ type: "txt", value: "3" }],
    answer: 0,
  },
  {
    id: 11,
    question: [
      { type: "txt", value: "Evaluate" },
      { type: "img", value: "/quiz-images/inha/img17.png" },
    ],
    options: [{ type: "img", value: "/quiz-images/inha/img18.png" }],
    answer: 0,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value: "Suppose that 2a+b=23 and a+2b=25. Find the value of 2a-b",
      },
    ],
    options: [{ type: "txt", value: "5" }],
    answer: 0,
  },
  {
    id: 13,
    question: [
      { type: "txt", value: "Find the smallest integer which is greater than" },
      { type: "img", value: "/quiz-images/inha/img19.png" },
    ],
    options: [{ type: "txt", value: "6" }],
    answer: 0,
  },
  {
    id: 14,
    question: [
      { type: "txt", value: "Find the remainder when a polynomial" },
      { type: "img", value: "/quiz-images/inha/img20.png" },
      { type: "txt", value: "is divided by x-1" },
    ],
    options: [{ type: "txt", value: "-4" }],
    answer: 0,
  },
  {
    id: 15,
    question: [
      { type: "txt", value: "Find" },
      { type: "img", value: "/quiz-images/inha/img21.png" },
      { type: "txt", value: "when" },
      { type: "img", value: "/quiz-images/inha/img22.png" },
    ],
    options: [{ type: "txt", value: "4" }],
    answer: 0,
  },
  {
    id: 16,
    question: [
      { type: "txt", value: "Evaluate" },
      { type: "img", value: "/quiz-images/inha/img23.png" },
    ],
    options: [{ type: "txt", value: "3" }],
    answer: 0,
  },
  {
    id: 17,
    question: [
      { type: "txt", value: "Let" },
      { type: "img", value: "/quiz-images/inha/img24.png" },
      { type: "txt", value: "be a solution of" },
      { type: "img", value: "/quiz-images/inha/img25.png" },
      { type: "txt", value: "Compute" },
      { type: "img", value: "/quiz-images/inha/img26.png" },
    ],
    options: [{ type: "txt", value: "3" }],
    answer: 0,
  },
  {
    id: 18,
    question: [
      { type: "txt", value: "Evaluate" },
      { type: "img", value: "/quiz-images/inha/img27.png" },
      { type: "txt", value: "where" },
      { type: "img", value: "/quiz-images/inha/img28.png" },
    ],
    options: [{ type: "txt", value: "-206" }],
    answer: 0,
  },
  {
    id: 19,
    question: [
      {
        type: "txt",
        value: "Find the sum of all values of x which satisfy the equation",
      },
      { type: "img", value: "/quiz-images/inha/img29.png" },
    ],
    options: [{ type: "txt", value: "3" }],
    answer: 0,
  },
  {
    id: 20,
    question: [
      { type: "txt", value: "Suppose that a function ⨍(x) satisfies" },
      { type: "img", value: "/quiz-images/inha/img30.png" },
      { type: "txt", value: "for every real number x. Evaluate ⨍(-1)" },
    ],
    options: [{ type: "txt", value: "4" }],
    answer: 0,
  },
  {
    id: 21,
    question: [
      {
        type: "txt",
        value: "Find k such that the system of simultaneous equations",
      },
      { type: "img", value: "/quiz-images/inha/img31.png" },
      { type: "txt", value: "does not have a solution in x and y" },
    ],
    options: [{ type: "txt", value: "2" }],
    answer: 0,
  },
  {
    id: 22,
    question: [
      { type: "txt", value: "Find the maximum value of" },
      { type: "img", value: "/quiz-images/inha/img33.png" },
    ],
    options: [{ type: "txt", value: "4" }],
    answer: 0,
  },
  {
    id: 23,
    question: [
      { type: "txt", value: "The sequence" },
      { type: "img", value: "/quiz-images/inha/img34.png" },
      { type: "txt", value: "is defined by" },
      { type: "img", value: "/quiz-images/inha/img35.png" },
      { type: "txt", value: "and" },
      { type: "img", value: "/quiz-images/inha/img36.png" },
      { type: "txt", value: "Evaluate" },
      { type: "img", value: "/quiz-images/inha/img37.png" },
    ],
    options: [{ type: "txt", value: "3" }],
    answer: 0,
  },
  {
    id: 24,
    question: [
      { type: "txt", value: "Suppose that" },
      { type: "img", value: "/quiz-images/inha/img38.png" },
      { type: "txt", value: "where" },
      { type: "img", value: "/quiz-images/inha/img39.png" },
      { type: "txt", value: "Find the sum" },
      { type: "img", value: "/quiz-images/inha/img40.png" },
    ],
    options: [{ type: "txt", value: "5" }],
    answer: 0,
  },
  {
    id: 25,
    question: [
      { type: "txt", value: "Find the minimum value of" },
      { type: "img", value: "/quiz-images/inha/img41.png" },
      { type: "txt", value: "when two real numbers satisfy" },
      { type: "img", value: "/quiz-images/inha/img42.png" },
    ],
    options: [{ type: "txt", value: "1" }],
    answer: 0,
  },
  {
    id: 26,
    question: [
      {
        type: "txt",
        value:
          "Consider a non-decreasing sequence of positive integers  1,2,2,3,3,3,4,4,4,4,5,5,5,5,5,6, …   in which the integer n appears n times. Find the",
      },
      { type: "img", value: "/quiz-images/inha/img43.png" },
      { type: "txt", value: "term." },
    ],
    options: [{ type: "txt", value: "4" }],
    answer: 0,
  },
  {
    id: 27,
    question: [
      { type: "txt", value: "Find the sum of all integers satisfying" },
      { type: "img", value: "/quiz-images/inha/img44.png" },
    ],
    options: [{ type: "txt", value: "16" }],
    answer: 0,
  },
  {
    id: 28,
    question: [
      { type: "txt", value: "Find the sum of all solutions of" },
      { type: "img", value: "/quiz-images/inha/img45.png" },
    ],
    options: [{ type: "img", value: "/quiz-images/inha/img46.png" }],
    answer: 0,
  },
  {
    id: 29,
    question: [
      { type: "txt", value: "Find the sum of all solutions of" },
      { type: "img", value: "/quiz-images/inha/img47.png" },
    ],
    options: [{ type: "txt", value: "3" }],
    answer: 0,
  },
];

// export const data = {
//   olympics: {
//     uz: {
//       3: olympicsClass3,
//       4: olympicsClass4,
//       5: olympicsClass5,
//       6: olympicsClass6,
//       7: olympicsClass7,
//       8: olympicsClass8,
//       9: olympicsClass9,
//       10: olympicsClass10,
//       11: olympicsClass11,
//     },
//     ru: {
//       3: olympicsClass3,
//       4: olympicsClass4,
//       5: olympicsClass5,
//       6: olympicsClass6,
//       7: olympicsClass7,
//       8: olympicsClass8,
//       9: olympicsClass9,
//       10: olympicsClass10,
//       11: olympicsClass11,
//     },
//   },
//   applicants: {
//     uz: {
//       0: applicant,
//     },
//     ru: {
//       0: applicantRu,
//     },
//   },
//   general: {
//     uz: {
//       mathematics: {
//         3: umumiyMatematika3SinfUZ,
//         4: umumiyMatematika4SinfUZ,
//         5: umumiyMatematika5SinfUZ,
//         6: umumiyMatematika6SinfUZ,
//         7: umumiyMatematika7SinfUz,
//         8: umumiyMatematika8SinfUz,
//         9: umumiyMatematika9SinfUz,
//       },
//       algebra: {
//         7: umumiyMatematika7SinfUz,
//         8: umumiyMatematika8SinfUz,
//         9: umumiyMatematika9SinfUz,
//       },
//       planometry: {
//         7: planametriya7_sinf,
//         8: planametriya8_sinf,
//         9: planametriya9_sinf,
//       },
//       stereometry: {
//         7: umumiyMatematika7SinfUz,
//         8: umumiyMatematika8SinfUz,
//         9: umumiyMatematika9SinfUz,
//       },
//     },
//     ru: {
//       mathematics: {
//         3: umumiyMatematika3SinfRU,
//         4: umumiyMatematika4SinfRU,
//         5: umumiyMatematika5SinfRU,
//         6: umumiyMatematika6SinfRU,
//         7: umumiyMatematika7SinfRu,
//         8: umumiyMatematika8SinfRu,
//         9: umumiyMatematika9SinfRu,
//       },
//       algebra: {
//         7: umumiyMatematika7SinfUz,
//         8: umumiyMatematika8SinfUz,
//         9: umumiyMatematika9SinfUz,
//       },
//       planometry: {
//         7: planametriya7_sinf,
//         8: planametriya8_sinf,
//         9: planametriya9_sinf,
//       },
//       stereometry: {
//         7: umumiyMatematika7SinfUz,
//         8: umumiyMatematika8SinfUz,
//         9: umumiyMatematika9SinfUz,
//       },
//     },
//   },
//
//   specialized: {
//     uz: {
//       3: ixtisoslashtirilganMaktablar3Sinf,
//       4: ixtisoslashtirilganMaktablar4Sinf,
//       5: ixtisoslashtirilganMaktablar5Sinf,
//       6: ixtisoslashtirilganMaktablar6Sinf,
//       7: ixtisoslashtirilganMaktablar7Sinf,
//     },
//     ru: {
//       3: ixtisoslashtirilganMaktablar3Sinf,
//       4: ixtisoslashtirilganMaktablar4Sinf,
//       5: ixtisoslashtirilganMaktablar5Sinf,
//       6: ixtisoslashtirilganMaktablar6Sinf,
//       7: ixtisoslashtirilganMaktablar7Sinf,
//     },
//   },
//   competition: {
//     uz: {
//       9: olimpiada9_10Uz,
//       10: olimpiada9_10Uz,
//       11: olimpiada11SinfUz,
//     },
//     ru: {
//       9: olimpiada9_10Ru,
//       10: olimpiada9_10Ru,
//       11: olimpiada11SinfRu,
//     },
//   },
//   certificate: {
//     uz: {
//       0: {
//         gmat: GMAT,
//         gre: GRE,
//         national: DTM_milliy_sertifikat,
//         sat: {
//           isCalculator: SAT_calculator,
//           withoutCalculator: SAT_no_calculator,
//         },
//       },
//     },
//     ru: {
//       0: {
//         gmat: GMAT,
//         gre: GRE,
//         sat: {
//           isCalculator: SAT_calculator,
//           withoutCalculator: SAT_no_calculator,
//         },
//         national: DTM_milliy_sertifikat,
//       },
//     },
//   },
// };

export const data = {
  uz: {
    olympics: {
      3: olympicsClass3,
      4: olympicsClass4,
      5: olympicsClass5,
      6: olympicsClass6,
      7: olympicsClass7,
      8: olympicsClass8,
      9: olympicsClass9,
      10: olympicsClass10,
      11: olympicsClass11,
    },
    applicants: {
      uzb_university: applicant,
      international_university: applicant,
    },
    general: {
      mathematics: {
        3: umumiyMatematika3SinfUZ,
        4: umumiyMatematika4SinfUZ,
        5: umumiyMatematika5SinfUZ,
        6: umumiyMatematika6SinfUZ,
        7: umumiyMatematika7SinfUz,
        8: umumiyMatematika8SinfUz,
        9: umumiyMatematika9SinfUz,
      },
      algebra: {
        7: umumiyMatematika7SinfUz,
        8: umumiyMatematika8SinfUz,
        9: umumiyMatematika9SinfUz,
      },
      planometry: {
        7: planametriya7_sinf,
        8: planametriya8_sinf,
        9: planametriya9_sinf,
      },
      stereometry: {
        7: umumiyMatematika7SinfUz,
        8: umumiyMatematika8SinfUz,
        9: umumiyMatematika9SinfUz,
      },
    },
    specialized1: {
      3: ixtisoslashtirilganMaktablar3Sinf,
      4: ixtisoslashtirilganMaktablar4Sinf,
      5: ixtisoslashtirilganMaktablar5Sinf,
      6: ixtisoslashtirilganMaktablar6Sinf,
      7: ixtisoslashtirilganMaktablar7Sinf,
    },
    competition: {
      9: olimpiada9_10Uz,
      10: olimpiada9_10Uz,
      11: olimpiada11SinfUz,
    },
    certificate: {
      gmat: GMAT,
      gre: GRE,
      national: DTM_milliy_sertifikat,
      sat: {
        isCalculator: SAT_calculator,
        withoutCalculator: SAT_no_calculator,
      },
    },
    specialized: {
      president: {
        problem_solving: prezidentSchoolProblemSolving,
        critical_thinking: prezidentSchoolCriticalThinking,
        mathematics: saralash,
        english: prezidentSchoolEnglish,
      },
      al_khorazmi: {
        mathematics: alXorazmiyMath,
        english: alXorazmiyEnglish,
      },
      mirzo_ulugbek: {
        mathematics: mirzoUlugbekMathUz,
        english: mirzoUlugbekEnglish,
      },
      specialized: {
        mathematics: {
          3: specializedSchoolMath_3class,
          4: specializedSchoolMath_4class,
          5: specializedSchoolMath_5class,
          6: specializedSchoolMath_6class,
          7: specializedSchoolMath_7class,
        },
        english: {
          3: specializedSchoolEnglish_3class,
          4: specializedSchoolEnglish_4class,
          5: specializedSchoolEnglish_5class,
          6: specializedSchoolEnglish_6class,
        },
      },
    },
  },
  ru: {
    olympics: {
      3: olympicsClass3,
      4: olympicsClass4,
      5: olympicsClass5,
      6: olympicsClass6,
      7: olympicsClass7,
      8: olympicsClass8,
      9: olympicsClass9,
      10: olympicsClass10,
      11: olympicsClass11,
    },
    applicants: {
      uzb_university: applicantRu,
      international_university: applicantRu,
    },
    general: {
      mathematics: {
        3: umumiyMatematika3SinfRU,
        4: umumiyMatematika4SinfRU,
        5: umumiyMatematika5SinfRU,
        6: umumiyMatematika6SinfRU,
        7: umumiyMatematika7SinfRu,
        8: umumiyMatematika8SinfRu,
        9: umumiyMatematika9SinfRu,
      },
      algebra: {
        7: umumiyMatematika7SinfRu,
        8: umumiyMatematika8SinfRu,
        9: umumiyMatematika9SinfRu,
      },
      planometry: {
        7: planametriya7_sinf,
        8: planametriya8_sinf,
        9: planametriya9_sinf,
      },
      stereometry: {
        7: umumiyMatematika7SinfRu,
        8: umumiyMatematika8SinfRu,
        9: umumiyMatematika9SinfRu,
      },
    },
    specialized1: {
      3: ixtisoslashtirilganMaktablar3Sinf,
      4: ixtisoslashtirilganMaktablar4Sinf,
      5: ixtisoslashtirilganMaktablar5Sinf,
      6: ixtisoslashtirilganMaktablar6Sinf,
      7: ixtisoslashtirilganMaktablar7Sinf,
    },
    competition: {
      9: olimpiada9_10Ru,
      10: olimpiada9_10Ru,
      11: olimpiada11SinfRu,
    },
    certificate: {
      gmat: GMAT,
      gre: GRE,
      sat: {
        isCalculator: SAT_calculator,
        withoutCalculator: SAT_no_calculator,
      },
      national: DTM_milliy_sertifikat,
    },
    specialized: {
      president: {
        problem_solving: prezidentSchoolProblemSolving,
        critical_thinking: prezidentSchoolCriticalThinking,
        mathematics: saralash,
        english: prezidentSchoolEnglish,
      },
      al_khorazmi: {
        mathematics: alXorazmiyMath,
        english: alXorazmiyEnglish,
      },
      mirzo_ulugbek: {
        mathematics: mirzoUlugbekMathRu,
        english: mirzoUlugbekEnglish,
      },
      specialized: {
        mathematics: {
          3: specializedSchoolMath_3class,
          4: specializedSchoolMath_4class,
          5: specializedSchoolMath_5class,
          6: specializedSchoolMath_6class,
          7: specializedSchoolMath_7class,
        },
        english: {
          3: specializedSchoolEnglish_3class,
          4: specializedSchoolEnglish_4class,
          5: specializedSchoolEnglish_5class,
          6: specializedSchoolEnglish_6class,
        },
      },
    },
  },
};
