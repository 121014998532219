import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const FancyFeatureEleven = ({ toggleModalOne }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="block-style-sixteen" style={{ background: "#EBF3F1" }}>
        <div className="container">
          <div className="row">
            <div
              className="col-xl-5 col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div
                className="icon d-flex align-items-center justify-content-center"
                style={{ background: "#DEEAE7" }}
              >
                <img src="images/icon/73.svg" alt="icon" />
              </div>
              <h3 className="title text-uppercase">{t("general.math")}</h3>
              <p>
                <ol>
                  <li> {t("for.3-11.class")}</li>
                  <li>- {t("speedup.education")}</li>
                  <li>- {t("iq.test")}</li>
                  <li>- {t("problem.solving.skills")}</li>
                  <li>- {t("testing.technique")}</li>
                </ol>
              </p>
              <div to="/solution-management" onClick={toggleModalOne}>
                <img src="images/icon/72.svg" alt="icon" />
              </div>
            </div>
          </div>
        </div>
        <img
          src="images/githubit/free-lesson.jpg"
          alt=""
          className="shapes screen-one"
        />
      </div>
      {/* /.block-style-sixteen */}

      <div className="block-style-sixteen" style={{ background: "#EDF3F9" }}>
        <div className="container">
          <div className="row">
            <div
              className="col-xl-5 col-lg-6 ms-auto"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div
                className="icon d-flex align-items-center justify-content-center"
                style={{ background: "#E1E7ED" }}
              >
                <img src="images/icon/74.svg" alt="icon" />
              </div>
              <h3 className="title">{t("preparation.for.special.schools")}</h3>
              <p>
                <ol>
                  <li>- {t("preparation.for.special.schools.option1")}</li>
                  <li>- {t("preparation.for.special.schools.option2")}</li>
                  <li>- {t("preparation.for.special.schools.option3")}</li>
                  <li>- {t("preparation.for.special.schools.option4")}</li>
                </ol>
              </p>
              <div to="/solution-management" onClick={toggleModalOne}>
                <img src="images/icon/72.svg" alt="icon" />
              </div>
            </div>
          </div>
        </div>
        <img
          src="images/githubit/prepering.jpg"
          alt="screen"
          className="shapes screen-two"
        />
      </div>
      {/* /.block-style-sixteen */}

      <div className="block-style-sixteen" style={{ background: "#F9F1EF" }}>
        <div className="container">
          <div className="row">
            <div
              className="col-xl-6 col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div
                className="icon d-flex align-items-center justify-content-center"
                style={{ background: "#EDE5E3" }}
              >
                <img src="images/icon/75.svg" alt="icon" />
              </div>
              <h3 className="title">{t("applicant")}</h3>
              <p>
                <ul>
                  <li>{t("9-11.classes")}</li>
                  <li>- {t("general.math")}</li>
                  <li>- {t("testing.method")}</li>
                  <li>- {t("preparation.for.international.university")}</li>
                  <li>- {t("preparation.for.national.certificate")}</li>
                </ul>
              </p>
              <div to="/solution-management" onClick={toggleModalOne}>
                <img src="images/icon/72.svg" alt="icon" />
              </div>
            </div>
          </div>
        </div>
        <img
          src="images/githubit/prepare-university.jpg"
          alt="screen"
          className="shapes screen-three"
        />
      </div>
      {/*  /.block-style-sixteen */}
      <div className="block-style-sixteen" style={{ background: "#fff" }}>
        <div className="container">
          <div className="row">
            <div
              className="col-xl-5 col-lg-6 ms-auto"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div
                className="icon d-flex align-items-center justify-content-center"
                style={{ background: "#E1E7ED" }}
              >
                <img src="images/icon/74.svg" alt="icon" />
              </div>
              <h3 className="title">{t("preparation.for.olympics")} </h3>
              <p>
                <ul>
                  <li>{t("3-9.classes")}</li>
                  <li>- {t("competition.of.knowledge")}</li>
                  <li>- {t("kangaroo.international.competition")}</li>
                  <li>- {t("international.olympics")}</li>
                </ul>
              </p>
              <div to="/solution-management" onClick={toggleModalOne}>
                <img src="images/icon/72.svg" alt="icon" />
              </div>
            </div>
          </div>
        </div>
        <img
          src="images/githubit/olimpic.jpg"
          alt="screen"
          className="shapes screen-two"
          style={{ left: 0, top: 0 }}
        />
      </div>
      {/* /.block-style-sixteen */}
    </>
  );
};

export default FancyFeatureEleven;
