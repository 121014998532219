import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { data } from "../../../../../fake-db";
import AnswerOption from "./answer-opt";
import Result from "./result";
import { useContext } from "react";
import { MainContext } from "../../../../../context/MainContext";
import { Link, useNavigate } from "react-router-dom";
import DeadLine from "./deadline";
import { useDispatch, useSelector } from "react-redux";
import { millisecondsToTime } from "../../../../../utils/parseToTime";
import { setUserData } from "../../../../../redux/slices/olympics";

const TestForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [answers, setAnswers] = useState({});
  const [error, setError] = useState([]);
  const [isDirty, setIsDirty] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [score, setScore] = useState({ incorrect: 0, total: 15 });
  const { isTimeOver, setIsTimeOver, setIsEndTask } = useContext(MainContext);
  const { userData } = useSelector((state) => state.olympics);
  const testList = useMemo(() => data?.competition?.[userData.lang]?.[userData.class], [data]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setError([]);
    testList?.forEach((item) => {
      const answer = answers[item.id]?.myAnswer;

      if (item.options[item.answer]?.value !== answer) {
        setError((prev) => ({
          ...prev,
          [item.id]: { answer, status: "error" },
        }));
        if (answer) setScore((prev) => ({ ...prev, incorrect: prev.incorrect + 1 }));
      } else
        setError((prev) => ({
          ...prev,
          [item.id]: { answer, status: "success" },
        }));
    });
    setIsEnd(true);
    setIsDirty(false);
    setIsEndTask(true);
    localStorage.setItem("endTime", Date.now());
  };
  const getData = () => {
    const array = [];
    let index = 0;
    for (const key in answers) {
      index++;
      if (Object.hasOwnProperty.call(answers, key)) {
        const element = answers[key];
        const isHas = element.myAnswer.includes(".png");
        let answer;
        if (isHas) answer = `https://mathschool.uz${element.myAnswer}`;
        else answer = element.myAnswer;

        array.push(`%0A${index})(test ${element.id}) Answer: ${answer}`);
      }
    }
    return array.toString().replaceAll(",", "");
  };

  const createTest = () => {
    const incorrect = score.incorrect;
    const correct = score.total - score.incorrect;
    const percent = `${(((score.total - score.incorrect) * 100) / score.total).toFixed(2)} %`;
    const { minutes, seconds, hours } = millisecondsToTime();

    fetch("https://api.sundaymart.net/api/v1/rest/bot-store", {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        class: userData?.class,
        language: userData?.lang,
        name: `${userData?.firstname || ""} ${userData?.lastname || ""}`,
        number: userData?.number,
        school_number: userData?.school,
        time: `${hours}:${minutes}:${seconds}`,
        true_answer: `${correct}`,
        false_answer: `${incorrect}`,
        percentage_answer: percent,
        result: getData(),
      }),
    })
      .then((response) => response.json())
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (isEnd) {
      createTest();
    }
  }, [isEnd]);

  const handleClick = (data) => {
    setAnswers((prev) => ({ ...prev, [data.id]: data }));
    setIsDirty(true);
  };
  const shuffle = useMemo(() => testList?.sort(() => Math.random() - 0.5), [testList]);

  useEffect(() => {
    // Add a beforeunload event listener
    const handleBeforeUnload = (e) => {
      if (isDirty) {
        e.preventDefault();
        e.returnValue = "You have unsaved changes. Are you sure you want to leave?";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    //Check user data
    if ((!Object.keys(userData).length || !userData?.class || !userData?.lang || !userData?.school) && !isDirty) {
      setIsEndTask(false);
      setIsTimeOver(false);
      dispatch(setUserData({}));
      localStorage.removeItem("endTime");
      localStorage.removeItem("initialTime");
      navigate("/olympics2023");
    }
    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("beforeunload", handleBeforeUnload);
      setIsTimeOver(null);
    };
  }, [isDirty]);

  console.log(userData);

  if (isTimeOver) {
    return <DeadLine />;
  } else
    return (
      <div className="main-page-wrapper pt-0">
        <Helmet>
          <title>Mathschool - учебный центр в Чирчике</title>
        </Helmet>
        <div className="faqs-inner-page pt-0 pb-0" style={{ background: "unset" }}>
          <div className="container">
            <div className="row">
              <div className="col-xl-11 m-auto">
                <div className="all-faqs">
                  <div className="faqs-all-qus">
                    {shuffle?.length > 0 ? (
                      <form onSubmit={(e) => onSubmit(e)}>
                        {shuffle?.slice(0, 15)?.map((item, i) => (
                          <div className="article-preview d-flex" key={i} data-aos="fade-up" data-aos-duration="1200">
                            <div className="w-100">
                              <h3 className="font-rubik article-preview-title">
                                {`${i + 1}. `}
                                {item.question?.map((q) => {
                                  if (q.type === "img") {
                                    return <img src={q.value} alt={q.value} />;
                                  } else return q.value;
                                })}
                              </h3>
                              <div className="avatar-info">
                                <ul className="test-options">
                                  {item.options?.map((opt, key) => {
                                    return (
                                      <li
                                        key={key}
                                        className={
                                          isEnd
                                            ? error[item.id].answer === opt?.value
                                              ? error[item.id].status
                                              : ""
                                            : ""
                                        }
                                        onClick={() =>
                                          handleClick({
                                            id: item.id,
                                            myAnswer: opt.value,
                                          })
                                        }>
                                        <AnswerOption item={item} isEnd={isEnd} opt={opt} />
                                        <br />
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        ))}
                        {!isEnd && (
                          <div className="text-center more-faq-ask">
                            <button className="theme-btn-one">Отправить</button>
                          </div>
                        )}
                      </form>
                    ) : (
                      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 24 }}>
                        <img src="/images/githubit/wrong.png" />
                        <div className="text-center more-faq-ask">
                          <Link to="/olympics2023">
                            <button className="theme-btn-one">перезагрузить</button>
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isEnd && <Result score={score} answers={answers} />}
      </div>
    );
};

export default TestForm;
