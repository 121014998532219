import React from "react";
import { Helmet } from "react-helmet";
import TypeForm from "./components/type-form";
import HeaderLanding from "../../../../components/header/landing/HeaderLanding";
import FooterFive from "../../../../components/footer/FooterFive";
import { useTranslation } from "react-i18next";

const QuizType = () => {
  const { t } = useTranslation();
  return (
    <div className="main-page-wrapper font-gordita">
      <Helmet>
        <title> Mathschool - учебный центр в Чирчике</title>
      </Helmet>
      <HeaderLanding />

      {/*<div className="container my-5 py-5 my-5">*/}
      {/*<div className="form-style-light" style={{ maxWidth: 650 }}>*/}
      <TypeForm />
      {/*</div>*/}
      {/*</div>*/}
      {/* =====================================================
				Footer Style Five
			===================================================== */}
      <footer className="theme-footer-five mt-130 md-mt-100">
        <div className="inner-container">
          <div className="container">
            <FooterFive />
          </div>
        </div>
        {/* /.inner-container */}
        <p className="copyright">
          г. Чирчик, массив Ак-кавак, макро супермаркет, 2 этаж.{" "}
        </p>
      </footer>
      {/*  /.theme-footer-five */}
    </div>
  );
};

export default QuizType;
