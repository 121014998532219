import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import { useTranslation } from "react-i18next";

const FancyTextBlock23 = () => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <div className="row align-items-start">
        <div
          className="col-xl-5 col-lg-6 ms-auto"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div className="text-wrapper">
            <p>{t("chirchik.math.olympics")}</p>
          </div>
          <li className="sub-text pt-0 pb-12">
            {t("chirchik.math.olympics.desc")}
          </li>
          <li className="sub-text pt-30 pb-12">{t("olympics.date.desc")}</li>
          <li className="sub-text pt-30 pb-12">{t("olympics.prize.desc")}</li>
          <li className="sub-text pt-30 pb-12">{t("rus.uzb.questions")}</li>
          <li className="sub-text pt-30 pb-12">{t("join.for.free")}</li>
          <li className="sub-text pt-30 pb-12">{t("more.info")}</li>
          <a
            style={{ color: "blue" }}
            href="https://forms.gle/rMSBqrYwmQJWEd2a6"
            target="_blank"
          >
            https://forms.gle/rMSBqrYwmQJWEd2a6
          </a>
          {/* /.text-wrapper */}
        </div>
        {/* End .col */}
        <div
          className="col-lg-6 col-md-7 m-auto"
          data-aos="fade-left"
          data-aos-duration="1200"
        >
          <div className="img-meta">
            <img
              src="images/githubit/olimpiada.PNG"
              alt="media"
              className="m-auto"
            />
            <img
              src="images/shape/135.svg"
              alt="shape"
              className="shapes shape-one"
            />
          </div>
        </div>
        {/* End .col */}
      </div>

      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="aj77DafszIc"
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default FancyTextBlock23;
