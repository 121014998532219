import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import TranslationRU from "./assets/locale/ru/translation.json";
import TranslationUZ from "./assets/locale/uz/translation.json";

const resources = {
  ru: {
    translation: TranslationRU,
  },
  uz: {
    translation: TranslationUZ,
  },
};

i18n
  .use(initReactI18next)
  .use(Backend)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: "uz",
    lng: localStorage.getItem("i18nextLng") || "uz",
  });

export default i18n;
