import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { useTranslation } from "react-i18next";

const FaqClassicThree = () => {
  const { t } = useTranslation();
  const FaqContent = [
    {
      title: t("when.does.start.test.lesson"),
      desc: t("when.does.start.test.lesson.answer"),
      expand: "0",
    },
    {
      title: t("how.much.does.homework.takes.time"),
      desc: t("how.much.does.homework.takes.time.answer"),
      expand: "a",
    },
    {
      title: t("who.are.your.teachers"),
      desc: t("who.are.your.teachers.answer"),
      expand: "b",
    },
    {
      title: t("at.what.age.is.suitable.for"),
      desc: t("at.what.age.is.suitable.for.answer"),
      expand: "c",
    },
    {
      title: t("is.your.program.suitable.for.my.child"),
      desc: t("is.your.program.suitable.for.my.child.answer"),
      expand: "d",
    },
    {
      title: t("will.there.be.any.confusion.between.your.program"),
      desc: t("will.there.be.any.confusion.between.your.program.answer"),
      expand: "e",
    },
  ];
  return (
    <div className="accordion-style-five md-mt-60">
      <div className="faq-wrraper">
        <Accordion allowZeroExpanded preExpanded={["0"]}>
          {FaqContent.map((item, i) => (
            <AccordionItem className="card" key={i} uuid={item.expand}>
              <AccordionItemHeading className="card-header">
                <AccordionItemButton>
                  <h5 className="mb-0">
                    <button className="btn btn-link">{item.title}</button>{" "}
                  </h5>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel className="card-body fadeInUp">
                <p>{item.desc}</p>
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default FaqClassicThree;
