import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../../../../redux/slices/olympics";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const BillingDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [section, setSection] = useState("general");
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const { userData } = useSelector((state) => state.olympics);

  // for validation
  const validationSchema = Yup.object().shape({
    school: Yup.string().required("school is required"),
    class: Yup.string().required("class is required"),
    firstname: Yup.string().required("firstname is required"),
    lastname: Yup.string().required("lastname is required"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  function onSubmit(data, e) {
    setLoading(true);
    fetch("https://api.sundaymart.net/api/v1/rest/bot-check-user", {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        number: phone,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status) {
          e.target.reset();
          dispatch(setUserData({ ...userData, ...data, number: phone }));
          navigate("/olympics/test");
        } else {
          toast.error(res.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }
  return (
    <form className="checkout-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="user-profile-data">
        <div className="row">
          <div className="col-12">
            <input
              type="text"
              placeholder="Имя"
              className={`single-input-wrapper mb-0 mt-5`}
              {...register("firstname")}
            />
            {errors.firstname && <div className="invalid-feedback">{errors.firstname?.message}</div>}
          </div>
          <div className="col-12">
            <input
              type="text"
              placeholder="Фамилия"
              className={`single-input-wrapper mb-0 mt-5`}
              {...register("lastname")}
            />
            {errors.lastname && <div className="invalid-feedback">{errors.lastname?.message}</div>}
          </div>
          <div className="col-12">
            <PhoneInput
              country={"uz"}
              onlyCountries={["uz"]}
              countryCodeEditable={false}
              onChange={(phone) => setPhone(phone)}
              className="single-input-wrapper mb-0 mt-5"
            />
            {errors.phone && <div className="invalid-feedback">{errors.phone?.message}</div>}
          </div>
          <div className="col-12">
            <GetType register={register} lang={userData?.lang} setSection={setSection} />
          </div>
          <div className="col-12">
            <select className="theme-select-menu mb-0 mt-5" {...register("class")}>
              <GetClassOptions type={section} lang={userData?.lang} />
            </select>
          </div>
        </div>
      </div>
      <div className="col-12 d-flex justify-content-center mt-5">
        <button type="submit" className="theme-btn-two" disabled={loading}>
          {loading ? "загрузка..." : "Начать"}
        </button>
      </div>
    </form>
  );
};
const GetType = ({ lang, setSection, register }) => {
  return (
    <>
      {lang === "uz" ? (
        <select
          className="theme-select-menu mb-0 mt-5"
          {...register("school")}
          onChange={(e) => setSection(e.target.value)}>
          {Array(26)
            .fill(null)
            .map((_, index) => {
              if (index !== 18 && index !== 22) return <option value={index + 1}>{`${index + 1}-maktab`}</option>;
            })}
        </select>
      ) : (
        <select
          className="theme-select-menu mb-0 mt-5"
          {...register("school")}
          onChange={(e) => setSection(e.target.value)}>
          {Array(26)
            .fill(null)
            .map((_, index) => {
              if (index !== 18 && index !== 22) return <option value={index + 1}>{`${index + 1}-школа`}</option>;
            })}
        </select>
      )}
    </>
  );
};
const GetClassOptions = ({ lang }) => {
  return (
    <>
      <option value={9}>9 {`${lang === "uz" ? " - sinf" : " класс"}`}</option>
      <option value={10}>10 {`${lang === "uz" ? " - sinf" : " класс"}`}</option>
      <option value={11}>11 {`${lang === "uz" ? " - sinf" : " класс"}`}</option>
    </>
  );
};

export default BillingDetails;
