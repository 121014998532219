import { useState } from "react";
import { createContext } from "react";

export const MainContext = createContext();

export function MainContextProvider({ children }) {
  const [userData, setUserData] = useState({});
  const [isTimeOver, setIsTimeOver] = useState(false);
  const [isEndTask, setIsEndTask] = useState(false);

  return (
    <MainContext.Provider value={{ userData, setUserData, isTimeOver, setIsTimeOver, isEndTask, setIsEndTask }}>
      {children}
    </MainContext.Provider>
  );
}
