import React, { useEffect } from "react";
import { useContext } from "react";
import { MainContext } from "../../../../../context/MainContext";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ButtonSelect from "../../../../../components/ui/select/button-select";
import { useTranslation } from "react-i18next";

const languageSelect = {
  title: "Qaysi tilda test topshirasiz? / На каком языке  хотите сдавать тест?",
  options: [
    { value: "uz", label: "O’zbek tilida" },
    { value: "ru", label: "На русском языке" },
  ],
};

const LanguageForm = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { setUserData, setIsEndTask, setIsTimeOver } = useContext(MainContext);

  const handleClick = (e) => {
    setUserData((prev) => ({ ...prev, lang: e }));
    i18n.changeLanguage(e).then((res) => {
      localStorage.setItem("i18nextLng", e);
    });
    navigate("/quiz/type");
  };

  useEffect(() => {
    setIsEndTask(false);
    setIsTimeOver(false);
    localStorage.removeItem("endTime");
    localStorage.removeItem("initialTime");
  }, []);
  return (
    <ButtonSelect title={languageSelect.title}>
      {languageSelect.options.map((item) => (
        <div className="col-6 p-1">
          <ButtonSelect.BigOption
            onClick={handleClick}
            {...item}
            key={item.value}
          />
        </div>
      ))}
    </ButtonSelect>
  );
};
export default LanguageForm;
