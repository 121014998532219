import React, { useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { data } from "../../../../../fake-db/quiz";
import AnswerOption from "./answer-opt";
import Result from "./result";
import { useContext } from "react";
import { MainContext } from "../../../../../context/MainContext";
import { Link, useNavigate } from "react-router-dom";
import DeadLine from "./deadline";

const TestForm = () => {
  const formRef = useRef(null);
  const navigate = useNavigate();
  const [answers, setAnswers] = useState({});
  const [error, setError] = useState([]);
  const [isDirty, setIsDirty] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [score, setScore] = useState({ incorrect: 0, total: 0 });
  const { userData, isTimeOver, setIsTimeOver, setIsEndTask } =
    useContext(MainContext);
  const counts = {
    president: {
      english: 40,
      mathematics: 30,
      problem_solving: 20,
      critical_thinking: 20,
    },
    al_khorazmi: {
      mathematics: 30,
      english: 15,
    },
    mirzo_ulugbek: {
      mathematics: 30,
      english: 15,
    },
    specialized: {
      mathematics: 30,
      english: 20,
    },
  };
  const dataBySection =
    useMemo(() => data?.[userData?.certificate], [data]) || {};
  const { english, mathematics, critical_thinking, problem_solving } =
    dataBySection;

  const {
    critical_thinking: cth,
    problem_solving: ps,
    english: en,
    mathematics: math,
  } = userData?.science || {};
  let testList = [];

  const shuffledEnglishTest = useMemo(
    () =>
      shuffleAndSlice({
        data:
          userData?.certificate === "specialized"
            ? english[userData?.class]
            : english,
        type: "english",
      }),
    [english]
  );
  const shuffledMathTest = useMemo(
    () =>
      shuffleAndSlice({
        data: mathematics?.[userData.lang]?.[userData.class],
        type: "mathematics",
      }),
    [mathematics, userData.lang, userData.class]
  );
  const shuffledCriticalThinkingTest = useMemo(
    () =>
      shuffleAndSlice({
        data: critical_thinking?.[userData.lang]?.[userData.class],
        type: "critical_thinking",
      }),
    [critical_thinking, userData.lang, userData.class]
  );
  const shuffledProblemSolvingTest = useMemo(
    () =>
      shuffleAndSlice({
        data: problem_solving?.[userData.lang]?.[userData.class],
        type: "problem_solving",
      }),
    [problem_solving, userData.lang, userData.class]
  );
  if (en) {
    testList = [...shuffledEnglishTest];
  }
  if (math) {
    testList = [...shuffledMathTest];
  }
  if (cth && ps) {
    testList = [...shuffledCriticalThinkingTest, ...shuffledProblemSolvingTest];
  }
  useEffect(() => {
    if (en) {
      setScore((prev) => ({
        ...prev,
        total: counts[userData?.certificate]?.english,
      }));
    }
    if (math) {
      setScore((prev) => ({
        ...prev,
        total: counts[userData?.certificate]?.mathematics,
      }));
    }
    if (cth && ps) {
      setScore((prev) => ({ ...prev, total: 40 }));
    }
  }, [en, math, cth, ps]);
  function shuffleAndSlice({ data, type }) {
    if (!data) return [];
    const shuffled = data?.slice().sort(() => Math.random() - 0.5);
    return shuffled?.slice(0, counts[userData?.certificate][type] || 20);
  }
  const onSubmit = (e) => {
    e.preventDefault();
    setError([]);
    testList?.forEach((item) => {
      const answer = answers[item.id]?.myAnswer;

      if (item.options[item.answer]?.value !== answer) {
        setError((prev) => ({
          ...prev,
          [item.id]: { answer, status: "error" },
        }));
        if (answer)
          setScore((prev) => ({ ...prev, incorrect: prev.incorrect + 1 }));
      } else
        setError((prev) => ({
          ...prev,
          [item.id]: { answer, status: "success" },
        }));
    });
    setIsEnd(true);
    setIsDirty(false);
    setIsEndTask(true);
    localStorage.setItem("endTime", Date.now());
  };

  const handleClick = (data) => {
    setAnswers((prev) => ({ ...prev, [data.id]: data }));
    setIsDirty(true);
  };

  useEffect(() => {
    // Add a beforeunload event listener
    const handleBeforeUnload = (e) => {
      if (isDirty) {
        e.preventDefault();
        e.returnValue =
          "You have unsaved changes. Are you sure you want to leave?";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    //Check user data
    if (!Object.keys(userData).length && !isDirty) {
      navigate("/chirchik");
    }
    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("beforeunload", handleBeforeUnload);
      setIsTimeOver(null);
    };
  }, [isDirty]);
  if (isTimeOver) {
    return <DeadLine />;
  } else
    return (
      <div className="main-page-wrapper pt-0">
        <Helmet>
          <title>Mathschool - учебный центр в Чирчике</title>
        </Helmet>
        <div
          className="faqs-inner-page pt-0 pb-0"
          style={{ background: "unset" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-11 m-auto">
                <div className="all-faqs">
                  <div className="faqs-all-qus">
                    <form onSubmit={(e) => onSubmit(e)} ref={formRef}>
                      {testList?.length > 0 ? (
                        testList?.map((item, i) => (
                          <div
                            className="article-preview d-flex"
                            key={i}
                            data-aos="fade-up"
                            data-aos-duration="1200"
                          >
                            <div className="w-100">
                              <h3 className="font-rubik article-preview-title">
                                {`${i + 1}. `}
                                {item.question?.map((q) => {
                                  if (q.type === "img") {
                                    return <img src={q.value} alt={q.value} />;
                                  } else return q.value;
                                })}
                              </h3>
                              <div className="avatar-info">
                                <ul className="test-options">
                                  {item.options?.map((opt, key) => {
                                    return (
                                      <li
                                        key={key}
                                        className={
                                          isEnd
                                            ? error[item.id]?.answer ==
                                              opt?.value
                                              ? error[item.id]?.status
                                              : ""
                                            : ""
                                        }
                                        onClick={() =>
                                          handleClick({
                                            id: item.id,
                                            myAnswer: opt.value,
                                          })
                                        }
                                      >
                                        <AnswerOption
                                          item={item}
                                          isEnd={isEnd}
                                          opt={opt}
                                        />
                                        <br />
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: 24,
                          }}
                        >
                          <img src="/images/githubit/wrong.png" />
                          <div className="text-center more-faq-ask">
                            <Link to="/chirchik">
                              <button className="theme-btn-one">
                                перезагрузить
                              </button>
                            </Link>
                          </div>
                        </div>
                      )}
                      {!isEnd && (
                        <div className="text-center more-faq-ask">
                          <button className="theme-btn-one">Отправить</button>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isEnd && (
          <Result
            score={score}
            answers={answers}
            setError={setError}
            setIsEnd={setIsEnd}
            setScore={setScore}
            setAnswers={setAnswers}
            formRef={formRef}
            total={score.total}
          />
        )}
      </div>
    );
};

export default TestForm;
