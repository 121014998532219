export const questionsMapUz = {
  options: [
    {
      children: {
        title: "select.section",
        selectionType: "small",
        options: [
          {
            value: "general",
            label: "general.math",
            children: {
              title: "select.field",
              selectionType: "big",
              options: [
                {
                  label: "math",
                  value: "mathematics",
                  children: {
                    title: "select.class",
                    selectionType: "big",
                    options: [
                      {
                        label: "3.class",
                        value: "3",
                        children: null,
                      },
                      {
                        label: "4.class",
                        value: "4",
                        children: null,
                      },
                      {
                        label: "5.class",
                        value: "5",
                        children: null,
                      },
                      {
                        label: "6.class",
                        value: "6",
                        children: null,
                      },
                    ],
                  },
                },
                {
                  label: "algebra",
                  value: "algebra",
                  children: {
                    title: "select.class",
                    selectionType: "big",
                    options: [
                      { label: "7.class", value: "7", children: null },
                      {
                        label: "8.class",
                        value: "8",
                        children: null,
                      },
                      { label: "9.class", value: "9", children: null },
                    ],
                  },
                },
                {
                  label: "planometry",
                  value: "planometry",
                  children: {
                    title: "select.class",
                    selectionType: "big",
                    options: [
                      {
                        label: "7.class",
                        value: "7",
                        children: null,
                      },
                      {
                        label: "8.class",
                        value: "7",
                        children: null,
                      },
                      {
                        label: "9.class",
                        value: "7",
                        children: null,
                      },
                    ],
                  },
                },
                {
                  label: "stereometry",
                  value: "stereometry",
                  children: {
                    title: "select.class",
                    selectionType: "big",
                    options: [
                      { label: "7.class", value: "7", children: null },
                      { label: "8.class", value: "8", children: null },
                      { label: "9.class", value: "9", children: null },
                    ],
                  },
                },
              ],
            },
          },
          {
            value: "competition",
            label: "olympics",
            children: {
              title: "select.class",
              selectionType: "big",
              options: [
                {
                  label: "9.class",
                  value: "9",
                  children: null,
                },
                {
                  label: "10.class",
                  value: "10",
                  children: null,
                },
                {
                  label: "11.class",
                  value: "11",
                  children: null,
                },
              ],
            },
          },
          {
            value: "certificate",
            label: "certificate",
            children: {
              title: "select.field",
              selectionType: "big",
              options: [
                { value: "gmat", label: "gmat", children: null },
                { value: "gre", label: "gre", children: null },
                {
                  value: "national",
                  label: "national.certificate",
                  children: null,
                },
                {
                  value: "sat",
                  label: "sat",
                  children: {
                    title: "select.test.type",
                    selectionType: "small",
                    options: [
                      {
                        value: "isCalculator",
                        label: "isCalculator",
                        children: null,
                      },
                      {
                        value: "withoutCalculator",
                        label: "withoutCalculator",
                        children: null,
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            value: "specialized",
            label: "specialized.school",
            children: {
              title: "select.field",
              selectionType: "big",
              options: [
                {
                  label: "president.school",
                  value: "president",
                  img: "/images/assets/logo-prezident-maktabi.png",
                  children: {
                    title: "select.field",
                    selectionType: "big",
                    options: [
                      {
                        label: "problem_solving",
                        value: "problem_solving",
                        children: null,
                      },
                      {
                        label: "critical_thinking",
                        value: "critical_thinking",
                        children: null,
                      },
                      {
                        label: "mathematics",
                        value: "mathematics",
                        children: null,
                      },
                      {
                        label: "english",
                        value: "english",
                        children: null,
                      },
                    ],
                  },
                },
                {
                  label: "al_khorazmi.school",
                  value: "al_khorazmi",
                  img: "/images/assets/logo-alxorazmiy.png",
                  children: {
                    title: "select.science",
                    selectionType: "big",
                    options: [
                      { label: "math", value: "mathematics", children: null },
                      { label: "english", value: "english", children: null },
                    ],
                  },
                },
                {
                  value: "mirzo_ulugbek",
                  label: "mirzo_ulugbek.school",
                  img: "/images/assets/logo-mirzo-ulugbek.png",
                  children: {
                    title: "select.science",
                    selectionType: "big",
                    options: [
                      { label: "math", value: "mathematics", children: null },
                      { label: "english", value: "english", children: null },
                    ],
                  },
                },
                {
                  value: "specialized",
                  label: "specialized.school",
                  img: "/images/assets/logo-ixtisoslashtirilgan.png",
                  children: {
                    title: "select.science",
                    selectionType: "big",
                    options: [
                      {
                        label: "math",
                        value: "mathematics",
                        children: {
                          title: "select.class",
                          selectionType: "big",
                          options: [
                            { value: 3, label: "3.class", children: null },
                            { value: 4, label: "4.class", children: null },
                            { value: 5, label: "5.class", children: null },
                            { value: 6, label: "6.class", children: null },
                            { value: 7, label: "7.class", children: null },
                          ],
                        },
                      },
                      {
                        label: "english",
                        value: "english",
                        children: {
                          title: "select.class",
                          selectionType: "big",
                          options: [
                            { value: 3, label: "3.class", children: null },
                            { value: 4, label: "4.class", children: null },
                            { value: 5, label: "5.class", children: null },
                            { value: 6, label: "6.class", children: null },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            value: "applicants",
            label: "applicants.test",
            children: {
              title: "select.field",
              selectionType: "big",
              options: [
                {
                  label: "uzb.university",
                  value: "uzb_university",
                  children: null,
                },
                {
                  label: "international.university",
                  value: "international_university",
                  children: null,
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

export const questionsMapRu = {
  options: [
    {
      children: {
        title: "select.section",
        selectionType: "small",
        options: [
          {
            value: "general",
            label: "general.math",
            children: {
              title: "select.field",
              selectionType: "big",
              options: [
                {
                  label: "math",
                  value: "mathematics",
                  children: {
                    title: "select.class",
                    selectionType: "big",
                    options: [
                      {
                        label: "3.class",
                        value: "3",
                        children: null,
                      },
                      {
                        label: "4.class",
                        value: "4",
                        children: null,
                      },
                      {
                        label: "5.class",
                        value: "5",
                        children: null,
                      },
                      {
                        label: "6.class",
                        value: "6",
                        children: null,
                      },
                    ],
                  },
                },
                {
                  label: "algebra",
                  value: "algebra",
                  children: {
                    title: "select.class",
                    selectionType: "big",
                    options: [
                      { label: "7.class", value: "7", children: null },
                      {
                        label: "8.class",
                        value: "8",
                        children: null,
                      },
                      { label: "9.class", value: "9", children: null },
                    ],
                  },
                },
              ],
            },
          },
          {
            value: "competition",
            label: "olympics",
            children: {
              title: "select.class",
              selectionType: "big",
              options: [
                {
                  label: "9.class",
                  value: "9",
                  children: null,
                },
                {
                  label: "10.class",
                  value: "10",
                  children: null,
                },
                {
                  label: "11.class",
                  value: "11",
                  children: null,
                },
              ],
            },
          },
          {
            value: "certificate",
            label: "certificate",
            children: {
              title: "select.field",
              selectionType: "big",
              options: [
                { value: "gmat", label: "gmat", children: null },
                { value: "gre", label: "gre", children: null },
                {
                  value: "sat",
                  label: "sat",
                  children: {
                    title: "select.test.type",
                    selectionType: "small",
                    options: [
                      {
                        value: "isCalculator",
                        label: "isCalculator",
                        children: null,
                      },
                      {
                        value: "withoutCalculator",
                        label: "withoutCalculator",
                        children: null,
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            value: "specialized",
            label: "specialized.school",
            children: {
              title: "select.field",
              selectionType: "big",
              options: [
                {
                  value: "mirzo_ulugbek",
                  label: "mirzo_ulugbek.school",
                  img: "/images/assets/logo-mirzo-ulugbek.png",
                  children: {
                    title: "select.science",
                    selectionType: "big",
                    options: [
                      { label: "math", value: "mathematics", children: null },
                      { label: "english", value: "english", children: null },
                    ],
                  },
                },
              ],
            },
          },
          {
            value: "applicants",
            label: "applicants.test",
            children: {
              title: "select.field",
              selectionType: "big",
              options: [
                {
                  label: "uzb.university",
                  value: "uzb_university",
                  children: null,
                },
                {
                  label: "international.university",
                  value: "international_university",
                  children: null,
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
