import React from "react";
import { useId } from "react";

const AnswerOption = ({ item, isEnd, opt }) => {
  const attrId = useId();
  return (
    <>
      <input type="radio" id={`id_${attrId}`} name={item.id} value={opt} disabled={isEnd} required />
      <label htmlFor={`id_${attrId}`}>{opt.type === "img" ? <img src={opt.value} alt={opt.value} /> : opt.value}</label>
    </>
  );
};

export default AnswerOption;
