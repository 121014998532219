import React from "react";
import FormEvent from "../form/FormEvent";
import { useTranslation } from "react-i18next";

const CallToActionFour = () => {
  const { t } = useTranslation();
  return (
    <div className="row align-items-center">
      <div className="col-lg-6">
        <div className="title-style-one">
          <h6 className="font-rubik" style={{ color: "#787CFF" }}>
            {t("weekly.test.lesson")}
          </h6>
          <h2>{t("join.test.lesson")}.</h2>
        </div>
        {/* /.title-style-one */}
      </div>
      <div className="col-lg-6">
        <div className="form-wrapper">
          <FormEvent />
          <p className="font-rubik">{t("user.agreement")}</p>
        </div>
        {/* /.form-wrapper */}
      </div>
    </div>
  );
};

export default CallToActionFour;
