import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  userData: {},
  error: "",
};

const olympicsSlice = createSlice({
  name: "olympics",
  initialState,
  reducers: {
    setUserData(state, action) {
      const { payload } = action;
      state.userData = payload;
    },
  },
});
export const { setUserData } = olympicsSlice.actions;
export default olympicsSlice.reducer;
