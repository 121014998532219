/* {
  id: __,
  question: [
    { type: "__", value: "__" },
    { type: "__", value: "__" },
  ],
  options: [
    { type: "__", value: "__" },
    { type: "__", value: "__" },
    { type: "__", value: "__" },
    { type: "__", value: "__" },
    { type: "__", value: "__" },
  ],
  answer: __,
}, */

export const alXorazmiyEnglish = [
  {
    id: 0,
    question: [
      {
        type: "txt",
        value:
          "Find the correct answer. ``______ is his job?`` ``He ______ a policeman``",
      },
    ],
    options: [
      { type: "txt", value: "Which/is" },
      { type: "txt", value: "what/is" },
      { type: "txt", value: "what/are" },
      { type: "txt", value: "where/is" },
    ],
    answer: 1,
  },
  {
    id: 1,
    question: [{ type: "txt", value: "My teacher`s name ________ Nick" }],
    options: [
      { type: "txt", value: "are" },
      { type: "txt", value: "am" },
      { type: "txt", value: "is" },
      { type: "txt", value: "not" },
    ],
    answer: 2,
  },
  {
    id: 2,
    question: [{ type: "txt", value: "Ibrohim and Anvar _____ my brothers." }],
    options: [
      { type: "txt", value: "Are" },
      { type: "txt", value: "am" },
      { type: "txt", value: "is" },
      { type: "txt", value: "be" },
    ],
    answer: 0,
  },
  {
    id: 3,
    question: [
      { type: "txt", value: "A: What is _____ ? B: Rayyona is a doctor." },
    ],
    options: [
      { type: "txt", value: "His job" },
      { type: "txt", value: "she job" },
      { type: "txt", value: "he job" },
      { type: "txt", value: "her job" },
    ],
    answer: 3,
  },
  {
    id: 4,
    question: [
      {
        type: "txt",
        value:
          "Midas thanks Dionysus and goes back to his royal palace. He is so excited! His first stop is his garden. He touches a rose and it turns into gold. After that, he touches an apple. It also turns into gold. He picks up some stones and they all change into gold. He can’t stop smiling!",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/ingliz-tili/img1.png",
      },
      { type: "txt", value: "How does King Midas feel about his magic gift?" },
    ],
    options: [
      { type: "txt", value: "He`s very happy" },
      { type: "txt", value: "He`s sad" },
      { type: "txt", value: "He`s unhappy" },
      { type: "txt", value: "He`s bored" },
    ],
    answer: 0,
  },
  {
    id: 5,
    question: [
      {
        type: "txt",
        value:
          "Match the words to complete the sentence. You can _____ with your ____.   1)Touch  2)Smell  3)See  4)Taste      a)eyes b)mouth c)nose e)hand",
      },
    ],
    options: [
      { type: "txt", value: "1d/2b/3c/4a" },
      { type: "txt", value: "1d/2a/3c/4b" },
      { type: "txt", value: "1b/2c/3d/4a" },
      { type: "txt", value: "1d/2c/3a/4b" },
    ],
    answer: 3,
  },
  {
    id: 6,
    question: [
      {
        type: "txt",
        value:
          "Choose the appropriate answers for the following questions  What is your brother like?",
      },
    ],
    options: [
      { type: "txt", value: "He likes football" },
      { type: "txt", value: "He is fond of swimming" },
      { type: "txt", value: "He is tall and hand some" },
      { type: "txt", value: "We like him very much" },
    ],
    answer: 0,
  },
  {
    id: 7,
    question: [
      {
        type: "txt",
        value:
          "Where is the conversation taking place?    -Could you drive faster, please.   -Yes, madam. But don`t have the wrong idea, the faster I drive the less you pay.",
      },
    ],
    options: [
      { type: "txt", value: "At the bus stop" },
      { type: "txt", value: "In a cafe" },
      { type: "txt", value: "In a taxi" },
      { type: "txt", value: "In a plane" },
    ],
    answer: 2,
  },
  {
    id: 8,
    question: [
      {
        type: "txt",
        value: "How long had you been there before you met your friends?",
      },
    ],
    options: [
      { type: "txt", value: "Yesterday" },
      { type: "txt", value: "for 2 hours" },
      { type: "txt", value: "It was late" },
      { type: "txt", value: "at the beginning of the lesson" },
    ],
    answer: 1,
  },
  {
    id: 9,
    question: [
      {
        type: "txt",
        value:
          "Look at these notices. It contains mistake. Find the mistake and correct it.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/ingliz-tili/img2.png",
      },
      {
        type: "txt",
        value: "Please switch on your mobile phone before coming into class.",
      },
    ],
    options: [
      { type: "txt", value: "Call phone (mobile phone)" },
      { type: "txt", value: "During the class (before the class)" },
      { type: "txt", value: "After the class (before the class)" },
      { type: "txt", value: "Switch off (switch on)" },
    ],
    answer: 3,
  },
  {
    id: 10,
    question: [
      {
        type: "txt",
        value:
          "Look at these notires. It contains mistake. Find the mistake and correct it.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/ingliz-tili/img3.png",
      },
      { type: "txt", value: "Please feed the animals at the zoo." },
    ],
    options: [
      { type: "txt", value: "Not animals (animals)" },
      { type: "txt", value: "In the cage ( at the zoo)" },
      { type: "txt", value: "Don`t feed (feed the animal)" },
      { type: "txt", value: "Lions (animals)" },
    ],
    answer: 2,
  },
  {
    id: 11,
    question: [
      { type: "txt", value: "Find the correct answer. I’m ______ New York" },
    ],
    options: [
      { type: "txt", value: "In" },
      { type: "txt", value: "for" },
      { type: "txt", value: "at" },
      { type: "txt", value: "of" },
    ],
    answer: 0,
  },
  {
    id: 12,
    question: [{ type: "txt", value: "My mother and father _____ at work." }],
    options: [
      { type: "txt", value: "Is" },
      { type: "txt", value: "am" },
      { type: "txt", value: "are" },
      { type: "txt", value: "*" },
    ],
    answer: 2,
  },
  {
    id: 13,
    question: [{ type: "txt", value: "It is half past eight." }],
    options: [
      { type: "txt", value: "7: 30" },
      { type: "txt", value: "18:30" },
      { type: "txt", value: "17: 30" },
      { type: "txt", value: "8:30" },
    ],
    answer: 3,
  },
  {
    id: 14,
    question: [
      { type: "txt", value: "Put the correct word to the free space." },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/ingliz-tili/img4.png",
      },
    ],
    options: [
      { type: "txt", value: "Is" },
      { type: "txt", value: "are" },
      { type: "txt", value: "am" },
      { type: "txt", value: "an" },
    ],
    answer: 1,
  },
  {
    id: 15,
    question: [
      { type: "txt", value: "Put the correct word to the free place." },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/ingliz-tili/img5.png",
      },
    ],
    options: [
      { type: "txt", value: "Name" },
      { type: "txt", value: "clothe" },
      { type: "txt", value: "time" },
      { type: "txt", value: "day" },
    ],
    answer: 2,
  },
  {
    id: 16,
    question: [{ type: "txt", value: "What will you do on Sunday?" }],
    options: [
      { type: "txt", value: "We went to the sircus" },
      { type: "txt", value: "There are many plans" },
      { type: "txt", value: "We have no time for doing it" },
      { type: "txt", value: "We`ll swim" },
    ],
    answer: 3,
  },
  {
    id: 17,
    question: [{ type: "txt", value: "Here is your pen. Thank you" }],
    options: [
      { type: "txt", value: "I`m  sorry" },
      { type: "txt", value: "Oh, that`s ok" },
      { type: "txt", value: "I beg your pardon" },
      { type: "txt", value: "You are welcome" },
    ],
    answer: 3,
  },
  {
    id: 18,
    question: [
      { type: "txt", value: "Jane`s a doctor. She _____ ill people." },
    ],
    options: [
      { type: "txt", value: "Looks at" },
      { type: "txt", value: "Speaks to" },
      { type: "txt", value: "Helps" },
      { type: "txt", value: "Server drinks" },
    ],
    answer: 2,
  },
  {
    id: 19,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question (20-24 questions). She goes to the carnival. She enters the pie-eating contest. She sits down on a chair. There are many pies in front of her. She cannot use her hands. She begins to est. She eats the apple pie first. It takes her five minutes to finish it. She eats ten more pies.",
      },
      { type: "txt", value: "Where does she go?" },
    ],
    options: [
      { type: "txt", value: "Pie-eating contest" },
      { type: "txt", value: "Chair" },
      { type: "txt", value: "Carnival" },
    ],
    answer: 2,
  },
  {
    id: 20,
    question: [{ type: "txt", value: "Where does she sit down on?" }],
    options: [
      { type: "txt", value: "Pie-chart" },
      { type: "txt", value: "Carnival" },
      { type: "txt", value: "Chair" },
      { type: "txt", value: "Race" },
    ],
    answer: 2,
  },
  {
    id: 21,
    question: [{ type: "txt", value: "She must not use her______." }],
    options: [
      { type: "txt", value: "Eyes" },
      { type: "txt", value: "Arm" },
      { type: "txt", value: "Mouth" },
      { type: "txt", value: "Hands" },
    ],
    answer: 0,
  },
  {
    id: 22,
    question: [{ type: "txt", value: "What does she eat first?" }],
    options: [
      { type: "txt", value: "Apple pie" },
      { type: "txt", value: "Peach pie" },
      { type: "txt", value: "Cherry pie" },
      { type: "txt", value: "Cake" },
    ],
    answer: 0,
  },
  {
    id: 23,
    question: [{ type: "txt", value: "How many pies does she eat?" }],
    options: [
      { type: "txt", value: "Ten" },
      { type: "txt", value: "Five" },
      { type: "txt", value: "Ten more" },
      { type: "txt", value: "First" },
    ],
    answer: 2,
  },
  {
    id: 24,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question (25-29 questions). He lives in a tall building. He lives on the 100th floor. He wakes up. He looks out the window. He sees the sun rise. He sees other buildings. He sees the mountains. He look down. The peole look so small. The stores look small, too. What an interesting view!",
      },
      { type: "txt", value: "Where does  he live ?" },
    ],
    options: [
      { type: "txt", value: "In a small building" },
      { type: "txt", value: "In a short building" },
      { type: "txt", value: "In a high building" },
      { type: "txt", value: "Nowhere" },
    ],
    answer: 2,
  },
  {
    id: 25,
    question: [{ type: "txt", value: "He can see the ______." }],
    options: [
      { type: "txt", value: "Sun rise" },
      { type: "txt", value: "Sunset" },
      { type: "txt", value: "Air" },
      { type: "txt", value: "Mall" },
    ],
    answer: 0,
  },
  {
    id: 26,
    question: [{ type: "txt", value: "How does he see people from the top?" }],
    options: [
      { type: "txt", value: "By looking out the window" },
      { type: "txt", value: "By looking in the window" },
      { type: "txt", value: "By dreaming" },
      { type: "txt", value: "By making conversation" },
    ],
    answer: 0,
  },
  {
    id: 27,
    question: [{ type: "txt", value: "How do people and stores look?" }],
    options: [
      { type: "txt", value: "Tall" },
      { type: "txt", value: "High" },
      { type: "txt", value: "Little" },
      { type: "txt", value: "Short" },
    ],
    answer: 2,
  },
  {
    id: 28,
    question: [
      {
        type: "txt",
        value:
          "Look at the picture and answer the question; In the dining room, the King pulls up a chair and sits down at the table. The food arrives. It smells so delicious! He grabs a slice of bread and takes a huge bite. Bam! The bread turns into gold. Angry, Midas throws the bread away and grabs some chicken instead. Bam! Gold. ",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/ingliz-tili/img6.png",
      },
      { type: "txt", value: "The King cannot eat his food because________" },
    ],
    options: [
      { type: "txt", value: "It tastes bad" },
      { type: "txt", value: "It changes into gold" },
      { type: "txt", value: "It is cold" },
      { type: "txt", value: "It smells bad" },
    ],
    answer: 1,
  },
  {
    id: 29,
    question: [
      {
        type: "txt",
        value:
          "Match the opposites: 1)Sit down, 2)Push, 3)Arrive, 4)Catch;  a)leave,  b)throw,  c)stand up, d)pull",
      },
    ],
    options: [
      { type: "txt", value: "1c/2d/3a/4b" },
      { type: "txt", value: "1a/2b/3d/4c" },
      { type: "txt", value: "1c/2b/3a/4d" },
      { type: "txt", value: "1d/2b/3c/4a" },
    ],
    answer: 0,
  },
  {
    id: 30,
    question: [
      {
        type: "txt",
        value:
          "Look at the notices. It contains mistake. Find the mistake and correktit.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/ingliz-tili/img7.png",
      },
      {
        type: "txt",
        value: "Safety notice: no fewer than 8 persons in this lift.",
      },
    ],
    options: [
      { type: "txt", value: "No more than (fewer than)" },
      { type: "txt", value: "People (8 persons)" },
      { type: "txt", value: "On the lift ( in this lift)" },
      { type: "txt", value: "Safety door ( safety notice)" },
    ],
    answer: 1,
  },
  {
    id: 31,
    question: [
      {
        type: "txt",
        value:
          "Look at the notices. It contains mistace. Find the mistake and correct it. Rinse well with dirty water.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/ingliz-tili/img8.png",
      },
    ],
    options: [
      { type: "txt", value: "Hot water (dirty water)" },
      { type: "txt", value: "Clean water (dirty water)" },
      { type: "txt", value: "Cold water ( dirty water)" },
      { type: "txt", value: "No mistake" },
    ],
    answer: 1,
  },
  {
    id: 32,
    question: [
      { type: "txt", value: "What is the meaning of the notice?" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/ingliz-tili/img9.png",
      },
    ],
    options: [
      { type: "txt", value: "You can not find any new clothes" },
      { type: "txt", value: "If you are free. You have to come to buy" },
      { type: "txt", value: "Available for purchase at a reduced price" },
    ],
    answer: 2,
  },
  {
    id: 33,
    question: [
      {
        type: "txt",
        value: "Find the correct answer. The table is in the________.",
      },
    ],
    options: [
      { type: "txt", value: "Room little" },
      { type: "txt", value: "Rooms" },
      { type: "txt", value: "Little room" },
      { type: "txt", value: "Little rooms" },
    ],
    answer: 2,
  },
  {
    id: 34,
    question: [{ type: "txt", value: "The man is _______ the room." }],
    options: [
      { type: "txt", value: "On" },
      { type: "txt", value: "And" },
      { type: "txt", value: "Or" },
      { type: "txt", value: "In" },
    ],
    answer: 3,
  },
  {
    id: 35,
    question: [{ type: "txt", value: "The little girls ________ two books." }],
    options: [
      { type: "txt", value: "Are" },
      { type: "txt", value: "Is" },
      { type: "txt", value: "Have" },
      { type: "txt", value: "Has" },
    ],
    answer: 2,
  },
  {
    id: 36,
    question: [{ type: "txt", value: "The smith _____ got a big hause." }],
    options: [
      { type: "txt", value: "Aren`t" },
      { type: "txt", value: "Isn`t" },
      { type: "txt", value: "Haven`t" },
      { type: "txt", value: "Hasn`t" },
    ],
    answer: 3,
  },
  {
    id: 37,
    question: [{ type: "txt", value: "Yes, ________ a lamp in the room." }],
    options: [
      { type: "txt", value: "There is" },
      { type: "txt", value: "There are" },
      { type: "txt", value: "Is there" },
      { type: "txt", value: "Are there" },
    ],
    answer: 0,
  },
  {
    id: 38,
    question: [{ type: "txt", value: "Susan,________ chairs in the garden?" }],
    options: [
      { type: "txt", value: "There is" },
      { type: "txt", value: "Is there" },
      { type: "txt", value: "There are" },
      { type: "txt", value: "Are there" },
    ],
    answer: 3,
  },
  {
    id: 39,
    question: [
      {
        type: "txt",
        value: "Look at the picture and answer the question. (40-44 questions)",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/ingliz-tili/img10.png",
      },
    ],
    options: [
      { type: "txt", value: "Seven" },
      { type: "txt", value: "Six" },
      { type: "txt", value: "Five" },
      { type: "txt", value: "Eight" },
    ],
    answer: 0,
  },
  {
    id: 40,
    question: [{ type: "txt", value: "What shapes are the tables?" }],
    options: [
      { type: "txt", value: "They are square" },
      { type: "txt", value: "They are flat" },
      { type: "txt", value: "They are round" },
      { type: "txt", value: "They are triangle" },
    ],
    answer: 2,
  },
  {
    id: 41,
    question: [{ type: "txt", value: "Which animal on the board?" }],
    options: [
      { type: "txt", value: "A dog" },
      { type: "txt", value: "No information" },
      { type: "txt", value: "A cat" },
      { type: "txt", value: "A cow" },
    ],
    answer: 2,
  },
  {
    id: 42,
    question: [{ type: "txt", value: "Why are the children coloring in?" }],
    options: [
      { type: "txt", value: "Because they are children" },
      { type: "txt", value: "Because they are want" },
      { type: "txt", value: "Because it is an art lesson" },
      { type: "txt", value: "Because there is no other work" },
    ],
    answer: 2,
  },
  {
    id: 43,
    question: [
      { type: "txt", value: "This is the photo__________ my family." },
    ],
    options: [
      { type: "txt", value: "In" },
      { type: "txt", value: "At" },
      { type: "txt", value: "Of" },
      { type: "txt", value: "On" },
    ],
    answer: 2,
  },
  {
    id: 44,
    question: [
      { type: "txt", value: "“How old is your brother?”;    “_________29” " },
    ],
    options: [
      { type: "txt", value: "They are" },
      { type: "txt", value: "I` m" },
      { type: "txt", value: "It is" },
      { type: "txt", value: "He is" },
    ],
    answer: 3,
  },
  {
    id: 45,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question. (46-50 questions) She has two pairs of high heels. One of them laces. The other one has a zipper. She has two pairs of running shoes. One of them is shiny. The other one is bright. She has two pairs of ballet flats. One of them is pink. The other one is brown. She wears her shoes to parties.",
      },
      { type: "txt", value: "What does she have?" },
    ],
    options: [
      { type: "txt", value: "Short heels" },
      { type: "txt", value: "Small heels" },
      { type: "txt", value: "Middle heels" },
      { type: "txt", value: "High heels" },
    ],
    answer: 3,
  },
  {
    id: 46,
    question: [
      { type: "txt", value: "The high heels have laces and__________." },
    ],
    options: [
      { type: "txt", value: "Bright" },
      { type: "txt", value: "Zipper" },
      { type: "txt", value: "Ballet" },
      { type: "txt", value: "Flat" },
    ],
    answer: 1,
  },
  {
    id: 47,
    question: [{ type: "txt", value: "What is shiny?" }],
    options: [
      { type: "txt", value: "Running shoe" },
      { type: "txt", value: "Ballet flat" },
      { type: "txt", value: "High heel" },
      { type: "txt", value: "Small heels" },
    ],
    answer: 0,
  },
  {
    id: 48,
    question: [{ type: "txt", value: "_______ are pink and brown." }],
    options: [
      { type: "txt", value: "Running shoe" },
      { type: "txt", value: "Ballet flat" },
      { type: "txt", value: "High heel" },
      { type: "txt", value: "Small heels" },
    ],
    answer: 1,
  },
  {
    id: 49,
    question: [{ type: "txt", value: "Pink is _________." }],
    options: [
      { type: "txt", value: "Ballet flat" },
      { type: "txt", value: "Shoe" },
      { type: "txt", value: "Color" },
      { type: "txt", value: "Heel" },
    ],
    answer: 0,
  },
];

export const alXorazmiyMath = [
  {
    id: 0,
    question: [
      {
        type: "txt",
        value: "Ifodaning qiymatini toping: 4003 - 3468 : 34 + 104",
      },
    ],
    options: [
      { type: "txt", value: "5004" },
      { type: "txt", value: "4003" },
      { type: "txt", value: "4005" },
      { type: "txt", value: "4405" },
    ],
    answer: 2,
  },
  {
    id: 1,
    question: [
      { type: "txt", value: "Ifodani soddalashtiring: 56 - (28 + x)" },
    ],
    options: [
      { type: "txt", value: "28 + x" },
      { type: "txt", value: "28 - x" },
      { type: "txt", value: "84 - x" },
      { type: "txt", value: "84 + x" },
    ],
    answer: 1,
  },
  {
    id: 2,
    question: [
      {
        type: "txt",
        value:
          "Ikki son yig`indisi shu sonlarning ikkinchisidan 27 taga katta. Birinchi son nechaga teng?",
      },
    ],
    options: [
      { type: "txt", value: "54" },
      { type: "txt", value: "28" },
      { type: "txt", value: "23" },
      { type: "txt", value: "27" },
    ],
    answer: 3,
  },
  {
    id: 3,
    question: [{ type: "txt", value: "Tenglamani yeching: 29x + 67x = 30720" }],
    options: [
      { type: "txt", value: "320" },
      { type: "txt", value: "310" },
      { type: "txt", value: "330" },
      { type: "txt", value: "300" },
    ],
    answer: 0,
  },
  {
    id: 4,
    question: [
      {
        type: "txt",
        value:
          "Do`konga har birida 25 tadan daftar bo`lgan bir nechta bog`lam  va yana 15 ta daftar keltirildi. Hammasi bo`lib 340ta daftar keltirilgan. Magazinga hammasi bo`lib necha bog`lam daftar keltirilgan?",
      },
    ],
    options: [
      { type: "txt", value: "12" },
      { type: "txt", value: "13" },
      { type: "txt", value: "10" },
      { type: "txt", value: "23" },
    ],
    answer: 1,
  },
  {
    id: 5,
    question: [
      {
        type: "txt",
        value:
          "To`liqsiz  bo`linma 21ga , bo`luvchi 89 ga , qoldiq 13 ga teng bo`lsa, bo`linuvchini toping.",
      },
    ],
    options: [
      { type: "txt", value: "1882" },
      { type: "txt", value: "1800" },
      { type: "txt", value: "1982" },
      { type: "txt", value: "1782" },
    ],
    answer: 0,
  },
  {
    id: 6,
    question: [
      { type: "txt", value: "Hisoblang:" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img1.png",
      },
    ],
    options: [
      { type: "txt", value: "2000" },
      { type: "txt", value: "2744" },
      { type: "txt", value: "2644" },
      { type: "txt", value: "3000" },
    ],
    answer: 0,
  },
  {
    id: 7,
    question: [
      { type: "txt", value: "Quyidagi: c=(m-12):8 formuladan m ni toping." },
    ],
    options: [
      { type: "txt", value: "8c-12" },
      { type: "txt", value: "12-8c" },
      { type: "txt", value: "8c+12" },
      { type: "txt", value: "12c-8" },
    ],
    answer: 2,
  },
  {
    id: 8,
    question: [
      {
        type: "txt",
        value:
          "To`g`ri to`rtburchakning eni 370 m, bo`yi undan 30 m ortiq. To`g`ri to`rtburchakning yuzini toping va uni gektar va arlarda ifodalang.",
      },
    ],
    options: [
      { type: "txt", value: "140 ga 8 ar" },
      { type: "txt", value: "14 ga 8 ar" },
      { type: "txt", value: "18 ga 12 ar" },
      { type: "txt", value: "14 ga 80 ar" },
    ],
    answer: 3,
  },
  {
    id: 9,
    question: [
      {
        type: "txt",
        value:
          "Bo`yi va eni 6 smdan , balandligi esa 3 sm bo`lgan to`gri burchakli parallelepiped sirtining yuzini va qirralari yig`indisini toping.",
      },
    ],
    options: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img2.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img3.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img4.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img5.png",
      },
    ],
    answer: 0,
  },
  {
    id: 10,
    question: [
      { type: "txt", value: "To`g`ri burchakli  parallelepipedning hajmi" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img6.png",
      },
      {
        type: "txt",
        value:
          ", eni 5sm, balandligi esa 3 sm. Parallelepipdning bo`yini toping.",
      },
    ],
    options: [
      { type: "txt", value: "16 sm" },
      { type: "txt", value: "7 sm" },
      { type: "txt", value: "17 sm" },
      { type: "txt", value: "20 sm" },
    ],
    answer: 1,
  },
  {
    id: 11,
    question: [
      { type: "txt", value: "Amallarni bajaring:" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img7.png",
      },
    ],
    options: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img8.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img9.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img10.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img11.png",
      },
    ],
    answer: 1,
  },
  {
    id: 12,
    question: [
      { type: "txt", value: "n ning shunday uchta qiymatini topingki," },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img12.png",
      },
      { type: "txt", value: "notog`ri kasr" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img13.png",
      },
      { type: "txt", value: "kasrdan kichik bo`lsin." },
    ],
    options: [
      { type: "txt", value: "n=23;24;25" },
      { type: "txt", value: "n=24;25;26" },
      { type: "txt", value: "n=23;24;26" },
      { type: "txt", value: "n=20;21;22" },
    ],
    answer: 0,
  },
  {
    id: 13,
    question: [
      {
        type: "txt",
        value:
          "x ning  qanday natural qiymatlarida quyidagi qo`sh tengsizlik o`rinli bo`ladi: 98,3<x<101?",
      },
    ],
    options: [
      { type: "txt", value: "x=100;101" },
      { type: "txt", value: "x=99;100;101" },
      { type: "txt", value: "x=98;99;100" },
      { type: "txt", value: "x=99;100" },
    ],
    answer: 3,
  },
  {
    id: 14,
    question: [
      {
        type: "txt",
        value:
          "2 ta birlik, 3 ta o`nlik, 7 ta yuzdan birlar, 5 ta o`ndan birlar, 6 ta mingdan birlardan iborat sonni yozing.",
      },
    ],
    options: [
      { type: "txt", value: "32,756" },
      { type: "txt", value: "32,576" },
      { type: "txt", value: "23,756" },
      { type: "txt", value: "23,576" },
    ],
    answer: 1,
  },
  {
    id: 15,
    question: [{ type: "txt", value: "Tenglamani yeching: 13,2-5,7+x=3,9" }],
    options: [
      { type: "txt", value: "3,8" },
      { type: "txt", value: "3,7" },
      { type: "txt", value: "3,6" },
      { type: "txt", value: "4,6" },
    ],
    answer: 2,
  },
  {
    id: 16,
    question: [
      {
        type: "txt",
        value: "6732,5941 sonini mingdan birlar xonasigacha yaxlitlang.",
      },
    ],
    options: [
      { type: "txt", value: "6732,594" },
      { type: "txt", value: "6732,6" },
      { type: "txt", value: "6733" },
      { type: "txt", value: "6732,604" },
    ],
    answer: 0,
  },
  {
    id: 17,
    question: [
      { type: "txt", value: "Ifodani soddalashtiring: 3,4n+4,7n-n-2,1n." },
    ],
    options: [
      { type: "txt", value: "6 n" },
      { type: "txt", value: "5 n" },
      { type: "txt", value: "4,2 n" },
      { type: "txt", value: "5,2 n" },
    ],
    answer: 1,
  },
  {
    id: 18,
    question: [
      {
        type: "txt",
        value: "x=10000 bo`lsa,   15,653x  ifodaning qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "15653" },
      { type: "txt", value: "1565,3" },
      { type: "txt", value: "156530" },
      { type: "txt", value: "15650" },
    ],
    answer: 2,
  },
  {
    id: 19,
    question: [
      { type: "txt", value: "Ifodaning qiymatini toping: 28∙0,24+28∙0,26" },
    ],
    options: [
      { type: "txt", value: "14" },
      { type: "txt", value: "140" },
      { type: "txt", value: "28" },
      { type: "txt", value: "2,8" },
    ],
    answer: 0,
  },
  {
    id: 20,
    question: [
      {
        type: "txt",
        value:
          "Ikki velosipedchi bir-biriga qarab harakatlanishmoqda. Ulardan birining tezligi 13 km/soat , ikkinchisining tezligi 14 km/soat. Hozir ular orasidagi masofa 10 km bo`lsa, 0,3 soatdan keyin ular orasidagi masofa qancha bo`ladi?",
      },
    ],
    options: [
      { type: "txt", value: "2,1km" },
      { type: "txt", value: "8,1km" },
      { type: "txt", value: "1,9km" },
      { type: "txt", value: "1,8km" },
    ],
    answer: 2,
  },
  {
    id: 21,
    question: [
      { type: "txt", value: "Tomorqa butun maydonning" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img14.png",
      },
      {
        type: "txt",
        value:
          "qismini tashkil qiladi. Agar tomorqa 7,2 ga bo`lsa, butun maydon necha gektar?",
      },
    ],
    options: [
      { type: "txt", value: "3,6ga" },
      { type: "txt", value: "3,2ga" },
      { type: "txt", value: "17,2ga" },
      { type: "txt", value: "16,2ga" },
    ],
    answer: 3,
  },
  {
    id: 22,
    question: [
      {
        type: "txt",
        value:
          "To`gri to`rtburchakning bo`yi 27,2 sm, eni undan 8 marta kichik bo`lsa, to`g`ri to`rtburchakning perimetrini toping (sm).",
      },
    ],
    options: [
      { type: "txt", value: "61,2" },
      { type: "txt", value: "30,6" },
      { type: "txt", value: "54,4" },
      { type: "txt", value: "16" },
    ],
    answer: 0,
  },
  {
    id: 23,
    question: [{ type: "txt", value: "Tenglamani yeching: 18,63:4,3+m=2,3" }],
    options: [
      { type: "txt", value: "6,4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "12,4" },
      { type: "txt", value: "3,8" },
    ],
    answer: 3,
  },
  {
    id: 24,
    question: [
      {
        type: "txt",
        value:
          "ABC uchburchakning perimetri 18 sm. AB tomon AC tomondan 1,5 marta  katta, BC esa AC dan 0,5 sm ga katta . ABC uchburchakning uchala tomonining uzunligini toping.",
      },
    ],
    options: [
      { type: "txt", value: "AB=6; AC=7,5; BC=5,5" },
      { type: "txt", value: "AB=7; AC=6; BC=4" },
      { type: "txt", value: "AB=7,5; AC=5; BC=5,5" },
      { type: "txt", value: "AB=5,5; AC=4; BC=7,5" },
    ],
    answer: 2,
  },
  {
    id: 25,
    question: [
      { type: "txt", value: "Ifodaning qiymatini toping: 3960-3924:36+49" },
    ],
    options: [
      { type: "txt", value: "3900" },
      { type: "txt", value: "3851" },
      { type: "txt", value: "3800" },
      { type: "txt", value: "3990" },
    ],
    answer: 0,
  },
  {
    id: 26,
    question: [{ type: "txt", value: "Ifodani soddalashtiring: 88-69+x" }],
    options: [
      { type: "txt", value: "x-9" },
      { type: "txt", value: "19-x" },
      { type: "txt", value: "19+x" },
      { type: "txt", value: "8-x" },
    ],
    answer: 1,
  },
  {
    id: 27,
    question: [
      {
        type: "txt",
        value:
          "Ikki son yig`indisi shu sonlarning birinchisidan 328 taga katta. Ikkinchi son nechaga teng?",
      },
    ],
    options: [
      { type: "txt", value: "164" },
      { type: "txt", value: "320" },
      { type: "txt", value: "656" },
      { type: "txt", value: "328" },
    ],
    answer: 3,
  },
  {
    id: 28,
    question: [{ type: "txt", value: "Tenglamani yeching: 34x+17x=1173" }],
    options: [
      { type: "txt", value: "23" },
      { type: "txt", value: "203" },
      { type: "txt", value: "32" },
      { type: "txt", value: "25" },
    ],
    answer: 0,
  },
  {
    id: 29,
    question: [
      {
        type: "txt",
        value:
          "Bir nechta quti bor edi. Har biriga 24 tadan qalam solindi. Shunda 15 ta qalam qoldi. Qalamlar soni 159 ta bo`lsa, jami  qutilar soni nechta edi?",
      },
    ],
    options: [
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
      { type: "txt", value: "7" },
      { type: "txt", value: "8" },
    ],
    answer: 1,
  },
  {
    id: 30,
    question: [
      {
        type: "txt",
        value:
          "To`liqsiz  bo`linma 69, bo`luvchi 123, qoldiq 29 bo`lsa, bo`linuvchini toping.",
      },
    ],
    options: [
      { type: "txt", value: "8586" },
      { type: "txt", value: "8567" },
      { type: "txt", value: "8516" },
      { type: "txt", value: "8576" },
    ],
    answer: 2,
  },
  {
    id: 31,
    question: [
      { type: "txt", value: "Hisoblang:" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img15.png",
      },
    ],
    options: [
      { type: "txt", value: "1260" },
      { type: "txt", value: "1360" },
      { type: "txt", value: "1460" },
      { type: "txt", value: "1540" },
    ],
    answer: 1,
  },
  {
    id: 32,
    question: [
      { type: "txt", value: "Quyidagi: a=x+18:9  formuladan x ni toping." },
    ],
    options: [
      { type: "txt", value: "x=a:9-18" },
      { type: "txt", value: "x=18-9a" },
      { type: "txt", value: "x=9a+18" },
      { type: "txt", value: "x=9a-18" },
    ],
    answer: 3,
  },
  {
    id: 33,
    question: [
      {
        type: "txt",
        value:
          "To`g`ri  to`rtburchakning bo`yi 340 sm, u enidan 80 sm uzun. To`g`ri to`rtburchakning yuzini toping va uni detsimetr kvadratlarda ifodalang.",
      },
    ],
    options: [
      { type: "txt", value: "884 dm²" },
      { type: "txt", value: "88,4 dm²" },
      { type: "txt", value: "88400 dm²" },
      { type: "txt", value: "8840 dm²" },
    ],
    answer: 0,
  },
  {
    id: 34,
    question: [
      {
        type: "txt",
        value:
          "To`g`ri burchakli parallelepipedning bo`yi 12 sm, eni bo`yidan 7 sm kichik, balandligi esa enidan 2 sm uzun. To`g`ri burchakli parallelepiped  sirtining yuzini va qirralar yig`indisini toping.",
      },
    ],
    options: [
      { type: "txt", value: "S=358 sm², l=48 sm" },
      { type: "txt", value: "S=96 sm², l=358 sm " },
      { type: "txt", value: "S=358 sm², l=96 sm" },
      { type: "txt", value: "S=96 sm², l=358 sm" },
    ],
    answer: 2,
  },
  {
    id: 35,
    question: [
      {
        type: "txt",
        value:
          "To`g`ri burchakli parallelepipedning hajmi 336 sm³ , uning bo`yi 8sm, balandligi 7sm. Parallelepipedning enini toping.",
      },
    ],
    options: [
      { type: "txt", value: "6 sm" },
      { type: "txt", value: "7 sm" },
      { type: "txt", value: "5 sm" },
      { type: "txt", value: "8 sm" },
    ],
    answer: 0,
  },
  {
    id: 36,
    question: [
      { type: "txt", value: "Amallarni bajaring:" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img16.png",
      },
    ],
    options: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img17.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img18.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img19.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img20.png",
      },
    ],
    answer: 2,
  },
  {
    id: 37,
    question: [
      { type: "txt", value: "n ning shunday uchta qiymatini topingki," },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img21.png",
      },
      { type: "txt", value: "notog`ri kasr" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img22.png",
      },
      { type: "txt", value: "kasrdan kichik bo`lsin." },
    ],
    options: [
      { type: "txt", value: "n=23;24;25" },
      { type: "txt", value: "n=25;26;27" },
      { type: "txt", value: "n=26;27;28" },
      { type: "txt", value: "n=24;25;26" },
    ],
    answer: 1,
  },
  {
    id: 38,
    question: [
      {
        type: "txt",
        value:
          "x ning qanday natural qiymatlarida quyidagi qo`sh tengsizlik to`g`ri bo`ladi: 98,83<x<102?",
      },
    ],
    options: [
      { type: "txt", value: "x=99;100;102" },
      { type: "txt", value: "x=98;99;100" },
      { type: "txt", value: "x=100;101" },
      { type: "txt", value: "x=99;100;101" },
    ],
    answer: 3,
  },
  {
    id: 39,
    question: [
      {
        type: "txt",
        value:
          "3 ta birlik, 4 ta o`nlik, 4 ta yuzdan birlar, 5 ta o`n mingdan birlardan iborat sonni toping.",
      },
    ],
    options: [
      { type: "txt", value: "34,45" },
      { type: "txt", value: "34,4005" },
      { type: "txt", value: "43,0405" },
      { type: "txt", value: "43,4005" },
    ],
    answer: 2,
  },
  {
    id: 40,
    question: [{ type: "txt", value: "Tenglamani yeching: x-5,4+2,3=5,2" }],
    options: [
      { type: "txt", value: "12,9" },
      { type: "txt", value: "8,3" },
      { type: "txt", value: "2,5" },
      { type: "txt", value: "8,6" },
    ],
    answer: 1,
  },
  {
    id: 41,
    question: [
      {
        type: "txt",
        value: "12378,0652 sonini minglar xonasigacha yaxlitlang.",
      },
    ],
    options: [
      { type: "txt", value: "12000" },
      { type: "txt", value: "13000" },
      { type: "txt", value: "12378,065" },
      { type: "txt", value: "12400" },
    ],
    answer: 0,
  },
  {
    id: 42,
    question: [
      { type: "txt", value: "Ifodani soddalashtiring: 5,6n-3,4n+2,6n-n+0,2n." },
    ],
    options: [
      { type: "txt", value: "3n" },
      { type: "txt", value: "5n" },
      { type: "txt", value: "4n" },
      { type: "txt", value: "2n" },
    ],
    answer: 2,
  },
  {
    id: 43,
    question: [
      {
        type: "txt",
        value: "Agar  x=100000   bo`lsa, 0,2437x  ifodaning qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "24370" },
      { type: "txt", value: "2437" },
      { type: "txt", value: "243,7" },
      { type: "txt", value: "24300" },
    ],
    answer: 0,
  },
  {
    id: 44,
    question: [
      { type: "txt", value: "Ifodaning qiymatini toping: 36∙0,17+36∙0,33" },
    ],
    options: [
      { type: "txt", value: "36" },
      { type: "txt", value: "12" },
      { type: "txt", value: "18" },
      { type: "txt", value: "20" },
    ],
    answer: 2,
  },
  {
    id: 45,
    question: [
      {
        type: "txt",
        value:
          "Ikki piyoda bir biriga qarab harakatlanishmoqda. Ulardan birining tezligi 3,2 km/soat, ikkinchisining tezligi 3,6 km/soat. Hozir ular orasidagi masofa 14 km bo`lsa, 2 soatdan keyin ular orasidagi masofa qancha bo`ladi(km)?",
      },
    ],
    options: [
      { type: "txt", value: "4" },
      { type: "txt", value: "0,4" },
      { type: "txt", value: "6,8" },
      { type: "txt", value: "13,6" },
    ],
    answer: 1,
  },
  {
    id: 46,
    question: [
      {
        type: "txt",
        value:
          "Bitta tarvuzning massasi 1,8 kg, u barcha sotib olingan tarvuzlarning",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img23.png",
      },
      {
        type: "txt",
        value:
          "qismini tashkil qiladi.  Necha kilogramm tarvuz sotib olingan edi?",
      },
    ],
    options: [
      { type: "txt", value: "8,1" },
      { type: "txt", value: "0,4" },
      { type: "txt", value: "10" },
      { type: "txt", value: "0,6" },
    ],
    answer: 0,
  },
  {
    id: 47,
    question: [
      {
        type: "txt",
        value:
          "To`g`ri to`rtburchakning bo`yi 34,3 m, u enidan 7 marta katta bo`lsa, to`g`ri to`rtburchakning perimetrini toping (m).",
      },
    ],
    options: [
      { type: "txt", value: "76,8" },
      { type: "txt", value: "78,4" },
      { type: "txt", value: "240,1" },
      { type: "txt", value: "82,6" },
    ],
    answer: 1,
  },
  {
    id: 48,
    question: [{ type: "txt", value: "Tenglamani yeching: 9,88:6,7-x=2,6" }],
    options: [
      { type: "txt", value: "7" },
      { type: "txt", value: "10,5" },
      { type: "txt", value: "2,9" },
      { type: "txt", value: "4,1" },
    ],
    answer: 2,
  },
  {
    id: 49,
    question: [
      {
        type: "txt",
        value:
          "ABC uchburchakning perimetri 7,6 sm. BC tomon AC tomondan 1,2 marta katta, AB esa BC dan 0,8 sm ga katta. ABC uchburchakning uchala tomoning uzunligini toping.",
      },
    ],
    options: [
      { type: "txt", value: "AB=3,2; AC=2; BC=2,4" },
      { type: "txt", value: "AB=2,5; AC=2,5; BC=2,6" },
      { type: "txt", value: "AB=3; AC=2,2; BC=2,4" },
      { type: "txt", value: "AB=2,6; AC=3; BC=2" },
    ],
    answer: 0,
  },
  {
    id: 50,
    question: [{ type: "txt", value: "Hisoblang: 123∙45- 123∙35 +2 ∙ 17²:34" }],
    options: [
      { type: "txt", value: "1247" },
      { type: "txt", value: "1347" },
      { type: "txt", value: "1230" },
      { type: "txt", value: "1330" },
    ],
    answer: 0,
  },
  {
    id: 51,
    question: [
      {
        type: "txt",
        value:
          "777776+77776+7776+776+76 yig‘indining qiymatini 7 ga boʻlinganidagi qoldiqni toping.",
      },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "2" },
      { type: "txt", value: "1" },
      { type: "txt", value: "5" },
    ],
    answer: 1,
  },
  {
    id: 52,
    question: [
      {
        type: "txt",
        value:
          "Natural sonlar qatorida 82 dan katta boʻlmagan barcha juft sonlar yig‘indisini toping.",
      },
    ],
    options: [
      { type: "txt", value: "1722" },
      { type: "txt", value: "1800" },
      { type: "txt", value: "3403" },
      { type: "txt", value: "3500" },
    ],
    answer: 0,
  },
  {
    id: 53,
    question: [
      {
        type: "txt",
        value:
          "20 minut oldinga ildamlab ketgan soat hozir 9∶15 ni koʻrsatyapti. Ayni shu paytda 25 minut orqada qolgan soat nechani koʻrsatayotgan boʻladi?",
      },
    ],
    options: [
      { type: "txt", value: "8∶55" },
      { type: "txt", value: "8∶35" },
      { type: "txt", value: "9∶05" },
      { type: "txt", value: "8∶30" },
    ],
    answer: 3,
  },
  {
    id: 54,
    question: [
      {
        type: "txt",
        value:
          "Bir-biriga qarab harakatlanayotgan avtomobillar orasidagi masofa 450 km . Ulardan birinchising tezligi ikkinchisidan 2 marta ko‘proq. Agar avtomobillar 2 soatdan keyin uchrashgan bo‘lsalar, birinchisining tezligini toping.",
      },
    ],
    options: [
      { type: "txt", value: "70" },
      { type: "txt", value: "75" },
      { type: "txt", value: "150" },
      { type: "txt", value: "140" },
    ],
    answer: 2,
  },
  {
    id: 55,
    question: [
      {
        type: "txt",
        value:
          "Evkalipt daraxtining bo‘yi birinchi 5 yilda 12 m balandlikka yetadi. 35 yilda 48 metrga yetadi. Birinchi 25 yilda evkalipt daraxti necha cm ga o‘sadi?",
      },
    ],
    options: [
      { type: "txt", value: "3400 cm" },
      { type: "txt", value: "2500 cm" },
      { type: "txt", value: "3600 cm" },
      { type: "txt", value: "3000cm" },
    ],
    answer: 2,
  },
  {
    id: 56,
    question: [
      {
        type: "txt",
        value:
          "2022 - yil 20 - mart soat 12∶00 da Halimning tugʻilganiga 100 sutka boʻldi. Halim qaysi kuni tug‘ilgan?",
      },
    ],
    options: [
      { type: "txt", value: "10 - dekabr" },
      { type: "txt", value: "20 - dekabr" },
      { type: "txt", value: "22 - dekabr" },
      { type: "txt", value: "12 - dekabr" },
    ],
    answer: 0,
  },
  {
    id: 57,
    question: [
      {
        type: "txt",
        value:
          "Olma narxi banan narxidan 4 marta arzon. 3 kg olma va 2 kg banan uchun 44000 soʻm toʻlangan boʻlsa, bananning narxini aniqlang.",
      },
    ],
    options: [
      { type: "txt", value: "12000" },
      { type: "txt", value: "4000" },
      { type: "txt", value: "16000" },
      { type: "txt", value: "20000" },
    ],
    answer: 2,
  },
  {
    id: 58,
    question: [
      {
        type: "txt",
        value:
          "Qirralari uzunliklari 16 cm, 20 cm va 24 cm ga teng boʻlgan toʻgʻri burchakli parallelepiped shaklidagi taxtani qirrasi 4 cm boʻlgan nechta kubchaga ajratish mumkin?",
      },
    ],
    options: [
      { type: "txt", value: "60" },
      { type: "txt", value: "40" },
      { type: "txt", value: "120" },
      { type: "txt", value: "90" },
    ],
    answer: 2,
  },
  {
    id: 59,
    question: [
      {
        type: "txt",
        value:
          "Zavodda birinchi kun 568 ta , ikkinchi kun birinchi kundagidan 87 ta kam , uchinchi kun esa ikkinchi kundagidan 65 ta ortiq detal tayyorlandi. Shu uch kunda zavodda jami nechta detal tayyorlangan?",
      },
    ],
    options: [
      { type: "txt", value: "1585" },
      { type: "txt", value: "1595" },
      { type: "txt", value: "1605" },
      { type: "txt", value: "1575" },
    ],
    answer: 1,
  },
  {
    id: 60,
    question: [
      { type: "txt", value: "Rasmdagi berilgan shakl perimetrini toping:" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img24.png",
      },
    ],
    options: [
      { type: "txt", value: "𝑃 = 24 𝑐𝑚" },
      { type: "txt", value: "𝑃 = 28 𝑐𝑚" },
      { type: "txt", value: "𝑃 = 34 𝑐𝑚" },
      { type: "txt", value: "𝑃 = 38 𝑐𝑚" },
    ],
    answer: 3,
  },
  {
    id: 61,
    question: [
      {
        type: "txt",
        value: "Tenglamani yeching:	2,25𝑥 + 7,23 + 7,75𝑥 = 430,23 − 223",
      },
    ],
    options: [
      { type: "txt", value: "13" },
      { type: "txt", value: "19" },
      { type: "txt", value: "21" },
      { type: "txt", value: "20" },
    ],
    answer: 3,
  },
  {
    id: 62,
    question: [
      {
        type: "txt",
        value:
          "Perimetri 20 m kvadrat shaklidagi xonaga boʻyi 4 m, eni esa 3 m boʻlgan toʻg‘ri toʻrtburchak shaklidagi gilam toʻshaldi. Bu xonaning necha kvadrat metr yuzasi gilam bilan qoplanmaydi?",
      },
    ],
    options: [
      { type: "txt", value: "13 m²" },
      { type: "txt", value: "8 m²" },
      { type: "txt", value: "6 m²" },
      { type: "txt", value: "12 m²" },
    ],
    answer: 0,
  },
  {
    id: 63,
    question: [
      {
        type: "txt",
        value:
          "Agar it 27 sekundda bolani quvib yetgan bo‘lsa, uning tezligi qancha bo‘lgan?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img25.png",
      },
    ],
    options: [
      { type: "txt", value: "200 m/min" },
      { type: "txt", value: "300 m/min" },
      { type: "txt", value: "350 m/min" },
      { type: "txt", value: "100 m/min" },
    ],
    answer: 1,
  },
  {
    id: 64,
    question: [
      {
        type: "txt",
        value:
          "Berilgan rasmning nechta joyida ikkita o‘quvchi chap qo‘llari bilan bir-birini ushlab turibdi? ",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img26.png",
      },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 0,
  },
  {
    id: 65,
    question: [
      {
        type: "txt",
        value:
          "O‘lchov birliklari va ularga mos qiymatlar o‘rtasidagi moslikni o‘rnating:",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img27.png",
      },
    ],
    options: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img28.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img29.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img30.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img31.png",
      },
    ],
    answer: 0,
  },
  {
    id: 66,
    question: [
      {
        type: "txt",
        value:
          "Noma’lum sonni 11 ga bo‘lganda to‘liqsiz bo‘linma ham qoldiq ham 5 chiqdi. Noma’lum sonni toping.",
      },
    ],
    options: [
      { type: "txt", value: "65" },
      { type: "txt", value: "55" },
      { type: "txt", value: "60" },
      { type: "txt", value: "50" },
    ],
    answer: 2,
  },
  {
    id: 67,
    question: [
      {
        type: "txt",
        value:
          "Rasmda ob-havo grafigi keltirilgan. Unda ob-havo va kelgusi besh kun uchun maksimal harorat ko‘rsatilgan. Quyidagilardan qaysi biri maksimal haroratning tegishli grafigini aks ettirgan?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img36.png",
      },
    ],
    options: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img32.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img33.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img34.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img35.png",
      },
    ],
    answer: 0,
  },
  {
    id: 68,
    question: [
      {
        type: "txt",
        value:
          "Oltita to‘g‘ri to‘rtburchaklar rasmda ko‘rsatilganidek joylashtirilgan. Chapdan yuqoridagi to‘g‘ri to‘rtburchakning balandligi 6 cm ga teng. To‘g‘ri to‘rtburchaklar ichidagi sonlar ularning cm² dagi yuzlarini bildiradi. O‘ngdan pastdagi to‘g‘ri to‘rtburchakning perimetrini toping .",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img37.png",
      },
    ],
    options: [
      { type: "txt", value: "34 cm" },
      { type: "txt", value: "24 cm" },
      { type: "txt", value: "22 cm" },
      { type: "txt", value: "32 cm" },
    ],
    answer: 2,
  },
  {
    id: 69,
    question: [
      {
        type: "txt",
        value:
          "Quyidagi tasdiqlar ichidan to‘g‘ri yoki noto‘g‘riligi ketma-ketligi to‘g‘ri ko‘rsatilgan javob variantini aniqlang.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img38.png",
      },
    ],
    options: [
      { type: "txt", value: "I- yo‘q, II- ha, III-yo‘q, IV-ha" },
      { type: "txt", value: "I-ha, II-ha, III-yo‘q, IV-ha" },
      { type: "txt", value: "I-ha, II-yo‘q, III-ha, IV-ha" },
      { type: "txt", value: "I-yo‘q, II-yo‘q, III-ha, IV-yo‘q" },
    ],
    answer: 0,
  },
  {
    id: 70,
    question: [
      {
        type: "txt",
        value:
          "Natural ko‘paytuvchilardan birini 10 % ga, ikkinchisini 20 % ga oshirib, uchinchisini 25 % ga kamaytirsak, ko‘paytma necha foizga o‘zgaradi?",
      },
    ],
    options: [
      { type: "txt", value: "1% ga oshadi" },
      { type: "txt", value: "5% ga oshadi" },
      { type: "txt", value: "1% ga kamayadi" },
      { type: "txt", value: "5% ga kamayadi" },
    ],
    answer: 2,
  },
  {
    id: 71,
    question: [
      {
        type: "txt",
        value:
          "Uchta sonning o‘rta arifmetigi 10 ga, boshqa ikki sonning o‘rta arifmetigi esa 15 ga teng. Shu beshta sonning o‘rta arifmetigini toping.",
      },
    ],
    options: [
      { type: "txt", value: "13" },
      { type: "txt", value: "15" },
      { type: "txt", value: "12,5" },
      { type: "txt", value: "12" },
    ],
    answer: 3,
  },
  {
    id: 72,
    question: [
      {
        type: "txt",
        value:
          "Harorati 36° bo‘lgan 6 l suvga harorati 15° bo‘lgan suv qo‘shilganida aralashtirilgan suvning harorati 24° bo‘ldi. Necha litr suv qo‘shilgan?",
      },
    ],
    options: [
      { type: "txt", value: "10" },
      { type: "txt", value: "8" },
      { type: "txt", value: "12" },
      { type: "txt", value: "9" },
    ],
    answer: 1,
  },
  {
    id: 73,
    question: [
      {
        type: "txt",
        value:
          "Oralaridagi masofa 280 km bo‘lgan A va B shaharlardan bir vaqtda ikki avtomobil yo‘lga chiqdi. Agar avtomobillar bir – biriga qarab yursa, ular 2 soatdan keyin uchrashadi. Agar ular bir yo‘nalishda yursa, A shahardan chiqqan avtomobil B shahardan chiqqan avtomobilni 14 soatda quvib yetadi. B shahardan chiqqan avtomobil tezligi necha km/h ga teng?",
      },
    ],
    options: [
      { type: "txt", value: "140 km/h" },
      { type: "txt", value: "60 km/h" },
      { type: "txt", value: "80 km/h" },
      { type: "txt", value: "20 km/h" },
    ],
    answer: 2,
  },
  {
    id: 74,
    question: [
      {
        type: "txt",
        value:
          "A, B, C, D, E, F variantlardan quyidagi I, II, III savollarning har biriga mos javobni qo‘ying:",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img39.png",
      },
    ],
    options: [
      { type: "txt", value: "I-D, II-F, III-A" },
      { type: "txt", value: "I-D, II-C, III-A" },
      { type: "txt", value: "I-C, II-B, III-A" },
      { type: "txt", value: "I-C, II-F, III-D" },
    ],
    answer: 0,
  },
  {
    id: 75,
    question: [
      {
        type: "txt",
        value:
          "ABCD va OPRS kvadratlarning yuzlari mos ravishda 324 cm² va 196 cm² ga teng. Bo‘yalgan sohaning yuzini cm² da toping.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img40.png",
      },
    ],
    options: [
      { type: "txt", value: "36" },
      { type: "txt", value: "64" },
      { type: "txt", value: "56" },
      { type: "txt", value: "48" },
    ],
    answer: 2,
  },
  {
    id: 76,
    question: [
      {
        type: "txt",
        value:
          "Jalol otaning bog‘i to‘g‘ri to‘rtburchak shaklida. Bu yerning o‘ng va chap tomonidan 28 m bo‘sh joy qoldirildi va oralari 5 m bo‘lgan kvadrat shaklidagi uylar qurildi. Agar Jalol ota uylarni yerning o‘ng va chap tomonidan 4 metr bo‘sh joy qoldirib qurganida avvalgisidan 5 ta ko‘p uy qurilgan bo‘lar edi. Bunga ko‘ra, bu uyning tomoni necha metrga teng?",
      },
    ],
    options: [
      { type: "txt", value: "4,6 m" },
      { type: "txt", value: "5,6 m" },
      { type: "txt", value: "4,7 m" },
      { type: "txt", value: "4,5 m" },
    ],
    answer: 0,
  },
  {
    id: 77,
    question: [
      {
        type: "txt",
        value: "Tenglamani yeching:	4𝑥 + 6,4 = (8,3 + 2,1) ∙ (14,9 − 8,3)",
      },
    ],
    options: [
      { type: "txt", value: "15,6" },
      { type: "txt", value: "15,56" },
      { type: "txt", value: "17,18" },
      { type: "txt", value: "17,78" },
    ],
    answer: 1,
  },
  {
    id: 78,
    question: [
      {
        type: "txt",
        value:
          "Isomiddin uzunligi 60 metr bo‘lgan metro eskalatorida jim turgan holatda pastdan yuqoriga 2 minutda ko‘tariladi. Keyin harakatlanayotgan eskalatorda o‘zi ham pastdan tepaga harakatlanib 1 minutda ko‘tarildi. Isomiddin harakatlanayotgan eskalatordagi tezligi bilan 180 metr masofani qancha vaqtda bosib o‘tadi?",
      },
    ],
    options: [
      { type: "txt", value: "2.5 minut" },
      { type: "txt", value: "3 minut" },
      { type: "txt", value: "4 minut" },
      { type: "txt", value: "6 minut" },
    ],
    answer: 3,
  },
  {
    id: 79,
    question: [
      {
        type: "txt",
        value:
          "Yig‘indining oxirgi raqamini toping: 1∙ 3 ∙ 5 ∙ 7 ∙ … ∙ 31 + 2 ∙ 4 ∙ 6 ∙ … ∙ 32 = … ?",
      },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "5" },
      { type: "txt", value: "0" },
      { type: "txt", value: "3" },
    ],
    answer: 1,
  },
  {
    id: 80,
    question: [
      {
        type: "txt",
        value:
          "Bir xil o‘lchamli oynalardan uchta akvarium yasalgan. Qaysi akvariumga ko‘proq oyna ketgan.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img41.png",
      },
    ],
    options: [
      { type: "txt", value: "uchalasiga ham bir xil" },
      { type: "txt", value: "ikkinchisiga" },
      { type: "txt", value: "uchinchisiga" },
      { type: "txt", value: "birinchisiga" },
    ],
    answer: 3,
  },
  {
    id: 81,
    question: [
      {
        type: "txt",
        value: "X va Y nuqtalari orasida bitta poyezd yuradigan yo‘l mavjud.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img42.png",
      },
      {
        type: "txt",
        value:
          "Temiryo‘l kompaniyasi har kuni bir vaqtning o‘zida X nuqtadan bitta poyezd va Y nuqtadan bitta poyezd ketishini e’lon qildi. Poyezd tezligini o‘zgartirmasdan X nuqtadan Y nuqtagacha 180 daqiqada va Y dan X gacha 60 daqiqada yetib keladi. Poyezdlar bir-biri bilan urilib ketmasligi uchun qo‘shaloq yo‘l qurishmoqchi. Bu yo‘l qayerga qurilishi kerak?",
      },
    ],
    options: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img43.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img44.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img45.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img46.png",
      },
    ],
    answer: 0,
  },
  {
    id: 82,
    question: [
      {
        type: "txt",
        value:
          "Ali, Bobur, Komil, Diyor va Elyor aylana stol atrofida o‘tirishibdi. Ali Boburning yonida emas, Diyor Elyorning yonida va Bobur esa Diyorning yonida emas. Komilning yonida kim o‘tiribdi?",
      },
    ],
    options: [
      { type: "txt", value: "Elyor va Ali" },
      { type: "txt", value: "Bobur va Diyor" },
      { type: "txt", value: "Diyor va Elyor" },
      { type: "txt", value: "Ali va Bobur" },
    ],
    answer: 3,
  },
  {
    id: 83,
    question: [
      {
        type: "txt",
        value:
          "Silindr atrofida santimetrli o‘lchash tasmasi o‘ralgan. So‘roq belgisi bilan ko‘rsatilgan joyda qaysi son bo‘lishi kerak?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/al-xorazmiy/math/img47.png",
      },
    ],
    options: [
      { type: "txt", value: "60" },
      { type: "txt", value: "69" },
      { type: "txt", value: "53" },
      { type: "txt", value: "77" },
    ],
    answer: 1,
  },
  {
    id: 84,
    question: [
      {
        type: "txt",
        value:
          "Qutida 20 ta olma va 20 ta nok bor edi. Komila tasodifiy tarzda qutidan 21 dona mevani oldi, qolganini esa Laylo oldi. Quyidagi gaplardan qaysi biri doimo to‘g‘ri?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "Laylo qancha olma olgan bo‘lsa, Komila ham shuncha nok oldi.",
      },
      {
        type: "txt",
        value: "Komila qancha nok olgan bo‘lsa, shuncha olma oldi.",
      },
      {
        type: "txt",
        value: "Laylo qancha olma olgan bo‘lsa, Komila ham shuncha olma oldi.",
      },
      { type: "txt", value: "Komila eng kamida bitta nok oldi." },
      {
        type: "txt",
        value: "Laylo qancha nok olgan bo‘lsa, Komila ham shuncha nok oldi.",
      },
    ],
    answer: 0,
  },
];

export const specializedSchoolMath_3class = [
  {
    id: 0,
    question: [{ type: "txt", value: "Hisoblang: 27 ∙ 38 + 38 ∙ 73" }],
    options: [
      { type: "txt", value: "2 774" },
      { type: "txt", value: "2 106" },
      { type: "txt", value: "3 800" },
      { type: "txt", value: "3 756" },
    ],
    answer: 2,
  },
  {
    id: 1,
    question: [{ type: "txt", value: "Hisoblang: 696: 6 − 10" }],
    options: [
      { type: "txt", value: "106" },
      { type: "txt", value: "96" },
      { type: "txt", value: "117" },
      { type: "txt", value: "116" },
    ],
    answer: 0,
  },
  {
    id: 2,
    question: [{ type: "txt", value: "Tenglamani yeching. 𝑋 − 360: 3 = 80" }],
    options: [
      { type: "txt", value: "80" },
      { type: "txt", value: "120" },
      { type: "txt", value: "200" },
      { type: "txt", value: "100" },
    ],
    answer: 2,
  },
  {
    id: 3,
    question: [{ type: "txt", value: "Hisoblang: 𝟑𝟔𝟔𝟐 ∙ 𝟐𝟗" }],
    options: [
      { type: "txt", value: "106198" },
      { type: "txt", value: "16198" },
      { type: "txt", value: "6 198" },
      { type: "txt", value: "26198" },
    ],
    answer: 0,
  },
  {
    id: 4,
    question: [
      {
        type: "txt",
        value:
          "“Bir million to’qson olti ming bir yuz to’qqiz” sonini raqamlarda ifodalang.",
      },
    ],
    options: [
      { type: "txt", value: "1960109" },
      { type: "txt", value: "1096109" },
      { type: "txt", value: "19601009" },
      { type: "txt", value: "10096109" },
    ],
    answer: 1,
  },
  {
    id: 5,
    question: [{ type: "txt", value: "Hisoblang: 4305: 35" }],
    options: [
      { type: "txt", value: "223" },
      { type: "txt", value: "123" },
      { type: "txt", value: "202" },
      { type: "txt", value: "203" },
    ],
    answer: 1,
  },
  {
    id: 6,
    question: [
      {
        type: "txt",
        value:
          "2*8 < 258 tengsizlik o’rinli bo’lishi uchun yulduzcha o’rniga quydagi raqamlardan qaysi birini qo’yish mumkin emas?",
      },
    ],
    options: [
      { type: "txt", value: "7" },
      { type: "txt", value: "1" },
      { type: "txt", value: "4" },
      { type: "txt", value: "3" },
      { type: "txt", value: "2" },
    ],
    answer: 0,
  },
  {
    id: 7,
    question: [{ type: "txt", value: "𝐇𝐢𝐬𝐨𝐛𝐥𝐚𝐧𝐠: 40: 20 + 50: 10" }],
    options: [
      { type: "txt", value: "5" },
      { type: "txt", value: "7" },
      { type: "txt", value: "10" },
      { type: "txt", value: "8" },
    ],
    answer: 1,
  },
  {
    id: 8,
    question: [
      {
        type: "txt",
        value:
          "Agar raqamlari yondagi kabi blegilansa, unda 6147 ni qaysi biriga to’g’rib keladi?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/3class/img1.png",
      },
    ],
    options: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/3class/img2.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/3class/img3.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/3class/img4.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/3class/img5.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/3class/img6.png",
      },
    ],
    answer: 1,
  },
  {
    id: 9,
    question: [{ type: "txt", value: "Barcha sonlar ko'paytmasini toping." }],
    options: [
      { type: "txt", value: "cheksiz" },
      { type: "txt", value: "hisoblab bo'lmaydi" },
      { type: "txt", value: "nol" },
    ],
    answer: 2,
  },
  {
    id: 10,
    question: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/3class/img7.png",
      },
      {
        type: "txt",
        value:
          "Yuqoridagi 5 ta tokchali kutubxona javonida yuqoridan pastgacha ketma-ket 1 dan boshlab toq sonlarga mutanosib qilib kitoblar joylangan bo’lsa kutubxona javonida jami nechta kitob bor?",
      },
    ],
    options: [
      { type: "txt", value: "15" },
      { type: "txt", value: "14" },
      { type: "txt", value: "16" },
      { type: "txt", value: "25" },
      { type: "txt", value: "6" },
    ],
    answer: 3,
  },
  {
    id: 11,
    question: [
      {
        type: "txt",
        value:
          "Jorj bir xil raqamli paypoqlarini juftlab chiqmoqda. Unda nechta juft paypoq hosil bo`ladi?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/3class/img8.png",
      },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
      { type: "txt", value: "8" },
    ],
    answer: 2,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value:
          "Maya ismli asalari to`rtburchak ichidagi, ammo uchburchakdan tashqarida joylashgan gullardan gul changini yig`moqda. Maya nechta guldan gul changini yig`di?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/3class/img9.png",
      },
    ],
    options: [
      { type: "txt", value: "9" },
      { type: "txt", value: "10" },
      { type: "txt", value: "13" },
      { type: "txt", value: "17" },
      { type: "txt", value: "20" },
    ],
    answer: 0,
  },
  {
    id: 13,
    question: [
      {
        type: "txt",
        value:
          "Zooparkka kirish uchun 12 ta bola navbatda turibdi. Lyusi eshikdan boshlab sanaganda 7-o`rinda turibdi, Kim esa oxiridan boshlab sanaganda 2-o`rinda turibdi. Lyusi va Kimning orasida navbatda nechta bola bor?",
      },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
    ],
    answer: 1,
  },
  {
    id: 14,
    question: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/3class/img10.png",
      },
    ],
    options: [
      { type: "txt", value: "5 tsent" },
      { type: "txt", value: "6 tsent" },
      { type: "txt", value: "7 tsent" },
      { type: "txt", value: "8 tsent" },
    ],
    answer: 2,
  },
];

export const specializedSchoolMath_4class = [
  {
    id: 0,
    question: [
      {
        type: "txt",
        value:
          "Sonli ketma-ketlikning keyingi hadini toping. 4 , 7, 13, 22, 34, 49, _____",
      },
    ],
    options: [
      { type: "txt", value: "64" },
      { type: "txt", value: "65" },
      { type: "txt", value: "66" },
      { type: "txt", value: "67" },
      { type: "txt", value: "68" },
    ],
    answer: 4,
  },
  {
    id: 1,
    question: [
      { type: "txt", value: "Sotuvchi 60 kilogramm guruchning" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img1.png",
      },
      {
        type: "txt",
        value:
          "qismini uch kilogrammlik qoplarga joyladi. Agar sotuvchi har bir qopni 9000 so`mdan sotsa qancha daromad oladi?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img2.png",
      },
    ],
    options: [
      { type: "txt", value: "156000" },
      { type: "txt", value: "144000" },
      { type: "txt", value: "624000" },
      { type: "txt", value: "180000" },
      { type: "txt", value: "240000" },
    ],
    answer: 1,
  },
  {
    id: 2,
    question: [
      {
        type: "txt",
        value:
          "* o’rniga qanday raqam qo’yilsa, to’g’ri tenglik hosil bo’ladi? 59181-459*3=13258",
      },
    ],
    options: [
      { type: "txt", value: "4" },
      { type: "txt", value: "3" },
      { type: "txt", value: "2" },
      { type: "txt", value: "1" },
      { type: "txt", value: "5" },
    ],
    answer: 2,
  },
  {
    id: 3,
    question: [
      {
        type: "txt",
        value:
          "So’roq belgisi o’rnida turishi zarur bo’lgan sonlarning ko’paytmasini toping.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img3.png",
      },
    ],
    options: [
      { type: "txt", value: "67" },
      { type: "txt", value: "702" },
      { type: "txt", value: "663" },
      { type: "txt", value: "286" },
      { type: "txt", value: "207" },
    ],
    answer: 1,
  },
  {
    id: 4,
    question: [
      {
        type: "txt",
        value:
          "Asliddin 7700 ni hosil qilish uchun bir nechta 7 raqamini qo’shib chiqdi. 7+7+7+…+7=7700  Agar Asliddin qo’shib chiqqan 7 raqamlari sonidan besh marta kam sondagi 4 raqamlarini qo’shib chiqsa, yig’indi nechaga teng bo’ladi?",
      },
    ],
    options: [
      { type: "txt", value: "220" },
      { type: "txt", value: "880" },
      { type: "txt", value: "660" },
      { type: "txt", value: "22000" },
      { type: "txt", value: "8800" },
    ],
    answer: 1,
  },
  {
    id: 5,
    question: [
      { type: "txt", value: "Tikuvchi matoning" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img4.png",
      },
      { type: "txt", value: "qismidan yubka," },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img5.png",
      },
      { type: "txt", value: "qismidan ko’ylak," },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img6.png",
      },
      {
        type: "txt",
        value:
          "qismidan jaket , qolganidan esa shim tikdi. Tikuvchi qaysi kiyim uchun eng kam mato ishlatgan?",
      },
    ],
    options: [
      { type: "txt", value: "Yubka" },
      { type: "txt", value: "Ko’ylak" },
      { type: "txt", value: "Jaket" },
      { type: "txt", value: "Shim" },
      { type: "txt", value: "Jaket va Shim" },
    ],
    answer: 3,
  },
  {
    id: 6,
    question: [
      {
        type: "txt",
        value:
          "Javoblardan qaysi biri quyida berilgan ifodalarning hech biriga teng emas?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img7.png",
      },
    ],
    options: [
      { type: "txt", value: "4×1000+4×500+(4×40)" },
      { type: "txt", value: "7×4000+7×700+(7×5)" },
      { type: "txt", value: "8×5000+8×900" },
      { type: "txt", value: "(2×7000+2×900+2×80+(2×1)" },
      { type: "txt", value: "11×1000+(11×1)" },
    ],
    answer: 2,
  },
  {
    id: 7,
    question: [
      {
        type: "txt",
        value:
          "Dehqon bozorga 480 kg kartoshka olib keldi. U har biri 8 kg dan bo’lgan 15 qop kartoshka sotdi. Dehqon olib kelgan kartoshkalarining qancha qismini sotgan?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img8.png",
      },
    ],
    options: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img9.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img10.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img11.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img12.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img13.png",
      },
    ],
    answer: 0,
  },
  {
    id: 8,
    question: [
      {
        type: "txt",
        value:
          "Abror katakli daftarga 4 ta to’gri chiziqni chizdi. Har bir katakning bo’yi va eni 1sm. Birinchi chiziq (1;1) dan boshlanadi va (1;7) da tugaydi. Ikkinchi chiziq (1;7) dan boshlanadi va (5;7) da tugaydi. Uchinchi chiziq (5;7) dan boshlanadi va (5;1) da tugaydi. To’rtinchi chiziq esa hosil bo’lgan siniq chiziqni to’g’ri to’rtburchakka to’ldirdi. Abror chizgan figuraning yuzini toping. (sm²)",
      },
    ],
    options: [
      { type: "txt", value: "35" },
      { type: "txt", value: "24" },
      { type: "txt", value: "30" },
      { type: "txt", value: "28" },
      { type: "txt", value: "20" },
    ],
    answer: 0,
  },
  {
    id: 9,
    question: [
      {
        type: "txt",
        value:
          "Agar 6 soat 21 daqiqa uxlagan inson tong vaqti bilan 07:00 da uyg'ongan bo'lsa, u nechada uxlagan?",
      },
    ],
    options: [
      { type: "txt", value: "01:39" },
      { type: "txt", value: "23:52" },
      { type: "txt", value: "23:11" },
      { type: "txt", value: "00:39" },
      { type: "txt", value: "00:11" },
    ],
    answer: 3,
  },
  {
    id: 10,
    question: [
      {
        type: "txt",
        value:
          "Quyidagi taqqoslashga ko'ra, o’ngdagi katakka shu tengsizlikni qanoatlantiradigan eng katta son yozildi. Quyidagilardan nechtasida shu songa noto’g’ri ta’rif berilgan.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img14.png",
      },
      {
        type: "txt",
        value:
          "1)O’nlar xonasigacha yaxlitlansa 9880 ga teng. 2)9×1000+8×100+7×10+6×1 ga teng. 3)Raqamlari har xil bo'lgan eng katta 4 xonali natural son. 4)Birlar va yuzlar xonasidagi raqamlar bir xil. 5)Juft son.",
      },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
    ],
    answer: 3,
  },
  {
    id: 11,
    question: [
      {
        type: "txt",
        value:
          "Nodir toq raqamlarni yaxshi ko`radi. U telefoniga o`zi yaxshi ko`rgan raqamlardan foydalanib, turli raqamli uch xonali parol qo`ymoqchi. Nodir buni necha xil usulda qilishi mumkin?",
      },
    ],
    options: [
      { type: "txt", value: "12" },
      { type: "txt", value: "20" },
      { type: "txt", value: "60" },
      { type: "txt", value: "75" },
      { type: "txt", value: "125" },
    ],
    answer: 1,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value:
          "Yondagi keltirilgan  rasmga ko’ra, bo’yalgan qism yuzini toping. (sm²)",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img15.png",
      },
    ],
    options: [
      { type: "txt", value: "260" },
      { type: "txt", value: "308" },
      { type: "txt", value: "490" },
      { type: "txt", value: "608" },
      { type: "txt", value: "460" },
    ],
    answer: 3,
  },
  {
    id: 13,
    question: [
      {
        type: "txt",
        value:
          " Muvozanatga erishish uchun X o‘rniga nechta Δ  bo`lishi kerak?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img16.png",
      },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
      { type: "txt", value: "2" },
    ],
    answer: 1,
  },
  {
    id: 14,
    question: [
      {
        type: "txt",
        value:
          "Buzilgan ikkita soatning birinchisi har 4 daqiqada 1 daqiqa, ikkinchisi esa har 5 daqiqada 1 daqiqa orqada qoladi. 3 soatdan keyin bir vaqtning o'zida to’g’rilangan ikkita soat orasidagi farq necha daqiqa bo'ladi?",
      },
    ],
    options: [
      { type: "txt", value: "8" },
      { type: "txt", value: "3" },
      { type: "txt", value: "10" },
      { type: "txt", value: "12" },
      { type: "txt", value: "9" },
    ],
    answer: 4,
  },
  {
    id: 15,
    question: [
      {
        type: "txt",
        value:
          "Maftuna bir soatda 9 ta yostiqcha tikadi. Madina esa bir soatda undan 6 ta kam yostiqcha tikadi. Maftuna va Madina har biri bir kunda 9 soat ishlaydi. Ular 864 ta yostiqchani necha kunda tikib bo’lishadi?",
      },
    ],
    options: [
      { type: "txt", value: "8" },
      { type: "txt", value: "7" },
      { type: "txt", value: "9" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
    ],
    answer: 0,
  },
  {
    id: 16,
    question: [
      { type: "txt", value: "∆ ning qiymatini toping.  88-44:11+22=111-∆  " },
    ],
    options: [
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
      { type: "txt", value: "84" },
      { type: "txt", value: "85" },
    ],
    answer: 1,
  },
  {
    id: 17,
    question: [
      {
        type: "txt",
        value:
          "Yo’l ta’mirlovchilar uzunligi 20 km bo’lgan yo’lni uch kunda ta’mirlashdi. Ular birinchi kuni butun yo’lning",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img17.png",
      },
      {
        type: "txt",
        value:
          "qismini, ikkinchi kuni birinchi kundagidan 1 km 400 m kam yo’l ta’mirlashdi. Ular uchinchi kuni qancha yo’lni ta’mirlashgan?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img18.png",
      },
    ],
    options: [
      { type: "txt", value: "6 km 400 m" },
      { type: "txt", value: "6 km" },
      { type: "txt", value: "5 km 500 m" },
      { type: "txt", value: "5 km 400 m" },
      { type: "txt", value: "5 km 600 m" },
    ],
    answer: 3,
  },
  {
    id: 18,
    question: [
      {
        type: "txt",
        value: "Quyidagi figura nechta kichik kublardan tashkil topgan?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img19.png",
      },
    ],
    options: [
      { type: "txt", value: "58" },
      { type: "txt", value: "57" },
      { type: "txt", value: "56" },
      { type: "txt", value: "55" },
      { type: "txt", value: "54" },
    ],
    answer: 4,
  },
  {
    id: 19,
    question: [
      { type: "txt", value: "Sayyodda 96000 so’m pul bor edi. U pulining" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img20.png",
      },
      {
        type: "txt",
        value:
          "qismiga banan va qolganiga esa qulupnay sotib oldi. Agar 1 kg bananning narxi 12000 so’m va 1 kg qulupnayning narxi 9000 so’m bo’lsa, u necha kilogramm banan va qulupnay sotib olgan?",
      },
    ],
    options: [
      { type: "txt", value: "8" },
      { type: "txt", value: "10" },
      { type: "txt", value: "9" },
      { type: "txt", value: "12" },
      { type: "txt", value: "11" },
    ],
    answer: 2,
  },
  {
    id: 20,
    question: [
      {
        type: "txt",
        value: "Quyidagi sonlardan qaysi biri 13 ga bo’linmaydi?",
      },
    ],
    options: [
      { type: "txt", value: "68" },
      { type: "txt", value: "78" },
      { type: "txt", value: "104" },
      { type: "txt", value: "91" },
      { type: "txt", value: "52" },
    ],
    answer: 0,
  },
  {
    id: 21,
    question: [
      { type: "txt", value: "Yondagi shaklning qanday qismi bo’yalgan." },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img21.png",
      },
    ],
    options: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img22.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img23.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img24.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img25.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img26.png",
      },
    ],
    answer: 2,
  },
  {
    id: 22,
    question: [
      {
        type: "txt",
        value: "Kvadrat va uchburchak nechaga teng ekanligini toping.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img27.png",
      },
    ],
    options: [
      { type: "txt", value: "6 va 3" },
      { type: "txt", value: "4 va 6" },
      { type: "txt", value: "3 va 6" },
      { type: "txt", value: "5 va 3" },
      { type: "txt", value: "4 va 5" },
    ],
    answer: 1,
  },
  {
    id: 23,
    question: [
      {
        type: "txt",
        value:
          "Yonda karra jadvali berilgan bo’lib, unda ayrim sonlar tushirib qoldirilgan. ∆ ning qiymatini hisoblang. ",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img28.png",
      },
    ],
    options: [
      { type: "txt", value: "35" },
      { type: "txt", value: "42" },
      { type: "txt", value: "45" },
      { type: "txt", value: "48" },
      { type: "txt", value: "49" },
    ],
    answer: 1,
  },
  {
    id: 24,
    question: [
      {
        type: "txt",
        value:
          "Quyidagi taqqoslashlardan qaysi biri son nurida ko‘rsatilgan kasr uchun noto‘g‘ri?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img29.png",
      },
    ],
    options: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img30.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img31.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img32.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img33.png",
      },
    ],
    answer: 3,
  },
  {
    id: 25,
    question: [
      {
        type: "txt",
        value:
          "Akmal, Suxrob, Javohir va Ahmad yaqin do’st. Akmal Suxrobdan 5 yosh va Javohirdan esa 2 yosh katta. Ahmad esa 40 yosh va Javohirdan 7 yosh katta bo’lsa, to’rtta do’stning yoshlari yig’indisini toping.",
      },
    ],
    options: [
      { type: "txt", value: "144" },
      { type: "txt", value: "143" },
      { type: "txt", value: "138" },
      { type: "txt", value: "150" },
      { type: "txt", value: "148" },
    ],
    answer: 2,
  },
  {
    id: 26,
    question: [
      {
        type: "txt",
        value:
          "Sut sog`uvchi har kuni sog`adigan sutining yarmini sutni qayta ishlash korxonasiga jo‘natadi. Qolgan yarmining 3/8 qismi bilan pishloq, qolgan sutdan esa sariyog‘ tayyorlaydi. Agar bu sog`uvchi har kuni 192 litr sut ishlab chiqarsa, sariyog‘ uchun necha litr sut sarflaydi?",
      },
    ],
    options: [
      { type: "txt", value: "12" },
      { type: "txt", value: "36" },
      { type: "txt", value: "60" },
      { type: "txt", value: "96" },
      { type: "txt", value: "72" },
    ],
    answer: 2,
  },
  {
    id: 27,
    question: [
      {
        type: "txt",
        value:
          "Yondagi diagrammalarda bo’yoqchilar birinchi va ikkinchi kunda devorning qancha qismini bo’yagani tasvirlangan. Ishchilar shu ikki kun davomida devorning qancha qismini bo’yagan?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img34.png",
      },
    ],
    options: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img35.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img36.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img37.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img38.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/4class/img39.png",
      },
    ],
    answer: 1,
  },
  {
    id: 28,
    question: [
      {
        type: "txt",
        value:
          "Uch yosh farq bilan tug'ilgan 3 aka-ukaning yoshlari yig’indisi eng kamida necha bo’lishi mumkin?",
      },
    ],
    options: [
      { type: "txt", value: "11" },
      { type: "txt", value: "14" },
      { type: "txt", value: "12" },
      { type: "txt", value: "15" },
      { type: "txt", value: "10" },
    ],
    answer: 0,
  },
  {
    id: 29,
    question: [
      {
        type: "txt",
        value:
          "Bir duradgorda ikki xil uzunlikdagi, uzun va kalta jami 36 ta yog’och bor. Uzun yog’ochlarning  har biri beshta teng bo’lakka, kalta yog’ochlarning har biri esa to'rta teng bo’lakka kesildi. Agar uzun yog’ochlarni kesishdan hosil bo’lgan bo’laklar soni kalta yog’ochlarni kesishdan hosil bo’lgan bo’laklar soniga teng bo’lsa, bu durodgorda nechta uzun yog’och bo’lgan?",
      },
    ],
    options: [
      { type: "txt", value: "16" },
      { type: "txt", value: "20" },
      { type: "txt", value: "80" },
      { type: "txt", value: "24" },
      { type: "txt", value: "12" },
    ],
    answer: 0,
  },
];

export const specializedSchoolMath_5class = [
  {
    id: 0,
    question: [{ type: "txt", value: "Hisoblang: 123∙45- 123∙35 +2 ∙ 17²:34" }],
    options: [
      { type: "txt", value: "1247" },
      { type: "txt", value: "1347" },
      { type: "txt", value: "1230" },
      { type: "txt", value: "1330" },
    ],
    answer: 0,
  },
  {
    id: 1,
    question: [
      {
        type: "txt",
        value:
          "777776+77776+7776+776+76 yig‘indining qiymatini 7 ga boʻlinganidagi qoldiqni toping.",
      },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "2" },
      { type: "txt", value: "1" },
      { type: "txt", value: "5" },
    ],
    answer: 1,
  },
  {
    id: 2,
    question: [
      {
        type: "txt",
        value:
          "Natural sonlar qatorida 82 dan katta boʻlmagan barcha juft sonlar yig‘indisini toping.",
      },
    ],
    options: [
      { type: "txt", value: "1722" },
      { type: "txt", value: "1800" },
      { type: "txt", value: "3403" },
      { type: "txt", value: "3500" },
    ],
    answer: 0,
  },
  {
    id: 3,
    question: [
      {
        type: "txt",
        value:
          "20 minut oldinga ildamlab ketgan soat hozir 9∶15 ni koʻrsatyapti. Ayni shu paytda 25 minut orqada qolgan soat nechani koʻrsatayotgan boʻladi?",
      },
    ],
    options: [
      { type: "txt", value: "8∶55" },
      { type: "txt", value: "8∶35" },
      { type: "txt", value: "9∶05" },
      { type: "txt", value: "8∶30" },
    ],
    answer: 3,
  },
  {
    id: 4,
    question: [
      {
        type: "txt",
        value:
          "Bir-biriga qarab harakatlanayotgan avtomobillar orasidagi masofa 450 km . Ulardan birinchising tezligi ikkinchisidan 2 marta ko‘proq. Agar avtomobillar 2 soatdan keyin uchrashgan bo‘lsalar, birinchisining tezligini toping.",
      },
    ],
    options: [
      { type: "txt", value: "70" },
      { type: "txt", value: "75" },
      { type: "txt", value: "150" },
      { type: "txt", value: "140" },
    ],
    answer: 2,
  },
  {
    id: 5,
    question: [
      {
        type: "txt",
        value:
          "Evkalipt daraxtining bo‘yi birinchi 5 yilda 12 m balandlikka yetadi. 35 yilda 48 metrga yetadi. Birinchi 25 yilda evkalipt daraxti necha cm ga o‘sadi?",
      },
    ],
    options: [
      { type: "txt", value: "3400 cm" },
      { type: "txt", value: "2500 cm" },
      { type: "txt", value: "3600 cm" },
      { type: "txt", value: "3000cm" },
    ],
    answer: 2,
  },
  {
    id: 6,
    question: [
      {
        type: "txt",
        value:
          "2022 - yil 20 - mart soat 12∶00 da Halimning tugʻilganiga 100 sutka boʻldi. Halim qaysi kuni tug‘ilgan?",
      },
    ],
    options: [
      { type: "txt", value: "10 - dekabr" },
      { type: "txt", value: "20 - dekabr" },
      { type: "txt", value: "22 - dekabr" },
      { type: "txt", value: "12 - dekabr" },
    ],
    answer: 0,
  },
  {
    id: 7,
    question: [
      {
        type: "txt",
        value:
          "Olma narxi banan narxidan 4 marta arzon. 3 kg olma va 2 kg banan uchun 44000 soʻm toʻlangan boʻlsa, bananning narxini aniqlang.",
      },
    ],
    options: [
      { type: "txt", value: "12000" },
      { type: "txt", value: "4000" },
      { type: "txt", value: "16000" },
      { type: "txt", value: "20000" },
    ],
    answer: 2,
  },
  {
    id: 8,
    question: [
      {
        type: "txt",
        value:
          "Qirralari uzunliklari 16 cm, 20 cm va 24 cm ga teng boʻlgan toʻgʻri burchakli parallelepiped shaklidagi taxtani qirrasi 4 cm boʻlgan nechta kubchaga ajratish mumkin?",
      },
    ],
    options: [
      { type: "txt", value: "60" },
      { type: "txt", value: "40" },
      { type: "txt", value: "120" },
      { type: "txt", value: "90" },
    ],
    answer: 2,
  },
  {
    id: 9,
    question: [
      {
        type: "txt",
        value:
          "Zavodda birinchi kun 568 ta , ikkinchi kun birinchi kundagidan 87 ta kam , uchinchi kun esa ikkinchi kundagidan 65 ta ortiq detal tayyorlandi. Shu uch kunda zavodda jami nechta detal tayyorlangan?",
      },
    ],
    options: [
      { type: "txt", value: "1585" },
      { type: "txt", value: "1595" },
      { type: "txt", value: "1605" },
      { type: "txt", value: "1575" },
    ],
    answer: 1,
  },
  {
    id: 10,
    question: [
      { type: "txt", value: "Rasmdagi berilgan shakl perimetrini toping:" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/5class/img1.png",
      },
    ],
    options: [
      { type: "txt", value: "𝑃 = 24 𝑐𝑚" },
      { type: "txt", value: "𝑃 = 28 𝑐𝑚" },
      { type: "txt", value: "𝑃 = 34 𝑐𝑚" },
      { type: "txt", value: "𝑃 = 38 𝑐𝑚" },
    ],
    answer: 3,
  },
  {
    id: 11,
    question: [
      {
        type: "txt",
        value: "Tenglamani yeching:	2,25𝑥 + 7,23 + 7,75𝑥 = 430,23 − 223",
      },
    ],
    options: [
      { type: "txt", value: "13" },
      { type: "txt", value: "19" },
      { type: "txt", value: "21" },
      { type: "txt", value: "20" },
    ],
    answer: 3,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value:
          "Perimetri 20 m kvadrat shaklidagi xonaga boʻyi 4 m, eni esa 3 m boʻlgan toʻg‘ri toʻrtburchak shaklidagi gilam toʻshaldi. Bu xonaning necha kvadrat metr yuzasi gilam bilan qoplanmaydi?",
      },
    ],
    options: [
      { type: "txt", value: "13 m²" },
      { type: "txt", value: "8 m²" },
      { type: "txt", value: "6 m²" },
      { type: "txt", value: "12 m²" },
    ],
    answer: 3,
  },
  {
    id: 13,
    question: [
      {
        type: "txt",
        value:
          "Agar it 27 sekundda bolani quvib yetgan bo‘lsa, uning tezligi qancha bo‘lgan?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/5class/img2.png",
      },
    ],
    options: [
      { type: "txt", value: "200 m/min" },
      { type: "txt", value: "300 m/min" },
      { type: "txt", value: "350 m/min" },
      { type: "txt", value: "100 m/min" },
    ],
    answer: 1,
  },
  {
    id: 14,
    question: [
      {
        type: "txt",
        value:
          "Berilgan rasmning nechta joyida ikkita o‘quvchi chap qo‘llari bilan bir-birini ushlab turibdi?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/5class/img3.png",
      },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 0,
  },
  {
    id: 15,
    question: [
      {
        type: "txt",
        value: "X va Y nuqtalari orasida bitta poyezd yuradigan yo‘l mavjud.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/5class/img4.png",
      },
      {
        type: "txt",
        value:
          "Temiryo‘l kompaniyasi har kuni bir vaqtning o‘zida X nuqtadan bitta poyezd va Y nuqtadan bitta poyezd ketishini e’lon qildi. Poyezd tezligini o‘zgartirmasdan X nuqtadan Y nuqtagacha 180 daqiqada va Y dan X gacha 60 daqiqada yetib keladi. Poyezdlar bir-biri bilan urilib ketmasligi uchun qo‘shaloq yo‘l qurishmoqchi. Bu yo‘l qayerga qurilishi kerak?",
      },
    ],
    options: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/5class/img5.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/5class/img6.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/5class/img7.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/5class/img8.png",
      },
    ],
    answer: 0,
  },
  {
    id: 16,
    question: [
      {
        type: "txt",
        value:
          "Ali, Bobur, Komil, Diyor va Elyor aylana stol atrofida o‘tirishibdi. Ali Boburning yonida emas, Diyor Elyorning yonida va Bobur esa Diyorning yonida emas. Komilning yonida kim o‘tiribdi?",
      },
    ],
    options: [
      { type: "txt", value: "Elyor va Ali" },
      { type: "txt", value: "Bobur va Diyor" },
      { type: "txt", value: "Diyor va Elyor" },
      { type: "txt", value: "Ali va Bobur" },
    ],
    answer: 3,
  },
  {
    id: 17,
    question: [
      {
        type: "txt",
        value:
          "Silindr atrofida santimetrli o‘lchash tasmasi o‘ralgan. So‘roq belgisi bilan ko‘rsatilgan joyda qaysi son bo‘lishi kerak?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/5class/img9.png",
      },
    ],
    options: [
      { type: "txt", value: "60" },
      { type: "txt", value: "69" },
      { type: "txt", value: "53" },
      { type: "txt", value: "77" },
    ],
    answer: 1,
  },
  {
    id: 18,
    question: [
      {
        type: "txt",
        value:
          "Qutida 20 ta olma va 20 ta nok bor edi. Komila tasodifiy tarzda qutidan 21 dona mevani oldi, qolganini esa Laylo oldi. Quyidagi gaplardan qaysi biri doimo to‘g‘ri?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "Laylo qancha olma olgan bo‘lsa, Komila ham shuncha nok oldi.",
      },
      {
        type: "txt",
        value: "Komila qancha nok olgan bo‘lsa, shuncha olma oldi.",
      },
      {
        type: "txt",
        value: "Laylo qancha olma olgan bo‘lsa, Komila ham shuncha olma oldi.",
      },
      { type: "txt", value: "Komila eng kamida bitta nok oldi." },
      {
        type: "txt",
        value: "Laylo qancha nok olgan bo‘lsa, Komila ham shuncha nok oldi.",
      },
    ],
    answer: 0,
  },
];

export const specializedSchoolMath_6class = [
  {
    id: 0,
    question: [
      {
        type: "txt",
        value:
          "O‘lchov birliklari va ularga mos qiymatlar o‘rtasidagi moslikni o‘rnating:",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/6class/img1.png",
      },
    ],
    options: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/6class/img2.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/6class/img3.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/6class/img4.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/6class/img5.png",
      },
    ],
    answer: 0,
  },
  {
    id: 1,
    question: [
      {
        type: "txt",
        value:
          "Noma’lum sonni 11 ga bo‘lganda to‘liqsiz bo‘linma ham qoldiq ham 5 chiqdi. Noma’lum sonni toping.",
      },
    ],
    options: [
      { type: "txt", value: "65" },
      { type: "txt", value: "55" },
      { type: "txt", value: "60" },
      { type: "txt", value: "50" },
    ],
    answer: 2,
  },
  {
    id: 2,
    question: [
      {
        type: "txt",
        value:
          "Rasmda ob-havo grafigi keltirilgan. Unda ob-havo va kelgusi besh kun uchun maksimal harorat ko‘rsatilgan. Quyidagilardan qaysi biri maksimal haroratning tegishli grafigini aks ettirgan?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/6class/img6.png",
      },
    ],
    options: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/6class/img7.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/6class/img8.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/6class/img9.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/6class/img10.png",
      },
    ],
    answer: 2,
  },
  {
    id: 3,
    question: [
      {
        type: "txt",
        value:
          "Oltita to‘g‘ri to‘rtburchaklar rasmda ko‘rsatilganidek joylashtirilgan. Chapdan yuqoridagi to‘g‘ri to‘rtburchakning balandligi 6 cm ga teng. To‘g‘ri to‘rtburchaklar ichidagi sonlar ularning cm² dagi yuzlarini bildiradi. O‘ngdan pastdagi to‘g‘ri to‘rtburchakning perimetrini toping.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/6class/img11.png",
      },
    ],
    options: [
      { type: "txt", value: "34 cm" },
      { type: "txt", value: "24 cm" },
      { type: "txt", value: "22 cm" },
      { type: "txt", value: "32 cm" },
    ],
    answer: 2,
  },
  {
    id: 4,
    question: [
      {
        type: "txt",
        value:
          "Quyidagi tasdiqlar ichidan to‘g‘ri yoki noto‘g‘riligi ketma-ketligi to‘g‘ri ko‘rsatilgan javob variantini aniqlang.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/6class/img12.png",
      },
    ],
    options: [
      { type: "txt", value: "I- yo‘q, II- ha, III-yo‘q, IV-ha" },
      { type: "txt", value: "I-ha, II-ha, III-yo‘q, IV-ha" },
      { type: "txt", value: "I-ha, II-yo‘q, III-ha, IV-ha" },
      { type: "txt", value: "I-yo‘q, II-yo‘q, III-ha, IV-yo‘q" },
    ],
    answer: 0,
  },
  {
    id: 5,
    question: [
      {
        type: "txt",
        value:
          "Natural ko‘paytuvchilardan birini 10 % ga, ikkinchisini 20 % ga oshirib, uchinchisini 25 % ga kamaytirsak, ko‘paytma necha foizga o‘zgaradi?",
      },
    ],
    options: [
      { type: "txt", value: "1% ga oshadi" },
      { type: "txt", value: "5% ga oshadi" },
      { type: "txt", value: "1% ga kamayadi" },
      { type: "txt", value: "5% ga kamayadi" },
    ],
    answer: 2,
  },
  {
    id: 6,
    question: [
      {
        type: "txt",
        value:
          "Uchta sonning o‘rta arifmetigi 10 ga, boshqa ikki sonning o‘rta arifmetigi esa 15 ga teng. Shu beshta sonning o‘rta arifmetigini toping.",
      },
    ],
    options: [
      { type: "txt", value: "13" },
      { type: "txt", value: "15" },
      { type: "txt", value: "12,5" },
      { type: "txt", value: "12" },
    ],
    answer: 3,
  },
  {
    id: 7,
    question: [
      {
        type: "txt",
        value:
          "Harorati 36° bo‘lgan 6 l suvga harorati 15° bo‘lgan suv qo‘shilganida aralashtirilgan suvning harorati 24° bo‘ldi. Necha litr suv qo‘shilgan?",
      },
    ],
    options: [
      { type: "txt", value: "10" },
      { type: "txt", value: "8" },
      { type: "txt", value: "12" },
      { type: "txt", value: "9" },
    ],
    answer: 1,
  },
  {
    id: 8,
    question: [
      {
        type: "txt",
        value:
          "Oralaridagi masofa 280 km bo‘lgan A va B shaharlardan bir vaqtda ikki avtomobil yo‘lga chiqdi. Agar avtomobillar bir – biriga qarab yursa, ular 2 soatdan keyin uchrashadi. Agar ular bir yo‘nalishda yursa, A shahardan chiqqan avtomobil B shahardan chiqqan avtomobilni 14 soatda quvib yetadi. B shahardan chiqqan avtomobil tezligi necha km/h ga teng?",
      },
    ],
    options: [
      { type: "txt", value: "140 km/h" },
      { type: "txt", value: "60 km/h" },
      { type: "txt", value: "80 km/h" },
      { type: "txt", value: "20 km/h" },
    ],
    answer: 2,
  },
  {
    id: 9,
    question: [
      {
        type: "txt",
        value:
          "A, B, C, D, E, F variantlardan quyidagi I, II, III savollarning har biriga mos javobni qo‘ying:",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/6class/img13.png",
      },
    ],
    options: [
      { type: "txt", value: "I-D, II-F, III-A" },
      { type: "txt", value: "I-D, II-C, III-A" },
      { type: "txt", value: "I-C, II-B, III-A" },
      { type: "txt", value: "I-C, II-F, III-D" },
    ],
    answer: 0,
  },
  {
    id: 10,
    question: [
      {
        type: "txt",
        value:
          "ABCD va OPRS kvadratlarning yuzlari mos ravishda 324 cm² va 196 cm² ga teng. Bo‘yalgan sohaning yuzini cm² da toping.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/6class/img14.png",
      },
    ],
    options: [
      { type: "txt", value: "36" },
      { type: "txt", value: "64" },
      { type: "txt", value: "56" },
      { type: "txt", value: "48" },
    ],
    answer: 2,
  },
  {
    id: 11,
    question: [
      {
        type: "txt",
        value:
          "Jalol otaning bog‘i to‘g‘ri to‘rtburchak shaklida. Bu yerning o‘ng va chap tomonidan 28 m bo‘sh joy qoldirildi va oralari 5 m bo‘lgan kvadrat shaklidagi uylar qurildi. Agar Jalol ota uylarni yerning o‘ng va chap tomonidan 4 metr bo‘sh joy qoldirib qurganida avvalgisidan 5 ta ko‘p uy qurilgan bo‘lar edi. Bunga ko‘ra, bu uyning tomoni necha metrga teng?",
      },
    ],
    options: [
      { type: "txt", value: "4,6 m" },
      { type: "txt", value: "5,6 m" },
      { type: "txt", value: "4,7 m" },
      { type: "txt", value: "4,5 m" },
    ],
    answer: 0,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value: "Tenglamani yeching:	4𝑥 + 6,4 = (8,3 + 2,1) ∙ (14,9 − 8,3)",
      },
    ],
    options: [
      { type: "txt", value: "15,6" },
      { type: "txt", value: "15,56" },
      { type: "txt", value: "17,18" },
      { type: "txt", value: "17,78" },
    ],
    answer: 1,
  },
  {
    id: 13,
    question: [
      {
        type: "txt",
        value:
          "Isomiddin uzunligi 60 metr bo‘lgan metro eskalatorida jim turgan holatda pastdan yuqoriga 2 minutda ko‘tariladi. Keyin harakatlanayotgan eskalatorda o‘zi ham pastdan tepaga harakatlanib 1 minutda ko‘tarildi. Isomiddin harakatlanayotgan eskalatordagi tezligi bilan 180 metr masofani qancha vaqtda bosib o‘tadi?",
      },
    ],
    options: [
      { type: "txt", value: "2.5 minut" },
      { type: "txt", value: "3 minut" },
      { type: "txt", value: "4 minut" },
      { type: "txt", value: "6 minut" },
    ],
    answer: 3,
  },
  {
    id: 14,
    question: [
      {
        type: "txt",
        value:
          "Yig‘indining oxirgi raqamini toping: 1 ∙ 3 ∙ 5 ∙ 7 ∙ … ∙ 31 + 2 ∙ 4 ∙ 6 ∙ … ∙ 32 = … ?",
      },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "5" },
      { type: "txt", value: "0" },
      { type: "txt", value: "3" },
    ],
    answer: 1,
  },
  {
    id: 15,
    question: [
      {
        type: "txt",
        value:
          "∠AOE dan OB, OC va OD nurlar chiqarilgan. OC nur BOE burchakning bissektrisasi, OD nur esa COE nurning ∠AOE = 168°, bissektrisasi. Agar ∠DOE = 32° bo‘lsa, ∠AOB necha gradusga teng bo‘ladi?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/6class/img15.png",
      },
    ],
    options: [
      { type: "txt", value: "40°" },
      { type: "txt", value: "64°" },
      { type: "txt", value: "128°" },
      { type: "txt", value: "116°" },
    ],
    answer: 0,
  },
  {
    id: 16,
    question: [
      {
        type: "txt",
        value:
          "Bir xil o‘lchamli oynalardan uchta akvarium yasalgan. Qaysi akvariumga ko‘proq oyna ketgan.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/6class/img16.png",
      },
    ],
    options: [
      { type: "txt", value: "uchalasiga ham bir xil" },
      { type: "txt", value: "ikkinchisiga" },
      { type: "txt", value: "uchinchisiga" },
      { type: "txt", value: "birinchisiga" },
    ],
    answer: 3,
  },
];

export const specializedSchoolMath_7class = [
  {
    id: 0,
    question: [
      {
        type: "txt",
        value:
          "Quyidagi tasdiqlarning to‘g‘ri yoki noto‘g‘ri ekanligi ketma-ketligini to‘g‘ri keltirilgan javob variantini aniqlang.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/7class/img1.png",
      },
    ],
    options: [
      { type: "txt", value: "1-ha, 2-ha, 3-yo‘q, 4-yo‘q" },
      { type: "txt", value: "1-ha, 2-yo‘q, 3-yo‘q, 4-yo‘q" },
      { type: "txt", value: "1-yo‘q, 2-yo‘q, 3-ha, 4-yo‘q" },
      { type: "txt", value: "1-yo‘q, 2-ha, 3-ha, 4-yo‘q" },
    ],
    answer: 0,
  },
  {
    id: 1,
    question: [
      {
        type: "txt",
        value: "24, 36 va 54 sonlarining EKUK ining EKUB iga nisbatini toping",
      },
    ],
    options: [
      { type: "txt", value: "72" },
      { type: "txt", value: "24" },
      { type: "txt", value: "36" },
      { type: "txt", value: "144" },
    ],
    answer: 2,
  },
  {
    id: 2,
    question: [
      {
        type: "txt",
        value:
          "Qalamdonda 5 ta qizil, 7 ta yashil, 4 ta qora rangli qalamlar bor. Qaramasdan: 2 ta turli rangdagi qalam olish uchun qalamdondan eng kami bilan nechta qalam olish kerak?",
      },
    ],
    options: [
      { type: "txt", value: "3 ta" },
      { type: "txt", value: "6 ta" },
      { type: "txt", value: "5 ta" },
      { type: "txt", value: "8 ta" },
    ],
    answer: 3,
  },
  {
    id: 3,
    question: [
      {
        type: "txt",
        value:
          "Ali, Bobur, Komil, Diyor va Elyor aylana stol atrofida o‘tirishibdi. Ali Boburning yonida emas, Diyor Elyorning yonida va Bobur esa Diyorning yonida emas. Komilning yonida qaysi ikki kishi o‘tirganligini aniqlang.",
      },
    ],
    options: [
      { type: "txt", value: "Ali va Bobur" },
      { type: "txt", value: "Bobur va Diyor" },
      { type: "txt", value: "Diyor va Elyor" },
      { type: "txt", value: "Elyor va Ali" },
    ],
    answer: 0,
  },
  {
    id: 4,
    question: [
      {
        type: "txt",
        value:
          "Rasmda Pekin, Anqara va Londonning ayni bir vaqtdagi soatlari ko‘rsatilgan:",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/7class/img2.png",
      },
      {
        type: "txt",
        value:
          "Pekin, London va Anqarada joylashgan texnologiya firmalari konferensiya o‘tkazishdi. Konferensiya Anqarada mahalliy vaqt bilan 10:00 da boshlandi. So‘ngra Londondagi mahalliy vaqt bilan 13:00 da tugadi. Berilgan ma’lumotlarga ko‘ra quyidagi mulohazalarning qaysi biri to‘g‘ri? 1)Uchrashuv 5 soat davom etdi. 2)Uchrashuv Pekinda mahalliy vaqt bilan 15:00 da boshlandi. 3)Uchrashuv Londonda mahalliy vaqt bilan 8:00 da boshlandi.",
      },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "2" },
      { type: "txt", value: "1" },
      { type: "txt", value: "barchasi to‘g‘ri" },
    ],
    answer: 2,
  },
  {
    id: 5,
    question: [
      {
        type: "txt",
        value:
          "Kitob varaqlari quyidagicha raqamlangan 1, 2, 3, 4, 5 va hokazo. Bu kitobda 5 raqami 16 marta takrorlanadi. Kitob eng ko‘pi bilan necha betdan iborat bo‘lishi mumkin?",
      },
    ],
    options: [
      { type: "txt", value: "80" },
      { type: "txt", value: "74" },
      { type: "txt", value: "66" },
      { type: "txt", value: "64" },
    ],
    answer: 3,
  },
  {
    id: 6,
    question: [
      {
        type: "txt",
        value: "Barcha uch xonali sonlar ichida 17 ga karrali sonlar nechta?",
      },
    ],
    options: [
      { type: "txt", value: "49" },
      { type: "txt", value: "53" },
      { type: "txt", value: "52" },
      { type: "txt", value: "51" },
    ],
    answer: 1,
  },
  {
    id: 7,
    question: [
      {
        type: "txt",
        value:
          "12% li	300 g	eritmaga	100 g	suv	qo‘shildi. Tuzning	eritmadagi	foiz miqdori qancha bo‘ldi?",
      },
    ],
    options: [
      { type: "txt", value: "5" },
      { type: "txt", value: "16" },
      { type: "txt", value: "9" },
      { type: "txt", value: "10" },
    ],
    answer: 2,
  },
  {
    id: 8,
    question: [
      {
        type: "txt",
        value: "𝑦 + 16 = 25	bo‘lsa,	(6𝑦 − 16) − (𝑦 + 4) ifoda qiymatini toping",
      },
    ],
    options: [
      { type: "txt", value: "35" },
      { type: "txt", value: "36" },
      { type: "txt", value: "26" },
      { type: "txt", value: "25" },
    ],
    answer: 3,
  },
  {
    id: 9,
    question: [
      {
        type: "txt",
        value:
          "100 ta chittak 100 kunda 100 kg don yeydi. 10 ta chittak 10 kunda necha kilogramm don yeydi?",
      },
    ],
    options: [
      { type: "txt", value: "10" },
      { type: "txt", value: "1" },
      { type: "txt", value: "5" },
      { type: "txt", value: "50" },
    ],
    answer: 1,
  },
  {
    id: 10,
    question: [
      {
        type: "txt",
        value:
          "Poyezdning tezligi 60 km/h. Masshtabi 1: 2000000 bo‘lgan xaritada 30 cm li kesma sifatida tasvirlangan haqiqiy masofani shu poyezd necha soatda  bosib o‘tadi?",
      },
    ],
    options: [
      { type: "txt", value: "5" },
      { type: "txt", value: "1" },
      { type: "txt", value: "10" },
      { type: "txt", value: "12" },
    ],
    answer: 2,
  },
  {
    id: 11,
    question: [
      {
        type: "txt",
        value:
          "Uchburchakning bir tomoni 1,7 dm, ikkinchi tomoni 17 cm va perimetri 5,8 dm. Bu uchburchak uchun quyidagi mulohazalardan qaysilari to‘g‘ri? 1.Uchburchak teng yonli; 2.Uchburchak teng tomonli; 3.Uchinchi tomon 24 dm ga teng; 4.Uchburchakning ikkita burchagi teng;",
      },
    ],
    options: [
      { type: "txt", value: "1, 4" },
      { type: "txt", value: "3, 4" },
      { type: "txt", value: "2, 4" },
      { type: "txt", value: "1,3" },
    ],
    answer: 0,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value:
          "Yuzi 50,24 cm² ga teng bo‘lgan doira aylanasining uzunligi dm da topish ketma-ketligi to‘g‘ri keltirilgan javob variantlarini aniqlang (bunda 𝜋 = 3,14). 1) 2 ⋅ 4 ⋅ 3,14 = 25,12 ;	2) 50,24 : 3,14 =16 ;	3) 25,12 cm = 2,512 dm ; 4)",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/7class/img3.png",
      },
    ],
    options: [
      { type: "txt", value: "1, 3, 2, 4" },
      { type: "txt", value: "2, 4, 1, 3" },
      { type: "txt", value: "4, 2, 1, 3" },
      { type: "txt", value: "1, 3, 2, 4" },
    ],
    answer: 1,
  },
  {
    id: 13,
    question: [
      {
        type: "txt",
        value:
          "Telefon stansiyasi mijozlarining uy telefon raqamlari 7 xonali sonlardan iborat va 234 sonidan boshlanadi. Telefon raqami turli raqamlardan iborat (bir xil raqam qatnashmagan) mijozlar sonini aniqlang.",
      },
    ],
    options: [
      { type: "txt", value: "1639 ta" },
      { type: "txt", value: "720  ta" },
      { type: "txt", value: "49 ta" },
      { type: "txt", value: "840 ta" },
    ],
    answer: 3,
  },
  {
    id: 14,
    question: [
      {
        type: "txt",
        value:
          "Mohiraning qadami uzunligi 50 cm, Nodiraning qadami uzunligi esa 60 cm. Opa- singillar uylaridan maktabga bir vaqtda yo’lga chiqdilar. Uydan maktabgacha bo‘lgan masofada ularning qadam izlari 200 marta ustma-ust tushdi: Uydan maktabgacha bo‘lgan masofa necha metr?",
      },
    ],
    options: [
      { type: "txt", value: "300 m" },
      { type: "txt", value: "6 dm" },
      { type: "txt", value: "600 m" },
      { type: "txt", value: "1200 m" },
    ],
    answer: 2,
  },
  {
    id: 15,
    question: [
      {
        type: "txt",
        value: "Bir haftalik havo harorati o‘zgarish grafigi berilgan:",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/7class/img4.png",
      },
      {
        type: "txt",
        value:
          "Grafikda berilgan ma’lumotlarga asoslanib, quyidagi savollarga javob bering: I.Eng yuqori harorat qaysi kuni kuzatilgan? II.Qaysi kunlari oralig‘ida havo harorati nisbatan ko‘proq o‘zgargan? III.Haftaning nechta kunida havo harorati haftalik o‘rtacha haroratdan past bo‘lgan? IV. Haftaning qaysi kunidagi harorat haftalik o‘rtacha haroratga yaqin bo‘lgan?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "I-shanba; II-juma-shanba; III-3 ta; IV-payshanba;",
      },
      { type: "txt", value: "I-shanba; II-juma-shanba; III-4 ta; IV-seshanba" },
      {
        type: "txt",
        value: "I-shanba; II-juma-shanba; III-3 ta; IV-dushanba va chorshanba",
      },
      {
        type: "txt",
        value: "I-shanba; II-juma-shanba; III-4 ta; IV- dushanba va chorshanba",
      },
    ],
    answer: 0,
  },
];

export const mirzoUlugbekEnglish = [
  {
    id: 0,
    question: [{ type: "txt", value: "Have you read today’s newspaper?" }],
    options: [
      { type: "txt", value: "No, I haven’t yet" },
      { type: "txt", value: "No, I didn’t" },
      { type: "txt", value: "Yes, I had" },
      { type: "txt", value: "No, I’m not" },
    ],
    answer: 0,
  },
  {
    id: 1,
    question: [{ type: "txt", value: "Who usually has lunch at school?" }],
    options: [
      { type: "txt", value: "Pupils and teachers have" },
      { type: "txt", value: "Teenagers and teachers have" },
      { type: "txt", value: "Teachers and driver have" },
      { type: "txt", value: "Teachers and children have" },
    ],
    answer: 0,
  },
  {
    id: 2,
    question: [
      { type: "txt", value: "Where can you see this notice?" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/ingliz-tili/img1.png",
      },
    ],
    options: [
      { type: "txt", value: "in the park" },
      { type: "txt", value: "on the bus" },
      { type: "txt", value: "in the library" },
    ],
    answer: 2,
  },
  {
    id: 3,
    question: [
      { type: "txt", value: "What is the meaning of the notice?" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/ingliz-tili/img2.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "only people can enter this building who works here",
      },
      { type: "txt", value: "Anybody can enter this building" },
      { type: "txt", value: "Only employers can" },
    ],
    answer: 0,
  },
  {
    id: 4,
    question: [
      { type: "txt", value: "What are the things you enjoy doing in summer?" },
    ],
    options: [
      { type: "txt", value: "I enjoy skating or skiing in cold weather." },
      { type: "txt", value: "If I’m near it’s bating or swimming." },
      { type: "txt", value: "There weren’t many things to choose" },
      { type: "txt", value: "I prefer singing to dancing" },
    ],
    answer: 1,
  },
  {
    id: 5,
    question: [
      { type: "txt", value: "What do you think „The Three Friends”?" },
    ],
    options: [
      { type: "txt", value: "They’ll come soon." },
      { type: "txt", value: "It’s a very interesting play." },
      { type: "txt", value: "It’s sad news." },
      { type: "txt", value: "They are friends." },
    ],
    answer: 3,
  },
  {
    id: 6,
    question: [{ type: "txt", value: "Who is a sailor?" }],
    options: [
      { type: "txt", value: "A man who makes things out of metal." },
      { type: "txt", value: "A man who works on a ship." },
      { type: "txt", value: "A man who looks after sheep." },
      {
        type: "txt",
        value: "Someone who secretly watches what other people are doing.",
      },
    ],
    answer: 1,
  },
  {
    id: 7,
    question: [{ type: "txt", value: "It is five to nine." }],
    options: [
      { type: "txt", value: "9:55" },
      { type: "txt", value: "9:35" },
      { type: "txt", value: "8:55" },
      { type: "txt", value: "9:05" },
    ],
    answer: 2,
  },
  {
    id: 8,
    question: [
      {
        type: "txt",
        value: "My uncle lives in Mexico City. _ lives in Mexico City.",
      },
    ],
    options: [
      { type: "txt", value: "She" },
      { type: "txt", value: "He" },
      { type: "txt", value: "We" },
      { type: "txt", value: "They" },
    ],
    answer: 1,
  },
  {
    id: 9,
    question: [{ type: "txt", value: "The house ____ four rooms." }],
    options: [
      { type: "txt", value: "have" },
      { type: "txt", value: "is" },
      { type: "txt", value: "has" },
      { type: "txt", value: "are" },
    ],
    answer: 2,
  },
  {
    id: 10,
    question: [
      {
        type: "txt",
        value:
          "- Do you want another cake? - No, thank you. I’ve still got ____.",
      },
    ],
    options: [
      { type: "txt", value: "some" },
      { type: "txt", value: "someone’s" },
      { type: "txt", value: "someones" },
      { type: "txt", value: "ones" },
    ],
    answer: 0,
  },
  {
    id: 11,
    question: [{ type: "txt", value: "- Whose is this? - It’s ___." }],
    options: [
      { type: "txt", value: "yours" },
      { type: "txt", value: "to Mary" },
      { type: "txt", value: "of him" },
      { type: "txt", value: "a mine" },
    ],
    answer: 0,
  },
  {
    id: 12,
    question: [
      { type: "txt", value: "A: *_______ can solve this problem? B: John." },
    ],
    options: [
      { type: "txt", value: "What" },
      { type: "txt", value: "Which" },
      { type: "txt", value: "Where" },
      { type: "txt", value: "Who" },
    ],
    answer: 3,
  },
  {
    id: 13,
    question: [
      { type: "txt", value: "Where can you see this notice." },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/ingliz-tili/img3.png",
      },
    ],
    options: [
      { type: "txt", value: "at the lake" },
      { type: "txt", value: "in the hospital" },
      { type: "txt", value: "in the restaurant" },
    ],
    answer: 0,
  },
  {
    id: 14,
    question: [
      { type: "txt", value: "What is the meaning of this notice" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/ingliz-tili/img4.png",
      },
    ],
    options: [
      { type: "txt", value: "do not drink and eat" },
      { type: "txt", value: "no mobile phone" },
      { type: "txt", value: "do not take a picture" },
    ],
    answer: 2,
  },
  {
    id: 15,
    question: [{ type: "txt", value: "This is my friend. His name’s ___." }],
    options: [
      { type: "txt", value: "Anna" },
      { type: "txt", value: "Linda" },
      { type: "txt", value: "Jack" },
      { type: "txt", value: "All  above" },
    ],
    answer: 2,
  },
  {
    id: 16,
    question: [{ type: "txt", value: "Find the opposite word. EASY - _____" }],
    options: [
      { type: "txt", value: "cold" },
      { type: "txt", value: "cheap" },
      { type: "txt", value: "difficult" },
      { type: "txt", value: "difference" },
    ],
    answer: 2,
  },
  {
    id: 17,
    question: [{ type: "txt", value: "Lovely - _" }],
    options: [
      { type: "txt", value: "old" },
      { type: "txt", value: "horrible" },
      { type: "txt", value: "expensive" },
      { type: "txt", value: "quick" },
    ],
    answer: 1,
  },
  {
    id: 18,
    question: [
      {
        type: "txt",
        value:
          "Choose the appropriate answers for the following questions. Who had a dictation last week?",
      },
    ],
    options: [
      { type: "txt", value: "My friend do" },
      { type: "txt", value: "I have" },
      { type: "txt", value: "We did" },
      { type: "txt", value: "You have had" },
    ],
    answer: 3,
  },
  {
    id: 19,
    question: [{ type: "txt", value: "Where is your dog? I don’t see it in." }],
    options: [
      { type: "txt", value: "Our cat is very clever." },
      { type: "txt", value: "It’s ok. My sister took him out for a walk." },
      { type: "txt", value: "Yes. We’ve bought a new lead for the dog." },
      { type: "txt", value: "Dogs don’t eat much." },
    ],
    answer: 2,
  },
  {
    id: 20,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question. Dinara is going to a party. Sshe has to dress nicely . she puts on a pearl necklace . She puts red lipstick on her lips . She wears a long , white dress. She put on sparkly shoes . She puts diamond earring on her ears . She puts a ruby ring on her finger . She is ready to leave the house. Where does Dinara go to?",
      },
    ],
    options: [
      { type: "txt", value: "park" },
      { type: "txt", value: "park station" },
      { type: "txt", value: "party" },
      { type: "txt", value: "port" },
    ],
    answer: 2,
  },
  {
    id: 21,
    question: [
      {
        type: "txt",
        value:
          "Dinara is going to a party. Sshe has to dress nicely . she puts on a pearl necklace . She puts red lipstick on her lips . She wears a long , white dress. She put on sparkly shoes . She puts diamond earring on her ears . She puts a ruby ring on her finger . She is ready to leave the house.        What color does she use on her lipstick?",
      },
    ],
    options: [
      { type: "txt", value: "Red" },
      { type: "txt", value: "White" },
      { type: "txt", value: "Black" },
      { type: "txt", value: "Without color" },
    ],
    answer: 0,
  },
  {
    id: 22,
    question: [
      {
        type: "txt",
        value:
          "I live in an apartment near the city. I have three sisters and two brothers. I am average. My mother is a teacher at school. My father is a policeman. He works different place according to situation. My two Brothers are very clever. Because they study at presidential school. Before entering the school, they studied very hard. Sometimes they do their homework until morning. My two sisters are diploma holder at University. One of them studies at Inha University. Another one goes to Westminster University. They like their study very much. My little sister does not study anywhere. Because she is very little. I love my family. My family important to me. It is because we try to a lot of things together. My two brother and I prefer going to swimming in river. It is because water of river is cool every time. My sisters love talking with their friends when they have free time. Saturday or Sunday we go to cinema with our family. I love my family.            My mother is __.",
      },
    ],
    options: [
      { type: "txt", value: "doctor" },
      { type: "txt", value: "waitress" },
      { type: "txt", value: "teacher" },
      { type: "txt", value: "student" },
    ],
    answer: 2,
  },
  {
    id: 23,
    question: [
      {
        type: "txt",
        value:
          "I live in an apartment near the city. I have three sisters and two brothers. I am average. My mother is a teacher at school. My father is a policeman. He works different place according to situation. My two Brothers are very clever. Because they study at presidential school. Before entering the school, they studied very hard. Sometimes they do their homework until morning. My two sisters are diploma holder at University. One of them studies at Inha University. Another one goes to Westminster University. They like their study very much. My little sister does not study anywhere. Because she is very little. I love my family. My family important to me. It is because we try to a lot of things together. My two brother and I prefer going to swimming in river. It is because water of river is cool every time. My sisters love talking with their friends when they have free time. Saturday or Sunday we go to cinema with our family. I love my family.         My apartment is near the __.",
      },
    ],
    options: [
      { type: "txt", value: "city" },
      { type: "txt", value: "monastery" },
      { type: "txt", value: "mountains" },
      { type: "txt", value: "Tashkent" },
    ],
    answer: 0,
  },
  {
    id: 24,
    question: [
      {
        type: "txt",
        value:
          "I live in an apartment near the city. I have three sisters and two brothers. I am average. My mother is a teacher at school. My father is a policeman. He works different place according to situation. My two Brothers are very clever. Because they study at presidential school. Before entering the school, they studied very hard. Sometimes they do their homework until morning. My two sisters are diploma holder at University. One of them studies at Inha University. Another one goes to Westminster University. They like their study very much. My little sister does not study anywhere. Because she is very little. I love my family. My family important to me. It is because we try to a lot of things together. My two brother and I prefer going to swimming in river. It is because water of river is cool every time. My sisters love talking with their friends when they have free time. Saturday or Sunday we go to cinema with our family. I love my family.      How many people are there in my family",
      },
    ],
    options: [
      { type: "txt", value: "9" },
      { type: "txt", value: "7" },
      { type: "txt", value: "5" },
      { type: "txt", value: "8" },
    ],
    answer: 3,
  },
  {
    id: 25,
    question: [
      {
        type: "txt",
        value:
          "I live in an apartment near the city. I have three sisters and two brothers. I am average. My mother is a teacher at school. My father is a policeman. He works different place according to situation. My two Brothers are very clever. Because they study at presidential school. Before entering the school, they studied very hard. Sometimes they do their homework until morning. My two sisters are diploma holder at University. One of them studies at Inha University. Another one goes to Westminster University. They like their study very much. My little sister does not study anywhere. Because she is very little. I love my family. My family important to me. It is because we try to a lot of things together. My two brother and I prefer going to swimming in river. It is because water of river is cool every time. My sisters love talking with their friends when they have free time. Saturday or Sunday we go to cinema with our family. I love my family.         Where does my little sister study?",
      },
    ],
    options: [
      { type: "txt", value: "at Westminster" },
      { type: "txt", value: "INHA" },
      { type: "txt", value: "Nowhere" },
      { type: "txt", value: "At school" },
    ],
    answer: 2,
  },
  {
    id: 26,
    question: [
      {
        type: "txt",
        value:
          "I live in an apartment near the city. I have three sisters and two brothers. I am average. My mother is a teacher at school. My father is a policeman. He works different place according to situation. My two Brothers are very clever. Because they study at presidential school. Before entering the school, they studied very hard. Sometimes they do their homework until morning. My two sisters are diploma holder at University. One of them studies at Inha University. Another one goes to Westminster University. They like their study very much. My little sister does not study anywhere. Because she is very little. I love my family. My family important to me. It is because we try to a lot of things together. My two brother and I prefer going to swimming in river. It is because water of river is cool every time. My sisters love talking with their friends when they have free time. Saturday or Sunday we go to cinema with our family. I love my family.     How many people like to go swimming?",
      },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
    ],
    answer: 1,
  },
  {
    id: 27,
    question: [
      {
        type: "txt",
        value:
          "I live in an apartment near the city. I have three sisters and two brothers. I am average. My mother is a teacher at school. My father is a policeman. He works different place according to situation. My two Brothers are very clever. Because they study at presidential school. Before entering the school, they studied very hard. Sometimes they do their homework until morning. My two sisters are diploma holder at University. One of them studies at Inha University. Another one goes to Westminster University. They like their study very much. My little sister does not study anywhere. Because she is very little. I love my family. My family important to me. It is because we try to a lot of things together. My two brother and I prefer going to swimming in river. It is because water of river is cool every time. My sisters love talking with their friends when they have free time. Saturday or Sunday we go to cinema with our family. I love my family.       Why his family important to him?",
      },
    ],
    options: [
      { type: "txt", value: "Because, they’re good" },
      { type: "txt", value: "Because, they never fight each other" },
      { type: "txt", value: "Because they do everything together" },
      { type: "txt", value: "that is not supported" },
    ],
    answer: 2,
  },
  {
    id: 28,
    question: [
      { type: "txt", value: "Who practises law and has studied law?" },
    ],
    options: [
      { type: "txt", value: "a doctor" },
      { type: "txt", value: "a chemist" },
      { type: "txt", value: "a teacher" },
      { type: "txt", value: "a lawyer" },
    ],
    answer: 3,
  },
  {
    id: 29,
    question: [{ type: "txt", value: "Who fell ill?" }],
    options: [
      { type: "txt", value: "My mother is" },
      { type: "txt", value: "I go" },
      { type: "txt", value: "My son does" },
      { type: "txt", value: "Ann did" },
    ],
    answer: 0,
  },
  {
    id: 30,
    question: [{ type: "txt", value: "Who is fond of swimming?" }],
    options: [
      { type: "txt", value: "I was" },
      { type: "txt", value: "She was" },
      { type: "txt", value: "I’m" },
      { type: "txt", value: "He was" },
    ],
    answer: 2,
  },
  {
    id: 31,
    question: [{ type: "txt", value: "Tarjima qiling: I collect trains" }],
    options: [
      { type: "txt", value: "Men oyoq kiyimlarni olaman" },
      { type: "txt", value: "Men shug’ullanaman" },
      { type: "txt", value: "Men poyezdlarni toplayman" },
      { type: "txt", value: "Men poyezdni kutaman" },
    ],
    answer: 2,
  },
  {
    id: 32,
    question: [{ type: "txt", value: "Do you like coffee without sugar?" }],
    options: [
      { type: "txt", value: "I like coffee." },
      { type: "txt", value: "I like coffee with sugar." },
      { type: "txt", value: "They don’t like coffee with sugar." },
      { type: "txt", value: "You like coffee with sugar." },
    ],
    answer: 1,
  },
  {
    id: 33,
    question: [
      { type: "txt", value: "What’s the meaning of the Notice." },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/ingliz-tili/img5.png",
      },
    ],
    options: [
      { type: "txt", value: "if you see this notice, you must not move." },
      { type: "txt", value: "if you see this notice, you may stop." },
      { type: "txt", value: "if you see this notice, you can move." },
    ],
    answer: 0,
  },
  {
    id: 34,
    question: [
      { type: "txt", value: "What’s the meaning of the notice." },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/ingliz-tili/img6.png",
      },
    ],
    options: [
      { type: "txt", value: "There is away on the left." },
      { type: "txt", value: "There is not away on the left." },
      { type: "txt", value: "You can turn left." },
    ],
    answer: 1,
  },
  {
    id: 35,
    question: [{ type: "txt", value: "After ____ hour, we can go to home." }],
    options: [
      { type: "txt", value: "a" },
      { type: "txt", value: "are" },
      { type: "txt", value: "an" },
      { type: "txt", value: "is" },
    ],
    answer: 2,
  },
  {
    id: 36,
    question: [{ type: "txt", value: "These pensils are (you) __" }],
    options: [
      { type: "txt", value: "yours" },
      { type: "txt", value: "mine" },
      { type: "txt", value: "ours" },
      { type: "txt", value: "hers" },
    ],
    answer: 0,
  },
  {
    id: 37,
    question: [{ type: "txt", value: "Excuse me, sir." }],
    options: [
      { type: "txt", value: "Yes, What can I do for you?" },
      { type: "txt", value: "Where are you from?" },
      { type: "txt", value: "Are you from Berlin." },
      { type: "txt", value: "Are you crazy" },
    ],
    answer: 0,
  },
  {
    id: 38,
    question: [{ type: "txt", value: "Coffee, sir." }],
    options: [
      { type: "txt", value: "Don’t mention it" },
      { type: "txt", value: "Yes, please" },
      { type: "txt", value: "That’s OK" },
      { type: "txt", value: "Oh, I’m sorry" },
    ],
    answer: 1,
  },
  {
    id: 39,
    question: [
      { type: "txt", value: "Where did you stay when you went to the South?" },
    ],
    options: [
      { type: "txt", value: "To the south" },
      { type: "txt", value: "I stayed there a month" },
      { type: "txt", value: "at the hotel" },
      { type: "txt", value: "for two weeks" },
    ],
    answer: 2,
  },
  {
    id: 40,
    question: [
      { type: "txt", value: "How long does it take you to get to school?" },
    ],
    options: [
      { type: "txt", value: "Oh-yes-I live quite close" },
      { type: "txt", value: "I get to school by bus" },
      { type: "txt", value: "I don’t take any meal to school" },
      { type: "txt", value: "It takes me twenty minutes to get to school" },
    ],
    answer: 3,
  },
  {
    id: 41,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question.   Basketball is about teamwork. Teammates must able to pass the ball. Robert is far from the hoop. He passes the ball to Edward catches it. He is far from the hoop, too. He passes the ball to Mark. Mark catches it. Mark is close to the hoop. He throws the ball in. it goes in the net!       What is about teamwork?",
      },
    ],
    options: [
      { type: "txt", value: "tennis" },
      { type: "txt", value: "teammates" },
      { type: "txt", value: "ball" },
      { type: "txt", value: "basketball" },
    ],
    answer: 3,
  },
  {
    id: 42,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question.   Basketball is about teamwork. Teammates must able to pass the ball. Robert is far from the hoop. He passes the ball to Edward catches it. He is far from the hoop, too. He passes the ball to Mark. Mark catches it. Mark is close to the hoop. He throws the ball in. it goes in the net!       Teammates ____ pass the ball.",
      },
    ],
    options: [
      { type: "txt", value: "cannot" },
      { type: "txt", value: "catch" },
      { type: "txt", value: "should" },
      { type: "txt", value: "feel" },
    ],
    answer: 2,
  },
  {
    id: 43,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question.   Basketball is about teamwork. Teammates must able to pass the ball. Robert is far from the hoop. He passes the ball to Edward catches it. He is far from the hoop, too. He passes the ball to Mark. Mark catches it. Mark is close to the hoop. He throws the ball in. it goes in the net!       Who is far from the hoop?",
      },
    ],
    options: [
      { type: "txt", value: "Edward" },
      { type: "txt", value: "Mark" },
      { type: "txt", value: "Robert" },
      { type: "txt", value: "Both a and c" },
    ],
    answer: 2,
  },
  {
    id: 44,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question.    Basketball is about teamwork. Teammates must able to pass the ball. Robert is far from the hoop. He passes the ball to Edward catches it. He is far from the hoop, too. He passes the ball to Mark. Mark catches it. Mark is close to the hoop. He throws the ball in. it goes in the net!        Who catches the ball?",
      },
    ],
    options: [
      { type: "txt", value: "Marco" },
      { type: "txt", value: "Edward" },
      { type: "txt", value: "Robert" },
      { type: "txt", value: "Nobody" },
    ],
    answer: 1,
  },
  {
    id: 45,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question.      He plays basketball. He wants to play for the Los Angeles Lakers. He goes to the basketball court every day. He practices his free throws. He stands before the free throw line. He throws the basketball in the net. He misses. He tries again. It goes in the net this time. He practices for two more hours.       In the name of the Los Angeles Lakers, He wants to __.",
      },
    ],
    options: [
      { type: "txt", value: "sing" },
      { type: "txt", value: "go" },
      { type: "txt", value: "sleep" },
      { type: "txt", value: "play" },
    ],
    answer: 3,
  },
  {
    id: 46,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question.      He plays basketball. He wants to play for the Los Angeles Lakers. He goes to the basketball court every day. He practices his free throws. He stands before the free throw line. He throws the basketball in the net. He misses. He tries again. It goes in the net this time. He practices for two more hours.       How often do they go to basketball court?",
      },
    ],
    options: [
      { type: "txt", value: "Sometimes" },
      { type: "txt", value: "Often" },
      { type: "txt", value: "Every day" },
      { type: "txt", value: "Yesterday" },
    ],
    answer: 2,
  },
  {
    id: 47,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question.      He plays basketball. He wants to play for the Los Angeles Lakers. He goes to the basketball court every day. He practices his free throws. He stands before the free throw line. He throws the basketball in the net. He misses. He tries again. It goes in the net this time. He practices for two more hours.       What does he practise?",
      },
    ],
    options: [
      { type: "txt", value: "Free throws" },
      { type: "txt", value: "Throw line" },
      { type: "txt", value: "The net" },
      { type: "txt", value: "Hours" },
    ],
    answer: 0,
  },
  {
    id: 48,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question.      He plays basketball. He wants to play for the Los Angeles Lakers. He goes to the basketball court every day. He practices his free throws. He stands before the free throw line. He throws the basketball in the net. He misses. He tries again. It goes in the net this time. He practices for two more hours.       Where does he throw the basketball?",
      },
    ],
    options: [
      { type: "txt", value: "Throw line" },
      { type: "txt", value: "Free Throws" },
      { type: "txt", value: "In the net" },
      { type: "txt", value: "Hours" },
    ],
    answer: 2,
  },
  {
    id: 49,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question.      He plays basketball. He wants to play for the Los Angeles Lakers. He goes to the basketball court every day. He practices his free throws. He stands before the free throw line. He throws the basketball in the net. He misses. He tries again. It goes in the net this time. He practices for two more hours.       How time does he practise?",
      },
    ],
    options: [
      { type: "txt", value: "two" },
      { type: "txt", value: "three" },
      { type: "txt", value: "one" },
      { type: "txt", value: "two more" },
    ],
    answer: 3,
  },
];

export const mirzoUlugbekMathUz = [
  {
    id: 0,
    question: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img1.png",
      },
      {
        type: "txt",
        value:
          "Shu B sonining barcha natural boʻluvchilari yigʻindisini toping.",
      },
    ],
    options: [
      { type: "txt", value: "24336" },
      { type: "txt", value: "121780" },
      { type: "txt", value: "28580" },
      { type: "txt", value: "121480" },
    ],
    answer: 0,
  },
  {
    id: 1,
    question: [
      {
        type: "txt",
        value:
          "5 dan 120 gacha barcha natural sonlar ketma-ket yozib chiqilsa, necha xonali son hosil bo‘ladi?",
      },
    ],
    options: [
      { type: "txt", value: "249" },
      { type: "txt", value: "248" },
      { type: "txt", value: "247" },
      { type: "txt", value: "245" },
    ],
    answer: 1,
  },
  {
    id: 2,
    question: [
      {
        type: "txt",
        value:
          "Quyidagi sonlar nurida berilgan A; B; C va D nuqtalar koordinatasini yozing.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img2.png",
      },
    ],
    options: [
      { type: "txt", value: "A(60); B(120); C(210); D(390)" },
      { type: "txt", value: "A(60); B(120); C(190); D(360)" },
      { type: "txt", value: "A(60); B(120); C(190); D(310)" },
      { type: "txt", value: "A(60); B(100); C(200); D(360)" },
    ],
    answer: 0,
  },
  {
    id: 3,
    question: [
      {
        type: "txt",
        value: "28 − 𝑏 = 24 boʻlsa, (3𝑏 + 5) + (9 − 𝑏) ifoda qiymatini toping.",
      },
    ],
    options: [
      { type: "txt", value: "20" },
      { type: "txt", value: "21" },
      { type: "txt", value: "24" },
      { type: "txt", value: "22" },
    ],
    answer: 3,
  },
  {
    id: 4,
    question: [
      {
        type: "txt",
        value:
          "Uchburchakning bir uchidan qarshisidagi tomonga tushirilgan 8 cm li kesma uni perimetrlari 24 va 30 cm bo‘lgan ikkita uchburchakka ajratadi. Berilgan uchburchakning perimetrini toping.",
      },
    ],
    options: [
      { type: "txt", value: "36 cm" },
      { type: "txt", value: "44 cm" },
      { type: "txt", value: "38 cm" },
      { type: "txt", value: "40 cm" },
    ],
    answer: 2,
  },
  {
    id: 5,
    question: [
      { type: "txt", value: "a raqamining qanday qiymatlarida" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img3.png",
      },
      { type: "txt", value: "son 45 ga bo‘linadi?" },
    ],
    options: [
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "7" },
      { type: "txt", value: "3" },
    ],
    answer: 1,
  },
  {
    id: 6,
    question: [
      {
        type: "txt",
        value: "50 < 𝑥 < 65 qo‘sh tengsizlikning barcha tub yechimlari nechta?",
      },
    ],
    options: [
      { type: "txt", value: "2 ta" },
      { type: "txt", value: "3 ta" },
      { type: "txt", value: "4 ta" },
      { type: "txt", value: "5 ta" },
    ],
    answer: 1,
  },
  {
    id: 7,
    question: [
      {
        type: "txt",
        value:
          "Ikki sonning koʻpaytmasi 4898880 ga teng. Agar bu sonlar EKUBi 108 ga teng boʻlsa, shu ikki sonning EKUKini toping.",
      },
    ],
    options: [
      { type: "txt", value: "90270" },
      { type: "txt", value: "113400" },
      { type: "txt", value: "90720" },
      { type: "txt", value: "45360" },
    ],
    answer: 3,
  },
  {
    id: 8,
    question: [
      { type: "txt", value: "Tenglamani yeching:" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img4.png",
      },
    ],
    options: [
      { type: "txt", value: "-8,5" },
      { type: "txt", value: "8,5" },
      { type: "txt", value: "4,5" },
      { type: "txt", value: "-2,5" },
    ],
    answer: 1,
  },
  {
    id: 9,
    question: [
      {
        type: "txt",
        value:
          "21 nafar o‘quvchidan 2 nafarini kinoga borish uchun tanlab olishmoqchi. Buni necha usulda amalga oshirish mumkin?",
      },
    ],
    options: [
      { type: "txt", value: "210" },
      { type: "txt", value: "420" },
      { type: "txt", value: "105" },
      { type: "txt", value: "42" },
    ],
    answer: 0,
  },
  {
    id: 10,
    question: [
      {
        type: "txt",
        value:
          "Tomoninig uzunligi 4 m boʻlgan kvadrat yuzi 25 cm² da teng boʻlgan kvadratchalarga boʻlindi va bu kvadratchalar bir qator qilib qoʻyildi. Hosil boʻlgan qator uzunligini toping.",
      },
    ],
    options: [
      { type: "txt", value: "80 m" },
      { type: "txt", value: "320 m" },
      { type: "txt", value: "160 m" },
      { type: "txt", value: "64 m" },
    ],
    answer: 1,
  },
  {
    id: 11,
    question: [
      {
        type: "txt",
        value:
          "700 kg tuz va suv aralashmasining 24 % i tuzdan iborat. Bu aralashmadagi tuz 33,5 % bo‘lishi uchun unga qancha tuz qoʻshish kerak?",
      },
    ],
    options: [
      { type: "txt", value: "100 kg" },
      { type: "txt", value: "26 kg" },
      { type: "txt", value: "24,31 kg" },
      { type: "txt", value: "86,13 kg" },
    ],
    answer: 0,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value:
          "Qayiqning daryo oqimi bo‘yicha tezligi 32 km/h, oqimga qarshi tezligi esa 22 km/h boʻlsa, bu daryo oqimi tezligini toping.",
      },
    ],
    options: [
      { type: "txt", value: "7,5 km/h" },
      { type: "txt", value: "10 km/h" },
      { type: "txt", value: "5 km/h" },
      { type: "txt", value: "6 km/h" },
    ],
    answer: 2,
  },
  {
    id: 13,
    question: [
      {
        type: "txt",
        value:
          "Detal o‘lchami 1:12 masshtabli chizmada 16 cm bo‘lsa, 1:8 masshtabli chizmada necha cm bo‘ladi?",
      },
    ],
    options: [
      { type: "txt", value: "12 cm" },
      { type: "txt", value: "32 cm" },
      { type: "txt", value: "8 cm" },
      { type: "txt", value: "24 cm" },
    ],
    answer: 3,
  },
  {
    id: 14,
    question: [
      {
        type: "txt",
        value:
          "Bir quvur bo‘sh hovuzni 10 soatda, ikkinchisi bilan birgalikda 8 soatda to‘ldiradi. Ikkinchi quvurning yolg‘iz o‘zi ochilsa, bo‘sh hovuz qancha vaqtda to‘ladi?",
      },
    ],
    options: [
      { type: "txt", value: "24 soatda" },
      { type: "txt", value: "20 soatda" },
      { type: "txt", value: "40 soatda" },
      { type: "txt", value: "30 soatda" },
    ],
    answer: 2,
  },
  {
    id: 15,
    question: [
      {
        type: "txt",
        value:
          "Quyidagi tasdiqlarning to‘g‘ri yoki noto‘g‘ri ekanligi ketma-ketligini to‘g‘ri keltirilgan javob variantini aniqlang.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img5.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "1- to‘g‘ri, 2 - noto‘g‘ri, 3 - noto‘g‘ri, 4 - noto‘g‘ri",
      },
      {
        type: "txt",
        value: "1- to‘g‘ri, 2 - to‘g‘ri, 3 - noto‘g‘ri, 4 - to‘g‘ri",
      },
      {
        type: "txt",
        value: "1 - noto‘g‘ri, 2 - noto‘g‘ri, 3 - to‘g‘ri, 4 - noto‘g‘ri",
      },
      {
        type: "txt",
        value: "1 - to‘g‘ri, 2 - to‘g‘ri, 3 - to‘g‘ri, 4 - noto‘g‘ri",
      },
    ],
    answer: 0,
  },
  {
    id: 16,
    question: [
      {
        type: "txt",
        value: "To‘g‘ri to‘rtburchakning perimetri 56 cm ga, bo‘yi esa enining",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img6.png",
      },
      {
        type: "txt",
        value: "qismiga teng. Shu to‘g‘ri to‘rtburchakning yuzini hisoblang.",
      },
    ],
    options: [
      { type: "txt", value: "𝑆 = 360 𝑐𝑚²" },
      { type: "txt", value: "𝑆 = 180 𝑐𝑚²" },
      { type: "txt", value: "𝑆 = 720 𝑐𝑚²" },
      { type: "txt", value: "𝑆 = 270 𝑐𝑚²" },
    ],
    answer: 1,
  },
  {
    id: 17,
    question: [
      { type: "txt", value: "Hisoblang:" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img7.png",
      },
    ],
    options: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img8.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img9.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img10.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img11.png",
      },
    ],
    answer: 0,
  },
  {
    id: 18,
    question: [
      {
        type: "txt",
        value:
          "Telefon stansiyasi mijozlarining uy telefon raqamlari 7 xonali sonlardan iborat va 945-0X-XX ko‘rinishida. Telefon raqami turli raqamlardan iborat (bir xil raqam qatnashmagan) mijozlar sonini aniqlang.",
      },
    ],
    options: [
      { type: "txt", value: "720 ta" },
      { type: "txt", value: "120 ta" },
      { type: "txt", value: "49 ta" },
      { type: "txt", value: "1638 ta" },
    ],
    answer: 1,
  },
  {
    id: 19,
    question: [
      {
        type: "txt",
        value:
          "Ketma – ketlikdan qonuniyatni aniqlab, so‘roq belgisi o‘nidagi sonni toping.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img12.png",
      },
    ],
    options: [
      { type: "txt", value: "12" },
      { type: "txt", value: "19" },
      { type: "txt", value: "16" },
      { type: "txt", value: "15" },
    ],
    answer: 0,
  },
  {
    id: 20,
    question: [
      {
        type: "txt",
        value:
          "Ketma – ketlikdan qonuniyatni aniqlab, so‘roq belgisi o‘nidagi sonni toping.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img13.png",
      },
    ],
    options: [
      { type: "txt", value: "604" },
      { type: "txt", value: "36" },
      { type: "txt", value: "184" },
      { type: "txt", value: "504" },
    ],
    answer: 3,
  },
  {
    id: 21,
    question: [
      {
        type: "txt",
        value: "Qonuniyatni aniqlab, so‘roq belgisi o‘rnidagi sonni toping.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img14.png",
      },
    ],
    options: [
      { type: "txt", value: "36" },
      { type: "txt", value: "108" },
      { type: "txt", value: "64" },
      { type: "txt", value: "92" },
    ],
    answer: 2,
  },
  {
    id: 22,
    question: [
      {
        type: "txt",
        value: "Qonuniyatni aniqlab, so‘roq belgisi o‘rnidagi sonni toping.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img15.png",
      },
    ],
    options: [
      { type: "txt", value: "98" },
      { type: "txt", value: "108" },
      { type: "txt", value: "72" },
      { type: "txt", value: "48" },
    ],
    answer: 1,
  },
  {
    id: 23,
    question: [
      {
        type: "txt",
        value: "Qonuniyatni aniqlab, so‘roq belgisi o‘rnidagi sonni toping.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img16.png",
      },
    ],
    options: [
      { type: "txt", value: "78" },
      { type: "txt", value: "84" },
      { type: "txt", value: "96" },
      { type: "txt", value: "86" },
    ],
    answer: 0,
  },
  {
    id: 24,
    question: [
      {
        type: "txt",
        value:
          "Ushbu ketma-ketlikning keyingi hadini toping: 2, 3, 5, 6, 8, 9, … .",
      },
    ],
    options: [
      { type: "txt", value: "10" },
      { type: "txt", value: "11" },
      { type: "txt", value: "12" },
      { type: "txt", value: "13" },
    ],
    answer: 1,
  },
  {
    id: 25,
    question: [
      {
        type: "txt",
        value:
          "Qonuniyatni topib x+y ning qiymatini toping: 1 , 2 , 4 , 8 , x , y ",
      },
    ],
    options: [
      { type: "txt", value: "56" },
      { type: "txt", value: "16" },
      { type: "txt", value: "32" },
      { type: "txt", value: "48" },
    ],
    answer: 3,
  },
  {
    id: 26,
    question: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img17.png",
      },
      { type: "txt", value: "x=?" },
    ],
    options: [
      { type: "txt", value: "32" },
      { type: "txt", value: "24" },
      { type: "txt", value: "36" },
      { type: "txt", value: "42" },
      { type: "txt", value: "20" },
    ],
    answer: 1,
  },
  {
    id: 27,
    question: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img18.png",
      },
      { type: "txt", value: "X-Y=?" },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "4" },
      { type: "txt", value: "6" },
      { type: "txt", value: "12" },
      { type: "txt", value: "10" },
    ],
    answer: 1,
  },
  {
    id: 28,
    question: [
      { type: "txt", value: "Keyingi shaklni toping:" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img19.png",
      },
    ],
    options: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img20.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img21.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img22.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img23.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img24.png",
      },
    ],
    answer: 0,
  },
  {
    id: 29,
    question: [
      { type: "txt", value: "Qonuniyatni topib, ? o’rnidagi shaklni toping." },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img25.png",
      },
    ],
    options: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img26.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img27.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img28.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img29.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img30.png",
      },
    ],
    answer: 3,
  },
  {
    id: 30,
    question: [
      { type: "txt", value: "Keyingi shaklni toping:" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img31.png",
      },
    ],
    options: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img32.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img33.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img34.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img35.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img36.png",
      },
    ],
    answer: 1,
  },
  {
    id: 31,
    question: [
      { type: "txt", value: "Qonuniyatni topib, ? o’rnidagi sonni toping." },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img37.png",
      },
    ],
    options: [
      { type: "txt", value: "6" },
      { type: "txt", value: "7" },
      { type: "txt", value: "8" },
      { type: "txt", value: "10" },
      { type: "txt", value: "11" },
    ],
    answer: 2,
  },
  {
    id: 32,
    question: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img38.png",
      },
    ],
    options: [
      { type: "txt", value: "20" },
      { type: "txt", value: "30" },
      { type: "txt", value: "45" },
      { type: "txt", value: "35" },
      { type: "txt", value: "28" },
    ],
    answer: 1,
  },
  {
    id: 33,
    question: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img39.png",
      },
    ],
    options: [
      { type: "txt", value: "10" },
      { type: "txt", value: "19" },
      { type: "txt", value: "12" },
      { type: "txt", value: "4" },
      { type: "txt", value: "6" },
    ],
    answer: 1,
  },
  {
    id: 34,
    question: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img40.png",
      },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
    ],
    answer: 3,
  },

  {
    id: 35,
    question: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img41.png",
      },
    ],
    options: [
      { type: "txt", value: "4" },
      { type: "txt", value: "6" },
      { type: "txt", value: "8" },
      { type: "txt", value: "12" },
      { type: "txt", value: "16" },
    ],
    answer: 4,
  },
  {
    id: 36,
    question: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img42.png",
      },
    ],
    options: [
      { type: "txt", value: "42" },
      { type: "txt", value: "45" },
      { type: "txt", value: "48" },
      { type: "txt", value: "56" },
      { type: "txt", value: "64" },
    ],
    answer: 4,
  },
  {
    id: 37,
    question: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img43.png",
      },
    ],
    options: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img44.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img45.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img46.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img47.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img48.png",
      },
    ],
    answer: 4,
  },
  {
    id: 38,
    question: [
      {
        type: "txt",
        value:
          "Qonuniyatni aniqlagan holda, berilgan ma’lumotlardan foydalanib x ni toping.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img49.png",
      },
    ],
    options: [
      { type: "txt", value: "42" },
      { type: "txt", value: "60" },
      { type: "txt", value: "72" },
      { type: "txt", value: "69" },
    ],
    answer: 3,
  },
  {
    id: 39,
    question: [
      {
        type: "txt",
        value:
          "Qonuniyatni aniqlagan holda, berilgan ma’lumotlardan foydalanib x ni toping.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img50.png",
      },
    ],
    options: [
      { type: "txt", value: "72" },
      { type: "txt", value: "65" },
      { type: "txt", value: "61" },
      { type: "txt", value: "69" },
    ],
    answer: 3,
  },
  {
    id: 40,
    question: [
      {
        type: "txt",
        value: "Keyingi soat qaysi vaqtni ko’rsatishini aniqlang.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img51.png",
      },
    ],
    options: [
      { type: "txt", value: "4:50" },
      { type: "txt", value: "05:00" },
      { type: "txt", value: "05:08 " },
      { type: "txt", value: "05:10" },
      { type: "txt", value: "05:13" },
    ],
    answer: 0,
  },
  {
    id: 41,
    question: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img52.png",
      },
      {
        type: "txt",
        value:
          "1-shaklga yana qanday shakl qo’shilsa rasmdagi parallelepiped hosil bo’ladi?",
      },
    ],
    options: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img53.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img54.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img55.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img56.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img57.png",
      },
    ],
    answer: 0,
  },
  {
    id: 42,
    question: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img58.png",
      },
    ],
    options: [
      { type: "txt", value: "20" },
      { type: "txt", value: "30" },
      { type: "txt", value: "45" },
      { type: "txt", value: "46" },
      { type: "txt", value: "54" },
    ],
    answer: 4,
  },
];

export const mirzoUlugbekMathRu = [
  {
    id: 0,
    question: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img1.png",
      },
      {
        type: "txt",
        value: "Найдите сумму всех натуральных делителей числа B.",
      },
    ],
    options: [
      { type: "txt", value: "24336" },
      { type: "txt", value: "121780" },
      { type: "txt", value: "28580" },
      { type: "txt", value: "121480" },
    ],
    answer: 0,
  },
  {
    id: 1,
    question: [
      {
        type: "txt",
        value:
          "Сколько значащих чисел образуется, если все натуральные числа от 5 до 120 записаны последовательно?",
      },
    ],
    options: [
      { type: "txt", value: "249" },
      { type: "txt", value: "248" },
      { type: "txt", value: "247" },
      { type: "txt", value: "245" },
    ],
    answer: 1,
  },
  {
    id: 2,
    question: [
      {
        type: "txt",
        value:
          "Запишите координаты точек A; B; C и D, заданные в луче следующих чисел",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img2.png",
      },
    ],
    options: [
      { type: "txt", value: "A(60); B(120); C(210); D(390)" },
      { type: "txt", value: "A(60); B(120); C(190); D(360)" },
      { type: "txt", value: "A(60); B(120); C(190); D(310)" },
      { type: "txt", value: "A(60); B(100); C(200); D(360)" },
    ],
    answer: 0,
  },
  {
    id: 3,
    question: [
      {
        type: "txt",
        value:
          "Если 28 - 𝑏 = 24, то найдите значение выражения (3𝑏 + 5) + (9 − 𝑏).",
      },
    ],
    options: [
      { type: "txt", value: "20" },
      { type: "txt", value: "21" },
      { type: "txt", value: "24" },
      { type: "txt", value: "22" },
    ],
    answer: 3,
  },
  {
    id: 4,
    question: [
      {
        type: "txt",
        value:
          "Длина отрезка равна 8 см, проведенное от одного вершины треугольника к противоположной стороне, делит его на два треугольника с периметрами 24 и 30 см. Найдите периметр данного треугольника.",
      },
    ],
    options: [
      { type: "txt", value: "36 cm" },
      { type: "txt", value: "44 cm" },
      { type: "txt", value: "38 cm" },
      { type: "txt", value: "40 cm" },
    ],
    answer: 2,
  },
  {
    id: 5,
    question: [
      { type: "txt", value: "При каких значениях a число" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img3.png",
      },
      { type: "txt", value: "делится на 45?" },
    ],
    options: [
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "7" },
      { type: "txt", value: "3" },
    ],
    answer: 1,
  },
  {
    id: 6,
    question: [
      {
        type: "txt",
        value:
          "50 <х< 65 сколько существует простых решений двойного неравенства?",
      },
    ],
    options: [
      { type: "txt", value: "2 ta" },
      { type: "txt", value: "3 ta" },
      { type: "txt", value: "4 ta" },
      { type: "txt", value: "5 ta" },
    ],
    answer: 1,
  },
  {
    id: 7,
    question: [
      {
        type: "txt",
        value:
          "Произведение двух чисел равно 4898880. Если НОД этих чисел равно 108, найдите НОК этих двух чисел.",
      },
    ],
    options: [
      { type: "txt", value: "90270" },
      { type: "txt", value: "113400" },
      { type: "txt", value: "90720" },
      { type: "txt", value: "45360" },
    ],
    answer: 3,
  },
  {
    id: 8,
    question: [
      { type: "txt", value: "Решите уравнение:" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img4.png",
      },
    ],
    options: [
      { type: "txt", value: "-8,5" },
      { type: "txt", value: "8,5" },
      { type: "txt", value: "4,5" },
      { type: "txt", value: "-2,5" },
    ],
    answer: 1,
  },
  {
    id: 9,
    question: [
      {
        type: "txt",
        value:
          "2 из 21 студента собираются выбрать, чтобы пойти в кино. Сколько способов это можно сделать?",
      },
    ],
    options: [
      { type: "txt", value: "210" },
      { type: "txt", value: "420" },
      { type: "txt", value: "105" },
      { type: "txt", value: "42" },
    ],
    answer: 0,
  },
  {
    id: 10,
    question: [
      {
        type: "txt",
        value:
          "Квадрат со стороной 4 м был разделен на квадраты, площадь которых равнялась 25 см², и эти квадраты были помещены в ряд. Найдите длину полученного ряда.",
      },
    ],
    options: [
      { type: "txt", value: "80 m" },
      { type: "txt", value: "320 m" },
      { type: "txt", value: "160 m" },
      { type: "txt", value: "64 m" },
    ],
    answer: 1,
  },
  {
    id: 11,
    question: [
      {
        type: "txt",
        value:
          "В 700 кг смеси 24% составляет соли. Сколько кг соли нужно добавить, чтобы соль в этой смеси составляла 33,5%?",
      },
    ],
    options: [
      { type: "txt", value: "100 кг" },
      { type: "txt", value: "26 кг" },
      { type: "txt", value: "24,31 кг" },
      { type: "txt", value: "86,13 кг" },
    ],
    answer: 0,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value:
          "Найдите скорость течения реки, если лодка движется со скоростью 32 км/ч по течению и 22 км/ч против течения.",
      },
    ],
    options: [
      { type: "txt", value: "7,5 км/ч" },
      { type: "txt", value: "10 км/ч" },
      { type: "txt", value: "5 км/ч" },
      { type: "txt", value: "6 км/ч" },
    ],
    answer: 2,
  },
  {
    id: 13,
    question: [
      {
        type: "txt",
        value:
          "Сколько см будет на чертеже в масштабе 1:8, если размер детали составляет 16 см на чертеже в масштабе 1:12?",
      },
    ],
    options: [
      { type: "txt", value: "12 cm" },
      { type: "txt", value: "32 cm" },
      { type: "txt", value: "8 cm" },
      { type: "txt", value: "24 cm" },
    ],
    answer: 3,
  },
  {
    id: 14,
    question: [
      {
        type: "txt",
        value:
          "Одна труба заполняет пустой бассейн за 10 часов, а вместе с другой за 8 часов. Сколько времени потребуется, чтобы пустой бассейн заполнился, если откроется только вторая труба?",
      },
    ],
    options: [
      { type: "txt", value: "24 часа" },
      { type: "txt", value: "20 часа" },
      { type: "txt", value: "40 часа" },
      { type: "txt", value: "30 часа" },
    ],
    answer: 2,
  },
  {
    id: 15,
    question: [
      {
        type: "txt",
        value:
          "Определите последовательность, в которой приведенные ниже утверждения верны или неверны, с правильным вариантом ответа.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img59.png",
      },
    ],
    options: [
      {
        type: "txt",
        value: "1-правильно, 2-неправильно, 3-неправильно, 4-неправильно",
      },
      {
        type: "txt",
        value: "1-правильно, 2-правильно, 3-неправильно, 4-правильно",
      },
      {
        type: "txt",
        value: "1-неправильно, 2-неправильно, 3-правильно, 4-неправильно",
      },
      {
        type: "txt",
        value: "1-правильно, 2-правильно, 3-правильно, 4-неправильно",
      },
    ],
    answer: 0,
  },
  {
    id: 16,
    question: [
      {
        type: "txt",
        value: "Периметр прямоугольника равен 56 см, а длина составляет",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img6.png",
      },
      {
        type: "txt",
        value: "часть ширины. Вычислите площадь этого прямоугольника.",
      },
    ],
    options: [
      { type: "txt", value: "𝑆 = 360 𝑐𝑚²" },
      { type: "txt", value: "𝑆 = 180 𝑐𝑚²" },
      { type: "txt", value: "𝑆 = 720 𝑐𝑚²" },
      { type: "txt", value: "𝑆 = 270 𝑐𝑚²" },
    ],
    answer: 1,
  },
  {
    id: 17,
    question: [
      { type: "txt", value: "Вычислите:" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img7.png",
      },
    ],
    options: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img8.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img9.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img10.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img11.png",
      },
    ],
    answer: 0,
  },
  {
    id: 18,
    question: [
      {
        type: "txt",
        value:
          "Домашние телефонные номера клиентов телефонной станции состоят из 7-значных цифр и имеют вид 945-0x-xx. Определите количество клиентов, чей номер телефона состоит из разных цифр (один и тот же номер не участвует).",
      },
    ],
    options: [
      { type: "txt", value: "720 ta" },
      { type: "txt", value: "120 ta" },
      { type: "txt", value: "49 ta" },
      { type: "txt", value: "1638 ta" },
    ],
    answer: 1,
  },
  {
    id: 19,
    question: [
      {
        type: "txt",
        value:
          "Найдите соответствующее число, определив закономерность из последовательности",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img12.png",
      },
    ],
    options: [
      { type: "txt", value: "12" },
      { type: "txt", value: "19" },
      { type: "txt", value: "16" },
      { type: "txt", value: "15" },
    ],
    answer: 0,
  },
  {
    id: 20,
    question: [
      {
        type: "txt",
        value:
          ".Найдите соответствующее число, определив закономерность из последовательности.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img13.png",
      },
    ],
    options: [
      { type: "txt", value: "604" },
      { type: "txt", value: "36" },
      { type: "txt", value: "184" },
      { type: "txt", value: "504" },
    ],
    answer: 3,
  },
  {
    id: 21,
    question: [
      {
        type: "txt",
        value: "Определив закон, найдите соответствующее число?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img14.png",
      },
    ],
    options: [
      { type: "txt", value: "36" },
      { type: "txt", value: "108" },
      { type: "txt", value: "64" },
      { type: "txt", value: "92" },
    ],
    answer: 2,
  },
  {
    id: 22,
    question: [
      {
        type: "txt",
        value: "Определив закон, найдите соответствующее число?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img15.png",
      },
    ],
    options: [
      { type: "txt", value: "98" },
      { type: "txt", value: "108" },
      { type: "txt", value: "72" },
      { type: "txt", value: "48" },
    ],
    answer: 1,
  },
  {
    id: 23,
    question: [
      {
        type: "txt",
        value: "Определив закономерность, найдите соответствующее число?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img16.png",
      },
    ],
    options: [
      { type: "txt", value: "78" },
      { type: "txt", value: "84" },
      { type: "txt", value: "96" },
      { type: "txt", value: "86" },
    ],
    answer: 0,
  },
  {
    id: 24,
    question: [
      {
        type: "txt",
        value:
          "Найдите следующий член этой последовательности: 2, 3, 5, 6, 8, 9, …",
      },
    ],
    options: [
      { type: "txt", value: "10" },
      { type: "txt", value: "11" },
      { type: "txt", value: "12" },
      { type: "txt", value: "13" },
    ],
    answer: 1,
  },
  {
    id: 25,
    question: [
      {
        type: "txt",
        value: "Найдите значение x+y, найдя закономерность: 1 , 2 , 4, 8, x, y",
      },
    ],
    options: [
      { type: "txt", value: "56" },
      { type: "txt", value: "16" },
      { type: "txt", value: "32" },
      { type: "txt", value: "48" },
    ],
    answer: 3,
  },
  {
    id: 26,
    question: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img17.png",
      },
      { type: "txt", value: "x=?" },
    ],
    options: [
      { type: "txt", value: "32" },
      { type: "txt", value: "24" },
      { type: "txt", value: "36" },
      { type: "txt", value: "42" },
      { type: "txt", value: "20" },
    ],
    answer: 1,
  },
  {
    id: 27,
    question: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img18.png",
      },
      { type: "txt", value: "X-Y=?" },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "4" },
      { type: "txt", value: "6" },
      { type: "txt", value: "12" },
      { type: "txt", value: "10" },
    ],
    answer: 1,
  },
  {
    id: 28,
    question: [
      { type: "txt", value: "Найдите следующую форму" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img19.png",
      },
    ],
    options: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img20.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img21.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img22.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img23.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img24.png",
      },
    ],
    answer: 0,
  },
  {
    id: 29,
    question: [
      {
        type: "txt",
        value: "Определив закономерность, найдите следующую форму?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img25.png",
      },
    ],
    options: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img26.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img27.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img28.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img29.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img30.png",
      },
    ],
    answer: 3,
  },
  {
    id: 30,
    question: [
      { type: "txt", value: "Найдите следующую форму:" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img31.png",
      },
    ],
    options: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img32.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img33.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img34.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img35.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img36.png",
      },
    ],
    answer: 1,
  },
  {
    id: 31,
    question: [
      {
        type: "txt",
        value: "Определив закономерность, найдите следующие число?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img37.png",
      },
    ],
    options: [
      { type: "txt", value: "6" },
      { type: "txt", value: "7" },
      { type: "txt", value: "8" },
      { type: "txt", value: "10" },
      { type: "txt", value: "11" },
    ],
    answer: 2,
  },
  {
    id: 32,
    question: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img38.png",
      },
    ],
    options: [
      { type: "txt", value: "20" },
      { type: "txt", value: "30" },
      { type: "txt", value: "45" },
      { type: "txt", value: "35" },
      { type: "txt", value: "28" },
    ],
    answer: 1,
  },
  {
    id: 33,
    question: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img39.png",
      },
    ],
    options: [
      { type: "txt", value: "10" },
      { type: "txt", value: "19" },
      { type: "txt", value: "12" },
      { type: "txt", value: "4" },
      { type: "txt", value: "6" },
    ],
    answer: 1,
  },
  {
    id: 34,
    question: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img40.png",
      },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
    ],
    answer: 3,
  },

  {
    id: 35,
    question: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img41.png",
      },
    ],
    options: [
      { type: "txt", value: "4" },
      { type: "txt", value: "6" },
      { type: "txt", value: "8" },
      { type: "txt", value: "12" },
      { type: "txt", value: "16" },
    ],
    answer: 4,
  },
  {
    id: 36,
    question: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img42.png",
      },
    ],
    options: [
      { type: "txt", value: "42" },
      { type: "txt", value: "45" },
      { type: "txt", value: "48" },
      { type: "txt", value: "56" },
      { type: "txt", value: "64" },
    ],
    answer: 4,
  },
  {
    id: 37,
    question: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img43.png",
      },
    ],
    options: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img44.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img45.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img46.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img47.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img48.png",
      },
    ],
    answer: 4,
  },
  {
    id: 38,
    question: [
      {
        type: "txt",
        value:
          "Определив закономерность, найдите x, используя предоставленные данные.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img49.png",
      },
    ],
    options: [
      { type: "txt", value: "42" },
      { type: "txt", value: "60" },
      { type: "txt", value: "72" },
      { type: "txt", value: "69" },
    ],
    answer: 3,
  },
  {
    id: 39,
    question: [
      {
        type: "txt",
        value: "Определив закономерность, найдите x, используя данные.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img50.png",
      },
    ],
    options: [
      { type: "txt", value: "72" },
      { type: "txt", value: "65" },
      { type: "txt", value: "61" },
      { type: "txt", value: "69" },
    ],
    answer: 3,
  },
  {
    id: 40,
    question: [
      {
        type: "txt",
        value: "Определите, какое время покажет следующий час.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img51.png",
      },
    ],
    options: [
      { type: "txt", value: "4:50" },
      { type: "txt", value: "05:00" },
      { type: "txt", value: "05:08 " },
      { type: "txt", value: "05:10" },
      { type: "txt", value: "05:13" },
    ],
    answer: 0,
  },
  {
    id: 41,
    question: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img52.png",
      },
      {
        type: "txt",
        value:
          "Какая еще фигура, добавленная к фигуре 1, образует параллелепипед на рисунке?",
      },
    ],
    options: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img53.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img54.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img55.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img56.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img57.png",
      },
    ],
    answer: 0,
  },
  {
    id: 42,
    question: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/mirzo-ulugbek/math/img58.png",
      },
    ],
    options: [
      { type: "txt", value: "20" },
      { type: "txt", value: "30" },
      { type: "txt", value: "45" },
      { type: "txt", value: "46" },
      { type: "txt", value: "54" },
    ],
    answer: 4,
  },
];

export const prezidentSchoolEnglish = [
  {
    id: 0,
    question: [{ type: "txt", value: "Is John__ brother?" }],
    options: [
      { type: "txt", value: "she" },
      { type: "txt", value: "you" },
      { type: "txt", value: "your" },
      { type: "txt", value: "yours" },
    ],
    answer: 2,
  },
  {
    id: 1,
    question: [{ type: "txt", value: "I live__ home ___New York" }],
    options: [
      { type: "txt", value: "*/in" },
      { type: "txt", value: "in/in" },
      { type: "txt", value: "in/*" },
      { type: "txt", value: "at/in" },
    ],
    answer: 3,
  },
  {
    id: 2,
    question: [
      { type: "txt", value: `"__is your phone number ?"   " it's 2209..."` },
    ],
    options: [
      { type: "txt", value: "How" },
      { type: "txt", value: "who" },
      { type: "txt", value: "what" },
      { type: "txt", value: "where" },
    ],
    answer: 2,
  },
  {
    id: 3,
    question: [{ type: "txt", value: "A BMW is___ German Car" }],
    options: [
      { type: "txt", value: "the" },
      { type: "txt", value: "*" },
      { type: "txt", value: "an" },
      { type: "txt", value: "a" },
    ],
    answer: 0,
  },
  {
    id: 4,
    question: [{ type: "txt", value: "What does the King do with Silenus?" }],
    options: [
      { type: "txt", value: "He gives him a lot of gold" },
      { type: "txt", value: "He plays with him in the garden" },
      { type: "txt", value: "He invites him to his place" },
      { type: "txt", value: "He sends him away" },
    ],
    answer: 2,
  },
  {
    id: 5,
    question: [
      { type: "txt", value: "Look at the picture!" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/ingliz-tili/img1.png",
      },
      { type: "txt", value: "The King has a cup in ____ hand " },
    ],
    options: [
      { type: "txt", value: "He's" },
      { type: "txt", value: "her" },
      { type: "txt", value: "his" },
      { type: "txt", value: "their" },
    ],
    answer: 2,
  },
  {
    id: 6,
    question: [
      {
        type: "txt",
        value:
          "Choose the appropriate answers for the following questions.       What's the table like?",
      },
    ],
    options: [
      { type: "txt", value: "I like it" },
      { type: "txt", value: "he is like me" },
      { type: "txt", value: "I don't like it" },
      { type: "txt", value: "It is square" },
    ],
    answer: 3,
  },
  {
    id: 7,
    question: [{ type: "txt", value: "Could you pass me a scone, please?" }],
    options: [
      { type: "txt", value: "I'm fine thank you" },
      { type: "txt", value: "sure, here you are" },
      { type: "txt", value: "it's very tasty" },
      { type: "txt", value: "this way please" },
    ],
    answer: 1,
  },
  {
    id: 8,
    question: [{ type: "txt", value: "Which of English writers do you read?" }],
    options: [
      { type: "txt", value: "I read Jack London" },
      { type: "txt", value: "I'm reading Mark Twain" },
      { type: "txt", value: "I've read Dreiser" },
      { type: "txt", value: "I had read Dickens" },
    ],
    answer: 0,
  },
  {
    id: 9,
    question: [
      { type: "txt", value: "Where can you see this notice?" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/ingliz-tili/img2.png",
      },
    ],
    options: [
      { type: "txt", value: "at home" },
      { type: "txt", value: "in the sea" },
      { type: "txt", value: "intersection" },
      { type: "txt", value: "at school" },
    ],
    answer: 2,
  },
  {
    id: 10,
    question: [
      { type: "txt", value: "Where can you see this notice?" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/ingliz-tili/img3.png",
      },
    ],
    options: [
      { type: "txt", value: "In the garden" },
      { type: "txt", value: "In the corridor" },
      { type: "txt", value: "In the truck" },
    ],
    answer: 0,
  },
  {
    id: 11,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question. She gets into her car. She puts on her seatbelt. She starts to drive. She drives ata good spud. It is not safe to drive too fast. It is also not safe to drive too slow. The traffic light is yellow. She slows down. The traffic light turns red. She stops Completely. She waits. It turns green. She drives.             She has a _______.",
      },
    ],
    options: [
      { type: "txt", value: "car" },
      { type: "txt", value: "money" },
      { type: "txt", value: "brain" },
      { type: "txt", value: "light" },
    ],
    answer: 0,
  },
  {
    id: 12,
    question: [{ type: "txt", value: "Why does she puts on her seatbelt?" }],
    options: [
      { type: "txt", value: "For sleeping" },
      { type: "txt", value: "For driving fast" },
      { type: "txt", value: "For safety" },
      { type: "txt", value: "For driving slowly" },
    ],
    answer: 2,
  },
  {
    id: 13,
    question: [
      { type: "txt", value: "Driving is not safe to drive very_______." },
    ],
    options: [
      { type: "txt", value: "green" },
      { type: "txt", value: "fast" },
      { type: "txt", value: "slow" },
      { type: "txt", value: "both b and c" },
    ],
    answer: 1,
  },
  {
    id: 14,
    question: [{ type: "txt", value: "What color traffic light is showing?" }],
    options: [
      { type: "txt", value: "green" },
      { type: "txt", value: "yellow" },
      { type: "txt", value: "red" },
      { type: "txt", value: "all above" },
    ],
    answer: 3,
  },
  {
    id: 15,
    question: [
      {
        type: "txt",
        value: "Which color you must stop if traffic light is on?",
      },
    ],
    options: [
      { type: "txt", value: "yellow" },
      { type: "txt", value: "red" },
      { type: "txt", value: "green" },
      { type: "txt", value: "orange" },
    ],
    answer: 1,
  },
  {
    id: 16,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question. Betty opens up her present. It is a snow globe. She shakes it. Glitter falls down. She looks inside the globe. It is Santa Claus! He has a white bread and a big belly. He has a bag of presents. He only gives gifts to kids. Betty is a good kid. She hopes Santa Claus visits her.         What is Betty opening?",
      },
    ],
    options: [
      { type: "txt", value: "Santa Claus" },
      { type: "txt", value: "Present" },
      { type: "txt", value: "Glitter" },
      { type: "txt", value: "Globe" },
    ],
    answer: 1,
  },
  {
    id: 17,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question. Betty opens up her present. It is a snow globe. She shakes it. Glitter falls down. She looks inside the globe. It is Santa Claus! He has a white bread and a big belly. He has a bag of presents. He only gives gifts to kids. Betty is a good kid. She hopes Santa Claus visits her.         What' s in the gift?",
      },
    ],
    options: [
      { type: "txt", value: "Kid" },
      { type: "txt", value: "Snow Globe" },
      { type: "txt", value: "Santa Claus" },
      { type: "txt", value: "White beard" },
    ],
    answer: 1,
  },
  {
    id: 18,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question. Betty opens up her present. It is a snow globe. She shakes it. Glitter falls down. She looks inside the globe. It is Santa Claus! He has a white bread and a big belly. He has a bag of presents. He only gives gifts to kids. Betty is a good kid. She hopes Santa Claus visits her.         What does she shake?",
      },
    ],
    options: [
      { type: "txt", value: "Santa Claus" },
      { type: "txt", value: "White beard" },
      { type: "txt", value: "Kid" },
      { type: "txt", value: "Snow Globe" },
    ],
    answer: 3,
  },
  {
    id: 19,
    question: [{ type: "txt", value: "What is inside the globe?" }],
    options: [
      { type: "txt", value: "Glitter" },
      { type: "txt", value: "Present" },
      { type: "txt", value: "Globe" },
      { type: "txt", value: "Santa Clause" },
    ],
    answer: 3,
  },
  {
    id: 20,
    question: [{ type: "txt", value: "She is ___________ teacher" }],
    options: [
      { type: "txt", value: "the" },
      { type: "txt", value: "*" },
      { type: "txt", value: "a" },
      { type: "txt", value: "an" },
    ],
    answer: 2,
  },
  {
    id: 21,
    question: [
      { type: "txt", value: " __________ from spain. I' m Rodriguez." },
    ],
    options: [
      { type: "txt", value: "She's" },
      { type: "txt", value: "you' are" },
      { type: "txt", value: "he's" },
      { type: "txt", value: "I'm" },
    ],
    answer: 3,
  },
  {
    id: 22,
    question: [{ type: "txt", value: "I have two ________." }],
    options: [
      { type: "txt", value: "brother" },
      { type: "txt", value: "brother's" },
      { type: "txt", value: "brothers" },
      { type: "txt", value: "a brother" },
    ],
    answer: 2,
  },
  {
    id: 23,
    question: [
      { type: "txt", value: "Put the correct answer the free space." },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/ingliz-tili/img4.png",
      },
    ],
    options: [
      { type: "txt", value: "do like" },
      { type: "txt", value: "like" },
      { type: "txt", value: "like not" },
      { type: "txt", value: "don't like" },
    ],
    answer: 3,
  },
  {
    id: 24,
    question: [
      { type: "txt", value: "Put the correct answer the free space." },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/ingliz-tili/img5.png",
      },
    ],
    options: [
      { type: "txt", value: "sharpener" },
      { type: "txt", value: "cutter" },
      { type: "txt", value: "chopper" },
      { type: "txt", value: "case" },
    ],
    answer: 0,
  },
  {
    id: 25,
    question: [{ type: "txt", value: '" Do you have any free time today"' }],
    options: [
      { type: "txt", value: "I have a good idea" },
      { type: "txt", value: "Fine" },
      { type: "txt", value: "Yes, I do" },
      { type: "txt", value: "I tried" },
    ],
    answer: 2,
  },
  {
    id: 26,
    question: [
      {
        type: "txt",
        value:
          "Ann: It looks like to rain.  What will Ann’s mother advise her?",
      },
    ],
    options: [
      { type: "txt", value: "You’d better go for a walk." },
      { type: "txt", value: "You’d better take an umbrella." },
      { type: "txt", value: "You’d better go fishing." },
      { type: "txt", value: "You’d better take your hand bag." },
    ],
    answer: 1,
  },
  {
    id: 27,
    question: [{ type: "txt", value: "Ann is ill." }],
    options: [
      { type: "txt", value: "Oh, I’m sorry to hear that." },
      { type: "txt", value: "Her mother very pleasant woman." },
      { type: "txt", value: "She studies in the second form." },
      { type: "txt", value: "Yes, Ann is a good singer." },
    ],
    answer: 0,
  },
  {
    id: 28,
    question: [
      {
        type: "txt",
        value:
          "Look at the picture and answer the question.       The magician is very pleased to see his friend alive! As a reward for his kindness, the magician decides to grant Midas a wish.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/ingliz-tili/img6.png",
      },
      {
        type: "txt",
        value: "When Dionysus sees his friend safe, what does he do?",
      },
    ],
    options: [
      { type: "txt", value: "He offers to reward the king." },
      { type: "txt", value: "He punishes the king." },
      { type: "txt", value: "He sends the king away." },
      { type: "txt", value: "He gives some gold to the king." },
    ],
    answer: 0,
  },
  {
    id: 29,
    question: [{ type: "txt", value: "The magician will grant Midas______." }],
    options: [
      { type: "txt", value: "one wish" },
      { type: "txt", value: "two wishes" },
      { type: "txt", value: "four wishes" },
      { type: "txt", value: "three wishes" },
    ],
    answer: 0,
  },
  {
    id: 30,
    question: [
      {
        type: "txt",
        value: `Find the correct answer.      -"______ are you?"       -  “I am Jack .”`,
      },
    ],
    options: [
      { type: "txt", value: "What" },
      { type: "txt", value: "Who" },
      { type: "txt", value: "Which" },
      { type: "txt", value: "Where" },
    ],
    answer: 1,
  },
  {
    id: 31,
    question: [
      { type: "txt", value: "I_________21 years old, but Andrew ______20." },
    ],
    options: [
      { type: "txt", value: "am/am" },
      { type: "txt", value: "is/am" },
      { type: "txt", value: "am/is" },
      { type: "txt", value: "is/are" },
    ],
    answer: 2,
  },
  {
    id: 32,
    question: [{ type: "txt", value: "I’m __ home." }],
    options: [
      { type: "txt", value: "in" },
      { type: "txt", value: "on" },
      { type: "txt", value: "at" },
      { type: "txt", value: "from" },
    ],
    answer: 2,
  },
  {
    id: 33,
    question: [{ type: "txt", value: "We _ students." }],
    options: [
      { type: "txt", value: "are a" },
      { type: "txt", value: "is" },
      { type: "txt", value: "are" },
      { type: "txt", value: "am" },
    ],
    answer: 2,
  },
  {
    id: 34,
    question: [
      { type: "txt", value: "Put the correct word to free space." },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/ingliz-tili/img7.png",
      },
    ],
    options: [
      { type: "txt", value: "chair" },
      { type: "txt", value: "door" },
      { type: "txt", value: "Gate" },
      { type: "txt", value: "Flower" },
    ],
    answer: 1,
  },
  {
    id: 35,
    question: [
      { type: "txt", value: "Put the correct answer to free space." },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/ingliz-tili/img8.png",
      },
    ],
    options: [
      { type: "txt", value: "afternoon" },
      { type: "txt", value: "night" },
      { type: "txt", value: "midnight" },
      { type: "txt", value: "dawn" },
    ],
    answer: 0,
  },
  {
    id: 36,
    question: [
      { type: "txt", value: "“Are you from Senegal?”      “No, ______”." },
    ],
    options: [
      { type: "txt", value: "I amn’t" },
      { type: "txt", value: "I are not" },
      { type: "txt", value: "I’m not" },
      { type: "txt", value: "I not" },
    ],
    answer: 2,
  },
  {
    id: 37,
    question: [
      {
        type: "txt",
        value: "„ ___ Martha English?”         „Yes, she _______”.",
      },
    ],
    options: [
      { type: "txt", value: "is/isn’t" },
      { type: "txt", value: "are/is" },
      { type: "txt", value: "isn’t/is" },
      { type: "txt", value: "is/is" },
    ],
    answer: 3,
  },
  {
    id: 38,
    question: [{ type: "txt", value: "Who has lost my favourite book?" }],
    options: [
      { type: "txt", value: "Ann did" },
      { type: "txt", value: "Jack has" },
      { type: "txt", value: "Nick is" },
      { type: "txt", value: "they do" },
    ],
    answer: 1,
  },
  {
    id: 39,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the questions.     Abdurahmon goes to his backyard. He sees something. It is small and slimy. It is a lizard! He puts it on his hand. He brings it inside his house. He shows it to his parents. He tells them he wants to keep it. They say no. they say lizards need to be outside. He goes to his backyard. He lets the lizard go.   The boy’s name is ___",
      },
    ],
    options: [
      { type: "txt", value: "Parents" },
      { type: "txt", value: "Lizard" },
      { type: "txt", value: "Abdurahmon" },
      { type: "txt", value: "Nobody" },
    ],
    answer: 2,
  },
  {
    id: 40,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the questions.     Abdurahmon goes to his backyard. He sees something. It is small and slimy. It is a lizard! He puts it on his hand. He brings it inside his house. He shows it to his parents. He tells them he wants to keep it. They say no. they say lizards need to be outside. He goes to his backyard. He lets the lizard go.   Where does he see slimy lizard?",
      },
    ],
    options: [
      { type: "txt", value: "Yard" },
      { type: "txt", value: "Stadium" },
      { type: "txt", value: "Backyard" },
      { type: "txt", value: "Field" },
    ],
    answer: 2,
  },
  {
    id: 41,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the questions.     Abdurahmon goes to his backyard. He sees something. It is small and slimy. It is a lizard! He puts it on his hand. He brings it inside his house. He shows it to his parents. He tells them he wants to keep it. They say no. they say lizards need to be outside. He goes to his backyard. He lets the lizard go.   On his hand there is __.",
      },
    ],
    options: [
      { type: "txt", value: "money" },
      { type: "txt", value: "lizard" },
      { type: "txt", value: "house" },
      { type: "txt", value: "parent" },
    ],
    answer: 1,
  },
  {
    id: 42,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the questions.     Abdurahmon goes to his backyard. He sees something. It is small and slimy. It is a lizard! He puts it on his hand. He brings it inside his house. He shows it to his parents. He tells them he wants to keep it. They say no. they say lizards need to be outside. He goes to his backyard. He lets the lizard go.   Why he cannot bring the lizard inside?",
      },
    ],
    options: [
      { type: "txt", value: "He does not want to" },
      { type: "txt", value: "Father does not want" },
      { type: "txt", value: "Parents do not let" },
      { type: "txt", value: "Mother does not want" },
    ],
    answer: 2,
  },
  {
    id: 43,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the questions.     Abdurahmon goes to his backyard. He sees something. It is small and slimy. It is a lizard! He puts it on his hand. He brings it inside his house. He shows it to his parents. He tells them he wants to keep it. They say no. they say lizards need to be outside. He goes to his backyard. He lets the lizard go.   At the end of story what happens?",
      },
    ],
    options: [
      { type: "txt", value: "He lests the lizard stay." },
      { type: "txt", value: "He lests the lizard go." },
      { type: "txt", value: "He lets the lizard come." },
      { type: "txt", value: "He lets the lizard live." },
    ],
    answer: 1,
  },
  {
    id: 44,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the questions.    It is snowing. It is cold. Zuhra puts on a jacket. She puts a scarf around her neck. She puts mittens on her hands. She sits close to the fireplace. Her dad taps her on the shoulder. She turns around. He gives her a cup of hot chocolate. She is excited.      What is happening?",
      },
    ],
    options: [
      { type: "txt", value: "she is excited" },
      { type: "txt", value: "it is snowing" },
      { type: "txt", value: "it is cool" },
      { type: "txt", value: "nothing" },
    ],
    answer: 1,
  },
  {
    id: 45,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the questions.    It is snowing. It is cold. Zuhra puts on a jacket. She puts a scarf around her neck. She puts mittens on her hands. She sits close to the fireplace. Her dad taps her on the shoulder. She turns around. He gives her a cup of hot chocolate. She is excited.       Zukhra wears her ___.",
      },
    ],
    options: [
      { type: "txt", value: "chocolate" },
      { type: "txt", value: "fireplace" },
      { type: "txt", value: "Shoulder" },
      { type: "txt", value: "Jacket" },
    ],
    answer: 3,
  },
  {
    id: 46,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the questions.    It is snowing. It is cold. Zuhra puts on a jacket. She puts a scarf around her neck. She puts mittens on her hands. She sits close to the fireplace. Her dad taps her on the shoulder. She turns around. He gives her a cup of hot chocolate. She is excited.      She has __.",
      },
    ],
    options: [
      { type: "txt", value: "Money" },
      { type: "txt", value: "Scarf" },
      { type: "txt", value: "White scarf" },
      { type: "txt", value: "phone" },
    ],
    answer: 1,
  },
  {
    id: 47,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the questions.    It is snowing. It is cold. Zuhra puts on a jacket. She puts a scarf around her neck. She puts mittens on her hands. She sits close to the fireplace. Her dad taps her on the shoulder. She turns around. He gives her a cup of hot chocolate. She is excited.      Why does she sit near fireplace?",
      },
    ],
    options: [
      { type: "txt", value: "No information" },
      { type: "txt", value: "There is no fireplace" },
      { type: "txt", value: "Because he wants" },
      { type: "txt", value: "There is chocolate near fireplace" },
    ],
    answer: 0,
  },
  {
    id: 48,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the questions.    It is snowing. It is cold. Zuhra puts on a jacket. She puts a scarf around her neck. She puts mittens on her hands. She sits close to the fireplace. Her dad taps her on the shoulder. She turns around. He gives her a cup of hot chocolate. She is excited.      What does he give Zukhra?",
      },
    ],
    options: [
      { type: "txt", value: "Jacket" },
      { type: "txt", value: "hot chocolate " },
      { type: "txt", value: "shoulder" },
      { type: "txt", value: "scary" },
    ],
    answer: 1,
  },
  {
    id: 49,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the questions.    It is snowing. It is cold. Zuhra puts on a jacket. She puts a scarf around her neck. She puts mittens on her hands. She sits close to the fireplace. Her dad taps her on the shoulder. She turns around. He gives her a cup of hot chocolate. She is excited.      What is shoulder?",
      },
    ],
    options: [
      { type: "txt", value: "Body of humans" },
      { type: "txt", value: "Body of animal" },
      { type: "txt", value: "Stick" },
      { type: "txt", value: "Liquid" },
    ],
    answer: 0,
  },
];

export const prezidentSchoolProblemSolving = [
  {
    id: 0,
    question: [
      {
        type: "txt",
        value:
          "Quyidagi jadvalda O’zbekiston viloyatining ayrim viloyatlari  uchun ma’lum bir kunni quyosh chiqishi va botishi, oy chiqishi va botishi ko'rsatilgan. Viloyatlarning  qaysi birida osmondagi quyosh va oy eng ko’p vaqt davomida birga bo’lgan?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img1.png",
      },
    ],
    options: [
      { type: "txt", value: "Toshkent" },
      { type: "txt", value: "Buxoro" },
      { type: "txt", value: "Qashqadaryo" },
      { type: "txt", value: "Xorazm" },
    ],
    answer: 2,
  },
  {
    id: 1,
    question: [
      {
        type: "txt",
        value:
          "Vali yangi yil uchun berilgan barcha shirinliklarni darhol yarmini yedi. Qolgan shirinliklarning yarmini va yana bittasini akasiga berdi. Shundan so‘ng qolgan shirinliklarning yarmini va yana bittasini ukasiga berdi. Shundan keyin Valida ikkita shirinlik qoldi. Valida dastlab nechta shirinlik bo’lgan?",
      },
    ],
    options: [
      { type: "txt", value: "24" },
      { type: "txt", value: "30" },
      { type: "txt", value: "28" },
      { type: "txt", value: "16" },
    ],
    answer: 2,
  },
  {
    id: 2,
    question: [
      {
        type: "txt",
        value:
          "Sardor va Hoshim ikkita natural son o’ylashdi. Ma’lum bo’lishicha ularni qo’shsangiz 1345 hosil bo`ladi.  Agar Sardor o’z sonining oxiridan keyin 3 raqamini yozsa va Hoshim o’z sonining oxiridan 2 raqamini olib tashlasa, natijada hosil bo’lgan sonlar teng bo’lib qoladi. Hoshim o’ylagan sonning raqamlari yig’indisini toping.",
      },
    ],
    options: [
      { type: "txt", value: "4" },
      { type: "txt", value: "12" },
      { type: "txt", value: "18" },
      { type: "txt", value: "9" },
    ],
    answer: 3,
  },
  {
    id: 3,
    question: [
      {
        type: "txt",
        value:
          "Raqamlari bir-biridan 4 ga farq qiladigan ikki xonali sonlar nechta?",
      },
    ],
    options: [
      { type: "txt", value: "12" },
      { type: "txt", value: "10" },
      { type: "txt", value: "9" },
      { type: "txt", value: "11" },
    ],
    answer: 3,
  },
  {
    id: 4,
    question: [
      {
        type: "txt",
        value:
          "Sa’dulla amaki hamyonida 370$ bilan ko‘mir idorasiga kiradi va 15 qop ko‘mir olmoqchi bo‘ladi. Sotuvchi 15 qop ko‘mir narxini aytsa, puli 80$ kamligini hisoblab chiqadi. Keyin Sa’dulla amaki 9 qop ko‘mir va 4 bog’  o’tin  sotib oladi. Bir bog’ o’tin  qancha turadi?",
      },
    ],
    options: [
      { type: "txt", value: "25" },
      { type: "txt", value: "26" },
      { type: "txt", value: "27" },
      { type: "txt", value: "28" },
    ],
    answer: 0,
  },
  {
    id: 5,
    question: [
      {
        type: "txt",
        value:
          "Komilaning uchta sharfi bor – oq, ko’k va qizil. Uning uchta ko’ylagi bor – oq, ko’k va qizil. Uning uchta poyabzali bor – oq, ko’k va qizil. Komila necha xil usulda uch xil rangda kiyina oladi?",
      },
    ],
    options: [
      { type: "txt", value: "6" },
      { type: "txt", value: "8" },
      { type: "txt", value: "9" },
      { type: "txt", value: "11" },
    ],
    answer: 0,
  },
  {
    id: 6,
    question: [
      {
        type: "txt",
        value:
          "Marjona opa, Kamron va Ma`ruf ismli o’g’illariga sport poyabzali sotib  olmoqda. Quyidagi jadvalda sotuvda mavjud bo’lgan sport poyabzallari narxi ko’rsatilgan.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img2.png",
      },
      {
        type: "txt",
        value:
          "Kamronning o’lchami 4, ammo u oq yoki qizil rangli sport poyabzalni xohlaydi. Ma`rufning o’lchami 10, u faqat qora yoki ko’k rangli sport poyabzalini xohlaydi.  Agar Marjona opaning o’g’illari xohishi bajarilsa, ikki juft poyabzal uchun to’lash mumkin bo’lgan eng kam narx qancha bo’ladi?",
      },
    ],
    options: [
      { type: "txt", value: "110 $" },
      { type: "txt", value: "100 $" },
      { type: "txt", value: "120 $" },
      { type: "txt", value: "130 $" },
    ],
    answer: 1,
  },
  {
    id: 7,
    question: [
      {
        type: "txt",
        value:
          "Nozima 1 dan 66 gacha bo`lgan sonlarni ketma-ket qilib doskada yozdi. Nigina 4 raqamini yoqtirmaydi, shuning uchun doskadagi barcha 4 raqamini o`chirib tashladi. Doskadagi hosil bo`lgan son necha xonali ekanligini aniqlang.",
      },
    ],
    options: [
      { type: "txt", value: "106" },
      { type: "txt", value: "105" },
      { type: "txt", value: "107" },
      { type: "txt", value: "108" },
    ],
    answer: 0,
  },
  {
    id: 8,
    question: [
      {
        type: "txt",
        value:
          "Nozim va Hamid aka-ukalar. Ularda jami  50 ta o’yinchoq bor. Nozimning tug’ilgan kunida Hamid unga o`yinchoqlarining yarmini berdi hamda 8 ta o`yinchoq undan oldi. Shundan so’ng ikkalasini o’yinchoqlari teng bo’lib qoldi. Dastlab Nozimda nechta o’yinchoq bo’lgan?",
      },
    ],
    options: [
      { type: "txt", value: "16" },
      { type: "txt", value: "14" },
      { type: "txt", value: "13" },
      { type: "txt", value: "21" },
    ],
    answer: 0,
  },
  {
    id: 9,
    question: [
      {
        type: "txt",
        value:
          "Quyida jadvalda qonuniyat aniqlab, ? lar o’rnida turgan sonlarning yig’indisini toping.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img3.png",
      },
    ],
    options: [
      { type: "txt", value: "56" },
      { type: "txt", value: "53" },
      { type: "txt", value: "55" },
      { type: "txt", value: "54" },
    ],
    answer: 1,
  },
  {
    id: 10,
    question: [
      {
        type: "txt",
        value:
          "Qasrdagi soat 06:50 bo’lganda buzilib qoldi. U endi oldinga emas orqaga yuradigan bo’lib qoldi. Ma’lum vaqtdan keyin soat 03:10 ni ko’rsatayotgan bo’lsa, haqiqiy vaqtni toping.",
      },
    ],
    options: [
      { type: "txt", value: "10:10" },
      { type: "txt", value: "9:40" },
      { type: "txt", value: "10:30" },
      { type: "txt", value: "9:30" },
    ],
    answer: 2,
  },
  {
    id: 11,
    question: [
      {
        type: "txt",
        value:
          "Garajda jami 750 ta yuk va yengil mashinalar bor. Yuk mashinalarda 6 ta, yengil mashinalarda esa 4 ta g’ildirak bor. Garajdagi barcha mashinalarning  g`ildiraklari soni 3024 ta ekanligi ma`lum bo`lsa, u yerda nechta yuk mashinasi bor?",
      },
    ],
    options: [
      { type: "txt", value: "738" },
      { type: "txt", value: "14" },
      { type: "txt", value: "12" },
      { type: "txt", value: "736" },
    ],
    answer: 2,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value:
          "Devoriy soat har kuni 6 daqiqa orqada qoladi. Bir kuni Kozim undagi vaqtni to`g`rilab qo’ydi va xizmat safariga ketdi. U qaytib kelganida, devordagi soat vaqti bilan haqiqiy soat vaqti mos edi. Kozim eng kamida xizmat safariga necha kun ketgan bo’lishi mumkin?",
      },
    ],
    options: [
      { type: "txt", value: "60" },
      { type: "txt", value: "90" },
      { type: "txt", value: "40" },
      { type: "txt", value: "120" },
    ],
    answer: 3,
  },
  {
    id: 13,
    question: [
      {
        type: "txt",
        value:
          "Quyidagi rasmda aylanalar ichiga 10 dan 15 gacha bo`lgan sonlar bir martadan yozilgan. Natijada uchburchakning istalgan tomonidagi aylanalar ichidagi sonlar yig`indisi qandaydir A soniga teng bo`lib qoldi. A ning eng katta qiymati necha bo`lishi mumkin?",
      },
    ],
    options: [
      { type: "txt", value: "37" },
      { type: "txt", value: "38" },
      { type: "txt", value: "39" },
      { type: "txt", value: "40" },
    ],
    answer: 2,
  },
  {
    id: 14,
    question: [
      {
        type: "txt",
        value:
          "Soat 12:00 va men hozirda shahar markaziga uchrashuvga keldim. Ushbu uchrashuvdan so'ng, soat 18:00 da muhim uchrashuvim bor, bu esa 17:30 dan oldin ketishimni talab qiladi. Shahar markazida 5 ta avtoturargoh mavjud bo'lib, ular quyidagi narxlarda to'lanadi:",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img5.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img4.png",
      },
      {
        type: "txt",
        value:
          "Eslatma:  soatiga qo’yilgan narxlar soatning ma’lum bir qismi uchun ham shu narx. Masalan soatiga 2000 so’m deyilsa, 1 soatdan kam vaqtlar  (1min,2 min,…, yarim soat,…)  uchun ham 2000 so’m.  To'lovlarni     iloji boricha  kamroq  to'lash uchun mashinamni qaysi   avtoturargohda qoldirishim kerak?",
      },
    ],
    options: [
      { type: "txt", value: "Grand avtoturargohi" },
      { type: "txt", value: "Mustqaillik maydoni  avtoturargohi" },
      { type: "txt", value: "Markaziy avtoturargoh" },
      { type: "txt", value: "Yangi  avtoturargoh" },
      { type: "txt", value: "Bozor yaqinidagi avtoturargoh" },
    ],
    answer: 4,
  },
  {
    id: 15,
    question: [
      {
        type: "txt",
        value:
          "Maktabda o‘tkazilgan daraxt ekish tadbirida 4-sinf o‘quvchilari tomonidan ekilgan daraxt ko‘chatlari soni diagrammada keltirilgan. Quyidagi mulohazalardan nechtasi to`g`ri?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img6.png",
      },
      {
        type: "txt",
        value:
          "1)4-A sinf eng ko‘p ko‘chat o‘tqazgan.  2)4-B va 4-C sinflar tomonidan ekilgan ko'chatlar soni teng.  3)Ekilgan terak ko'chatlari soni archa ko'chatlari sonidan ko'p.  4)4-B sinf eng ko‘p archa ko‘chatlarini ekgan.",
      },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 2,
  },
  {
    id: 16,
    question: [
      {
        type: "txt",
        value:
          "Guli sabzavot do’konidan 5 kg kartoshka va 1 kg 500 gramm qalampir sotib oladi. Do`kondagi yarim kilogramm qalampirning narxi 1 kilogramm kartoshkaning narxiga teng. Guli sotuvchiga 50 so’m to‘laganida,  sotuvchi    18 so’m qaytim beradi. Agar Guli   bor yo'g'i    5 kg kartoshka olganida, necha so’m qaytim olgan   bo'lar edi?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img7.png",
      },
    ],
    options: [
      { type: "txt", value: "24" },
      { type: "txt", value: "32" },
      { type: "txt", value: "20" },
      { type: "txt", value: "30" },
    ],
    answer: 3,
  },
  {
    id: 17,
    question: [
      {
        type: "txt",
        value:
          "Qutini quyidagicha sharlar bilan to’ldirish uchun yana  nechta shar kerak bo’ladi?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img8.png",
      },
    ],
    options: [
      { type: "txt", value: "10" },
      { type: "txt", value: "14" },
      { type: "txt", value: "22" },
      { type: "txt", value: "16" },
    ],
    answer: 2,
  },
  {
    id: 18,
    question: [
      {
        type: "txt",
        value:
          "Nigina to`liq yanvar oyida (31 kun) o`zining sevimli 72 qismdan iborat serialini ko`rib tugatdi. U barcha juma va chorshanba kunlari serialning 3 tadan qismini va boshqa kunlari esa 2 tadan qismini ko`rdi. Agar oyning birinchi kuni chorshanbadan boshlansa Nigina serialning 25-qismini qaysi kuni ko`rgan?",
      },
    ],
    options: [
      { type: "txt", value: "Payshanba" },
      { type: "txt", value: "Juma" },
      { type: "txt", value: "Shanba" },
      { type: "txt", value: "Yakshanba" },
      { type: "txt", value: "Dushanba" },
    ],
    answer: 2,
  },
  {
    id: 19,
    question: [
      {
        type: "txt",
        value:
          "Oromgohga Qarshi va Termizdan 240 nafar bola keldi. Kelganlarning orasida 125 nafari o’g’il bolalar, ularning 60 tasi Qashqadaryodan kelishgan. Termizdan kelgan bolalarning 53 nafari qiz bolalar. Qashqadaryodan jami necha kishi kelgan?",
      },
    ],
    options: [
      { type: "txt", value: "62" },
      { type: "txt", value: "115" },
      { type: "txt", value: "122" },
      { type: "txt", value: "120" },
    ],
    answer: 2,
  },
  {
    id: 20,
    question: [
      {
        type: "txt",
        value:
          "Menda hammasi bo`lib jami 13 ta mandarinli va apelsinli konfetlar bor edi. Men apelsinli konfetdan ko`ra ikki dona ko`p mandarinli konfet yedim. Shundan so`ng mendagi apelsinli konfetlar soni mandarinli konfetlar sonidan olti marta ko`p bo`lib qoldi. Menda dastlab nechta mandarinli konfet bor edi?",
      },
    ],
    options: [
      { type: "txt", value: "5" },
      { type: "txt", value: "4" },
      { type: "txt", value: "6" },
      { type: "txt", value: "7" },
      { type: "txt", value: "8" },
    ],
    answer: 0,
  },
  {
    id: 21,
    question: [
      {
        type: "txt",
        value:
          "Ushbu shaklarning nechtasini buklab kub sirtini hosil qilish mumkin?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img9.png",
      },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "hech qaysi biridan" },
    ],
    answer: 1,
  },
  {
    id: 22,
    question: [
      {
        type: "txt",
        value:
          "Quyida shakllar berilgan. Birinchi shakl 1 ta kulrang va 4 ta oq rangli kataklardan iborat. Ikinchi shakl esa 4 ta kulrang 9 ta oq rangli kataklardan iborat. Xuddi shunday ko`rinishdagi shaklning jami 16 ta kulrang katagi bo`lsa, nechta oq katagi bor?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img10.png",
      },
    ],
    options: [
      { type: "txt", value: "32" },
      { type: "txt", value: "48" },
      { type: "txt", value: "64" },
      { type: "txt", value: "25" },
      { type: "txt", value: "30" },
    ],
    answer: 3,
  },
  {
    id: 23,
    question: [
      {
        type: "txt",
        value:
          "Fermada jami 30 ta sigir, qo`y va tovuqlar bor edi. Fermer 6 ta sigir va 3 ta qo`y sotib, o`rniga 2 ta sigir va 4 ta tovuq sotib oldi. Shundan so`ng fermadagi sigir, qo`y va tovuqlar soni teng bo`lib qoldi. Fermada dastlab hayvonlarning oyoqlari soni nechta bo`lgan?",
      },
    ],
    options: [
      { type: "txt", value: "110" },
      { type: "txt", value: "90" },
      { type: "txt", value: "120" },
      { type: "txt", value: "100" },
      { type: "txt", value: "102" },
    ],
    answer: 0,
  },
  {
    id: 24,
    question: [
      {
        type: "txt",
        value:
          "Dushanba kuni Ozod Hoshimdan ikki baravar ko'p maosh oldi. Seshanba kuni Ozod 100$ sarfladi, Hoshim esa yana 200$ maosh oldi. Shundan keyin ular teng pulga ega bo'lishdi. Hoshim dushanba kuni qancha maosh olgan?",
      },
    ],
    options: [
      { type: "txt", value: "600$" },
      { type: "txt", value: "400$" },
      { type: "txt", value: "300$" },
      { type: "txt", value: "200$" },
    ],
    answer: 2,
  },
  {
    id: 25,
    question: [
      {
        type: "txt",
        value:
          "Quyidagi jadvalda uchta o‘quvchining matematika, ona tili,  tabiiy fanlar va ijtimoiy fanlardan olgan ballari  ko‘rsatilgan. Jadvalga ko’ra quyidagi mulohazalardan nechtasi noto'g'ri?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img11.png",
      },
      {
        type: "txt",
        value:
          "1) Matematikadan Salim eng yuqori ballni, eng past ballni Murod oldi.   2) Ona tilida eng yuqori ballni Sadoqat, eng past ballni Murod oldi.   3) Sadoqat barcha fandan eng yuqori ballarni, Murod barcha fandan eng kam ballarni oldi.   4)Murod ijtimoiy fanlardan eng yuqori ball oldi,  Sadoqat Matematikadan eng yuqori ball ololmadi.",
      },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 1,
  },
  {
    id: 26,
    question: [
      {
        type: "txt",
        value:
          "Jalol endi yilning qaysidir oyidan boshlab pullarni tejab ishlatishga ahd qildi. U birinchi oyda 15 dollar, ikkinchi oyda 30 dollar, uchinchi oyda 45 dollar va hokazolarni tejab qoldi. Yilning oxiriga kelib (dekabr oyi) uning jami tejab qolgan puli 540$ ni tashkil etdi. Jalol qaysi oydan boshlab pulini tejab ishlatishni boshlagan?",
      },
    ],
    options: [
      { type: "txt", value: "Mart" },
      { type: "txt", value: "Aprel" },
      { type: "txt", value: "May" },
      { type: "txt", value: "Iyun" },
      { type: "txt", value: "Iyul" },
    ],
    answer: 2,
  },
  {
    id: 27,
    question: [
      {
        type: "txt",
        value:
          "Do`konga bir nechta yashiklarda jami 180 kg meva keltirildi. Mevalarning yarmi olmalar yarmi esa noklardan iborat. Olma solingan yashik sig`imi nok solingan yashik sig`imidan 15 kg ko`p. Agar nok solingan yashiklar soni olma solingan yashiklar sonidan ikki marta ko`p bo`lsa, do`konga mevalar nechta yashikda keltirilgan?",
      },
    ],
    options: [
      { type: "txt", value: "12" },
      { type: "txt", value: "9" },
      { type: "txt", value: "10" },
      { type: "txt", value: "15" },
      { type: "txt", value: "14" },
    ],
    answer: 1,
  },
  {
    id: 28,
    question: [
      {
        type: "txt",
        value:
          "Lobar, Laylo, Rahima va Nozimalar dushanbadan shanba kunigacha har kuni ikki kishidan navbatchilik qilishdi. Lobar hammasi bo`lib 2 marta, Laylo undan ikki marta ko`p, Rahima esa Laylodan 3 ta kam navbatchilik qilgan. Nozima necha marta navbatchilik qilgan?",
      },
    ],
    options: [
      { type: "txt", value: "5" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "Aniqlab bo`lmaydi." },
    ],
    answer: 0,
  },
  {
    id: 29,
    question: [
      {
        type: "txt",
        value:
          "Dehqon o‘rib olgan bug‘doyini qoplarga to‘ldirdi. Buning uchun u ikki xil, sariq va oq qoplardan foydalandi. Sariq qoplarga jami 1368 kg, oq qoplarga esa jami 1180 kg bug‘doy to‘ldirdi. Sariq rangli qoplarning sig`imi 3kg, oq rangli qoplarning sig`imi esa 4kg. U urug‘lantirish uchun 57 ta sariq qop va 41 ta oq qopni ajratib o`ziga olib qo`ydi. Boshqa qoplarni esa sotib yubordi. Dehqon necha qop bug`doy sotgan?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img12.png",
      },
    ],
    options: [
      { type: "txt", value: "653" },
      { type: "txt", value: "751" },
      { type: "txt", value: "670" },
      { type: "txt", value: "740" },
    ],
    answer: 0,
  },
  {
    id: 30,
    question: [
      {
        type: "txt",
        value:
          "Hammomning pastki qismi rasmdagi kabi kvadratli va to’g’ri to'rtburchakli plitkalar bilan qoplangan. Bitta kvadrat  plitkaning perimetri 160 sm, bitta to’g’ri to'rtburchak plitkaning perimetri esa 320 sm. Hammomning pastki qismi yuzasi necha dm²?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img13.png",
      },
    ],
    options: [
      { type: "txt", value: "48000" },
      { type: "txt", value: "480" },
      { type: "txt", value: "57600" },
      { type: "txt", value: "576" },
    ],
    answer: 3,
  },
  {
    id: 31,
    question: [
      {
        type: "txt",
        value:
          "Kompaniya xodimlarining ish vaqti 09:00 dan 18:00 gacha bo`lib, tushlik vaqti 13:00 dan 14:00 gacha. Har bir xodim bitta mijoz uchun 25 daqiqa xizmat ko`rsatib, har to`rtta mijozga xizmat ko`rsatgandan so`ng 20 daqiqa dam oladi. Agar kompaniyada 8 ta xodim ishlasa ular bir kunda ko`pi bilan nechta mijozga xizmat ko`rsata oladi?",
      },
    ],
    options: [
      { type: "txt", value: "64" },
      { type: "txt", value: "48" },
      { type: "txt", value: "80" },
      { type: "txt", value: "128" },
    ],
    answer: 3,
  },
  {
    id: 32,
    question: [
      {
        type: "txt",
        value:
          "Javohir do‘stlari uchun 19:00 da iftorlik dasturxoni tayyorlayapti. U dasturxon uchun dastlab tovuq pishirmoqchi, uni pishirish 85 daqiqa davom etadi. Dastlab u pechni isitib, guruch va brokollini pechda pishirib olishi kerak. Agar unda faqatgina bitta pech bor bo`lsa, Iftorlikgacha ulgurishi uchun qanday eng kech vaqtda boshlay olishi mumkin? Guruchni pishirishga 45 daqiqa, brokollini pishirishga 10 daqiqa, pechni isitish uchun esa 15 daqiqa vaqt kerak.",
      },
    ],
    options: [
      { type: "txt", value: "15:00" },
      { type: "txt", value: "15:35" },
      { type: "txt", value: "16:25" },
      { type: "txt", value: "15:25" },
    ],
    answer: 2,
  },
  {
    id: 33,
    question: [
      {
        type: "txt",
        value:
          "Quyidagi jadvalda mahalliy futbol ligadagi yakuniy o’rinlar ko'rsatilgan. Beshta jamoa bir-biri bilan bir martadan o'ynagan. G'alaba uchun uch ochko va durang o'yinda har bir jamoa uchun bittadan ochko beriladi. Nechta o’yin durang bilan tugagan?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img14.png",
      },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "6" },
      { type: "txt", value: "8" },
    ],
    answer: 1,
  },
  {
    id: 34,
    question: [
      {
        type: "txt",
        value:
          "Havas supermarketida quyidagicha Aksiyalar bo’lmoqda. Faqatgina bitta aksiyadan foydalanish mumkin.    a)Agar xaridingiz 100 dollardan oshsa, 40% chegirma.   b)Agar siz to’rtadan ko’p mahsulotni sotib olsangiz, eng arzon 2 ta mahsulotingizni  bepul olasiz.  c)Agar siz to’rtadan ko’p mahsulotni sotib olsangiz, eng qimmat mahsulotingizni  bepul olasiz.    Men 5$, 15$ ,20$, 30$, 40$ lik mahsulotlarni  sotib oldim. Men tejab qolishim mumkin bo’lgan maksimal pul qancha bo’lishi mumkin?",
      },
    ],
    options: [
      { type: "txt", value: "20$" },
      { type: "txt", value: "44$" },
      { type: "txt", value: "40$" },
      { type: "txt", value: "33$" },
    ],
    answer: 1,
  },
  {
    id: 35,
    question: [
      {
        type: "txt",
        value:
          "Maftuna qo’lining bir qarichi uzunligini 20 sm deb o’ylaydi. U ukasining bo'yini 7 qarich o'lchadi va ukasining bo'yini taxmin qildi. Keyin u ukasining bo'yini lentali metr bilan o'lchadi va uni o'z taxmini bilan taqqosladi. Uning taxmini 14 sm ko'proq ekanligini hisobladi. Bunga ko'ra, Maftunaning bir qarichi aslida necha santimetr?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img15.png",
      },
    ],
    options: [
      { type: "txt", value: "22" },
      { type: "txt", value: "18" },
      { type: "txt", value: "19" },
      { type: "txt", value: "20" },
    ],
    answer: 1,
  },
  {
    id: 36,
    question: [
      {
        type: "txt",
        value:
          "Men ta`rif rejamni almashtirmoqchiman. Mening hozirgi “Aloqa” tarifim oylik to’lovi 30$ bo’lib, uning ichida 75 daqiqalik bepul qo'ng'iroqlar va 100 ta bepul SMS xabarlar bor. Qo'shimcha qo'ng'iroqlar narxi daqiqasiga 10 sent, qo'shimcha 1 dona SMS xabar ham 10 sent. Men o’tmoqchi bo’lgan “Qulay” tarifida oylik to‘lov olinmaydi, biroq barcha qo‘ng‘iroqlar narxi daqiqasiga 30 sent va SMS larning har biri 5  sent. Men odatda har oy 100 daqiqa qo'ng'iroq qilaman va 60 ta SMS yuboraman. Men uchun qaysi tarif yaxshiroq va undan qancha pul tejayman?",
      },
    ],
    options: [
      { type: "txt", value: "Aloqadan, 50 sent" },
      { type: "txt", value: "Qulay, 50 sent" },
      { type: "txt", value: "Ikkalasi ham bir xil" },
      { type: "txt", value: "Qulay, 1 dollar" },
    ],
    answer: 0,
  },
  {
    id: 37,
    question: [
      {
        type: "txt",
        value:
          "Elshod va uning do’sti Dilmurod bir-biridan 400 km uzoqlikda yashaydi. Bir kuni ular uchrashishmoqchi bo’lishdi. Elshod uyidan ertalab soat 08:00 da, Dilmurod  esa ayrim sabablarga ko’ra uyidan soat 10:00 da chiqishiga to’g’ri keldi. Ular ikkalasi ham mashinasida soatiga 120 km tezlikda harakatlanishadi. Ular soat nechada uchrashishadi?",
      },
    ],
    options: [
      { type: "txt", value: "10:20" },
      { type: "txt", value: "10:40" },
      { type: "txt", value: "11:20" },
      { type: "txt", value: "11:40" },
    ],
    answer: 1,
  },
  {
    id: 38,
    question: [
      {
        type: "txt",
        value:
          "Quyida jadvalda bir nechta avtomobillarning ma`lum bir masofada yura olishi uchun kerak bo`lgan benzin miqdori ko`rsatilgan. Qaysi avtomobilning benzin samaradorligi (km/litr) eng kam?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img16.png",
      },
    ],
    options: [
      { type: "txt", value: "Matiz" },
      { type: "txt", value: "Spark" },
      { type: "txt", value: "Cobalt" },
      { type: "txt", value: "Malibu" },
      { type: "txt", value: "Captiva" },
    ],
    answer: 5,
  },
  {
    id: 39,
    question: [
      {
        type: "txt",
        value:
          "Maishiy texnikalar do'konida har 1000$ xarid uchun 200$ sovg'a sertifikati beriladi. Akbar aka, rasmda ko'rsatilgan muzlatgich va televizorni shu do'kondan sotib oldi. Shundan so`ng berilgan sovg‘a sertifikatlarining hammasiga dazmol sotib oldi. Akbar akaning olgan mahsulotlarning umumiy miqdori aslida qancha edi?",
      },
    ],
    options: [
      { type: "txt", value: "5155$" },
      { type: "txt", value: "6155$" },
      { type: "txt", value: "5355$" },
      { type: "txt", value: "5900$" },
    ],
    answer: 1,
  },
  {
    id: 40,
    question: [
      {
        type: "txt",
        value:
          "Yondagi keyingi figura rasmdagi asl kuboiddan nechta kichik kub olib tashlab hosil qilingan?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img17.png",
      },
    ],
    options: [
      { type: "txt", value: "6" },
      { type: "txt", value: "7" },
      { type: "txt", value: "8" },
      { type: "txt", value: "9" },
    ],
    answer: 1,
  },
  {
    id: 41,
    question: [
      {
        type: "txt",
        value:
          "Men Karimdan cho‘ntagida qancha pul borligini so’radim. U 1so’mlik, 2so’mlik, 5so’mlik tangalar hammasi bo`lib 12 ta ekanligini, va 2so’mlik tangalar 1 so’mlik tangalardan ko’proq, shuningdek 5 so’mlik tanagalar 2 so’mlik tangalardan ko’proq ekanligini aytdi. Lekin bu ma’lumotlar menga Karimning jami necha so’m puli borligini aniqlash uchun yetarli emas. Menga yana qanday qo’shimcha ma’lumot bo’lsa, Karimning pulini necha so’m ekanligini hisoblay olaman?",
      },
    ],
    options: [
      { type: "txt", value: "Uning uchta 2so’mlik tangasi bor edi." },
      { type: "txt", value: "Pulning umumiy miqdori 10 ga karrali edi." },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img18.png",
      },
      {
        type: "txt",
        value:
          "Unda 1so’mlik va 2so’mlik dan ko'ra ikkita ko'proq 5so’mlik tanga bor edi.",
      },
    ],
    answer: 2,
  },
  {
    id: 42,
    question: [
      {
        type: "txt",
        value:
          "Bog'da to'rtburchaklar shaklidagi gulzor bor, uning eni 4 m, bo’yi enidan 3 marta katta.  Gulzorning  markazida tomoni 3m bo’lgan  kvadrat hosil qilinib, lolalar ekilgan. Maydonning  qolgan qismiga atirgullar ekilgan.  Atirgullar ekilgan maydon yuzini aniqlang.",
      },
    ],
    options: [
      { type: "txt", value: "39m²" },
      { type: "txt", value: "21m²" },
      { type: "txt", value: "40m²" },
      { type: "txt", value: "36m²" },
    ],
    answer: 0,
  },
  {
    id: 43,
    question: [
      {
        type: "txt",
        value:
          "Kamola film tomosha qilish uchun kinoteatrga keldi. Filmning boshlanish va tugash vaqti yon tomondagi rasmda ikkita soatda ko'rsatilgan. Film qancha vaqt davom etadi?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img19.png",
      },
    ],
    options: [
      { type: "txt", value: "1soat 45min" },
      { type: "txt", value: "2soat 45min " },
      { type: "txt", value: "2soat 15min" },
      { type: "txt", value: "3 soat" },
    ],
    answer: 1,
  },
  {
    id: 44,
    question: [
      {
        type: "txt",
        value:
          "Yon tomondagi rasmda to’rtta shaharni birlashtiruvchi yo’lning xaritasi ko’rsatilgan. Men Asten shahrida yashayman va odatda Carlstaddagi do’stimnikiga Byburg orqali boraman. Lekin bugun Byburg shahri  baxtsiz hodisa tufayli to’sib qo’yilganini aniqladim va boshqa yo’ldan borishimga to’g’ri keldi. Men oldingi yo’limga nisbatan qancha ko’p masofa bosib o’tishimga to’g’ri keladi(km)?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img20.png",
      },
    ],
    options: [
      { type: "txt", value: "9" },
      { type: "txt", value: "8" },
      { type: "txt", value: "10" },
      { type: "txt", value: "12" },
    ],
    answer: 1,
  },
  {
    id: 45,
    question: [
      {
        type: "txt",
        value:
          "Samolyot Los-Anjelesdan Mumbaygacha tinch havoda 21 soat parvoz qildi. Qaytguncha shamol bo’lib qolganligi sababli samolyot shamol yo’nalishida 20 soat parvoz qildi.  Agar Los –Anjelesdan Mumbaygacha  bo’lgan masofa 6300 km bo’lsa, shamolning o’rtacha tezligini toping(km/soat).",
      },
    ],
    options: [
      { type: "txt", value: "24" },
      { type: "txt", value: "15" },
      { type: "txt", value: "10" },
      { type: "txt", value: "20" },
    ],
    answer: 1,
  },
  {
    id: 46,
    question: [
      {
        type: "txt",
        value:
          "Rasmdagi doiraviy diagrammada davlatlarning maydoni ming km² da berilgan. Ushbu davlatlar orasidan eng katta hududli davlat maydoni Italiya maydonidan qancha ko`p (ming km²)",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img21.png",
      },
    ],
    options: [
      { type: "txt", value: "246" },
      { type: "txt", value: "455" },
      { type: "txt", value: "190" },
      { type: "txt", value: "265" },
    ],
    answer: 0,
  },
  {
    id: 47,
    question: [
      {
        type: "txt",
        value:
          "Maktabdagi 42 ta o‘quvchidan iborat guruhda barcha o‘quvchilar fizika fanini o`rganishadi. Ularning 9 nafari fizikadan boshqa Kimyo va Biologiya fanlarini, 15 nafari esa fizikadan boshqa faqatgina biologiya fanini o`rganadi. 12 nafar o’quvchi faqatgina fizika fanini o’rgansa, nechta o`quvchi fizika va kimyo fanlarini o`rganadi lekin biologiya fanini o`rganmaydi?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img22.png",
      },
    ],
    options: [
      { type: "txt", value: "8" },
      { type: "txt", value: "12" },
      { type: "txt", value: "6" },
      { type: "txt", value: "9" },
    ],
    answer: 2,
  },
  {
    id: 48,
    question: [
      {
        type: "txt",
        value:
          "Morniya orolida bitta temir yo'l bor bo`lib, u 4 ta bekatdan tashkil topgan. Enderbi va Widmut bekatlari orasida rasmda ko`rsatilganidek Maintown va Riverford bekatlari mavjud. Poyezd Enderbidan Widmutgacha 42 daqiqa, Enderbidan Riverfordgacha 24 daqiqa,  Maintowndan Vidmutgacha esa 36 daqiqa vaqt sarfladi. Agar poezd har bir bekatda 3 daqiqadan to`xtagan bo`lsa, poyezd Maintowndan Riverfordgacha masofani qancha vaqtda bosib o`tgan(min)? (bekatda kutish vaqti hisobga olinmasin)",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img23.png",
      },
    ],
    options: [
      { type: "txt", value: "9" },
      { type: "txt", value: "18" },
      { type: "txt", value: "15" },
      { type: "txt", value: "20" },
    ],
    answer: 1,
  },
  {
    id: 49,
    question: [
      {
        type: "txt",
        value:
          "Men dengiz bo'yidagi dam olish uyimdan ikkita mayoqni ko'raman. Janubdagisi har 11 sekundda bir marta miltiraydi.  Shimoliy mayoq esa har 10 sekundda ikki marta miltiraydi.  Birinchi miltirash har siklning 3-sekundida, ikkinchi miltirash esa sikl oxirida sodir bo’ladi. Ikkita mayoq bir vaqtda miltiragandan so`ng, yana eng kamida qancha vaqtdan so`ng ular bir paytda miltiraydi(sekund)?",
      },
    ],
    options: [
      { type: "txt", value: "110" },
      { type: "txt", value: "37" },
      { type: "txt", value: "44" },
      { type: "txt", value: "33" },
    ],
    answer: 3,
  },
  {
    id: 50,
    question: [
      {
        type: "txt",
        value: "Yon tomondagi rasmda nechta daftar katagi bo’yalgan?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img24.png",
      },
    ],
    options: [
      { type: "txt", value: "8" },
      { type: "txt", value: "12" },
      { type: "txt", value: "15" },
      { type: "txt", value: "20" },
    ],
    answer: 1,
  },
  {
    id: 51,
    question: [
      {
        type: "txt",
        value:
          "Yaqinda shahardagi kitob do’konidan yetita kitob olmoqchi bo’lib buyurtma berdim. Bu kitob do’konining yetkazib berish narxi quyidagi jadvalda ko’rsatilgan.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img25.png",
      },
      {
        type: "txt",
        value:
          "Men xarajatlar uchun eng kamida qancha pul to`lashim kerak(so`m)?",
      },
    ],
    options: [
      { type: "txt", value: "31500" },
      { type: "txt", value: "21500" },
      { type: "txt", value: "21000" },
      { type: "txt", value: "36500" },
    ],
    answer: 2,
  },
  {
    id: 52,
    question: [
      {
        type: "txt",
        value:
          "Mahalliy xayriya tashkiloti o'z jamg'armasi uchun jami 7000 dollar pul yig’ishi kerak edi. Bugungacha yig`ilgan pulning uchdan bir qismi, jami yig`ilishi kerak bo`lgan pulning o`ndan uch qismiga teng ekan. Xayriya tashkiloti jamg`armasi to`liq bo`lishi uchun yana necha dollar pul kerak?",
      },
    ],
    options: [
      { type: "txt", value: "700" },
      { type: "txt", value: "600" },
      { type: "txt", value: "800" },
      { type: "txt", value: "1000" },
    ],
    answer: 0,
  },
  {
    id: 53,
    question: [
      {
        type: "txt",
        value:
          "1-rasmda qonuniyat aniqlab, 2-rasmdagi so’roqlar o’rnida yashiringan belgilarni toping.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img26.png",
      },
    ],
    options: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img27.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img28.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img29.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img30.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img31.png",
      },
    ],
    answer: 1,
  },
  {
    id: 54,
    question: [
      {
        type: "txt",
        value:
          "Sitora hozirgi Charosning yoshida bo’lganida, U  Charosdan uch baravar katta bo`lar edi. Charos Sitoraning hozirgi yoshiga kirganida esa Sitora 56 yoshda bo`lib qoladi. Hozir Sitora necha yoshda?",
      },
    ],
    options: [
      { type: "txt", value: "24" },
      { type: "txt", value: "40" },
      { type: "txt", value: "48" },
      { type: "txt", value: "50" },
    ],
    answer: 1,
  },
  {
    id: 55,
    question: [
      {
        type: "txt",
        value:
          "12 ta bir xil olma rasmdagi kabi idishga solib tarozining o’ng pallasiga qo’yildi. Bo’sh idishning massasi yarim kg ni tashkil qiladi. Har bir olmaning massasi 125 gramm. Shunga ko'ra, tarozi muvozanatda bo`lishi uchun har birining massasi",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img32.png",
      },
      {
        type: "txt",
        value:
          "kg bo'lgan nechta anorni tarozining chap pallasiga  qo'yish kerak? ",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img33.png",
      },
    ],
    options: [
      { type: "txt", value: "8" },
      { type: "txt", value: "7" },
      { type: "txt", value: "6" },
      { type: "txt", value: "4" },
    ],
    answer: 0,
  },
  {
    id: 56,
    question: [
      {
        type: "txt",
        value:
          "Soat 8:00 da poyezd Toshkentdan Samarqandga, boshqa poyezd esa Samarqanddan Toshkentga jo‘nadi. Poyezdlar to‘xtamasdan o’zgarmas tezlik bilan harakatlanyapti. Birinchi poyezdga safarni yakunlash uchun besh soat, ikkinchi poyezdga esa yetti soat vaqt kerak bo‘ladi. Agar Toshkentdan Samarqandgacha bo’lgan masofa 420 km bo’lsa, Poezdlar soat nechada uchrashadilar?",
      },
    ],
    options: [
      { type: "txt", value: "10:25" },
      { type: "txt", value: "10:55" },
      { type: "txt", value: "11:25" },
      { type: "txt", value: "11:35" },
    ],
    answer: 1,
  },
  {
    id: 57,
    question: [
      {
        type: "txt",
        value:
          "Tom uch kun davomida  sichqonlarni tutdi. Har kuni Tom oldingi kunga qaraganda 2 ta ko'p sichqon tutdi. Tom shu uch kun ichida jami nechta sichqon tutgan bo’lishi mumkin?",
      },
    ],
    options: [
      { type: "txt", value: "24" },
      { type: "txt", value: "13" },
      { type: "txt", value: "38" },
      { type: "txt", value: "16" },
    ],
    answer: 0,
  },
  {
    id: 58,
    question: [
      {
        type: "txt",
        value:
          "Bugun shanba va Sobir tug'ilgan kuni haftaning qaysi kuniga to'g'ri kelishini bilish uchun kalendarni tekshirdi.  Hisobiga ko’ra uning tug'ilgan kuni 55 kundan keyin kelar ekan.  Topingchi, Sobirning yaqinlashayotgan tug`ilgan kuni haftaning qaysi kunida?",
      },
    ],
    options: [
      { type: "txt", value: "Seshanba" },
      { type: "txt", value: "Chorshanba" },
      { type: "txt", value: "Payshanba" },
      { type: "txt", value: "Juma" },
      { type: "txt", value: "Shanba" },
    ],
    answer: 3,
  },
  {
    id: 59,
    question: [
      {
        type: "txt",
        value:
          "Sanjar yon tomondagi kvadratlardan iborat birlashtirilgan shakldan kub hosil qildi. U hosil qilgan kub quyidagilardan qaysi biri bo`lishi mumkin?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img34.png",
      },
    ],
    options: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img35.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img36.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img37.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img38.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img38.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img39.png",
      },
    ],
    answer: 4,
  },
  {
    id: 60,
    question: [
      {
        type: "txt",
        value:
          "Yon tomondagi rasmda har bir mashqda to`g`ri tengliklar uchun yashil quti, notog`ri tengliklar uchun esa qizil quti tanlandi. Mashqlar to`liq bajarilgandan so`ng tanlangan qutilardagi harflar yuqoridan pastga qarab o`qilganda qanday so`z hosil bo`ladi.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img40.png",
      },
    ],
    options: [
      { type: "txt", value: "KREM" },
      { type: "txt", value: "ATAM" },
      { type: "txt", value: "KRAL" },
      { type: "txt", value: "ATEL" },
    ],
    answer: 2,
  },
  {
    id: 61,
    question: [
      {
        type: "txt",
        value:
          "Turnirda olti nafar tennischi ishtirok etdi. Ma`lumki tennisda o`yin g`olib aniqlanguncha davom etadi. Har bir o'yinchi boshqa o'yinchi bilan faqat bir martadan o`ynaydi. Agar Xakim 4 o'yinda, Ilyos 3 o'yinda, Jamshid 2 o'yinda, Kozim 2 o'yinda va Laziz ham 2 o'yinda g'alaba qozongan bo'lsa, Mohir nechta o'yinda g'alaba qozongan?",
      },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 1,
  },
  {
    id: 62,
    question: [
      {
        type: "txt",
        value:
          "Tezligi 30 metr/sekund  bo‘lgan poyezd 200 m uzunlikdagi tunneldan 15 soniyada o‘tsa, poyezdning uzunligini toping(m).",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img41.png",
      },
    ],
    options: [
      { type: "txt", value: "150" },
      { type: "txt", value: "200" },
      { type: "txt", value: "250" },
      { type: "txt", value: "300" },
      { type: "txt", value: "450" },
    ],
    answer: 2,
  },
  {
    id: 63,
    question: [
      { type: "txt", value: "? belgisi o`rnida qaysi figura bo`lishi kerak?" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img42.png",
      },
    ],
    options: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img43.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img44.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img45.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img46.png",
      },
    ],
    answer: 2,
  },
  {
    id: 64,
    question: [
      {
        type: "txt",
        value:
          "Bir necha yil oldin, Oktabr oyida 4 ta Payshanba va 5 ta Juma bo’lgan. Shu oyning 20-kuni haftaning qaysi kuni bo’ladi?",
      },
    ],
    options: [
      { type: "txt", value: "Dushanba" },
      { type: "txt", value: "Seshanba" },
      { type: "txt", value: "Chorshanba" },
      { type: "txt", value: "Payshanba" },
      { type: "txt", value: "Yakshanba" },
    ],
    answer: 2,
  },
  {
    id: 65,
    question: [
      {
        type: "txt",
        value:
          "Jasur tomayotgan jo‘mrak ostiga chelak qo‘ydi. Jo’mrakdan 1 daqiqada 3 ml suv tomadi. Chelak ertasi kuni xuddi shu vaqtga qadar jo'mrak ostida qoladi. Agar Jasurning chelagi sig`imi 5 litr bo’lsa, u to’lishi uchun yana necha ml suv kerak bo`ladi?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img47.png",
      },
    ],
    options: [
      { type: "txt", value: "500" },
      { type: "txt", value: "750" },
      { type: "txt", value: "780" },
      { type: "txt", value: "680" },
    ],
    answer: 3,
  },
  {
    id: 66,
    question: [
      {
        type: "txt",
        value:
          "Kulol 30 ta ko’zani bozorga olib borish uchun arava yolladi. Bozorga olib kelingan har bir butun ko’za uchun kulol aravakashga 2000 so’m beradi. Sindirib qo’yilgan har bir ko’za uchun aravakash kulolga 4000 so’m to`laydi. Yo’lda bir nechta ko’za sinib qoldi va kulol aravakashga 30000 so’m berdi. Nechta ko’za sinib qolgan edi?",
      },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "5" },
      { type: "txt", value: "7" },
      { type: "txt", value: "4" },
      { type: "txt", value: "8" },
    ],
    answer: 1,
  },
  {
    id: 67,
    question: [
      {
        type: "txt",
        value:
          "Rebusdan A + B - C ni toping( har xil harflar har xil raqamlarni, bir xil harflar bir xil raqamlarni ifodalaydi).",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img48.png",
      },
    ],
    options: [
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
      { type: "txt", value: "7" },
      { type: "txt", value: "8" },
      { type: "txt", value: "9" },
    ],
    answer: 3,
  },
  {
    id: 68,
    question: [
      {
        type: "txt",
        value:
          "Men odatda uyimiz yaqinidagi dehqon bozordan savdo qilaman. Men sotuvchidan apelsin narxini so’radim. Uning aytishicha, apelsin va limon narxi birga 2 dollar. Keyin u qulupnay va limonning narxi birgalikda 3 dollar ekanligini va uchalasining narxi har xil ekanligini aytdi. Bu ma'lumotlarga asoslanib, quyidagilardan qaysi birini aniq aytish mumkin?",
      },
    ],
    options: [
      { type: "txt", value: "Apelsinning narxi limondan qimmatroq." },
      { type: "txt", value: "Limon qulupnaydan qimmatroq." },
      { type: "txt", value: "Qulupnayning narxi bir dollardan qimmatroq." },
      { type: "txt", value: "apelsin narxi bir dollardan kam." },
      { type: "txt", value: "to`g`ri javob yo`q." },
    ],
    answer: 2,
  },
  {
    id: 69,
    question: [
      {
        type: "txt",
        value: "? belgisi o`rnida quyidagilardan qaysi biri bo`la oladi?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img49.png",
      },
    ],
    options: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img50.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img51.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img52.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img53.png",
      },
    ],
    answer: 3,
  },
  {
    id: 70,
    question: [
      {
        type: "txt",
        value:
          "Palov tayyorlash uchun guruch va sabzi teng miqdorda solinadi. Go‘sht guruchdan 2 marta kam, yog‘ go’shtdan 3 marta kam, piyoz esa yog’dan 2 marta ko’p ishlatiladi. Agar 12 kg sabzidan palov tayyorlanmoqchi bo‘linsa, bu palov uchun necha kg piyoz kerak?",
      },
    ],
    options: [
      { type: "txt", value: "5" },
      { type: "txt", value: "4" },
      { type: "txt", value: "3" },
      { type: "txt", value: "6" },
      { type: "txt", value: "9" },
    ],
    answer: 1,
  },
  {
    id: 71,
    question: [
      {
        type: "txt",
        value:
          "Muslimbek soat 16:15 da darsga kirdi. U 2 soat 43 daqiqadan so’ng qo’l soatiga qaraganida soat necha edi? ",
      },
    ],
    options: [
      { type: "txt", value: "18:56" },
      { type: "txt", value: "18:57" },
      { type: "txt", value: "18:58" },
      { type: "txt", value: "18:59" },
    ],
    answer: 2,
  },
  {
    id: 72,
    question: [
      {
        type: "txt",
        value:
          "Farhod bir sutkaning yarmini uxlash hamda ovqatlanishga, choragini maktabda bo‘lishga,",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img54.png",
      },
      {
        type: "txt",
        value:
          "qismini o‘yinga va televizor ko‘rishga sarfladi. Sutkaning qolgan qismida dars tayyorladi. U necha soat dars tayyorlagan?",
      },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "yarim soat" },
      { type: "txt", value: "1" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 0,
  },
  {
    id: 73,
    question: [
      {
        type: "txt",
        value:
          "Quyidagi rasm doimiy ravishda takrorlanadi. Shunga ko'ra, 2008-2009-2010-nomerlardan hosil qilingan shakl  qaysi javobda to’g’ri ko’rsatilgan?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img55.png",
      },
    ],
    options: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img56.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img57.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img58.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img59.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img60.png",
      },
    ],
    answer: 3,
  },
  {
    id: 74,
    question: [
      {
        type: "txt",
        value:
          "Anora doskaga barcha tomonlari 16 ga teng bo`lgan uchburchak, Sarvar esa kvadrat chizdi. Agar ular chizgan figuralar perimetrlari teng bo`lsa, Sarvar chizgan kvadrat yuzini toping.",
      },
    ],
    options: [
      { type: "txt", value: "128" },
      { type: "txt", value: "144" },
      { type: "txt", value: "81" },
      { type: "txt", value: "100" },
    ],
    answer: 1,
  },
  {
    id: 75,
    question: [
      {
        type: "txt",
        value:
          "A va B daraxtlarning uzunligi rasmda ko’rsatilgan. A daraxt har yili 3 sm o'sadi; B daraxt esa har yili 5 sm o'sadi. Rasmda berilgan ma`lumotlarga koʻra, necha yildan so`ng B daraxt A daraxtdan uzunroq bo`ladi?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img61.png",
      },
    ],
    options: [
      { type: "txt", value: "24" },
      { type: "txt", value: "25" },
      { type: "txt", value: "26" },
      { type: "txt", value: "27" },
      { type: "txt", value: "28" },
    ],
    answer: 3,
  },
  {
    id: 76,
    question: [
      {
        type: "txt",
        value: "Nechta uch xonali sonning oxirgi raqami 5 ga teng?",
      },
    ],
    options: [
      { type: "txt", value: "90" },
      { type: "txt", value: "100" },
      { type: "txt", value: "99" },
      { type: "txt", value: "80" },
    ],
    answer: 0,
  },
  {
    id: 77,
    question: [
      {
        type: "txt",
        value:
          "Anvar olti marta matematika fanidan imtihon topshirganda uning o`rtacha bali 82 ball edi. U yana bir marta imtihon topshirgandan so`ng uning o`rtacha bali 84 ball bo`ldi. Anvar oxirgi imtihonda necha bal olgan edi?",
      },
    ],
    options: [
      { type: "txt", value: "92" },
      { type: "txt", value: "84" },
      { type: "txt", value: "88" },
      { type: "txt", value: "96" },
    ],
    answer: 3,
  },
  {
    id: 78,
    question: [
      {
        type: "txt",
        value:
          "Minskdan Moskvagacha bo'lgan masofa 714 km. Minskdan Moskvaga 60 km/soat tezlikda yo‘lovchi poyezd jo‘nab ketdi. Oradan olti soat o`tgandan keyin soat 20:55 da u, 17:55 da Moskvadan Minskka jo'nab ketgan tezyurar poyezdni uchratdi. Tezyurar poyezdning tezligi qanday  bo`lgan(km/soat)?",
      },
    ],
    options: [
      { type: "txt", value: "118" },
      { type: "txt", value: "120" },
      { type: "txt", value: "122" },
      { type: "txt", value: "124" },
    ],
    answer: 0,
  },
  {
    id: 79,
    question: [
      { type: "txt", value: "Quyidagi figuraning yuzini toping. (m²)" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/problem-solving/img62.png",
      },
    ],
    options: [
      { type: "txt", value: "100" },
      { type: "txt", value: "124" },
      { type: "txt", value: "110" },
      { type: "txt", value: "144" },
    ],
    answer: 1,
  },
];

export const prezidentSchoolCriticalThinking = [
  {
    id: 0,
    question: [
      {
        type: "txt",
        value:
          "Universitetda o`qiydigan barcha jiddiy talabalar baxtli talabalardir va barcha jiddiy talabalar magistraturada o`qiydilar. Bundan tashqari, magistraturaga boradigan barcha talabalar  o`qishdan tashqari ishlaydi. Yuqoridagi gaplardan quyidagilardan qaysi birini xulosa qilish mumkin?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "Barcha ishlaydigan talabalar baxtli talabalardir.",
      },
      { type: "txt", value: "Barcha baxtli talabalar ishlaydi." },
      {
        type: "txt",
        value: "Barcha ishlaydigan talabalar jiddiy talabalardir.",
      },
      { type: "txt", value: "Jiddiy bo`lmagan talabalar baxtsizdir." },
      { type: "txt", value: "Barcha jiddiy talabalar ishlaydi." },
    ],
    answer: 4,
  },
  {
    id: 1,
    question: [
      {
        type: "txt",
        value:
          "Prezident maktabi kirish imtihonidan o`tish uchun matematika va ingliz tili fanlarini yaxshi bilish kerak. U holda quyidagilardan kimning gapi to`g`ri? Alibek: Ibrohim matematikani yaxshi biladi shuning uchun u imtihondan o`ta oladi. Farrux: Ibrohim ingliz tilini yaxshi o`rgana olmadi. Shuning uchun u imtihondan o`ta olmaydi?",
      },
    ],
    options: [
      { type: "txt", value: "Faqat Alibekning" },
      { type: "txt", value: "Faqat Farruxning" },
      { type: "txt", value: "Ikkalasining ham gapi to`g`ri" },
      { type: "txt", value: "Ikkalasining ham gapi noto`g`ri" },
    ],
    answer: 1,
  },
  {
    id: 2,
    question: [
      {
        type: "txt",
        value:
          "Ibrohim, Jaloliddin, Alibek, Usmon, Aziz va Sardor bir jamoada o`ynaydilar. Murabbiy o`yindan oldin ularga to`satdan quyidagi gaplarni aytdi:“Ibrohim va Jaloliddin birga maydonda o`ynamaydi” “Alibek va Jaloliddin ham birga maydonda o`ynamaydi” “Alibek o`ynamasa u holda Usmon Sardorning o`rniga o`ynaydi” “Agar Alibek o`ynasagina Aziz ham o`ynaydi”. Agar Jalol o`ynasa, u holda yana kimlar    o`ynaydi?",
      },
    ],
    options: [
      { type: "txt", value: "Aziz va Usmon" },
      { type: "txt", value: "Usmon" },
      { type: "txt", value: "Sardor" },
      { type: "txt", value: "Sardor va Aziz" },
    ],
    answer: 1,
  },
  {
    id: 3,
    question: [
      {
        type: "txt",
        value:
          "Dan xodimi shunday dedi. “Mashina mingan har bir kishi xavsfsizlik kamarini taqishi kerak”. Quyidagi firklarning qaysi biri Dan xodimi fikriga qo`shilishingizga asos bo`ladi?",
      },
    ],
    options: [
      {
        type: "txt",
        value:
          "Xavfsizlik kamarlarini taqish haydovchiga noqulaylik tug`diradi.",
      },
      {
        type: "txt",
        value: "Mototsikl haydovchi kishilar ham himoya uchun kaska kiyishadi.",
      },
      {
        type: "txt",
        value:
          "Agar xavfsizlik kamarini taqsangiz jiddiy jarohat olish ehtimolingiz kamayadi.",
      },
      {
        type: "txt",
        value: "Odatda ko`pchilik haydovchilar xavfsizlik kamarini taqmaydi.",
      },
    ],
    answer: 2,
  },
  {
    id: 4,
    question: [
      {
        type: "txt",
        value:
          "Supermarketda har 400 minglik xarid uchun xaridorlarga 5 foiz chegirma beriladi. Aziz supermarketga kirib 300 ming so`mlik xarid qildi, shuning uchun unga 5 foizlik chegirma berilmaydi. Quyidagilardan qaysi biri yuqoridagi gaplarga mantiqan o`xshash tamoyilga ega?",
      },
    ],
    options: [
      {
        type: "txt",
        value:
          "Supermarket o`zining doimiy mijozlariga har doim chegirma qilib beradi. Aziz supermarketning doimiy mijozi shuning uchun u har doim chegirmaga ega.",
      },
      {
        type: "txt",
        value:
          "Korxona bir kunda 120 ta detal yasagan ishchisiga qo`shimcha maosh beradi. Aziz 95 ta detal yasagan edi, shuning uchun u qo`shimcha maosh ololmaydi.",
      },
      {
        type: "txt",
        value:
          "Azizning puli kam edi shuning uchun u 300 ming so`mlik xarid qilgan edi.",
      },
      {
        type: "txt",
        value:
          "Do`konda xarid qilingan har uchta o`yinchoqga yana qo`shimcha bepul o`yinchoq olish aksiyasi o`tkazilyabdi. Aziz uchta o`yinchoq olgan edi unga bepul yana bitta o`yinchoq berildi. Azizda endi to`rtta o`yinchoq bor.",
      },
    ],
    answer: 1,
  },
  {
    id: 5,
    question: [
      {
        type: "txt",
        value:
          '"4-A" sinf o`quvchilari orasida so`rovnoma o`tkazilganda o`quvchilarning yarmi offline o`qishni qolgan yarmi esa online o`qishni tanlashdi. Ma`lum vaqt o`tgandan keyin shu aniq bo`ldiki, online o`qiyotgan ko`pchilik o`quvchilarning imtihon natijalari hamda darslarni o`zlashtirish ko`rsatkichlari offline o`qiyatganlarga nisbatan ancha pastroq ekan. Shundan so`ng online o`qish offline o`qishga nisbatan samarali emas deb xulosa qilindi. Quyidagilardan qaysi biri yuqoridagi xulosa to`g`ri ekanligini zaiflashtiradi?',
      },
    ],
    options: [
      {
        type: "txt",
        value:
          "So`rovnomada o`quvchilar emas o`qituvchilar qatnashishi kerak edi?",
      },
      {
        type: "txt",
        value:
          "O`quvchilar ularning o`qituvchisi yonida bo`lsagina darslarni yaxshi o`zlashtirishadi.",
      },
      {
        type: "txt",
        value:
          "So`rovnomada o`zlashtirishi oldindan past bo`lgan aksar o`quvchilar online o`qishni tanlashgan edi.",
      },
      {
        type: "txt",
        value:
          "Offline o`qishda online o`qishdagiga nisbatan bolalarni nazorat qilish osonroq bo`ladi.",
      },
    ],
    answer: 2,
  },
  {
    id: 6,
    question: [
      {
        type: "txt",
        value:
          "Sarguzashtsevar kishilar tavakkal qilishdan qo`rqishmaydi. Ushbu ma`lumotdan quyidagi gaplarning qaysi biri to`g`ri ekanligini aniqlang.     Baxodir: Mening tog`am tavakkal qilishdan qo`rqmas edi, demak u sarguzashtsevar inson.     Nodira: Mening amakim sarguzashtsevar, shuning uchun u doim tavakkal qillib yuradi.",
      },
    ],
    options: [
      { type: "txt", value: "Faqat Baxodirning." },
      { type: "txt", value: "Faqat Nodiraning." },
      { type: "txt", value: "Ikkalasining ham gapi to`g`ri." },
      { type: "txt", value: "Ikkalasining ham gapi notog`ri." },
    ],
    answer: 3,
  },
  {
    id: 7,
    question: [
      {
        type: "txt",
        value:
          "Yugurish musobaqasida yakuniy natijalarni ko`rgan murabbiy Kozimning natijasidan xursand bo`ldi lekin Sobirning natijasidan xafa bo`ldi. Ushbu ma`lumotdan quyidagilardan qaysi birini xulosa qilishimiz mumkin?",
      },
    ],
    options: [
      {
        type: "txt",
        value:
          "Sobir musobaqada yaxshi natija ko`rsata olmadi, Kozim esa yaxshi natija ko`rsatdi.",
      },
      { type: "txt", value: "Kozim Sobirdan marraga oldinroq keldi." },
      {
        type: "txt",
        value:
          "Murabbiyga Sobirning natijasi yoqmadi, Kozimning natijasi esa yoqdi.",
      },
      {
        type: "txt",
        value:
          "Murabiy Sobirdan yaxshiroq natija kutgandi, Kozimdan esa bunaqa natija ko`rsata olishini kutmagan edi.",
      },
      { type: "txt", value: "Barcha javoblar to`g`ri." },
    ],
    answer: 2,
  },
  {
    id: 8,
    question: [
      {
        type: "txt",
        value:
          "Farruxning barcha do`stlari “Coca-Cola” ichimligini yaxshi ko`radi. Ushbu gapdan quyidagilardan qaysi birini aniq ayta olish mumkin?",
      },
    ],
    options: [
      {
        type: "txt",
        value:
          "Aziz “Coca-Cola” ichimligini yaxshi ko`radi, demak u Farrux bilan do`st.",
      },
      {
        type: "txt",
        value:
          "Sardor “Pepsi”ni yaxshi ko`radi, demak u Farrux bilan do`st emas.",
      },
      {
        type: "txt",
        value: "Demak Farruxning do`stlari “Fanta” ichimligini yoqtirishmaydi.",
      },
      {
        type: "txt",
        value:
          "Anvar “Coca-Cola”ni yoqtirmaydi, demak u Farrux bilan do`st emas.",
      },
      {
        type: "txt",
        value: "Demak Farruxning do`stlari “Fanta”ni yoqtirishmaydi.",
      },
    ],
    answer: 3,
  },
  {
    id: 9,
    question: [
      {
        type: "txt",
        value:
          "Oromgohdagi barcha o`g`il bolalar futbolni yaxshi ko`rishadi. Futbolni yaxshi ko`radiganlar esa suzishni ham yaxshi ko`rishadi. Tennisni yaxshi ko`radiganlar ham suzishni yaxshi ko`rishadi. Ushbu ma`lumotlarga asoslanib, quyidagilardan qaysi biri to`g`rib o`lishi mumkin ekanligini aniqlang.",
      },
    ],
    options: [
      { type: "txt", value: "Qizlar futbolni yaxshi ko`rishmaydi." },
      {
        type: "txt",
        value: "Tennisni yaxshi ko`radiganlar futbolni ham yaxshi ko`radi.",
      },
      {
        type: "txt",
        value: "Suzishni yaxshi ko`radiganlar tennisni ham yaxshi ko`radi.",
      },
      {
        type: "txt",
        value:
          "Ba`zi bolalar ( 1 yoki undan ko`p) tennisni ham futbolni ham yaxshi ko`radi.",
      },
      { type: "txt", value: "To`g`ri javob yo`q." },
    ],
    answer: 4,
  },
  {
    id: 10,
    question: [
      {
        type: "txt",
        value:
          "Matematika fanini yaxshiroq o`rganish uchun yaxshi o`qituvchi va tinimsiz mehnat qilish kerak. Ushbu ma`lumotdan kimning gapi to`g`ri ekanligini aniqlang. Anvar: Muhammadaminda yaxshi o`qituvchi bor hamda u tinimsiz mehnat qiladi. Shuning uchun u matematikani yaxshi o`rganadi.Aziz: Sanjar matematikani yaxshi o`rgangan. Demak uning ustozi yaxshi bo`lgan. Laylo: Sarvar matematikani yaxshi o`rganmagan, demak u tinimsiz mehnat qilmagan yoki yaxshi ustozi bo`lmagan.",
      },
    ],
    options: [
      { type: "txt", value: "Faqat Anvar va Aziz.	" },
      { type: "txt", value: "Faqat Anvar va Laylo." },
      { type: "txt", value: "Faqat Laylo." },
      { type: "txt", value: "Faqat Aziz." },
      { type: "txt", value: "Barchasini gapi to`g`ri.	" },
      { type: "txt", value: "Barchasining gapi notog`ri." },
    ],
    answer: 3,
  },
  {
    id: 11,
    question: [
      {
        type: "txt",
        value:
          "Zavodda ishlaydigan ayollarning 30 foizi, erkaklarning esa 20 foizi oila qurgan. Demak zavodda oilali ayollar oilali erkaklardan ko`p ekan. Quyidagilardan qaysi biri yuqoridagi xulosada mantiqiy xatolik borligini ko`rsatib beradi?",
      },
    ],
    options: [
      {
        type: "txt",
        value:
          "Zavodlarda ayollarga mos ish o`rinlari kam, shuning uchun zavodlarda ayollar kamroq ishlashi inobatga olinmagan.",
      },
      {
        type: "txt",
        value:
          "Odatda tungi navbatchiliklarda ayollar emas faqat erkaklar ishlashligi inobatga olinmagan.",
      },
      {
        type: "txt",
        value:
          "Bazida kichik miqdorning katta foizi, katta miqdorning kichik foizidan kam bo`lishi mumkin.",
      },
      {
        type: "txt",
        value:
          "Odatda erkaklarning oila qurish yoshi ayollarnikidan kattaroq ekanligi hisobga olinmagan.",
      },
      { type: "txt", value: "Barcha javoblar to`g`ri." },
    ],
    answer: 2,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value:
          "O`qituvchilar shokolad iste`mol qilish miya faoliyatini yaxshilashini ta`kidlashadi. Stomatologlar esa shokolad iste`moli tishlar uchun zararli ekanligini aytishadi.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/prezident-maktabi/tanqidiy-fikrlash/img1.png",
      },
      { type: "txt", value: "Shamsiddin qanday xatoga yo`l qo`ydi?" },
    ],
    options: [
      { type: "txt", value: "U kimnidir aybdor qilib ko`rsatishga urinmoqda." },
      {
        type: "txt",
        value:
          "U o`qituvchilariga shokolad yemasligi uchun stomatologlar fikrini baxona qilib ko`rsatmoqchi.",
      },
      {
        type: "txt",
        value:
          "U stomatologlarga shokolad yeyish uchun o`qituvchilar fikrini baxona qilib ko`rsatmoqchi.",
      },
      {
        type: "txt",
        value:
          "U ba`zi narsalar bir vaqtning o`zida ham foydali ham zararli bo`lishi mumkin ekanligini o`ylamadi. ",
      },
      {
        type: "txt",
        value:
          "B va C javoblar birgalikda to`g`ri javobni hosil qilishi mumkin.",
      },
    ],
    answer: 3,
  },
  {
    id: 13,
    question: [
      {
        type: "txt",
        value:
          "Shifokorlar bemorlar bilan tibbiy niqoblarni taqib muloqot qilishi kerak. Quyidagilarning qaysi biri sizning yuqoridagi fikrga qo`shilishingizga asos bo`ladi?",
      },
    ],
    options: [
      {
        type: "txt",
        value:
          "Tibbiy niqobning sifatlisini taqish kerak, chunki sifatli niqob sifatsizidan ko`ra yaxshiroq himoya qiladi.",
      },
      {
        type: "txt",
        value:
          "Niqoblar oq yoki ko'k rangda bo'lishi kerak, chunki shifokorlar kiyimi odatda oq yoki ko'k rangda bo'ladi. Agar kishi niqob taqsa, uning kasallik yuqtirib olish ehtimoli kamayadi.",
      },
      {
        type: "txt",
        value:
          "Agar kishi niqob taqsa, uning kasallik yuqtirib olish ehtimoli kamayadi.",
      },
      {
        type: "txt",
        value:
          "Turli labaratoriyalarda ishlovchilar ham tibbiy niqob ta`qib yurishadi.",
      },
      {
        type: "txt",
        value:
          "O`zbekiston hukumati tomonidan “Pandemiya” davrida tibbiy niqob taqish majburiy qilib belgilandi.",
      },
    ],
    answer: 2,
  },
  {
    id: 14,
    question: [
      {
        type: "txt",
        value:
          "Farida, Aziz, Nasiba, Jahongir va Nodiralar bitta sinfda o`qiydilar. Ular dushanbadan jumagacha almashib, har doim bir xil tartibda navbatchilik qilishar edilar. Lekin oxirgi haftada dushanba kuni navbatchilik qilishi kerak bo`lgan Nasiba kasal bo`lib qolgani uchun o`zining navbatini juma kunlari navbatchilik qiladigan Nodira bilan almashdi. Agar Aziz Faridadan keyin, Jahongir esa Faridadan oldin navbatchilik qilgan bo`lsa, sheshanba va chorshanba kunlari kimlar navbatchilik qilgan?",
      },
    ],
    options: [
      { type: "txt", value: "Aziz va Jahongir" },
      { type: "txt", value: "Aziz va Farida" },
      { type: "txt", value: "Jahongir va Farida" },
      { type: "txt", value: "Nasiba va Farida	" },
      { type: "txt", value: "Ma`lumot yetarli emas." },
    ],
    answer: 2,
  },
  {
    id: 15,
    question: [
      {
        type: "txt",
        value:
          "Sariq rangni yaxshi ko`radigan kishilar yashil rangni ham yaxshi ko`rishadi. Ko`k rangni yaxshi ko`radigan kishilar ham yashil rangni yaxshi ko`rishadi. Agar qizil rangni yaxshi ko`radigan kishilar sariq rangni ham ko`k rangni ham yoqtirmasa quyidagilardan qaysi biri har doim to`g`ri bo`la oladi?",
      },
    ],
    options: [
      {
        type: "txt",
        value:
          "Sariq rangni yaxshi ko`radigan kishilar ko`k rangni ham yaxshi ko`rishadi.",
      },
      {
        type: "txt",
        value: "Ko`k rangni yoqtiradigan kishilar qizil rangni yoqtirmaydi.",
      },
      {
        type: "txt",
        value:
          "Sariq rangni ham ko`k rangni ham birdaniga yaxshi ko`rib bo`lmaydi.",
      },
      {
        type: "txt",
        value:
          "Qizil rangni yaxshi ko`radigan kishilar yashil rangni yoqtirmaydi.",
      },
      {
        type: "txt",
        value:
          "Ko`k rangni va sariq rangni yoqtirmaydigan kishilar qizil rangni yoqtiradi.",
      },
    ],
    answer: 1,
  },
  {
    id: 16,
    question: [
      {
        type: "txt",
        value:
          "Ma`lumki maktabda bir yillik o`qish to`rt chorakdan iborat. To`rtinchi chorakda Anvar 5 baho oldi, Sanjar esa 4 baho oldi. Shu narsa ma`lum bo`ldiki, Anvarning bir yillik o`rtacha bahosi Sanjarnikidan pastroq ekan. Ushbu ma`lumotlardan quyidagilardan qaysi birini xulosa qilishimiz mumkin?",
      },
    ],
    options: [
      {
        type: "txt",
        value:
          "Sanjarning oldingi choraklardagi baholari yig`indisi Anvarnikidan ko`proq.",
      },
      { type: "txt", value: "Sanjar oldingi choraklarda 5 bahoga o`qigan." },
      {
        type: "txt",
        value: "Anvar oldingi choraklarda imtihonlardan o`ta olmagan.",
      },
      {
        type: "txt",
        value: "Sanjar to`rtinchi chorakda munosib baholanmagan.",
      },
      { type: "txt", value: "Barcha javoblar to`g`ri." },
    ],
    answer: 0,
  },
  {
    id: 17,
    question: [
      {
        type: "txt",
        value:
          "Quyoshning nurlari ertalabgi vaqtlarda odam terisi uchun foydali. Ushbu fikr quyidagilarning qaysi biri to`g`ri ekanligiga dalil bo`la oladi?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "Darslarni ertalab qilish o`quvchilar uchun ancha foydali.",
      },
      {
        type: "txt",
        value: "Erta tongda vaqtli turib darslarni tayyorlash samaraliroq.",
      },
      {
        type: "txt",
        value:
          "Shifokorlarning ta`kidlashicha yosh bolalarni 10:00 gacha quyoshga olib chiqish foydali.",
      },
      {
        type: "txt",
        value:
          "Ertalabdan keyingi quyosh odam terisi uchun zararli bo`lishi mumkin.",
      },
      {
        type: "txt",
        value: "Qish kunlarida ba`zida ertalab ham quyoshni ko`rib bo`lmaydi.",
      },
    ],
    answer: 2,
  },
  {
    id: 18,
    question: [
      {
        type: "txt",
        value:
          "Suzish havzasi haftada yetti kun ishlaydi. Laziz, Murod, Nodir va Olim ismli to‘rtta qutqaruvchi bor, ularning har biri haftada to‘rt kun ishlaydi. Hech kim ketma-ket to'rt kun ishlamaydi va har kuni kamida ikkita qutqaruvchi navbatchilik qiladi shuningdek shanba va yakshanba kunlari uch kishidan navbatchilik qilish shart. Bizda quyidagicha ma’lumotlar bor.  • Laziz dushanba va shanba kuni ishlamaydi.  • Murod seshanba, payshanba va juma kuni ishlamaydi    • Nodir chorshanba va juma kuni ishlamaydi.    • Olim payshanba kuni doim ishlaydi.   Berilgan ma’lumotlarga ko’ra, quyidagi javoblardan qaysi birini aniq aytish mumkin?",
      },
    ],
    options: [
      { type: "txt", value: "Laziz payshanba kuni ishlaydi." },
      { type: "txt", value: "Nodir shanba va yakshanba kunlari ishlaydi." },
      { type: "txt", value: "Olim dushanba va seshanba kunlari ishlaydi." },
      { type: "txt", value: "Dushanba kuni uch kishi ishlaydi." },
      { type: "txt", value: "to`g`ri javob yo`q" },
    ],
    answer: 1,
  },
  {
    id: 19,
    question: [
      {
        type: "txt",
        value:
          "Arqon tortish musobaqasida Anvar, Botir, Vali va G`ulom dastlabki to'rtta o'rinni egalladi. Muxbirning ularning har biri qaysi joyni egallaganligi haqidagi savoliga uchta javob olindi:    • Anvar birinchi, Botir ikkinchi,   • Anvar - ikkinchi, G`ulom - uchinchi,    • Vali - ikkinchi, G`ulom - to'rtinchi.   Ushbu javoblarning biri rost , biri esa yolg’on bo’lsa , to’rtinchi o’rinni kim olgan?",
      },
    ],
    options: [
      { type: "txt", value: "Botir" },
      { type: "txt", value: "Vali" },
      { type: "txt", value: "G`ulom" },
      { type: "txt", value: "Anvar" },
    ],
    answer: 0,
  },
];

export const saralash = [
  {
    id: 0,
    question: [{ type: "txt", value: "Hisoblang: 123∙45- 123∙35 +2 ∙ 17²:34" }],
    options: [
      { type: "txt", value: "1247" },
      { type: "txt", value: "1347" },
      { type: "txt", value: "1230" },
      { type: "txt", value: "1330" },
    ],
    answer: 0,
  },
  {
    id: 1,
    question: [
      {
        type: "txt",
        value:
          "777776+77776+7776+776+76 yig‘indining qiymatini 7 ga boʻlinganidagi qoldiqni toping.",
      },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "2" },
      { type: "txt", value: "1" },
      { type: "txt", value: "5" },
    ],
    answer: 1,
  },
  {
    id: 2,
    question: [
      {
        type: "txt",
        value:
          "Natural sonlar qatorida 82 dan katta boʻlmagan barcha juft sonlar yig‘indisini toping.",
      },
    ],
    options: [
      { type: "txt", value: "1722" },
      { type: "txt", value: "1800" },
      { type: "txt", value: "3403" },
      { type: "txt", value: "3500" },
    ],
    answer: 0,
  },
  {
    id: 3,
    question: [
      {
        type: "txt",
        value:
          "20 minut oldinga ildamlab ketgan soat hozir 9∶15 ni koʻrsatyapti. Ayni shu paytda 25 minut orqada qolgan soat nechani koʻrsatayotgan boʻladi?",
      },
    ],
    options: [
      { type: "txt", value: "8∶55" },
      { type: "txt", value: "8∶35" },
      { type: "txt", value: "9∶05" },
      { type: "txt", value: "8∶30" },
    ],
    answer: 3,
  },
  {
    id: 4,
    question: [
      {
        type: "txt",
        value:
          "Bir-biriga qarab harakatlanayotgan avtomobillar orasidagi masofa 450 km . Ulardan birinchising tezligi ikkinchisidan 2 marta ko‘proq. Agar avtomobillar 2 soatdan keyin uchrashgan bo‘lsalar, birinchisining tezligini toping.",
      },
    ],
    options: [
      { type: "txt", value: "70" },
      { type: "txt", value: "75" },
      { type: "txt", value: "150" },
      { type: "txt", value: "140" },
    ],
    answer: 2,
  },
  {
    id: 5,
    question: [
      {
        type: "txt",
        value:
          "Evkalipt daraxtining bo‘yi birinchi 5 yilda 12 m balandlikka yetadi. 35 yilda 48 metrga yetadi. Birinchi 25 yilda evkalipt daraxti necha cm ga o‘sadi?",
      },
    ],
    options: [
      { type: "txt", value: "3400 cm" },
      { type: "txt", value: "2500 cm" },
      { type: "txt", value: "3600 cm" },
      { type: "txt", value: "3000cm" },
    ],
    answer: 2,
  },
  {
    id: 6,
    question: [
      {
        type: "txt",
        value:
          "2022 - yil 20 - mart soat 12∶00 da Halimning tugʻilganiga 100 sutka boʻldi. Halim qaysi kuni tug‘ilgan?",
      },
    ],
    options: [
      { type: "txt", value: "10 - dekabr" },
      { type: "txt", value: "20 - dekabr" },
      { type: "txt", value: "22 - dekabr" },
      { type: "txt", value: "12 - dekabr" },
    ],
    answer: 0,
  },
  {
    id: 7,
    question: [
      {
        type: "txt",
        value:
          "Olma narxi banan narxidan 4 marta arzon. 3 kg olma va 2 kg banan uchun 44000 soʻm toʻlangan boʻlsa, bananning narxini aniqlang.",
      },
    ],
    options: [
      { type: "txt", value: "12000" },
      { type: "txt", value: "4000" },
      { type: "txt", value: "16000" },
      { type: "txt", value: "20000" },
    ],
    answer: 2,
  },
  {
    id: 8,
    question: [
      {
        type: "txt",
        value:
          "Qirralari uzunliklari 16 cm, 20 cm va 24 cm ga teng boʻlgan toʻgʻri burchakli parallelepiped shaklidagi taxtani qirrasi 4 cm boʻlgan nechta kubchaga ajratish mumkin?",
      },
    ],
    options: [
      { type: "txt", value: "60" },
      { type: "txt", value: "40" },
      { type: "txt", value: "120" },
      { type: "txt", value: "90" },
    ],
    answer: 2,
  },
  {
    id: 9,
    question: [
      {
        type: "txt",
        value:
          "Zavodda birinchi kun 568 ta , ikkinchi kun birinchi kundagidan 87 ta kam , uchinchi kun esa ikkinchi kundagidan 65 ta ortiq detal tayyorlandi. Shu uch kunda zavodda jami nechta detal tayyorlangan?",
      },
    ],
    options: [
      { type: "txt", value: "1585" },
      { type: "txt", value: "1595" },
      { type: "txt", value: "1605" },
      { type: "txt", value: "1575" },
    ],
    answer: 1,
  },
  {
    id: 10,
    question: [
      { type: "txt", value: "Rasmdagi berilgan shakl perimetrini toping:" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/5class/img1.png",
      },
    ],
    options: [
      { type: "txt", value: "𝑃 = 24 𝑐𝑚" },
      { type: "txt", value: "𝑃 = 28 𝑐𝑚" },
      { type: "txt", value: "𝑃 = 34 𝑐𝑚" },
      { type: "txt", value: "𝑃 = 38 𝑐𝑚" },
    ],
    answer: 3,
  },
  {
    id: 11,
    question: [
      {
        type: "txt",
        value: "Tenglamani yeching:	2,25𝑥 + 7,23 + 7,75𝑥 = 430,23 − 223",
      },
    ],
    options: [
      { type: "txt", value: "13" },
      { type: "txt", value: "19" },
      { type: "txt", value: "21" },
      { type: "txt", value: "20" },
    ],
    answer: 3,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value:
          "Perimetri 20 m kvadrat shaklidagi xonaga boʻyi 4 m, eni esa 3 m boʻlgan toʻg‘ri toʻrtburchak shaklidagi gilam toʻshaldi. Bu xonaning necha kvadrat metr yuzasi gilam bilan qoplanmaydi?",
      },
    ],
    options: [
      { type: "txt", value: "13 m²" },
      { type: "txt", value: "8 m²" },
      { type: "txt", value: "6 m²" },
      { type: "txt", value: "12 m²" },
    ],
    answer: 3,
  },
  {
    id: 13,
    question: [
      {
        type: "txt",
        value:
          "Agar it 27 sekundda bolani quvib yetgan bo‘lsa, uning tezligi qancha bo‘lgan?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/5class/img2.png",
      },
    ],
    options: [
      { type: "txt", value: "200 m/min" },
      { type: "txt", value: "300 m/min" },
      { type: "txt", value: "350 m/min" },
      { type: "txt", value: "100 m/min" },
    ],
    answer: 1,
  },
  {
    id: 14,
    question: [
      {
        type: "txt",
        value:
          "Berilgan rasmning nechta joyida ikkita o‘quvchi chap qo‘llari bilan bir-birini ushlab turibdi?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/5class/img3.png",
      },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 0,
  },
  {
    id: 15,
    question: [
      {
        type: "txt",
        value: "X va Y nuqtalari orasida bitta poyezd yuradigan yo‘l mavjud.",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/5class/img4.png",
      },
      {
        type: "txt",
        value:
          "Temiryo‘l kompaniyasi har kuni bir vaqtning o‘zida X nuqtadan bitta poyezd va Y nuqtadan bitta poyezd ketishini e’lon qildi. Poyezd tezligini o‘zgartirmasdan X nuqtadan Y nuqtagacha 180 daqiqada va Y dan X gacha 60 daqiqada yetib keladi. Poyezdlar bir-biri bilan urilib ketmasligi uchun qo‘shaloq yo‘l qurishmoqchi. Bu yo‘l qayerga qurilishi kerak?",
      },
    ],
    options: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/5class/img5.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/5class/img6.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/5class/img7.png",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/5class/img8.png",
      },
    ],
    answer: 0,
  },
  {
    id: 16,
    question: [
      {
        type: "txt",
        value:
          "Ali, Bobur, Komil, Diyor va Elyor aylana stol atrofida o‘tirishibdi. Ali Boburning yonida emas, Diyor Elyorning yonida va Bobur esa Diyorning yonida emas. Komilning yonida kim o‘tiribdi?",
      },
    ],
    options: [
      { type: "txt", value: "Elyor va Ali" },
      { type: "txt", value: "Bobur va Diyor" },
      { type: "txt", value: "Diyor va Elyor" },
      { type: "txt", value: "Ali va Bobur" },
    ],
    answer: 3,
  },
  {
    id: 17,
    question: [
      {
        type: "txt",
        value:
          "Silindr atrofida santimetrli o‘lchash tasmasi o‘ralgan. So‘roq belgisi bilan ko‘rsatilgan joyda qaysi son bo‘lishi kerak?",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/math/5class/img9.png",
      },
    ],
    options: [
      { type: "txt", value: "60" },
      { type: "txt", value: "69" },
      { type: "txt", value: "53" },
      { type: "txt", value: "77" },
    ],
    answer: 1,
  },
  {
    id: 18,
    question: [
      {
        type: "txt",
        value:
          "Qutida 20 ta olma va 20 ta nok bor edi. Komila tasodifiy tarzda qutidan 21 dona mevani oldi, qolganini esa Laylo oldi. Quyidagi gaplardan qaysi biri doimo to‘g‘ri?",
      },
    ],
    options: [
      {
        type: "txt",
        value: "Laylo qancha olma olgan bo‘lsa, Komila ham shuncha nok oldi.",
      },
      {
        type: "txt",
        value: "Komila qancha nok olgan bo‘lsa, shuncha olma oldi.",
      },
      {
        type: "txt",
        value: "Laylo qancha olma olgan bo‘lsa, Komila ham shuncha olma oldi.",
      },
      { type: "txt", value: "Komila eng kamida bitta nok oldi." },
      {
        type: "txt",
        value: "Laylo qancha nok olgan bo‘lsa, Komila ham shuncha nok oldi.",
      },
    ],
    answer: 0,
  },
  {
    id: 19,
    question: [
      {
        type: "txt",
        value:
          "Sonli ketma-ketlikning keyingi hadini toping. 4 , 7, 13, 22, 34, 49, _____",
      },
    ],
    options: [
      { type: "txt", value: "64" },
      { type: "txt", value: "65" },
      { type: "txt", value: "66" },
      { type: "txt", value: "67" },
      { type: "txt", value: "68" },
    ],
    answer: 3,
  },
  {
    id: 20,
    question: [
      { type: "txt", value: "Sotuvchi 60 kilogramm guruchning" },
      { type: "img", value: "/quiz-images-2/saralash/img1.png" },
      {
        type: "txt",
        value:
          "qismini uch kilogrammlik qoplarga joyladi. Agar sotuvchi har bir qopni 9000 so`mdan sotsa qancha daromad oladi?",
      },
      { type: "txt", value: "/quiz-images-2/saralash/img2.png" },
    ],
    options: [
      { type: "txt", value: "156000" },
      { type: "txt", value: "144000" },
      { type: "txt", value: "624000" },
      { type: "txt", value: "180000" },
      { type: "txt", value: "240000" },
    ],
    answer: 1,
  },
  {
    id: 21,
    question: [
      {
        type: "txt",
        value:
          "* o’rniga qanday raqam qo’yilsa, to’g’ri tenglik hosil bo’ladi? 59181-459*3=13258",
      },
    ],
    options: [
      { type: "txt", value: "4" },
      { type: "txt", value: "3" },
      { type: "txt", value: "2" },
      { type: "txt", value: "1" },
      { type: "txt", value: "5" },
    ],
    answer: 2,
  },
  {
    id: 22,
    question: [
      {
        type: "txt",
        value:
          "So’roq belgisi o’rnida turishi zarur bo’lgan sonlarning ko’paytmasini toping.",
      },
      { type: "img", value: "/quiz-images-2/saralash/img3.png" },
    ],
    options: [
      { type: "txt", value: "67" },
      { type: "txt", value: "702" },
      { type: "txt", value: "663" },
      { type: "txt", value: "286" },
      { type: "txt", value: "207" },
    ],
    answer: 1,
  },
  {
    id: 23,
    question: [
      {
        type: "txt",
        value:
          "Asliddin 7700 ni hosil qilish uchun bir nechta 7 raqamini qo’shib chiqdi. 7+7+7+…+7=7700 Agar Asliddin qo’shib chiqqan 7 raqamlari sonidan besh marta kam sondagi 4 raqamlarini qo’shib chiqsa, yig’indi nechaga teng bo’ladi?",
      },
    ],
    options: [
      { type: "txt", value: "220" },
      { type: "txt", value: "880" },
      { type: "txt", value: "660" },
      { type: "txt", value: "22000" },
      { type: "txt", value: "8800" },
    ],
    answer: 1,
  },
  {
    id: 24,
    question: [
      { type: "txt", value: "Tikuvchi matoning" },
      { type: "img", value: "/quiz-images-2/saralash/img4.png" },
      { type: "txt", value: "qismidan yubka," },
      { type: "img", value: "/quiz-images-2/saralash/img5.png" },
      { type: "txt", value: "qismidan ko’ylak," },
      { type: "img", value: "/quiz-images-2/saralash/img6.png" },
      {
        type: "txt",
        value:
          "qismidan jaket , qolganidan esa shim tikdi. Tikuvchi qaysi kiyim uchun eng kam mato ishlatgan?",
      },
      { type: "img", value: "/quiz-images-2/saralash/img7.png" },
    ],
    options: [
      { type: "txt", value: "Yubka" },
      { type: "txt", value: "Ko’ylak" },
      { type: "txt", value: "Jaket" },
      { type: "txt", value: "Shim" },
      { type: "txt", value: "Jaket va Shim" },
    ],
    answer: 3,
  },
  {
    id: 25,
    question: [
      {
        type: "txt",
        value:
          "Javoblardan qaysi biri quyida berilgan ifodalarning hech biriga teng emas?",
      },
      { type: "img", value: "/quiz-images-2/saralash/img8.png" },
    ],
    options: [
      { type: "txt", value: "4×1000+4×500+(4×40)" },
      { type: "txt", value: "7×4000+7×700+(7×5)" },
      { type: "txt", value: "8×5000+8×900" },
      { type: "txt", value: "(2×7000+2×900+2×80+(2×1)" },
      { type: "txt", value: "11×1000+(11×1)" },
    ],
    answer: 2,
  },
  {
    id: 26,
    question: [
      {
        type: "txt",
        value:
          "Dehqon bozorga 480 kg kartoshka olib keldi. U har biri 8 kg dan bo’lgan 15 qop kartoshka sotdi. Dehqon olib kelgan kartoshkalarining qancha qismini sotgan?",
      },
      { type: "img", value: "/quiz-images-2/saralash/img9.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/saralash/img10.png" },
      { type: "img", value: "/quiz-images-2/saralash/img11.png" },
      { type: "img", value: "/quiz-images-2/saralash/img12.png" },
      { type: "img", value: "/quiz-images-2/saralash/img13.png" },
      { type: "img", value: "/quiz-images-2/saralash/img14.png" },
    ],
    answer: 0,
  },
  {
    id: 27,
    question: [
      {
        type: "txt",
        value:
          "Abror katakli daftarga 4 ta to’gri chiziqni chizdi. Har bir katakning bo’yi va eni 1sm.  Birinchi chiziq (1;1) dan boshlanadi va (1;7) da tugaydi.     Ikkinchi chiziq (1;7) dan boshlanadi va (5;7) da tugaydi.     Uchinchi chiziq (5;7) dan boshlanadi va (5;1) da tugaydi.    To’rtinchi chiziq esa hosil bo’lgan siniq chiziqni to’g’ri to’rtburchakka to’ldirdi. Abror chizgan figuraning yuzini toping. (sm²)",
      },
    ],
    options: [
      { type: "txt", value: "35" },
      { type: "txt", value: "24" },
      { type: "txt", value: "30" },
      { type: "txt", value: "28" },
      { type: "txt", value: "20" },
    ],
    answer: 0,
  },
  {
    id: 28,
    question: [
      {
        type: "txt",
        value:
          "Agar 6 soat 21 daqiqa uxlagan inson tong vaqti bilan 07:00 da uyg'ongan bo'lsa, u nechada uxlagan?",
      },
    ],
    options: [
      { type: "txt", value: "01:39" },
      { type: "txt", value: "23:52" },
      { type: "txt", value: "23:11" },
      { type: "txt", value: "00:39" },
      { type: "txt", value: "00:11" },
    ],
    answer: 3,
  },
  {
    id: 29,
    question: [
      {
        type: "txt",
        value:
          "Quyidagi taqqoslashga ko'ra, o’ngdagi katakka shu tengsizlikni qanoatlantiradigan eng katta son yozildi. Quyidagilardan nechtasida shu songa noto’g’ri ta’rif berilgan.",
      },
      { type: "img", value: "/quiz-images-2/saralash/img15.png" },
      {
        type: "txt",
        value:
          "1)O’nlar xonasigacha yaxlitlansa 9880 ga teng.   2)9×1000+8×100+7×10+6×1 ga teng.   3)Raqamlari har xil bo'lgan eng katta 4 xonali natural son.   4)Birlar va yuzlar xonasidagi raqamlar bir xil.  5)Juft son.",
      },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
    ],
    answer: 3,
  },
  {
    id: 30,
    question: [
      {
        type: "txt",
        value:
          "Nodir toq raqamlarni yaxshi ko`radi. U telefoniga o`zi yaxshi ko`rgan raqamlardan foydalanib, turli raqamli uch xonali parol qo`ymoqchi. Nodir buni necha xil usulda qilishi mumkin?",
      },
      { type: "img", value: "/quiz-images-2/saralash/img16.png" },
    ],
    options: [
      { type: "txt", value: "12" },
      { type: "txt", value: "20" },
      { type: "txt", value: "60" },
      { type: "txt", value: "75" },
      { type: "txt", value: "125" },
    ],
    answer: 1,
  },
  {
    id: 31,
    question: [
      {
        type: "txt",
        value:
          "Yondagi keltirilgan  rasmga ko’ra, bo’yalgan qism yuzini toping. (sm²)",
      },
      { type: "img", value: "/quiz-images-2/saralash/img17.png" },
    ],
    options: [
      { type: "txt", value: "260" },
      { type: "txt", value: "308" },
      { type: "txt", value: "490" },
      { type: "txt", value: "608" },
      { type: "txt", value: "460" },
    ],
    answer: 3,
  },
  {
    id: 32,
    question: [
      {
        type: "txt",
        value: "Muvozanatga erishish uchun X o‘rniga nechta D  bo`lishi kerak?",
      },
      { type: "img", value: "/quiz-images-2/saralash/img18.png" },
    ],
    options: [
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
      { type: "txt", value: "2" },
    ],
    answer: 1,
  },
  {
    id: 33,
    question: [
      {
        type: "txt",
        value:
          "Buzilgan ikkita soatning birinchisi har 4 daqiqada 1 daqiqa, ikkinchisi esa har 5 daqiqada 1 daqiqa orqada qoladi. 3 soatdan keyin bir vaqtning o'zida to’g’rilangan ikkita soat orasidagi farq necha daqiqa bo'ladi?",
      },
    ],
    options: [
      { type: "txt", value: "8" },
      { type: "txt", value: "3" },
      { type: "txt", value: "10" },
      { type: "txt", value: "12" },
      { type: "txt", value: "9" },
    ],
    answer: 4,
  },
  {
    id: 34,
    question: [
      {
        type: "txt",
        value:
          "Maftuna bir soatda 9 ta yostiqcha tikadi. Madina esa bir soatda undan 6 ta kam yostiqcha tikadi. Maftuna va Madina har biri bir kunda 9 soat ishlaydi. Ular 864 ta yostiqchani necha kunda tikib bo’lishadi?",
      },
    ],
    options: [
      { type: "txt", value: "8" },
      { type: "txt", value: "7" },
      { type: "txt", value: "9" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
    ],
    answer: 0,
  },
  {
    id: 35,
    question: [
      {
        type: "txt",
        value: "∆ ning qiymatini toping.          88-44:11+22=111-∆",
      },
    ],
    options: [
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
      { type: "txt", value: "84" },
      { type: "txt", value: "85" },
    ],
    answer: 2,
  },
  {
    id: 36,
    question: [
      {
        type: "txt",
        value:
          "Yo’l ta’mirlovchilar uzunligi 20 km bo’lgan yo’lni uch kunda ta’mirlashdi. Ular birinchi kuni butun yo’lning",
      },
      { type: "img", value: "/quiz-images-2/saralash/img19.png" },
      {
        type: "txt",
        value:
          "qismini, ikkinchi kuni birinchi kundagidan 1 km 400 m kam yo’l ta’mirlashdi. Ular uchinchi kuni qancha yo’lni ta’mirlashgan?",
      },
    ],
    options: [
      { type: "txt", value: "6 km 400 m" },
      { type: "txt", value: "6 km" },
      { type: "txt", value: "5 km 500 m" },
      { type: "txt", value: "5 km 400 m" },
      { type: "txt", value: "5 km 600 m" },
    ],
    answer: 3,
  },
  {
    id: 37,
    question: [
      {
        type: "txt",
        value: "Yondagi figura nechta kichik kublardan tashkil topgan?",
      },
      { type: "img", value: "/quiz-images-2/saralash/img20.png" },
    ],
    options: [
      { type: "txt", value: "58" },
      { type: "txt", value: "57" },
      { type: "txt", value: "56" },
      { type: "txt", value: "55" },
      { type: "txt", value: "54" },
    ],
    answer: 4,
  },
  {
    id: 38,
    question: [
      { type: "txt", value: "Sayyodda 96000 so’m pul bor edi. U pulining" },
      { type: "img", value: "/quiz-images-2/saralash/img21.png" },
      {
        type: "txt",
        value:
          "qismiga banan va qolganiga esa qulupnay sotib oldi. Agar 1 kg bananning narxi 12000 so’m va 1 kg qulupnayning narxi 9000 so’m bo’lsa, u necha kilogramm banan va qulupnay sotib olgan?",
      },
    ],
    options: [
      { type: "txt", value: "8" },
      { type: "txt", value: "10" },
      { type: "txt", value: "9" },
      { type: "txt", value: "12" },
      { type: "txt", value: "11" },
    ],
    answer: 2,
  },
  {
    id: 39,
    question: [
      {
        type: "txt",
        value: "Quyidagi sonlardan qaysi biri 13 ga bo’linmaydi?",
      },
    ],
    options: [
      { type: "txt", value: "68" },
      { type: "txt", value: "78" },
      { type: "txt", value: "104" },
      { type: "txt", value: "91" },
      { type: "txt", value: "52" },
    ],
    answer: 0,
  },
  {
    id: 40,
    question: [
      { type: "txt", value: "Yondagi shaklning qanday qismi bo’yalgan." },
      { type: "img", value: "/quiz-images-2/saralash/img22.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/saralash/img23.png" },
      { type: "img", value: "/quiz-images-2/saralash/img24.png" },
      { type: "img", value: "/quiz-images-2/saralash/img25.png" },
      { type: "img", value: "/quiz-images-2/saralash/img26.png" },
      { type: "img", value: "/quiz-images-2/saralash/img27.png" },
    ],
    answer: 2,
  },
  {
    id: 41,
    question: [
      {
        type: "txt",
        value: "Kvadrat va uchburchak nechaga teng ekanligini toping.",
      },
      { type: "img", value: "/quiz-images-2/saralash/img28.png" },
    ],
    options: [
      { type: "txt", value: "6 va 3" },
      { type: "txt", value: "4 va 6" },
      { type: "txt", value: "3 va 6" },
      { type: "txt", value: "5 va 3" },
      { type: "txt", value: "4 va 5" },
    ],
    answer: 1,
  },
  {
    id: 42,
    question: [
      {
        type: "txt",
        value:
          "Yonda karra jadvali berilgan bo’lib, unda ayrim sonlar tushirib qoldirilgan. ∆ ning qiymatini hisoblang.",
      },
      { type: "img", value: "/quiz-images-2/saralash/img29.png" },
    ],
    options: [
      { type: "txt", value: "35" },
      { type: "txt", value: "42" },
      { type: "txt", value: "45" },
      { type: "txt", value: "48" },
      { type: "txt", value: "49" },
    ],
    answer: 1,
  },
  {
    id: 43,
    question: [
      {
        type: "txt",
        value:
          "Quyidagi taqqoslashlardan qaysi biri son nurida ko‘rsatilgan kasr uchun noto‘g‘ri?",
      },
      { type: "img", value: "/quiz-images-2/saralash/img30.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/saralash/img31.png" },
      { type: "img", value: "/quiz-images-2/saralash/img32.png" },
      { type: "img", value: "/quiz-images-2/saralash/img33.png" },
      { type: "img", value: "/quiz-images-2/saralash/img34.png" },
    ],
    answer: 3,
  },
  {
    id: 44,
    question: [
      {
        type: "txt",
        value:
          "Akmal, Suxrob, Javohir va Ahmad yaqin do’st. Akmal Suxrobdan 5 yosh va Javohirdan esa 2 yosh katta. Ahmad esa 40 yosh va Javohirdan 7 yosh katta bo’lsa, to’rtta do’stning yoshlari yig’indisini toping.",
      },
    ],
    options: [
      { type: "txt", value: "144" },
      { type: "txt", value: "143" },
      { type: "txt", value: "138" },
      { type: "txt", value: "150" },
      { type: "txt", value: "148" },
    ],
    answer: 2,
  },
  {
    id: 45,
    question: [
      {
        type: "txt",
        value:
          "Sut sog`uvchi har kuni sog`adigan sutining yarmini sutni qayta ishlash korxonasiga jo‘natadi. Qolgan yarmining 3/8 qismi bilan pishloq, qolgan sutdan esa sariyog‘ tayyorlaydi. Agar bu sog`uvchi har kuni 192 litr sut ishlab chiqarsa, sariyog‘ uchun necha litr sut sarflaydi?",
      },
    ],
    options: [
      { type: "txt", value: "12" },
      { type: "txt", value: "36" },
      { type: "txt", value: "60" },
      { type: "txt", value: "96" },
      { type: "txt", value: "72" },
    ],
    answer: 2,
  },
  {
    id: 46,
    question: [
      {
        type: "txt",
        value:
          "Yondagi diagrammalarda bo’yoqchilar birinchi va ikkinchi kunda devorning qancha qismini bo’yagani tasvirlangan. Ishchilar shu ikki kun davomida devorning qancha qismini bo’yagan?",
      },
      { type: "img", value: "/quiz-images-2/saralash/img35.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/saralash/img36.png" },
      { type: "img", value: "/quiz-images-2/saralash/img37.png" },
      { type: "img", value: "/quiz-images-2/saralash/img38.png" },
      { type: "img", value: "/quiz-images-2/saralash/img39.png" },
      { type: "img", value: "/quiz-images-2/saralash/img40.png" },
    ],
    answer: 1,
  },
  {
    id: 47,
    question: [
      {
        type: "txt",
        value:
          "Uch yosh farq bilan tug'ilgan 3 aka-ukaning yoshlari yig’indisi eng kamida necha bo’lishi mumkin?",
      },
    ],
    options: [
      { type: "txt", value: "11" },
      { type: "txt", value: "14" },
      { type: "txt", value: "12" },
      { type: "txt", value: "15" },
      { type: "txt", value: "10" },
    ],
    answer: 0,
  },
  {
    id: 48,
    question: [
      {
        type: "txt",
        value:
          "Bir duradgorda ikki xil uzunlikdagi, uzun va kalta jami 36 ta yog’och bor. Uzun yog’ochlarning  har biri beshta teng bo’lakka, kalta yog’ochlarning har biri esa to'rta teng bo’lakka kesildi. Agar uzun yog’ochlarni kesishdan hosil bo’lgan bo’laklar soni kalta yog’ochlarni kesishdan hosil bo’lgan bo’laklar soniga teng bo’lsa, bu durodgorda nechta uzun yog’och bo’lgan?",
      },
    ],
    options: [
      { type: "txt", value: "16" },
      { type: "txt", value: "20" },
      { type: "txt", value: "80" },
      { type: "txt", value: "24" },
      { type: "txt", value: "12" },
    ],
    answer: 0,
  },
  {
    id: 49,
    question: [
      {
        type: "txt",
        value:
          "O‘lchov birliklari va ularga mos qiymatlar o‘rtasidagi moslikni o‘rnating:",
      },
      { type: "img", value: "/quiz-images-2/saralash/img41.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/saralash/img42.png" },
      { type: "img", value: "/quiz-images-2/saralash/img43.png" },
      { type: "img", value: "/quiz-images-2/saralash/img44.png" },
      { type: "img", value: "/quiz-images-2/saralash/img45.png" },
    ],
    answer: 0,
  },
  {
    id: 50,
    question: [
      {
        type: "txt",
        value:
          "Noma’lum sonni 11 ga bo‘lganda to‘liqsiz bo‘linma ham qoldiq ham 5 chiqdi. Noma’lum sonni toping.",
      },
    ],
    options: [
      { type: "txt", value: "65" },
      { type: "txt", value: "55" },
      { type: "txt", value: "60" },
      { type: "txt", value: "50" },
    ],
    answer: 2,
  },
  {
    id: 51,
    question: [
      {
        type: "txt",
        value:
          "Rasmda ob-havo grafigi keltirilgan. Unda ob-havo va kelgusi besh kun uchun maksimal harorat ko‘rsatilgan. Quyidagilardan qaysi biri maksimal haroratning tegishli grafigini aks ettirgan?",
      },
      { type: "img", value: "/quiz-images-2/saralash/img46.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/saralash/img47.png" },
      { type: "img", value: "/quiz-images-2/saralash/img48.png" },
      { type: "img", value: "/quiz-images-2/saralash/img49.png" },
      { type: "img", value: "/quiz-images-2/saralash/img50.png" },
    ],
    answer: 0,
  },
  {
    id: 52,
    question: [
      {
        type: "txt",
        value:
          "Oltita to‘g‘ri to‘rtburchaklar rasmda ko‘rsatilganidek joylashtirilgan. Chapdan yuqoridagi to‘g‘ri to‘rtburchakning balandligi 6 cm ga teng. To‘g‘ri to‘rtburchaklar ichidagi sonlar ularning cm² dagi yuzlarini bildiradi. O‘ngdan pastdagi to‘g‘ri to‘rtburchakning perimetrini toping .",
      },
      { type: "img", value: "/quiz-images-2/saralash/img51.png" },
    ],
    options: [
      { type: "txt", value: "34 cm" },
      { type: "txt", value: "24 cm" },
      { type: "txt", value: "22 cm" },
      { type: "txt", value: "32 cm" },
    ],
    answer: 2,
  },
  {
    id: 53,
    question: [
      {
        type: "txt",
        value:
          "Quyidagi tasdiqlar ichidan to‘g‘ri yoki noto‘g‘riligi ketma-ketligi to‘g‘ri ko‘rsatilgan javob variantini aniqlang.",
      },
      { type: "img", value: "/quiz-images-2/saralash/img52.png" },
    ],
    options: [
      { type: "txt", value: "I- yo‘q, II- ha, III-yo‘q, IV-ha" },
      { type: "txt", value: "I-ha, II-ha, III-yo‘q, IV-ha" },
      { type: "txt", value: "I-ha, II-yo‘q, III-ha, IV-ha" },
      { type: "txt", value: "I-yo‘q, II-yo‘q, III-ha, IV-yo‘q" },
    ],
    answer: 0,
  },
  {
    id: 54,
    question: [
      {
        type: "txt",
        value:
          "Natural ko‘paytuvchilardan birini 10 % ga, ikkinchisini 20 % ga oshirib, uchinchisini 25 % ga kamaytirsak, ko‘paytma necha foizga o‘zgaradi?",
      },
    ],
    options: [
      { type: "txt", value: "1% ga oshadi" },
      { type: "txt", value: "5% ga oshadi" },
      { type: "txt", value: "1% ga kamayadi" },
      { type: "txt", value: "5% ga kamayadi" },
    ],
    answer: 2,
  },
  {
    id: 55,
    question: [
      {
        type: "txt",
        value:
          "Uchta sonning o‘rta arifmetigi 10 ga, boshqa ikki sonning o‘rta arifmetigi esa 15 ga teng. Shu beshta sonning o‘rta arifmetigini toping.",
      },
    ],
    options: [
      { type: "txt", value: "13" },
      { type: "txt", value: "15" },
      { type: "txt", value: "12,5" },
      { type: "txt", value: "12" },
    ],
    answer: 3,
  },
  {
    id: 56,
    question: [
      {
        type: "txt",
        value:
          "Harorati 36 bo‘lgan 6 l suvga harorati 15 bo‘lgan suv qo‘shilganida aralashtirilgan suvning harorati 24 bo‘ldi. Necha litr suv qo‘shilgan?",
      },
    ],
    options: [
      { type: "txt", value: "10" },
      { type: "txt", value: "8" },
      { type: "txt", value: "12" },
      { type: "txt", value: "9" },
    ],
    answer: 1,
  },
  {
    id: 57,
    question: [
      {
        type: "txt",
        value:
          "Oralaridagi masofa 280 km bo‘lgan A va B shaharlardan bir vaqtda ikki avtomobil yo‘lga chiqdi. Agar avtomobillar bir – biriga qarab yursa, ular 2 soatdan keyin uchrashadi. Agar ular bir yo‘nalishda yursa, A shahardan chiqqan avtomobil B shahardan chiqqan avtomobilni 14 soatda quvib yetadi. B shahardan chiqqan avtomobil tezligi necha km/h ga teng?",
      },
    ],
    options: [
      { type: "txt", value: "140 km/h" },
      { type: "txt", value: "60 km/h" },
      { type: "txt", value: "80 km/h" },
      { type: "txt", value: "20 km/h" },
    ],
    answer: 2,
  },
  {
    id: 58,
    question: [
      {
        type: "txt",
        value:
          "A, B, C, D, E, F variantlardan quyidagi I, II, III savollarning har biriga mos javobni qo‘ying:",
      },
      { type: "img", value: "/quiz-images-2/saralash/img53.jpg" },
    ],
    options: [
      { type: "txt", value: "I-D, II-F, III-A" },
      { type: "txt", value: "I-D, II-C, III-A" },
      { type: "txt", value: "I-C, II-B, III-A" },
      { type: "txt", value: "I-C, II-F, III-D" },
    ],
    answer: 0,
  },
  {
    id: 59,
    question: [
      {
        type: "txt",
        value:
          "ABCD va OPRS kvadratlarning yuzlari mos ravishda 324 cm² va 196 cm² ga teng. Bo‘yalgan sohaning yuzini cm² da toping.",
      },
      { type: "img", value: "/quiz-images-2/saralash/img54.png" },
    ],
    options: [
      { type: "txt", value: "36" },
      { type: "txt", value: "64" },
      { type: "txt", value: "56" },
      { type: "txt", value: "48" },
    ],
    answer: 2,
  },
  {
    id: 60,
    question: [
      {
        type: "txt",
        value:
          "Jalol otaning bog‘i to‘g‘ri to‘rtburchak shaklida. Bu yerning o‘ng va chap tomonidan 28 m bo‘sh joy qoldirildi va oralari 5 m bo‘lgan kvadrat shaklidagi uylar qurildi. Agar Jalol ota uylarni yerning o‘ng va chap tomonidan 4 metr bo‘sh joy qoldirib qurganida avvalgisidan 5 ta ko‘p uy qurilgan bo‘lar edi. Bunga ko‘ra, bu uyning tomoni necha metrga teng?",
      },
    ],
    options: [
      { type: "txt", value: "4,6 m" },
      { type: "txt", value: "5,6 m" },
      { type: "txt", value: "4,7 m" },
      { type: "txt", value: "4,5 m" },
    ],
    answer: 0,
  },
  {
    id: 61,
    question: [
      {
        type: "txt",
        value: "Tenglamani yeching:	4𝑥 + 6,4 = (8,3 + 2,1) ∙ (14,9 − 8,3)",
      },
    ],
    options: [
      { type: "txt", value: "15,6" },
      { type: "txt", value: "15,56" },
      { type: "txt", value: "17,18" },
      { type: "txt", value: "17,78" },
    ],
    answer: 1,
  },
  {
    id: 62,
    question: [
      {
        type: "txt",
        value:
          "Isomiddin uzunligi 60 metr bo‘lgan metro eskalatorida jim turgan holatda pastdan yuqoriga 2 minutda ko‘tariladi. Keyin harakatlanayotgan eskalatorda o‘zi ham pastdan tepaga harakatlanib 1 minutda ko‘tarildi. Isomiddin harakatlanayotgan eskalatordagi tezligi bilan 180 metr masofani qancha vaqtda bosib o‘tadi?",
      },
    ],
    options: [
      { type: "txt", value: "2.5 minut" },
      { type: "txt", value: "3 minut" },
      { type: "txt", value: "4 minut" },
      { type: "txt", value: "6 minut" },
    ],
    answer: 3,
  },
  {
    id: 63,
    question: [
      { type: "txt", value: "Hisoblang." },
      { type: "img", value: "/quiz-images-2/saralash/img55.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/saralash/img56.png" },
      { type: "txt", value: "10" },
      { type: "txt", value: "13" },
      { type: "txt", value: "12" },
    ],
    answer: 2,
  },
  {
    id: 64,
    question: [
      {
        type: "txt",
        value:
          "Ko’paytmani bajarmasdan uning ishorasini aniqlang.    (-25)∙–24–2–1∙0∙1∙2∙…∙24∙25",
      },
    ],
    options: [
      { type: "txt", value: "musbat" },
      { type: "txt", value: "0" },
      { type: "txt", value: "manfiy" },
      { type: "txt", value: "aniqlab bo’lmaydi" },
    ],
    answer: 1,
  },
  {
    id: 65,
    question: [
      { type: "img", value: "/quiz-images-2/saralash/img57.png" },
      { type: "txt", value: "sonlar yig’indisining" },
      { type: "img", value: "/quiz-images-2/saralash/img58.png" },
      { type: "txt", value: "qismini 2 barobarini toping." },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "1,5" },
      { type: "img", value: "/quiz-images-2/saralash/img59.png" },
      { type: "img", value: "/quiz-images-2/saralash/img60.png" },
    ],
    answer: 3,
  },
  {
    id: 66,
    question: [
      { type: "txt", value: "O’ramda 28 m adras bore di. Dastlab uning" },
      { type: "img", value: "/quiz-images-2/saralash/img61.png" },
      { type: "txt", value: "qismi, so’ngra qolgan matoning" },
      { type: "img", value: "/quiz-images-2/saralash/img62.png" },
      {
        type: "txt",
        value:
          "qismi qirqib olindi. Shundan so’ng o’ramda necha metr adras qolgan?",
      },
    ],
    options: [
      { type: "txt", value: "10 m" },
      { type: "txt", value: "15 m" },
      { type: "txt", value: "12 m" },
      { type: "txt", value: "aniqlab bo’lmaydi" },
    ],
    answer: 0,
  },
  {
    id: 67,
    question: [
      {
        type: "txt",
        value:
          "“Yosh tabiatshunoslar” to’garagi a’zosi Anvar tabiatni o’rganish maqsadida sayohatga chiqdi. U yo’lning",
      },
      { type: "img", value: "/quiz-images-2/saralash/img63.png" },
      {
        type: "txt",
        value:
          "qismini o’tgandan keyin hisoblasa, qolgan yo’l o’tilganidan 12 km ko’p ekan. Anvar yana qancha yo’l yurishi kerak?",
      },
    ],
    options: [
      { type: "txt", value: "42 km" },
      { type: "txt", value: "27 km" },
      { type: "txt", value: "15 km" },
      { type: "txt", value: "30 km" },
    ],
    answer: 1,
  },
  {
    id: 68,
    question: [
      {
        type: "txt",
        value: "Soddalashtiring.   – ( – 7a + 5 ) – 4,5a + 2,8 + 2 ( 1 – a)",
      },
    ],
    options: [
      { type: "txt", value: "0,5a – 0,2" },
      { type: "txt", value: "1" },
      { type: "txt", value: "1 – a" },
      { type: "txt", value: "– 2a + 1" },
    ],
    answer: 0,
  },
  {
    id: 69,
    question: [
      {
        type: "txt",
        value:
          "Zig’ir urug’i sepish me’yori 1 gektarga 0,5 sr ga teng. Tarhda uzunligi 20 sm, eni 15 sm bo’lgan to’g’ri to’rtburchak shaklidagi maydonga sepish uchun qancha zig’ir urug’i kerak bo’ladi? Masshtab  1:10000.",
      },
    ],
    options: [
      { type: "txt", value: "100 sr" },
      { type: "txt", value: "20 t" },
      { type: "txt", value: "15 t" },
      { type: "txt", value: "120 sr" },
    ],
    answer: 2,
  },
  {
    id: 70,
    question: [
      { type: "txt", value: "Tenglamani yeching. " },
      { type: "img", value: "/quiz-images-2/saralash/img64.png" },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "img", value: "/quiz-images-2/saralash/img65.png" },
      { type: "txt", value: "1" },
      { type: "img", value: "/quiz-images-2/saralash/img66.png" },
    ],
    answer: 1,
  },
  {
    id: 71,
    question: [
      {
        type: "txt",
        value: "- 14,7 va 15,6 sonlari orasida nechta natural son mavjud?",
      },
    ],
    options: [
      { type: "txt", value: "29" },
      { type: "txt", value: "30" },
      { type: "txt", value: "14" },
      { type: "txt", value: "15" },
    ],
    answer: 4,
  },
  {
    id: 72,
    question: [
      {
        type: "txt",
        value:
          "Kitobning narxi 20% ga arzonlashtirilgandan so’ng 48000 so’mga sotila boshladi, kitobning dastlabki narxini toping.",
      },
    ],
    options: [
      { type: "txt", value: "60 000" },
      { type: "txt", value: "50 000" },
      { type: "txt", value: "100 000" },
      { type: "txt", value: "72 000" },
    ],
    answer: 0,
  },
  {
    id: 73,
    question: [
      {
        type: "txt",
        value:
          "Karim bilan Anvar bitta poyezdda sayrga chiqishdi. Karim poyezdning boshiga nisbatan 117 chi vagonda, Anvar esa poyezdning oxiriga nisbatan 134 chi vagonda ketmoqda. Agar ularning joylashgan vagoni qo’shni bo’lsa, poyezd nechta vagondan iborat?",
      },
    ],
    options: [
      { type: "txt", value: "252" },
      { type: "txt", value: "248" },
      { type: "txt", value: "250" },
      { type: "txt", value: "249" },
    ],
    answer: 3,
  },
  {
    id: 74,
    question: [
      {
        type: "txt",
        value:
          "Sakkizta basketbolchining o’rtacha bo’yi 201 santimetr. Ular orasida bo’yi 198 santimetrdan past bo’lgan eng ko’pi bilan nechta o’yinchi bo’lishi mumkin?",
      },
    ],
    options: [
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
      { type: "txt", value: "7" },
    ],
    answer: 3,
  },
  {
    id: 75,
    question: [
      { type: "txt", value: "Kubning sirti 24 sm². Kubning hajmini toping." },
    ],
    options: [
      { type: "txt", value: "83 sm³" },
      { type: "txt", value: "63 sm³" },
      { type: "txt", value: "24 sm³" },
      { type: "txt", value: "8 sm³" },
    ],
    answer: 3,
  },
  {
    id: 76,
    question: [
      {
        type: "txt",
        value:
          "Stol ustida beshburchak va oltiburchaklar yotibdi. Ularning hammasi bo’lib 37 ta uchi bo’lsa, stol ustida nechta beshburchak bor?",
      },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
    ],
    answer: 3,
  },
  {
    id: 77,
    question: [
      {
        type: "txt",
        value:
          "1861 gacha bo’lgan sonlar ichida nechta son 12 ga ham 15 ga ham bo’linmaydi?",
      },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "5" },
      { type: "txt", value: "10" },
      { type: "txt", value: "13" },
    ],
    answer: 2,
  },
  {
    id: 78,
    question: [
      {
        type: "txt",
        value:
          "Ra’no «Men uyga ketdim» gapidagi har bir so’zdagi harflar sonini ko’paytirmoqda. U qanday javob olgan?",
      },
    ],
    options: [
      { type: "txt", value: "18" },
      { type: "txt", value: "30" },
      { type: "txt", value: "36" },
      { type: "txt", value: "72" },
    ],
    answer: 3,
  },
  {
    id: 79,
    question: [
      {
        type: "txt",
        value:
          "Anvar va G’ulom ikkalasi 16 martda tug’ilgan va G’ulom tug’ilganda Anvar 3 yosh edi. G’ulom nechaga to’lganda, uning yoshi Anvarning yoshidan ikki barobar kichik bo’ladi?",
      },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
    ],
    answer: 2,
  },
  {
    id: 80,
    question: [
      {
        type: "txt",
        value:
          "Karim bilan Anvar bitta poyezdda sayrga chiqishdi. Karim poyezdning boshiga nisbatan 117 chi vagonda, Anvar esa poyezdning oxiriga nisbatan 134 chi vagonda ketmoqda. Agar ularning joylashgan vagoni qo’shni bo’lsa, poyezd nechta vagondan iborat?",
      },
    ],
    options: [
      { type: "txt", value: "252" },
      { type: "txt", value: "248" },
      { type: "txt", value: "250" },
      { type: "txt", value: "249" },
    ],
    answer: 3,
  },
  {
    id: 81,
    question: [
      {
        type: "txt",
        value:
          "Hikmat bobo qarib qolganligi sababli, xovlisidagi 108 qop yong’oqni omborga tashish uchun nevaralarini ishga chaqirdi. Nevaralari kelib juft–juft bo’lib bo’linishdi va har bir juftlikka 3 ta qop to’g’ri keldi. Hikmat boboning nechta nevarasi ishga kelgan?",
      },
    ],
    options: [
      { type: "txt", value: "108" },
      { type: "txt", value: "96" },
      { type: "txt", value: "72" },
      { type: "txt", value: "36" },
    ],
    answer: 2,
  },
  {
    id: 82,
    question: [
      {
        type: "txt",
        value: "Qaysi xalqani uzsak, xalqalar bir-biridan ajraladi?",
      },
      { type: "img", value: "/quiz-images-2/saralash/img67.png" },
    ],
    options: [
      { type: "txt", value: "A" },
      { type: "txt", value: "B" },
      { type: "txt", value: "C" },
      { type: "txt", value: "D" },
    ],
    answer: 2,
  },
  {
    id: 83,
    question: [
      {
        type: "txt",
        value:
          "Sakkizta basketbolchining o’rtacha bo’yi 201 santimetr. Ular orasida bo’yi 198 santimetrdan past bo’lgan eng ko’pi bilan nechta o’yinchi bo’lishi mumkin?",
      },
    ],
    options: [
      { type: "txt", value: "1" },
      { type: "txt", value: "5" },
      { type: "txt", value: "3" },
      { type: "txt", value: "7" },
    ],
    answer: 3,
  },
  {
    id: 84,
    question: [
      {
        type: "txt",
        value:
          "Anvarda yettita sanoq cho’pi bor edi. U bu cho’plardan birini ikki bo’lakga bo’ldi. Endi unda nechta sanoq cho’pi bor?",
      },
    ],
    options: [
      { type: "txt", value: "5" },
      { type: "txt", value: "6" },
      { type: "txt", value: "7" },
      { type: "txt", value: "8" },
    ],
    answer: 3,
  },
  {
    id: 85,
    question: [
      {
        type: "txt",
        value:
          "Karimning o’g’il bola sinfdoshlari soni qiz bola sinfdoshlari sonidan 7 taga ko’p. O’g’il bolalar soni qiz bolalar sonidan 2 marta ko’p. Zuhra – Karimning sinfdoshi. Zuhraning sinfdosh dugonalari nechta?",
      },
    ],
    options: [
      { type: "txt", value: "6" },
      { type: "txt", value: "7" },
      { type: "txt", value: "8" },
      { type: "txt", value: "9" },
    ],
    answer: 1,
  },
  {
    id: 86,
    question: [
      {
        type: "txt",
        value:
          "Agar kecha dushanba bo’lganda edi, u holda bugungi kun yarmidan 72 soat keyingi kun, aslida haqiqiy kunning ertangi kunining ertasi bo’lar edi. Bundan kelib chiqadiki, ertaga",
      },
    ],
    options: [
      { type: "txt", value: "dushanba" },
      { type: "txt", value: "seshanba" },
      { type: "txt", value: "payshanba" },
      { type: "txt", value: "yakshanba" },
    ],
    answer: 2,
  },
  {
    id: 87,
    question: [
      {
        type: "txt",
        value:
          "Agar qizil ajdarxoning boshlari soni yashil ajdarxoning boshlari sonidan 6 ta ko’p bo’lganda edi, ularning umumiy boshlari soni 34 ta bo’lar edi. Ammo qizil ajdarxoning boshlari soni yashil ajdarxoning boshlari sonidan 6 ta kam. Qizil ajdarxoning boshi nechta?",
      },
    ],
    options: [
      { type: "txt", value: "6" },
      { type: "txt", value: "8" },
      { type: "txt", value: "12" },
      { type: "txt", value: "14" },
    ],
    answer: 1,
  },
  {
    id: 88,
    question: [
      {
        type: "txt",
        value:
          "Velosipedchi tepalikka 12 km/soat tezlik bilan chiqdi. Chiqqan yo’li orqali 20 km/soat tezlik bilan tushdi va chiqishdagiga qaraganda 16 minut kamroq vaqt sarfladi. Yo’lning uzunligini toping.",
      },
    ],
    options: [
      { type: "txt", value: "8 km" },
      { type: "txt", value: "10 km" },
      { type: "txt", value: "12 km" },
      { type: "txt", value: "14 km" },
    ],
    answer: 0,
  },
  {
    id: 89,
    question: [
      {
        type: "txt",
        value:
          "Avtomobil poyg’asida uchta avtomobil qatnashdi. Ular poyg’ani quyidagi tartibda boshladilar: L, N, M, ya’ni avval «Lacetti», keyin «Nexia», so’ngra «Matiz». Poyga davomida «Lacetti» 3 marta, «Nexia» 5 marta, «Matiz» 8 marta quvib o’tgan bo’lsa, mashinalar marraga qaysi tartibda kelishgan?",
      },
    ],
    options: [
      { type: "txt", value: "N M L" },
      { type: "txt", value: "L M N" },
      { type: "txt", value: "M N L" },
      { type: "txt", value: "L N M" },
    ],
    answer: 3,
  },
  {
    id: 90,
    question: [
      {
        type: "txt",
        value:
          "Kema bortiga 30 ta yo’lovchi chiqqanidan so’ng, kemadagi suv zaxirasi oldingiday 60 kunga emas, 50 kunga yetishi ma’lum bo’ldi. Dastlab kemada nechta yo’lovchi bo’lgan?",
      },
    ],
    options: [
      { type: "txt", value: "15" },
      { type: "txt", value: "150" },
      { type: "txt", value: "110" },
      { type: "txt", value: "140" },
    ],
    answer: 1,
  },
  {
    id: 91,
    question: [
      {
        type: "txt",
        value:
          "O’quvchilar test topshirig’ini muhokama qilishmoqda.    Munisa to’g’ri javob A yoki D dedi.    Otabek to’g’ri javob B yoki E dedi.     Kumush A, B, va C noto’g’ri javoblar dedi.    Farxod to’g’ri javob A dedi.   Shirin siz hammangiz xato qilyapsizlar dedi.      Agar har bir o’quvchi bir martadan xato qilgan bo’lsa, qaysi javob to’g’ri?",
      },
    ],
    options: [
      { type: "txt", value: "A" },
      { type: "txt", value: "B" },
      { type: "txt", value: "C" },
      { type: "txt", value: "D" },
    ],
    answer: 2,
  },
  {
    id: 92,
    question: [
      {
        type: "txt",
        value:
          "Uchta toshbaqa – Andi, Bandi va Kandi – 30 m masofaga yugurish bo’yicha musobaqa o’ynashmoqda. Ular bir paytda musobaqani boshlashdi. Andi marraga kelganda Bandiga marragacha 10 m qolgan edi, Kandi esa Bandidan 4 m oldinda edi. Kandi marraga yetib kelganda Bandidan marragacha qancha masofa qoladi( toshbaqalar o’zgarmas bir xil tezlikda harakat qilishmoqda)?",
      },
    ],
    options: [
      { type: "txt", value: "2 m" },
      { type: "txt", value: "3 m" },
      { type: "txt", value: "4 m" },
      { type: "txt", value: "5 m" },
    ],
    answer: 2,
  },
  {
    id: 93,
    question: [
      { type: "txt", value: "Sutkaning 6 dan 3 qismi necha soatga teng." },
    ],
    options: [
      { type: "txt", value: "12" },
      { type: "txt", value: "32" },
      { type: "txt", value: "40" },
      { type: "txt", value: "16" },
    ],
    answer: 0,
  },
  {
    id: 94,
    question: [
      { type: "txt", value: "Detsimetr kvadratlarda ifodalang. 2 m² =__" },
    ],
    options: [
      { type: "txt", value: "2 dm²" },
      { type: "txt", value: "200 dm²" },
      { type: "txt", value: "2000 dm²" },
      { type: "txt", value: "20000 dm²" },
    ],
    answer: 1,
  },
  {
    id: 95,
    question: [
      {
        type: "txt",
        value:
          "Maktab bog'ida o'quvchilar 6 kun 100 tupdan, 5 kun 150 tupdan ko'chat o'tqazishdi. 2 kunda hammasi bolib nechta ko'chat o'tqazishgan?",
      },
    ],
    options: [
      { type: "txt", value: "1350 tup" },
      { type: "txt", value: "600 tup" },
      { type: "txt", value: "400 tup" },
      { type: "txt", value: "750 tup" },
    ],
    answer: 0,
  },
  {
    id: 96,
    question: [{ type: "txt", value: "XIX – X + IX : III ni hisoblang." }],
    options: [
      { type: "txt", value: "XII" },
      { type: "txt", value: "VI" },
      { type: "txt", value: "IX" },
      { type: "txt", value: "X" },
    ],
    answer: 0,
  },
  {
    id: 97,
    question: [
      {
        type: "txt",
        value:
          "Uchburchak shaklidagi maydonning perimetri 1640 m ga teng. Birinchi tamoni 400 m ga, ikkinchi tamoni birinchi tamonodan 300 m ga ortiq bo’lsa, uchinchi tamonni toping.",
      },
    ],
    options: [
      { type: "txt", value: "540 m" },
      { type: "txt", value: "700 m" },
      { type: "txt", value: "1100 m" },
      { type: "txt", value: "1240 m" },
    ],
    answer: 0,
  },
  {
    id: 98,
    question: [
      {
        type: "txt",
        value:
          "Jasur ikkiyuzta yuz va bitta birni qo’shib, qaysi natijani olishi mumkin?",
      },
    ],
    options: [
      { type: "txt", value: "201" },
      { type: "txt", value: "1201" },
      { type: "txt", value: "2001" },
      { type: "txt", value: "20001" },
    ],
    answer: 3,
  },
  {
    id: 99,
    question: [
      {
        type: "txt",
        value:
          "Rasmda 1 dan 4 gacha bo’lgan raqam bilan uning oynadagi tasviri yonma-yon ko’rsatilgan.  Keyingi rasm qanday ko’rinishda bo’ladi?",
      },
      { type: "img", value: "/quiz-images-2/saralash/img68.png" },
    ],
    options: [
      { type: "img", value: "/quiz-images-2/saralash/img69.png" },
      { type: "img", value: "/quiz-images-2/saralash/img70.png" },
      { type: "img", value: "/quiz-images-2/saralash/img71.png" },
      { type: "img", value: "/quiz-images-2/saralash/img72.png" },
      { type: "img", value: "/quiz-images-2/saralash/img73.png" },
    ],
    answer: 1,
  },
  {
    id: 100,
    question: [
      { type: "txt", value: "Rasmda qanday geometrik shakllar yo’q? " },
      { type: "img", value: "/quiz-images-2/saralash/img74.png" },
    ],
    options: [
      { type: "txt", value: "To’g’ri to’rtburchaklar" },
      { type: "txt", value: "uchburchaklar" },
      { type: "txt", value: "kvadratlar" },
      { type: "txt", value: "doiralar" },
    ],
    answer: 1,
  },
  {
    id: 101,
    question: [
      {
        type: "txt",
        value:
          "Maktab hovlisida 19 ta qiz va 12 ta o’g’il bola o’ynamoqda. Yana qancha maktab o’quvchisi ularga qo’shilsa, ular 6 ta teng jamoaga bo’linishi mumkin?",
      },
    ],
    options: [
      { type: "txt", value: "2" },
      { type: "txt", value: "3" },
      { type: "txt", value: "4" },
      { type: "txt", value: "5" },
    ],
    answer: 3,
  },
  {
    id: 102,
    question: [
      {
        type: "txt",
        value:
          "Quyidagi sonlardan qaysi biri ko’paytirish jadvalida boshqalariga qaraganda ko’proq uchraydi?",
      },
    ],
    options: [
      { type: "txt", value: "36" },
      { type: "txt", value: "42" },
      { type: "txt", value: "56" },
      { type: "txt", value: "64" },
    ],
    answer: 0,
  },
  {
    id: 103,
    question: [
      {
        type: "txt",
        value:
          "10 km ga yugurush musobaqasida Toshmat 9 641m yugurdi, keyin 3 456dm yurdi va 12 340 mm so’ng charchab to’xtab qoldi. Toshmatdan finifgacha necha sm qolgan?",
      },
    ],
    options: [
      { type: "txt", value: "1060 sm" },
      { type: "txt", value: "160 sm" },
      { type: "txt", value: "106 sm" },
      { type: "txt", value: "100 sm " },
    ],
    answer: 2,
  },
  {
    id: 104,
    question: [
      {
        type: "txt",
        value: "Bobur maktabdan uyiga qanday tezlikda borishi mumkin?",
      },
    ],
    options: [
      { type: "txt", value: "20 m/soat" },
      { type: "txt", value: "1 km/min" },
      { type: "txt", value: "4000 m/soat" },
      { type: "txt", value: "900 m/min" },
    ],
    answer: 2,
  },
  {
    id: 105,
    question: [
      {
        type: "txt",
        value:
          "Karim bilan Anvar bitta poyezdda sayrga chiqishdi. Karim poyezdning boshiga nisbatan 117 chi vagonda, Anvar esa poyezdning oxiriga nisbatan 134 chi vagonda ketmoqda. Agar ularning joylashgan vagoni qo’shni bo’lsa, poyezd nechta vagondan iborat?",
      },
    ],
    options: [
      { type: "txt", value: "252" },
      { type: "txt", value: "248" },
      { type: "txt", value: "250" },
      { type: "txt", value: "249" },
    ],
    answer: 3,
  },
  {
    id: 106,
    question: [
      {
        type: "txt",
        value:
          "Eski soat har soatda 20 sekund orqada qolmoqda. Soat 12 da to’g’rilanib, bir kundan so’ng qaralsa soat nechani ko’rsatadi?",
      },
    ],
    options: [
      { type: "txt", value: "12:08" },
      { type: "txt", value: "12:02" },
      { type: "txt", value: "11:52" },
      { type: "txt", value: "11:50" },
    ],
    answer: 2,
  },
  {
    id: 107,
    question: [
      {
        type: "txt",
        value:
          "Ushbu 1991, 2323, 2222, 3131, 2332, 5252  sonlarni teskari tartibda o’qisak ulardan nechtasi kamayadi.",
      },
    ],
    options: [
      { type: "txt", value: "0" },
      { type: "txt", value: "1" },
      { type: "txt", value: "2" },
      { type: "txt", value: "4" },
    ],
    answer: 2,
  },
];

export const specializedSchoolEnglish_3class = [
  {
    id: 0,
    question: [{ type: "txt", value: "Tarjima qiling ( Listen and sing )" }],
    options: [
      { type: "txt", value: "kuzat va kuyla" },
      { type: "txt", value: "tingla va kuyla" },
      { type: "txt", value: "uxla va tingla" },
      { type: "txt", value: "all above" },
    ],
    answer: 1,
  },
  {
    id: 1,
    question: [
      { type: "txt", value: "This is my friend. Her name is _________." },
    ],
    options: [
      { type: "txt", value: "Jack" },
      { type: "txt", value: "John" },
      { type: "txt", value: "Anna" },
      { type: "txt", value: "All above " },
    ],
    answer: 2,
  },
  {
    id: 2,
    question: [{ type: "txt", value: "Shavkat can _____ to school " }],
    options: [
      { type: "txt", value: "go" },
      { type: "txt", value: "drink" },
      { type: "txt", value: "think" },
      { type: "txt", value: "sleep" },
    ],
    answer: 0,
  },
  {
    id: 3,
    question: [{ type: "txt", value: "What's your favourite day ? " }],
    options: [
      { type: "txt", value: "new year" },
      { type: "txt", value: "January 1st" },
      { type: "txt", value: "Sunday" },
      { type: "txt", value: "Yesterday" },
    ],
    answer: 2,
  },
  {
    id: 4,
    question: [{ type: "txt", value: "Are you ok?" }],
    options: [
      { type: "txt", value: "Ok" },
      { type: "txt", value: "Yes I’m" },
      { type: "txt", value: "Yes you're" },
      { type: "txt", value: "No I'm" },
    ],
    answer: 1,
  },
  {
    id: 5,
    question: [
      { type: "txt", value: "How many animals are there in the picture " },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/english/img1.png",
      },
    ],
    options: [
      { type: "txt", value: "17" },
      { type: "txt", value: "16" },
      { type: "txt", value: "18" },
      { type: "txt", value: "15" },
    ],
    answer: 0,
  },
  {
    id: 6,
    question: [{ type: "txt", value: "_____ car" }],
    options: [
      { type: "txt", value: "a" },
      { type: "txt", value: "are" },
      { type: "txt", value: "an" },
      { type: "txt", value: "is" },
    ],
    answer: 0,
  },
  {
    id: 7,
    question: [
      {
        type: "txt",
        value:
          "My friend Jany lives in Berlin City . ______ lives in Berlin City .",
      },
    ],
    options: [
      { type: "txt", value: "she" },
      { type: "txt", value: "he" },
      { type: "txt", value: "they" },
      { type: "txt", value: "we" },
    ],
    answer: 0,
  },
  {
    id: 8,
    question: [{ type: "txt", value: "The school_____ twenty rooms " }],
    options: [
      { type: "txt", value: "have" },
      { type: "txt", value: "has" },
      { type: "txt", value: "is" },
      { type: "txt", value: "are" },
    ],
    answer: 1,
  },
  {
    id: 9,
    question: [
      {
        type: "txt",
        value:
          "Do you want another ice-cream ?   -No , thank you , I've still got _____",
      },
    ],
    options: [
      { type: "txt", value: "some" },
      { type: "txt", value: "someone" },
      { type: "txt", value: "someone's " },
      { type: "txt", value: "ones" },
    ],
    answer: 0,
  },
  {
    id: 10,
    question: [
      { type: "txt", value: ' Tarjima qiling ! "There is a camel at home "' },
    ],
    options: [
      { type: "txt", value: "uyda tuya bor " },
      { type: "txt", value: "uyda tuyalar bor" },
      { type: "txt", value: "uyda echkilar bor" },
      { type: "txt", value: "uyda hech kim yo'q" },
    ],
    answer: 0,
  },
  {
    id: 11,
    question: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/english/img2.png",
      },
      { type: "txt", value: "Who is Midas ?" },
    ],
    options: [
      { type: "txt", value: "a young queen " },
      { type: "txt", value: "a rich king " },
      { type: "txt", value: "a beautiful princess " },
      { type: "txt", value: "a poor man" },
    ],
    answer: 1,
  },
  {
    id: 12,
    question: [
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/english/img2.png",
      },
      { type: "txt", value: "What does the King love the most ?" },
    ],
    options: [
      { type: "txt", value: "silver" },
      { type: "txt", value: "diamonds" },
      { type: "txt", value: "his garden" },
      { type: "txt", value: "gold" },
    ],
    answer: 2,
  },
  {
    id: 13,
    question: [
      { type: "txt", value: "This is my sister . _______ name is Madina " },
    ],
    options: [
      { type: "txt", value: "his" },
      { type: "txt", value: "my" },
      { type: "txt", value: "her" },
      { type: "txt", value: "its" },
    ],
    answer: 2,
  },
  {
    id: 14,
    question: [
      { type: "txt", value: "English is _________ international language" },
    ],
    options: [
      { type: "txt", value: "a" },
      { type: "txt", value: "an" },
      { type: "txt", value: "the" },
      { type: "txt", value: "*" },
    ],
    answer: 1,
  },
  {
    id: 15,
    question: [
      { type: "txt", value: "Find the opposite word" },
      { type: "txt", value: "Fire - ______" },
    ],
    options: [
      { type: "txt", value: "fireman" },
      { type: "txt", value: "oil" },
      { type: "txt", value: "orange" },
      { type: "txt", value: "water" },
    ],
    answer: 3,
  },
  {
    id: 16,
    question: [
      { type: "txt", value: "Find the opposite word" },
      { type: "txt", value: " Sky - _____" },
    ],
    options: [
      { type: "txt", value: "earth" },
      { type: "txt", value: "ocean" },
      { type: "txt", value: "river" },
      { type: "txt", value: "mountain" },
    ],
    answer: 0,
  },
  {
    id: 17,
    question: [
      { type: "txt", value: "Find the opposite word" },
      { type: "txt", value: "Difficult -_______" },
    ],
    options: [
      { type: "txt", value: "difference" },
      { type: "txt", value: "easy" },
      { type: "txt", value: "cheap" },
      { type: "txt", value: "different" },
    ],
    answer: 1,
  },
  {
    id: 18,
    question: [
      { type: "txt", value: "Fill the gap in the picture" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/english/img3.png",
      },
    ],
    options: [
      { type: "txt", value: "paper" },
      { type: "txt", value: "kite" },
      { type: "txt", value: "window" },
      { type: "txt", value: "phone" },
    ],
    answer: 0,
  },
  {
    id: 19,
    question: [
      { type: "txt", value: "Fill the gap in the picture" },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/english/img4.png",
      },
    ],
    options: [
      { type: "txt", value: "doesn't" },
      { type: "txt", value: "don't" },
      { type: "txt", value: "isn't" },
      { type: "txt", value: "do" },
    ],
    answer: 1,
  },
  {
    id: 20,
    question: [{ type: "txt", value: " _____ ambulance" }],
    options: [
      { type: "txt", value: "a" },
      { type: "txt", value: "an" },
      { type: "txt", value: "are" },
      { type: "txt", value: "is" },
    ],
    answer: 1,
  },
  {
    id: 21,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question.    Brian and Nancy sometimes go to zoo . They go about twise a year . They sometimes have have a picnic but not always . They usually play soccer but they sometimes fly their kites . They never go camping because they don’t have a tent .",
      },
      { type: "txt", value: "Where do Brian and Nancy sometimes go ?" },
    ],
    options: [
      { type: "txt", value: "to jog" },
      { type: "txt", value: "to school" },
      { type: "txt", value: "to swim" },
      { type: "txt", value: "to the zoo" },
    ],
    answer: 3,
  },
  {
    id: 22,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question.    Brian and Nancy sometimes go to zoo . They go about twise a year . They sometimes have have a picnic but not always . They usually play soccer but they sometimes fly their kites . They never go camping because they don’t have a tent .",
      },
      { type: "txt", value: "Why do not they go camping ?" },
    ],
    options: [
      { type: "txt", value: "no money" },
      { type: "txt", value: "no tent" },
      { type: "txt", value: "no time" },
      { type: "txt", value: "no place" },
    ],
    answer: 1,
  },
  {
    id: 23,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question. Hello my name is Muslima . Im eleven years old. My family is big . I have got grandfather , grandmother , father , mother, brother, little brother and little sister . I study at “ Merit school”. Merit school is very big and beautiful . Im five “D” class. My favorite things are books and my mobile phone . Im very happy girl . My hobby is drawing . I like drawing my future clothes . When it comes to me sibling. My two brothers and me sister are pupils as well. My sister’s name is Osiyo. She is 3 A class . My brother’s name is Abdurahim . He is 7 D class . They also study at Merit school . I love them . Because  we spend all time wisely together . When I come across complexity of getting subject at me school always my brother helps me . My parent as well as me grandparent are so kind . Im very happy with them. ",
      },
      { type: "txt", value: "What is my name ?" },
    ],
    options: [
      { type: "txt", value: "Osiyo" },
      { type: "txt", value: "Muslima" },
      { type: "txt", value: "Abdurahim" },
      { type: "txt", value: "Merit" },
    ],
    answer: 1,
  },
  {
    id: 24,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question. Hello my name is Muslima . Im eleven years old. My family is big . I have got grandfather , grandmother , father , mother, brother, little brother and little sister . I study at “ Merit school”. Merit school is very big and beautiful . Im five “D” class. My favorite things are books and my mobile phone . Im very happy girl . My hobby is drawing . I like drawing my future clothes . When it comes to me sibling. My two brothers and me sister are pupils as well. My sister’s name is Osiyo. She is 3 A class . My brother’s name is Abdurahim . He is 7 D class . They also study at Merit school . I love them . Because  we spend all time wisely together . When I come across complexity of getting subject at me school always my brother helps me . My parent as well as me grandparent are so kind . Im very happy with them. ",
      },
      { type: "txt", value: "Who is 3 A class?" },
    ],
    options: [
      { type: "txt", value: "None" },
      { type: "txt", value: "My sister" },
      { type: "txt", value: "My little brother" },
      { type: "txt", value: "my older brother" },
    ],
    answer: 1,
  },
  {
    id: 25,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question. Hello my name is Muslima . Im eleven years old. My family is big . I have got grandfather , grandmother , father , mother, brother, little brother and little sister . I study at “ Merit school”. Merit school is very big and beautiful . Im five “D” class. My favorite things are books and my mobile phone . Im very happy girl . My hobby is drawing . I like drawing my future clothes . When it comes to me sibling. My two brothers and me sister are pupils as well. My sister’s name is Osiyo. She is 3 A class . My brother’s name is Abdurahim . He is 7 D class . They also study at Merit school . I love them . Because  we spend all time wisely together . When I come across complexity of getting subject at me school always my brother helps me . My parent as well as me grandparent are so kind . Im very happy with them. ",
      },
      { type: "txt", value: "My school is very big and ______ ." },
    ],
    options: [
      { type: "txt", value: "Long" },
      { type: "txt", value: "Attractive" },
      { type: "txt", value: "Smart" },
      { type: "txt", value: "Visible" },
    ],
    answer: 3,
  },
  {
    id: 26,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question. Hello my name is Muslima . Im eleven years old. My family is big . I have got grandfather , grandmother , father , mother, brother, little brother and little sister . I study at “ Merit school”. Merit school is very big and beautiful . Im five “D” class. My favorite things are books and my mobile phone . Im very happy girl . My hobby is drawing . I like drawing my future clothes . When it comes to me sibling. My two brothers and me sister are pupils as well. My sister’s name is Osiyo. She is 3 A class . My brother’s name is Abdurahim . He is 7 D class . They also study at Merit school . I love them . Because  we spend all time wisely together . When I come across complexity of getting subject at me school always my brother helps me . My parent as well as me grandparent are so kind . Im very happy with them. ",
      },
      { type: "txt", value: "My best- loved things are _____." },
    ],
    options: [
      { type: "txt", value: "My mobile phone " },
      { type: "txt", value: "Books" },
      { type: "txt", value: "Playing Games" },
      { type: "txt", value: "Both B and A" },
    ],
    answer: 3,
  },
  {
    id: 27,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question. Hello my name is Muslima . Im eleven years old. My family is big . I have got grandfather , grandmother , father , mother, brother, little brother and little sister . I study at “ Merit school”. Merit school is very big and beautiful . Im five “D” class. My favorite things are books and my mobile phone . Im very happy girl . My hobby is drawing . I like drawing my future clothes . When it comes to me sibling. My two brothers and me sister are pupils as well. My sister’s name is Osiyo. She is 3 A class . My brother’s name is Abdurahim . He is 7 D class . They also study at Merit school . I love them . Because  we spend all time wisely together . When I come across complexity of getting subject at me school always my brother helps me . My parent as well as me grandparent are so kind . Im very happy with them. ",
      },
      { type: "txt", value: "What do I like ?" },
    ],
    options: [
      { type: "txt", value: "drawing" },
      { type: "txt", value: "sleeping" },
      { type: "txt", value: "talking" },
      { type: "txt", value: "listening to music" },
    ],
    answer: 0,
  },
  {
    id: 28,
    question: [
      {
        type: "txt",
        value:
          "When I find difficult on my school subject, Who gives me a hent ?",
      },
    ],
    options: [
      { type: "txt", value: "My sister" },
      { type: "txt", value: "My brother" },
      { type: "txt", value: "My mother" },
      { type: "txt", value: "My father" },
    ],
    answer: 1,
  },
];

export const specializedSchoolEnglish_4class = [
  {
    id: 0,
    question: [{ type: "0", value: "They’re not his _______ mistakes" }],
    options: [
      { type: "txt", value: "Friend’s" },
      { type: "txt", value: "Friends" },
      { type: "txt", value: "Friend" },
      { type: "txt", value: "Friens’s" },
    ],
    answer: 0,
  },
  {
    id: 1,
    question: [{ type: "txt", value: "________ a magazine" }],
    options: [
      { type: "txt", value: "Go" },
      { type: "txt", value: "Read" },
      { type: "txt", value: "Live" },
      { type: "txt", value: "Has" },
    ],
    answer: 1,
  },
  {
    id: 2,
    question: [{ type: "txt", value: "Where ____ you ____ ?" }],
    options: [
      { type: "txt", value: "Is/from" },
      { type: "txt", value: "Are/is" },
      { type: "txt", value: "Are/go" },
      { type: "txt", value: "Are/from" },
    ],
    answer: 3,
  },
  {
    id: 3,
    question: [{ type: "txt", value: "She is ___ student" }],
    options: [
      { type: "txt", value: "An" },
      { type: "txt", value: "*" },
      { type: "txt", value: "A" },
      { type: "txt", value: "The" },
    ],
    answer: 2,
  },
  {
    id: 4,
    question: [{ type: "txt", value: "I’m ___ New York" }],
    options: [
      { type: "txt", value: "For" },
      { type: "txt", value: "At" },
      { type: "txt", value: "Of" },
      { type: "txt", value: "In" },
    ],
    answer: 3,
  },
  {
    id: 5,
    question: [
      { type: "txt", value: "“where ____ now?”    “In her office “ " },
    ],
    options: [
      { type: "txt", value: "Is he" },
      { type: "txt", value: "Is she" },
      { type: "txt", value: "Is it" },
      { type: "txt", value: "Is" },
    ],
    answer: 1,
  },
  {
    id: 6,
    question: [
      {
        type: "txt",
        value:
          "Look at the picture and answer the question.        Dionysus sends Midas to have a bath in the Pactolus river and wash the spell away . The King follows Dionysus’ instructions .The magic disappears…..",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/english/img7.png",
      },
      { type: "txt", value: "What lesson does Midas learn in this story ?" },
    ],
    options: [
      { type: "txt", value: "Hard work always rewarded!" },
      { type: "txt", value: "Always help people in need !" },
      { type: "txt", value: "Don’t waste your gold!" },
      { type: "txt", value: "Be careful what you wish for!" },
    ],
    answer: 3,
  },
  {
    id: 7,
    question: [
      {
        type: "txt",
        value:
          "Look at the picture and answer the question.        Dionysus sends Midas to have a bath in the Pactolus river and wash the spell away . The King follows Dionysus’ instructions .The magic disappears…..",
      },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/english/img7.png",
      },
      { type: "txt", value: "To wash the spell away the King has to ____. " },
    ],
    options: [
      { type: "txt", value: "Throw his gold into the river" },
      { type: "txt", value: "Bathe in the river" },
      { type: "txt", value: "Help poor people in need" },
      { type: "txt", value: "Drink water from Pactolus River" },
    ],
    answer: 1,
  },
  {
    id: 8,
    question: [{ type: "txt", value: "They _____ happy animals." }],
    options: [
      { type: "txt", value: "Is" },
      { type: "txt", value: "Are" },
      { type: "txt", value: "Isn’t" },
      { type: "txt", value: "Am" },
    ],
    answer: 1,
  },
  {
    id: 9,
    question: [
      {
        type: "txt",
        value: "The Lizards _______ sitting in the sun at the moment .",
      },
    ],
    options: [
      { type: "txt", value: "Am" },
      { type: "txt", value: "Is" },
      { type: "txt", value: "Are" },
      { type: "txt", value: "They" },
    ],
    answer: 1,
  },
  {
    id: 10,
    question: [{ type: "txt", value: "You____ do your homework every day." }],
    options: [
      { type: "txt", value: "Must not" },
      { type: "txt", value: "Have to" },
      { type: "txt", value: "Must" },
      { type: "txt", value: "May" },
    ],
    answer: 2,
  },
  {
    id: 11,
    question: [
      { type: "txt", value: "We usually ____ at home in the evening." },
    ],
    options: [
      { type: "txt", value: "Stay" },
      { type: "txt", value: "Staying" },
      { type: "txt", value: "Stays" },
      { type: "txt", value: "Stayed" },
    ],
    answer: 0,
  },
  {
    id: 12,
    question: [
      { type: "txt", value: "_____ your grandma bake a cake last Friday?" },
    ],
    options: [
      { type: "txt", value: "Do" },
      { type: "txt", value: "Does" },
      { type: "txt", value: "Did" },
      { type: "txt", value: "Had" },
    ],
    answer: 2,
  },
  {
    id: 13,
    question: [
      { type: "txt", value: "We ___ scared at the cinema last night." },
    ],
    options: [
      { type: "txt", value: "Were" },
      { type: "txt", value: "Did" },
      { type: "txt", value: "Was" },
      { type: "txt", value: "Are" },
    ],
    answer: 0,
  },
  {
    id: 14,
    question: [
      {
        type: "txt",
        value: "There _______ a cake in a cakeshop.      Where is it now?",
      },
    ],
    options: [
      { type: "txt", value: "Is" },
      { type: "txt", value: "Are" },
      { type: "txt", value: "Were" },
      { type: "txt", value: "Isn’t" },
    ],
    answer: 3,
  },
  {
    id: 15,
    question: [{ type: "txt", value: "____ you like computer games ?" }],
    options: [
      { type: "txt", value: "Can" },
      { type: "txt", value: "May" },
      { type: "txt", value: "Do" },
      { type: "txt", value: "Are" },
    ],
    answer: 2,
  },
  {
    id: 16,
    question: [
      { type: "txt", value: "I’m sorry , I’m late . _____ I come in?" },
    ],
    options: [
      { type: "txt", value: "Can" },
      { type: "txt", value: "Must" },
      { type: "txt", value: "Have" },
      { type: "txt", value: "May" },
    ],
    answer: 3,
  },
  {
    id: 17,
    question: [{ type: "txt", value: "My little sister ____ to be a lawyer." }],
    options: [
      { type: "txt", value: "Goes" },
      { type: "txt", value: "Are going" },
      { type: "txt", value: "Is going" },
      { type: "txt", value: "Go" },
    ],
    answer: 2,
  },
  {
    id: 18,
    question: [
      { type: "txt", value: "Look at this ! they ____ new youth magazine" },
    ],
    options: [
      { type: "txt", value: "Are going to read" },
      { type: "txt", value: "Is going to read" },
      { type: "txt", value: "Are not going to read" },
      { type: "txt", value: "Is not going to read" },
    ],
    answer: 0,
  },
  {
    id: 19,
    question: [
      {
        type: "txt",
        value: "My mother and father are kind and caring . I love my ____.",
      },
    ],
    options: [
      { type: "txt", value: "Classmates" },
      { type: "txt", value: "Characteristics" },
      { type: "txt", value: "Jobs" },
      { type: "txt", value: "Parent" },
    ],
    answer: 3,
  },
  {
    id: 20,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question.    She is a waitress. She works at a restaurant . A customer walks in . She walks him to a table . She gives him a menu . She puts a plate, fork, and spoon in front of him . He orders fried chicken . She smiles . She is very quick . She gives him his meal . He finishes eating . He gives her a big tip.",
      },
      { type: "txt", value: "She works at a restaurant  as a_______." },
    ],
    options: [
      { type: "txt", value: "Customer" },
      { type: "txt", value: "Quick" },
      { type: "txt", value: "Menu" },
      { type: "txt", value: "Waitress" },
    ],
    answer: 3,
  },
  {
    id: 21,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question.    She is a waitress. She works at a restaurant . A customer walks in . She walks him to a table . She gives him a menu . She puts a plate, fork, and spoon in front of him . He orders fried chicken . She smiles . She is very quick . She gives him his meal . He finishes eating . He gives her a big tip.",
      },
      { type: "txt", value: "What does customer do ?" },
    ],
    options: [
      { type: "txt", value: "Puts a plate" },
      { type: "txt", value: "Walks in" },
      { type: "txt", value: "Spoon it" },
      { type: "txt", value: "Make a meal" },
    ],
    answer: 1,
  },
  {
    id: 22,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question.    She is a waitress. She works at a restaurant . A customer walks in . She walks him to a table . She gives him a menu . She puts a plate, fork, and spoon in front of him . He orders fried chicken . She smiles . She is very quick . She gives him his meal . He finishes eating . He gives her a big tip.",
      },
      { type: "txt", value: "Who gives him a menu ?" },
    ],
    options: [
      { type: "txt", value: "Waitress" },
      { type: "txt", value: "Customer" },
      { type: "txt", value: "Chicken" },
      { type: "txt", value: "Parent" },
    ],
    answer: 0,
  },
  {
    id: 23,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question.    She is a waitress. She works at a restaurant . A customer walks in . She walks him to a table . She gives him a menu . She puts a plate, fork, and spoon in front of him . He orders fried chicken . She smiles . She is very quick . She gives him his meal . He finishes eating . He gives her a big tip.",
      },
      { type: "txt", value: "Where does waitress put a plate?" },
    ],
    options: [
      { type: "txt", value: "In front of her" },
      { type: "txt", value: "Behind him" },
      { type: "txt", value: "Next to him" },
      { type: "txt", value: "In front of him" },
    ],
    answer: 3,
  },
  {
    id: 24,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question.    She is a waitress. She works at a restaurant . A customer walks in . She walks him to a table . She gives him a menu . She puts a plate, fork, and spoon in front of him . He orders fried chicken . She smiles . She is very quick . She gives him his meal . He finishes eating . He gives her a big tip.",
      },
      { type: "txt", value: "What does a customer order ?" },
    ],
    options: [
      { type: "txt", value: "Fried fish" },
      { type: "txt", value: "Fried chicken" },
      { type: "txt", value: "Fried bread" },
      { type: "txt", value: "Fried meal" },
    ],
    answer: 1,
  },
  {
    id: 25,
    question: [
      {
        type: "txt",
        value:
          "Read  the text and answer the question.    Clowns are cool . They look funny . They wear face paint . They wear big shoes . They wear colorful wings . They do funny things . They juggle balls. They make balloon animals . They do magic tricks . They tell jokes . Clowns work at many places . Sometimes, they perform at parties. Sometimes, they perform at circuses. They make people laugh everywhere. ",
      },
      { type: "txt", value: "What are cool?" },
    ],
    options: [
      { type: "txt", value: "Jokers" },
      { type: "txt", value: "Color" },
      { type: "txt", value: "Clowns" },
      { type: "txt", value: "People" },
    ],
    answer: 2,
  },
  {
    id: 26,
    question: [
      {
        type: "txt",
        value:
          "Read  the text and answer the question.    Clowns are cool . They look funny . They wear face paint . They wear big shoes . They wear colorful wings . They do funny things . They juggle balls. They make balloon animals . They do magic tricks . They tell jokes . Clowns work at many places . Sometimes, they perform at parties. Sometimes, they perform at circuses. They make people laugh everywhere. ",
      },
      { type: "txt", value: "What do clowns wear?" },
    ],
    options: [
      { type: "txt", value: "Face paint" },
      { type: "txt", value: "Funny things" },
      { type: "txt", value: "Place" },
      { type: "txt", value: "Perform" },
    ],
    answer: 0,
  },
  {
    id: 27,
    question: [
      {
        type: "txt",
        value:
          "Read  the text and answer the question.    Clowns are cool . They look funny . They wear face paint . They wear big shoes . They wear colorful wings . They do funny things . They juggle balls. They make balloon animals . They do magic tricks . They tell jokes . Clowns work at many places . Sometimes, they perform at parties. Sometimes, they perform at circuses. They make people laugh everywhere. ",
      },
      { type: "txt", value: "They juggle_____." },
    ],
    options: [
      { type: "txt", value: "Hand" },
      { type: "txt", value: "Leg" },
      { type: "txt", value: "Balls" },
      { type: "txt", value: "Head" },
    ],
    answer: 2,
  },
  {
    id: 28,
    question: [
      {
        type: "txt",
        value:
          "Read  the text and answer the question.    Clowns are cool . They look funny . They wear face paint . They wear big shoes . They wear colorful wings . They do funny things . They juggle balls. They make balloon animals . They do magic tricks . They tell jokes . Clowns work at many places . Sometimes, they perform at parties. Sometimes, they perform at circuses. They make people laugh everywhere. ",
      },
      { type: "txt", value: "What do clowns do?" },
    ],
    options: [
      { type: "txt", value: "Funny things" },
      { type: "txt", value: "Tell jokes" },
      { type: "txt", value: "Magic trick" },
      { type: "txt", value: "All above" },
    ],
    answer: 3,
  },
  {
    id: 29,
    question: [
      {
        type: "txt",
        value:
          "Read  the text and answer the question.    Clowns are cool . They look funny . They wear face paint . They wear big shoes . They wear colorful wings . They do funny things . They juggle balls. They make balloon animals . They do magic tricks . They tell jokes . Clowns work at many places . Sometimes, they perform at parties. Sometimes, they perform at circuses. They make people laugh everywhere. ",
      },
      { type: "txt", value: "Clown makes many people ______." },
    ],
    options: [
      { type: "txt", value: "Sad" },
      { type: "txt", value: "Good" },
      { type: "txt", value: "Laugh" },
      { type: "txt", value: "Perform" },
    ],
    answer: 2,
  },
];

export const specializedSchoolEnglish_5class = [
  {
    id: 0,
    question: [{ type: "txt", value: "_____ dentist ." }],
    options: [
      { type: "txt", value: "An" },
      { type: "txt", value: "The" },
      { type: "txt", value: "A" },
      { type: "txt", value: "Is" },
    ],
    answer: 2,
  },
  {
    id: 1,
    question: [{ type: "txt", value: "The dog is ______ garden. " }],
    options: [
      { type: "txt", value: "On" },
      { type: "txt", value: "And" },
      { type: "txt", value: "In" },
      { type: "txt", value: "A" },
    ],
    answer: 2,
  },
  {
    id: 2,
    question: [{ type: "txt", value: "I have not ____ a book . " }],
    options: [
      { type: "txt", value: "And" },
      { type: "txt", value: "Read" },
      { type: "txt", value: "Got" },
      { type: "txt", value: "Nothing" },
    ],
    answer: 1,
  },
  {
    id: 3,
    question: [
      {
        type: "txt",
        value: "When babies try to walk , they often fall ____. ",
      },
    ],
    options: [
      { type: "txt", value: "Away" },
      { type: "txt", value: "Over" },
      { type: "txt", value: "On" },
      { type: "txt", value: "Down" },
    ],
    answer: 3,
  },
  {
    id: 4,
    question: [
      { type: "txt", value: "What’s the meaning of this notice ? " },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/english/img5.png",
      },
    ],
    options: [
      { type: "txt", value: "You must not go in this way " },
      { type: "txt", value: "You must not drive carefully " },
      { type: "txt", value: "You can eat cheaply" },
    ],
    answer: 0,
  },
  {
    id: 5,
    question: [
      { type: "txt", value: "What’s the meaning of this notice ? " },
      {
        type: "img",
        value:
          "/quiz-images-2/ixtisoslashtirilgan-maktablar/ixtisoslashtirilgan-maktablar/english/img6.png",
      },
    ],
    options: [
      { type: "txt", value: "You pay the same price but you get more " },
      { type: "txt", value: "You can get a car any time " },
      { type: "txt", value: "There are two prices for ticket " },
    ],
    answer: 1,
  },
  {
    id: 6,
    question: [
      { type: "txt", value: "“ Where ____ now?”       “ In her office”" },
    ],
    options: [
      { type: "txt", value: "Is he" },
      { type: "txt", value: "Is it" },
      { type: "txt", value: "Is she" },
      { type: "txt", value: "Is" },
    ],
    answer: 2,
  },
  {
    id: 7,
    question: [
      { type: "txt", value: "The airline ____ so nice to me last week . " },
    ],
    options: [
      { type: "txt", value: "Is" },
      { type: "txt", value: "Was" },
      { type: "txt", value: "Were" },
      { type: "txt", value: "Are" },
    ],
    answer: 1,
  },
  {
    id: 8,
    question: [
      { type: "txt", value: "You and I _____ doctor in the future ." },
    ],
    options: [
      { type: "txt", value: "Shall be" },
      { type: "txt", value: "Are" },
      { type: "txt", value: "Was" },
      { type: "txt", value: "Will be" },
    ],
    answer: 0,
  },
  {
    id: 9,
    question: [
      {
        type: "txt",
        value:
          "I ____ a problem with my education . Don’t worry , No pain , No gain . You can do it .",
      },
    ],
    options: [
      { type: "txt", value: "Have" },
      { type: "txt", value: "Had" },
      { type: "txt", value: "Has" },
      { type: "txt", value: "Not" },
    ],
    answer: 0,
  },
  {
    id: 10,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question.  Since 2000 , the amount of time that we spend meeting has increased by an estimated 10% per year . An awerage meeting last 30 to 60 minutes and we attend up to 10 meetings a week.",
      },
      {
        type: "txt",
        value: "The amount of time we spend in meetings is _____? ",
      },
    ],
    options: [
      { type: "txt", value: "Decreasing" },
      { type: "txt", value: "Increasing year on year" },
      { type: "txt", value: "About 10%  of our week" },
      { type: "txt", value: "Always productive" },
    ],
    answer: 1,
  },
  {
    id: 11,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question.  Since 2000 , the amount of time that we spend meeting has increased by an estimated 10% per year . An awerage meeting last 30 to 60 minutes and we attend up to 10 meetings a week.",
      },
      { type: "txt", value: "How long is an average meeting ?" },
    ],
    options: [
      { type: "txt", value: "10 minutes " },
      { type: "txt", value: "2 hours" },
      { type: "txt", value: "30-36 minutes" },
      { type: "txt", value: "A day" },
    ],
    answer: 2,
  },
  {
    id: 12,
    question: [
      { type: "txt", value: "You can ____ on the phone in the library . " },
    ],
    options: [
      { type: "txt", value: "Not talk" },
      { type: "txt", value: "Not be calm" },
      { type: "txt", value: "Use" },
      { type: "txt", value: "Talk" },
    ],
    answer: 0,
  },
  {
    id: 13,
    question: [
      { type: "txt", value: "I ____ here two years ago . I don’t know yet . " },
    ],
    options: [
      { type: "txt", value: "Am living" },
      { type: "txt", value: "Lived" },
      { type: "txt", value: "Have been living" },
      { type: "txt", value: "Live" },
    ],
    answer: 1,
  },
  {
    id: 14,
    question: [
      { type: "txt", value: "Every other day they ____ their new car ." },
    ],
    options: [
      { type: "txt", value: "Will buy" },
      { type: "txt", value: "Buys" },
      { type: "txt", value: "Bought" },
      { type: "txt", value: "Buy" },
    ],
    answer: 3,
  },
  {
    id: 15,
    question: [
      {
        type: "txt",
        value: "I used to ___ water a lot . But now I don’t do it . ",
      },
    ],
    options: [
      { type: "txt", value: "Drunk" },
      { type: "txt", value: "Drank" },
      { type: "txt", value: "Drink" },
      { type: "txt", value: "Drinked" },
    ],
    answer: 2,
  },
  {
    id: 16,
    question: [
      { type: "txt", value: "He ____ to another place some days ago . " },
    ],
    options: [
      { type: "txt", value: "Will be sent" },
      { type: "txt", value: "Is sent" },
      { type: "txt", value: "Has sent" },
      { type: "txt", value: "Was sent" },
    ],
    answer: 3,
  },
  {
    id: 17,
    question: [{ type: "txt", value: "At last or at the end" }],
    options: [
      { type: "txt", value: "Finally" },
      { type: "txt", value: "Clever" },
      { type: "txt", value: "Reply" },
      { type: "txt", value: "Angry" },
    ],
    answer: 0,
  },
  {
    id: 18,
    question: [{ type: "txt", value: "The lowest part" }],
    options: [
      { type: "txt", value: "Bottom" },
      { type: "txt", value: "Lot" },
      { type: "txt", value: "Moment" },
      { type: "txt", value: "Promise" },
    ],
    answer: 0,
  },
  {
    id: 19,
    question: [{ type: "txt", value: "He has ____ books" }],
    options: [
      { type: "txt", value: "A" },
      { type: "txt", value: "Two" },
      { type: "txt", value: "An" },
      { type: "txt", value: "Yet" },
    ],
    answer: 1,
  },
  {
    id: 20,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question. Laura and Donald play tennis together . They go to the tennis court. Laura wants to practice her serves . She stands behind the line . She throws the ball up . She hits it with the racket . It goes over the net . Donald sees the ball . He hits it with the racket . It goes to Laura’s side . The Ball keeps going back and forth.",
      },
      { type: "txt", value: "Laura and ____ play tennis together." },
    ],
    options: [
      { type: "txt", value: "John" },
      { type: "txt", value: "Jack" },
      { type: "txt", value: "Donald" },
      { type: "txt", value: "Laura’s parent" },
    ],
    answer: 2,
  },
  {
    id: 21,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question. Laura and Donald play tennis together . They go to the tennis court. Laura wants to practice her serves . She stands behind the line . She throws the ball up . She hits it with the racket . It goes over the net . Donald sees the ball . He hits it with the racket . It goes to Laura’s side . The Ball keeps going back and forth.",
      },
      { type: "txt", value: "Where do they go to practice? " },
    ],
    options: [
      { type: "txt", value: "The ball" },
      { type: "txt", value: "Racket" },
      { type: "txt", value: "Court" },
      { type: "txt", value: "Serves" },
    ],
    answer: 2,
  },
  {
    id: 22,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question. Laura and Donald play tennis together . They go to the tennis court. Laura wants to practice her serves . She stands behind the line . She throws the ball up . She hits it with the racket . It goes over the net . Donald sees the ball . He hits it with the racket . It goes to Laura’s side . The Ball keeps going back and forth.",
      },
      { type: "txt", value: "What does Laura do the ball?" },
    ],
    options: [
      { type: "txt", value: "Play" },
      { type: "txt", value: "Throw" },
      { type: "txt", value: "Keep" },
      { type: "txt", value: "Like" },
    ],
    answer: 1,
  },
  {
    id: 23,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question. Laura and Donald play tennis together . They go to the tennis court. Laura wants to practice her serves . She stands behind the line . She throws the ball up . She hits it with the racket . It goes over the net . Donald sees the ball . He hits it with the racket . It goes to Laura’s side . The Ball keeps going back and forth.",
      },
      { type: "txt", value: "Donald hits the ball with the _____ ." },
    ],
    options: [
      { type: "txt", value: "The ball" },
      { type: "txt", value: "Racket" },
      { type: "txt", value: "Court" },
      { type: "txt", value: "Serves" },
    ],
    answer: 1,
  },
  {
    id: 24,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question. Laura and Donald play tennis together . They go to the tennis court. Laura wants to practice her serves . She stands behind the line . She throws the ball up . She hits it with the racket . It goes over the net . Donald sees the ball . He hits it with the racket . It goes to Laura’s side . The Ball keeps going back and forth.",
      },
      { type: "txt", value: "The_____ keeps going  back and forth." },
    ],
    options: [
      { type: "txt", value: "Ball" },
      { type: "txt", value: "Racket" },
      { type: "txt", value: "Court" },
      { type: "txt", value: "Serves" },
    ],
    answer: 0,
  },
  {
    id: 25,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question.   Summer ends. Autumn begins . Autumn is perfect . It is not too hot like summer. It is not too cold like winter . Mubina wakes up . She looks out her window . The leaves are falling . Some leaves are orange . Some leaves are brown . She sees her dad raking the leaves . She goes outside . She helps her dad. ",
      },
      { type: "txt", value: "What is perfect? " },
    ],
    options: [
      { type: "txt", value: "Summer" },
      { type: "txt", value: "Autumn" },
      { type: "txt", value: "Winter" },
      { type: "txt", value: "Spring" },
    ],
    answer: 1,
  },
  {
    id: 26,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question. Laura and Donald play tennis together . They go to the tennis court. Laura wants to practice her serves . She stands behind the line . She throws the ball up . She hits it with the racket . It goes over the net . Donald sees the ball . He hits it with the racket . It goes to Laura’s side . The Ball keeps going back and forth.",
      },
      { type: "txt", value: "Fall is not very ____ as summer ?" },
    ],
    options: [
      { type: "txt", value: "Cold" },
      { type: "txt", value: "Cool" },
      { type: "txt", value: "Hot" },
      { type: "txt", value: "Fall" },
    ],
    answer: 2,
  },
  {
    id: 27,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question. Laura and Donald play tennis together . They go to the tennis court. Laura wants to practice her serves . She stands behind the line . She throws the ball up . She hits it with the racket . It goes over the net . Donald sees the ball . He hits it with the racket . It goes to Laura’s side . The Ball keeps going back and forth.",
      },
      {
        type: "txt",
        value: "What is happening when Mubina looks out her window ? ",
      },
    ],
    options: [
      { type: "txt", value: "It is snowing" },
      { type: "txt", value: "Leaves are falling" },
      { type: "txt", value: "Summer is coming" },
      { type: "txt", value: "Both a and c" },
    ],
    answer: 1,
  },
  {
    id: 28,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question. Laura and Donald play tennis together . They go to the tennis court. Laura wants to practice her serves . She stands behind the line . She throws the ball up . She hits it with the racket . It goes over the net . Donald sees the ball . He hits it with the racket . It goes to Laura’s side . The Ball keeps going back and forth.",
      },
      { type: "txt", value: "Some leaves are ____ ." },
    ],
    options: [
      { type: "txt", value: "Orange" },
      { type: "txt", value: "Brown" },
      { type: "txt", value: "Red" },
      { type: "txt", value: "Both a and b" },
    ],
    answer: 3,
  },
  {
    id: 29,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question. Laura and Donald play tennis together . They go to the tennis court. Laura wants to practice her serves . She stands behind the line . She throws the ball up . She hits it with the racket . It goes over the net . Donald sees the ball . He hits it with the racket . It goes to Laura’s side . The Ball keeps going back and forth.",
      },
      { type: "txt", value: "What does “ leaf “  mean ?" },
    ],
    options: [
      { type: "txt", value: "Member of tree" },
      { type: "txt", value: "Member of water" },
      { type: "txt", value: "Member of sand" },
      { type: "txt", value: "Member of air" },
    ],
    answer: 0,
  },
];

export const specializedSchoolEnglish_6class = [
  {
    id: 0,
    question: [{ type: "txt", value: "Where___ my mom?" }],
    options: [
      { type: "txt", value: "are" },
      { type: "txt", value: "is" },
      { type: "txt", value: "am" },
      { type: "txt", value: "*" },
    ],
    answer: 1,
  },
  {
    id: 1,
    question: [
      {
        type: "txt",
        value:
          "I ___ student at university . My brother and my sister ____ also student.",
      },
    ],
    options: [
      { type: "txt", value: "am/is" },
      { type: "txt", value: "is/are" },
      { type: "txt", value: "am/are" },
      { type: "txt", value: "is/am" },
    ],
    answer: 2,
  },
  {
    id: 2,
    question: [
      {
        type: "txt",
        value:
          'Choose the appropriate antonym to the word in quotation marks. I must change my jeans . There are really "dirty".',
      },
    ],
    options: [
      { type: "txt", value: "clean" },
      { type: "txt", value: "yellow" },
      { type: "txt", value: "hardly" },
      { type: "txt", value: "completely" },
    ],
    answer: 0,
  },
  {
    id: 3,
    question: [{ type: "txt", value: "You must___ more diary products." }],
    options: [
      { type: "txt", value: "ate" },
      { type: "txt", value: "to eat" },
      { type: "txt", value: "eating" },
      { type: "txt", value: "eat" },
    ],
    answer: 3,
  },
  {
    id: 4,
    question: [{ type: "txt", value: "____ a fluent speaker of English ?" }],
    options: [
      { type: "txt", value: "is her" },
      { type: "txt", value: "he" },
      { type: "txt", value: "does she" },
      { type: "txt", value: "is she" },
    ],
    answer: 3,
  },
  {
    id: 5,
    question: [
      { type: "txt", value: "Peter ___ in the living room when phone rang." },
    ],
    options: [
      { type: "txt", value: "was sitting" },
      { type: "txt", value: "has been sitting" },
      { type: "txt", value: "at" },
      { type: "txt", value: "has he wanted" },
    ],
    answer: 0,
  },
  {
    id: 6,
    question: [
      { type: "txt", value: "The teacher seems ____ small for all people." },
    ],
    options: [
      { type: "txt", value: "to" },
      { type: "txt", value: "to be too" },
      { type: "txt", value: "its too" },
      { type: "txt", value: "too much" },
    ],
    answer: 3,
  },
  {
    id: 7,
    question: [
      {
        type: "txt",
        value:
          "Peter ____ return the book to the library . We all want to read.",
      },
    ],
    options: [
      { type: "txt", value: "need" },
      { type: "txt", value: "must" },
      { type: "txt", value: "can" },
      { type: "txt", value: "may" },
    ],
    answer: 0,
  },
  {
    id: 8,
    question: [{ type: "txt", value: "I’m not sure ___ green coat is this." }],
    options: [
      { type: "txt", value: "whom" },
      { type: "txt", value: "whose" },
      { type: "txt", value: "who" },
      { type: "txt", value: "who’s" },
    ],
    answer: 1,
  },
  {
    id: 9,
    question: [{ type: "txt", value: "Kamola has bought ___ new shoes" }],
    options: [
      { type: "txt", value: "any" },
      { type: "txt", value: "some" },
      { type: "txt", value: "a" },
      { type: "txt", value: "no" },
    ],
    answer: 1,
  },
  {
    id: 10,
    question: [
      {
        type: "txt",
        value:
          "Decide which of the sentences means the same given sentences .   I won’t go unless Mary goes.",
      },
    ],
    options: [
      { type: "txt", value: "I will go if Mary doesn’t go " },
      { type: "txt", value: "I won’t go if Mary goes" },
      { type: "txt", value: "I won’t go if Mary doesn’t go" },
      { type: "txt", value: "I won’t go when Mary goes" },
    ],
    answer: 3,
  },
  {
    id: 11,
    question: [
      {
        type: "txt",
        value:
          "Decide which of the sentences means the same given sentences .        This book is very difficult . I don’t understand it .",
      },
    ],
    options: [
      { type: "txt", value: "It is in another language" },
      { type: "txt", value: "There are many hard words to understand" },
      { type: "txt", value: "it is not interesting" },
      { type: "txt", value: "it is boring" },
    ],
    answer: 1,
  },
  {
    id: 12,
    question: [
      {
        type: "txt",
        value: "I _____ get into my house last night because I’d lost my key.",
      },
    ],
    options: [
      { type: "txt", value: "can" },
      { type: "txt", value: "could" },
      { type: "txt", value: "managed to" },
      { type: "txt", value: "couldn’t" },
    ],
    answer: 3,
  },
  {
    id: 13,
    question: [
      { type: "txt", value: "Our kitchen ___ decorated at the moment." },
    ],
    options: [
      { type: "txt", value: "is" },
      { type: "txt", value: "is being" },
      { type: "txt", value: "being" },
      { type: "txt", value: "was being" },
    ],
    answer: 1,
  },
  {
    id: 14,
    question: [
      {
        type: "txt",
        value: "I live in a house now , but before I ___ in a flat.",
      },
    ],
    options: [
      { type: "txt", value: "am using to live" },
      { type: "txt", value: "used to live" },
      { type: "txt", value: "had used to live" },
      { type: "txt", value: "have used to live" },
    ],
    answer: 1,
  },
  {
    id: 15,
    question: [{ type: "txt", value: "I have got a ____ in my back ." }],
    options: [
      { type: "txt", value: "pain" },
      { type: "txt", value: "ache" },
      { type: "txt", value: "sore" },
      { type: "txt", value: "broken" },
    ],
    answer: 0,
  },
  {
    id: 16,
    question: [{ type: "txt", value: "A jar of ____" }],
    options: [
      { type: "txt", value: "honey" },
      { type: "txt", value: "coffee" },
      { type: "txt", value: "chocolate" },
      { type: "txt", value: "rise" },
    ],
    answer: 0,
  },
  {
    id: 17,
    question: [
      { type: "txt", value: "John was singing while he ____ a bath." },
    ],
    options: [
      { type: "txt", value: "is taking" },
      { type: "txt", value: "was taking" },
      { type: "txt", value: "takes" },
      { type: "txt", value: "take" },
    ],
    answer: 1,
  },
  {
    id: 18,
    question: [{ type: "txt", value: "____ she got a watch ?" }],
    options: [
      { type: "txt", value: "does" },
      { type: "txt", value: "do" },
      { type: "txt", value: "has" },
      { type: "txt", value: "have" },
    ],
    answer: 2,
  },
  {
    id: 19,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question.   Ann Scot  is fourteen , and she has changed her school because her parents moved to another town this summer .  “ Let me tell you about my new school . I’m having a great time . All pupils are very friendly , but it’s very different from my old school. I can tell you ! First of all school starts at 8:00 here , a whole hour before it did in that school . I hardly wake up in time every morning. This morning I forgot about it and go to school ten minutes late , so the teacher put a note into my record-book . I was really angry! There are a lot of other differences in this school of course. We have seen lessons here almost every day. What else? The school day is different , too. In my former school we finished school at 3:00, and then most pupils went home. Here we finish lessons at 3:30, and then most pupils go to sports practice. Well, they love sports here . My mother said I also should join one of them . I joined gymnastics and football clubs here. A lot of girls play football in this school . I had my first practice yesterday, and I feel tired today. I have several hobbies but sport is not my favorite one. Another important thing here is learning to do practical things. Their metal and wood shops are like small factories, and we are taught to drive as well. It’s fantastic. I had my first lesson in driving a tractor yesterday.” ",
      },
      { type: "txt", value: "Choose the right synonym to “ important “ ." },
    ],
    options: [
      { type: "txt", value: "necessary" },
      { type: "txt", value: "hard" },
      { type: "txt", value: "close" },
      { type: "txt", value: "weak" },
    ],
    answer: 0,
  },
  {
    id: 20,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question.   Ann Scot  is fourteen , and she has changed her school because her parents moved to another town this summer .  “ Let me tell you about my new school . I’m having a great time . All pupils are very friendly , but it’s very different from my old school. I can tell you ! First of all school starts at 8:00 here , a whole hour before it did in that school . I hardly wake up in time every morning. This morning I forgot about it and go to school ten minutes late , so the teacher put a note into my record-book . I was really angry! There are a lot of other differences in this school of course. We have seen lessons here almost every day. What else? The school day is different , too. In my former school we finished school at 3:00, and then most pupils went home. Here we finish lessons at 3:30, and then most pupils go to sports practice. Well, they love sports here . My mother said I also should join one of them . I joined gymnastics and football clubs here. A lot of girls play football in this school . I had my first practice yesterday, and I feel tired today. I have several hobbies but sport is not my favorite one. Another important thing here is learning to do practical things. Their metal and wood shops are like small factories, and we are taught to drive as well. It’s fantastic. I had my first lesson in driving a tractor yesterday.” ",
      },
      { type: "txt", value: "Choose the right antonym to “ friendly”" },
    ],
    options: [
      { type: "txt", value: "helpful" },
      { type: "txt", value: "unkind" },
      { type: "txt", value: "chatty" },
      { type: "txt", value: "funny" },
    ],
    answer: 1,
  },
  {
    id: 21,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question.   Ann Scot  is fourteen , and she has changed her school because her parents moved to another town this summer .  “ Let me tell you about my new school . I’m having a great time . All pupils are very friendly , but it’s very different from my old school. I can tell you ! First of all school starts at 8:00 here , a whole hour before it did in that school . I hardly wake up in time every morning. This morning I forgot about it and go to school ten minutes late , so the teacher put a note into my record-book . I was really angry! There are a lot of other differences in this school of course. We have seen lessons here almost every day. What else? The school day is different , too. In my former school we finished school at 3:00, and then most pupils went home. Here we finish lessons at 3:30, and then most pupils go to sports practice. Well, they love sports here . My mother said I also should join one of them . I joined gymnastics and football clubs here. A lot of girls play football in this school . I had my first practice yesterday, and I feel tired today. I have several hobbies but sport is not my favorite one. Another important thing here is learning to do practical things. Their metal and wood shops are like small factories, and we are taught to drive as well. It’s fantastic. I had my first lesson in driving a tractor yesterday.” ",
      },
      {
        type: "txt",
        value:
          "Find the alternative meaning .     I hardly wake up in time every morning .",
      },
    ],
    options: [
      { type: "txt", value: "it’s easy to wake up in time every morning" },
      { type: "txt", value: "he often wakes up early" },
      { type: "txt", value: "he likes waking up early in the morning" },
      { type: "txt", value: "It’s hard to wake up early in the morning" },
    ],
    answer: 3,
  },
  {
    id: 22,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question.   Ann Scot  is fourteen , and she has changed her school because her parents moved to another town this summer .  “ Let me tell you about my new school . I’m having a great time . All pupils are very friendly , but it’s very different from my old school. I can tell you ! First of all school starts at 8:00 here , a whole hour before it did in that school . I hardly wake up in time every morning. This morning I forgot about it and go to school ten minutes late , so the teacher put a note into my record-book . I was really angry! There are a lot of other differences in this school of course. We have seen lessons here almost every day. What else? The school day is different , too. In my former school we finished school at 3:00, and then most pupils went home. Here we finish lessons at 3:30, and then most pupils go to sports practice. Well, they love sports here . My mother said I also should join one of them . I joined gymnastics and football clubs here. A lot of girls play football in this school . I had my first practice yesterday, and I feel tired today. I have several hobbies but sport is not my favorite one. Another important thing here is learning to do practical things. Their metal and wood shops are like small factories, and we are taught to drive as well. It’s fantastic. I had my first lesson in driving a tractor yesterday.” ",
      },
      {
        type: "txt",
        value:
          "Choose the right answer .   The statement : “ Another important thing here is LEARNING to do practical things. The word is capital letters is ______",
      },
    ],
    options: [
      { type: "txt", value: "The past continuous" },
      { type: "txt", value: "the present continuous" },
      { type: "txt", value: "The gerund" },
      { type: "txt", value: "the present simple" },
    ],
    answer: 1,
  },
  {
    id: 23,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question.   Ann Scot  is fourteen , and she has changed her school because her parents moved to another town this summer .  “ Let me tell you about my new school . I’m having a great time . All pupils are very friendly , but it’s very different from my old school. I can tell you ! First of all school starts at 8:00 here , a whole hour before it did in that school . I hardly wake up in time every morning. This morning I forgot about it and go to school ten minutes late , so the teacher put a note into my record-book . I was really angry! There are a lot of other differences in this school of course. We have seen lessons here almost every day. What else? The school day is different , too. In my former school we finished school at 3:00, and then most pupils went home. Here we finish lessons at 3:30, and then most pupils go to sports practice. Well, they love sports here . My mother said I also should join one of them . I joined gymnastics and football clubs here. A lot of girls play football in this school . I had my first practice yesterday, and I feel tired today. I have several hobbies but sport is not my favorite one. Another important thing here is learning to do practical things. Their metal and wood shops are like small factories, and we are taught to drive as well. It’s fantastic. I had my first lesson in driving a tractor yesterday.” ",
      },
      {
        type: "txt",
        value: "Choose the correct preposition .   I get up late ___ Sundays",
      },
    ],
    options: [
      { type: "txt", value: "on" },
      { type: "txt", value: "in" },
      { type: "txt", value: "from" },
      { type: "txt", value: "at" },
    ],
    answer: 0,
  },
  {
    id: 24,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question.   Ann Scot  is fourteen , and she has changed her school because her parents moved to another town this summer .  “ Let me tell you about my new school . I’m having a great time . All pupils are very friendly , but it’s very different from my old school. I can tell you ! First of all school starts at 8:00 here , a whole hour before it did in that school . I hardly wake up in time every morning. This morning I forgot about it and go to school ten minutes late , so the teacher put a note into my record-book . I was really angry! There are a lot of other differences in this school of course. We have seen lessons here almost every day. What else? The school day is different , too. In my former school we finished school at 3:00, and then most pupils went home. Here we finish lessons at 3:30, and then most pupils go to sports practice. Well, they love sports here . My mother said I also should join one of them . I joined gymnastics and football clubs here. A lot of girls play football in this school . I had my first practice yesterday, and I feel tired today. I have several hobbies but sport is not my favorite one. Another important thing here is learning to do practical things. Their metal and wood shops are like small factories, and we are taught to drive as well. It’s fantastic. I had my first lesson in driving a tractor yesterday.” ",
      },
      { type: "txt", value: "Choose the sentence with the Modal verb." },
    ],
    options: [
      { type: "txt", value: "My mother said I also should join one of them" },
      { type: "txt", value: "the school day is different, too" },
      { type: "txt", value: "well, they love sports here" },
      { type: "txt", value: "I’m having a great time" },
    ],
    answer: 0,
  },
  {
    id: 25,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question.   Ann Scot  is fourteen , and she has changed her school because her parents moved to another town this summer .  “ Let me tell you about my new school . I’m having a great time . All pupils are very friendly , but it’s very different from my old school. I can tell you ! First of all school starts at 8:00 here , a whole hour before it did in that school . I hardly wake up in time every morning. This morning I forgot about it and go to school ten minutes late , so the teacher put a note into my record-book . I was really angry! There are a lot of other differences in this school of course. We have seen lessons here almost every day. What else? The school day is different , too. In my former school we finished school at 3:00, and then most pupils went home. Here we finish lessons at 3:30, and then most pupils go to sports practice. Well, they love sports here . My mother said I also should join one of them . I joined gymnastics and football clubs here. A lot of girls play football in this school . I had my first practice yesterday, and I feel tired today. I have several hobbies but sport is not my favorite one. Another important thing here is learning to do practical things. Their metal and wood shops are like small factories, and we are taught to drive as well. It’s fantastic. I had my first lesson in driving a tractor yesterday.” ",
      },
      { type: "txt", value: "Choose the only irregular verbs answer." },
    ],
    options: [
      { type: "txt", value: "join, play, have" },
      { type: "txt", value: "finish, love, wake" },
      { type: "txt", value: "say, drive, move" },
      { type: "txt", value: "wake , have, teach" },
    ],
    answer: 3,
  },
  {
    id: 26,
    question: [
      {
        type: "txt",
        value:
          "Read the text and answer the question.   Ann Scot  is fourteen , and she has changed her school because her parents moved to another town this summer .  “ Let me tell you about my new school . I’m having a great time . All pupils are very friendly , but it’s very different from my old school. I can tell you ! First of all school starts at 8:00 here , a whole hour before it did in that school . I hardly wake up in time every morning. This morning I forgot about it and go to school ten minutes late , so the teacher put a note into my record-book . I was really angry! There are a lot of other differences in this school of course. We have seen lessons here almost every day. What else? The school day is different , too. In my former school we finished school at 3:00, and then most pupils went home. Here we finish lessons at 3:30, and then most pupils go to sports practice. Well, they love sports here . My mother said I also should join one of them . I joined gymnastics and football clubs here. A lot of girls play football in this school . I had my first practice yesterday, and I feel tired today. I have several hobbies but sport is not my favorite one. Another important thing here is learning to do practical things. Their metal and wood shops are like small factories, and we are taught to drive as well. It’s fantastic. I had my first lesson in driving a tractor yesterday.” ",
      },
      {
        type: "txt",
        value:
          "Choose the alternative word to change the word in capital letters in the sentence.   I have SEVERAL hobbies.",
      },
    ],
    options: [
      { type: "txt", value: "any" },
      { type: "txt", value: "none" },
      { type: "txt", value: "no" },
      { type: "txt", value: "some" },
    ],
    answer: 3,
  },
  {
    id: 27,
    question: [
      {
        type: "txt",
        value: "Choose the correct answer.   My favorite subject is _____.",
      },
    ],
    options: [
      { type: "txt", value: "England" },
      { type: "txt", value: "Math" },
      { type: "txt", value: "teacher" },
      { type: "txt", value: "listening" },
    ],
    answer: 1,
  },
  {
    id: 28,
    question: [{ type: "txt", value: "He can ____." }],
    options: [
      { type: "txt", value: "sleep" },
      { type: "txt", value: "water" },
      { type: "txt", value: "sky" },
      { type: "txt", value: "morning" },
    ],
    answer: 0,
  },
  {
    id: 29,
    question: [{ type: "txt", value: "Who is the youngest in your family?" }],
    options: [
      { type: "txt", value: "My granny" },
      { type: "txt", value: "My mother" },
      { type: "txt", value: "My little sister" },
      { type: "txt", value: "My dad" },
    ],
    answer: 2,
  },
];

export const data = {
  president: {
    problem_solving: {
      uz: {
        3: prezidentSchoolProblemSolving,
        4: prezidentSchoolProblemSolving,
      },
      ru: {
        3: prezidentSchoolProblemSolving,
        4: prezidentSchoolProblemSolving,
      },
    },
    critical_thinking: {
      uz: {
        3: prezidentSchoolCriticalThinking,
        4: prezidentSchoolCriticalThinking,
      },
      ru: {
        3: prezidentSchoolCriticalThinking,
        4: prezidentSchoolCriticalThinking,
      },
    },
    mathematics: {
      uz: {
        3: saralash,
        4: saralash,
      },
      ru: {
        3: saralash,
        4: saralash,
      },
    },
    english: prezidentSchoolEnglish,
  },
  al_khorazmi: {
    mathematics: {
      uz: {
        3: alXorazmiyMath,
        4: alXorazmiyMath,
      },
      ru: {
        3: alXorazmiyMath,
        4: alXorazmiyMath,
      },
    },
    english: alXorazmiyEnglish,
  },
  mirzo_ulugbek: {
    mathematics: {
      uz: {
        5: mirzoUlugbekMathUz,
        6: mirzoUlugbekMathUz,
      },
      ru: {
        5: mirzoUlugbekMathRu,
        6: mirzoUlugbekMathRu,
      },
    },
    english: mirzoUlugbekEnglish,
  },
  specialized: {
    mathematics: {
      uz: {
        3: specializedSchoolMath_3class,
        4: specializedSchoolMath_4class,
        5: specializedSchoolMath_5class,
        6: specializedSchoolMath_6class,
        7: specializedSchoolMath_7class,
      },
      ru: {
        3: specializedSchoolMath_3class,
        4: specializedSchoolMath_4class,
        5: specializedSchoolMath_5class,
        6: specializedSchoolMath_6class,
        7: specializedSchoolMath_7class,
      },
    },
    english: {
      3: specializedSchoolEnglish_3class,
      4: specializedSchoolEnglish_4class,
      5: specializedSchoolEnglish_5class,
      6: specializedSchoolEnglish_6class,
      7: specializedSchoolEnglish_6class,
    },
  },
};
