export function millisecondsToTime() {
  const initialTime = localStorage.getItem("initialTime");
  const endTime = localStorage.getItem("endTime");
  let milliseconds = endTime - initialTime;

  // Calculate the hours, minutes, seconds, and milliseconds
  const hours = Math.floor(milliseconds / 3600000); // 1 hour = 3600000 milliseconds
  milliseconds %= 3600000;

  const minutes = Math.floor(milliseconds / 60000); // 1 minute = 60000 milliseconds
  milliseconds %= 60000;

  const seconds = Math.floor(milliseconds / 1000); // 1 second = 1000 milliseconds
  milliseconds %= 1000;

  return {
    hours,
    minutes,
    seconds,
    milliseconds,
  };
}
