import React from "react";
import { useTranslation } from "react-i18next";
import LanguageSelect from "../ui/select/language-select";

function SelectLanguage() {
  const { t, i18n } = useTranslation();

  const handleChange = (lang) => {
    i18n.changeLanguage(lang).then((res) => {
      localStorage.setItem("i18nextLng", lang);
    });
  };

  return (
    <div className="mx-2">
      <LanguageSelect onChange={handleChange} value={i18n.language} />
    </div>
  );
}

export default SelectLanguage;
