function ButtonSelect({ title, children }) {
  return (
    <div className="button-select">
      <h4 className="button-select__title">{title}</h4>

      <div className="button-select__options mx-auto">
        <ul className="row g-1 gy-3 g-sm-3 mx-auto justify-content-center">
          {children}
        </ul>
      </div>
    </div>
  );
}

ButtonSelect.BigOption = function ({ img, label, value, onClick }) {
  return (
    <li
      role="option"
      className="button-select__big-option"
      onClick={() => onClick(value)}
    >
      <span className="button-select__big-option-text">{label}</span>
      {img && (
        <div className="button-select__big-option-img">
          <img src={img} alt={value} />
        </div>
      )}
    </li>
  );
};

ButtonSelect.SmallOption = function ({ label, value, onClick }) {
  return (
    <li
      role="option"
      className="button-select__small-option"
      onClick={() => onClick(value)}
    >
      <span className="button-select__small-option-text">{label}</span>
    </li>
  );
};

export default ButtonSelect;
