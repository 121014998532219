import React from "react";

const socialContent = [
  {
    icon: "facebook",
    link: "https://www.facebook.com/profile.php?id=61551144492480",
  },
  {
    icon: "telegram",
    link: "https://t.me/mathschoolsuz",
  },
  {
    icon: "instagram",
    link: "https://instagram.com/mathschools?igshid=OGQ5ZDc2ODk2ZA==",
  },
];

const ContactAddress = () => {
  return (
    <div className="row justify-content-center">
      <div className="col-lg-4 col-sm-6 d-lg-flex">
        <div className="address-info">
          <div className="icon d-flex align-items-end">
            <img src="images/icon/44.svg" alt="icon" />
          </div>
          <div className="title">Location</div>
          <p className="font-rubik">
            г. Чирчик, <br />
            массив Ак-кавак, <br /> макро супермаркет, 2 этаж.
          </p>
        </div>{" "}
        {/* /.address-info  */}
      </div>
      {/* End .col */}

      <div className="col-lg-4 col-sm-6 d-lg-flex">
        <div className="address-info">
          <div className="icon d-flex align-items-end">
            <img src="images/icon/45.svg" alt="icon" />
          </div>
          <div className="title">Contact</div>
          <p className="font-rubik">
            info@mathschool.uz <br />
            (+998) 91 115 5555
            <br />
            Телеграм: @mathschoolmanager
          </p>
        </div>{" "}
        {/* /.address-info  */}
      </div>
      {/* End .col */}

      <div className="col-lg-4 col-sm-6 d-lg-flex">
        <div className="address-info">
          <div className="icon d-flex align-items-end">
            <img src="images/icon/46.svg" alt="icon" />
          </div>
          <div className="title">Social Media</div>
          <p className="font-rubik">Find on social media</p>
          <ul className="d-flex justify-content-center">
            {socialContent.map((val, i) => (
              <li key={i}>
                <a href={val.link} target="_blank" rel="noreferrer">
                  <img src={`/images/icon/${val.icon}.png`} />
                </a>
              </li>
            ))}
          </ul>
        </div>
        {/* /.address-info  */}
      </div>
      {/* End .col */}
    </div>
  );
};

export default ContactAddress;
