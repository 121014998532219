import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const socialContent = [
  {
    icon: "facebook",
    link: "https://www.facebook.com/profile.php?id=61551144492480",
  },
  {
    icon: "telegram",
    link: "https://t.me/mathschoolsuz",
  },
  {
    icon: "instagram",
    link: "https://instagram.com/mathschools?igshid=OGQ5ZDc2ODk2ZA==",
  },
];

const FooterFive = () => {
  const { t } = useTranslation();
  return (
    <div className="row justify-content-center align-items-center">
      <div className="col-lg-4">
        <div className="logo">
          <Link to="/">
            <img src="/images/githubit/logo.png" alt="brand" width={220} />
          </Link>
        </div>
      </div>
      {/* End .col */}

      <div className="col-lg-4">
        <div className="title">{t("stay.in.touch")}</div>
        <ul className="d-flex justify-content-center" style={{ gap: 12 }}>
          {socialContent.map((val, i) => (
            <li key={i}>
              <a
                href={val.link}
                target="_blank"
                rel="noreferrer"
                className="d-flex justify-content-center align-items-center"
              >
                <img
                  src={`/images/icon/${val.icon}.png`}
                  width={24}
                  height={24}
                />
              </a>
            </li>
          ))}
        </ul>
      </div>
      {/* End .col */}

      <div className="col-lg-4">
        <div className="title">{t("we.are.happy.by.helping")}</div>
        <div className="text-center">
          <a href="tel:+998911155555" className="email">
            +99891 115 5555
          </a>
        </div>
      </div>
      {/* End .col */}
    </div>
  );
};

export default FooterFive;
