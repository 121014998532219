import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useContext } from "react";
import { MainContext } from "../../../../../context/MainContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { millisecondsToTime } from "../../../../../utils/parseToTime";

const UserForm = ({ answers, incorrect, correct, percent }) => {
  const navigate = useNavigate();
  const { userData } = useContext(MainContext);
  const sectionList = {
    general: "Umumiy matematika (Maktab dasturi)",
    specialized: "Ixtisoslashtirilgan maktablar",
    applicants: "Abiturient (DTM, xorijiy universitet va filliallari, milliy sertifikat)",
    olympics: "Olimpiadalar",
  };
  // for validation
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    phone: Yup.string().required("Phonenumber is required"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  // get functions to build form with useForm() hook
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  const getSection = () => {};
  const getData = () => {
    const array = [];
    let index = 0;
    for (const key in answers) {
      index++;
      if (Object.hasOwnProperty.call(answers, key)) {
        const element = answers[key];
        const isHas = element.myAnswer.includes(".png");
        let answer;
        if (isHas) answer = `https://mathschool.uz${element.myAnswer}`;
        else answer = element.myAnswer;

        array.push(`%0A${index})(test ${element.id}) Answer: ${answer}`);
      }
    }
    return array.toString().replaceAll(",", "");
  };

  function onSubmit(data, e) {
    // display form data on success
    const { minutes, seconds, milliseconds, hours } = millisecondsToTime();

    fetch("https://api.sundaymart.net/api/v1/rest/question", {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },

      body: JSON.stringify({
        text: `Имя: ${data.name}%0AНомер телефона: ${data.phone}%0AЯзык: ${userData.lang}%0APаздел: ${
          sectionList[userData.section]
        }%0AКласс: ${
          userData.class
        }%0AПравильных: ${correct}%0AНеправильных: ${incorrect}%0AКоэффициент: ${percent}%0AВремя: ${hours}:${minutes}:${seconds}%0AРезультат: ${getData()}`,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        toast.success("Наши менеджеры свяжутся с вами в ближайшее время.");
        navigate("/");
      });
    e.target.reset();
  }

  return (
    <form className="checkout-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="user-profile-data user-profile-custom">
        <div className="row">
          <div className="col-12">
            <input type="text" placeholder="Имя" className={`single-input-wrapper mb-0 mt-5`} {...register("name")} />
            {errors.name && <div className="invalid-feedback">{errors.name?.message}</div>}
          </div>
          <div className="col-12">
            <input
              type="number"
              placeholder="Номер телефона*"
              className="single-input-wrapper mb-0 mt-5"
              {...register("phone")}
            />
            {errors.phone && <div className="invalid-feedback">{errors.phone?.message}</div>}
          </div>
        </div>
      </div>
      <div className="col-12 d-flex justify-content-center mt-5">
        <button type="submit" className="theme-btn-two">
          Отправить
        </button>
      </div>
    </form>
  );
};

export default UserForm;
