import React from "react";
import { Helmet } from "react-helmet";
import HeaderLanding from "../../../../components/header/landing/HeaderLanding";
import TestForm from "./components/test-form";
import FooterFive from "../../../../components/footer/FooterFive";

const QuizTest = () => {
  return (
    <div className="main-page-wrapper font-gordita">
      <Helmet>
        <title> Mathschool - учебный центр в Чирчике</title>
      </Helmet>
      <HeaderLanding />
      <TestForm />

      {/* =====================================================
				Footer Style Five
			===================================================== */}
      <footer className="theme-footer-five mt-130 md-mt-100">
        <div className="inner-container">
          <div className="container">
            <FooterFive />
          </div>
        </div>
        {/* /.inner-container */}
        <p className="copyright">г. Чирчик, массив Ак-кавак, макро супермаркет, 2 этаж. </p>
      </footer>
      {/*  /.theme-footer-five */}
    </div>
  );
};

export default QuizTest;
