import React from "react";
import { Helmet } from "react-helmet";
import HeaderLanding from "../../../../components/header/landing/HeaderLanding";
import LanguageForm from "./components/language-form";
import FooterFive from "../../../../components/footer/FooterFive";
import ButtonSelect from "../../../../components/ui/select/button-select";

const { BigOption, SmallOption } = ButtonSelect;

const opt1 = {
  title: "Qaysi tilda test topshirasiz? / На каком языкехотите сдавать тест?",
  options: [
    { label: "O’zbek tilida", value: "uz" },
    { label: "На русском языке", value: "ru" },
  ],
};

const opt2 = {
  title: "Выберите раздел",
  options: [
    {
      label: "AL-Xorazmiy maktabi",
      value: "xorazmiy",
      img: "/images/assets/logo-alxorazmiy.png",
    },
    {
      label: "Prezident maktablari",
      value: "prezident",
      img: "/images/assets/logo-prezident-maktabi.png",
    },
    {
      label: "Mirzo-Ulug’bek maktablari",
      value: "prezident",
      img: "/images/assets/logo-mirzo-ulugbek.png",
    },
    {
      label: "Ixtisoslashtirilgan maktablar",
      value: "prezident",
      img: "/images/assets/logo-ixtisoslashtirilgan.png",
    },
  ],
};

const opt3 = {
  title: "Выберите раздел",
  options: [
    { label: "Школьная программа", value: "scholl" },
    { label: "Специализированные школы", value: "scholl" },
    { label: "Национальные и зарубежные сертификаты", value: "scholl" },
  ],
};

const QuizLanguage = () => {
  return (
    <div className="main-page-wrapper font-gordita">
      <Helmet>
        <title> Mathschool - учебный центр в Чирчике</title>
      </Helmet>
      <HeaderLanding />

      {/*<div className="container">*/}
      {/*  <ButtonSelect title={opt2.title}>*/}
      {/*    {opt2.options.map((item) => (*/}
      {/*      <BigOption {...item} key={item.value} />*/}
      {/*    ))}*/}
      {/*  </ButtonSelect>*/}
      {/*  <ButtonSelect title={opt2.title}>*/}
      {/*    {opt3.options.map((item) => (*/}
      {/*      <SmallOption {...item} key={item.value} />*/}
      {/*    ))}*/}
      {/*  </ButtonSelect>*/}
      {/*</div>*/}
      <div className="container pb-200 md-pt-90 md-pb-80 d-flex justify-content-center my-4 px-4">
        {/*<div className="form-style-light" style={{ maxWidth: 800 }}>*/}
        <LanguageForm />
        {/*</div>*/}
      </div>

      {/* =====================================================
				Footer Style Five
			===================================================== */}
      <footer className="theme-footer-five mt-130 md-mt-100">
        <div className="inner-container">
          <div className="container">
            <FooterFive />
          </div>
        </div>
        {/* /.inner-container */}
        <p className="copyright">
          г. Чирчик, массив Ак-кавак, макро супермаркет, 2 этаж.{" "}
        </p>
      </footer>
      {/*  /.theme-footer-five */}
    </div>
  );
};

export default QuizLanguage;
