import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../context/MainContext";

const formatDuration = (seconds) => {
  const min = Math.floor(seconds / 60);
  let sec = seconds - min * 60;
  if (sec < 10) {
    sec = `0${sec}`;
  }
  return {
    min,
    sec,
  };
};

function Countdown({ duration = 60 }) {
  const { isTimeOver, setIsTimeOver } = useContext(MainContext);

  const defaultTime = 60 * duration;
  const currentHours = Date.now();
  const initialTime = localStorage.getItem("initialTime");

  const [count, setCount] = useState(defaultTime);

  useEffect(() => {
    let timer;
    if (!isTimeOver) {
      // setCount(defaultTime);
      timer = setInterval(() => {
        setCount((counter) => {
          const updatedCounter = counter - 1;
          if (updatedCounter === 0) {
            setIsTimeOver(true);
            return 0;
          }
          return updatedCounter;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isTimeOver]);

  useEffect(() => {
    if (!initialTime) {
      localStorage.setItem("initialTime", currentHours);
    }

    if (Date.now() - initialTime < defaultTime * 1000) {
      setCount(
        Math.round((defaultTime * 1000 - (Date.now() - initialTime)) / 1000),
      );
    }
  }, [initialTime]);

  return (
    <span
      className="demo-button counter-btn"
      style={{ marginRight: 12, fontSize: 32 }}
    >
      {formatDuration(count).min}:{formatDuration(count).sec}
    </span>
  );
}

export default Countdown;
