import React, { useState } from "react";
import ButtonSelect from "../../../../../../components/ui/select/button-select";
import { questionsMapRu, questionsMapUz } from "./questionsMapUz";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const { SmallOption, BigOption } = ButtonSelect;

function getNestedProperty(obj, path) {
  return path.reduce((prev, curr) => prev && prev[curr], obj);
}

function generatePath(stack) {
  const result = [];

  for (let i = 0; i < stack.length; i++) {
    result.push("options", String(stack[i].point), "children");
  }
  return result;
}

function QuestionTypeSelect({ startQuestionsWith }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [stack, setStack] = useState([{ point: 0 }]);
  const [selectedOption, setSelectedOption] = useState([]);

  const handleSelectionChange = (value, optionIdx) => {
    setSelectedOption((prev) => [...prev, value]);
    setStack([...stack, { point: optionIdx }]);
  };

  const handleGoBack = () => {
    const newStack = stack.slice(0, -1);
    if (newStack.length === 0) return navigate(-1);
    setStack(newStack);
    setSelectedOption((prev) => prev.slice(0, -1));
    // console.log("selectedOption", selectedOption);
  };

  const nodePaths = generatePath(stack);

  const currentNode = getNestedProperty(
    i18n.language === "uz" ? questionsMapUz : questionsMapRu,
    nodePaths,
  );
  if (currentNode === null) {
    startQuestionsWith(selectedOption);
    return null;
  }

  return (
    <>
      <div className="breadcrumb-container">
        <button onClick={handleGoBack} className="back-btn">
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M9.02369 10.0006L13.1485 14.1253L11.97 15.3038L6.66669 10.0006L11.97 4.69727L13.1485 5.87577L9.02369 10.0006Z"
                fill="black"
              />
            </svg>
          </span>
          <span className="back-btn__text">{t("back")}</span>
        </button>
      </div>
      <div className="container my-5 py-5 my-5">
        <ButtonSelect title={t(currentNode.title)}>
          {currentNode.options.map((option, idx) =>
            currentNode.selectionType === "small" ? (
              <SmallOption
                value={option.value}
                label={t(option.label)}
                onClick={(value) => handleSelectionChange(value, idx)}
                key={option.value}
              />
            ) : (
              <div className="col-6 col-lg-3">
                <BigOption
                  onClick={(value) => handleSelectionChange(value, idx)}
                  value={option.value}
                  label={t(option.label)}
                  img={option.img}
                  key={option.value}
                />
              </div>
            ),
          )}
        </ButtonSelect>
      </div>
    </>
  );
}

export default QuestionTypeSelect;
