import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useContext } from "react";
import { MainContext } from "../../../../../../context/MainContext";
import { useNavigate } from "react-router-dom";
import ClassOption from "./class-option";
import TypeOption from "./type-option";
import ChildTypeOption from "./child-type-option";
import PresidentStepOption from "./president-school-step-option";
import ScienceOption from "./president-school-option";
import SchoolScienceOption from "./school-option";
import SATOption from "./sat-option";
import ButtonSelect from "../../../../../../components/ui/select/button-select";
import QuestionTypeSelect from "./questionTypeSelect";
import { useTranslation } from "react-i18next";

const BillingDetails = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { setUserData, userData } = useContext(MainContext);

  const handleStartQuestion = (selectedQuizPath) => {
    selectedQuizPath.unshift(userData.lang || i18n.language);
    setUserData((prev) => ({ ...prev, selectedQuizPath }));
    navigate("/quiz/test");
  };

  return <QuestionTypeSelect startQuestionsWith={handleStartQuestion} />;
};

export default BillingDetails;
