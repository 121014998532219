import React, { useState } from "react";
import Scrollspy from "react-scrollspy";
import { Link, useLocation } from "react-router-dom";
import Modal from "react-modal";
import HeaderPopupForm from "../../form/HeaderPopupForm";
import Countdown from "../../../utils/countDown";
import { useContext } from "react";
import { MainContext } from "../../../context/MainContext";
import { useTranslation } from "react-i18next";
import SelectLanguage from "../LanguageSelect";

Modal.setAppElement("#root");

const logo = "/images/githubit/logo.png";

const HeaderLanding = () => {
  const { t } = useTranslation();
  const { isTimeOver, isEndTask, userData } = useContext(MainContext);
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [navbar, setNavbar] = useState(false);

  function toggleModalOne() {
    setIsOpen(!isOpen);
  }

  const changeBackground = () => {
    if (window.scrollY >= 90) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeBackground);
  return (
    <>
      <div
        className={
          navbar
            ? "theme-main-menu sticky-menu theme-menu-five"
            : "theme-main-menu sticky-menu theme-menu-five"
        }
      >
        <div className="d-flex align-items-center justify-content-between">
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="brand" width={250} />
            </Link>
          </div>
          <nav id="mega-menu-holder" className="navbar navbar-expand-lg">
            <div className="container nav-container">
              <div
                className="navbar-collapse collapse landing-menu-onepage"
                id="navbarSupportedContent"
              >
                <div className="d-lg-flex justify-content-between align-items-center">
                  <Scrollspy
                    className="navbar-nav  main-side-nav font-gordita"
                    items={[
                      "home",
                      "features",
                      "about",
                      "product",
                      "pricing",
                      "feedback",
                    ]}
                    currentClassName="active"
                    offset={-500}
                  >
                    <li className="nav-item dropdown position-static">
                      <a className="nav-link" href="#home"></a>
                    </li>
                  </Scrollspy>
                </div>
              </div>
            </div>
          </nav>

          <div className="right-widget custom-button-header d-flex">
            {/* {pathname === "/" && (
              <Link to="/olympics" style={{ width: 200, marginRight: 12 }} className="demo-button start-btn">
                Hачать тест
              </Link>
            )} */}

            {!(
              pathname.split("/").includes("quiz") || pathname === "/chirchik"
            ) && <SelectLanguage />}

            {pathname === "/" && (
              <Link
                to="/chirchik"
                style={{ width: 200, marginRight: 12 }}
                className="demo-button start-btn"
              >
                {t("start.test")}
              </Link>
            )}
            {(pathname === "/quiz/test" ||
              pathname === "/quiz/specialized/test") &&
              !isTimeOver &&
              !isEndTask && <Countdown duration={userData?.science?.time} />}
            {pathname === "/olympics/test" && !isTimeOver && !isEndTask && (
              <Countdown duration={40} />
            )}
            {pathname === "/olympics/test" && !isTimeOver && !isEndTask && (
              <Countdown duration={40} />
            )}
            <button
              style={{ width: 300, paddingRight: 10, paddingLeft: 10 }}
              className="demo-button mobile-hidden"
              onClick={toggleModalOne}
            >
              <span>{t("join.test.lesson")}</span>
              <img src="images/icon/user.svg" alt="icon" />
            </button>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModalOne}
        contentLabel="My dialog"
        className="custom-modal  modal-contact-popup-one"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="box_inner ">
          <main className="main-body box_inner modal-content clearfix">
            <button className="close" onClick={toggleModalOne}>
              <img src="/images/icon/close.svg" alt="close" />
            </button>
            {/* End close icon */}

            <div className="left-side">
              <div className="d-flex flex-column justify-content-between h-100">
                <div className="row">
                  <div className="col-xl-10 col-lg-8 m-auto">
                    <blockquote>
                      “I never dreamed about success. I worked for it.”
                    </blockquote>
                    <span className="bio">—Estée Lauder</span>
                  </div>
                </div>
                <img
                  src="/images/assets/ils_18.svg"
                  alt=""
                  className="illustration mt-auto"
                />
              </div>
            </div>
            {/* /.left-side */}

            <div className="right-side">
              <h2 className="form-title">{t("join.test.lesson")}</h2>
              <HeaderPopupForm toggleModalOne={toggleModalOne} />
            </div>
            {/*  /.right-side */}
          </main>
          {/* /.main-body */}
        </div>
      </Modal>
      {/* End  Modal For Request a demo */}
    </>
  );
};

export default HeaderLanding;
