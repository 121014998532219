import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import { Trans } from "react-i18next";

const FancyTextBlock21 = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <div className="row align-items-center">
        <div
          className="col-lg-6 col-md-7 m-auto"
          data-aos="fade-right"
          data-aos-duration="1200"
        >
          <div className="img-meta">
            <img src="images/githubit/m1.jpg" alt="media" className="m-auto" />
            <img
              src="images/shape/138.svg"
              alt="shape"
              className="shapes shape-one"
            />
          </div>
        </div>
        {/* End .col */}

        <div
          className="col-xl-5 col-lg-6 ms-auto"
          data-aos="fade-left"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <div className="text-wrapper md-pt-50">
            <a className="fancybox" onClick={() => setOpen(true)}>
              <img src="images/icon/71.svg" alt="icon" className="icon" />
            </a>
            <p>
              <Trans i18nKey={"help.to.grow.up.child.desc1"}>
                text
                <span />
              </Trans>
            </p>
          </div>
          {/* /.text-wrapper */}
        </div>
        {/* End .col */}
      </div>

      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="aj77DafszIc"
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default FancyTextBlock21;
