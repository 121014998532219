import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { data } from "../../../../../fake-db";
import AnswerOption from "./answer-opt";
import Result from "./result";
import { useContext } from "react";
import { MainContext } from "../../../../../context/MainContext";
import { useNavigate } from "react-router-dom";
import DeadLine from "./deadline";

function getNestedProperty(obj, path) {
  return path.reduce((prev, curr) => prev && prev[curr], obj);
}

const TestForm = () => {
  const navigate = useNavigate();
  const [answers, setAnswers] = useState({});
  const [error, setError] = useState([]);
  const [isDirty, setIsDirty] = useState(false);
  const [isEnd, setIsEnd] = useState(false);
  const [score, setScore] = useState({ incorrect: 0, total: 30 });
  const { userData, isTimeOver, setIsTimeOver, setIsEndTask } =
    useContext(MainContext);

  const testList = useMemo(
    () =>
      Object.keys(userData).length
        ? getNestedProperty(data, userData.selectedQuizPath)
        : [],
    [],
  );
  const onSubmit = (e) => {
    e.preventDefault();
    setError([]);
    testList?.forEach((item) => {
      const answer = answers[item.id]?.myAnswer;

      if (item.options[item.answer]?.value !== answer) {
        setError((prev) => ({
          ...prev,
          [item.id]: { answer, status: "error" },
        }));
        if (answer)
          setScore((prev) => ({ ...prev, incorrect: prev.incorrect + 1 }));
      } else
        setError((prev) => ({
          ...prev,
          [item.id]: { answer, status: "success" },
        }));
    });
    setIsEnd(true);
    setIsDirty(false);
    setIsEndTask(true);
    localStorage.setItem("endTime", Date.now());
  };

  const handleClick = (data) => {
    setAnswers((prev) => ({ ...prev, [data.id]: data }));
    setIsDirty(true);
  };
  const shuffle = useMemo(
    () => testList?.sort(() => Math.random() - 0.5),
    [testList],
  );

  useEffect(() => {
    if (!Object.keys(userData).length && !isDirty) {
      navigate("/chirchik");
    }
    // Add a beforeunload event listener
    const handleBeforeUnload = (e) => {
      if (isDirty) {
        e.preventDefault();
        e.returnValue =
          "You have unsaved changes. Are you sure you want to leave?";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("beforeunload", handleBeforeUnload);
      setIsTimeOver(null);
    };
  }, [isDirty]);
  if (isTimeOver) {
    return <DeadLine />;
  } else
    return (
      <div className="main-page-wrapper pt-0">
        <Helmet>
          <title>Mathschool - учебный центр в Чирчике</title>
        </Helmet>
        <div
          className="faqs-inner-page pt-0 pb-0"
          style={{ background: "unset" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-11 m-auto">
                <div className="all-faqs">
                  <div className="faqs-all-qus">
                    <form onSubmit={(e) => onSubmit(e)}>
                      {shuffle?.slice(0, 30)?.map((item, i) => (
                        <div
                          className="article-preview d-flex"
                          key={i}
                          data-aos="fade-up"
                          data-aos-duration="1200"
                        >
                          <div className="w-100">
                            <h3 className="font-rubik article-preview-title">
                              {`${i + 1}. `}
                              {item.question?.map((q) => {
                                if (q.type === "img") {
                                  return <img src={q.value} alt={q.value} />;
                                } else return q.value;
                              })}
                            </h3>
                            <div className="avatar-info">
                              <ul className="test-options">
                                {item.options?.map((opt, key) => {
                                  return (
                                    <li
                                      key={key}
                                      className={
                                        isEnd
                                          ? error[item.id].answer === opt?.value
                                            ? error[item.id].status
                                            : ""
                                          : ""
                                      }
                                      onClick={() =>
                                        handleClick({
                                          id: item.id,
                                          myAnswer: opt.value,
                                        })
                                      }
                                    >
                                      <AnswerOption
                                        item={item}
                                        isEnd={isEnd}
                                        opt={opt}
                                      />
                                      <br />
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                      {!isEnd && (
                        <div className="text-center more-faq-ask">
                          <button className="theme-btn-one">Отправить</button>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isEnd && (
          <Result
            score={score}
            answers={answers}
            total={testList?.length > 30 ? 30 : testList?.length}
          />
        )}
      </div>
    );
};

export default TestForm;
