import React from "react";
import UserForm from "./user-form";
import { useContext } from "react";
import { MainContext } from "../../../../../context/MainContext";

const Result = ({ score, answers, total = 30, setError, setIsEnd, setScore, setAnswers, formRef }) => {
  const { userData, setUserData, setIsTimeOver, setIsEndTask } = useContext(MainContext);

  const startSecondPart = () => {
    setIsTimeOver(false);
    setIsEndTask(false);
    setIsEnd(false);
    setError([]);
    setScore({ incorrect: 0, total: 0 });
    setAnswers();
    if (formRef.current) {
      formRef.current.reset();
    }
    setUserData((prev) => ({
      ...prev,
      science: {
        label: "Matematika (Tanqidiy fikrlash + Muammoni yechish)",
        value: "mathematic_2",
        time: 90,
        problem_solving: true,
        critical_thinking: true,
        isVisible: true,
      },
    }));
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Add smooth scrolling animation
    });
  };

  return (
    <div className="all-faqs container">
      <div className="faqs-all-qus">
        <div className="article-preview ant-result ant-result-403 css-amq5gd pt-0">
          <div className="ant-result-icon ant-result-image">
            <img src="/images/githubit/undraw_agree_re_hor9.svg" />
          </div>
          <div className="ant-result-title">
            <span>
              {`${userData?.lang === "uz" ? "Umumiy: " : "Общий: "}`} {total}
            </span>
            <span>
              {`${userData?.lang === "uz" ? "To'g'ri: " : "Правильных: "}`} {total - score.incorrect}
            </span>
            <span>
              {`${userData?.lang === "uz" ? "Noto'g'ri: " : "Неправильных: "}`} {score.incorrect}
            </span>
          </div>
          <div className="ant-result-title">
            <span>
              {`${userData?.lang === "uz" ? "Koeffitsiyent: " : "Коэффициент: "}`}

              {`${(((total - score.incorrect) * 100) / total).toFixed(2)} %`}
            </span>
          </div>
          <div className="ant-result-subtitle">
            {userData?.lang === "uz" ? (
              <span>
                Bizning telegram sahifamizga a'zo bo'ling.{" "}
                <a href="https://t.me/mathschoolsuz" style={{ color: "blue" }}>
                  https://t.me/mathschoolsuz
                </a>
              </span>
            ) : (
              <span>
                Подпишитесь на нашу страницу в Telegram.{" "}
                <a href="https://t.me/mathschoolsuz" style={{ color: "blue" }}>
                  https://t.me/mathschoolsuz
                </a>
              </span>
            )}
          </div>
          {userData?.science?.value === "mathematics_1" && (
            <div className="d-flex justify-content-center mt-4">
              <button className="theme-btn-one" onClick={startSecondPart}>
                Start
              </button>
            </div>
          )}
          <UserForm
            answers={answers}
            incorrect={score.incorrect}
            correct={total - score.incorrect}
            percent={`${(((total - score.incorrect) * 100) / total).toFixed(2)} %`}
          />
        </div>
      </div>
    </div>
  );
};

export default Result;
