import React, { useState } from "react";
import { Helmet } from "react-helmet";
import FancyFeatureTen from "../../components/features/FancyFeatureTen";
import HeaderLanding from "../../components/header/landing/HeaderLanding";
import HeroBannerFive from "../../components/hero-banner/HeroBannerFive";
import FancyTextBlock21 from "../../components/fancy-text-block/FancyTextBlock21";
import CounterTwo from "../../components/counter/CounterTwo";
import FancyFeatureEleven from "../../components/features/FancyFeatureEleven";
import Pricing from "../../components/pricing/pricing-four/Pricing";
import TestimonialFour from "../../components/testimonial/TestimonialFour";
import FooterFive from "../../components/footer/FooterFive";
import FaqClassicThree from "../../components/faq/FaqClassicThree";
import { Link } from "react-router-dom";
import CallToActionFour from "../../components/call-to-action/CallToActionFour";
import FancyTextBlock23 from "../../components/fancy-text-block/FancyTextBlock23";
import { useTranslation } from "react-i18next";

const ProductLanding = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  function toggleModalOne() {
    setIsOpen(!isOpen);
  }

  return (
    <div className="main-page-wrapper font-gordita">
      <Helmet>
        <title> Mathschool - {t("edu.center.in.chirchik")}</title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderLanding />
      {/* End HeaderLanding */}

      <div id="home">
        <HeroBannerFive
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          toggleModalOne={toggleModalOne}
        />
      </div>
      {/* End HeaderBannerFive */}

      {/* 	=============================================
				Fancy Feature Ten
			==============================================  */}
      <div className="fancy-feature-ten pt-100 md-pt-70" id="features">
        <div className="bg-wrapper">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div
                className="col-md-6"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="title-style-six">
                  <h2>
                    {t("our")} <br /> <span>{t("programs")}</span>
                  </h2>
                </div>
                {/* /.title-style-six */}
              </div>
              {/* End .col */}

              <div
                className="col-lg-5 col-md-6"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <p className="sub-text pt-30 pb-30">{t("our.programs.desc")}</p>
              </div>
              {/* End .col */}
            </div>
            <FancyFeatureTen />
            {/* End Fancy Feature Ten */}
          </div>
          <img
            src="images/shape/137.svg"
            alt="shape"
            className="shapes shape-one"
          />
        </div>
        {/* /.bg-wrapper */}
      </div>
      {/* /.fancy-feature-ten */}

      {/*   =============================================
				Fancy Text block Twenty One
			==============================================  */}
      <div className="fancy-text-block-twentyOne pt-170 md-pt-100" id="about">
        <div className="container">
          <FancyTextBlock23 />
        </div>
      </div>

      <div className="fancy-text-block-twentyOne pt-170 md-pt-100" id="about">
        <div className="container">
          <FancyTextBlock21 />
        </div>
      </div>
      {/* /.fancy-text-block-twentyOne */}

      {/*  =====================================================
				Counter Style Two
			===================================================== */}
      {/* <div className="counter-style-two mt-150 md-mt-60">
        <div className="border-bottom">
          <div className="container">
            <CounterTwo />
          </div>
        </div>
      </div> */}
      {/* /.counter-style-two */}

      {/*   =====================================================
				Fancy Feature Eleven
			===================================================== */}
      <div className="fancy-feature-eleven pt-130 md-pt-80" id="product">
        <div className="inner-container">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 m-auto">
                <div className="title-style-six text-center">
                  <h6>{t("math")}</h6>
                  <h3>{t("help.to.grow.up.child.desc2")}</h3>
                </div>
                {/* /.title-style-six */}
              </div>
            </div>
          </div>
          {/* End .container */}
          <FancyFeatureEleven toggleModalOne={toggleModalOne} />
        </div>
        {/* /.inner-container */}
        <img
          src="images/shape/139.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/140.svg"
          alt="shape"
          className="shapes shape-two"
        />
      </div>
      {/* /.fancy-feature-eleven */}

      {/*  =====================================================
				Pricing Section Four
			===================================================== */}
      {/* <div className="pricing-section-four pt-200 md-pt-100" id="pricing">
        <div className="container">
          <div className="row">
            <div className="col-xl-10  m-auto">
              <div className="title-style-six text-center">
                <h2>
                  Solo, Agency or Team? We’ve got you <span>covered.</span>
                </h2>
              </div> */}
      {/* /.title-style-six */}
      {/* </div>
          </div>
        </div> */}
      {/* End .container */}
      {/* 
        <Pricing />
      </div> */}
      {/*  /.pricing-section-four */}

      {/*  =====================================================
				Client Feedback Slider Four
			===================================================== */}
      {/* <div
        className="client-feedback-slider-four mt-200 md-mt-100"
        id="feedback"
      >
        <div className="inner-container">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-9 col-md-8 m-auto">
                <div className="title-style-six text-center">
                  <h2>
                    <span>13,000+</span> Clients love Our product
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="clientSliderFour slick-nav-none">
            <TestimonialFour />
          </div>
          <img
            src="images/shape/141.svg"
            alt="shape"
            className="shapes shape-one"
          />
          <img
            src="images/shape/142.svg"
            alt="shape"
            className="shapes shape-two"
          />
        </div>
      </div> */}
      {/* /.client-feedback-slider-four */}

      {/*  =====================================================
				Fancy Short Banner Six
			===================================================== */}
      <div className="fancy-short-banner-one">
        <div className="container">
          <CallToActionFour />
        </div>
        {/* /.container */}
        <div className="bubble-one"></div>
        <div className="bubble-two"></div>
        <div className="bubble-three"></div>
        <div className="bubble-four"></div>
        <div className="bubble-five"></div>
        <div className="bubble-six"></div>
      </div>
      {/* /.fancy-short-banner-six */}
      {/* =====================================================
            Faq Section Five
        ===================================================== */}
      <div
        className="faq-section-five lg-container pt-200 mt-25 md-pt-80"
        id="faq"
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-6">
              <div className="title-style-twelve">
                <div className="upper-title">FAQ</div>
                <h2>{t("faq")}</h2>
                <p className="pt-25 pb-40 md-pb-30">
                  {t("ask.your.questions")}
                </p>
                <Link className="theme-btn-eight" to="/contact-doc">
                  {t("write")}
                </Link>
              </div>
            </div>
            <div className="col-lg-6 ms-auto">
              <FaqClassicThree />
            </div>
          </div>
        </div>
      </div>
      {/* /.fqa-section-five */}
      {/* =====================================================
				Footer Style Five
			===================================================== */}
      <footer className="theme-footer-five mt-130 md-mt-100">
        <div className="inner-container">
          <div className="container">
            <FooterFive />
          </div>
        </div>
        {/* /.inner-container */}
        <p className="copyright">{t("address.text")}. </p>
      </footer>
      {/*  /.theme-footer-five */}
    </div>
  );
};

export default ProductLanding;
