import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setUserData } from "../../../../../redux/slices/olympics";
import { MainContext } from "../../../../../context/MainContext";

const DeadLine = () => {
  const dispatch = useDispatch();
  const { setIsTimeOver, setIsEndTask } = useContext(MainContext);

  const logOut = () => {
    setIsEndTask(false);
    setIsTimeOver(false);
    dispatch(setUserData({}));
    localStorage.removeItem("endTime");
    localStorage.removeItem("initialTime");
  };
  return (
    <div className="all-faqs container">
      <div className="faqs-all-qus">
        <div className="article-preview ant-result ant-result-403 css-amq5gd pt-0">
          <div className="ant-result-icon ant-result-image">
            <img src="/images/githubit/undraw_time_management_re_tk5w.svg" />
          </div>
          <div className="ant-result-title"></div>
          <div className="ant-result-subtitle">
            К сожалению, вы не смогли выполнить тестовые задания в указанное время. <br /> Пожалуйста, попробуйте еще
            раз.
          </div>
          <div className="col-12 d-flex justify-content-center mt-5">
            <Link to="/olympics">
              <button onClick={logOut} type="submit" className="theme-btn-two">
                Начать сначала
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeadLine;
